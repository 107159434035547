<template>
    <div class="survey-frequency-warp">
        <div class="bg">
            <el-row>
                <el-col :span="24">
                    <NewTitle
                        title="机构调研频次个股列表"
                        @change="changeHandle1"
                    />
                    <el-table
                        v-loading="loading"
                        class="table-style-2 cursor"
                        :data="dataList1.list"
                        border
                        style="width: 100%"
                        @sort-change="sortChange1"
                        @row-click="goResearchList"
                        :default-sort ="{prop:'researchCount',order:'descending'}">
                        <el-table-column
                            prop="code"
                            align="center"
                            label="代码"
                        />
                        <el-table-column
                            prop="name"
                            align="center"
                            label="股票名称"
                        />
                        <el-table-column
                            prop="totalMarketCap"
                            align="center"
                            label="总市值(亿)"
                            sortable="custom"
                        >
                            <template #default='{row}'>
                                <div>{{row.totalMarketCap || '-'}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="peTtm"
                            align="center"
                            label="PE-TTM"
                            sortable="custom"
                        >
                            <template #default='{row}'>
                                <div>{{row.peTtm || '-'}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="researchCount"
                            align="center"
                            label="机构调研总次数"
                            sortable="custom"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="orgResearchNumber"
                            align="center"
                            label="机构调研家数"
                            sortable="custom"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="focusOrgResearchCount"
                            align="center"
                            label="重点机构调研总次数"
                            sortable="custom"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="focusOrgResearchNumber"
                            align="center"
                            label="重点机构调研家数"
                            sortable="custom"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="increase"
                            align="center"
                            label="区间涨幅"
                        >
                            <template #default='{row}'>
                                <div v-dClass='row.increase' v-if="row.increase || row.increase === 0">
                                    <template v-if="row.increase > 0">+</template>{{row.increase === 0 ? '0.00' : row.increase}}%
                                </div>
                                <div v-else>-</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="industry"
                            align="center"
                            label="所属行业"
                        >
                            <template #default='{row}'>
                                <div>{{row.industry || '-'}}</div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="pagination">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="page.pageNum"
                            :page-sizes="[30, 50, 100]"
                            :page-size="page.pageSize"
                            layout="sizes, prev, pager, next, jumper"
                            :total="dataList1.totalSize">
                        </el-pagination>
                    </div>   
                </el-col>
            </el-row>
        </div>
    </div>
</template>
    
<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue';
import NewTitle from '@/components/new-title/Index.vue';
import { getSurveyRateCountList } from '@/apis/research';
import moment from 'moment';
import dicts from '@/utils/dicts';
import data from '../pdf/content';
  
interface Inter {
    order: string | undefined,
    orderField: string | undefined,
}
export default defineComponent({
    components: {
        NewTitle
    },
    setup() {
        const dataMap = reactive({
            dataList1: [],
            selectedTime1: 0,
            beginDate1: undefined as any,
            endDate1: undefined as any,
            loading: true,
            page: {
                totalSize: 0,
                pageSize: 30,
                pageNum: 1,
            },
            timeLevel: -1
        })
    
        let sortParams1:Inter = reactive({
            order: 'DESC',
            orderField: 'researchCount',
            timeLevel: '1w',
        })
        
        const changeHandle1 = async (params:any) => {
            dataMap.timeLevel = params.timeLevel;
            if(params.timeLevel !== 0) {
                dataMap.selectedTime1 = (dicts.timeSelection || []).findIndex((item:any) => item.value === params.timeLevel);
                dataMap.beginDate1 = undefined;
                dataMap.endDate1 = undefined;
            }else {
                dataMap.selectedTime1 = 5;
                dataMap.beginDate1 = params.dateFrom ? params.dateFrom : undefined;
                dataMap.endDate1 = params.dateTo ? params.dateTo : undefined;
            }
            dataMap.page.pageNum = 1;
            dataMap.page.pageSize = 30;
            getList();         
        }

        const getList = async () => {
            const timeLevelStr = (dicts.timeSelection || []).find((item:any) => item.value === dataMap.timeLevel);
            const newParams = {
                timeLevel: timeLevelStr?.timeLevel,
                startTime: dataMap.beginDate1 ? moment(dataMap.beginDate1).valueOf() : undefined,
                endTime: dataMap.endDate1 ? moment(dataMap.endDate1).valueOf() : undefined,
                pageNum: dataMap.page.pageNum,
                pageSize: dataMap.page.pageSize,
            }
            dataMap.loading = true;
            const data = await getSurveyRateCountList( {...sortParams1, ...newParams})
            dataMap.loading = false;
            dataMap.dataList1 = (data as any).data
        }

        const sortChange1 = (item:any) => {
            if( item.prop ){
                sortParams1 = {
                    order: item.order === "ascending" ? 'ASC' : 'DESC',
                    orderField: item.prop,
                }
                getList()
            }else{
                delete sortParams1.order
                delete sortParams1.orderField
                getList()
            }
        }

        const goResearchList = (row:any, column:any)=>{
            const { property } = column;
                // 行业
                if(!['industry'].includes(property)){
                    let url = `/investment/research/detail?code=${row.code}&timeLevel=${dataMap.selectedTime1}`
                // 重点
                if(['orgResearchCount', 'focusOrgResearchCount'].includes(property)){
                    if(dataMap.beginDate1) {
                    url = `${url}&isPointOrg=1&beginDate=${dataMap.beginDate1}&endDate=${dataMap.endDate1}`
                    }else {
                    url = `${url}&isPointOrg=1`
                    }
                }else {
                    if(dataMap.beginDate1) {
                    url = `${url}&isPointOrg=0&beginDate=${dataMap.beginDate1}&endDate=${dataMap.endDate1}`
                    }else {
                    url = `${url}&isPointOrg=0`
                    }
                }
                window.open(url);
            }else {
                if(dataMap.beginDate1) {
                    window.open(`/investment/research/list?isPointOrg=0&industryName=${row.industry}&timeLevel=${dataMap.selectedTime1}&beginDate=${dataMap.beginDate1}&endDate=${dataMap.endDate1}`)
                }else{
                    window.open(`/investment/research/list?isPointOrg=0&industryName=${row.industry}&timeLevel=${dataMap.selectedTime1}`)
                }
            }
        }

        // （切换每页多少条）
        const handleSizeChange = (val:any) => {
            dataMap.page.pageSize = val;
            dataMap.page.pageNum = 1;
            getList()
        }

        //（切换页码）
        const handleCurrentChange = (val:any) => {
            dataMap.page.pageNum = val;
            getList()
        }

        return {
            sortChange1,
            changeHandle1,
            goResearchList,
            handleSizeChange,
            handleCurrentChange,
            ...toRefs(dataMap)
        }
    }
})
</script>
<style scoped lang='scss'>
.survey-frequency-warp{
    padding: 21px 35px;
    .bg{
        background: rgb(255, 255, 255);
        padding: 20px;
        margin-top: 30px;
    }
    .pagination{
        text-align: center;
        margin-top: 20px;
        // .el-pagination{
        //     margin-top: 40px;
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;
        //     ::v-deep .el-pagination__total{
        //         display: none;
        //     }
        //     ::v-deep .el-pager{
        //         li{
        //         min-width: 37px;
        //         height: 37px;
        //         line-height: 37px;
        //         border-radius: 4px;
        //         font-size: 14px;
        //         border: 1px solid #CCCCCC;
        //         color: #333333;
        //         margin: 0 8px;
        //         &.active{
        //             background: #386FF2;
        //             border-color: #386FF2;
        //             color: #fff;
        //         }
        //         }
        //     }
        //     ::v-deep .btn-prev,::v-deep .btn-next{
        //         padding: 0;
        //         width: 82px;
        //         height: 37px;
        //         border-radius: 4px;
        //         border: 1px solid #CCCCCC!important;
        //     }
        //     ::v-deep .el-pagination__jump{
        //         height: 37px;
        //         .el-input__inner{
        //         height: 37px;
        //         }
        //     }
        // }
    }
}
</style>
    