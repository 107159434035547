

import { defineComponent, reactive, toRefs, ref } from 'vue'
import breadcrumb from '@/components/breadcrumb/Index.vue'
import CTitle from "@/components/title/Index.vue"
import CPagination from "@/components/pagination/Index.vue"
import LineChartN from '@/components/charts/LineChartN.vue'

import { useRoute  } from "vue-router";

import { getSurveyStockDetail, getOrgIndustryBoardList, getOrgIndustryBoardMore, getSurveyOrgList, getSurveyDailyCount, getReportCalculateGains } from '@/apis/research'
import { getStockQuery, getSurveyOrg } from '@/apis/home'
import dicts from '@/utils/dicts'
import moment from 'moment';

interface IComponent{
    changeCurPage?:Function
}
export default defineComponent({
  components: {
    CTitle,
    breadcrumb,
    CPagination,
    LineChartN
  },
  props:{
    from: {
      type: String
    }
  },
  setup(props) {
    const route = useRoute();
    const stockCode = route.query.code;
    const orgName = route.query.orgName;
    const isFirstFollow = Number(route.query.isFirstFollow) === 1;
    const section = route.query.section as any;
    const pagin = ref<InstanceType<typeof CPagination>>() 
    const refTab = ref(null)

    const dataMap = reactive({
      loading: true,
      visible: false,
      // selectedText: '',
      selectedIndustry: '',
      industryActive: -1,
      formInlineinit: {
        timeLevel: '1w',
        industryName:'',//行业名称
        orgName: '',//机构名称
        region: '',
        isFirstFollow: [],
        isPointOrg: [],
        time: []
      },
      formInline: {
        // stockCode:'',
        timeLevel: '1w',
        industryName:route.query.industryName,//行业名称
        orgName: orgName ?? '',//机构名称
        region: {},
        isFirstFollow: isFirstFollow ? ['首次覆盖'] : [],
        isPointOrg: route['query']['isPointOrg'] && route['query']['isPointOrg'] !== '0' ? ['只看重点机构'] : [],
        time: []
      },
      stockList1: {industryName:''},
      industryList: [],
      industryMoreList: [],
      echartsData: [],
      industryLastText: '',
      searchList: [],
      searchList2: [],

      dstxActive  : 0,
      showTimeSelect: false,
      selectTime: ['',''],

      industryVisible: false,
      ruleForm2: {
          valuationFrom: '',
          valuationTo: ''
      },
      rules:  {
          valuationFrom: [
              { pattern: /^(0|([1-9][0-9]*))(\.[\d]{2})?$/, message: '请输入正确的值' }
          ],
          valuationTo: [
              { pattern: /^(0|([1-9][0-9]*))(\.[\d]{2})?$/, message: '请输入正确的值' }
          ]
      },
      sortParams: {
        order: 'DESC',
        orderField: 'surveyDate'
      },

      isShowOrgResearchNumber: true
    })

    if(section){
      const newData = section.split(',');
      dataMap.formInline['marketCapGreatThan'] = newData[0]
      dataMap.formInline['marketGapLessThan'] = newData[1]
      let name = '';
      if(newData[0] && newData[1]){
        name =  newData[0] + ' - ' + newData[1]
      }else {
        if(newData[0] && !newData[1]){
          name = `≥${newData[0]}`
        }else if(!newData[0] && newData[1]){
          name = `<${newData[1]}`
        }
      }
      dataMap.formInline['aa'] = name
    }
    

    if( route.query.time ){
      dataMap.formInline.region = dicts.month[Number(route.query.time)]
      // console.log(9909, dicts.month[Number(route.query.time)])
      dataMap.formInline['timeLevel'] = dicts.month[Number(route.query.time)]!.timeLevel
    }

    if( route.query.stockCode ){
      dataMap.formInline['stockCode'] = route.query.stockCode
    }
    // dataMap.formInline['timeLevel'] = '1w'

    //图表
    const getChartData = async () => {
      if( !dataMap.formInline.industryName ){
        return false
      }
      let params = {}

      //如果是自定义时间
      if( dataMap.showTimeSelect ){
        params = {
          startTime: dataMap.formInline['startTime'],
          endTime: dataMap.formInline['endTime'],
        }
      }else{
        params = {
          timeLevel: dataMap.formInline['timeLevel'],
        }
      }
      let data3 = await getSurveyDailyCount({ 
        industry: dataMap.formInline.industryName,
        // referCode: route.query.code,  
        ...params
      })
      let data3Copy =  JSON.parse(JSON.stringify((data3 as any).data))
      data3Copy.map((item:any)=>{
        item.referValue = item.referValue ? item.referValue : 0
        item.orgResearchCount = item.orgResearchCount ? item.orgResearchCount : 0
        item.orgResearchNumber = item.orgResearchNumber ? item.orgResearchNumber : 0
        item.stockNumber = item.stockNumber ? item.stockNumber : 0
      })
      dataMap.echartsData = data3Copy
    }

    //获取行业列表
    const fetchList = async () => {
     
      let data1 = await getOrgIndustryBoardList({ })
      dataMap.industryList = (data1 as any).data

      let data2 = await getOrgIndustryBoardMore({ })
      dataMap.industryMoreList = (data2 as any).data

      

      let name = route.query.industryName
      if( !props.from && name ){

        //寻找行业列表下标
        const idx = dataMap.industryList.findIndex(item => item === name)
        
        //行业列表默认选中
        if( idx !== -1 ){
          dataMap.industryActive = idx
        }else{
          //行业列表最后一个默认选中
          dataMap.industryActive = -2
          dataMap.industryLastText = String(name);
          // dataMap.selectedText = String(name);
        }
      }
      // industryMoreList
    }
    fetchList()

    const fetchData = async (_parms:any) => {
      dataMap.loading = true

      dataMap.formInline = {...dataMap.formInline, order: dataMap.sortParams.order, orderField:dataMap.sortParams.orderField, ..._parms}

      const newForm = JSON.parse(JSON.stringify(dataMap.formInline))
      //如果是自定义时间
      // if( newForm.region['value'] === 4 ){
      delete newForm['time']
      // }
      delete newForm['region']


      let data1 = props['from'] ? await getSurveyStockDetail({ 
        stockCode,
        ...newForm,
        isPointOrg: dataMap.formInline.isPointOrg.length ? 1 : 0,
        isFirstFollow: dataMap.formInline.isFirstFollow.length ? 1 : 0,
      }) : await getSurveyOrgList({ 
        stockCode,
        ...newForm,
        isPointOrg: dataMap.formInline.isPointOrg.length ? 1 : 0,
        isFirstFollow: dataMap.formInline.isFirstFollow.length ? 1 : 0,
      })
      
      dataMap.stockList1 = (data1 as any).data
      
      if( !props.from ){
        if( dataMap.industryActive === -1 ){
          // dataMap.selectedText = ''
          dataMap['formInline']['industryName'] = ''
        }else{
          // dataMap.selectedText = dataMap.selectedIndustry || dataMap.industryLastText
          dataMap['formInline']['industryName'] = dataMap.selectedIndustry || dataMap.industryLastText
        }
      }

      dataMap.loading = false
    }
    
    //搜索按钮
    const onSearch = async (_parms:any) => {
      //自定义时间
      if( dataMap.showTimeSelect ){
        // delete time
        
        dataMap.formInline['startTime'] = window.moment(dataMap.selectTime[0]).format('x')
        dataMap.formInline['endTime'] = window.moment(dataMap.selectTime[1]).format('x')
      }

      if( !dataMap.formInline.isPointOrg.length && !dataMap.formInline.orgName ){
        dataMap.isShowOrgResearchNumber = true
      }else{
        dataMap.isShowOrgResearchNumber = false
      }

      getChartData()
      // moment(this.dialogcontent.kssj, 'YYYY-MM-DD kk:mm:ss').format('x')


      // fetchData({});
      if( pagin && pagin.value ){
        pagin.value!.changeCurPage(1)
      }
    }

    //清空
    const onClearForm = async () => {
      dataMap.formInline = {
        pageNum: dataMap.formInline['pageNum'],
        pageSize: dataMap.formInline['pageSize'],
        ...JSON.parse(JSON.stringify(dataMap.formInlineinit))
      }
      dataMap.industryActive = -1
      // dataMap.selectedText = ''
      
      swichItem(0)

      if( refTab ){
        (refTab as any).value.sort('surveyDate','descending')
      }

      if( pagin && pagin.value ){
        pagin.value!.changeCurPage(1)
      }

    }

    //新版时间tab选择
    const swichItem = async (idx: any) => {
        dataMap.dstxActive = idx
        dataMap.showTimeSelect = idx === 5 ? true : false
        if( idx === 5 ){
          // delete dataMap.formInline['timeLevel']
          dataMap.formInline['timeLevel'] = ''
        }else{
          dataMap.formInline['timeLevel'] = dicts.timeSelection[idx].timeLevel
        }
    }

    if( route.query.timeLevel ){
      swichItem(Number(route.query.timeLevel))

      //自定义时间
      if( dataMap.showTimeSelect ){
        const beginDate = `${route.query.beginDate ?? ''}`;
        const endDate = `${route.query.endDate ?? ''}`;
        dataMap.formInline['startTime'] = beginDate ? moment(beginDate).format('x') : ''
        dataMap.formInline['endTime'] = endDate ? moment(endDate).format('x') : ''
        dataMap['selectTime'] = [String(route.query.beginDate), String(route.query.endDate)]
      }else{
        dataMap.selectTime = []
      }
      getChartData()
    }else{
      dataMap.selectTime = []
      getChartData()
    }

    //时间下拉框
    const handleChange = (obj:any) => {
      const { timeLevel } = obj
      if( obj.value !== 4 ){
        //fetchData({timeLevel});
        dataMap.formInline['timeLevel'] = timeLevel
        // delete dataMap.formInline['time']
      }else{
        dataMap.formInline['timeLevel'] = ''
      }
    }

    //行业选择
    const handleIndustryTab = (idx:any, item:any) => {
      if( idx === -1 ){
        dataMap.echartsData = []
      }
      dataMap['industryActive'] = idx
      dataMap.formInline.industryName = item
      // dataMap['formInline']['industry'] = item
      dataMap.selectedIndustry = item
    }

    //行业更多选择
    const handleMoreClick = (item:any) => {
      // dataMap['formInline']['industry'] = item
      dataMap.formInline.industryName = item
      dataMap.industryLastText = item
      dataMap.selectedIndustry = item
      dataMap.industryActive = -2
      dataMap.visible = false
    }

    //日期排序
    const sortChange = (column:any) => {
      
      let order = column.order === "ascending" ? 'ASC' : 'DESC';
      // let orderField = 
      // getTableData2(column["prop"], isAsc);
      if( column['prop'] ){
        dataMap.sortParams = {
          order,
          orderField: column["prop"]
        }
        fetchData({});
      }else{
        dataMap.sortParams = {
          order: '',
          orderField: ''
        }
        delete dataMap.formInline['order']
        delete dataMap.formInline['orderField']
        fetchData({});
      }
    }

    const handleRowClick = (column:any) => {
        const beginDate = window.moment(dataMap.selectTime[0]).format('YYYY-MM-DD')
        const endDate = window.moment(dataMap.selectTime[1]).format('YYYY-MM-DD')
        window.open(`/investment/research/detail?code=${column.stockCode}&timeLevel=${dataMap.dstxActive}&beginDate=${beginDate}&endDate=${endDate}`)
    }


    const blurHandler = (res:any) => {
      setTimeout(()=>{
        dataMap.searchList = []
      },200)
    }
    let timer:any = null;
    const fetchSearchData = () => {
      if( timer ){
        clearTimeout(timer)
      }
      timer = setTimeout(async ()=>{
        let data = await getStockQuery({ keyword: dataMap.formInline['stockCode'], limit: 20})
        let aaa = (data as any).data;
        // aaa = aaa.filter((item:any)=>item.state !== 1 && item.state !== 4)
        dataMap.searchList = aaa;
      },500)
      
    }

    const selectedSearch = (item:any) => {
      dataMap.formInline['stockCode'] = item.name
    }

     const blurHandler2 = (res:any) => {
      focusState = false
      setTimeout(()=>{
        dataMap.searchList2 = []
      },200)
    }
    let timer2:any = null;
    let focusState:any = false;
    const fetchSearchData2 = (isFocus:any) => {
      if( timer2 ){
        clearTimeout(timer2)
      }
      if( isFocus ){
        focusState = true
      }
      timer2 = setTimeout(async ()=>{
        let data = await getSurveyOrg({ keyword: dataMap.formInline['orgName'], limit: 20})
        let aaa = (data as any).data;
        if( focusState ){
          dataMap.searchList2 = aaa;
        }
      },500)
      
    }
    const selectedSearch2 = (item:any) => {
      dataMap.formInline['orgName'] = item.name
    }

    //表格时间选择
    const HandleRowTime = async (item:any) => {
      let data2 = await getReportCalculateGains({ 
        stockCode: item.stockCode,
        beginDate: item.surveyDate + ' 23:59:59',
        endDate:   window.moment(item.time).format('YYYY-MM-DD HH:mm:ss')
      })
      item.customIncome = (data2 as any).data
      // dataMap.industryMoreList = (data2 as any).data
    }

    //市值规模
    const myForm2 = ref(null);
    const submitForm = async () => {
      let { validate } = myForm2.value as any;
      validate((valid:any) => {
          if (valid) {
            dataMap['industryVisible'] = false
            dataMap.formInline['marketCapGreatThan'] = dataMap.ruleForm2['valuationFrom']
            dataMap.formInline['marketGapLessThan'] = dataMap.ruleForm2['valuationTo']
            let name = '';
            if(dataMap.ruleForm2['valuationFrom'] && dataMap.ruleForm2['valuationTo']){
              name =  dataMap.ruleForm2['valuationFrom'] + ' - ' + dataMap.ruleForm2['valuationTo']
            }else {
              if(dataMap.ruleForm2['valuationFrom'] && !dataMap.ruleForm2['valuationTo']){
                name = `≥${dataMap.ruleForm2['valuationFrom']}`
              }else if(!dataMap.ruleForm2['valuationFrom'] && dataMap.ruleForm2['valuationTo']){
                name = `<${dataMap.ruleForm2['valuationTo']}`
              }
            }
            dataMap.formInline['aa'] = name
          }
      })
      
    }



  
    return {
      ...toRefs(dataMap),
      refTab,
      pagin,
      fetchData,
      onSearch,
      onClearForm,
      handleChange,
      swichItem,
      handleIndustryTab,
      handleMoreClick,
      sortChange,
      handleRowClick,
      blurHandler,
      HandleRowTime,
      fetchSearchData,
      selectedSearch,
      submitForm,
      dicts,
      myForm2,
      blurHandler2,
      fetchSearchData2,
      selectedSearch2,
      moment
    }
  }
})
