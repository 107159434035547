<template>
  <div class="app-container" :class="from" @click="visible = false">
    <!-- <breadcrumb @getStockInfo='getStockInfo' v-if="!from"></breadcrumb> -->
    <div class="bg">
      <CTitle :mode='12' title="机构调研查询" @changeTips='dialogVisible2 = true' v-if="!from"></CTitle>
      <LineChartN 
        :chartData='echartsData' 
        id='scyjgdybj11' 
        style="margin-top:20px" 
        v-if="industryActive != -1 && echartsData.length"
      />
      <div class="navList" v-if="!from">
        <div class="list">
          <div class="des">行业</div>
          <div class="ans">
            <ul>
              <li :class="industryActive === -1 ? 'active' : ''" @click="handleIndustryTab(-1)">不限</li>
              <li 
                :class="industryActive === idx ? 'active' : ''"
                @click="handleIndustryTab(idx, item)"
                v-for="(item,idx) in industryList"
                :key="idx">
                {{item}}
              </li>
              <li v-if='industryLastText' :class="industryActive === -2 ? 'active' : ''" @click="handleIndustryTab(-2, industryLastText)">{{industryLastText}}</li>
              <li class="more">
                <div>
                  <el-popover
                    :width="600"
                    trigger="click"
                    popper-class='c-popover-list'
                    :visible='visible'
                  >
                    <div class="more-list">
                      <div 
                        :key="idx"
                        v-for="(item,idx) in industryMoreList"
                        >
                        <span class="letter">{{idx}}</span>
                        <span class="letter-ans" 
                          @click="handleMoreClick(innnerItem)"
                          :key="innerIdx"
                          v-for="(innnerItem,innerIdx) in item">
                          {{innnerItem}}
                        </span>
                      </div>
                    </div>
                    <template #reference>
                      <span @click="visible = !visible">更多</span>
                    </template>
                  </el-popover>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-row>
          <el-form-item label="证券简称" v-if="!from">
              <!--  -->
            <el-input 
              size="mini"
              v-model="formInline.stockCode" 
              placeholder="输入股票代码或名称"
              @input="fetchSearchData"
              @focus='fetchSearchData'
              @blur='blurHandler'
              />
            <div class="search_box" :class="searchList.length>0?'showsearch_box':''" catchtap="noclose">
              <div class="search_box_list_box" scroll-y enhanced='{{true}}'  binddragging='binddragging1'>
                <div class="search_box_list">
                  <div class="search_box_item"  v-for="(item,index) in searchList" :key="index" @click.native="selectedSearch(item)">
                    <div class="search_box_item_box">
                      <div class="search_box_item_left">
                        <div class="search_box_item_left_text">{{item.name}}</div>
                        <div class="search_box_item_left_num">{{item.code}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="调研机构">
            <el-input
              size="mini"
              v-model="formInline.orgName" 
              placeholder="输入机构名称" 
              @input="fetchSearchData2(false)"
              @focus='fetchSearchData2(true)'
              @blur='blurHandler2'
            />
            <div class="search_box" :class="searchList2.length>0?'showsearch_box':''" catchtap="noclose">
              <div class="search_box_list_box" scroll-y enhanced='{{true}}'  binddragging='binddragging1'>
                <div class="search_box_list">
                  <div class="search_box_item"  v-for="(item,index) in searchList2" :key="index" @click.native="selectedSearch2(item)">
                    <div class="search_box_item_box">
                      <div class="search_box_item_left">
                        <div class="search_box_item_left_text">{{item.name}}</div>
                        <div class="search_box_item_left_num">{{item.code}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="时间" class="time-item">
            <div class="tab">
              <ul>
                <li :class="dstxActive === idx ? 'active' : ''" @click="swichItem(idx)" v-for="(item,idx) in dicts.timeSelection" :key='idx'>{{item.label}}</li>
                <li :class="dstxActive === 5 ? 'active' : ''" @click="swichItem(5)">自定义</li>
              </ul>
            </div>
          </el-form-item>
          <el-form-item v-if="showTimeSelect" class="time-item">
            <el-date-picker
              size="mini"
              class="date-picker"
              unlink-panels
              v-model="selectTime"
              type="daterange"
              value="YYYY-MM"
              value-format="yyyy-MM-dd HH:mm"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              @change='getTime'
            />
          </el-form-item>
        </el-row>  
        <el-form-item label="市值规模" >
          <el-popover
              placement="bottom"
              width="400"
              trigger="click"
              :visible="industryVisible"
              >
              <el-form size="small" :model="ruleForm2" :rules="rules" ref="myForm2" label-width="80px" class="demo-ruleForm">
                  <el-form-item label="大于等于" prop="valuationFrom">
                      <el-input v-model="ruleForm2.valuationFrom" style="width:200px;margin-right:10px"></el-input>
                      <span>亿</span>
                  </el-form-item>
                  <el-form-item label="小于等于" prop="valuationTo">
                      <el-input v-model="ruleForm2.valuationTo" style="width:200px;margin-right:10px"></el-input>
                      <span>亿</span>
                  </el-form-item>
                  <div class="c-custom-tips">
                      <span>最多精确到小数点后面两位</span>
                  </div>
                  <el-form-item style="margin-bottom:0px;text-algin:right;">
                      <el-button size="mini" @click="industryVisible = false">取消</el-button>
                      <el-button size="mini" type="primary" @click="submitForm">保存</el-button>
                  </el-form-item>
              </el-form>
              <template #reference>
                  <el-input size="mini" v-model="formInline.aa" readonly @click="industryVisible = true" placeholder="自定义" />
                  <!-- <li :class="industryActiveKey === 5 ? 'is-active': ''" @click="showIndustryPopover">自定义</li> -->
              </template>
          </el-popover>
        </el-form-item>
        <el-form-item style="padding-left:25px">
          <el-checkbox-group v-model="formInline.isPointOrg">
            <el-checkbox label="只看重点机构" />
          </el-checkbox-group>
        </el-form-item>
        <el-form-item>
          <el-checkbox-group v-model="formInline.isFirstFollow">
            <el-checkbox label="首次覆盖" />
          </el-checkbox-group>
        </el-form-item>
        <!-- <el-form-item label="时间">
          <el-select v-model="formInline.region" @change="handleChange" popper-class='c-el-select'>
            <el-option
              v-for="item in dicts.month"
              :key="item.value"
              :label="item.label"
              :value="item"
            />
          </el-select>
        </el-form-item>
        <el-form-item style="width:350px" v-if="formInline.region.value === 4">
          <el-date-picker
            unlink-panels
            v-model="formInline.time"
            type="daterange"
            value="YYYY-MM"
            value-format="YYYY-MM-DD HH:mm:ss"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
          />
        </el-form-item> -->
      </el-form>
      
      <div class="search-btn">
        <div @click="onClearForm">清空所有条件</div>
        <div @click="onSearch">查询</div>
      </div>
    </div>

    <div class="bg bg-1" v-loading='loading'>
      <!-- <CTitle :mode='13' :title="selectedText + '行业：'" :titleData='{num:stockList1.page?.totalSize}' v-if="!from && selectedText"></CTitle> -->
      <div class="tit" v-if="stockList1.head && Object.keys(stockList1.head).length">
        {{stockList1.head.industry ? stockList1.head.industry + '：' : ''}}<span class="red">{{stockList1.head.stockNumber}}</span>家被<span class="red">{{stockList1.head.orgNumber}}</span><template v-if="stockList1.head.orgNumber && typeof stockList1.head.orgNumber === 'number'">家机构</template>调研<span class="red">{{stockList1.head.researchCount}}</span>次
      </div>
      <el-table
        class="table-style-3"
        :class="!from ? 'hand' : ''"
        :data="stockList1.page ? stockList1.page.list : stockList1.list"
        border
        style="width: 100%"
        :default-sort ="{prop:'surveyDate',order:'descending'}"
        @sort-change="sortChange"
        @row-click="handleRowClick"
        ref='refTab'
      >
        <el-table-column
          prop="stockCode"
          align="center"
          label="股票代码"
        >
          <template #default='{row}'>
            {{row.stockCode.split('.')[0]}}
          </template>
        </el-table-column>
        <el-table-column
          prop="stockName"
          align="center"
          label="股票名称"
        />
        <el-table-column
          prop="currentPrice"
          align="center"
          label="最新价"
        >
          <template #default='{row}'>
            <div class="red">
              {{row.currentPrice}}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="incr"
          align="center"
          label="涨跌幅"
        >
          <template #default='{row}'>
            <div v-dClass='row.incr'>
              {{row.incr > 0 ? '+' : '' }}{{row.incr}}%
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="researchCount"
          align="center"
          label="机构调研总次数"
          sortable="custom"
        >
          <template #default='{row}'>
            <div class="line1">
              {{row.researchCount ?? '-'}}
            </div>
          </template>
        </el-table-column> -->
          <!-- v-if="!formInline.isPointOrg.length && !formInline.orgName" -->
        <el-table-column
          prop="orgResearchNumber"
          align="center"
          label="调研机构家数"
          sortable="custom"
          v-if="isShowOrgResearchNumber"
        >
          <template #default='{row}'>
            <div class="line1">
              {{row.orgResearchNumber}}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="focusOrgResearchNumber"
          align="center"
          label="重点机构家数"
          sortable="custom"
          v-if="!formInline.orgName"
        >
          <template #default='{row}'>
            <div class="line1">
              {{row.focusOrgResearchNumber}}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="industryName"
          align="center"
          label="所属行业"
          v-if="stockList1.head && !stockList1.head.industry"
        />
        <el-table-column
          prop="surveyDate"
          align="center"
          label="调研日期"
          sortable="custom"
        >
          <template #default='{row}'>
            {{row.surveyDate}}
          </template>
        </el-table-column>
        <el-table-column
          prop="day1Change"
          align="center"
          label="次日涨幅"
          sortable="custom"
        >
          <template #default='{row}'>
            <div class="line1" v-dClass='row.day1Change' v-if="row.day1Change">
              {{row.day1Change > 0 ? '+' : '' }}{{row.day1Change}}%
            </div>
            <div v-else-if="row.day1Change === 0">
              +{{`${row.day1Change}.00`}}%
            </div>
            <div v-else>-</div>
          </template>
        </el-table-column>
        <el-table-column
          width='110'
          prop="day5Change"
          align="center"
          label="5日涨幅"
          sortable="custom"
        >
          <template #default='{row}'>
            <div class="line1" v-dClass='row.day5Change' v-if="row.day5Change">
              {{row.day5Change > 0 ? '+' : '' }}{{row.day5Change}}%
            </div>
            <div v-else>-</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="day10Change"
          align="center"
          label="10日涨幅"
          sortable="custom"
        >
          <template #default='{row}'>
            <div class="line1" v-dClass='row.day10Change' v-if="row.day10Change">
              {{row.day10Change > 0 ? '+' : '' }}{{row.day10Change}}%
            </div>
            <div v-else>-</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="day20Change"
          align="center"
          label="20日涨幅"
          sortable="custom"
        >
          <template #default='{row}'>
            <div class="line1" v-dClass='row.day20Change' v-if="row.day20Change">
              {{row.day20Change > 0 ? '+' : '' }}{{row.day20Change}}%
            </div>
            <div v-else>-</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="customIncome"
          align="center"
          label="自定义区间收益"
        >
          <template #default='{row}'>
            <div class="line1" v-dClass='row.customIncome'>
              {{row.customIncome ?? '-'}}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="自定义区间"
        >
          <template #default='{row}'>
            <div @click.stop>
              <div class="blue" v-if="!row.time">选择</div>
              <div class="blue" v-else>{{ moment(row.time).format('YYYY-MM-DD')}}</div>
              <el-date-picker
                class="table-picker"
                unlink-panels
                v-model="row.time"
                type="date"
                value-format="yyyy-MM-dd"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                @change='HandleRowTime(row)'
              />
            </div>
          </template> 
        </el-table-column>
      </el-table>
      
      <CPagination ref='pagin' :pageData='stockList1.page ?? stockList1' @fetchData='fetchData'/>
    </div>
  </div>
</template>

<script lang="ts">

import { defineComponent, reactive, toRefs, ref } from 'vue'
import breadcrumb from '@/components/breadcrumb/Index.vue'
import CTitle from "@/components/title/Index.vue"
import CPagination from "@/components/pagination/Index.vue"
import LineChartN from '@/components/charts/LineChartN.vue'

import { useRoute  } from "vue-router";

import { getSurveyStockDetail, getOrgIndustryBoardList, getOrgIndustryBoardMore, getSurveyOrgList, getSurveyDailyCount, getReportCalculateGains } from '@/apis/research'
import { getStockQuery, getSurveyOrg } from '@/apis/home'
import dicts from '@/utils/dicts'
import moment from 'moment';

interface IComponent{
    changeCurPage?:Function
}
export default defineComponent({
  components: {
    CTitle,
    breadcrumb,
    CPagination,
    LineChartN
  },
  props:{
    from: {
      type: String
    }
  },
  setup(props) {
    const route = useRoute();
    const stockCode = route.query.code;
    const orgName = route.query.orgName;
    const isFirstFollow = Number(route.query.isFirstFollow) === 1;
    const section = route.query.section as any;
    const pagin = ref<InstanceType<typeof CPagination>>() 
    const refTab = ref(null)

    const dataMap = reactive({
      loading: true,
      visible: false,
      // selectedText: '',
      selectedIndustry: '',
      industryActive: -1,
      formInlineinit: {
        timeLevel: '1w',
        industryName:'',//行业名称
        orgName: '',//机构名称
        region: '',
        isFirstFollow: [],
        isPointOrg: [],
        time: []
      },
      formInline: {
        // stockCode:'',
        timeLevel: '1w',
        industryName:route.query.industryName,//行业名称
        orgName: orgName ?? '',//机构名称
        region: {},
        isFirstFollow: isFirstFollow ? ['首次覆盖'] : [],
        isPointOrg: route['query']['isPointOrg'] && route['query']['isPointOrg'] !== '0' ? ['只看重点机构'] : [],
        time: []
      },
      stockList1: {industryName:''},
      industryList: [],
      industryMoreList: [],
      echartsData: [],
      industryLastText: '',
      searchList: [],
      searchList2: [],

      dstxActive  : 0,
      showTimeSelect: false,
      selectTime: ['',''],

      industryVisible: false,
      ruleForm2: {
          valuationFrom: '',
          valuationTo: ''
      },
      rules:  {
          valuationFrom: [
              { pattern: /^(0|([1-9][0-9]*))(\.[\d]{2})?$/, message: '请输入正确的值' }
          ],
          valuationTo: [
              { pattern: /^(0|([1-9][0-9]*))(\.[\d]{2})?$/, message: '请输入正确的值' }
          ]
      },
      sortParams: {
        order: 'DESC',
        orderField: 'surveyDate'
      },

      isShowOrgResearchNumber: true
    })

    if(section){
      const newData = section.split(',');
      dataMap.formInline['marketCapGreatThan'] = newData[0]
      dataMap.formInline['marketGapLessThan'] = newData[1]
      let name = '';
      if(newData[0] && newData[1]){
        name =  newData[0] + ' - ' + newData[1]
      }else {
        if(newData[0] && !newData[1]){
          name = `≥${newData[0]}`
        }else if(!newData[0] && newData[1]){
          name = `<${newData[1]}`
        }
      }
      dataMap.formInline['aa'] = name
    }
    

    if( route.query.time ){
      dataMap.formInline.region = dicts.month[Number(route.query.time)]
      // console.log(9909, dicts.month[Number(route.query.time)])
      dataMap.formInline['timeLevel'] = dicts.month[Number(route.query.time)]!.timeLevel
    }

    if( route.query.stockCode ){
      dataMap.formInline['stockCode'] = route.query.stockCode
    }
    // dataMap.formInline['timeLevel'] = '1w'

    //图表
    const getChartData = async () => {
      if( !dataMap.formInline.industryName ){
        return false
      }
      let params = {}

      //如果是自定义时间
      if( dataMap.showTimeSelect ){
        params = {
          startTime: dataMap.formInline['startTime'],
          endTime: dataMap.formInline['endTime'],
        }
      }else{
        params = {
          timeLevel: dataMap.formInline['timeLevel'],
        }
      }
      let data3 = await getSurveyDailyCount({ 
        industry: dataMap.formInline.industryName,
        // referCode: route.query.code,  
        ...params
      })
      let data3Copy =  JSON.parse(JSON.stringify((data3 as any).data))
      data3Copy.map((item:any)=>{
        item.referValue = item.referValue ? item.referValue : 0
        item.orgResearchCount = item.orgResearchCount ? item.orgResearchCount : 0
        item.orgResearchNumber = item.orgResearchNumber ? item.orgResearchNumber : 0
        item.stockNumber = item.stockNumber ? item.stockNumber : 0
      })
      dataMap.echartsData = data3Copy
    }

    //获取行业列表
    const fetchList = async () => {
     
      let data1 = await getOrgIndustryBoardList({ })
      dataMap.industryList = (data1 as any).data

      let data2 = await getOrgIndustryBoardMore({ })
      dataMap.industryMoreList = (data2 as any).data

      

      let name = route.query.industryName
      if( !props.from && name ){

        //寻找行业列表下标
        const idx = dataMap.industryList.findIndex(item => item === name)
        
        //行业列表默认选中
        if( idx !== -1 ){
          dataMap.industryActive = idx
        }else{
          //行业列表最后一个默认选中
          dataMap.industryActive = -2
          dataMap.industryLastText = String(name);
          // dataMap.selectedText = String(name);
        }
      }
      // industryMoreList
    }
    fetchList()

    const fetchData = async (_parms:any) => {
      dataMap.loading = true

      dataMap.formInline = {...dataMap.formInline, order: dataMap.sortParams.order, orderField:dataMap.sortParams.orderField, ..._parms}

      const newForm = JSON.parse(JSON.stringify(dataMap.formInline))
      //如果是自定义时间
      // if( newForm.region['value'] === 4 ){
      delete newForm['time']
      // }
      delete newForm['region']


      let data1 = props['from'] ? await getSurveyStockDetail({ 
        stockCode,
        ...newForm,
        isPointOrg: dataMap.formInline.isPointOrg.length ? 1 : 0,
        isFirstFollow: dataMap.formInline.isFirstFollow.length ? 1 : 0,
      }) : await getSurveyOrgList({ 
        stockCode,
        ...newForm,
        isPointOrg: dataMap.formInline.isPointOrg.length ? 1 : 0,
        isFirstFollow: dataMap.formInline.isFirstFollow.length ? 1 : 0,
      })
      
      dataMap.stockList1 = (data1 as any).data
      
      if( !props.from ){
        if( dataMap.industryActive === -1 ){
          // dataMap.selectedText = ''
          dataMap['formInline']['industryName'] = ''
        }else{
          // dataMap.selectedText = dataMap.selectedIndustry || dataMap.industryLastText
          dataMap['formInline']['industryName'] = dataMap.selectedIndustry || dataMap.industryLastText
        }
      }

      dataMap.loading = false
    }
    
    //搜索按钮
    const onSearch = async (_parms:any) => {
      //自定义时间
      if( dataMap.showTimeSelect ){
        // delete time
        
        dataMap.formInline['startTime'] = window.moment(dataMap.selectTime[0]).format('x')
        dataMap.formInline['endTime'] = window.moment(dataMap.selectTime[1]).format('x')
      }

      if( !dataMap.formInline.isPointOrg.length && !dataMap.formInline.orgName ){
        dataMap.isShowOrgResearchNumber = true
      }else{
        dataMap.isShowOrgResearchNumber = false
      }

      getChartData()
      // moment(this.dialogcontent.kssj, 'YYYY-MM-DD kk:mm:ss').format('x')


      // fetchData({});
      if( pagin && pagin.value ){
        pagin.value!.changeCurPage(1)
      }
    }

    //清空
    const onClearForm = async () => {
      dataMap.formInline = {
        pageNum: dataMap.formInline['pageNum'],
        pageSize: dataMap.formInline['pageSize'],
        ...JSON.parse(JSON.stringify(dataMap.formInlineinit))
      }
      dataMap.industryActive = -1
      // dataMap.selectedText = ''
      
      swichItem(0)

      if( refTab ){
        (refTab as any).value.sort('surveyDate','descending')
      }

      if( pagin && pagin.value ){
        pagin.value!.changeCurPage(1)
      }

    }

    //新版时间tab选择
    const swichItem = async (idx: any) => {
        dataMap.dstxActive = idx
        dataMap.showTimeSelect = idx === 5 ? true : false
        if( idx === 5 ){
          // delete dataMap.formInline['timeLevel']
          dataMap.formInline['timeLevel'] = ''
        }else{
          dataMap.formInline['timeLevel'] = dicts.timeSelection[idx].timeLevel
        }
    }

    if( route.query.timeLevel ){
      swichItem(Number(route.query.timeLevel))

      //自定义时间
      if( dataMap.showTimeSelect ){
        const beginDate = `${route.query.beginDate ?? ''}`;
        const endDate = `${route.query.endDate ?? ''}`;
        dataMap.formInline['startTime'] = beginDate ? moment(beginDate).format('x') : ''
        dataMap.formInline['endTime'] = endDate ? moment(endDate).format('x') : ''
        dataMap['selectTime'] = [String(route.query.beginDate), String(route.query.endDate)]
      }else{
        dataMap.selectTime = []
      }
      getChartData()
    }else{
      dataMap.selectTime = []
      getChartData()
    }

    //时间下拉框
    const handleChange = (obj:any) => {
      const { timeLevel } = obj
      if( obj.value !== 4 ){
        //fetchData({timeLevel});
        dataMap.formInline['timeLevel'] = timeLevel
        // delete dataMap.formInline['time']
      }else{
        dataMap.formInline['timeLevel'] = ''
      }
    }

    //行业选择
    const handleIndustryTab = (idx:any, item:any) => {
      if( idx === -1 ){
        dataMap.echartsData = []
      }
      dataMap['industryActive'] = idx
      dataMap.formInline.industryName = item
      // dataMap['formInline']['industry'] = item
      dataMap.selectedIndustry = item
    }

    //行业更多选择
    const handleMoreClick = (item:any) => {
      // dataMap['formInline']['industry'] = item
      dataMap.formInline.industryName = item
      dataMap.industryLastText = item
      dataMap.selectedIndustry = item
      dataMap.industryActive = -2
      dataMap.visible = false
    }

    //日期排序
    const sortChange = (column:any) => {
      
      let order = column.order === "ascending" ? 'ASC' : 'DESC';
      // let orderField = 
      // getTableData2(column["prop"], isAsc);
      if( column['prop'] ){
        dataMap.sortParams = {
          order,
          orderField: column["prop"]
        }
        fetchData({});
      }else{
        dataMap.sortParams = {
          order: '',
          orderField: ''
        }
        delete dataMap.formInline['order']
        delete dataMap.formInline['orderField']
        fetchData({});
      }
    }

    const handleRowClick = (column:any) => {
        const beginDate = window.moment(dataMap.selectTime[0]).format('YYYY-MM-DD')
        const endDate = window.moment(dataMap.selectTime[1]).format('YYYY-MM-DD')
        window.open(`/investment/research/detail?code=${column.stockCode}&timeLevel=${dataMap.dstxActive}&beginDate=${beginDate}&endDate=${endDate}`)
    }


    const blurHandler = (res:any) => {
      setTimeout(()=>{
        dataMap.searchList = []
      },200)
    }
    let timer:any = null;
    const fetchSearchData = () => {
      if( timer ){
        clearTimeout(timer)
      }
      timer = setTimeout(async ()=>{
        let data = await getStockQuery({ keyword: dataMap.formInline['stockCode'], limit: 20})
        let aaa = (data as any).data;
        // aaa = aaa.filter((item:any)=>item.state !== 1 && item.state !== 4)
        dataMap.searchList = aaa;
      },500)
      
    }

    const selectedSearch = (item:any) => {
      dataMap.formInline['stockCode'] = item.name
    }

     const blurHandler2 = (res:any) => {
      focusState = false
      setTimeout(()=>{
        dataMap.searchList2 = []
      },200)
    }
    let timer2:any = null;
    let focusState:any = false;
    const fetchSearchData2 = (isFocus:any) => {
      if( timer2 ){
        clearTimeout(timer2)
      }
      if( isFocus ){
        focusState = true
      }
      timer2 = setTimeout(async ()=>{
        let data = await getSurveyOrg({ keyword: dataMap.formInline['orgName'], limit: 20})
        let aaa = (data as any).data;
        if( focusState ){
          dataMap.searchList2 = aaa;
        }
      },500)
      
    }
    const selectedSearch2 = (item:any) => {
      dataMap.formInline['orgName'] = item.name
    }

    //表格时间选择
    const HandleRowTime = async (item:any) => {
      let data2 = await getReportCalculateGains({ 
        stockCode: item.stockCode,
        beginDate: item.surveyDate + ' 23:59:59',
        endDate:   window.moment(item.time).format('YYYY-MM-DD HH:mm:ss')
      })
      item.customIncome = (data2 as any).data
      // dataMap.industryMoreList = (data2 as any).data
    }

    //市值规模
    const myForm2 = ref(null);
    const submitForm = async () => {
      let { validate } = myForm2.value as any;
      validate((valid:any) => {
          if (valid) {
            dataMap['industryVisible'] = false
            dataMap.formInline['marketCapGreatThan'] = dataMap.ruleForm2['valuationFrom']
            dataMap.formInline['marketGapLessThan'] = dataMap.ruleForm2['valuationTo']
            let name = '';
            if(dataMap.ruleForm2['valuationFrom'] && dataMap.ruleForm2['valuationTo']){
              name =  dataMap.ruleForm2['valuationFrom'] + ' - ' + dataMap.ruleForm2['valuationTo']
            }else {
              if(dataMap.ruleForm2['valuationFrom'] && !dataMap.ruleForm2['valuationTo']){
                name = `≥${dataMap.ruleForm2['valuationFrom']}`
              }else if(!dataMap.ruleForm2['valuationFrom'] && dataMap.ruleForm2['valuationTo']){
                name = `<${dataMap.ruleForm2['valuationTo']}`
              }
            }
            dataMap.formInline['aa'] = name
          }
      })
      
    }



  
    return {
      ...toRefs(dataMap),
      refTab,
      pagin,
      fetchData,
      onSearch,
      onClearForm,
      handleChange,
      swichItem,
      handleIndustryTab,
      handleMoreClick,
      sortChange,
      handleRowClick,
      blurHandler,
      HandleRowTime,
      fetchSearchData,
      selectedSearch,
      submitForm,
      dicts,
      myForm2,
      blurHandler2,
      fetchSearchData2,
      selectedSearch2,
      moment
    }
  }
})
</script>
<style lang='scss' scoped>
@import '@/styles/searchForm.scss';
.app-container{
  margin-top: 50px;
  &.detail{
    padding: 0;
    .bg{
      padding: 0;
      margin: 0;
    }
    .el-form {
      display: inline-block;
      vertical-align: middle;
      .el-form-item{
        margin-bottom: 0;
      }
    }
    .search-btn{
      display: inline-block;
      div{
        display: inline-block;
      }
    }
  }
}
.navList{
  margin-top: 27px;
  .des{
    width: 85px!important;
  }
}
.demo-form-inline{
  margin-top: 0;
  ::v-deep .el-form-item__label{
    width: 85px!important;
  }
}

.bg-1{
  .tit{
    font-size: 20px;
    font-weight: 500;
    color: #333333;
    .red{
      font-size: 24px;
    }
  }
}


.search_box{
    width: 100%;
    background: #fff;
    max-height: 670px;
    overflow-y: auto;
    position: absolute;
    top: 50px;
    left: 0;
    z-index: 200;
    // padding: 0 10px;
    display: none;
    /* padding-top: 10px; */
}
.showsearch_box{
    display: block;
}
.search_box_list_box{
    // max-height: 670px;
    box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.2);
    padding-top: 20px;
    .search_box_list{
      overflow-x: hidden;
    }
}
.search_box_item{
    padding: 0 8px;
    margin-bottom: 8px;
    cursor: pointer;
    &.disabled{
      cursor: context-menu;
      .search_box_item_right{
        background: #ccc;
      }
    }
}
.search_box_item_box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    // height: 57px;
    border-bottom: 2px solid #F4F4F4;
}
.search_box_item_right{
    width: 55px !important;
    height: 25px;
    padding: 0;
    margin-right: 0 !important;
    line-height: 25px;
    border-radius: 12px;
    background: #FF423A;
    font-size: 14px;
    text-align: center;
    color: #fff;
    
}
.search_box_item_left_text{
    font-size: 14px;
    color: RGBA(66, 66, 66, 1);
    line-height: 14px;
}
.search_box_item_left_num{
  padding-bottom: 4px;
    font-size: 12px;
    line-height: 20px;
    color:RGBA(170, 170, 170, 1);
    margin-top: 3px;
}

.tab{
    display: inline-block;
    ul{
      display: flex;
      // border: 1px solid #DDDDDD;
      border-radius: 4px;
      overflow: hidden;
      // font-size: 0;
      li{
        width: 52px;
        height: 32px;
        line-height: 32px;
        cursor: pointer;
        background: #FFFFFF;
        text-align: center;
        // border-right: 1px solid #DDDDDD;
        color: #666666;
        border: 1px solid #DDDDDD;
        border-right: none;
        &.active{
          color: #fff;
          background: #386FF2;
          border-color: transparent;
          // border-top: transparent;
          // border-bottom: transparent;
        }
        &:last-child{
          // border: 0;
          border-right: 1px solid #DDDDDD;
          border-radius: 0 4px 4px 0;
        }
        &:first-child{
          // border: 0;
          // border-right: 1px solid #DDDDDD;
          border-radius: 4px 0 0 4px;
        }
      }
    }
}

.time-item{
  ::v-deep .el-form-item__content{
    width: auto!important;
  }
}

.date-picker{
  width: 220px !important;
}

.table-picker{
  opacity: 0;
  width: 100%;
  height: 20px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  *{
    cursor: pointer;
  }
}
</style>
