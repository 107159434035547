<template>
<div>
  <div
    :id="id"
    :class="className"
    :style="{height: height, width: width}"
  />
</div>
</template>

<script lang="ts">
import {
  defineComponent,
  onActivated,
  onBeforeUnmount,
  onDeactivated,
  onMounted,
  nextTick,
  watch,
  reactive,
  toRefs
} from 'vue'
import { init, EChartsOption } from 'echarts'
import resize from '@/components/charts/mixins/resize'
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '350px',
      required: true
    },
    chartData: {
      type: Object,
      default: {}
    },
    selectedTime: {
      type: String || Number,
      default: ''
    },
    titleTag: {
      type: String || Number,
      default: ''
    },
    styleStatus: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()
    const dataMap = reactive({
      count: 0
    })

    watch(props, (newName, oldName) => {
      initChart()
    });

    const initChart = () => {
      const xAxisList = Array.from(props.chartData.data, ({ name }) => name);
      const yAxisLeftList = Array.from(props.chartData.data, ({ value }) => value);
      const borderTypeList = Array.from(props.chartData.data, ({ boardType }) => boardType);
      const integerData: any = [];
      yAxisLeftList.map((item) => {
        let obj = {
          value: item,
          itemStyle: {
            color: item > 0 ? '#F74E4F' : '#5CBC7C'
          }
        }
        integerData.push(obj)
      })
      const barChart = init(document.getElementById(props.id) as HTMLDivElement)
      barChart.setOption({
        grid: {
          top: '30px',
          left: '20px',
          right: '0%',
          bottom: '30px',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          formatter:function(params: any) {
            let text = `<div style="display: flex; justify-content: space-between"><span>${ params[0].axisValue }</span><span></span></div>`;
            for (let i = 0; i < params.length; i++) {
              const item = params[i]
              text += `<div style="display: flex; justify-content: space-between">
                      <div style="display: flex;align-items: center">
                        <span style='width:8px;height:8px;display:inline-block;border-radius:50%;margin-right:3px;background: ${item.color}'></span>
                        <span style='display:inline-block;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;line-height: 17px;'>数值：</span>
                        </div>
                        <span style='display:inline-block;font-size: 14px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #333333;line-height: 20px;'>${ item.value }${props.titleTag}</span>
                    </div>`
            }
            return text
          }
        },
        dataZoom: [
          {
            type: 'slider',
            start: 0,
            end: borderTypeList[0] === 'swl2' ? props.styleStatus ? 100 :  20 : 100,
            realtime: true,
            handleSize: 0,
            height: 8,
            bottom: 0,
            fillerColor: "rgba(17, 100, 210, 0.42)", // 滚动条颜色
            borderColor: "rgba(17, 100, 210, 0.12)",
            showDetail: false,
          }
        ],
        xAxis: {
          data: xAxisList,
          axisLine: { show: false, onZero: false },
          splitLine: { show: false },
          axisPointer: {
            label: { show: false }
          },
          axisLabel:{
            show: true,
            interval: 0,
            formatter: function(value: any) {
              return value.split("").join("\n")
            }
          },
          triggerEvent: true,
          axisTick: { show: false },
          splitArea: { show: false }
        },
        yAxis: [
          {
            type: 'value',
            name: '单位（' + props.chartData.unit + '）',
            splitLine: { show: true, lineStyle: { type: 'dashed' } },
            axisPointer: {
              type: 'none',
              label: { show: false }
            },
            axisLabel: {
              show: true,
              formatter:function(param: any) {
                return `${param}${props.chartData.unit}`
              }
            }
          }
        ],
        series: [
          {
            name: '北上净买入(亿元)',
            type: 'bar',
            barWidth: 20,
            itemStyle: {
              normal: {
                color: '#F74E4F'
              }
            },
            data: integerData
          },
        ]
      } as EChartsOption)
      chart.value = barChart
      barChart.on('click', (params:any) => {
        if(params.componentType == "xAxis"){
          const corresponding = props.chartData.data.filter((item: any) => item.name == params.value)
          const { boardType } = corresponding[0]
          if (boardType == 'swl1') {
            context.emit('chartsClick', params.value, true)
          } else {
            if (dataMap.count >= 1) return false
            dataMap.count++
            window.open(`/investment/northboundFunds/industry?code=${params.value}&boardType=${boardType}`)
            setTimeout(() => {
              dataMap.count = 0
            }, 1000)
          }
        }else if (params.componentType == 'series'){
          const corresponding = props.chartData.data.filter((item: any) => item.name == params.name)
          const { boardType } = corresponding[0]
          if (dataMap.count >= 1) return false
          dataMap.count++
          window.open(`/investment/northboundFunds/industry?code=${params.name}&boardType=${boardType}`)
          setTimeout(() => {
            dataMap.count = 0
          }, 1000)
        }
      })
    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {
      ...toRefs(dataMap),
    }
  }
})
</script>
