<template>
  <div>
    <CTitle :mode='6' title="机构调研" :class="isMobile ? '' : 'mt50'" v-if="!hide"></CTitle>
    <BarChartH id='chart-jgdy' width='100%' height='160px' :chartData='stockList8' :isMobile='true' v-if="isMobile"></BarChartH>
    <BarChartH id='chart-jgdy' width='550px' :chartData='stockList8' v-else></BarChartH>
  </div>
</template>

<script lang="ts">

import { defineComponent, reactive, toRefs } from 'vue'
import CTitle from '@/components/title/Index.vue'
import BarChartA from '@/components/charts/BarChartA.vue'
import BarChartH from '@/components/charts/BarChartH.vue'
import { getSurveyOrgRanking } from '@/apis/diagnosis'
import {
  getSurveyOrgRankingMoblie
} from "@/apis/diagnosisMoble";
import { useRoute } from 'vue-router'
export default defineComponent({
  props: {
    dataMap: {
      type: Array,
      default: []
    },
    title: {
      type: String,
      default: '机构评级'
    },
    hide: {
      type: Boolean
    },
    isMobile: {
      type: Boolean,
      default: false
    },
  },
  components: {
    CTitle,
    BarChartA,
    BarChartH
  },
  setup(props) {
    // const stockCode = '688018.SH'
    const route = useRoute()
    const stockCode = route.query.code
    const dataMap = reactive({
      ajaxRes: false,
      stockList8: {},
    })
    

    const fetchData = async () => {

      let data8 = props.isMobile ? await getSurveyOrgRankingMoblie({ stockCode: stockCode }) : await getSurveyOrgRanking({ stockCode: stockCode })
      dataMap.stockList8 = (data8 as any).data;

      dataMap.ajaxRes = true;
    }
    fetchData();

    return {
      ...toRefs(dataMap)
    }
  }
})
</script>
<style scoped lang='scss'>

</style>
