<template>
  <div>
    <el-table
      v-loading='isLoading'
      border
      align="center"
      class="table-style-2 cursor"
      :data="stockList.list || []"
      :cell-class-name='addClass'
      :default-sort ="{prop:'rzjmr', order:'descending'}"
      @row-click='goDetail'
      @sort-change="sortChange">
      <el-table-column prop="rankingNo" label="排名"  align="center">
        <template #default='{row}'>
          {{ row.rankingNo }}<br />
          <span v-if="row.rankingChg > 0" v-dClass="row.rankingChg" style="display: flex; align-items: center; justify-content: center">
            {{ row.rankingChg }}
            <i class="el-icon-top" />
          </span>
          <span v-if="row.rankingChg < 0" v-dClass="row.rankingChg" style="display: flex; align-items: center; justify-content: center">
            {{ row.rankingChg }}
            <i class="el-icon-bottom" />
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="个股名称/代码"  align="center">
        <template #default='{row}'>
          <div>{{ row.name }}</div>
          <div>{{ row.code }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="incr" :label="`${timeIncr.text}涨幅`"  align="center">
        <template #default='{row}'>
          <div v-dClass='row.incr'>{{ row.incr || row.incr == 0 ? row.incr.toFixed(2) + '%' : '-' }}</div>
        </template>
      </el-table-column>
      <el-table-column label="融资"  align="center" >
        <el-table-column prop="rzye" label="余额"  align="center" sortable="custom">
          <template #default='{row}'>
            <div>{{ bigNumberTransform(row.rzye) }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="rzyezb" label="余额占流通市值比"  align="center" sortable="custom">
          <template #default='{row}'>
            <div>{{ row.rzyezb ? row.rzyezb.toFixed(2) + '%' : '-'  }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="rzmr" label="买入额(元)"  align="center" sortable="custom">
          <template #default='{row}'>
            <div>{{ bigNumberTransform(row.rzmr)  }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="rzjmr" label="净买入(元) "  align="center" sortable="custom">
          <template #default='{row}'>
            <div>{{ bigNumberTransform(row.rzjmr) }}</div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="融券"  align="center" >
        <el-table-column prop="rqye" label="余额(元)"  align="center" sortable="custom">
          <template #default='{row}'>
            <div>{{ bigNumberTransform(row.rqye) }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="rqmce" label="卖出额"  align="center" sortable="custom">
          <template #default='{row}'>
            <div>{{ bigNumberTransform(row.rqmce) }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="rqjme" label="净卖额"  align="center" sortable="custom" >
          <template #default='{row}'>
            <div>{{ bigNumberTransform(row.rqjme) }}</div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column prop="rzrqye" label="融资融券余额(元)"  align="center" sortable="custom">
        <template #default='{row}'>
          <div>{{ bigNumberTransform(row.rzrqye) }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="turnover" label="换手率"  align="center">
        <template #default='{row}'>
          <div>{{ row.turnover ? row.turnover.toFixed(2) + '%' : '-' }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="vol" label="成交量(股)"  align="center">
        <template #default='{row}'>
          <div>{{ amount(row.vol) }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="amt" label="成交额(元)"  align="center">
        <template #default='{row}'>{{ amount(row.amt) }}</template>
      </el-table-column>
    </el-table>
    <CPagination ref='pagin' :showPageSizes='true' :pageSize='10' :pageData='stockList' @fetchData='fetchData'/>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue'
import CPagination from "@/components/pagination/Index.vue"
import { getMarginStock } from '@/apis/trading'
import { bigNumberTransform } from '@/utils/data-format'
import { useRoute  } from "vue-router";
export default defineComponent({
  components:{
    CPagination,
  },
  props: {
    // stockList : {
    //   type: Object,
    //   default: {}
    // },
  },
  setup() {
    const route = useRoute();
    const dataMap = reactive({
      isLoading: true,
      timeIncr:{},
      stockList: {
        list: []
      }
    })

    let params = {
      pageNum: 1,
      pageSize: 10,
      sortFiled: 'rzjmr',
      boardType: route.query.boardType,
      boardName: route.query.code,
      // boardName: '半导体',
      timeLevel: '',
    }
    const fetchData = async (obj:any) => {
      if( obj && obj.pageNum ) {
        params.pageNum = obj.pageNum
      }
      if( obj && obj.pageSize ) {
        params.pageSize = obj.pageSize
      }
      if( obj && obj.timeLevel ){
        params.timeLevel = obj.timeLevel
      }
      if( !params.timeLevel ){
        return false
      }
      dataMap.isLoading = true
      const stockList = await getMarginStock(params)
      dataMap.stockList = (stockList as any).data;
      dataMap.isLoading = false
    }

    const timeChange = (obj:any) => {
      params.timeLevel = obj.lbValue
      dataMap.timeIncr = obj
      fetchData({})
    }

    const sortChange = (column: any) => {
      let isAsc = column.order === "ascending" ? 'asc' : 'desc';
      params['sortFiled'] = column["prop"]
      params['sort'] = isAsc
      fetchData({})
    };

    const addClass = (column:any) => {
      if( column.column.property === params.sortFiled ){
        return 'sort-bg'
      }
    }

    const amount = (value: any, fixed = 2, unit = '元') => {
      if (unit == '万') {
        value = value * 10000
      } else if (unit == '亿') {
        value = value * 100000000
      }
      if (Math.abs(value / 100000000) >= 1) {
        return (value / 100000000).toFixed(fixed) + '亿'
      }
      if (Math.abs(value / 10_000) >= 1) {
        return (value / 10000).toFixed(fixed) + '万'
      }
      return value
    }

    const goDetail = async (item:any) => {
      window.open(`/investment/marginTrading/shares?name=${item.name}&code=${item.code}`)
    }


    return {
      ...toRefs(dataMap),
      fetchData,
      timeChange,
      addClass,
      sortChange,
      bigNumberTransform,
      amount,
      goDetail
    }
  }
})
</script>
<style scoped lang="scss">

</style>
