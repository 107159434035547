

import { defineComponent, reactive, toRefs, ref } from 'vue'
import CTitle from './components/NavTitle.vue'
import CDate from '@/components/new-title/Index.vue'
import CTab from './components/NavSwitch.vue'
import AllDataTable from './components/AllDataTable.vue'
import ChartA from './chart/ChartA.vue'
// import ChartB from './chart/ChartB.vue'
import ChartC from './chart/ChartC.vue'
import ChartD from './chart/ChartD.vue'
import ChartE from './chart/ChartE.vue'
import { getFixedIncrBoard, getSZFB, getFixedIncrPartIncr } from '@/apis/fixed-increase'

export default defineComponent({
  components: {
    CTitle,
    CDate,
    CTab,
    ChartA,
    // ChartB,
    ChartC,
    ChartD,
    ChartE,
    AllDataTable
  },
  setup() {
    const adt = ref(null)
    const dataMap = reactive({
      initialStatus: 1,
      tabList: [
        {
          id: 1,
          value: 'swl1',
          name: '申万一级'
        },
        {
          id: 2,
          value: 'swl2',
          name: '申万二级'
        }
      ],
      initialSyfbStatus: 1,
      tabSyfbList: [
        {
          id: 1,
          type: 1,
          name: '定增期间涨幅'
        },
        {
          id: 2,
          type: 2,
          name: '定增后120日涨幅'
        }
      ],
      stockListA: [],
      stockListB: [],
      stockListC: {},
      stockListD: [],
      paramA: {},
      paramB: {},
      paramC: {},
      paramD: {},
      
      changeNetTabA: async(param: Object) => {
        dataMap.paramA['name'] = ''
        dataMap.paramA['type'] = param['value']
        dataMap.fetchDataA()
      },
      changeHandleA: async(param: Object) => {
        console.log(param)
        if( param['timeLevel'] === 0 ){
          delete dataMap.paramA['timeLevel']
          dataMap.paramA['customDate'] = param['dateFrom'] + ':' + param['dateTo']
          dataMap.fetchDataA()
        }else{
          delete dataMap.paramA['customDate']
          dataMap.paramA['timeLevel'] = param['timeLevel']
          dataMap.fetchDataA()
        }
      },
      fetchDataA: async() => {
        let list:any = await getFixedIncrBoard(dataMap.paramA)
        let arr:any = []
        list.data.map((item:any)=>{
          if( item.name ){
            arr.push(item)
          }
        })
        if(arr.length <= 5){
          console.log(dataMap.getShowNum())
          const num:any = dataMap.getShowNum()
          for (let index = 0; index < num - arr.length; index++) {
            arr.push({
              name: null,
              fixedIncreaseStock: null,
              type: null
            })
          }
        }
        dataMap.stockListA = arr
      },
      getShowNum: () => {
        const appV3 = (document.getElementById('app-v3') as HTMLDivElement)
        const clientWidth = appV3.clientWidth
        // console.log('vvvvvvvvvvvvv',clientWidth)
        if( clientWidth > 1400 ){
            return 15
        }if( clientWidth > 1300 ){
            return 12
        }else if( clientWidth > 1200 ){
            return 11
        }else if( clientWidth > 1000 ){
            return 9
        }else if( clientWidth < 800 ){
            return 6
        }
      },
      changeHandleB: async(param: Object) => {
        if( param['timeLevel'] === 0 ){
          delete dataMap.paramB['timeLevel']
          dataMap.paramB['customDate'] = param['dateFrom'] + ':' + param['dateTo']
          dataMap.fetchDataB({})
        }else{
          delete dataMap.paramB['customDate']
          dataMap.paramB['timeLevel'] = param['timeLevel']
          dataMap.fetchDataB({})
        }
      },
      fetchDataB: async(param: Object) => {
        // const list = await getFixedIncrType(dataMap.paramB)
        const list = {
          data: [
            {
              name: '123',
              fixedIncreaseStock: '4'
            },
            {
              name: '123',
              fixedIncreaseStock: '4'
            }
          ]
        }
        dataMap.stockListB = (list as any).data;
      },
      changeHandleC: async(param: Object) => {
        if( param['timeLevel'] === 0 ){
          delete dataMap.paramC['timeUnit']
          delete dataMap.paramC['num']
          dataMap.paramC['customDate'] = param['dateFrom'] + ':' + param['dateTo']
          dataMap.fetchDataC({})
        }else{
          delete dataMap.paramC['customDate']
          dataMap.paramC['timeUnit'] = param['timeUnit']
          dataMap.paramC['num'] = param['num']
          dataMap.fetchDataC({})
        }
      },
      fetchDataC: async(param: Object) => {
        const list = await getSZFB(dataMap.paramC)
        console.log('000',list)
        if( list ){
          dataMap.stockListC = (list as any).data;
        }
      },
      changeNetTabB: async(param: Object) => {
        dataMap.paramD['type'] = param['type']
        dataMap.fetchDataD({})
      },
      changeHandleD: async(param: Object) => {
        if( param['timeLevel'] === 0 ){
          delete dataMap.paramD['timeUnit']
          delete dataMap.paramD['num']
          dataMap.paramD['customDate'] = param['dateFrom'] + ':' + param['dateTo']
          dataMap.fetchDataD({})
        }else{
          delete dataMap.paramD['customDate']
          dataMap.paramD['timeUnit'] = param['timeUnit']
          dataMap.paramD['num'] = param['num']
          dataMap.fetchDataD({})
        }
      },
      fetchDataD: async(param: Object) => {
        if( !dataMap.paramD['type'] ){
          dataMap.paramD['type'] = dataMap.tabSyfbList[0].type
        }
        const list = await getFixedIncrPartIncr(dataMap.paramD)
        dataMap.stockListD = (list as any).data;
      },
      chartsClick: async(param: Object, isAjax:Boolean) => {
        // console.log(param)
        if( isAjax ){
          dataMap.paramA['name'] = param
          dataMap.fetchDataA()
        }else{
          (adt.value as any).selectedHy(dataMap.paramA['timeLevel'], param)
          console.log(222,param)
          let box:any = document.querySelector(".investment")
          let scrTop:any = document.querySelector("#dz-all-data")
          box.scrollTop = scrTop.offsetTop 
        }
      },
      szfbClick: async(param: Object) => {
        (adt.value as any).selectedSz(param)
        
        let box:any = document.querySelector(".investment")
        let scrTop:any = document.querySelector("#dz-all-data")
        box.scrollTop = scrTop.offsetTop 
      },


    })
    return { ...toRefs(dataMap),adt }
  }
})
