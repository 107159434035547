export default {
  tabList: [
    {
      label: '1日',
      incrLabel: '1日',
      value: '1d',
      timeUnit: 'DAYS',
      num: 1
    },
    {
      label: '3日',
      incrLabel: '3日',
      value: '3d',
      timeUnit: 'DAYS',
      num: 3
    },
    {
      label: '5日',
      incrLabel: '5日',
      value: '5d',
      timeUnit: 'DAYS',
      num: 5
    },
    {
      label: '10日',
      incrLabel: '10日',
      value: '10d',
      timeUnit: 'DAYS',
      num: 1
    },
    {
      label: '月排行',
      incrLabel: '月',
      value: '1m',
      timeUnit: 'MONTHS',
      num: 1
    },
    {
      label: '季排行',
      incrLabel: '季',
      value: '1q',
      timeUnit: 'QUARTERS',
      num: 1
    },
    {
      label: '年排行',
      incrLabel: '年',
      value: '1y',
      timeUnit: 'YEARS',
      num: 1
    },
    // {
    //   label: '自定义',
    //   type: 'custom',
    //   value: 'z'
    // },
  ],
}
