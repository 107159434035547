
import { reactive, defineComponent, toRefs } from 'vue'
import dicts from '@/utils/dicts'
export default defineComponent({
  props:{
    mode : {
      type : Number,
      default : 1
    },
    activeIdx : {
      type : Number,
      default : 0
    },
    init:{
      type: Boolean,
      default: true
    },
    isCustom:{
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const dataMap = reactive({
      dicts,
      dstxActive: props.activeIdx,
      optionsData: {},
      value1: '',
      showCustom: false,
      swichItem: (idx: any) => {
        dataMap.dstxActive = idx
        if( dataMap.optionsData[idx].type === "custom" ){
          dataMap.showCustom = true
          return false
        }else{
          dataMap.showCustom = false
        }
        context.emit('change', dataMap.optionsData[idx])
      },
    })

    console.log('!props.isCustom',!props.isCustom)

    if( props.mode === 1 ){
      dataMap.optionsData = props.isCustom ? dicts.day : dicts.day.filter((item:any)=>item.type !== 'custom')
    }else if( props.mode === 2 ){
      dataMap.optionsData = props.isCustom ? dicts.week : dicts.week.filter((item:any)=>item.type !== 'custom')
    }

    if( props.init ){
      context.emit('change', dataMap.optionsData[0])
    }

    const handleChange = (val:any) => {
      context.emit('change', {lbValue : `${window.moment(val[0]).format('YYYY-MM-DD')},${ window.moment(val[1]).format('YYYY-MM-DD')}`})
    }

    return { ...toRefs(dataMap),handleChange }
  }
})
