<template>
  <div
    :id="id"
    :class="className"
    :style="{height: height, width: width}"
  />
</template>

<script lang="ts">
import { defineComponent, onActivated, onBeforeUnmount, onDeactivated, onMounted, nextTick, watch } from 'vue'
import resize from './mixins/resize'
import { init, EChartsOption } from 'echarts'
import * as echarts from 'echarts'
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '193px',
      required: true
    },
    chartData: {
      type: Object,
      default: []
    }
  },
  setup(props) {
    watch(props, (newName, oldName) => {
      initChart()
    });
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()
    const initChart = () => {
      if( !props.chartData.length ){
        return false;
      }
      const barChart = init(document.getElementById(props.id) as HTMLDivElement)
      let xData = [];
      let serviesData1 = [];
      let serviesData2 = [];
      let serviesData3 = [];
      let serviesData4 = [];
      for (let index = 0; index < props.chartData.length; index++) {
        xData.push(props.chartData[index].date)
        serviesData1.push(props.chartData[index].referValue)
        serviesData2.push(props.chartData[index].orgResearchCount)
        serviesData3.push(props.chartData[index].orgResearchNumber)
        serviesData4.push(props.chartData[index].stockNumber)
      }
      const copyServies = JSON.parse(JSON.stringify(serviesData1))
      const newSort = copyServies.sort((a:any, b:any) => a - b);
      barChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          icon: 'circle',
          left: '0',
	        // bottom: 30,		//具体像素值或百分比
          data: ['行业指数', '机构调研次数']
        },
        // },
        grid: {
          left: '10px',
          right: '10px',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            // position:'top',
            type: 'category',
            data: xData,
            show: true,
            boundaryGap: false,
            axisLine:{
              lineStyle:{
                color: '#CCCCCC',
                width:1,
              }
            },
            axisTick: { show: false },
          }
        ],
        yAxis: [
          {
            type: 'value',
            
            // splitLine: { show: false },
            // name:"管\n线\n查\n询\n次\n数\n︵\n次\n︶",
            // nameLocation:"center",
            // nameGap:35,
            // nameRotate:0,
            // nameTextStyle:{
            //     fontSize: 16,
            // },
            //默认以千分位显示，不想用的可以在这加一段
            axisLabel: {   //调整左侧Y轴刻度， 直接按对应数据显示
              show: true,
              align:'right',
              // margin: 20,
              // showMinLabel: true,
              // showMaxLabel: true,
              // formatter: function (value:any) {
              //   return value;
              // }
            },
          },
          {
            type: 'value',
            min: Math.floor(newSort[0]),
            splitLine: {
              lineStyle:{
                  color: ['#ccc'],
                  width: 1,
                  type: 'solid'
              }
            },
          }
        ],
        series: [
          
          {
            name: '机构调研次数',
            type: 'line',
            // stack: 'Total',
            // areaStyle: {},
            // emphasis: {
            //   focus: 'series'
            // },
            symbol:'none',
            yAxisIndex: 0,
            data: serviesData2,
            color: '#0086FB',
            areaStyle:{
              normal: {
                color: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [{
                      offset: 0,
                      color: "rgba(0,134,251,0.29)" // 0% 处的颜色
                    }, {
                      offset:  1,
                      color: "rgba(59,127,238,0)" // 100% 处的颜色
                    }],
      
                    }
                  }
            },
          },
          {
            name: '行业指数',
            type: 'line',
            color: '#FE9625',
            // stack: 'Total',
            // areaStyle: {},
            // emphasis: {
            //   focus: 'series'
            // },
            symbol:'none',
            yAxisIndex: 1,
            data: serviesData1
          },
          {
            name: '机构家数',
            type: 'line',
            // stack: 'Total',
            // areaStyle: {},
            // emphasis: {
            //   focus: 'series'
            // },
            symbol:'none',
            yAxisIndex: 1,
            data: serviesData3,
            color: '#0086FB',
            lineStyle:{
              normal: {
                width: 0
              }
            }
          },
          {
            name: '被调研家数',
            type: 'line',
            // stack: 'Total',
            // areaStyle: {},
            // emphasis: {
            //   focus: 'series'
            // },
            symbol:'none',
            yAxisIndex: 1,
            data: serviesData4,
            color: '#0086FB',
            lineStyle:{
              normal: {
                width: 0,
              }
            }
          },
        ]
      } as unknown as EChartsOption)
      chart.value = barChart
    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {

    }
  }
})
</script>

<style lang="scss" scoped>

</style>
