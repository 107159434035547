<template>
  <div class="survey-frequency-warp">
    <el-row>
      <el-col :span="24" >
        <div class="t-title">
          <NewTitle
            title="行业机构调研排行"
            @change="changeHandle3"
            :icon='true'
            @goToList="toIndustryList"
            :isEmitJump="true"
          />
        </div>
        <div class="echarts">
          <BarChartNewA v-if="showBarChartNewA" :chartData='dataList3' :selectedTime='selectedTime' :beginDate='beginDate' :endDate="endDate" />
        </div>
      </el-col>
      <el-col :span="24" style="margin-top:30px;">
        <NewTitle
          title="机构调研频次个股前30"
          @change="changeHandle1"
          :icon='true'
          @goToList="toIndustryList2"
          :isEmitJump="true"
        />
        <el-table
          class="table-style-2 cursor"
          :data="dataList1"
          border
          style="width: 100%"
          @sort-change="sortChange1"
          @row-click="goResearchList"
          :default-sort ="{prop:'researchCount',order:'descending'}"
        >
          <el-table-column
            prop="code"
            align="center"
            label="代码"
          />
          <el-table-column
            prop="name"
            align="center"
            label="股票名称"
          />
          <el-table-column
            prop="totalMarketCap"
            align="center"
            label="总市值(亿)"
            sortable="custom"
          >
            <template #default='{row}'>
              <div>{{row.totalMarketCap || '-'}}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="peTtm"
            align="center"
            label="PE-TTM"
            sortable="custom"
          >
            <template #default='{row}'>
              <div>{{row.peTtm || '-'}}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="researchCount"
            align="center"
            label="机构调研总次数"
            sortable="custom"
          >
          </el-table-column>
          <el-table-column
            prop="orgResearchNumber"
            align="center"
            label="机构调研家数"
            sortable="custom"
          >
          </el-table-column>
          <el-table-column
            prop="focusOrgResearchCount"
            align="center"
            label="重点机构调研总次数"
            sortable="custom"
          >
          </el-table-column>
          <el-table-column
            prop="focusOrgResearchNumber"
            align="center"
            label="重点机构调研家数"
            sortable="custom"
          >
          </el-table-column>
          <el-table-column
            prop="increase"
            align="center"
            label="区间涨幅"
          >
            <template #default='{row}'>
              <div v-dClass='row.increase' v-if="row.increase || row.increase === 0">
                <template v-if="row.increase > 0">+</template>{{row.increase === 0 ? '0.00' : row.increase}}%
              </div>
              <div v-else>-</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="industry"
            align="center"
            label="所属行业"
          >
            <template #default='{row}'>
              <div>{{row.industry || '-'}}</div>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
      <el-col :span="24" style="margin-top:30px;">
        <NewTitle
          title="调研机构前30"
          @change="changeHandle2"
          :icon='true'
          @goToList="toIndustryList3"
          :isEmitJump="true"
        />
        <el-table
          class="table-style-2 cursor"
          :data="dataList2"
          border
          style="width: 100%"
          @sort-change="sortChange2"
          @row-click="goResearchMechanism"
          :default-sort ="{prop:'researchCount',order:'descending'}"
        >
          <el-table-column
            prop="orgName"
            align="center"
            label="机构名称"
          />
          <el-table-column
            prop="orgType"
            align="center"
            label="类型"
          />
          <el-table-column
            prop="researchCount"
            align="center"
            label="调研总次数"
            sortable="custom"
          />
          <el-table-column
            prop="stockCount"
            align="center"
            label="调研个股总数"
            sortable="custom"
          />
          <el-table-column
            prop="shMainBoard"
            align="center"
            label="上证主板(家)"
            sortable="custom"
          />
          <el-table-column
            prop="szMainBoard"
            align="center"
            label="深证主板(家)"
            sortable="custom"
          />
          <el-table-column
            prop="szSTIBoard"
            align="center"
            label="创业板(家)"
            sortable="custom"
          />
          <el-table-column
            prop="shSTIBoard"
            align="center"
            label="科创板(家)"
            sortable="custom"
          />
          <el-table-column
            prop="follows1"
            align="center"
            label="关注度NO.1"
          >
            <template #default='{row}'>
              <div>
                <span>{{row.follows[0] ? fmtFollowsEmptyData(row.follows[0], 0) : '-'}}</span> <br>
                <span>{{row.follows[0] ? fmtFollowsEmptyData(row.follows[0], 1) : '-'}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="follows2"
            align="center"
            label="关注度NO.2"
          >
            <template #default='{row}'>
              <div>
                <span>{{row.follows[1] ? fmtFollowsEmptyData(row.follows[1], 0) : '-'}}</span> <br>
                <span>{{row.follows[1] ? fmtFollowsEmptyData(row.follows[1], 1) : '-'}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="follows3"
            align="center"
            label="关注度NO.3"
          >
            <template #default='{row}'>
              <div>
                <span>{{row.follows[2] ? fmtFollowsEmptyData(row.follows[2], 0) : '-'}}</span> <br>
                <span>{{row.follows[2] ? fmtFollowsEmptyData(row.follows[2], 1) : '-'}}</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>
  
<script lang="ts">

import { defineComponent, reactive, toRefs } from 'vue'
import NewTitle from '@/components/new-title/Index.vue'
import BarChartNewA from '@/components/charts/BarChartNewA.vue'
import { getSurveyRateCount, getSurveyOrgCount, getSurveyIndustryCount } from '@/apis/research'
import moment from 'moment'
import dicts from '@/utils/dicts'

interface Inter {
  order: string | undefined,
  orderField: string | undefined,
}
export default defineComponent({
  components: {
    NewTitle,
    BarChartNewA
  },
  setup() {
    const dataMap = reactive({
      dataList1: [],
      dataList2: [],
      dataList3: [],
      selectedTime: 0 as any,
      beginDate: undefined as any,
      endDate: undefined as any,
      selectedTime1: 0 as any,
      beginDate1: undefined as any,
      endDate1: undefined as any,
      selectedTime2: 0 as any,
      beginDate2: undefined as any,
      endDate2: undefined as any,
      showBarChartNewA: false
    })

    let sortParams1:Inter = reactive({
      order: 'DESC',
      orderField: 'researchCount',
      timeLevel: '1w',
    })
    let sortParams2:Inter = reactive({
      order: 'DESC',
      orderField: 'researchCount',
      timeLevel: '1w'
    })
    
    const changeHandle1 = async (params:any) => {
      if(params.timeLevel !== 0) {
        dataMap.selectedTime1 = (dicts.timeSelection || []).findIndex((item:any) => item.value === params.timeLevel);
      }else {
        dataMap.selectedTime1 = 5;
        dataMap.beginDate1 = params.dateFrom ? params.dateFrom : undefined;
        dataMap.endDate1 = params.dateTo ? params.dateTo : undefined;
      }
      const timeLevelStr = (dicts.timeSelection || []).find((item:any) => item.value === params.timeLevel);
      
      const newParams = {
        timeLevel: timeLevelStr?.timeLevel,
        startTime: params.dateFrom ? moment(params.dateFrom).valueOf() : undefined,
        endTime: params.dateTo ? moment(params.dateTo).valueOf() : undefined,
      }
      const data = await getSurveyRateCount( {...sortParams1, ...newParams})
      dataMap.dataList1 = (data as any).data
    }
    const sortChange1 = (item:any) => {
      const timeLevel = dataMap.selectedTime1 !== 5 ? (dicts.timeSelection || [])[dataMap.selectedTime1].value : 0;
      if( item.prop ){
        sortParams1 = {
          order: item.order === "ascending" ? 'ASC' : 'DESC',
          orderField: item.prop,
        }
        changeHandle1({
          timeLevel: timeLevel,
          dateFrom: dataMap.beginDate1 ? dataMap.beginDate1: undefined,
          dateTo: dataMap.endDate1 ? dataMap.endDate1 : undefined,
        })
      }else{
        delete sortParams1.order
        delete sortParams1.orderField
        changeHandle1({
          timeLevel: timeLevel,
          dateFrom: dataMap.beginDate1 ? dataMap.beginDate1: undefined,
          dateTo: dataMap.endDate1 ? dataMap.endDate1 : undefined,
        })
      }
    }

    const changeHandle2 = async (params:any) => {
      if(params.timeLevel !== 0) {
        dataMap.selectedTime2 = (dicts.timeSelection || []).findIndex((item:any) => item.value === params.timeLevel);
      }else {
        dataMap.selectedTime2 = 5;
        dataMap.beginDate2 = params.dateFrom ? params.dateFrom : undefined;
        dataMap.endDate2 = params.dateTo ? params.dateTo : undefined;
      }
      const timeLevelStr = (dicts.timeSelection || []).find((item:any) => item.value === params.timeLevel);
      const newParams = {
        timeLevel: timeLevelStr?.timeLevel,
        startTime: params.dateFrom ? moment(params.dateFrom).valueOf() : undefined,
        endTime: params.dateTo ? moment(params.dateTo).valueOf() : undefined,
      }

      const data = await getSurveyOrgCount({...sortParams2, ...newParams})
      dataMap.dataList2 = (data as any).data
    }
    
    const sortChange2 = (item:any) => {
      const timeLevel = dataMap.selectedTime2 !== 5 ? (dicts.timeSelection || [])[dataMap.selectedTime2].value : 0;
      if(item.prop){
        sortParams2 = {
          order: item.order === "ascending" ? 'ASC' : 'DESC',
          orderField: item.prop,
        }
        changeHandle2({
          timeLevel: timeLevel,
          dateFrom: dataMap.beginDate2 ? dataMap.beginDate2: undefined,
          dateTo: dataMap.endDate2 ? dataMap.endDate2 : undefined,
        })
      }else{
        delete sortParams2.order
        delete sortParams2.orderField
        changeHandle2({
          timeLevel: timeLevel,
          dateFrom: dataMap.beginDate2 ? dataMap.beginDate2: undefined,
          dateTo: dataMap.endDate2 ? dataMap.endDate2 : undefined,
        })
      }
    }

    const changeHandle3 = async (params: any) => {
      if(params.timeLevel !== 0) {
        dataMap.selectedTime = (dicts.timeSelection || []).findIndex((item:any) => item.value === params.timeLevel);
      }else {
        dataMap.selectedTime = 5;
        dataMap.beginDate = params.dateFrom ? params.dateFrom : undefined;
        dataMap.endDate = params.dateTo ? params.dateTo : undefined;
      }
      const timeLevelStr = (dicts.timeSelection || []).find((item:any) => item.value === params.timeLevel);
      const newParams = {
        timeLevel: timeLevelStr?.timeLevel,
        startTime: params.dateFrom ? moment(params.dateFrom).valueOf() : undefined,
        endTime: params.dateTo ? moment(params.dateTo).valueOf() : undefined,
      }
      dataMap.showBarChartNewA = false
      const data3 = await getSurveyIndustryCount({ ...newParams })
      dataMap.dataList3 = (data3 as any).data;
      dataMap.showBarChartNewA = true
    }

    const goResearchList = (row:any, column:any)=>{
      const { property } = column;
      // 行业
      if(!['industry'].includes(property)){
        let url = `/investment/research/detail?code=${row.code}&timeLevel=${dataMap.selectedTime1}`
        // 重点
        if(['orgResearchCount', 'focusOrgResearchCount'].includes(property)){
          if(dataMap.beginDate1) {
            url = `${url}&isPointOrg=1&beginDate=${dataMap.beginDate1}&endDate=${dataMap.endDate1}`
          }else {
            url = `${url}&isPointOrg=1`
          }
        }else {
          if(dataMap.beginDate1) {
            url = `${url}&isPointOrg=0&beginDate=${dataMap.beginDate1}&endDate=${dataMap.endDate1}`
          }else {
            url = `${url}&isPointOrg=0`
          }
        }
        window.open(url);
      }else {
        if(dataMap.beginDate1) {
          window.open(`/investment/research/list?isPointOrg=0&industryName=${row.industry}&timeLevel=${dataMap.selectedTime1}&beginDate=${dataMap.beginDate1}&endDate=${dataMap.endDate1}`)
        }else{
          window.open(`/investment/research/list?isPointOrg=0&industryName=${row.industry}&timeLevel=${dataMap.selectedTime1}`)
        }
      }
    }

    // 过滤关注度数据
    const fmtFollowsEmptyData = (val:any, type:any) => {
      const newVal = val.split(',')
      return newVal[type];
    }

    // 跳转机构调研列表
    const toIndustryList = (obj:any) => {
      if(obj.beginDate) {
        window.open(`/investment/research/list?isPointOrg=0&timeLevel=${obj.timeLevel}&beginDate=${obj.beginDate}&endDate=${obj.endDate}`)
      }else{
        window.open(`/investment/research/list?isPointOrg=0&timeLevel=${obj.timeLevel}`)
      }
    }

    // 跳转机构调研频次个股前30列表
    const toIndustryList2 = (obj:any) => {
      if(obj.beginDate) {
        window.open(`/investment/research/frequency-list?isSpecial=1&timeLevel=${obj.timeLevel}&beginDate=${obj.beginDate}&endDate=${obj.endDate}`)
      }else{
        window.open(`/investment/research/frequency-list?isSpecial=1&timeLevel=${obj.timeLevel}`)
      }
    }

    // 跳转调研机构前30列表
    const toIndustryList3 = (obj:any) => {
      if(obj.beginDate) {
        window.open(`/investment/research/mechanism-list?isSpecial=1&timeLevel=${obj.timeLevel}&beginDate=${obj.beginDate}&endDate=${obj.endDate}`)
      }else{
        window.open(`/investment/research/mechanism-list?isSpecial=1&timeLevel=${obj.timeLevel}`)
      }
    }

    // 调研机构30内容跳转
    const goResearchMechanism = (row:any, column:any) => {
      const { property } = column;
      // 行业
      if(!['follows1', 'follows2', 'follows3'].includes(property)){
        let url = `/investment/research/list?isPointOrg=0&timeLevel=${dataMap.selectedTime2}`
        // 机构名称
        // if(['orgName'].includes(property)){
        if(dataMap.beginDate2) {
          url = `${url}&orgName=${row.orgName}&beginDate=${dataMap.beginDate2}&endDate=${dataMap.endDate2}`
        }else {
          url = `${url}&orgName=${row.orgName}`
        }
        // }else {
        //   if(dataMap.beginDate2) {
        //     url = `${url}&beginDate=${dataMap.beginDate2}&endDate=${dataMap.endDate2}`
        //   }else {
        //     url = `${url}`
        //   }
        // }
        window.open(url);
      }else {
        const { follows } = row;
        let vals = '';
        switch (property) {
          case 'follows1':
            vals = follows[0]
            break;
          case 'follows2':
            vals = follows[1]
            break;
          case 'follows3':
            vals = follows[2]
            break;  
          default:
            break;
        }
        const newCode = vals ? vals.split(',')[1] : ''
        if(newCode) {
          if(dataMap.beginDate2) {
            window.open(`/investment/research/detail?code=${newCode}&timeLevel=${dataMap.selectedTime2}&isPointOrg=0&beginDate=${dataMap.beginDate2}&endDate=${dataMap.endDate2}`)
          }else{
            window.open(`/investment/research/detail?code=${newCode}&timeLevel=${dataMap.selectedTime2}&isPointOrg=0`)
          }
        }
      }
    }

    return {
      sortChange1,
      sortChange2,
      changeHandle1,
      changeHandle2,
      changeHandle3,
      goResearchList,
      fmtFollowsEmptyData,
      toIndustryList,
      toIndustryList2,
      toIndustryList3,
      goResearchMechanism,
      ...toRefs(dataMap)
    }
  }
})
</script>
<style scoped lang='scss'>
.echarts{
  width: 100%;
  height: 300px;
}
</style>
  