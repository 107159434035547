

import { defineComponent, reactive, toRefs } from 'vue'
import Search from '@/components/search-input/Index.vue'
import CTitle from '@/components/title/Index.vue'
import RecommendList from './components/RecommendList.vue'
import CTable from './components/Table.vue'
import AgencyRating from '@/views/agency/components/AgencyRating.vue'
import { getStockReportDetail, getBoardStockReportDetail } from '@/apis/agency'
import { useRoute } from 'vue-router'
// import pdf from 'vue-pdf'

export default defineComponent({
  components: {
    CTitle,
    RecommendList,
    CTable,
    Search,
    AgencyRating,
    // pdf
  },
  setup() {
    const route = useRoute()
    const dataMap = reactive({
      stockList: [],
      active: 0
    })

    const fetchData = async () => {
      console.log(route.query.page)
      if( route.query.page && route.query.page === 'alla' ){
        const data = await getBoardStockReportDetail({ reportId: route.query.reportId })
        if( data ){
          dataMap.stockList = (data as any).data;
        }
        return false
      }
      const data = await getStockReportDetail({ reportId: route.query.reportId })
      if( data ){
        dataMap.stockList = (data as any).data;
      }
    }
    fetchData()

    const openPdf = () => {
      window.open(dataMap.stockList['url']+'#page=1&toolbar=0&statusbar=0')
    }

    const handleTab = (idx:any) => {
      dataMap.active = idx
    }


    return {
      ...toRefs(dataMap),
      openPdf,
      handleTab
    }
  }
})
