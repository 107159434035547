
import {
  defineComponent,
  onMounted,
  nextTick,
  watch
} from 'vue'
import resize from '@/components/charts/mixins/resize'
import { init, EChartsOption } from 'echarts'
import any = jasmine.any
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '300px',
      required: true
    },
    chartData: {
      type: Object,
      default: {}
    },
    selectedTime: {
      type: String || Number,
      default: ''
    },
  },
  setup(props) {
    const {
      mounted,
      chart
    } = resize()

    watch(props, (newName, oldName) => {
      initChart()
    });

    const initChart = () => {
      const iconUrl = 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAYCAYAAAHhSr/lAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAJqADAAQAAAABAAAAGAAAAACa4ya8AAAAkUlEQVRIDWNgwAes69/9B2GQGiZ8CrHLUagdyVBGJDaYCXMVTPxooxCGGpgcjWkMe7G5jajQI0oRjX1Da+MxQgvdQvTQQ5dHjumREGDo/h/lExUCgzedUTXRDl7DiIqlUUWjITAaAoRCgGBZRsgAQnUqIf3IdS6yWqqWPsgGU8oedRipIThoQ4xUj4yqH7QhAAApNB68wpPEWAAAAABJRU5ErkJggg=='
      const iconLog = 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAMCAYAAAH317YUAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAE6ADAAQAAAABAAAADAAAAADC31D1AAABjElEQVQoFXVSPUsDQRCdvQsGsY2IP8AkaI4IamsE0x8INmLpB4iljY2mECxEEIuoSUDQxEJbSxELG0FtohCs7ESxkgvxEu/Gnb3sehcvU+y8nfdm2OUNAA88TJiURSAPiduVfGIWnaZXxeOJW5JQBGSBIcR4Gn7yDrprdIDbEgm+v/Y94DuxMm2rrnOz4aOAYSGZAcedAr1nHRarUT8JRcMGp7kNunbD/AQWU1sQM1ZF7aNaYstPa5IPCgvJODjOPOh6mS3VXqRIZSyNXmLT8p5H+ch4VKQE+Hqt3i8Av0suggfxTXGJDcual/ldcZJx80PBSacZS3IqI+Y0LKUtvNu1sZiuK4KDwO/8BBZSO8D0FegfacHgWC+8PTTg8znC/dqDhZkNxnKuXx+KkVbiZNKitQgNXhdf4LrOAX9OSoYcnbuKQt+ArPzP9XeAs6znsmS52xKqLPaz04bOJxLf3krV2A2Q0XhhNpT5chgtQSVr02KE9XY1gMR8mePwg0lg7jigdg8RVgtdu/bkX/GjJd10FYqlAAAAAElFTkSuQmCC'

      // let incr = [] as any;
      // props.chartData.quarterData.forEach((item: any) => {
      //   incr.push(item.quarter1ProfitIncr, item.quarter2ProfitIncr, item.quarter3ProfitIncr, item.quarter4ProfitIncr)
      // })
      // incr = incr.reduceRight((acc: any, cur: any) => {
      //   if (cur !== null || acc.length > 0) {
      //     acc.unshift(cur)
      //   }
      //   return acc;
      // }, [] as any)
      // console.log(incr, 'incr------')




      const ringRatio:any = []
      const ringDate:any = []
      const barList:any = []
      props.chartData.quarterData.map((item:any)=>{
        ringRatio.push(item.quarter1ProfitIncr);
        ringRatio.push(item.quarter2ProfitIncr);
        ringRatio.push(item.quarter3ProfitIncr);
        ringRatio.push(item.quarter4ProfitIncr);
        ringDate.push(item.quarter1ReportDate);
        ringDate.push(item.quarter2ReportDate);
        ringDate.push(item.quarter3ReportDate);
        ringDate.push(item.quarter4ReportDate);
        barList.push(item.quarter1PeTtm);
        barList.push(item.quarter2PeTtm);
        barList.push(item.quarter3PeTtm);
        barList.push(item.quarter4PeTtm);
      })

      // const oneIncr = props.chartData.quarterData[0].quarter1ProfitIncr + ',' + props.chartData.quarterData[0].quarter2ProfitIncr + ',' +  props.chartData.quarterData[0].quarter3ProfitIncr + ',' +  props.chartData.quarterData[0].quarter4ProfitIncr
      // const twoIncr = props.chartData.quarterData[1].quarter1ProfitIncr + ',' + props.chartData.quarterData[1].quarter2ProfitIncr + ',' +  props.chartData.quarterData[1].quarter3ProfitIncr + ',' +  props.chartData.quarterData[1].quarter4ProfitIncr
      // const threeIncr = props.chartData.quarterData[2].quarter1ProfitIncr + ',' + props.chartData.quarterData[2].quarter2ProfitIncr + ',' +  props.chartData.quarterData[2].quarter3ProfitIncr + ',' +  props.chartData.quarterData[2].quarter4ProfitIncr
      // const fourIncr = props.chartData.quarterData[3].quarter1ProfitIncr + ',' + props.chartData.quarterData[3].quarter2ProfitIncr + ',' +  props.chartData.quarterData[3].quarter3ProfitIncr + ',' +  props.chartData.quarterData[3].quarter4ProfitIncr
      // const fiveIncr = props.chartData.quarterData[4].quarter1ProfitIncr + ',' + props.chartData.quarterData[4].quarter2ProfitIncr + ',' +  props.chartData.quarterData[4].quarter3ProfitIncr + ',' +  props.chartData.quarterData[4].quarter4ProfitIncr
      // const sixIncr = props.chartData.quarterData[5]?.quarter1ProfitIncr + ',' + props.chartData.quarterData[5]?.quarter2ProfitIncr + ',' +  props.chartData.quarterData[5]?.quarter3ProfitIncr + ',' +  props.chartData.quarterData[5]?.quarter4ProfitIncr
      // let ringRatio = (oneIncr + ',' + twoIncr+ ',' + threeIncr + ',' + fourIncr + ',' + fiveIncr + ',' + sixIncr).split(',')
      // ringRatio = ringRatio.reduceRight((acc, cur) => {
      //   if (cur !== null || cur !== undefined || acc.length > 0) {
      //     acc.unshift(cur)
      //   }
      //   return acc;
      // }, [] as any)
      // const oneDate = props.chartData.quarterData[0].quarter1ReportDate + ',' + props.chartData.quarterData[0].quarter2ReportDate + ',' +  props.chartData.quarterData[0].quarter3ReportDate + ',' +  props.chartData.quarterData[0].quarter4ReportDate
      // const twoDate = props.chartData.quarterData[1].quarter1ReportDate + ',' + props.chartData.quarterData[1].quarter2ReportDate + ',' +  props.chartData.quarterData[1].quarter3ReportDate + ',' +  props.chartData.quarterData[1].quarter4ReportDate
      // const threeDate = props.chartData.quarterData[2].quarter1ReportDate + ',' + props.chartData.quarterData[2].quarter2ReportDate + ',' +  props.chartData.quarterData[2].quarter3ReportDate + ',' +  props.chartData.quarterData[2].quarter4ReportDate
      // const fourDate = props.chartData.quarterData[3].quarter1ReportDate + ',' + props.chartData.quarterData[3].quarter2ReportDate + ',' +  props.chartData.quarterData[3].quarter3ReportDate + ',' +  props.chartData.quarterData[3].quarter4ReportDate
      // const fiveDate = props.chartData.quarterData[4].quarter1ReportDate + ',' + props.chartData.quarterData[4].quarter2ReportDate + ',' +  props.chartData.quarterData[4].quarter3ReportDate + ',' +  props.chartData.quarterData[4].quarter4ReportDate
      // const sixDate = props.chartData.quarterData[5]?.quarter1ReportDate + ',' + props.chartData.quarterData[5]?.quarter2ReportDate + ',' +  props.chartData.quarterData[5]?.quarter3ReportDate + ',' +  props.chartData.quarterData[5]?.quarter4ReportDate
      // let ringDate = (oneDate + ',' + twoDate+ ',' + threeDate + ',' + fourDate + ',' + fiveDate + ',' + sixDate).split(',')
      // ringDate = ringDate.reduceRight((acc, cur) => {
      //   if (cur !== "" || acc.length > 0) {
      //     acc.unshift(cur)
      //   }
      //   return acc;
      // }, [] as any)
      // const one = props.chartData.quarterData[0].quarter1PeTtm + ',' + props.chartData.quarterData[0].quarter2PeTtm + ',' +  props.chartData.quarterData[0].quarter3PeTtm + ',' +  props.chartData.quarterData[0].quarter4PeTtm
      // const two = props.chartData.quarterData[1].quarter1PeTtm + ',' + props.chartData.quarterData[1].quarter2PeTtm + ',' +  props.chartData.quarterData[1].quarter3PeTtm + ',' +  props.chartData.quarterData[1].quarter4PeTtm
      // const three = props.chartData.quarterData[2].quarter1PeTtm + ',' + props.chartData.quarterData[2].quarter2PeTtm + ',' +  props.chartData.quarterData[2].quarter3PeTtm + ',' +  props.chartData.quarterData[2].quarter4PeTtm
      // const four = props.chartData.quarterData[3].quarter1PeTtm + ',' + props.chartData.quarterData[3].quarter2PeTtm + ',' +  props.chartData.quarterData[3].quarter3PeTtm + ',' +  props.chartData.quarterData[3].quarter4PeTtm
      // const five = props.chartData.quarterData[4].quarter1PeTtm + ',' + props.chartData.quarterData[4].quarter2PeTtm + ',' +  props.chartData.quarterData[4].quarter3PeTtm + ',' +  props.chartData.quarterData[4].quarter4PeTtm
      // const six = props.chartData.quarterData[5]?.quarter1PeTtm + ',' + props.chartData.quarterData[5]?.quarter2PeTtm + ',' +  props.chartData.quarterData[5]?.quarter3PeTtm + ',' +  props.chartData.quarterData[5]?.quarter4PeTtm
      // let barList = (one + ',' + two + ',' + three + ',' + four + ',' + five + ',' + six).split(',')
      // barList = barList.reduceRight((acc, cur) => {
      //   if (cur !== null || acc.length > 0) {
      //     acc.unshift(cur)
      //   }
      //   return acc;
      // }, [] as any)
      const reportingPeriodList = ['一季报', '二季报', '三季报', '年报','一季报', '二季报', '三季报', '年报','一季报', '二季报', '三季报', '年报','一季报', '二季报', '三季报', '年报','一季报', '二季报', '三季报', '年报','一季报', '二季报', '三季报', '年报']
      const barChart = init(document.getElementById(props.id) as HTMLDivElement)
      barChart.setOption({
        grid: {
          top: '60px',
          left: '41px',
          right: '41px',
          bottom: '40px',
          containLabel: true
        },
        legend: {
          data: [
            {
              name: 'PE-TTM',
              icon: iconUrl
            },
            {
              name: '环比增长率',
              icon: iconLog
            }
          ],
          itemWidth: 19,  // 设置宽度
          itemHeight: 12, // 设置高度
          x: 'center',
          bottom: '0',
          itemGap: 30,
          textStyle: {
            fontSize: 12,// 字体大小
            color:'#666666',
          }
        },
        tooltip: {
          trigger: 'axis',
          formatter:function(param:any){
            let text = ''
            for (let i = 0; i < param.length; i++) {
              const item = param[i]
              if (item.data != 'null') {
                text += `<div>
                        <span style='width:8px;height:8px;display:inline-block;border-radius:50%;margin-right:3px;background: ${item.color}'></span>
                        <span style='display:inline-block;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;line-height: 17px;'>${item.seriesName}：</span>
                        <span :style='display:inline-block;font-size: 14px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #333333;line-height: 20px;'>${item.seriesName === '环比增长率' ? item.data > 0 ? '+' + item.data + '%' : item.data + '%' : amount(item.data)}</span>
                    </div>`
              } else {
                return ''
              }
            }
            return text
          }
        },
        xAxis: {
          show: true,
          type: 'category',
          axisLine: {
            show: true,
            lineStyle: {
              color: "#BFBFBF",
              type: "dashed"
            }
          },
          axisLabel:{
            show: true,
            rotate: 45
          },
          axisTick: { show: false },
          data: ringDate
        },
        yAxis: [
          {
            type: 'value',
            splitLine: { show: false },
            position: 'left',
            max: value => {
              const { max, min } = value;
              const absMax = Math.max(Math.abs(max), Math.abs(min));
              return Math.floor(absMax * 1.2);
            } ,
            min: value => {
              const { max, min } = value;
              const absMax = Math.max(Math.abs(max), Math.abs(min));
              return Math.floor(-absMax * 1.2);
            },
            axisPointer: {
              type: 'none',
              label: { show: false }
            },
            axisLabel: {
              show: false,
              align: 'right',
            }
          },
          {
            type: 'value',
            splitLine: { show: false },
            max: value => {
              const { max, min } = value;
              const absMax = Math.max(Math.abs(max), Math.abs(min));
              return Math.floor(absMax * 1.2);
            },
            min: value => {
              const { max, min } = value;
              const absMax = Math.max(Math.abs(max), Math.abs(min));
              return Math.floor(-absMax * 1.2);
            },
            axisLabel: {
              show: false,
            }
          }
        ],
        series: [
          {
            name: '环比增长率',
            type: 'line',
            yAxisIndex: 0,
            itemStyle: {
              normal: {
                color: '#FE9625',
                lineStyle: {
                  width: 2,
                  symbolSize: 10
                }
              }
            },
            data: ringRatio
          },
          {
            type: 'bar',
            name: 'PE-TTM',
            yAxisIndex: 1,
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: 'top',
                  fontSize: 8,
                  color: '#3B7FEE',
                  formatter: (params: any) => {
                    return amount(params.value)
                  }
                },
                color: '#3B7FEE',
              }
            },
            barWidth: 18, // 柱子宽度
            data: barList
          },
          {
            name: '报告期',
            type: 'line',
            symbol: 'none',
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 1,
                  color: '#AEAEAE'
                }
              }
            },
            data: reportingPeriodList
          },
        ]
      } as EChartsOption)
      chart.value = barChart
    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    const amount = (value: any, fixed = 2, unit = '元') => {
      if (unit == '万') {
        value = value * 10000
      } else if (unit == '亿') {
        value = value * 100000000
      }
      if (Math.abs(value / 100000000) >= 1) {
        return (value / 100000000).toFixed(fixed) + '亿'
      }
      if (Math.abs(value / 10_000) >= 1) {
        return (value / 10000).toFixed(fixed) + '万'
      }
      return value
    }
    return {
      amount
    }
  }
})
