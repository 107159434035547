
import pagingComponent from '@/components/pagination/Index.vue'
import { defineComponent, reactive, toRefs } from 'vue'
import { geBoardSub, getBoardAdd } from '@/apis/margin-trading'
export default defineComponent({
  components: {
    pagingComponent
  },
  setup(props) {
    const dataMap = reactive({
      loading: true,
      currentValue: {
        id: 1
      },
      tableData: {}
    })
    let params = {
      pageNum: '1',
      pageSize: '10'
    }
    const fetchData = async (value:any) => {
      if (value.id) {
        dataMap.currentValue = value
      }
      if( value.pageNum ){
        params['pageNum'] = value.pageNum
      }
      if( value.pageSize ){
        params['pageSize'] = value.pageSize
      }
      const apiFun = dataMap.currentValue.id === 2 ? getBoardAdd(params) : geBoardSub(params)
      dataMap.loading = true
      const marginList = await apiFun
      dataMap.tableData = (marginList as any).data;
      dataMap.loading = false
    }
    const goDetail = (item: any) => {
      const path = '/marginTrading/industry'
      window.open( `/investment${path}?code=${item.name}`)
    };
    return {
      ...toRefs(dataMap),
      fetchData,
      goDetail
    }
  }
})
