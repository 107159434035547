<template>
  <div
    :id="id"
    :class="className"
    :style="{height: height, width: width}"
  />
</template>

<script lang="ts">
import { defineComponent, onActivated, onBeforeUnmount, onDeactivated, onMounted, nextTick, watch } from 'vue'
import resize from './mixins/resize'
import { init, EChartsOption } from 'echarts'
import * as echarts from 'echarts'
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '193px',
      required: true
    },
    chartData: {
      type: Object,
      default: {}
    }
  },
  setup(props,context) {
    watch(props, (newName, oldName) => {
      initChart()
    });

    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()
    const initChart = () => {
      const barChart = init(document.getElementById(props.id) as HTMLDivElement)

      let rsiList = props.chartData
            // console.log(this.data.technicalInfo)
      // console.log('macdList',rsiList)
      let seriesdata1 = [],
          seriesdata2 = [],
          seriesdata3 = [],
          timedata=[],
          max=0,
          min=0;
      if(rsiList&&rsiList.length>0){
        for (let i = 0; i < rsiList.length; i++) {
          const item = rsiList[i];
          const rsi1 = item.rsi6 || item.rsi1
          const rsi2 = item.rsi12 || item.rsi2
          const rsi3 = item.rsi24 || item.rsi3
          seriesdata1.push(Number(rsi1).toFixed(2))
          seriesdata2.push(Number(rsi2).toFixed(2))
          seriesdata3.push(Number(rsi3).toFixed(2))
          timedata.push(item.date)
          if(i==0){
            max = rsi1*1
            min = rsi1*1
          }else{
            if(max<rsi1*1){
              max = rsi1*1
            }
            if(min>rsi1*1){
              min = rsi1*1
            }

          }
        }
      }
      // this.setData({
      //   starttime2:starttime,
      //   endtime2:endtime,
      //   max2:(max*1).toFixed(2)*1,
      //   min2:(min*1).toFixed(2)*1
      // })

      barChart.setOption({
        tooltip: {
          show: true,
          trigger: 'axis', 
          position:'inside',
          padding:4,
          width:1,
          textStyle:{
            color:'#999999',
            fontSize:'10',
          },

          formatter:function(param:any){
            // console.log(param)
            // 利用循环更改显示的内容
            context.emit('getRsiVal',[param[0].data,param[1].data,param[2].data])
            // that.setData({
            //     rsi1:(param[0].data*1).toFixed(2)*1,
            //     rsi2:(param[1].data*1).toFixed(2)*1,
            //     rsi3:(param[2].data*1).toFixed(2)*1,
            //   })
            return 123
          }
        },
        grid: {
          left: -30,
          right: 0,
          bottom: 0,
          top: 0,
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: timedata,
            axisLine: {
              lineStyle: {
                color: '#999',
                width:1,
              }
            },
            axisLabel: {
                show:false,
                color: '#666'
            },
            axisTick:{
                show:false
            },
    
          }
        ],
        yAxis: [
          {
            type: 'value',
            show:false,
            axisTick: { show: false },
            axisLine: {
              lineStyle: {
                color: '#999'
              }
            },
            axisLabel: {
              color: '#666'
            },
          }
        ],
        series: [
          {
            name: 'rsi1',
            type: 'line',
            smooth: true,  //这个可以显示为平滑过渡的线条
            showSymbol:false,
            itemStyle:{
              normal:{
                
                lineStyle:{
                  color:'#333333',
                  width:1,
                },
              },
              emphasis:{
                lineStyle:{
                  width:1,
                },
              }
            },
            data:seriesdata1
          },
          {
            name: 'rsi2',
            type: 'line',
            smooth: true,  //这个可以显示为平滑过渡的线条
            showSymbol:false,
            itemStyle:{
              normal:{
                lineStyle:{
                  color:'#F6C52A',
                  width:1,

                },
              },
              emphasis:{
                lineStyle:{
                  width:1,
                },
              }
            },
            data:seriesdata2
            // data:seriesdata[1]
          },
          {
            name: 'rsi3',
            type: 'line',
            smooth: true,  //这个可以显示为平滑过渡的线条
            showSymbol:false,
            itemStyle:{
              normal:{
                
                lineStyle:{
                  color:'#951EFD',
                  width:1,
                },
              },
              emphasis:{
                lineStyle:{
                  width:1,
                },
              }
            },
            data:seriesdata3
            // data:seriesdata[2]
          },
        ]
      } as unknown as EChartsOption)
      chart.value = barChart
    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {

    }
  }
})
</script>

<style lang="scss" scoped>

</style>
