<template>
  <div
    :id="id"
    :class="className"
    :style="{height: height, width: width}"
  />
</template>

<script lang="ts">
import {
  defineComponent,
  onActivated,
  onBeforeUnmount,
  onDeactivated,
  onMounted,
  nextTick,
  watch
} from 'vue'
import resize from '@/components/charts/mixins/resize'
import { init, EChartsOption } from 'echarts'
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '350px',
      required: true
    },
    chartData: {
      type: Object,
      default: {}
    },
    selectedTime: {
      type: String || Number,
      default: ''
    },
  },
  setup(props, context) {
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()

    watch(props, (newName, oldName) => {
      initChart()
    });

    const initChart = () => {
      const xAxisList = Array.from((props.chartData as any).indexDaily.points, ({ x }) => x);
      const yAxisLeftList = Array.from((props.chartData as any).indexDaily.points, ({ y }) => y);
      const compositeIndex = yAxisLeftList.filter((item) => item !== null);
      const MaxArrpLeft = Math.ceil(Math.max(...compositeIndex));
      const MinArrpLeft = Math.floor(Math.min(...compositeIndex));
      const yAxisRightList = Array.from((props.chartData as any).rzyeDaily.points, ({ y }) => y);
      const compositeIndexe = yAxisRightList.filter((item) => item !== null);
      const MaxArrpLefte = Math.ceil(Math.max(...compositeIndexe));
      // const MinArrpLefte = Math.floor(Math.min(...compositeIndexe));
      const barChart = init(document.getElementById(props.id) as HTMLDivElement)
      barChart.setOption({
        grid: {
          top: '30px',
          left: '38px',
          right: '38px',
          bottom: '40px',
          containLabel: true
        },
        color: ['#AEAEAE', '#AEAEAE'],
        legend: {
          icon: 'line',
          itemWidth: 12,  // 设置宽度
          itemHeight: 6, // 设置高度
          x: 'center',
          bottom: '0',
          itemGap: 30,
          textStyle: {
            fontSize: 14,// 字体大小
            color:'#666666',
          },
          data: [
            {
              name: '融资余额',
            },
            {
              name: '上证指数',
            }
          ]
        },
        tooltip: {
          trigger: 'axis',
          formatter:function(params: any) {
            let text = `<div style="display: flex; justify-content: space-between"><span>${ params[0].axisValue }</span><span></span></div>`;
            for (let i = 0; i < params.length; i++) {
              const item = params[i]
              text += `<div style="display: flex; justify-content: space-between">
                      <div style="display: flex;align-items: center">
                        <span style='width:8px;height:8px;display:inline-block;border-radius:50%;margin-right:3px;background: ${item.color}'></span>
                        <span style='display:inline-block;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;line-height: 17px;'>${ item.seriesName }：</span>
                        </div>
                        <span style='display:inline-block;font-size: 14px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #333333;line-height: 20px;'>${ item.seriesName === '融资余额' ? (item.value / 100000000).toFixed(2) : (item.value).toFixed(2) }</span>
                    </div>`
            }
            return text
          }
        },
        xAxis: {
          data: xAxisList,
          axisLine: { show: false, onZero: false },
          splitLine: { show: false },
          axisLabel:{
            show: true,
            fontSize: 14,
            showMaxLabel:true, // 展示最大值
            showMinLabel:true, // 展示最小值
            formatter: function(value: any, index: any){
              if(index === 0) {
                return '        ' + value;
              }
              if(index === xAxisList.length -1) {
                return value + '        ';
              }
              return value
            }
          },
          axisTick: { show: false },
          splitArea: { show: false }
        },
        yAxis: [
          {
            type: 'value',
            splitLine: { show: false },
            scale: true,
            max: MaxArrpLefte,
            // min: MinArrpLefte,
            axisLabel: {
              show: true,
              fontSize: 14,
              formatter: function(params: any) {
                return (params / 100000000).toFixed(2)
              }
            }
          },
          {
            type: 'value',
            splitLine: { show: false, lineStyle: { type: 'dashed' } },
            max: MaxArrpLeft + 50,
            min: MinArrpLeft - 50,
            axisPointer: {
              type: 'none',
              label: { show: false }
            },
            axisLabel: {
              show: true,
              fontSize: 14,
              // align: 'right',
            }
          },
        ],
        series: [
          {
            name: '融资余额',
            type: 'bar',
            yAxisIndex: 0,
            barWidth: 20,
            itemStyle: {
              normal: {
                color: '#0086FB'
              }
            },
            data: yAxisRightList
          },
          {
            name: '上证指数',
            type: 'line',
            yAxisIndex: 1,
            symbol: 'none',
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 1.5,
                  color: '#AEAEAE'
                }
              }
            },
            data: compositeIndex
          },
        ]
      } as EChartsOption)
      chart.value = barChart
    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {
    }
  }
})
</script>
