/*
 * @Description: 定增
 */
import https from '@/utils/https'
import { RequestParams, ContentType, Method } from 'axios-mapper'

//搜索
export const getFinanceReportSearch = (params: RequestParams) => {
  return https().request('/agg/financeReport/search', Method.GET, params, ContentType.form)
}

//今日财报
export const getFinanceReportToday = (params: RequestParams) => {
  return https().request('/agg/financeReport/today', Method.GET, params, ContentType.form)
}

//历史财报披露数据
export const getFinanceReportHistory = (params: RequestParams) => {
  return https().request('/agg/financeReport/history', Method.GET, params, ContentType.form)
}

