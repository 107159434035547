
import { defineComponent, reactive, toRefs } from 'vue'
import CPagination from "@/components/pagination/Index.vue"
import { getMarginStock } from '@/apis/trading'
import { bigNumberTransform } from '@/utils/data-format'
import { useRoute  } from "vue-router";
export default defineComponent({
  components:{
    CPagination,
  },
  props: {
    // stockList : {
    //   type: Object,
    //   default: {}
    // },
  },
  setup() {
    const route = useRoute();
    const dataMap = reactive({
      isLoading: true,
      timeIncr:{},
      stockList: {
        list: []
      }
    })

    let params = {
      pageNum: 1,
      pageSize: 10,
      sortFiled: 'rzjmr',
      boardType: route.query.boardType,
      boardName: route.query.code,
      // boardName: '半导体',
      timeLevel: '',
    }
    const fetchData = async (obj:any) => {
      if( obj && obj.pageNum ) {
        params.pageNum = obj.pageNum
      }
      if( obj && obj.pageSize ) {
        params.pageSize = obj.pageSize
      }
      if( obj && obj.timeLevel ){
        params.timeLevel = obj.timeLevel
      }
      if( !params.timeLevel ){
        return false
      }
      dataMap.isLoading = true
      const stockList = await getMarginStock(params)
      dataMap.stockList = (stockList as any).data;
      dataMap.isLoading = false
    }

    const timeChange = (obj:any) => {
      params.timeLevel = obj.lbValue
      dataMap.timeIncr = obj
      fetchData({})
    }

    const sortChange = (column: any) => {
      let isAsc = column.order === "ascending" ? 'asc' : 'desc';
      params['sortFiled'] = column["prop"]
      params['sort'] = isAsc
      fetchData({})
    };

    const addClass = (column:any) => {
      if( column.column.property === params.sortFiled ){
        return 'sort-bg'
      }
    }

    const amount = (value: any, fixed = 2, unit = '元') => {
      if (unit == '万') {
        value = value * 10000
      } else if (unit == '亿') {
        value = value * 100000000
      }
      if (Math.abs(value / 100000000) >= 1) {
        return (value / 100000000).toFixed(fixed) + '亿'
      }
      if (Math.abs(value / 10_000) >= 1) {
        return (value / 10000).toFixed(fixed) + '万'
      }
      return value
    }

    const goDetail = async (item:any) => {
      window.open(`/investment/marginTrading/shares?name=${item.name}&code=${item.code}`)
    }


    return {
      ...toRefs(dataMap),
      fetchData,
      timeChange,
      addClass,
      sortChange,
      bigNumberTransform,
      amount,
      goDetail
    }
  }
})
