<template>
  <div>
    <el-table
      class="table-style-2 cursor"
      :data="stockList.list || []"
      border
      align="center"
      :cell-class-name='addClass'
      >
      <el-table-column prop="date" label="日期"  align="center" />
      <el-table-column prop="incr" label="涨幅"  align="center">
        <template #default='{row}'>
          <div v-dClass='row.incr'>{{ row.incr ? row.incr.toFixed(2) + '%' : '-' }}</div>
        </template>
      </el-table-column>
      <el-table-column label="融资" align="center" >
        <el-table-column prop="rzye" label="余额" align="center">
          <template #default='{row}'>
            <div>{{ bigNumberTransform(row.rzye) }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="rzyezb" label="余额占流通市值比"  align="center">
          <template #default='{row}'>
            <div>{{ row.rzyezb ? row.rzyezb.toFixed(2) + '%' : '-'  }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="rzmr" label="买入额(元)"  align="center">
          <template #default='{row}'>
            <div>{{ bigNumberTransform(row.rzmr)  }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="rzjmr" label="净买入(元) "  align="center">
          <template #default='{row}'>
            <div>{{ bigNumberTransform(row.rzjmr) }}</div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="融券" align="center" >
        <el-table-column prop="rqye" label="余额(元)" align="center">
          <template #default='{row}'>
            <div>{{ bigNumberTransform(row.rqye) }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="rqmce" label="卖出额" align="center">
          <template #default='{row}'>
            <div>{{ bigNumberTransform(row.rqmce) }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="rqjme" label="净卖额" align="center">
          <template #default='{row}'>
            <div>{{ bigNumberTransform(row.rqjme) }}</div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column prop="rzrqye" label="融资融券余额(元)" align="center">
        <template #default='{row}'>
          <div>{{ bigNumberTransform(row.rzrqye) }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="rzjmrzbMaxName" label="融资净买入占流通市值最大比" align="center">
        <template #default='{row}'>
          <div @click="goDetail1(row)">{{ row.rzjmrzbMaxName ? row.rzjmrzbMaxName : '-' }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="rqjmczbMaxName" label="融券净卖出占流通股最大比" align="center">
        <template #default='{row}'>
          <div @click="goDetail2(row)">{{ row.rqjmczbMaxName ? row.rqjmczbMaxName : '-' }}</div>
        </template>
      </el-table-column>
    </el-table>
    <CPagination ref='pagin' :showPageSizes='true' :pageSize='10' :pageData='stockList' @fetchData='fetchData'/>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue'
import CPagination from "@/components/pagination/Index.vue"
import { getMarginHistoryStock } from '@/apis/trading'
import { bigNumberTransform } from '@/utils/data-format'
import { useRoute  } from "vue-router";
export default defineComponent({
  components:{
    CPagination,
  },
  props: {
    stockList : {
      type: Object,
      default: {}
    },
  },
  setup() {
    const route = useRoute();
    const dataMap = reactive({
      stockList: {
        list: []
      }
    })

    let params = {
      pageNum: 1,
      pageSize: 10,
      boardType: route.query.boardType,
      boardName: route.query.code,
      // timeLevel: ''
    }
    const fetchData = async (obj:any) => {
      if( obj && obj.pageNum ) {
        params.pageNum = obj.pageNum
      }
      if( obj && obj.pageSize ) {
        params.pageSize = obj.pageSize
      }
      // if( obj && obj.timeLevel ){
      //   params.timeLevel = obj.timeLevel
      // }
      // if( !params.timeLevel ){
      //   return false
      // }
      const stockList = await getMarginHistoryStock(params)
      dataMap.stockList = (stockList as any).data;
    }

    const goDetail1 = async (item:any) => {
      console.log(item)
      // return false
      window.open(`/investment/marginTrading/shares?name=${item.rzjmrzbMaxName}&code=${item.rzjmrzbMaxCode}`)
    }
    const goDetail2 = async (item:any) => {
      console.log(item)
      window.open(`/investment/marginTrading/shares?name=${item.rqjmczbMaxName}&code=${item.rqjmczbMaxCode}`)
    }

    // const goDetail1 = async (item:any) => {
    //   console.log(item)
    //   return false
    //   window.open(`/investment/marginTrading/shares?name=长沙银行&code=601577.SH`)
    // }

    return {
      ...toRefs(dataMap),
      fetchData,
      bigNumberTransform,
      goDetail1,
      goDetail2
    }
  }
})
</script>
<style scoped lang="scss">

</style>
