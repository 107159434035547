
import {
  defineComponent,
  onActivated,
  onBeforeUnmount,
  onDeactivated,
  onMounted,
  nextTick,
  watch,
  reactive,
  toRefs
} from 'vue'
import { init, EChartsOption } from 'echarts'
import resize from '@/components/charts/mixins/resize'
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '350px',
      required: true
    },
    chartData: {
      type: Object || Array,
    },
    selectedTime: {
      type: String || Number,
      default: ''
    },
    type: {
      type: String || Number,
      default: ''
    },
  },
  setup(props, context) {
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()

    watch(props, (newName, oldName) => {
      initChart()
    });

    const initChart = () => {
      if( !props.chartData ){
        return false
      }
      if( props.type === '2' ){
        if( !Object.keys((props as any)['chartData']).length ){
          return false
        }
      }
      if( props.type === '1' && !(props as any).chartData.length ){
        return false
      }
      let data:any = []
      let xAxisList = []
      let yAxisLeftList = []
      
      if( props.type === '1' ){
        data = props.chartData
        yAxisLeftList = Array.from(data, ({ fixedIncreaseStock }) => fixedIncreaseStock);
      }else if( props.type === '2' ){
        for (const key in props.chartData) {
          data.push({
            name: key,
            value: props.chartData[key]
          })
        }
        yAxisLeftList = Array.from(data, ({ value }) => value);
      }
      
      xAxisList = Array.from(data, ({ name }) => name);
      const maxnum = Math.ceil(Math.max(...yAxisLeftList) * 1.2)

      const barChart = init(document.getElementById(props.id) as HTMLDivElement)
      barChart.setOption({
        grid: {
          top: '30px',
          left: '67px',
          right: '0',
          bottom: '30px',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          formatter:function(params: any) {
            let text = ``;
            for (let i = 0; i < params.length; i++) {
              const item = params[i]
              text += `<div style="display: flex; justify-content: space-between">
                      <div style="display: flex;align-items: center">
                        <span style='width:8px;height:8px;display:inline-block;border-radius:50%;margin-right:3px;background: ${item.color}'></span>
                        <span style='display:inline-block;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;line-height: 17px;'>${item.name}：</span>
                        </div>
                        <span style='display:inline-block;font-size: 14px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #333333;line-height: 20px;'>${ item.value }家</span>
                    </div>`
            }
            return text
          }
        },
        xAxis: {
          data: xAxisList,
          axisLine: { show: false, onZero: false },
          splitLine: { show: false },
          axisPointer: {
            label: { show: false }
          },
          axisLabel:{
            show: true
          },
          triggerEvent: true,
          axisTick: { show: false },
          splitArea: { show: false }
        },
        yAxis: [
          {
            type: 'value',
            name: '单位（家）',
            max: maxnum,
            splitLine: { show: true, lineStyle: { type: 'dashed' } },
            axisPointer: {
              type: 'none',
              label: { show: false }
            },
            axisLabel: {
              show: true,
              formatter:function(param: any) {
                return param
              }
            }
          }
        ],
        series: [
          {
            // name: '北上净买入(亿元)',
            type: 'bar',
            barWidth: 70,
            itemStyle: {
              normal: {
                color: '#3B7FEE',
                label: {
                  formatter: '{c}家',
                  show: true,
                  position: 'top',
                  textStyle: {
                    fontWeight: 'bolder',
                    fontSize: '12',
                    color: '#333333'
                  }
                },
              }
            },
            data: yAxisLeftList
          },
        ]
      } as EChartsOption)
      chart.value = barChart
      barChart.on('click', (params:any) => {
        console.log(params)
        // if(params.componentType == "xAxis"){
        //   // context.emit('chartsClick', {
        //   //   code: params.value,
        //   //   boardType: type,
        //   // }, false)
        // }else 
        if(params.componentType == 'series'){
          context.emit('chartsClick', params.dataIndex)
        }
        // if(params.componentType == "xAxis"){
        //   const corresponding:any = props.chartData.filter((item: any) => item.name == params.value)
        //   const { type } = corresponding[0]
        //   if (type == 'swl1') {
        //     context.emit('chartsClick', params.value, true)
        //   } else {
        //     // if (dataMap.count >= 1) return false
        //     // dataMap.count++
        //     // window.open(`/investment/northboundFunds/industry?code=${params.value}&boardType=${type}`)
        //     context.emit('chartsClick', {
        //       code: params.value,
        //       boardType: type,
        //     }, false)
        //     // setTimeout(() => {
        //     //   dataMap.count = 0
        //     // }, 1000)
        //   }
        // }else if (params.componentType == 'series'){
        //   const corresponding:any = props.chartData.filter((item: any) => item.name == params.name)
        //   const { type } = corresponding[0]
        //   // if (dataMap.count >= 1) return false
        //   // dataMap.count++
        //   // window.open(`/investment/northboundFunds/industry?code=${params.name}&boardType=${type}`)
        //   context.emit('chartsClick', {
        //       code: params.name,
        //       boardType: type,
        //     }, false)
        //   // setTimeout(() => {
        //   //   dataMap.count = 0
        //   // }, 1000)
        // }
      })
    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {
      // ...toRefs(dataMap),
    }
  }
})
