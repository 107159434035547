
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    zsList: {
      type: Array,
      default: []
    }
  },
  setup() {
    const goDetails = (item: any) => {
      window.open(`/page/data_center_all?industryName=${item.boardName}&boardType=index`)
    }
    const keepTwo = (item: any) => {
      return Number(item).toFixed(2)
    }
    return {
      goDetails,
      keepTwo
    }
  }
})
