<template>
  <div>
    <div class="c-tab">
      <CustomTab :mode='2' @change='fetchData' />
    </div>
    <el-table
      class="table-style-2 first-active"
      :data="stockList"
      border
      align="center"
      v-loading='loading'
      :cell-class-name='addClass'
      :default-sort ="{prop:'addHold',order:'descending'}"
      @sort-change="sortChange">
      <el-table-column label="股票名称" prop="stockName" align="center" />
      <el-table-column label="同业排名" prop="sort" align="center" />
      <el-table-column :label="`${timeSelected.text}涨幅`" align="center">
        <template #default='{row}'>
          <div v-dClass='row.incr'><template v-if="row.incr[0] !== '-'">+</template>{{ row.incr }}%</div>
        </template>
      </el-table-column>
      <el-table-column label="增持数量（股）" prop="addHoldMarketCap" sortable="custom" align="center">
        <template #default='{row}'>
          {{ row.addHoldMarketCap }}
        </template>
      </el-table-column>
      <el-table-column label="增值市值（元）" prop="addHold" sortable="custom" align="center">
        <template #default='{row}'>
          {{ row.addHold }}
        </template>
      </el-table-column>
      <el-table-column label="增持市值增幅" prop="addHoldMarketCapRatio" sortable="custom" align="center" />
      <!-- <el-table-column label="增持环比变化" prop="rankingNo"  align="center" /> -->
    </el-table>
    <!-- <CPagination ref='pagin' :pageSize='20'  :pageData='stockList' @fetchData='fetchData'/> -->
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  toRefs,
  reactive
} from 'vue'
import { useRoute } from "vue-router";

// import CPagination from "@/components/pagination/Index.vue"
import CustomTab from '../../components/CustomTab.vue'
import { getPeer } from '@/apis/northboundFunds'
export default defineComponent({
  components: {
    // CPagination,
    CustomTab
  },
  setup(props, context) {
    const route = useRoute();
    const dataMap = reactive({
      loading: false,
      timeSelected: {},
      stockList: [
        
      ],
    })

    let params = { 
      stockCode: route.query.code,//'000913.SZ',
      timeUnit: '',
      orderBy: 'addHold',
      order: 'DESC'
    }

    const fetchData = async (value:any) => {
      console.log('ccccc', value)
      if( value.value ){
        dataMap.timeSelected = value
        params['timeUnit'] = value.value
      }

      dataMap.loading = true

      // let list = await getPeer(params)
      // const newList = (list as any).data
      // const filterStock = newList.filter((item:any) => item.stockCode === route.query.code)
      // list = filterStock.concat(newList)
      // dataMap.stockList = (list as any);
      const list = await getPeer(params)
      dataMap.stockList = (list as any).data;
      dataMap.loading = false
    }

    const sortChange = (column: any) => {
      let isAsc = column.order === "ascending" ? 'ASC' : 'DESC';
      params['orderBy'] = column["prop"]
      params['order'] = isAsc
      fetchData({})
    };
    
    const addClass = (column:any) => { 
      if( column.column.property === params.orderBy ){
        return 'sort-bg'
      }
    }
    
    return {
      ...toRefs(dataMap),
      fetchData,
      sortChange,
      addClass
    }
  }
})
</script>

<style scoped lang="scss">
.c-tab{
  text-align: right;
  margin: 12px 0 20px;
}
</style>
