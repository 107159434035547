
import { defineComponent, reactive, toRefs } from 'vue'
import CTitle from '@/components/title/Index.vue'
import AnalysisTable from '@/views/analysis/components/Table.vue'
import RecommendList from '@/views/agency/components/RecommendList.vue'
import AgencyRating from '@/views/agency/components/AgencyRating.vue'
import SurveyCountRecord from '@/views/research/components/SurveyCountRecord.vue'
import SurveyRankingtList from '@/views/research/components/SurveyRankingtList.vue'
import Search from '@/components/search-input/Index.vue'
import NewsFlash from './components/NewsFlash.vue'
import Hot from './components/Hot.vue'
import Analysis from './components/Analysis.vue'
import SurveyFrequency from '@/views/research/components/SurveyFrequency.vue'

import { useRouter } from 'vue-router'
// import { getIndexRisingList, getIndividualStocksRose } from '@/apis/home'

export default defineComponent({
  components: {
    CTitle,
    RecommendList,
    AnalysisTable,
    AgencyRating,
    SurveyCountRecord,
    SurveyRankingtList,
    Search,
    NewsFlash,
    Hot,
    Analysis,
    SurveyFrequency
  },
  setup() {
    const router = useRouter()
    // const keyTimeMap = ['1m','3m','6m','1y'];
    const dataMap = reactive({
      ajaxRes:false,
      // stockList1:[],
      // stockList2:[],
      // stockList3:[],
      // stockList4:{},
      // stockList5:[],
      // stockList6:[],
      // stockList7:[],
      // stockList6:[],
      // stockList7:[],
    })

    const fetchData = async () => {
      // const data = await getIndexRisingList({  })
      // const data1 = await getIndividualStocksRose({ sortKey: 'streakDay' })
      // const data2 = await getIndividualStocksRose({ sortKey: 'streakChange' })
      // const data3 = await getSurveyRankingtList({  })
      // const data4 = await getSurveyRecordsList({  })

      // const data3 = await getAgencyRatingList({  })

      // dataMap.stockList1 = (data as any).data;
      // dataMap.stockList2 = (data1 as any).data;
      // dataMap.stockList3 = (data2 as any).data;
      // dataMap.stockList4 = (data3 as any).data;
      // dataMap.stockList5 = (data4 as any).data;

      dataMap.ajaxRes = true;
      // console.log(data3)
    }
    fetchData();


    // const changeHandle = async (val:any) => {
    //   const data3 = await getSurveyRankingtList({ timeLevel: val.timeLevel })
    //   dataMap.stockList4 = (data3 as any).data;
    // }



    const navPush = (num:number) => {
      let list = ['/agency', '/research', '/analysis']
      // router.push({ path: list[num] }).catch(err => {
      //   console.warn(err)
      // })
      window['$props'].push({
        path: `/investment${list[num]}/index`,

      })
    }

    return {
      ...toRefs(dataMap),
      // changeHandle,
      navPush
      // goDetail
    }
  }
})
