<template>
  <div class="mode">
    <div
      class="mode-1"
      v-if="mode === 1"
    >
      {{ title }}
    </div>
    <div
      class="mode-2"
      v-else-if="mode === 2"
    >
      <div class="left">
        {{ title }}
      </div>
      <div class="right">
        <el-select
          v-model="value"
          class="select-style-1"
          popper-class="select-option-style-1"
          placeholder="请选择"
          @change='changeHandle'
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
    </div>
    <div
      class="mode-3 mode-2"
      v-else-if="mode === 3"
    >
      <div class="left">
        {{ title }}
      </div>
      <div class="right">
        <el-select
          v-model="value"
          class="select-style-1"
          popper-class="select-option-style-1"
          placeholder="请选择"
          @change='changeHandle'
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
    </div>
    <div
      class="mode-4 mode-3 mode-2"
      v-else-if="mode === 4"
    >
      <div class="left">
        {{ title }} 
      </div>
      <div class="date"><span class="vice-title">{{ viceTitle }}</span></div>
      <div class="right">
        <el-select
          v-model="value"
          class="select-style-1"
          popper-class="select-option-style-1"
          placeholder="请选择"
          @change='changeHandle'
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
    </div>
    <div
      class="mode-5 mode-2"
      v-else-if="mode === 5"
    >
      <div class="left">
        {{ title }}
      </div>
      <div class="right">
        <el-select
          v-model="value"
          class="select-style-1"
          popper-class="select-option-style-1"
          placeholder="请选择"
          @change='changeHandle'
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
    </div>
    <div
      class="mode-6 mode-2"
      v-else-if="mode === 6"
    >
      <div class="left">
        {{ title }} 
         <img
          src="@/assets/images/doubt.png"
          @click="changeTips"
          v-if="sixIcon"
          alt="">
      </div>
    </div>
    <div
      class="mode-7 mode-5 mode-2"
      v-else-if="mode === 7"
    >
      <div class="left">
        {{ title }}
      </div>
      <div class="code">（{{$stock.codeFilter(titleData.code,0)}}）</div>
      <ul>
        <li>
          <div class="des">最新价</div>
          <div class="symbol">：</div>
          <div class="ans" v-dClass='titleData.incr'>{{titleData.price.toFixed(2)}}</div>
        </li>
        <li>
          <!-- {{titleData}} -->
          <div class="des">涨<b>跌</b></div>
          <div class="symbol">：</div>
          <div class="ans" v-dClass='titleData.incr'>{{titleData.chg}}</div>
        </li>
        <li>
          <div class="des">涨跌幅</div>
          <div class="symbol">：</div>
          <div class="ans" v-dClass='titleData.incr'>
            <!-- {{state}} -->
            {{ state === 3 ? '停牌' : $stock.numFilter(titleData.incr)}}
          </div>
        </li>
        <li>
          <div class="des">换<b>手</b></div>
          <div class="symbol">：</div>
          <div class="ans">{{titleData.turnover}}%</div>
        </li>
        <li>
          <div class="des">总<b>手</b></div>
          <div class="symbol">：</div>
          <div class="ans">{{ $stock.mktcap(titleData.vol / 100, 10000, 2, "万")}}</div>
        </li>
        <li>
          <div class="des">金<b>额</b></div>
          <div class="symbol">：</div>
          <div class="ans">{{ $stock.mktcap(titleData.amt , 100000000, 2, "亿") }}</div>
        </li>
      </ul>
    </div>
    <div
      class="mode-8 mode-6 mode-2"
      v-else-if="mode === 8"
    >
      <div class="left">
        {{ title }}
      </div>
      <div class="right" @click="lookMore">
        查看更多>
      </div>
    </div>
    <div
      class="mode-9 mode-3 mode-2"
      v-else-if="mode === 9"
    >
      <div class="left">
        {{ title }}
      </div>
    </div>
    <div
      class="mode-10 mode-5 mode-2"
      v-else-if="mode === 10"
    >
      <div class="left">
        {{ title }}
      </div>
      <div class="right">
        （{{code.split('.')[0]}}）
      </div>
    </div>
    <div
      class="mode-11 mode-5 mode-2"
      v-else-if="mode === 11"
    >
      <div class="left">
        {{ title }}
      </div>
      <div class="center">（{{code.split('.')[0]}}）</div>
      <div class="right">
        <el-select
          v-model="value"
          class="select-style-1"
          popper-class="select-option-style-1"
          placeholder="请选择"
          @change='changeHandle'
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
    </div>
    <div
      class="mode-5 mode-2"
      v-else-if="mode === 12"
    >
      <div class="left">
        {{ title }}
      </div>
    </div>
    <div
      class="mode-13 mode-1"
      v-else-if="mode === 13"
    >
      <div class="left">
        {{ title }}
        <span>
          <i>{{titleData.num}}</i>家
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">


import { reactive, defineComponent, toRefs, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  props: {
    // eslint-disable-next-line vue/require-default-prop
    mode: {
      type: Number
    },
    title: {
      type: String
    },
    viceTitle: {
      type: String
    },
    sixIcon: {
      type: Boolean
    },
    titleData: {
      type: Object
    },
    type: {
      type: String,
      default: 'month'
    },
    code: {
      type: String,
      default: ''
    },
    moreUrl: {
      type: String,
      default: ''
    },
    state: {
      type: Number,
      default: ''
    }
  },
  setup(props, context) {
    const router = useRouter()
    const route = useRoute()
    const stockCode = route.query.code
    const value = ref(0)
    let options:any = []
    if( props.type === 'year' ){
      options = [
        {
          value: 0,
          label: '最近3年',
          timeLevel: '3y'
        },
        {
          value: 1,
          label: '最近5年',
          timeLevel: '5y'
        },
        {
          value: 2,
          label: '最近10年',
          timeLevel: '10y'
        }
      ]
    }else{
      options = [
        {
          value: 0,
          label: '最近1月',
          timeLevel: '1m'
        },
        {
          value: 1,
          label: '最近3月',
          timeLevel: '3m'
        },
        {
          value: 2,
          label: '最近6月',
          timeLevel: '6m'
        },
        {
          value: 3,
          label: '最近1年',
          timeLevel: '1y'
        }
      ]
    }

    
    const changeHandle = (val:any) => {
      context.emit('change', options[val])
    }
    context.emit('change', options[0])


    const lookMore = ()=>{
      // router.push({ 
      //   path: props.moreUrl, 
      //   query: {
      //     code: stockCode
      //   } 
      // })
      window['$props'].push({
        path: `/investment${props.moreUrl}`,
        query:{
          code: stockCode
        }
      })
    }

    const changeTips = ()=>{
      context.emit('changeTips')
    }

    return {
      value,
      options,
      changeHandle,
      lookMore,
      changeTips
    }
  }
})
</script>

<style lang="scss" scoped>
.mode{
  // margin-bottom: 38px;
}
.mode-1{
  font-size: 24px;
  font-family: PingFang;
  font-weight: bold;
  color: #000000;
}
.mode-2{
  display: flex;
  justify-content: space-between;
  .left{
    font-size: 20px;
    font-family: PingFang;
    font-weight: bold;
    color: #000000;
    padding-top: 4px;
  }
}
.mode-3{
  .left{
    position: relative;
    &::after{
      width: 85%;
      height: 10px;
      opacity: 0.58;
      content: '';
      background: linear-gradient(90deg, #3C7FEE 0%, rgba(60,127,238,0) 100%);
      position: absolute;
      left: 0;
      top: 22px;
    }
  }
}
.mode-4{
  justify-content: inherit;
  align-items: center;
  .vice-title{
    font-size: 14px;
    font-family: PingFang;
    font-weight: 500;
    color: #666666;
    padding-left: 113px;
  }
  .right{
    margin-left: auto;
  }
}
.mode-5{
  // padding-bottom: 10px;
  border-bottom: 1px solid #F2F4F8;
  .left{
    padding-bottom: 14px;
    border-bottom: 3px solid #1E5DF0;
  }
}
.mode-6{
  padding-left: 10px;
  position: relative;
  &::after{
    width: 3px;
    height: 20px;
    content: '';
    background:  linear-gradient(180deg, #FF6235 0%, #FB0000 100%);
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .left{
    padding-top: 0;
    img{
      width: 15px;
      cursor: pointer;
      position: relative;
      top: -5px;
    }
  }
}
.mode-7{
  justify-content: left;
  align-items: center;
  .code{
    font-size: 20px;
    font-weight: bold;
    color: #000000;
    padding-bottom: 14px;
    // padding-top: 4px;
  }
  .left{
    padding-top: 0;
    white-space: nowrap;
  }
  ul{
    display: flex;
    padding-left: 33px;
    padding-bottom: 5px;
    // padding-top: 7px;
    flex-flow: wrap;
    li{
      display: flex;
      // align-items: center;
      margin-right: 48px;
      line-height: 20px;
      // padding: 5px 0;
    }
    .des,.symbol{
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      b{
        font-weight: normal;
        padding-left: 12px;
      }
    }
    .ans{
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
  }
}
.mode-8{
  .right{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    cursor: pointer;
  }
}
.mode-10{
  justify-content: flex-start;
  .right{
    font-size: 20px;
    font-family: PingFang;
    font-weight: bold;
    color: #000000;
    padding-top: 4px;
  }
}
.mode-11{
  justify-content: flex-start;
  .center{
    font-size: 20px;
    font-family: PingFang;
    font-weight: bold;
    color: #000000;
    padding-top: 4px;
  }
  .right{
    margin-left: auto;
  }
}
.mode-13{
  font-size: 20px;
  font-weight: 500;
  .left{
    span{
      font-size: 24px;
      i{
        font-style: normal;
        color: #FF0000;
      }
    }
  }
}
</style>
