<template>
    <div>
        <div class="content">
            <div
            :id="id"
            :class="className"
            :style="{height: height, width: width}"
            />
        </div>
    </div>
</template>
  
<script lang="ts">
import { defineComponent, onActivated, onBeforeUnmount, onDeactivated, onMounted, nextTick, watch } from 'vue'
import resize from './mixins/resize'
import { init, EChartsOption } from 'echarts'
export default defineComponent({
props: {
    className: {
        type: String,
        default: 'chart'
    },
    id: {
        type: String,
        default: 'lineChartsCompoents'
    },
    width: {
        type: String,
        default: '300%',
        required: true
    },
    height: {
        type: String,
        default: '250px',
        required: true
    },
    chartData: {
        type: Object,
        default: {}
    },
    selectedTime: {
        type: String || Number,
        default: ''
    },
    beginDate: {
        type: String || Number,
        default: ''
    },
    endDate: {
        type: String || Number,
        default: ''
    },
},
setup(props) {
    const {
        mounted,
        chart,
        beforeDestroy,
        activated,
        deactivated
    } = resize()

    watch(props, (newName, oldName) => {
        initChart()
    });

    const getShowNum = () => {
        const appV3 = (document.getElementById('app-v3') as HTMLDivElement)
        const clientWidth = appV3.clientWidth
        // console.log('vvvvvvvvvvvvv',clientWidth)
        if( clientWidth > 1400 ){
            return 13
        }if( clientWidth > 1300 ){
            return 12
        }else if( clientWidth > 1200 ){
            return 11
        }else if( clientWidth > 1000 ){
            return 9
        }else if( clientWidth < 800 ){
            return 6
        }
    }

    const initChart = () => {
        if( !props.chartData.length ){
            return false
        }
        const barChart = init(document.getElementById(props.id) as HTMLDivElement)
        
        
        let xData = [];
        let serviesData1 = [];
        let serviesData2 = [];
        let serviesData3 = []; // 行业调研次数
        let serviesData4 = []; // 行业调研机构家数
        let serviesData5 = []; // 重点机构家数
        let serviesData6 = []; // 行业区间涨幅
        for (let index = 0; index < props.chartData.length; index++) {
            xData.push(props.chartData[index].code)
            serviesData1.push(props.chartData[index].researchCount)
            serviesData2.push(props.chartData[index].focusOrgResearchCount)
            serviesData3.push(props.chartData[index].orgResearchCount)
            serviesData4.push(props.chartData[index].orgResearchNumber)
            serviesData5.push(props.chartData[index].focusOrgResearchNumber)
            serviesData6.push(props.chartData[index].increase)
        }
        const maxD = (serviesData1 || []).sort((a,b) => b - a);
        const namenum = Math.floor(100 / (props.chartData.length / (getShowNum() || 8)))
        // console.log(namenum, 'namenum');
    
        barChart.setOption({
            tooltip: {
                trigger: 'axis',
                textStyle:{
                    align:'left'
                },
                formatter:function(param:any, idx:any){
                    // console.log('jjjjjjjjjjj', props.chartData[param[0].dataIndex])
                    const item = props.chartData[param[0].dataIndex]
                    let text = ''
                    text += `<div style='margin-bottom:5px'>${param[0].name}</div>`
                    text += `<div> 
                                <span style='width:12px;height:12px;display:inline-block;border-radius:50%;margin-right:3px;background:#FF9626'></span>
                                <span style='width:120px;display:inline-block'>调研机构家数</span>
                                <span style='width:50px;display:inline-block;text-align:right'>${item.orgResearchNumber ?? '-'}</span> 
                            </div>`
                    text += `<div> 
                                <span style='width:12px;height:12px;display:inline-block;border-radius:50%;margin-right:3px;background:#466EEA'></span>
                                <span style='width:120px;display:inline-block'>调研重点机构家数</span>
                                <span style='width:50px;display:inline-block;text-align:right'>${item.focusOrgResearchNumber ?? '-'}</span> 
                            </div>`
                    text += `<div> 
                                <span style='width:12px;height:12px;display:inline-block;border-radius:50%;margin-right:3px;background:#FF9626'></span>
                                <span style='width:120px;display:inline-block'>行业被调研次数</span>
                                <span style='width:50px;display:inline-block;text-align:right'>${item.researchCount ?? '-'}</span> 
                            </div>`
                    text += `<div> 
                                <span style='width:12px;height:12px;display:inline-block;border-radius:50%;margin-right:3px;background:#FF9626'></span>
                                <span style='width:120px;display:inline-block'>行业被调研公司家数</span>
                                <span style='width:50px;display:inline-block;text-align:right'>${item.stockNumber ?? '-'}</span> 
                            </div>`
                    // text += `<div> 
                    //             <span style='width:12px;height:12px;display:inline-block;border-radius:50%;margin-right:3px;background:#466EEA'></span>
                    //             <span style='width:120px;display:inline-block'>重点机构来访量</span>
                    //             <span style='width:50px;display:inline-block;text-align:right'>${item.focusOrgResearchCount ?? '-'}</span> 
                    //         </div>`
                    // text += `<div> 
                    //             <span style='width:12px;height:12px;display:inline-block;border-radius:50%;margin-right:3px;background:#FF9626'></span>
                    //             <span style='width:120px;display:inline-block'>行业调研次数</span>
                    //             <span style='width:50px;display:inline-block;text-align:right'>${item.orgResearchCount ?? '-'}</span> 
                    //         </div>`
                    
                    
                    text += `<div> 
                                <span style='width:12px;height:12px;display:inline-block;border-radius:50%;margin-right:3px;background:#FF9626'></span>
                                <span style='width:120px;display:inline-block'>行业区间涨幅</span>
                                <span class='${item.increase > 0 ? "red" : "green"}' style='width:50px;display:inline-block;text-align:right'>
                                    ${item.increase > 0 ? '+' : ''}${item.increase ? item.increase+'%' : '-'}
                                </span> 
                            </div>`
                    
                    // for (let index = 0; index < props.chartData.length; index++) {
                    //     xData.push(props.chartData[index].code)
                    //     serviesData1.push(props.chartData[index].researchCount)
                    //     serviesData2.push(props.chartData[index].focusOrgResearchCount)
                    //     serviesData3.push(props.chartData[index].orgResearchCount)
                    //     serviesData4.push(props.chartData[index].orgResearchNumber)
                    //     serviesData5.push(props.chartData[index].focusOrgResearchNumber)
                    //     serviesData6.push(props.chartData[index].increase)
                    // }
                    // for (let index = 0; index < 6; index++) {
                    //     const element = param[index];
                    //     if( element.seriesName === '行业区间涨幅' ){
                    //         text += `<div> 
                    //                     <span style='width:12px;height:12px;display:inline-block;border-radius:50%;margin-right:3px;background:${element.color}'></span>
                    //                     <span style='width:120px;display:inline-block'>${element.seriesName}</span>
                    //                     <span class='${element.value > 0 ? "red" : "green"}' style='width:50px;display:inline-block;text-align:right'>
                    //                         ${element.value > 0 ? '+' : ''}${element.value ? element.value+'%' : '-'}
                    //                     </span> 
                    //                 </div>`
                    //     }else{
                    //         text += `<div> 
                    //                     <span style='width:12px;height:12px;display:inline-block;border-radius:50%;margin-right:3px;background:${element.color}'></span>
                    //                     <span style='width:120px;display:inline-block'>${element.seriesName}</span>
                    //                     <span style='width:50px;display:inline-block;text-align:right'>${element.value}</span> 
                    //                 </div>`
                    //     }
                    // }
    
                    // for (let index = 0; index < param.length; index++) {
                    //     const element = param[index];
                    //     if( element.seriesName === '行业区间涨幅' ){
                    //         text += `<div> 
                    //                     <span style='width:12px;height:12px;display:inline-block;border-radius:50%;margin-right:3px;background:${element.color}'></span>
                    //                     <span style='width:120px;display:inline-block'>${element.seriesName}</span>
                    //                     <span class='${element.value > 0 ? "red" : "green"}' style='width:50px;display:inline-block;text-align:right'>
                    //                         ${element.value > 0 ? '+' : ''}${element.value ? element.value+'%' : '-'}
                    //                     </span> 
                    //                 </div>`
                    //     }else{
                    //         text += `<div> 
                    //                     <span style='width:12px;height:12px;display:inline-block;border-radius:50%;margin-right:3px;background:${element.color}'></span>
                    //                     <span style='width:120px;display:inline-block'>${element.seriesName}</span>
                    //                     <span style='width:50px;display:inline-block;text-align:right'>${element.value}</span> 
                    //                 </div>`
                    //     }
                    // }
                    return text
                }
            },
            grid: {
                top: '30px',
                left: '40px',
                right: '40px',
                bottom: '20px',
                containLabel: true
            },
            legend: {
                show: false,
                data: ['调研总数', '重点机构调研总数'],
                icon: 'circle',
                x: '0',
                left: '0',
                top: '0',
                itemGap: 30,
                textStyle: {
                    align: 'left', // 这个是可以左右调整的
                    fontSize: 14,// 字体大小
                    color:'#999999'
                }
            },
            toolbox: {
                show: true
            },
            dataZoom: [
                {
                    type: 'slider',
                    start: 0,
                    end: namenum,
                    realtime: true,
                    handleSize: 0,
                    height: 8,
                    bottom: 0,
                    fillerColor: "rgba(17, 100, 210, 0.42)", // 滚动条颜色
                    borderColor: "rgba(17, 100, 210, 0.12)",
                    showDetail: false,
                },
                {
                    type: "inside",
                    start: 0,
                    end: 40,
                    zoomOnMouseWheel: false,
                    moveOnMouseWheel: true,
                    moveOnMouseMove: true
                }
            ],
            calculable: true,
            xAxis: [
                {
                    type: 'category',
                    boundaryGap: false,
                    data: xData,
                    axisTick: { show: false },
                    axisLine: { show: false },
                    axisLabel: {
                    //x轴文字的配置
                        show: true,
                        interval: 0,//使x轴文字显示全
                    }
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    // alignTicks: false,
                    max: maxD[0],
                    min: 0,
                    splitLine: { show: false },
                    axisLabel: { show: false }
                }
            ],
            series: [
                {
                    name: '机构来访量',
                    barGap: '80%',
                    barWidth: 15, // 柱子宽度
                    type: 'bar',
                    data: serviesData1,
                    itemStyle: {
                        normal: {
                            barBorderRadius: [4, 4, 0, 0],
                            label: {
                            formatter: '{c}' + '次',
                            show: true,
                            position: 'top',
                            textStyle: {
                                fontWeight: 'bolder',
                                fontSize: '12',
                                color: '#FF9626'
                            }
                            },
                            color: '#FF9626',
                            opacity: 1
                        }
                    }
                },
                {
                    name: '重点机构来访量',
                    type: 'bar',
                    barWidth: 15, // 柱子宽度
                    data: serviesData2,
                    itemStyle: {
                        normal: {
                            barBorderRadius: [4, 4, 0, 0],
                            label: {
                                formatter: '{c}' + '次',
                                show: true,
                                position: 'top',
                                textStyle: {
                                    fontWeight: 'bolder',
                                    fontSize: '12',
                                    color: '#466EEA'
                                }
                            },
                            color: '#466EEA',
                            opacity: 1
                        }
                    }
                },
                // {
                //     name: '行业调研次数',
                //     type: 'line',
                //     symbol: 'none', 
                //     data: serviesData3,
                //     lineStyle:{
                //         normal: {
                //             width: 0
                //         }
                //     },
                //     itemStyle: {
                //         normal: {
                //             color: '#FF9626',
                //             opacity: 1
                //         }
                //     }
                // },
                // {
                //     name: '行业调研机构家数',
                //     type: 'line',
                //     symbol: 'none',
                //     data: serviesData4,
                //     lineStyle:{
                //         normal: {
                //             width: 0
                //         }
                //     },
                //     itemStyle: {
                //         normal: {
                //             color: '#FF9626',
                //             opacity: 1
                //         }
                //     }
                // },
                // {
                //     name: '重点机构家数',
                //     type: 'line',
                //     symbol: 'none',
                //     data: serviesData5,
                //     lineStyle:{
                //         normal: {
                //             width: 0
                //         }
                //     },
                //     itemStyle: {
                //         normal: {
                //             color: '#466EEA',
                //             opacity: 1
                //         }
                //     }
                // },
                // {
                //     name: '行业区间涨幅',
                //     type: 'line',
                //     symbol: 'none',
                //     data: serviesData6,
                //     lineStyle:{
                //         normal: {
                //             width: 0
                //         }
                //     },
                //     itemStyle: {
                //         normal: {
                //             color: '#FF9626',
                //             opacity: 1
                //         }
                //     }
                // },
            ]
        } as EChartsOption)
        chart.value = barChart

        barChart.on('click', (params:any) => {
            window.open(`/investment/research/list?industryName=${params['name']}&isPointOrg=${params['seriesIndex']}&timeLevel=${props.selectedTime}&beginDate=${props.beginDate}&endDate=${props.endDate}`)
        })
    }

    onMounted(() => {
        mounted()
        nextTick(() => {
            initChart()
        })
    })

    onBeforeUnmount(() => {
        beforeDestroy()
    })

    onActivated(() => {
        activated()
    })

    onDeactivated(() => {
        deactivated()
    })

    return {

    }
}
})
</script>
  
<style lang="scss" scoped>
.list{
display: flex;
.item{
    margin-right: 30px;
    &:last-child{
    .circle{
        background: #3C7FEE;
    }
    }
    .circle{
    width: 12px;
    height: 12px;
    background: #FF9625;
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;
    }
}
}
.content{
display: flex;
text-align: center;
// margin-top: 30px;
position: relative;
.left{
    width: 151px;
    height: 193px;
    background: #F6F8FB;
    margin-right: 80px;
    position: absolute;
    top: 60px;
    .des{
    font-size: 18px;
    font-family: PingFang;
    font-weight: 500;
    color: #999999;
    &:nth-child(1){
        margin: 25px 0 16px;
    }
    &:nth-child(3){
        margin: 25px 0 18px;
    }
    }
    .num{
    font-size: 20px;
    font-family: PingFang;
    font-weight: 800;
    color: #FB160C;
    &:nth-child(4){
        padding-bottom: 22px;
    }
    }
}
}
</style>
  