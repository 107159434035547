
import { defineComponent, reactive, toRefs, ref, nextTick } from 'vue'
import pagingComponent from '@/components/pagination/Index.vue'
import SearchForm from './SearchForm.vue'
import CDate from '@/components/new-title/Index.vue'
import ChartE from '../chart/ChartE.vue'
import { getFixedIncrList } from '@/apis/fixed-increase'
import { bigNumberTransform } from '@/utils/data-format'
export default defineComponent({
  props: {
    tableList: {
      type: Array,
      default: []
    },
    textStatus: {
      type: String,
      default: ''
    }
  },
  components: {
    pagingComponent,
    SearchForm,
    ChartE,
    CDate
  },
  setup(props) {
    const sf = ref(null)
    const cd = ref(null)
    const dataMap = reactive({
      loading: true,
      tableData: {},
      boardType: 'swl1'
    })
    let params = {
      // pageNum: '1',
      // pageSize: '10',
      // market: 'ALL',
      // timeUnit: 'WEEKS',
      // num: '1',
      orderBy: 'date'
    }
    const fetchData = async (value: any) => {
      if(value.pageNum){
        params['pageNum'] = value.pageNum
      }
      if(value.pageSize){
        params['pageSize'] = value.pageSize
      }
      if(value.timeUnit){
        params['timeUnit'] = value.timeUnit
      }
      if(value.num){
        params['num'] = value.num
      }
      dataMap.loading = true
      let marginList:any = await getFixedIncrList(params)
      marginList.data.list.map((item:any,idx:any)=>{
        item.index = idx
      })  
      dataMap.tableData = marginList.data;
      dataMap.loading = false
    }
    const sortChange = (column: any) => {
      let isAsc = column.order === "ascending" ? 'ASC' : 'DESC';
      params['orderBy'] = column["prop"]
      params['order'] = isAsc
      fetchData({});
    };
    const addClass = (column:any) => {
      if( column.column.property === params.orderBy ){
        return 'sort-bg'
      }
    }
    const amount = (value: any, fixed = 2, unit = '元') => {
      if (unit == '万') {
        value = value * 10000
      } else if (unit == '亿') {
        value = value * 100000000
      }
      if (Math.abs(value / 100000000) >= 1) {
        return (value / 100000000).toFixed(fixed) + '亿'
      }
      if (Math.abs(value / 10_000) >= 1) {
        return (value / 10000).toFixed(fixed) + '万'
      }
      return value
    }
    const goDetail = (item: any, index: any) => {
      if (index.label === '个股名称/代码') {
        const path = '/marginTrading/shares'
        window.open( `/investment${path}?name=${item.stockName}&code=${item.stockCode}`)
      } else if (index.label === '所属行业') {
        const path = '/marginTrading/industry'
        window.open( `/investment${path}?code=${item.boardName}&boardType=swl2`)
      }
    }

    const changeHandle = (param: Object, isAjax:any) => {
      console.log('vvvvvvvvvv',param,isAjax)
      if( param['timeLevel'] === 0 ){
        delete params['timeUnit']
        delete params['num']
        params['customDate'] = param['dateFrom'] + ':' + param['dateTo']
        if( params['pageNum'] ){
          if( !isAjax ){
            fetchData({})
          }
        }
      }else{
        delete params['customDate']
        params['timeUnit'] = param['timeUnit']
        params['num'] = param['num']
        if( params['pageNum'] ){
          if( !isAjax ){
            fetchData({})
          }
        }
      }
    }

    const onSearch = (param: Object) => {
      params = {
        ...params,
        ...param,
      }
      fetchData({});
    }

    const selectedHy = (time:any, param: Object) => {
      // console.log(999, (cd.value as any).setTime(time))
      nextTick(() => {
        (cd.value as any).setTime(time)
      })
      nextTick(() => {
        (sf.value as any).selectedHy(param)
        dataMap.boardType = param['boardType']
      })
    }

    const selectedSz = (param: any) => {
      (sf.value as any).selectedSz(param)
    }

    const dataFormat = (val: any, character:any) => {
      if( val === null ){
        return '-'
      }
      val = Number(val)
      if( val > 0 ){
        return '+' + val.toFixed(2) + '%'
      }else{
        return val.toFixed(2) + '%'
      }
    }
    
    return {
      ...toRefs(dataMap),
      fetchData,
      sortChange,
      addClass,
      amount,
      goDetail,
      changeHandle,
      dataFormat,
      bigNumberTransform,
      onSearch,
      selectedHy,
      selectedSz,
      sf,
      cd
    }
  }
})
