<template>
  <div
    :id="id"
    :class="className"
    :style="{height: height, width: width}"
  />
</template>

<script lang="ts">
import { defineComponent, onActivated, onBeforeUnmount, onDeactivated, onMounted, nextTick, watch } from 'vue'
import resize from '@/components/charts/mixins/resize'
import { init, EChartsOption } from 'echarts'
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '270px',
      required: true
    },
    chartData: {
      type: Object,
      default: {}
    },
    selectedTime: {
      type: String || Number,
      default: ''
    },
  },
  setup(props) {
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()

    watch(props, (newName, oldName) => {
      initChart()
    });

    // let serviesData1:any = []
    // let serviesData2:any = []

    const initChart = () => {
      const chartData = props.chartData
      if( !chartData.length ){
        return false;
      }
      const barChart = init(document.getElementById(props.id) as HTMLDivElement)
      const xData = Array.from((chartData as any), ({date})=> date)
      const serviesData1 = Array.from((chartData as any), ({szAmt})=> szAmt)
      const serviesData2 = Array.from((chartData as any), ({shAmt})=> shAmt)
      const serviesData3 = Array.from((chartData as any), ({shIndex})=> shIndex)
      let legendselect:any = { '沪市' : true, '深市': true, '上证指数': true }
      let yMin = parseInt(JSON.parse(JSON.stringify(serviesData3)).sort()[0])
      let yMax = parseInt(JSON.parse(JSON.stringify(serviesData3)).sort((a:any,b:any)=>b-a)[0]) + 50
      yMin = yMin > 50 ? yMin-50 : 0

      const getOption = () => {
        console.log('获取', legendselect['深市'])
        return {
          tooltip: {
            trigger: 'axis',
            formatter:function(param:any){
              let text = ''
              // text += `<div style='margin-bottom:5px'>${param[0].name}</div>`
              for (let index = 0; index < param.length; index++) {
                const element = param[index];
                
                text += `<div> 
                    <span style='width:10px;height:10px;display:inline-block;border-radius:50%;margin-right:3px;background:${element.color}'></span>
                    <span style='width:70px;display:inline-block;color:#666'>${element.seriesName}</span>
                    <span style='width:50px;display:inline-block;text-align:right;color:#333'>${element.value}${element.seriesName !== '上证指数' ? '亿': ''}</span> 
                </div>`
              }
              return text
            }
          },
          grid: {
            top: '30px',
            left: '30px',
            right: '0%',
            bottom: '40px',
            containLabel: true
          },
          legend: {
            icon: 'line',
            itemWidth: 8,  // 设置宽度
            itemHeight: 3, // 设置高度
            x: 'center',
            bottom: '0',
            itemGap: 30,
            textStyle: {
              fontSize: 12,// 字体大小
              color:'#666666'
            }
          },
          toolbox: {
            show: true
          },
          calculable: true,
          xAxis: [
            {
              type: 'category',
              data: xData,
              axisTick: { show: false },
              axisLabel: {
                //x轴文字的配置
                show: true,
                interval: 0,//使x轴文字显示全
                rotate: window.innerWidth < 1400 ? 45 : 0,
              },
              axisLine: {
                  show: true,
                  lineStyle: {
                      color: "#ccc",
                      // width: 0,
                      // type: "solid"
                  }
              },
            }
          ],
          yAxis: [
            {
              type: 'value',
              name: '上证指数',
              min: yMin,
              // max: yMax,
              // min: 2,
              splitLine: { show: false, lineStyle: { type: 'dashed' } },
              // axisTick: { show: false },
              // axisLabel: { show: false }
            },
            {
              type: 'value',
              name: '',
              axisLabel:{
                show: false,
              },
              splitLine: { show: true, lineStyle: { type: 'dashed' } },
              // splitLine: { show: false },
              // axisTick: { show: false },
              // axisLabel: { show: false }
            }
          ],
          series: [
            {
              name: '沪市',
              barGap: '80%',
              barWidth: '35%', // 柱子宽度
              type: 'bar',
              data: serviesData1,
              yAxisIndex: 1,
              stack: 'one',
              itemStyle: {
                normal: {
                  color: '#3B7FEE',
                  opacity: 1,
                  label: {
                    show: window.innerWidth < 1380 || legendselect['深市'] || legendselect['深市'] === undefined ? false : true,
                    formatter: function(val:any) {
                      return chartData[val.dataIndex].szAmt + '亿'
                    },
                    position: 'top',
                    textStyle: {
                      fontWeight: 'bolder',
                      fontSize: '100%',
                      color: '#3B7FEE'
                    }
                  },
                }
              },
            },
            {
              name: '深市',
              barGap: '80%',
              barWidth: '35%', // 柱子宽度
              type: 'bar',
              data: serviesData2,
              yAxisIndex: 1,
              stack: 'one',
              itemStyle: {
                normal: {
                  label: {
                    show: window.innerWidth < 1380 ? false : true,
                    formatter: function(val:any) {
                      if( !legendselect['沪市'] ){
                        return chartData[val.dataIndex].shAmt + '亿'
                      }
                      return chartData[val.dataIndex].szAmt + chartData[val.dataIndex].shAmt + '亿'
                    },
                    position: 'top',
                    textStyle: {
                      fontWeight: 'bolder',
                      fontSize: '100%',
                      color: '#3B7FEE'
                    }
                  },
                  color: '#C2D7FA',
                  opacity: 1
                }
              },
            },
            {
              name: '上证指数',
              barGap: '80%',
              type: 'line',
              data: serviesData3,
              yAxisIndex: 0,
              itemStyle: {
                normal: {
                  barBorderRadius: [4, 4, 0, 0],
                  color: '#FE9625',
                  opacity: 1
                }
              },
            }
          ]
        }
         
      }
      barChart.setOption(getOption() as EChartsOption)
      chart.value = barChart

      barChart.on('legendselectchanged', (e:any) => {
        legendselect = e.selected
        barChart.setOption(getOption())
      })

      // barChart.on('click', (params:any) => {
      //   console.log(params)
      //   window.open(`/investment/research/list?industryName=${params['name']}&isPointOrg=${params['seriesIndex']}&time=${props.selectedTime}`)
      // })
    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {

    }
  }
})
</script>
