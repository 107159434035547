<template>
  <div class="app-container">
    
    <div class="page-nav">机构调研</div>

    <!-- 搜索框 -->
    <Search class="search" :path='"/research/list"' :isResearch='true'></Search>

    <!-- 机构调研 -->
    <div class="bg">
      <CTitle :mode="1" title="机构调研" />
      <div class="institutional-research-main">
        <SurveyFrequency />
      </div>
    </div>

    <!-- 机构新增调研覆盖个股行业分布 -->
    <div class="bg">
        <!-- :smallTitle='`(截至 ${moment().format("YYYY年MM月DD日")}）`' -->
      <NewTitle
        title="机构新增调研覆盖个股行业分布"
        :showTime='false'
        :smallTitle='`(截至 2022年12月31日）`'
      />
      <BarChartI 
        id='BarChartI'
        :chartData='stockList1'
        v-if="stockList1.length"
      />
      <el-empty description="暂无数据" :image='require("@/assets/images/empty.svg")' :image-size="120" v-else></el-empty>
    </div>

    <!-- 机构新增调研个股总市值分布 -->
    <div class="bg">
      <NewTitle
        title="机构新增调研个股总市值分布"
        :smallTitle='`(截至 2022年12月31日）`'
        :showTime='false'
      />
      <BarChartJ
        id='BarChartJ'
        :chartData='stockList2'
        v-if="stockList2.length"
      />
      <el-empty description="暂无数据" :image='require("@/assets/images/empty.svg")' :image-size="120" v-else></el-empty>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue'
import CTitle from "@/components/title/Index.vue"
import NewTitle from '@/components/new-title/Index.vue'
import Search from '@/components/search-input/Index.vue'
import BarChartI from '@/components/charts/BarChartI.vue'
import BarChartJ from '@/components/charts/BarChartJ.vue'
import SurveyFrequency from '@/views/research/components/SurveyFrequency.vue'

import { getSurveyStockFirstCount, getSurveyMarketCapFirstCount } from '@/apis/research'
import moment from 'moment'

export default defineComponent({
  components: {
    CTitle,
    NewTitle,
    Search,
    BarChartI,
    BarChartJ,
    SurveyFrequency
  },
  setup() {
    const dataMap = reactive({
      ajaxRes: false,
      stockList1: [],
      stockList2: []
    })
    const fetchData = async () => {
      let data1 = await getSurveyStockFirstCount({ })
      dataMap.stockList1 = (data1 as any).data

      let data2 = await getSurveyMarketCapFirstCount({ })
      dataMap.stockList2 = (data2 as any).data


      dataMap.ajaxRes = true;
      
    }
    fetchData();

    return {
      moment,
      ...toRefs(dataMap)
    }
  }
})
</script>
<style lang='scss' scoped>
.page-nav{
  // margin: 20px 0;
  font-size: 14px;
  font-weight: 500;
  color: #386FF2;
}
.search{
  margin-top: 20px!important;
}
.bg{
  background: #fff;
  padding: 20px;
  margin-top: 30px;
}
.institutional-research-main{
  margin-top: 20px;
}

</style>
