<template>
  <div
    :id="id"
    :class="className"
    :style="{height: height, width: width}"
  />
</template>

<script lang="ts">
import { defineComponent, onActivated, onBeforeUnmount, onDeactivated, onMounted, nextTick, watch } from 'vue'
import resize from './mixins/resize'
import { init, EChartsOption } from 'echarts'
import moment from 'moment';
import * as echarts from 'echarts'
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '260px',
      required: true
    },
    chartData: {
      type: Object,
      default: []
    }
  },
  setup(props) {
    watch(props, (newName, oldName) => {
      initChart()
    });
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()
    const initChart = () => {
      if( !props.chartData.length ){
        return false
      }
      const barChart = init(document.getElementById(props.id) as HTMLDivElement)
      let xData = [];
      let serviesData1 = [];
      let mapData = JSON.parse(JSON.stringify(props.chartData))
      for (let index = 0; index < mapData.length; index++) {
      //   // mapData[index].maxGoalPrice = Number(mapData[index].maxGoalPrice.toFixed(2))
      //   // mapData[index].minGoalPrice = Number(mapData[index].minGoalPrice.toFixed(2))

        xData.push(mapData[index]['industry']) 
        serviesData1.push(mapData[index].stockCount)
      //   serviesData2.push(Number(mapData[index].maxGoalPrice - mapData[index].minGoalPrice).toFixed(2) )
      //   serviesData3.push(mapData[index].avgGoalPrice)
      }
      barChart.setOption({
        legend:{
          // top: 20,
          left: '15px',
          icon: 'circle',
          // itemWidth: 14,
          // itemHeight: 5,
          // itemGap: 13,
          // data: ['CMCC', 'CTCC', 'CUCC'],
          // right: '4%',
          textStyle: {
            fontSize: 14,
            color: '#666'
          }
        },
        tooltip: {
          trigger: 'axis',
          // axisPointer: {
          //   type: 'shadow'
          // },
          // backgroundColor: "rgba(21,84,155,0.67)", // 修改背景颜色
          // borderColor: "transparent", // 修改边框颜色
          // borderRadius: 10,
          // formatter:function(param:any){
          //   // console.log(mapData[param[0].dataIndex].maxGoalPrice)
          //   // 利用循环更改显示的内容
          //   let list = param.length
          //   // let Oparam=param
          //   let txt = '<div style="padding-bottom:10px;color:#fff">' + param[0].name+"</div>"
          //   for (var i=0;i<list;i++){
          //     if(i==list-1){
          //       //最小值
          //       txt += '<div style="color:#fff">' + param[i].seriesName + "：" + mapData[param[0].dataIndex].minGoalPrice.toFixed(2) + '</div>'
          //     }else if(i === 0){
          //       txt += '<div style="padding-bottom:10px;color:#fff">' +  param[i].seriesName + "：" + mapData[param[0].dataIndex].maxGoalPrice.toFixed(2) + '</div>'
          //     }else{
          //       txt += '<div style="padding-bottom:10px;color:#fff">' + param[i].seriesName + "：" + mapData[param[0].dataIndex].avgGoalPrice.toFixed(2) + '</div>'
          //     }
          //   }
          //   return txt
          // }
        },
        grid: {
          left: '20px',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: xData,
            axisTick: {
              show:false,
              alignWithLabel: true
            },
            axisLabel: {
              color:  'rgba(66, 129, 236, 0.5)',
              interval: 0,
              formatter: function(value:any) {
                return value.split('').join('\n')
              },
            },
            axisLine: {
              show:true,
              lineStyle: {
                color: '#EEEEEE',
                width:1
              }
            },
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLine: {
              show:false,
              lineStyle: {
                color: '#E8E8E8',
                width:1
              }
            },
            axisLabel: {
              color:  '#2E2E2E',
            },
          }
        ],
        series: [
          {
            name: '新增调研覆盖个股数量（家）',
            data: serviesData1,
            type: 'bar',
            barWidth: '10',
            color: '#3B7FEE'
          }
        ]
      } as EChartsOption)
      chart.value = barChart

      barChart.on('click', (params:any) => {
        // const beginDate = moment().startOf('year').format('YYYY-MM-DD');
        // const endDate = moment().format('YYYY-MM-DD');
        const beginDate = '2022-01-01';
        const endDate = '2022-12-31';
        window.open(`/investment/research/list?industryName=${params['name']}&isFirstFollow=1&timeLevel=5&beginDate=${beginDate}&endDate=${endDate}`)
      })
    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {

    }
  }
})
</script>

<style lang="scss" scoped>
.list{
  display: flex;
  .item{
    margin-right: 30px;
    &:last-child{
      .circle{
        background: #3C7FEE;
      }
    }
    .circle{
      width: 12px;
      height: 12px;
      background: #FF9625;
      border-radius: 50%;
      display: inline-block;
      margin-right: 8px;
    }
  }
}
.content{
  display: flex;
  text-align: center;
  margin-top: 54px;
  .left{
    width: 134px;
    height: 193px;
    background: #F6F8FB;
    margin-right: 80px;
    .des{
      font-size: 18px;
      font-family: PingFang;
      font-weight: 500;
      color: #999999;
      &:nth-child(1){
        margin: 30px 0 20px;
      }
      &:nth-child(3){
        margin: 27px 0 20px;
      }
    }
    .num{
      font-size: 20px;
      font-family: PingFang;
      font-weight: 800;
      color: #FB160C;
      &:nth-child(4){
        padding-bottom: 22px;
      }
    }
  }
}
</style>
