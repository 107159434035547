<template>
  <div class="">
    <template v-if="stockList1">
      <CTitle :mode='6' title="投资亮点" class="red-title"></CTitle>
      <ul class="t-list">
        <li v-for="(item,idx) in stockList1" :key="idx" :class="item.type !== 1 ? 'none' : ''">
          {{type}}
          <div>{{item.tags}}</div>
          <div>{{item.content}}</div>
        </li>
      </ul>
      <div class="green">
        <CTitle :mode='6' title="风险提示" class="mt50"></CTitle>
        <ul class="t-list">
          <li v-for="(item,idx) in stockList1" :key="idx" :class="item.type !== 2 ? 'none' : ''">
            <div>{{item.tags}}</div>
            <div>{{item.content}}</div>
          </li>
        </ul>
      </div>
      <div class="b-content">
        <div class="left">
          <CTitle :mode='6' title="大事提醒" style="margin-top:50px"></CTitle>
          <div class="tab">
            <ul>
              <li :class="dstxActive === idx ? 'active' : ''" @click="swichItem(idx)" v-for="(item,idx) in dstxList" :key='idx'>{{item}}</li>
            </ul>
          </div>
          <div class="tab-left-content">
            <div class="content_item_events_content_list" v-if="sentimentInfo.length && sentimentInfo[clicktype].length>0">
              <div class="content_item_events_content_item" v-for="(item,index) in sentimentInfo[clicktype]" :key="index">
                <template v-if="index < 3 || morePerformance">
                  <div class="content_item_events_content_item_left">{{item.infoTime}}</div>
                  <div class="content_item_events_content_item_right" >
                    <div  class="content_item_events_content_item_right_icon"></div>
                    <div class="content_item_events_content_item_right_box">
                      <div class="content_item_events_content_item_right_top"  v-if="clicktype==1||clicktype==2||clicktype==3||clicktype==4">
                        {{clicktype==1?item.noticeName:clicktype==3?'股票激励计划':clicktype==4?'限售股解禁':clicktype==2?item.title:''}}
                      </div>
                      <div class="content_item_events_content_item_right_text"  v-if="clicktype==0">{{item.title}}</div>
                      <div class="content_item_events_content_item_right_text text"  v-if="clicktype==1">{{item.content}}</div>
                      <div class="content_item_events_content_item_right_text text"  v-if="clicktype==4">
                        解禁数量{{item.floatShare}},占总资本{{item.floatRatio}}
                      </div>
                      <div v-if="clicktype==2">
                        <div class="content_item_events_content_item_right_text2">{{item.text}}</div>
                        <div class="content_item_events_content_item_right_text text2" v-for="(innerItem,innerIdx) in item.subTips" :key="innerIdx">{{innerItem.text}}3333</div>
                      </div>
                      <div class="content_item_events_content_item_right_text1"  v-if="clicktype==3">
                        <div class="content_item_events_content_item_right_text1_text {{chakan?'type':''}}">{{item.text}}</div>
                        <!-- <div class="content_item_events_content_item_right_text1_btn" bindtap="clickzhankai">
                          <div class="btntext">展开全文</div>
                          <div class="btnicon {{chakan?'type':''}}"></div>
                        </div> -->
                      </div>
                      <div class="content_item_events_content_item_right_foot"  v-if="clicktype==0">
                        <div class="content_item_events_content_item_right_foot_left">{{item.typeName}}</div>
                        <div class="content_item_events_content_item_right_foot_right">来源：{{item.source}}</div>
                      </div>
                    </div>
        
                  </div>
                </template>
              </div>
              <div class="content_item_events_content_foot" v-if="sentimentInfo[clicktype].length>3" @click="getmoreInfo">{{morePerformance?'收起更多':'显示更多'}}</div>
            </div>
            <div v-else class="wushuju_box">
              <img class="wushuju" src="http://resource.jufxt.com/static/zhengu/home/wushuju.png" />
              <div class="wushuju_text">该项目暂无数据</div>
            </div>
          </div>
        </div>
        <div class="right">
          <CTitle :mode='6' title="盈利能力" style="margin-top:50px"></CTitle>
          <div class="tab">
            <ul>
              <li :class="profitType === index ? 'active' : ''" v-for="(item,index) in profitTypearr" :key="index" @click="swichYlnlItem(index)">{{item}}</li>
            </ul>
          </div>
          <div class="tab-right-content">
            <div class="content_item_mechanism">
              <div v-if="profitType!=0">
                <div class="profitType_typebox">
                  <div class="profitType_typebox_item" :class="profitType1==index?'type':''" v-for="(item,index) in profitType==1?returnRate:profitType==2?profitMargintext:costRatetext" :key="index" @click="setprofitType1(index)">
                    <div>{{item}}</div>
                    <div class="profitType_typebox_item_icon"></div>
                  </div>
                </div>
              </div>

              
              <div v-if="profitType==0||(profitType1arr1.length>1||profitType1arr2.length>1)">
                <div class="content_item_mechanism_line">
                  <div class="content_item_mechanism_line_header">
                    <div class="content_item_mechanism_line_header_item">
                      <div class="content_item_mechanism_line_header_item_icon"></div>
                      <div class="content_item_mechanism_line_header_item_text" v-if="dupontInfo.roeDupont1">{{dupontInfo.roeDupont1.name}}</div>
                    </div>
                    <div class="content_item_mechanism_line_header_item">
                      <div class="content_item_mechanism_line_header_item_icon"></div>
                      <div class="content_item_mechanism_line_header_item_text" v-if="dupontInfo.roeDupont1">{{dupontInfo.roeDupont2.name}}</div>
                    </div>
                  </div>
                </div>
              
              </div>
              <div v-if="profitType==0">
                <div class="content_item_mechanism_line_box">
                    <BarChartD id='chart-ylnl11' :profitabilityInfo='profitabilityInfo' :profitType='profitType' :profitTypeobj='profitTypeobj' :dupontInfo='dupontInfo' :profitType1arr1='profitType1arr1' :profitType1arr2='profitType1arr2'></BarChartD>
                  </div>
                  <div class="content_item_profit_line_box"></div>
                <div class="content_item_profit_table">
                <div>
                  <div class="content_item_profit_table_header">
                    <div class="content_item_profit_table_header_item" v-for="(item,index) in profitTable" :key="index">{{item}}</div>
                  </div>
                </div>
                  <div class="content_item_profit_table_content" v-if="dupontInfo.roeDupont1">
                    <div class="content_item_profit_table_content_item" v-for="(item,index) in 2" :key="index">
                      <div class="text text1">{{dupontInfo['roeDupont'+(index+1)].roe}}%</div>
                      <div class="text0">≈</div>
                      <div class="text text2">{{dupontInfo['roeDupont'+(index+1)].netprofitmarginttm2}}%</div>
                      <div class="text0">x</div>
                      <div class="text text3">{{dupontInfo['roeDupont'+(index+1)].turnoverttm}}</div>
                      <div class="text0">x</div>
                      <div class="text text4">{{dupontInfo['roeDupont'+(index+1)].assetstoequity}}</div>
                    </div>
                  </div>
                </div>
              
              </div>
              <div v-if="profitType!=0">
                <div v-if="profitType1arr1.length>1">
                  <scroll-div class="content_item_profit">
                    <div class="content_item_mechanism_line_box" >
                        <BarChartD id='chart-ylnl22' :profitabilityInfo='profitabilityInfo' :profitType='profitType' :profitTypeobj='profitTypeobj' :dupontInfo='dupontInfo' :profitType1arr1='profitType1arr1' :profitType1arr2='profitType1arr2'></BarChartD>
                    </div>

                    <div class="content_item_profit_table" >
                      <div :style="{width:profitType1arr1.length * 135 + 'px'}">
                        <div class="content_item_profit_table_header 111" >
                          <div class="content_item_profit_table_header_item" v-for="(item,index) in profitType1arr1" :key="index" id='{{"report"+index}}'>{{item.reportPeriodtext}}年</div>
                        </div>
                        
                        <div class="content_item_profit_table_header" v-for="(item,idx) in 2"  :key="idx">
                          <div class="content_item_profit_table_header_item"  v-for="(item,index) in idx==0?profitType1arr1:profitType1arr2" :key="index">
                          {{item.value}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </scroll-div>
                </div>
                <div v-else>
                  <div  class="wushuju_box">
                    <img   class="wushuju" src="http://resource.jufxt.com/static/zhengu/home/wushuju.png" />
                    <div class="wushuju_text">该项目暂无数据</div>
                  </div>
                </div>
              </div>
        
            </div>
          </div>
        </div>
      </div>
    </template>
    <div  class="wushuju_box" v-else>
      <img class="wushuju" src="http://resource.jufxt.com/static/zhengu/home/wushuju.png" />
      <div class="wushuju_text">该项目暂无数据</div>
    </div>
  </div>
</template>

<script lang="ts">

import { defineComponent,reactive,toRefs } from 'vue'
import CTitle from '@/components/title/Index.vue'
import BarChartD from '@/components/charts/BarChartD.vue'
import { getDiagnosticRiskInvestment, getDiagnosticSentiment, getDiagnosticPerformance, getDiagnosticMajorEvent, getDiagnosticProfitability } from '@/apis/diagnosis'
import { useRoute } from 'vue-router'

interface Role {
  code: number
}
export default defineComponent({
  props: {
    dataMap: {
      type: Array,
      default: []
    }
  },
  components: {
    CTitle,
    BarChartD
  },
  setup() {
    // const stockCode = '600751.SH'
    // const stockCode = '002821.SZ'
    const route = useRoute()
    const stockCode = route.query.code//'600519.SH'
    const dataMap = reactive({
      dstxActive:0,
      ajaxRes: false,
      stockList1: [],
      sentimentInfo: [] as Role[],
      timearr: [],
      clicktype: 0,

      morePerformance:false,
      dupontInfo:{},//杜邦分析
      profitTypearr:[
        '杜邦分析','回报率','利润率','费用率'
      ],
      returnRate:['roe','roic','roa'],
      profitMargin:['allExpense','netProfitMargin','optoor'],
      profitMargintext:['毛利率','净利率','营业率'],
      costRate:['allExpense','accountsReceivableRatios','adminExpense','rdExpense','finaExpense'],
      costRatetext:['总费用','销售费用','管理费用','研发费用','财务费用'],
      profitTypeobj:['dupontInfo','returnRate','profitMargin','costRate'],
      profitType:0,
      profitType1:0,
      profitType1arr1:[],
      profitType1arr2:[],
      profitabilityInfo:[],//盈利能力数据
      profitTable:[
        'ROM(TTM)','销售净利润', '总资产周转率','权益乘数'
      ],
    })
    
    

    // let sentimentInfo = [{"id":4158395,"stocksId":9093,"batchNum":264,"title":"金钟股份:关于2022年限制性股票激励计划授予价格确定方法的更正说明公告","typeName":"公告","source":"金钟股份","infoCode":"AN202210141579162101","infoTime":"10-14"},{"id":4158396,"stocksId":9093,"batchNum":264,"title":"金钟股份:广州市金钟汽车零件股份有限公司2022年限制性股票激励计划首次授予部分激励对象名单","typeName":"公告","source":"金钟股份","infoCode":"AN202210131579134257","infoTime":"10-13"},{"id":4158397,"stocksId":9093,"batchNum":264,"title":"金钟股份:广州市金钟汽车零件股份有限公司监事会关于公司2022年限制性股票激励计划(草案)的核查意见","typeName":"公告","source":"金钟股份","infoCode":"AN202210131579134263","infoTime":"10-13"}]


    const dstxList = ['舆情关注','公司业绩','内部交易','股权激励','大额解禁'];

    const swichItem=(idx:any)=>{
      dataMap.dstxActive = idx
      dataMap.clicktype = idx
    }

    const swichYlnlItem=(idx:any)=>{
      const { profitabilityInfo, profitTypeobj, profitType } = dataMap;

      let arr = profitabilityInfo[profitTypeobj[idx]][dataMap[profitTypeobj[idx]][0]+1]
      let arr2 = profitabilityInfo[profitTypeobj[idx]][dataMap[profitTypeobj[idx]][0]+2]

      if(arr&&arr.length>0){
        arr.forEach((item:any)=>{
          item.reportPeriodtext = item.reportPeriod.substr(0,4)
        })
      }
      // let that = this
      console.log(99999,arr)

      dataMap.profitType = idx
      dataMap.profitType1 = 0
      dataMap.profitType1arr1 = arr
      dataMap.profitType1arr2 = arr2
      
      // this.setData({
      //   profitType:e.currentTarget.dataset.index,
      //   isecbar2:false,
      //   profitType1:0,
      //   profitType1arr1:arr,
      //   profitType1arr1:arr2
      // },()=>{
      //   that.getecbar2()
      // })
      // setTimeout(() => {
        
        
      // }, 10);
    }

    const setprofitType1 = (idx:any) => {
       // console.log(e)
      const { profitabilityInfo, profitTypeobj, profitType } = dataMap;

      console.log(profitabilityInfo,profitType)
      let arr = profitabilityInfo[profitTypeobj[profitType]][dataMap[profitTypeobj[profitType]][idx]+1]
      let arr2 = profitabilityInfo[profitTypeobj[profitType]][dataMap[profitTypeobj[profitType]][idx]+2]

      if(arr&&arr.length>0){
        arr.forEach((item:any)=>{
          if(item.reportPeriod){
            item.reportPeriodtext = item.reportPeriod.substr(0,4)
          }
        })
      }
      arr = arr ? arr : []
      arr2 = arr2 ? arr2 : []
      if( !arr2 ){

      }
       console.log('arr111',arr,arr2)
        
      dataMap.profitType1 = idx
      dataMap.profitType1arr1 = arr
      dataMap.profitType1arr2 = arr2
      // let that = this
      // this.setData({
      //   profitType1:e.currentTarget.dataset.index*1,
      //   isecbar2:false,
      //   profitType1arr1:arr,
      //   profitType1arr2:arr2,

      // }, ()=>{
      //   that.getecbar2()
      // })
    }

    const getmoreInfo = () => {
      dataMap.morePerformance = !dataMap.morePerformance
    }

    const gettimetext2 = (time:any) => {
      let time2 = new Date(time)
      let yue = time2.getMonth()+1<10?('0'+(time2.getMonth()+1)):time2.getMonth()+1
      let day = time2.getDate()+1<10?('0'+(time2.getDate()+1)):time2.getDate()+1
      return yue +'-'+day
    }

    const fetchData = async () => {
      const data1 = await getDiagnosticRiskInvestment({ stockCode: stockCode })
      dataMap.stockList1 = (data1 as any).data;

      const data2 = await getDiagnosticSentiment({ stockCode: stockCode })
      const data3 = await getDiagnosticPerformance({ stockCode: stockCode })
      const data4 = await getDiagnosticMajorEvent({ stockCode: stockCode })
      const data5 = await getDiagnosticProfitability({ stockCode: stockCode })
      
      let arr = (data3 as any).data
      if(arr&&arr.length>0){
        arr.forEach((item:any)=>{
          if(item.sourceTime){
            item.infoTime = item.sourceTime.substr(5)
          }
        })
      }
      let arr2 = (data4 as any).data?.trans
      if(arr2&&arr2.length>0){
        arr2.forEach((item:any)=>{
          if(item.annDtNew){
            item.infoTime = item.annDtNew.substr(5)
          }
        })
      }
      

      let obj = (data4 as any).data?.stockUps
      if(obj && obj.length>0){
        obj.forEach((item:any)=>{
            // console.log(item)
          item.infoTime = item.date.substr(4,2)+'-'+item.date.substr(6)
        })
      }

      let obj2 = (data4 as any).data?.shares
      if(obj2 && obj2.length>0){
        obj2.forEach((item:any)=>{
          item.infoTime = gettimetext2(item.date)
        })
      }
      dataMap.sentimentInfo = [
        (data2 as any).data, 
        arr, 
        arr2, 
        obj, 
        obj2];

      dataMap.dupontInfo = (data5 as any).data?.dupontInfo
      dataMap.profitabilityInfo = (data5 as any).data;

      dataMap.ajaxRes = true;
    }
    fetchData()


    return {
      dstxList,
      swichItem,
      swichYlnlItem,
      setprofitType1,
      getmoreInfo,
      ...toRefs(dataMap),
    }
  }
})
</script>
<style scoped lang='scss'>
.t-list{
  li{
    display: flex;
    margin-bottom: 12px;
    div{
      &:first-child{
        width: 30px;
        line-height: 20px;
        text-align: center;
        background: #FCEDF0;
        border-radius: 4px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FB160C;
        margin-right: 12px;
      }
      &:last-child{
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }
    }
  }
}
.tab{
  display: inline-block;
  ul{
    display: flex;
    // border: 1px solid #DDDDDD;
    border-radius: 4px;
    overflow: hidden;
    // font-size: 0;
    li{
      width: 79px;
      height: 36px;
      line-height: 36px;
      cursor: pointer;
      background: #FFFFFF;
      text-align: center;
      // border-right: 1px solid #DDDDDD;
      color: #666666;
      border: 1px solid #DDDDDD;
      border-right: none;
      &.active{
        color: #fff;
        background: #386FF2;
        border-color: transparent;
        border-top: none;
        border-bottom: none;
      }
      &:last-child{
        // border: 0;
        border-right: 1px solid #DDDDDD;
        border-radius: 0 4px 4px 0;
      }
      &:first-child{
        // border: 0;
        // border-right: 1px solid #DDDDDD;
        border-radius: 4px 0 0 4px;
      }
    }
  }
}

.b-content{
  display: flex;
  &>div{
    // width: 550px;
    flex: 1;
  }
  .left{
    // margin-right: 160px;
    margin-right: 50px;
  }
  .right{
    margin-left: 50px;
    overflow-x: auto;
    .mode{
      margin-left: 0;
    }
  }
  .tab{
    margin-left: 12px;
  }
  .tab-right-content{
    margin-left: 12px;
  }
}


.content_item_events_content_list{
    margin-top: 30px;
}
.content_item_events_content_item{
    display: flex;
    align-items: flex-start;
}
.content_item_events_content_item_left{
    width: 40px;
    margin-right: 10px;
    white-space:nowrap;
    flex-shrink:0;
    text-align: center;
    position: relative;
    top: -6px;
    color: #AAAAAA;
    font-size: 12px;
    
}
.content_item_events_content_item_right{
    flex-shrink:1;
    /* width: 80%; */
   padding-left: 15px;
   border-left: 1px dashed #ECECEC;
   /* border-left: 2px dashed red; */
   position: relative;
   padding-bottom: 30px;
}
.content_item_events_content_item:last-child .content_item_events_content_item_right{
    border-left:  4px dashed #fff;
}
.content_item_events_content_item_right_icon{
    position: absolute;
    top: 0px;
    left: -3px;
    width: 5px;
    height: 5px;
    border-radius: 8px;
    background-color: #D8D8D8;
}
.content_item_events_content_item_right_box{
    position: relative;
    top: -10px;
}
.content_item_events_content_item_right_top{
    font-size: 18px;
    color: #333333;
    padding-bottom: 20px;
    position: relative;
    // top: -10px;
}
.content_item_events_content_item_right_text{
    background: #F6F8FA;
    padding: 8px 9px;
    color: #333333;
    font-size: 14px;
    border-radius: 10px;
    width: 100%;
    line-height: 20px;
}
.content_item_events_content_item_right_text1{
    background: #F6F8FA;
    padding: 20px;
    color: #333333;
    font-size: 30px;
    border-radius: 10px;
 
}
.content_item_events_content_item_right_text1_text{
  font-size: 16px;
}
.content_item_events_content_item_right_text1_text.type{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}
.content_item_events_content_item_right_text1_btn{
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 26px;
    color: #0278DD;
}
.content_item_events_content_item_right_text1_btn .btnicon{
    width: 10px;
    height: 10px;
    border-top: 2px solid #CFCFD0;
    border-right: 2px solid #CFCFD0;
    transform: rotate(-45deg);
    position: relative;
    top: 4px;
    margin-left: 10px;
 
}
.content_item_events_content_item_right_text1_btn .btnicon.type{
    transform: rotate(135deg);
    top: 0;
}
.content_item_events_content_item_right_text2{
    padding-top: 0;
    padding-left: 0;
    color: #333333;
    font-size: 14px;
    border-radius: 10px;
}
.content_item_events_content_item_right_text.text{
    top: 0px;
}
.content_item_events_content_item_right_text.text2{
    margin-bottom: 10px;
}
.content_item_events_content_item_right_foot{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}
.content_item_events_content_item_right_foot_left{
    color: #999999;
    font-size: 12px;
}
.content_item_events_content_item_right_foot_right{
    font-size: 12px;
    color: #999999;
}
.none{
  display: none!important;
}








.content_item_profit_table{
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  .content_item_profit_table_header{
    display: flex;
    align-items: center;
    &.type1 {
      .content_item_profit_table_header_item{
          color: #E03434;
      }
    }
    .content_item_profit_table_header_item{
      width: 135px;
      height: 46px;
      line-height: 46px;
      text-align: center;
      border: 2px solid #F4F4F4;
      border-bottom: none;
      border-right: none;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      &:last-child{
          border-right:  2px solid #F4F4F4;
      }
    }
  }
  .content_item_profit_table_content_item{
    height: 68px;
    line-height: 68px;
    border:  2px solid #F4F4F4;
    display: flex;
    color: #3B7FEE;
    &:last-child{
      border-top: none;
    }
    &:nth-child(2) .text{
      color: #FE9625;
    }
    .text{
      width: 24%;
      text-align: center;
      color: RGBA(0, 134, 251, .8);
      font-size: 14px;
    }
    .text0{
      text-align: center;
      color: #999999;
      font-size: 14px;
    }
  }
}

.profitType_typebox{
  display: flex;
  align-items: center;
  color: #c3c3c3;
  font-size: 14px;
  margin-top: 12px;
  margin-bottom: 24px;
  .profitType_typebox_item{
    height: 20px;
    line-height: 20px;
    padding: 0 20px;
    border-right: 2px solid #999999;
    cursor: pointer;
    &:last-child{
      border-right:none
    }
    &.type{
      color: #fb0a0a;
      .profitType_typebox_item_icon{
        width: 70%;
        height: 2px;
        display: block;
        background: #fb0a0a;
        margin: 0 auto;
        margin-top: 10px;
      }
    }
  }
}

.content_item_mechanism_line_header{
  display: flex;
  align-items: center;
  margin-top: 17px;
  margin-bottom: 12px;
  .content_item_mechanism_line_header_item_icon{
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #0086FB;
    margin-right: 6px;
  }
  .content_item_mechanism_line_header_item_text{
    color: #666666;
    font-size: 12px;
  }
}
.content_item_mechanism_line_header.profit .content_item_mechanism_line_header_item:first-child .content_item_mechanism_line_header_item_icon{
    background-color: #B4C9EC;
}
.content_item_mechanism_line_header.profit .content_item_mechanism_line_header_item:nth-child(2) .content_item_mechanism_line_header_item_icon{
    background-color: #A0C4FF;
}
.content_item_mechanism_line_header.profit .content_item_mechanism_line_header_item:nth-child(3) .content_item_mechanism_line_header_item_icon{
    background-color: #6FA4FA;
}
.content_item_mechanism_line_header.profit .content_item_mechanism_line_header_item:nth-child(4) .content_item_mechanism_line_header_item_icon{
    background-color: #3B7FEE;
}

.content_item_mechanism_line_header_item{
    display: flex;
    align-items: center;
}
.content_item_mechanism_line_header_item:not(:last-child){
    margin-right: 40px;
    
}

.content_item_mechanism_line_header_item:nth-child(2) .content_item_mechanism_line_header_item_icon{
    background: #FE9625;
}



.red-title{
  ::v-deep .left{
    color: #FB160C;
  }
}
.green{
  li{
    div:first-child{
      color: #02C165;
      background: #DAFEEF;
    }
  }
  ::v-deep .mode-6{
    .left{
      color: #02C165;
    }
    &::after{
      background: #02C165;
    }
  }
}




</style>
