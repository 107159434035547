
import { defineComponent, onActivated, onBeforeUnmount, onDeactivated, onMounted, nextTick, watch } from 'vue'
import resize from '@/components/charts/mixins/resize'
import { init, EChartsOption } from 'echarts'
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '300px',
      required: true
    },
    chartData: {
      type: Object,
      default: {}
    },
    selectedTime: {
      type: String || Number,
      default: ''
    },
  },
  setup(props) {
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()

    watch(props, (newName, oldName) => {
      initChart()
    });

    const initChart = () => {
      const minuteRecords = props.chartData.minuteRecords
      if( !minuteRecords ){
        return false;
      }

      const barChart = init(document.getElementById(props.id) as HTMLDivElement)
      let xData = [];
      let serviesData1 = [];
      let markData = [];
      let count = true;
      for (let index = 0; index < minuteRecords.length; index++) {
        const item = minuteRecords[index]
        xData.push(item.timeLabel)
        serviesData1.push(item.indexPrice)

        if( item.unusualBoards.length ){
          for (let innerIdx = 0; innerIdx < item.unusualBoards.length; innerIdx++) {
            const element = item.unusualBoards[innerIdx];
            let icon = ''
            let offset = []
            let position = []
            const infolen = element.boardInfo.length > 4
            const namelen = element.boardName.length > 4
            if( !count ){
              if( index < 10 ){
                icon = element.color === 'red' ? require('@/assets/images/tooltip-red-b-r.png') : require('@/assets/images/tooltip-green-b-r.png')
                offset = ['50%', '52%']
                position = [ infolen || namelen ? '5%' : '18%', '110%']
              }else{
                icon = element.color === 'red' ? require('@/assets/images/tooltip-red-b.png') : require('@/assets/images/tooltip-green-b.png')
                offset = ['-50%', '50%']
                position = [ infolen || namelen ? '5%' : '18%', infolen ? '100%' : '103%']
              }
            }else{
              icon = element.color === 'red' ? require('@/assets/images/tooltip-red.png') : require('@/assets/images/tooltip-green.png')
              offset = ['50%', '-50%']
              position = [infolen || namelen ? '7%' : '18%', infolen ? '63%' : '70%']
            }
            count = !count

            // const boardInfo = infolen ? element.boardInfo.substring(0,4) + '...' : element.boardInfo
            const boardName = namelen ? element.boardName.substring(0,4) + '...' : element.boardName
            const boardInfo1 = infolen ? element.boardInfo.substring(0,4) : element.boardInfo
            const boardInfo2 = infolen ? element.boardInfo.substring(4,element.boardInfo.length) : ''
            
            markData.push(
              {
                coord: [index, item.indexPrice],
                symbol: 'image://' + icon,
                symbolSize: 75,
                symbolOffset: offset,
                label: {
                  position: position,
                  color: '#000',  // 文字颜色
                  padding: [-40, 0, 0, 0], // 可用padding调整图片内文字距离
                  show: true,
                  formatter: [`{a|${boardName}}`, `{${element.color === 'red' ? 'b' : 'c'}|${boardInfo1}}`, `{${element.color === 'red' ? 'b' : 'c'}|${boardInfo2}}`].join('\n'),  // 自定义文字内容
                  rich: {
                    a: {
                        color: '#333333',
                        fontSize: 12,
                    },
                    b: {
                        color: '#F74E4F',
                        fontSize: 12,
                    },
                    c: {
                        color: '#5CBC7C',
                        fontSize: 12,
                    },
                  }
                }
              }
            )
          }
          markData.push(
            {
              coord: [index, item.indexPrice],
              symbol: 'circle',
              symbolSize: 10,
              symbolOffset: [0, 0],
              itemStyle: { color: item.unusualBoards[0].color === 'red' ? '#F74E4F' : '#5CBC7C' }
              // itemStyle: { color: '#ccc' }
            }
          )
        }
      }


      barChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            // type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
            // label:'cross',
            show:false
          },
        },
        grid: {
          top: '80px',
          left: '20px',
          right: '80px',
          bottom: '80px',
          containLabel: true
        },
        // legend: {
        //   // show: false,
        //   data: ['机构来访量', '重点机构来访量'],
        //   icon: 'circle',
        //   x: '0',
        //   left: '0',
        //   top: '0',
        //   itemGap: 30,
        //   textStyle: {
        //     align: 'left', // 这个是可以左右调整的
        //     fontSize: 14,// 字体大小
        //     color:'#999999'
        //     // padding:[0,0,25,-45]//主要就是用这个，可以把文字上下调整
        //     // color: 'rgba(255,255,255,0.65)'//字体颜色
        //   }
        // },
        toolbox: {
          show: true
          // feature: {
          //   dataView: { show: true, readOnly: false },
          //   magicType: { show: true, type: ['line', 'bar'] },
          //   restore: { show: true },
          //   saveAsImage: { show: true }
          // }
        },
        calculable: true,
        xAxis: [
          {
            type: 'category',
            // prettier-ignore
            data: xData,
            splitLine: { show: false },
            axisLabel:{show:false},
            axisTick: { show: false },
            axisLine: { show: false },
          }
        ],
        yAxis: [
          {
            type: 'value',
            min: props.chartData.minPrice,
            max: props.chartData.maxPrice,
            splitLine: { show: false },
            axisTick: { show: false },
            axisLabel: { show: false }
            // splitLine :{ //网格线
            //   lineStyle:{
            //     type:'dashed' //设置网格线类型 dotted：虚线 solid:实线
            //   }
            // },
            // axisLabel:{
            //   show:true,
            //   formatter (_:any,index:any) {
            //     if( _ === props.chartData.maxPrice || _ === props.chartData.minPrice ){
            //       return _
            //     }
            //   }
            // }
          }
        ],
        series: [
          {
            name: '',
            barGap: '80%',
            barWidth: 15, // 柱子宽度
            type: 'line',
            data: serviesData1,
            markPoint:{
              data: markData
            },
            itemStyle: {
              normal: {
                // barBorderRadius: [4, 4, 0, 0],
                // label: {
                //   formatter: '{c}' + '次',
                //   show: true,
                //   position: 'top',
                //   textStyle: {
                //     fontWeight: 'bolder',
                //     fontSize: '12',
                //     color: '#FF9626'
                //   }
                // },
                color: '#AEAEAE',
                opacity: 1
              }
            }
            // markPoint: {
            //   data: [
            //     { type: 'max', name: 'Max' },
            //     { type: 'min', name: 'Min' }
            //   ]
            // },
            // markLine: {
            //   data: [{ type: 'average', name: 'Avg' }]
            // }
          }
        ]
      } as EChartsOption)
      chart.value = barChart

      barChart.dispatchAction({
          type: 'showTip',
          // position: [-100, -150], // 自己可以设置显示位置 负数为正方向 正数为反方向
          seriesIndex:0,  // 显示第几个series
          dataIndex: [1, 2] // 显示第几个数据
      });

      // barChart.on('click', (params:any) => {
      //   console.log(params)
      //   window.open(`/investment/research/list?industryName=${params['name']}&isPointOrg=${params['seriesIndex']}&time=${props.selectedTime}`)
      // })
    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {

    }
  }
})
