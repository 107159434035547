<template>
    <div
      :id="id"
      :class="className"
      :style="{height: height, width: width}"
    />
</template>
  
<script lang="ts">
import { defineComponent, onActivated, onBeforeUnmount, onDeactivated, onMounted, nextTick, watch } from 'vue'
import resize from './mixins/resize'
import { init, EChartsOption } from 'echarts'
import * as echarts from 'echarts';
export default defineComponent({
props: {
    className: {
        type: String,
        default: 'chart'
    },
    id: {
        type: String,
        default: 'lineChartsCompoents'
    },
    width: {
        type: String,
        default: '100%',
        required: true
    },
    height: {
        type: String,
        default: '300px',
        required: true
    },
    chartData: {
        type: Object,
        default: []
    }
},
setup(props) {
    watch(props, (newName, oldName) => {
        initChart()
    });
    const {
        mounted,
        chart,
        beforeDestroy,
        activated,
        deactivated
    } = resize()
    const initChart = () => {
        if( !Object.keys(props.chartData).length ){
            return false;
        }
        const barChart = init(document.getElementById(props.id) as HTMLDivElement)
        const { peTtmDaily, quantileDaily } = props.chartData;
        const xData = (peTtmDaily.points || []).map((item:any) => item.x);
        const serviesData1 = (peTtmDaily.points || []).map((item:any) => item.y);
        const serviesData2 = (quantileDaily.points || []).map((item:any) => item.y);
        barChart.setOption({
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#6a7985'
                    }
                }
            },
            legend: {
                icon: 'circle',
                bottom: '0%',
                data: ['PE(TTM)', '分位点']
            },
            grid: {
                top: '10%',
                left: '10px',
                right: '10px',
                bottom: '10%',
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    data: xData,
                    show: true,
                    boundaryGap: false,
                    axisLine:{
                    lineStyle:{
                        color: '#CCCCCC',
                        width:1,
                    }
                    },
                    axisTick: { show: false },
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    //默认以千分位显示，不想用的可以在这加一段
                    axisLabel: {
                        show: true,
                        align: 'right'
                    }
                },
                {
                    type: 'value',
                    
                    axisLabel: {
                        show: true,
                        formatter: '{value}%',
                    },
                    splitLine: {
                        lineStyle:{
                            color: ['#ccc'],
                            width: 1,
                            type: 'solid'
                        }
                    },
                }
            ],
            series: [
                {
                    name: 'PE(TTM)',
                    type: 'line',
                    color: '#0E72AA',
                    symbol:'none',
                    yAxisIndex: 0,
                    data: serviesData1,
                },
                {
                    name: '分位点',
                    type: 'line',
                    symbol:'none',
                    smooth: true,
                    yAxisIndex: 1,
                    data: serviesData2,
                    color: '#F8A551',
                    areaStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: 'rgb(248, 165, 81)'
                        },
                        {
                            offset: 1,
                            color: 'rgb(248, 165, 81)'
                        }
                        ])
                    },
                },
            ]
        } as unknown as EChartsOption)
        chart.value = barChart
    }

    onMounted(() => {
    mounted()
    nextTick(() => {
        initChart()
    })
    })

    onBeforeUnmount(() => {
    beforeDestroy()
    })

    onActivated(() => {
    activated()
    })

    onDeactivated(() => {
    deactivated()
    })

    return {

    }
}
})
</script>
  
<style lang="scss" scoped>

</style>
  