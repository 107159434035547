<template>
  <div class="app-container" @click="visible = false">
    <div class="bg">
      <div class="top">
        <el-row>
          <el-col :span="8">
            <CTitle :mode='1' title="估值优选"></CTitle>
          </el-col>
          <el-col :span="16" >
            <div class="check-list">
                <el-checkbox-group 
                    v-model="checkboxGroupYear" 
                    size="small" 
                    fill="rgb(56, 111, 242)"
                >
                    <el-checkbox-button v-for="keys in yearKeyList" :label="keys" :key="keys" @change="switchValuationYear(keys)" >{{keys}}</el-checkbox-button>
                </el-checkbox-group>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="navList">
        <div class="list">
          <div class="des">行业</div>
          <div class="ans">
            <ul>
              <li :class="industryActive === -1 ? 'active' : ''" @click="handleIndustryTab(-1)">不限</li>
              <li 
                :class="industryActive === idx ? 'active' : ''"
                @click="handleIndustryTab(idx, item)"
                v-for="(item,idx) in industryList"
                :key="idx">
                {{item}}
              </li>
              <li v-if='industryLastText' :class="industryActive === -2 ? 'active' : ''" @click="handleIndustryTab(-2, industryLastText)">{{industryLastText}}</li>
              <li class="more">
                <div>
                  <el-popover
                    :width="600"
                    trigger="click"
                    popper-class='c-popover-list'
                    :visible='visible'
                  >
                    <div class="more-list">
                      <div 
                        :key="idx"
                        v-for="(item,idx) in industryMoreList"
                        >
                        <span class="letter">{{idx}}</span>
                        <span class="letter-ans" 
                          @click="handleMoreClick(innnerItem)"
                          :key="innerIdx"
                          v-for="(innnerItem,innerIdx) in item">
                          {{innnerItem}}
                        </span>
                      </div>
                    </div>
                    <template #reference>
                      <span @click="visible = !visible">更多</span>
                    </template>
                  </el-popover>
                </div>
              </li>
            </ul>
            
          </div>
        </div>
      </div>
      <div class="synthesize-valuation">
        <div class="factor-item-wrapper">
          <div 
            class="factor-item-data"
            :class="(currentCheckValuetion === factorItem.code || rangeCheckKeyList.includes(factorItem.code)) ? 'factor-item-data-active' : ''"
            v-for="factorItem in factorDataList" 
            :key="factorItem.key" 
            @click="changeFactorType(factorItem)"
          >
            {{factorItem.name}}
          </div>
        </div>
        <div class="factor-item-value-wrapper" v-if="currentCheckValuetion">
          <div 
            class="factor-children-item"
            :class="rangeCheckChildKeyList.includes(item.key) ? 'factor-children-item-active' : ''"
            v-for="item in factorChildrenList" :key="item.key" 
            @click="checkChildrenRange(item)"
          >
            <span v-if="item.type !== 'custom'">{{item.name}}</span>
            <el-popover
              v-else
              ref="itemRefs"
              placement="bottom"
              :visible-arrow="true"
              width="300"
              trigger="hover"
            >
              <div class="c-custom-form">
                <div class="c-custom-form-warp">
                  <div class="c-custom-form-item">
                    <div class="c-custom-form-item-label">
                      <span>大于等于</span>
                    </div>
                    <div class="c-custom-form-item-input">
                      <el-input
                        :class="(customValue1 && isVaild1) ? 'c-custom-valid-failed' : ''"
                        size="mini"
                        placeholder=""
                        v-model="customValue1"
                        @input="changeCustomValue($event, 'value1')"
                      >
                      </el-input>
                    </div>
                  </div>
                  <div class="c-custom-form-item">
                    <div class="c-custom-form-item-label">
                      <span>小于等于</span>
                    </div>
                    <div class="c-custom-form-item-input">
                      <el-input
                        :class="(customValue2 && isVaild2) ? 'c-custom-valid-failed' : ''"
                        size="mini"
                        placeholder=""
                        v-model="customValue2"
                        @input="changeCustomValue($event, 'value2')"
                        >
                      </el-input>
                    </div>
                  </div>
                  <div class="c-custom-form-tips">
                    <span>最多精确到小数点后面两位</span>
                  </div>
                  <div class="c-custom-form-btn">
                    <div class="c-custom-form-btn-warp">
                      <el-button size="mini" @click="cancelCustomValue">取消</el-button>
                      <el-button size="mini" type="primary" :disabled="isDisabled" @click="saveCustomValue(item)">保存</el-button>
                    </div>
                  </div>
                </div>
              </div>
              <template  #reference><span>{{item.name}}</span></template>
            </el-popover>
          </div>
        </div>
      </div>
      <div class="filter-criteria">
        <div class="filter-criteria-title">
          筛选条件:
        </div>
        <div class="filter-criteria-wrapper">
          <div class="filter-criteria-item" v-if="selectedIndustry">
            <span>{{selectedIndustry}}</span>
            <i class="el-icon-close" @click="delIndustryTab()" />
          </div>
          <div class="filter-criteria-item" v-for="item in rangeCheckList" :key="item.key">
            <span>{{`${item.parentName}: ${item.name}`}}</span>
            <i class="el-icon-close" @click="delChildrenRange(item)" />
          </div>
        </div>
      </div>
      <div class="tables">
        <div class="content">
          <div class="left">
            <el-table
              v-loading="loading1"
              ref="sortTable"
              class="table-style-2 cursor"
              :data="dataList1.list"
              border
              style="width: 100%;"
              :default-sort="defaultSortData"
              @sort-change="sortDataList"
              @row-click="goDetail"
            >
              <el-table-column
                prop="name"
                align="center"
                label="股票名称"
              />
              <el-table-column
                v-if="rangeCheckKeyList.includes('zhgz')"
                prop="valuation"
                align="center"
                label="综合估值"
                sortable="custom"
                :sort-orders="['ascending', 'descending']"
              />
              <el-table-column
                v-if="rangeCheckKeyList.includes('syl')"
                prop="pe"
                align="center"
                label="市盈率"
                sortable="custom"
                :sort-orders="['ascending', 'descending']"
              />
              <el-table-column
                v-if="rangeCheckKeyList.includes('pettm')"
                prop="peTtm"
                align="center"
                label="PETTM"
                sortable="custom"
                :sort-orders="['ascending', 'descending']"
              />
              <el-table-column
                v-if="rangeCheckKeyList.includes('sjl')"
                prop="pb"
                align="center"
                label="市净率"
                sortable="custom"
                :sort-orders="['ascending', 'descending']"
              />
              <el-table-column
                v-if="rangeCheckKeyList.includes('sxl')"
                prop="pcf"
                align="center"
                label="市现率"
                sortable="custom"
                :sort-orders="['ascending', 'descending']"
              />
              <el-table-column
                v-if="rangeCheckKeyList.includes('sxl2')"
                prop="ps"
                align="center"
                label="市销率"
                sortable="custom"
                :sort-orders="['ascending', 'descending']"
              />
              <el-table-column
                prop="quantile"
                align="center"
                label="分位点"
                sortable="custom"
                :sort-orders="['ascending', 'descending']"
              >
                <template #default='{row}'>
                    <div>{{row.quantile ?? '-'}}</div>
                </template>
              </el-table-column>
            </el-table>
            <div class="pagination">
              <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page.pageNum"
                :hide-on-single-page="true"
                :pager-count="4"
                :page-sizes="[10, 30, 50, 100]"
                :page-size="page.pageSize"
                layout="sizes, prev, pager, next, jumper"
                :total="dataList1.totalSize">
              </el-pagination>
            </div>
          </div>
          <div class="right">
            <el-table
              v-loading="loading2"
              class="table-style-2 cursor"
              :data="dataList2.list"
              border
              style="width: 100%;"
              :default-sort = "{prop: 'stockNum', order: 'descending'}"
              @sort-change="sortDataListIndustry"
              @row-click="checkCurrentIndustry"
            >
              <el-table-column
                prop="name"
                align="center"
                label="行业名称"
              />
              <el-table-column
                prop="stockNum"
                align="center"
                label="分布家数"
                sortable="custom"
                :sort-orders="['ascending', 'descending']"
              />
              <el-table-column
                prop="valuation"
                align="center"
                label="行业估值"
                sortable="custom"
                :sort-orders="['ascending', 'descending']"
              >
                <template #default='{row}'>
                    <div>{{row.valuation ?? '-'}}</div>
                </template>
              </el-table-column>
            </el-table>
            <div class="pagination">
              <el-pagination
                background
                @size-change="handleSizeChange2"
                @current-change="handleCurrentChange2"
                :current-page="page2.pageNum"
                :pager-count="3"
                :page-sizes="[10, 30, 50, 100]"
                :page-size="page2.pageSize"
                :hide-on-single-page="true"
                layout="sizes, prev, pager, next, jumper"
                :total="dataList2.totalSize">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>  
  </div>
</template>

<script lang="ts">

import { defineComponent, reactive, toRefs, ref, nextTick } from 'vue'
import CTitle from "@/components/title/Index.vue"
import { getOrgIndustryBoardList, getOrgIndustryBoardMore } from '@/apis/research'
import { getStockIndustryList, getStockBoardList } from '@/apis/analysis';
import { useRoute } from "vue-router";

export default defineComponent({
  components: {
    CTitle,
  },
  setup() {
    const factorDataList = [{
      firstLevel: 1,
      key: 0,
      code: 'zhgz',
      name: '综合估值',
      children: [{
        parentKey: 0,
        parentCode: 'zhgz',
        parentName: '综合估值',
        prop: 'valuation',
        key: '0-1',
        name: '10以下',
        maxValue: 9.999,
        minValue: ''
      },{
        parentKey: 0,
        parentCode: 'zhgz',
        parentName: '综合估值',
        prop: 'valuation',
        key: '0-2',
        name: '10-20',
        maxValue: 20,
        minValue: 10
      },{
        parentKey: 0,
        parentCode: 'zhgz',
        parentName: '综合估值',
        prop: 'valuation',
        key: '0-3',
        name: '20-50',
        maxValue: 50,
        minValue: 20
      },{
        parentKey: 0,
        parentCode: 'zhgz',
        parentName: '综合估值',
        prop: 'valuation',
        key: '0-4',
        name: '50以上',
        maxValue: '',
        minValue: 50.001
      },{
        parentKey: 0,
        parentCode: 'zhgz',
        parentName: '综合估值',
        prop: 'valuation',
        type: 'custom',
        key: '0-5',
        name: '自定义',
        maxValue: '',
        minValue: ''
      }]
    },{
      firstLevel: 1,
      key: 1,
      code: 'syl',
      name: '市盈率（倍）',
      children: [{
        parentKey: 1,
        parentCode: 'syl',
        parentName: '市盈率（倍）',
        prop: 'pe',
        key: '1-1',
        name: '5以下',
        maxValue: 4.999,
        minValue: ''
      },{
        parentKey: 1,
        parentCode: 'syl',
        parentName: '市盈率（倍）',
        prop: 'pe',
        key: '1-2',
        name: '5-10',
        maxValue: 10,
        minValue: 5
      },{
        parentKey: 1,
        parentCode: 'syl',
        parentName: '市盈率（倍）',
        prop: 'pe',
        key: '1-3',
        name: '10-20',
        maxValue: 20,
        minValue: 10
      },{
        parentKey: 1,
        parentCode: 'syl',
        parentName: '市盈率（倍）',
        prop: 'pe',
        key: '1-4',
        name: '20-50',
        maxValue: 50,
        minValue: 20
      },{
        parentKey: 1,
        parentCode: 'syl',
        parentName: '市盈率（倍）',
        prop: 'pe',
        key: '1-5',
        name: '50以上',
        maxValue: '',
        minValue: 50.001
      },{
        parentKey: 1,
        parentCode: 'syl',
        parentName: '市盈率（倍）',
        prop: 'pe',
        type: 'custom',
        key: '1-6',
        name: '自定义',
        maxValue: '',
        minValue: ''
      }]
    },{
      firstLevel: 1,
      key: 2,
      code: 'pettm',
      name: 'PETTM(倍）',
      children: [{
        parentKey: 2,
        parentCode: 'pettm',
        parentName: 'PETTM(倍）',
        prop: 'peTtm',
        key: '2-0',
        name: '0以下',
        maxValue: -0.001,
        minValue: -1000000
      },{
        parentKey: 2,
        parentCode: 'pettm',
        parentName: 'PETTM(倍）',
        prop: 'peTtm',
        key: '2-1',
        name: '0-10',
        maxValue: 10,
        minValue: 0
      },{
        parentKey: 2,
        parentCode: 'pettm',
        parentName: 'PETTM(倍）',
        prop: 'peTtm',
        key: '2-2',
        name: '10-20',
        maxValue: 20,
        minValue: 10
      },{
        parentKey: 2,
        parentCode: 'pettm',
        parentName: 'PETTM(倍）',
        prop: 'peTtm',
        key: '2-3',
        name: '20-30',
        maxValue: 30,
        minValue: 20
      },{
        parentKey: 2,
        parentCode: 'pettm',
        parentName: 'PETTM(倍）',
        prop: 'peTtm',
        key: '2-4',
        name: '30-50',
        maxValue: 50,
        minValue: 30
      },{
        parentKey: 2,
        parentCode: 'pettm',
        parentName: 'PETTM(倍）',
        prop: 'peTtm',
        key: '2-5',
        name: '50以上',
        maxValue: 1000000,
        minValue: 50.001
      },{
        parentKey: 2,
        parentCode: 'pettm',
        parentName: 'PETTM(倍）',
        prop: 'peTtm',
        type: 'custom',
        key: '2-6',
        name: '自定义',
        maxValue: '',
        minValue: ''
      }]
    },{
      firstLevel: 1,
      key: 3,
      code: 'sjl',
      name: '市净率(倍）',
      children: [{
        parentKey: 3,
        parentCode: 'sjl',
        parentName: '市净率(倍）',
        prop: 'pb',
        key: '3-1',
        name: '1以下',
        maxValue: 0.999,
        minValue: ''
      },{
        parentKey: 3,
        parentCode: 'sjl',
        parentName: '市净率(倍）',
        prop: 'pb',
        key: '3-2',
        name: '1-3',
        maxValue: 3,
        minValue: 1
      },{
        parentKey: 3,
        parentCode: 'sjl',
        parentName: '市净率(倍）',
        prop: 'pb',
        key: '3-3',
        name: '3-5',
        maxValue: 5,
        minValue: 3
      },{
        parentKey: 3,
        parentCode: 'sjl',
        parentName: '市净率(倍）',
        prop: 'pb',
        key: '3-4',
        name: '5-10',
        maxValue: 10,
        minValue: 5
      },{
        parentKey: 3,
        parentCode: 'sjl',
        parentName: '市净率(倍）',
        prop: 'pb',
        key: '3-5',
        name: '10以上',
        maxValue: '',
        minValue: 10.001
      },{
        parentKey: 3,
        parentCode: 'sjl',
        parentName: '市净率(倍）',
        prop: 'pb',
        type: 'custom',
        key: '3-6',
        name: '自定义',
        maxValue: '',
        minValue: ''
      }]
    },{
      firstLevel: 1,
      key: 4,
      code: 'sxl',
      name: '市现率(倍）',
      children: [{
        parentKey: 4,
        parentCode: 'sxl',
        parentName: '市现率(倍）',
        prop: 'pcf',
        key: '4-1',
        name: '0以下',
        maxValue: -0.001,
        minValue: ''
      },{
        parentKey: 4,
        parentCode: 'sxl',
        parentName: '市现率(倍）',
        prop: 'pcf',
        key: '4-2',
        name: '0-10',
        maxValue: 10,
        minValue: 0
      },{
        parentKey: 4,
        parentCode: 'sxl',
        parentName: '市现率(倍）',
        prop: 'pcf',
        key: '4-3',
        name: '10-50',
        maxValue: 50,
        minValue: 10
      },{
        parentKey: 4,
        parentCode: 'sxl',
        parentName: '市现率(倍）',
        prop: 'pcf',
        key: '4-4',
        name: '50-100',
        maxValue: 100,
        minValue: 50
      },{
        parentKey: 4,
        parentCode: 'sxl',
        parentName: '市现率(倍）',
        prop: 'pcf',
        key: '4-5',
        name: '100以上',
        maxValue: '',
        minValue: 100.001
      },{
        parentKey: 4,
        parentCode: 'sxl',
        parentName: '市现率(倍）',
        prop: 'pcf',
        type: 'custom',
        key: '4-6',
        name: '自定义',
        maxValue: '',
        minValue: ''
      }]
    },{
      firstLevel: 1,
      key: 5,
      code: 'sxl2',
      name: '市销率(倍）',
      children: [{
        parentKey: 5,
        parentCode: 'sxl2',
        parentName: '市销率(倍）',
        prop: 'ps',
        key: '5-1',
        name: '1以下',
        maxValue: 0.999,
        minValue: ''
      },{
        parentKey: 5,
        parentCode: 'sxl2',
        parentName: '市销率(倍）',
        prop: 'ps',
        key: '5-2',
        name: '1-10',
        maxValue: 10,
        minValue: 1
      },{
        parentKey: 5,
        parentCode: 'sxl2',
        parentName: '市销率(倍）',
        prop: 'ps',
        key: '5-3',
        name: '10-50',
        maxValue: 50,
        minValue: 10
      },{
        parentKey: 5,
        parentCode: 'sxl2',
        parentName: '市销率(倍）',
        prop: 'ps',
        key: '5-4',
        name: '50-100',
        maxValue: 100,
        minValue: 50
      },{
        parentKey: 5,
        parentCode: 'sxl2',
        parentName: '市销率(倍）',
        prop: 'ps',
        key: '5-5',
        name: '100以上',
        maxValue: '',
        minValue: 100.001
      },{
        parentKey: 5,
        parentCode: 'sxl2',
        parentName: '市销率(倍）',
        prop: 'ps',
        type: 'custom',
        key: '5-6',
        name: '自定义',
        maxValue: '',
        minValue: ''
      }]
    }]

    const route = useRoute();
    const industryName = route.query.industryName ?? '';
    const isCustom = route.query.isCustom;
    const section = route.query.section as any;
    const isChildKey = route.query.jumpKey as any;
    const sectionArr = section ? section.split(',') : [];
    const sortTable = ref(null);
    const itemRefs = ref(null);

    const dataMap = reactive({
      loading1: true,
      loading2: true,
      checkboxGroupYear: ['3Y'],
      yearKeyList: ['3Y','5Y','10Y'],
      visible: false,
      industryActive: -1,
      selectedIndustry: industryName,
      visibleList: {
        visible0: false,
        visible1: false,
        visible2: false,
        visible3: false,
        visible4: false,
        visible5: false
      },
      defaultSortData: {prop: 'peTtm', order: 'ascending'},
      sortMap: {
        sortKey: 'peTtm',
        isAsc: true,
      },
      page: {
        totalSize: 0,
        pageSize: 10,
        pageNum: 1,
      },
      sortMap2: {
        sortKey: 'stockNum',
        isAsc: false,
      },
      page2: {
        totalSize: 0,
        pageSize: 10,
        pageNum: 1,
      },
      formInlineinit: {
        industryName:'',//行业名称
        time: []
      },
      formInline: {
        industryName: industryName,//行业名称
        time: []
      },
      industryList: [],
      industryMoreList: [],
      industryLastText: '',
      selectedText: '',
      currentCheckValuetion: 'pettm',
      factorChildrenList: factorDataList[2].children as any,
      rangeCheckList: [{
        parentKey: 2,
        parentCode: 'pettm',
        parentName: 'PETTM(倍）',
        prop: 'peTtm',
        key: '2-0',
        name: '10-20',
        maxValue: 20,
        minValue: 10
      }], // 范围筛选条件合集(默认综合估值10-20)
      rangeCheckKeyList: ['pettm'], // 范围筛选的父级key集合
      rangeCheckChildKeyList: ['2-2'], // 范围筛选的子集的key集合
      dataList1: [],
      dataList2: [],
      customValue1: '',
      customValue2: '',
      isVaild1: false,
      isVaild2: false,
      isDisabled: true,
    })

    if(route.query.checkLevel){
      const { factorChildrenList } = dataMap;
      dataMap.checkboxGroupYear = [`${route.query.checkLevel}`];
      if(isChildKey){
        dataMap.rangeCheckChildKeyList = isChildKey ? [`${isChildKey}`] : ['2-2'];
        const obj =  (factorChildrenList || []).find((item:any) => item.key === isChildKey);
        const newObj = {
          ...obj,
          name: Number(isCustom) === 1 ? `${obj.name}:[${sectionArr[0]},${sectionArr[1]}]` : obj.name,
          minValue: sectionArr[0],
          maxValue: sectionArr[1]
        }
        dataMap.rangeCheckList = [newObj]
        if(Number(isCustom) === 1){
          dataMap.customValue1 = sectionArr[0];
          dataMap.customValue2 = sectionArr[1];
        }
      }
    }

    //获取行业列表
    const fetchList = async () => {
      let data1 = await getOrgIndustryBoardList({ })
      dataMap.industryList = (data1 as any).data;
      let data2 = await getOrgIndustryBoardMore({ })
      dataMap.industryMoreList = (data2 as any).data;
      let name = route.query.industryName ?? '';
      if(name ){
        //寻找行业列表下标
        const idx = dataMap.industryList.findIndex(item => item === name)
        
        //行业列表默认选中
        if( idx !== -1 ){
          dataMap.industryActive = idx
        }else{
          //行业列表最后一个默认选中
          dataMap.industryActive = -2
          dataMap.industryLastText = String(name);
          dataMap.selectedText = String(name);
        }
      }
    }

   fetchList()

    //行业选择
    const handleIndustryTab = (idx:any, item:any) => {
      dataMap['industryActive'] = idx
      dataMap.formInline.industryName = item
      dataMap.selectedIndustry = item
      dataMap.page.pageNum = 1
      dataMap.page2.pageNum = 1
      fetchData()
    }

    //行业更多选择
    const handleMoreClick = (item:any) => {
      dataMap.formInline.industryName = item
      dataMap.industryLastText = item
      dataMap.selectedIndustry = item
      dataMap.industryActive = -2
      dataMap.visible = false
      dataMap.page.pageNum = 1
      dataMap.page2.pageNum = 1
      fetchData()
    }

    // 删除行业
    const delIndustryTab = () => {
      dataMap.formInline.industryName = ''
      dataMap.selectedIndustry = ''
      dataMap.industryActive = -1
      dataMap.page.pageNum = 1
      dataMap.page2.pageNum = 1
      fetchData()
    }

    // 切换估值年份条件
    const switchValuationYear = (val:any) => {
      dataMap.checkboxGroupYear = [];
      dataMap.checkboxGroupYear.push(val);
      dataMap.page.pageNum = 1
      dataMap.page2.pageNum = 1
      fetchData();
    }

    // 切换估值类型
    const changeFactorType = (item:any) => {
      dataMap.customValue1 = '';
      dataMap.customValue2 = '';
      dataMap.isDisabled = true;
      dataMap.isVaild1 = false;
      dataMap.isVaild2 = false;
      dataMap.currentCheckValuetion = item.code;
      dataMap.factorChildrenList = item.children || [];
    }

    // 选中范围子集
    const checkChildrenRange = (item:any) => {
      if(item.type === 'custom') return;
      const newArr = JSON.parse(JSON.stringify(dataMap.rangeCheckList)).filter((items:any) => items.parentCode !== item.parentCode).concat(item); 
      dataMap.rangeCheckList = newArr
      dataMap.rangeCheckKeyList = newArr.map((items:any) => items.parentCode);
      dataMap.rangeCheckChildKeyList = newArr.map((items:any) => items.key);
      const defaultSort = dataMap.rangeCheckList[0];
      nextTick(() => {
        let { clearSort, sort } = sortTable.value as any;
        clearSort();
        sort(defaultSort['prop'], 'ascending');
      })
      dataMap.sortMap = { sortKey: defaultSort ? defaultSort['prop'] : '',  isAsc: true }
      dataMap.page.pageNum = 1
      dataMap.page2.pageNum = 1
      getDataList2()
    }

    // 删除范围子集
    const delChildrenRange = (item:any) => {
      const newArr = JSON.parse(JSON.stringify(dataMap.rangeCheckList)).filter((items:any) => items.parentCode !== item.parentCode); 
      dataMap.rangeCheckList = newArr
      dataMap.rangeCheckKeyList = newArr.map((items:any) => items.parentCode);
      dataMap.rangeCheckChildKeyList = newArr.map((items:any) => items.key);
      const defaultSort = dataMap.rangeCheckList[0];
      if(defaultSort) {
        nextTick(() => {
          let { clearSort, sort } = sortTable.value as any;
          clearSort();
          sort(defaultSort['prop'], 'ascending');
        })
        dataMap.sortMap = { sortKey: defaultSort ? defaultSort['prop'] : '',  isAsc: true };
      }
      dataMap.page.pageNum = 1
      dataMap.page2.pageNum = 1
      fetchData()
    }

    const getParams = () => {
      // 综合估值
      const valuationObj = (dataMap.rangeCheckList || []).find((item:any) => item.parentCode === 'zhgz') || {};
      // 市盈率
      const peObj = (dataMap.rangeCheckList || []).find((item:any) => item.parentCode === 'syl') || {};
      // PETTM
      const pettmObj = (dataMap.rangeCheckList || []).find((item:any) => item.parentCode === 'pettm') || {};
      // 市净率
      const pbObj = (dataMap.rangeCheckList || []).find((item:any) => item.parentCode === 'sjl') || {};
      // 市现率
      const pcObj =  (dataMap.rangeCheckList || []).find((item:any) => item.parentCode === 'sxl') || {};
      // 市销率
      const psObj =  (dataMap.rangeCheckList || []).find((item:any) => item.parentCode === 'sxl2') || {};
      const params = {
        timeLevel: dataMap.checkboxGroupYear[0].replace('Y', 'y'),
        boardName: dataMap.selectedIndustry,
        valuationFrom: valuationObj['minValue'],
        valuationTo: valuationObj['maxValue'],
        peFrom: peObj['minValue'],
        peTo: peObj['maxValue'],
        peTtmFrom: pettmObj['minValue'],
        peTtmTo: pettmObj['maxValue'],
        pbFrom: pbObj['minValue'],
        pbTo: pbObj['maxValue'],
        pcfFrom: pcObj['minValue'],
        pcfTo: pcObj['maxValue'],
        psFrom: psObj['minValue'],
        psTo: psObj['maxValue'],
      }
      return params;
    }

    // 获取股票估值列表
    const getDataList1 = async () => {
      dataMap.loading1 = true;
      const params = {
        ...getParams(),
        sortKey: dataMap.sortMap.sortKey,
        isAsc: dataMap.sortMap.isAsc,
        pageNum: dataMap.page.pageNum,
        pageSize: dataMap.page.pageSize,
      };
      let data1 = await getStockIndustryList({ ...params })
      dataMap.loading1 = false;
      dataMap.dataList1 = (data1 as any).data
    }
    
    // 获取行业估值列表
    const getDataList2 = async () => {
      dataMap.loading2 = true;
      const params = {
        ...getParams(),
        sortKey: dataMap.sortMap2.sortKey,
        isAsc: dataMap.sortMap2.isAsc,
        pageNum: dataMap.page2.pageNum,
        pageSize: dataMap.page2.pageSize,
      };
      let data1 = await getStockBoardList({ ...params })
      dataMap.loading2 = false;
      dataMap.dataList2 = (data1 as any).data
    }

    const fetchData = () => {
      
      getDataList1()
      getDataList2()
    }

    fetchData()

    // 股票分页（切换每页多少条）
    const handleSizeChange = (val:any) => {
      dataMap.page.pageSize = val;
      getDataList1()
    }

    // 股票分页（切换页码）
    const handleCurrentChange = (val:any) => {
      dataMap.page.pageNum = val;
      getDataList1()
    }

    // 行业分页（切换每页多少条）
    const handleSizeChange2 = (val:any) => {
      dataMap.page2.pageSize = val;
      getDataList2()
    }

    // 行业分页（切换页码）
    const handleCurrentChange2 = (val:any) => {
      dataMap.page2.pageNum = val;
      getDataList2()
    }

    const sortDataList = (column:any) => {
      dataMap.sortMap = {
        sortKey: column.prop,
        isAsc: column.order === 'ascending',
      }
      getDataList1()
    }

    const sortDataListIndustry = (column:any) => {
      dataMap.sortMap2 = {
        sortKey: column.prop,
        isAsc: column.order === 'ascending',
      }
      getDataList2()
    }

    const changeCustomValue = (e:any, type:String) => {
      const reg = /^(0|([1-9][0-9]*))(\.[\d]{1,2})?$/; 
      const result = reg.test(e);
      if(type === 'value1'){
        dataMap.isVaild1 = !result;
      }else {
        dataMap.isVaild2 = !result;
      }
      if(!dataMap.customValue1 && !dataMap.customValue2){
        dataMap.isDisabled = true;
      }else{
        if(dataMap.customValue1 && !dataMap.isVaild1 && dataMap.customValue2 && dataMap.isVaild2){
          dataMap.isDisabled = true;
        }else if(dataMap.customValue1 && dataMap.isVaild1 && dataMap.customValue2 && !dataMap.isVaild2){
          dataMap.isDisabled = true;
        }else if(dataMap.customValue1 && dataMap.isVaild1 && !dataMap.customValue2){
          dataMap.isDisabled = true;
        }else{
          dataMap.isDisabled = false;
        }
      }
    }
    
    // 取消自定义输入内容
    const cancelCustomValue = () => {
      dataMap.customValue1 = '';
      dataMap.customValue2 = '';
      dataMap.isDisabled = true;
      dataMap.isVaild1 = false;
      dataMap.isVaild2 = false;
      nextTick(() => {
        let obj = itemRefs.value as any;
        obj.doDestroy();
        obj.visibility = false
      })
    }

    const saveCustomValue = (item:any) => {
      const newItem = {
        ...item,
        name: `${item.name}:[${dataMap.customValue1},${dataMap.customValue2}]`,
        maxValue: dataMap.customValue2,
        minValue: dataMap.customValue1
      }
      const newArr = JSON.parse(JSON.stringify(dataMap.rangeCheckList)).filter((items:any) => items.parentCode !== item.parentCode).concat(newItem); 
      dataMap.rangeCheckList = newArr
      dataMap.rangeCheckKeyList = newArr.map((items:any) => items.parentCode);
      dataMap.rangeCheckChildKeyList = newArr.map((items:any) => items.key);
      const defaultSort = dataMap.rangeCheckList[0];
      nextTick(() => {
        let { clearSort, sort } = sortTable.value as any;
        clearSort();
        sort(defaultSort['prop'], 'ascending');
      })
      dataMap.customValue1 = '';
      dataMap.customValue2 = '';
      dataMap.isDisabled = true;
      dataMap.isVaild1 = false;
      dataMap.isVaild2 = false;
      nextTick(() => {
        let obj = itemRefs.value as any;
        obj.doDestroy();
        obj.visibility = false
      })
      dataMap.sortMap = { sortKey: defaultSort ? defaultSort['prop'] : '',  isAsc: true }
      dataMap.page.pageNum = 1
      dataMap.page2.pageNum = 1
      getDataList2() 
    }

    const goDetail = (column: any) => {
      window.open(`/investment/analysis/detail?code=${column.code}&timeLevel=${dataMap.checkboxGroupYear[0]}`)
    }

    // 行业列表点击行业名称切换行业类型
    const checkCurrentIndustry = (row:any, column:any) => {
      const { property } = column;
      if(['name'].includes(property)){
        let name = row.name ?? '';
        if(name){
          //寻找行业列表下标
          const idx = dataMap.industryList.findIndex(item => item === name);
          //行业列表默认选中
          if( idx !== -1 ){
            dataMap.industryActive = idx
          }else{
            //行业列表最后一个默认选中
            dataMap.industryActive = -2
            dataMap.industryLastText = String(name);
            dataMap.selectedText = String(name);
            dataMap.selectedIndustry = String(name);
          }
        }
        fetchData();
        return false;
      }
    }
 
    return {
      ...toRefs(dataMap),
      itemRefs,
      sortTable,
      factorDataList,
      handleIndustryTab,
      handleMoreClick,
      delIndustryTab,
      switchValuationYear,
      changeFactorType,
      checkChildrenRange,
      delChildrenRange,
      sortDataList,
      sortDataListIndustry,
      handleSizeChange,
      handleCurrentChange,
      handleSizeChange2,
      handleCurrentChange2,
      changeCustomValue,
      saveCustomValue,
      cancelCustomValue,
      goDetail,
      checkCurrentIndustry,
    }
  }
})
</script>
<style lang='scss' scoped>
@import '@/styles/searchForm.scss';
.app-container{
  .check-list{
    text-align: right;
  }
}
.navList{
  .des{
    color: #000000 !important;
    width: auto !important;
    text-align: left !important;
  }
}
.synthesize-valuation{
  .factor-item-wrapper{
    display: flex;
    align-items: center;
    .factor-item-title{
      font-size: 14px;
      font-weight: 500;
      color: #000000;
      line-height: 28px;
    }
    .factor-item-data{
      cursor: pointer;
      width: 12%;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      color: #666666;
      line-height: 28px;
    }
    .factor-item-data-active{
      color: #44A2E6;
    }
  }
  .factor-item-value-wrapper{
    margin-top: 15px;
    width: 60%;
    display: flex;
    background: #f7f7f7;
    padding: 3px 17px;
    .factor-children-item{
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      color: #666666;
      line-height: 28px;
      margin-right: 40px;
    }
    .factor-children-item:hover{
      color: #44A2E6;
    }
    .factor-children-item-active{
      color: #44A2E6;
    }
  }
}
.filter-criteria{
  margin-top: 20px;
  display: flex;
  align-items: center;
  .filter-criteria-title{
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    line-height: 28px;
    margin-right: 20px;
  }
  .filter-criteria-wrapper{
    display: flex;
    .filter-criteria-item{
      margin-right: 20px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 10px;
      background: #f7f7f7;
      border: 1px solid #E9E9E9;
      border-radius: 3px;
      span{
        font-size: 14px;
        color: #666666;
      }
      i{
        font-size: 10px;
        margin-left: 5px;
      }
    }
    .filter-criteria-item:hover{
      background: #F1F9FF;
      border: 1px solid #44A2E6;
      i{
        color: #44A2E6;
      }
    }
  }
}
.tables{
  margin-top: 20px;
  
  .pagination{
    margin-top: 20px;
    margin-left: -10px;
  }
  .content{
    display: flex;
    justify-content: space-between;
    .left{
        width: 55%;
    }
    .right{
        width: 42%;
    }
  }
  
}
.bg{
  background: #fff;
  padding: 32px 33px 54px;
  ::v-deep .mode{
    margin-bottom: 30px;
  }
}
@media (max-width: 1350px){
    .synthesize-valuation{
      .factor-item-wrapper{
        .factor-item-data{
          width: 14%;
        }
      }
      .factor-item-value-wrapper{
        width: 76%;
      }
    }
    .tables{
        .content {
            flex-direction: column;
            .left{
                width: 100%;
            }
            .right{
                margin-top: 20px;
                width: 100%;
            }
        }
    }
}
@media (max-width: 1200px){
    .synthesize-valuation{
      .factor-item-value-wrapper{
        width: 82%;
      }
    }
}
</style>