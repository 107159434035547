<template>
  <div class="agency-rating-container">
    <div class="t-title">
      <CTitle
        :mode="3"
        title="行业机构调研排行"
        @change='changeHandle'
      />
    </div>
    <div class="echarts">
      <BarChartA :chartData='stockList4' :selectedTime='selectedTime' />
    </div>
  </div>
</template>

<script lang="ts">

import { defineComponent, reactive, toRefs } from 'vue'
import CTitle from '@/components/title/Index.vue'
import BarChartA from '@/components/charts/BarChartA.vue'
import { getSurveyRankingtList } from '@/apis/home'

export default defineComponent({
  props: {
    dataMap: {
      type: Array,
      default: []
    },
    title: {
      type: String,
      default: '机构评级'
    }
  },
  components: {
    CTitle,
    BarChartA
  },
  setup() {
    
    const dataMap = reactive({
      ajaxRes: false,
      stockList4:[],
      selectedTime: ''
    })
    

    const changeHandle = async (val:any) => {
      dataMap.selectedTime = val.value
      const data3 = await getSurveyRankingtList({ timeLevel: val.timeLevel })
      dataMap.stockList4 = (data3 as any).data;
    }
  
    return {
      changeHandle,
      ...toRefs(dataMap)
    }
  }
})
</script>
<style scoped lang='scss'>

</style>
