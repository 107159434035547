/*
 * @Description: 市场综述接口
 */
import https from '@/utils/https'
import { RequestParams, ContentType, Method } from 'axios-mapper'

//板块异动
export const getDataCenterBoardUnusualAction = (params: RequestParams) => {
  return https().request('/dataCenter/board/unusual/action', Method.GET, params, ContentType.form)
}

//板块统计 => 导航
export const getDataCenterBoardTypes = (params: RequestParams) => {
  return https().request('/dataCenter/board/types', Method.GET, params, ContentType.form)
}

//板块统计 => 板块市值统计
export const getDataCenterBoardMvStatistic = (params: RequestParams) => {
  return https().request('/dataCenter/board/mv/statistic', Method.GET, params, ContentType.form)
}

//板块统计 => 板块行业统计
export const getDataCenterBoardIndustryStatistic = (params: RequestParams) => {
  return https().request('/dataCenter/board/industry/statistic', Method.GET, params, ContentType.form)
}

//两市成交额
export const getDataCenterMarketTransaction = (params: RequestParams) => {
  return https().request('/dataCenter/market/transaction', Method.GET, params, ContentType.form)
}

//融资融券数据
export const marginTradingData = (params: RequestParams) => {
  return https().request('/dataCenter/marginData', Method.GET, params, ContentType.form)
}

//市场概览，当日涨跌数据
export const getDataCenterMarketOverviewToday = (params: RequestParams) => {
  return https().request('/dataCenter/market/overview/today', Method.GET, params, ContentType.form)
}

//市场概览，历史涨跌数据
export const getDataCenterMarketOverviewHistory = (params: RequestParams) => {
  return https().request('/dataCenter/market/overview/history', Method.GET, params, ContentType.form)
}
//北向资金分时
export const northboundFundsTimeSharing = (params: RequestParams) => {
  return https().request('/dataCenter/NorthboundFunds/timeData', Method.GET, params, ContentType.form)
}
//北向资金历史
export const northboundFundsHistory = (params: RequestParams) => {
  return https().request('/dataCenter/NorthboundFunds/history', Method.GET, params, ContentType.form)
}

//获取交易日信息
export const getStockTradeDate = (params: RequestParams) => {
  return https().request('/stock/trade/date', Method.GET, params, ContentType.form)
}
// 市场综述， 获取当日指数统计，板块数据统计
export const getCurrentBoardData = (params: RequestParams) => {
  return https().request('/dataCenter/market/overview/currentBoardData', Method.GET, params, ContentType.form)
}

// 板块区域统计
export const getRegionStatistic = (params: RequestParams) => {
  return https().request('/dataCenter/board/region/statistic', Method.GET, params, ContentType.form)
}

// 指数行业估值
export const getIndexBoardValuation = (params: RequestParams) => {
  return https().request('/dataCenter/market/overview/indexBoardValuation', Method.GET, params, ContentType.form)
}



