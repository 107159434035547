const stockFormat = (val:any) => {
  if( val > 0){
    return '+' + val + '%'
  }else if( val < 0){
    return val + '%'
  }
  return val + '%'
}

const bigNumberTransform = (value:any) => {
  let isNegativeNumber = value < 0
  let val:any = 0
  value = Math.abs(value)
  if( value >= 0 && value < 10000 ){
    val = value.toFixed(2)
  }
  if( value >= 10000 && value < 100000000 ){
    val = (value / 10000).toFixed(2) + '万'
  }
  if( value >= 100000000 ){
    val = (value / 100000000).toFixed(2) + '亿'
  }
  return isNegativeNumber  ? `-${val}` : val
  // const newValue = [0, '', '']
  // let fr = 1000
  // let num = 3
  // let text1 = ''
  // let fm = 1
  // while (value / fr >= 1) {
  //   fr *= 10
  //   num += 1
  //   // console.log('数字', value / fr, 'num:', num)
  // }
  // if (num <= 4) { // 千
  //   newValue[0] = parseInt(`${value / 1000}`) + ''
  //   newValue[1] = '千'
  // } else if (num <= 8) { // 万
  //   text1 = parseInt(`${num - 4}`) / 3 > 1 ? '千万' : '万'
  //   // tslint:disable-next-line:no-shadowed-variable
  //   fm = text1 === '万' ? 10000 : 10000000
  //   if (value % fm === 0) {
  //     newValue[0] = parseInt(`${value / fm}`) + ''
  //   } else {
  //     newValue[0] = parseFloat(`${value / fm}`).toFixed(2) + ''
  //   }
  //   newValue[1] = text1
  // } else if (num <= 16) { // 亿
  //   text1 = (num - 8) / 3 > 1 ? '千亿' : '亿'
  //   text1 = (num - 8) / 4 > 1 ? '万亿' : text1
  //   text1 = (num - 8) / 7 > 1 ? '千万亿' : text1
  //   // tslint:disable-next-line:no-shadowed-variable
  //   fm = 1
  //   if (text1 === '亿') {
  //     fm = 100000000
  //   } else if (text1 === '千亿') {
  //     fm = 100000000000
  //   } else if (text1 === '万亿') {
  //     fm = 1000000000000
  //   } else if (text1 === '千万亿') {
  //     fm = 1000000000000000
  //   }
  //   if (value % fm === 0) {
  //     newValue[0] = parseInt(`${value / fm}`) + ''
  //   } else {
  //     newValue[0] = parseFloat(`${value / fm}`).toFixed(2) + ''
  //   }
  //   newValue[1] = text1
  // }
  // if (value < 1000) {
  //   newValue[0] = value + ''
  //   newValue[1] = ''
  // }
  // return newValue.join('')
}

export{
  stockFormat,
  bigNumberTransform
}