<template>
  <div
    :id="id"
    :class="className"
    :style="{height: height, width: width}"
  />
</template>

<script lang="ts">
import { defineComponent, onActivated, onBeforeUnmount, onDeactivated, onMounted, nextTick, watch } from 'vue'
import resize from '@/components/charts/mixins/resize'
import { init, EChartsOption } from 'echarts'
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '250px',
      required: true
    },
    chartData: {
      type: Object,
      default: {}
    },
    selectedTime: {
      type: String || Number,
      default: ''
    },
  },
  setup(props) {
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()

    watch(props, (newName, oldName) => {
      initChart()
    });

    const initChart = () => {
      const chartData = props.chartData
      if( !Object.keys(chartData).length ){
        return false
      }
      const barChart = init(document.getElementById(props.id) as HTMLDivElement)

      let xData = []
      let serviesData1 = [];
      // 1,
      //   {
      //     value: 2,
      //     itemStyle: {
      //       color: '#a90000'
      //     }
      //   },3,4,5
      for (const key in chartData) {
        xData.push(key.slice(2, key.length-1))

        if( key[0] === '=' ){
          //平 灰色
          serviesData1.push({
            value: chartData[key],
            itemStyle: {
              color: '#6B7486'
            }
          })
        }else if( key[0] === '-' ){
          //绿色  跌
          serviesData1.push({
            value: chartData[key],
            itemStyle: {
              color: '#5CBC7C'
            }
          })
        }else{
          //涨
          serviesData1.push({
            value: chartData[key],
            itemStyle: {
              color: '#F74E4F'
            }
          })
        }
      }


      barChart.setOption({
        // tooltip: {
        //   trigger: 'axis',
        //   formatter:function(param:any){
        //     let text = ''
        //     // text += `<div style='margin-bottom:5px'>${param[0].name}</div>`
        //     text += `<div>
        //                 <span style='width:10px;height:10px;display:inline-block;border-radius:50%;background:#F74E4F'></span>
        //                 <span style='margin-left:10px;display:inline-block;text-align:right;color:#333'>${param[0].value}</span>
        //             </div>`
        //     return text
        //   }
        // },
        grid: {
          top: '20px',
          left: '0',
          right: '10px',
          bottom: '0%',
          containLabel: true
        },
        // legend: {
        //   // show: false,
        //   data: ['机构来访量', '重点机构来访量'],
        //   icon: 'circle',
        //   x: '0',
        //   left: '0',
        //   top: '0',
        //   itemGap: 30,
        //   textStyle: {
        //     align: 'left', // 这个是可以左右调整的
        //     fontSize: 14,// 字体大小
        //     color:'#999999'
        //     // padding:[0,0,25,-45]//主要就是用这个，可以把文字上下调整
        //     // color: 'rgba(255,255,255,0.65)'//字体颜色
        //   }
        // },
        toolbox: {
          show: true
          // feature: {
          //   dataView: { show: true, readOnly: false },
          //   magicType: { show: true, type: ['line', 'bar'] },
          //   restore: { show: true },
          //   saveAsImage: { show: true }
          // }
        },
        calculable: true,
        xAxis: [
          {
            type: 'category',
            // prettier-ignore
            data: xData,
            axisLine: {
                show: true,
                lineStyle: {
                    color: "#BFBFBF",
                    // width: 0,
                    // type: "solid"
                }
            },
            // splitLine: { show: false },
            // axisLabel:{show:false},
            // axisTick: { show: false },
            // axisLine: { show: false },
            axisLabel: {
              //x轴文字的配置
              show: true,
              interval: 0,//使x轴文字显示全
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: { show: false },
            // axisTick: { show: false },
            axisLabel: { show: false }
          }
        ],
        series: [
          {
            name: '',
            barGap: '80%',
            barWidth: '60%', // 柱子宽度
            type: 'bar',
            data: serviesData1,
            itemStyle: {
              normal: {
                label: {
                  // formatter: '{c}',
                  show: true,
                  position: 'top',
                  textStyle: {
                    fontWeight: 'bolder',
                    fontSize: '12',
                    color: '#333333'
                  }
                },
                // color: 'green',
                opacity: 1
              }
            }
            // markPoint: {
            //   data: [
            //     { type: 'max', name: 'Max' },
            //     { type: 'min', name: 'Min' }
            //   ]
            // },
            // markLine: {
            //   data: [{ type: 'average', name: 'Avg' }]
            // }
          }
        ]
      } as EChartsOption)
      chart.value = barChart

      // barChart.on('click', (params:any) => {
      //   console.log(params)
      //   window.open(`/investment/research/list?industryName=${params['name']}&isPointOrg=${params['seriesIndex']}&time=${props.selectedTime}`)
      // })
    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {

    }
  }
})
</script>
