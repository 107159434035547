/*
 * @Description: 市场综述接口
 */
import https from '@/utils/https'
import { RequestParams, ContentType, Method } from 'axios-mapper'

//大盘表现
export const getMarketEvent = (params: RequestParams) => {
  return https().request('/central_bank/market/event', Method.GET, params, ContentType.form)
}

//降准后大盘涨跌幅
export const getRrrMarketIncr = (params: RequestParams) => {
  return https().request('/central_bank/rrr/market/incr', Method.GET, params, ContentType.form)
}

//降印花税后大盘涨跌幅
export const getStampTaxMarketIncr = (params: RequestParams) => {
  return https().request('/central_bank/stamp_tax/market/incr', Method.GET, params, ContentType.form)
}

//利率信息
export const getInterestRate = (params: RequestParams) => {
  return https().request('/central_bank/interest_rate', Method.GET, params, ContentType.form)
}
