<template>
  <div class="agency-rating-container">
    <CTitle
        :mode="1"
        :title="title"
        class="pj"
      />
    <el-row>
      <el-col :span="24" >
        <NewTitle
          title="买入评级"
          @change="changeHandle1"
          :icon='true'
          isJump='1'
          :toNewList="true"
          ref='refNewTitle1'
        />
        <el-table
          class="table-style-2 cursor"
          :data="stockList1.list"
          border
          style="width: 100%"
          @sort-change="sortChange1"
          @row-click='goDetail1'
          :default-sort ="{prop:'orgCount',order:'descending'}"
        >
          <el-table-column
            prop="stockName"
            align="center"
            label="股票名称"
          />
          <el-table-column
            prop="orgCount"
            align="center"
            label="评级家数"
            sortable='custom'
          />
          <el-table-column
            prop="maxIncr"
            align="center"
            label="阶段最高涨幅"
            sortable='custom'
          >
            <template #default='{row}'>
              <div v-dClass='row.maxIncr'>
                <template v-if="row.maxIncr[0] !== '-'">+</template>{{row.maxIncr}}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="intervalIncr"
            align="center"
            label="区间涨幅"
            sortable='custom'
          >
            <template #default='{row}'>
              <div v-dClass='row.intervalIncr'>
                <template v-if="row.intervalIncr[0] !== '-'">+</template>{{row.intervalIncr}}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
      <el-col :span="24" >
        <NewTitle
          title="评级调高"
          @change="changeHandle2"
          :icon='true'
          isJump='6'
          ref='refNewTitle2'
        />
        <el-table
          class="table-style-2 cursor"
          :data="stockList2"
          border
          style="width: 100%"
          @sort-change="sortChange2"
          @row-click='goDetail2'
          :default-sort ="{prop:'date',order:'descending'}"
        >
          <el-table-column
            prop="stockName"
            align="center"
            label="股票名称"
          />
          <el-table-column
            prop="orgSName"
            align="center"
            label="机构名称"
          >
            <template #default='{row}'>
              <div @click.stop="goList2(row.orgSName)">
                {{row.orgSName}}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="maxIncr"
            align="center"
            label="阶段最高涨幅"
            sortable='custom'
          >
            <template #default='{row}'>
              <div v-dClass='row.maxIncr'>
                <template v-if="row.maxIncr[0] !== '-'">+</template>{{row.maxIncr}}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="intervalIncr"
            align="center"
            label="区间涨幅"
            sortable='custom'
          >
            <template #default='{row}'>
              <div v-dClass='row.intervalIncr'>
                <template v-if="row.intervalIncr[0] !== '-'">+</template>{{row.intervalIncr}}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="date"
            align="center"
            label="评级日期"
            sortable='custom'
          >
            <template #default='{row}'>
                {{moment(row.date).format('YYYY-MM-DD')}}
            </template>
          </el-table-column>
        </el-table>
      </el-col>
      <el-col :span="24" >
        <NewTitle
          title="首次覆盖"
          @change="changeHandle3"
          :icon='true'
          isJump='8'
          ref='refNewTitle3'
        />
        <el-table
          class="table-style-2 cursor"
          :data="stockList3"
          border
          style="width: 100%"
          @sort-change="sortChange3"
          @row-click='goDetail3'
          :default-sort="{prop:'date',order:'descending'}"
        >
          <el-table-column
            prop="stockName"
            align="center"
            label="股票名称"
          />
          <el-table-column
            prop="orgSName"
            align="center"
            label="机构名称"
          >
            <template #default='{row}'>
              <div @click.stop="goList3(row.orgSName)">
                {{row.orgSName}}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="maxIncr"
            align="center"
            label="阶段最高涨幅"
            sortable='custom'
          >
            <template #default='{row}'>
              <div v-dClass='row.maxIncr'>
                <template v-if="row.maxIncr[0] !== '-'">+</template>{{row.maxIncr}}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="intervalIncr"
            align="center"
            label="区间涨幅"
            sortable='custom'
          >
            <template #default='{row}'>
              <div v-dClass='row.intervalIncr'>
                <template v-if="row.intervalIncr[0] !== '-'">+</template>{{row.intervalIncr}}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="fxsName"
            align="center"
            label="分析师"
          >
            <template #default='{row}'>
              <div v-if="row.isTop">
                <span v-for="(item, idx) in row.fxsList" :key='idx' :class="item.isTop === 1 ? 'fxs' : ''">
                  {{item.fxsName}}<img src="@/assets/images/new-fxs.png" v-if="item.isTop === 1" />
                  <template v-if="idx !== row.fxsList.length-1">,</template>
                </span>
              </div>
              <div v-else>
                {{row.fxsName}}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="date"
            align="center"
            label="评级日期"
            sortable='custom'
          >
            <template #default='{row}'>
                {{moment(row.date).format('YYYY-MM-DD')}}
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script lang="ts">

import { defineComponent, reactive, toRefs, ref } from 'vue'
import CTitle from '@/components/title/Index.vue'
import NewTitle from '@/components/new-title/Index.vue'
import { getStockReportBuy, getStockReportUp, getStockReportFirst } from '@/apis/agency'
import moment from 'moment' // 导入文件
import dicts from '../../../utils/dicts'
interface Inter {
  order: string | undefined,
  orderBy: string | undefined,
}
export default defineComponent({
  props: {
    dataMap: {
      type: Array,
      default: []
    },
    title: {
      type: String,
      default: '机构评级'
    }
  },
  components: {
    CTitle,
    NewTitle
  },
  setup() {
    const refNewTitle1 = ref(null)
    const refNewTitle2 = ref(null)
    const refNewTitle3 = ref(null)
    const dataMap = reactive({
      stockList1: [],
      stockList2: [],
      stockList3: [],
      // sortParams1: {
        
      // },
    })
    let sortParams1:Inter = reactive({
      order: 'DESC',
      orderBy: 'orgCount',
    })
    let sortParams2:Inter = reactive({
      order: 'DESC',
      orderBy: 'date',
    })
    let sortParams3:Inter = reactive({
      order: 'DESC',
      orderBy: 'date',
    })
    
    const changeHandle1 = async (params:any) => {
      if( sortParams1['timeLevel'] !== 0 ){
        delete sortParams1['dateFrom']
        delete sortParams1['dateTo']
      }
      sortParams1 = {...sortParams1, ...params}
      const data = await getStockReportBuy(sortParams1)
      dataMap.stockList1 = (data as any).data
    }
    const sortChange1 = (item:any) => {
      if( item.prop ){
        changeHandle1({
          order: item.order === "ascending" ? 'ASC' : 'DESC',
          orderBy: item.prop
        })
      }else{
        delete sortParams1.order
        delete sortParams1.orderBy
        changeHandle1({})
      }
    }

    const changeHandle2 = async (params:any) => {
      if( sortParams2['timeLevel'] !== 0 ){
        delete sortParams2['dateFrom']
        delete sortParams2['dateTo']
      }
      sortParams2 = {...sortParams2, ...params}
      const data = await getStockReportUp(sortParams2)
      dataMap.stockList2 = (data as any).data
    }
    const sortChange2 = (item:any) => {
      if( item.prop ){
        changeHandle2({
          order: item.order === "ascending" ? 'ASC' : 'DESC',
          orderBy: item.prop
        })
      }else{
        delete sortParams2.order
        delete sortParams2.orderBy
        changeHandle2({})
      }
    }

    const changeHandle3 = async (params:any) => {
      if( sortParams3['timeLevel'] !== 0 ){
        delete sortParams3['dateFrom']
        delete sortParams3['dateTo']
      }
      sortParams3 = {...sortParams3, ...params}
      const data = await getStockReportFirst(sortParams3)
      dataMap.stockList3 = (data as any).data
    }
    const sortChange3 = (item:any) => {
      if( item.prop ){
        changeHandle3({
          order: item.order === "ascending" ? 'ASC' : 'DESC',
          orderBy: item.prop
        })
      }else{
        delete sortParams3.order
        delete sortParams3.orderBy
        changeHandle3({})
      }
    }

    
    const goDetail1 = async (item:any) => {
      const Index = dicts.timeSelection.findIndex((item) => item.value === sortParams1['timeLevel']);
      if(Number(sortParams1['timeLevel']) === 0){
        const beginDate = window.moment(sortParams2['dateFrom']).format('YYYY-MM-DD')
        const endDate = window.moment(sortParams2['dateTo']).format('YYYY-MM-DD')
        window.open(`/investment/agency/index?idx=1&timeLevel=4&beginDate=${beginDate}&endDate=${endDate}&organizationSName=${item.stockName}`)
      }else{
        window.open(`/investment/agency/index?idx=1&timeLevel=${Index - 1}&organizationSName=${item.stockName}`)
      }
    }
    const goDetail2 = async (item:any) => {
      (refNewTitle2 as any).value.handleJump(item.stockName)
    }
    const goDetail3 = async (item:any) => {
      (refNewTitle3 as any).value.handleJump(item.stockName)
    }

    const goList2 = async (name:any) => {
      (refNewTitle2 as any).value.handleJump2(name)
    }
    const goList3 = async (name:any) => {
      (refNewTitle3 as any).value.handleJump2(name)
    }

    return {
      refNewTitle1,
      refNewTitle2,
      refNewTitle3,
      moment,
      sortChange1,
      sortChange2,
      sortChange3,
      changeHandle1,
      changeHandle2,
      changeHandle3,
      goDetail1,
      goDetail2,
      goDetail3,
      goList2,
      goList3,
      ...toRefs(dataMap)
    }
  }
})
</script>
<style scoped lang='scss'>
.agency-rating-container{
  background: #fff;
  margin-top: 30px;
  // padding: 34px 32px 52px;
  overflow: hidden;
  .pj{
    margin-bottom: 23px!important;
  }
  .el-table{
    margin-bottom: 20px;
  }
  .el-col{
    // margin-bottom: 40px;
      margin-bottom: 0;
    &:last-child{
      .el-table{
        margin-bottom: 0;
      }
    }
  }
  .t-title{
    // width: 950px;
  }
  .list-box{
    display: flex;
    justify-content: space-between;
  }
  .list{
    width: 30%;
    min-width: 266px;
    max-width: 350px;
    float: left;
    // margin-right: 79px;
    // height: 371px;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    border-radius: 6px;
    .header{
      border-bottom: 1px solid #EEEEEE;
      padding: 11px 0 8px;
      position: relative;
      .btn{
        width: 120px;
        height: 36px;
        color: #010101;
        line-height: 36px;
        text-align: center;
        background: #E4EBF9;
        margin: 0 auto;
        cursor: pointer;
      }
      img{
        position: absolute;
        right: 0;
        top: 0;
      }
    }
    .content{
      ul{
        margin: 22px 0 33px;
        li{
          padding: 0 13px;
          margin-bottom: 18px;
          display: flex;
          cursor: pointer;
          .left{
            flex: 1;
            display: flex;
            .active0{
              color: #ED584B!important;
            }
            .active1{
              color: #EA8E3C!important;
            }
            .active2{
              color: #FFBC41!important;
            }
            div{
              font-size: 18px;
              font-family: PingFang;
              font-weight: bold;
              &:nth-child(1){
                color: #878787;
                padding-right: 10px;
              }
              &:nth-child(2){
                // width: 200px;
                color: #000;
                // margin-right: 40px;
              }
            }
          }
          .right{
            width: 100px;
            margin-left: auto;
            text-align: right;
            font-size: 18px;
            font-weight: bold;
          }
          
        }
      }
    }
    .footer{
      border-top: 1px solid #EEEEEE;
      padding: 24px 35px 27px;
      text-align: center;
      font-size: 16px;
      font-family: PingFang;
      font-weight: 500;
      color: #000000;
      line-height: 28px;
      .tips{
        color: #EA5651;
      }
    }
  }
}
.fxs{
  color: #FFBA42;
  img{
    vertical-align: middle;
    margin: -4px 2px 0;
  }
}
</style>
