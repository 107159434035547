<template>
  <div >
    <div v-if="boardDetail.detail">
      <div class="describe" v-if="boardDetail.detail">
        昨日融资余额<span class="black">{{ zh(boardDetail.detail.preRzye) }}元</span>，
        较前一日{{boardDetail.detail.pre1RzyeAdd > 0 ? '增加' : '减少'}}<span v-dClass='boardDetail.detail.pre1RzyeAdd'>{{zh(boardDetail.detail.pre1RzyeAdd)}}元</span>
        ，{{boardDetail.detail.pre1RzyeAdd > 0 ? '增幅' : '降幅'}}为<span v-dClass='boardDetail.detail.pre1RzyeIncr'>{{Math.abs(boardDetail.detail.pre1RzyeIncr)}}%</span>，

        <template v-if="boardDetail.detail.continueRzyeAdd > 1">融资余额<span v-dClass='boardDetail.detail.continueRzyeAdd'>{{Math.abs(boardDetail.detail.continueRzyeAdd)}}{{ boardDetail.detail.continueRzyeAdd > 0 ? '连升' : '连降' }}</span>，</template>

        近5日{{boardDetail.detail.pre5RzyeAdd > 0 ? '增加' : '减少'}}<span v-dClass='boardDetail.detail.pre5RzyeAdd'>{{zh(boardDetail.detail.pre5RzyeAdd)}}</span>
        ，{{boardDetail.detail.pre5RzyeAdd > 0 ? '增幅' : '降幅'}}<span v-dClass='boardDetail.detail.pre5RzyeIncr'>{{Math.abs(boardDetail.detail.pre5RzyeIncr)}}%</span>
        ；融券余额<span class="black">{{zh(boardDetail.detail.preRqye)}}亿元</span>
        ，相较5日均值{{boardDetail.detail.pre5AvgRqyeAdd > 0 ? '增加' : '减少'}}
        <span v-dClass='boardDetail.detail.pre5AvgRqyeAdd'>{{zh(boardDetail.detail.pre5AvgRqyeAdd)}}</span>。
      </div>
      <div class="chart-box">
          <ChartA id='ChartA' :chartData="boardDetail.dayKList" v-if="boardDetail.dayKList" :chartKey='chartKey' :showChartIdx='showChartIdx'  />
          <div class="select-chart-list">
            <ul>
              <li :class="showChartClass === 0 ? 'active' : ''" @click="tabChart(0, 1, 'rzye')">
                <div class="icon circle"></div>
                <div class="text">融资余额(元)</div>
              </li>
              <li :class="showChartClass === 1 ? 'active' : ''" @click="tabChart(1, 2, 'rzjmr')">
                <div class="icon line"></div>
                <div class="text">融资净买入(元)</div>
              </li>
              <li :class="showChartClass === 2 ? 'active' : ''" @click="tabChart(2, 1, 'rzyezb')">
                <div class="icon circle"></div>
                <div class="text">融资余额占流通市值比(%)</div>
              </li>
              <li :class="showChartClass === 3 ? 'active' : ''" @click="tabChart(3, 1, 'rzrqye')">
                <div class="icon circle"></div>
                <div class="text">融资融券余额(元)</div>
              </li>
            </ul>
            <ul>
              <li :class="showChartClass === 4 ? 'active' : ''" @click="tabChart(4, 1, 'rqye')">
                <div class="icon circle"></div>
                <div class="text">融券余额(元)</div>
              </li>
              <li :class="showChartClass === 5 ? 'active' : ''" @click="tabChart(5, 2, 'rqjmc')">
                <div class="icon line"></div>
                <div class="text">融券净卖出(股)</div>
              </li>
              <li class="active spj" >
                <div class="icon circle"></div>
                <div class="text">收盘价(元)</div>
              </li>
            </ul>
          </div>
        </div>
    </div>
    <el-empty description="暂无数据" v-else />
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import { getMarginStockDetail } from '@/apis/trading'
import ChartA from '@/views/margin-trading/individual-shares/components/ChartA.vue'

export default {
  components:{
    ChartA
  },
  setup() {
    const route = useRoute()
    const stockCode = route.query.stockCode || route.query.code
    const dataMap = reactive({
      boardDetail: {},
      showChartClass: 0,
      showChartIdx: 1,
      chartKey : 'rzye'
    })

    const fetchData = async () => {
      // 明细
      const boardDetail = await getMarginStockDetail({ stockCode })
      if( boardDetail ){
        dataMap.boardDetail = boardDetail.data;
      }
    }
    fetchData()

    const zh = (param) => {
      return `${Math.abs(param) >= 100000000 ? (param / 100000000).toFixed(2) + '亿' : Math.abs(param) >= 10000 ? Math.abs((param / 10000)).toFixed(2) + '万' : param.toFixed(2) }`
    }

    const tabChart = (idx, type, key) => {
      dataMap.showChartClass = idx
      dataMap.showChartIdx = type
      dataMap.chartKey = key
    }


    return {
      ...toRefs(dataMap),
      tabChart,
      zh
    }
  }
}
</script>

<style scoped lang="scss">
.describe{
    margin-bottom: 57px;
    .tips{
      margin: 20px 0;
      font-size: 16px;
      font-family: none;
    }
    .list{
      display: inline-block;
      padding: 0 15px;
      background: #F7F8FA;
      ul{
        // display: flex;
        clear: both;
        overflow: hidden;
        &:first-child{
          border-bottom: 1px solid #EBF0F9;
        }
        li{
          width: 147px;
          text-align: center;
          padding: 5px 0;
          margin: 7px 0;
          border-right: 1px solid #EBF0F9;
          display: inline-block;
          &:last-child{
            border: 0;
          }
          .t{
            font-size: 14px;
            font-weight: 500;
            color: #333333;
          }
          .b{
            font-size: 20px;
            font-weight: 600;
            > span {
              font-size: 12px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              line-height: 17px;
            }
          }
        }
      }
    }
}
.chart-box{

      .select-chart-list{
        padding-bottom: 50px;
        ul{
          width: 900px;
          margin: auto;
          margin-top: 28px;
          display: flex;
          li{
            margin: 0 100px 9px 0;
            cursor: pointer;
            display: flex;
            align-items: center;
            &.active{
              .circle{
                &:before,&::after{
                  border: 1px solid #3B7FEE;
                }
              }
              .line{
                background: #3B7FEE;
              }
            }
            &.spj{
              .circle{
                &:before,&::after{
                  border: 1px solid #FE9625;
                  // background: #FE9625;
                }
              }
            }
            .circle{
              width: 19px;
              position: relative;
              &:before{
                content: '';
                width: 9px;
                height: 9px;
                border-radius: 50%;
                background: #FFFFFF;
                border: 1px solid #CDCDCD;
                z-index: 1;
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                margin: auto;
              }
              &::after{
                content: '';
                width: 19px;
                height: 1px;
                background: #CDCDCD;
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                margin: auto;
              }
            }
            .line{
              width: 8px;
              height: 3px;
              background: #CDCDCD;
            }
            .text{
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              padding-left: 5px;
            }
          }
        }
      }
    }
</style>
