
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
import CTitle from '@/components/title/Index.vue'
import { getAggStockChangeData, getAggBoardChangeData } from '@/apis/home'
export default defineComponent({
  components: {
    CTitle,
  },
  setup() {
    const dataMap = reactive({
      isOpen: false,
      list: [],
      stockList1: [],
      stockList2: {},
    })

    const fetchData = async () => {
      const data = await getAggStockChangeData({  })
      dataMap.stockList1 = (data as any).data;

      const data1 = await getAggBoardChangeData({  })
      dataMap.stockList2 = (data1 as any).data;
    }
    fetchData()

    onMounted(() => {
    })

    const handleToggle = () => {
      dataMap.isOpen = !dataMap.isOpen
    }

    //跳转数据中心
    const goData = (row:any) => {
      // console.log(row)
      window.open(`/page/data_center_all?industryName=${row['boardName']}`)
      // dataMap.isOpen = !dataMap.isOpen
    }

    //跳转详情
    const goDetail = (row:any) => {
      window.open(`/investment/home/detail?stockCode=${row.code}`)
    }

    return {
      ...toRefs(dataMap),
      handleToggle,
      goData,
      goDetail
    }
  }
})

