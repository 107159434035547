
import { defineComponent, onActivated, onBeforeUnmount, onDeactivated, onMounted, nextTick, watch } from 'vue'
import resize from '@/components/charts/mixins/resize'
import { init, EChartsOption } from 'echarts'
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '300px',
      required: true
    },
    chartData: {
      type: Object,
      default: {}
    },
    selectedTime: {
      type: String || Number,
      default: ''
    },
  },
  setup(props, context) {
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()

    watch(props, (newName, oldName) => {
      initChart()
    });

    const initChart = () => {
      const chartData = props.chartData
      if( !chartData.length ){
        return false;
      }
      const barChart = init(document.getElementById(props.id) as HTMLDivElement)
      const xData = Array.from((chartData as any), ({date}) => date).map((item)=> item = item.substring(5))
      const rise = Array.from((chartData as any), ({rise}) => rise)
      const flat = Array.from((chartData as any), ({flat}) => flat)
      const szzs = Array.from((chartData as any), ({szzs}) => szzs)
      const fall = Array.from((chartData as any), ({fall}) => fall).map((item)=> item = item - item * 2)
      const yMin = JSON.parse(JSON.stringify(szzs)).sort()[0]
      const yMax = JSON.parse(JSON.stringify(szzs)).sort((a:any,b:any)=>b-a)[0]
      context.emit('getZlMaxMinVal', {yMin,yMax})

      
      barChart.setOption({
        legend: {
          // show: false,
          data: ['上涨家数', '平家数', '下跌家数', '上证指数'],
          icon: 'line',
          // type: 'scroll',
          itemWidth: 8,  // 设置宽度
          itemHeight: 3, // 设置高度
          x: 'center',
          bottom: '0',
          itemGap: 30,
          textStyle: {
            // align: 'left', // 这个是可以左右调整的
            fontSize: 12,// 字体大小
            color:'#666666',
            // padding:[0,0,25,-45]//主要就是用这个，可以把文字上下调整
            // color: 'rgba(255,255,255,0.65)'//字体颜色
          }
        },
        tooltip: {
          trigger: 'axis',
          formatter:function(param:any){
            // console.log('jjjjjjjjjjj', props.chartData[param[0].dataIndex])
            const item = props.chartData[param[0].dataIndex]
            let text = ''
            // text += `<div style='margin-bottom:5px'>${param[0].name}</div>`
            text += `<div> 
                        <span style='width:10px;height:10px;display:inline-block;border-radius:50%;margin-right:3px;background:#F74E4F'></span>
                        <span style='width:70px;display:inline-block;color:#666'>上涨家数：</span>
                        <span style='width:50px;display:inline-block;text-align:right;color:#333'>${item.rise ? item.rise + '家' : '-'}</span> 
                    </div>`
            text += `<div> 
                        <span style='width:10px;height:10px;display:inline-block;border-radius:50%;margin-right:3px;background:#6B7486'></span>
                        <span style='width:70px;display:inline-block;color:#666'>平家数：</span>
                        <span style='width:50px;display:inline-block;text-align:right;color:#333'>${item.flat ? item.flat + '家' : '-'}</span> 
                    </div>`
            text += `<div> 
                        <span style='width:10px;height:10px;display:inline-block;border-radius:50%;margin-right:3px;background:#5CBC7C'></span>
                        <span style='width:70px;display:inline-block;color:#666'>下跌家数：</span>
                        <span style='width:50px;display:inline-block;text-align:right;color:#333'>${item.fall ? item.fall + '家' : '-'}</span> 
                    </div>`
            text += `<div> 
                        <span style='width:10px;height:10px;display:inline-block;border-radius:50%;margin-right:3px;background:#FE9625'></span>
                        <span style='width:70px;display:inline-block;color:#666'>上证指数：</span>
                        <span style='width:50px;display:inline-block;text-align:right;color:#333'>${item.szzs ?? '-'}</span> 
                    </div>`
            return text
          }
        },
        grid: {
          top: '20px',
          left: '0',
          right: '0%',
          bottom: '30px',
          containLabel: true
        },
        toolbox: {
          show: true
        },
        calculable: true,
        xAxis: [
          {
            type: 'category',
            // prettier-ignore
            data: xData,
            splitLine: { show: false },
            // axisLabel:{show:false},
            axisTick: { show: false },
            axisLine: { show: false },
            axisLabel: {
              //x轴文字的配置
              bottom: '40',
              show: true,
              margin: 50,
              interval: 0,//使x轴文字显示全
              rotate: window.innerWidth < 1350 ? 45 : 0,
            },
          }
        ],
        yAxis: [
          {
            type: 'value',
            min: yMin,
            splitLine: { show: false },
            // axisTick: { show: false },
            axisLabel: { show: false }
          },
          {
            type: 'value',
            // min: yMin,
            splitLine: { show: false },
            // axisTick: { show: false },
            axisLabel: { show: false }
          }
        ],
        series: [
          {
            name: '下跌家数',
            barGap: '80%',
            barWidth: '40%', // 柱子宽度
            type: 'bar',
            stack: 'Ad',
            data: fall,
            yAxisIndex: 1,
            itemStyle: {
              normal: {
                color: '#5CBC7C',
              }
            }
          },
          {
            name: '平家数',
            barGap: '80%',
            barWidth: '40%', // 柱子宽度
            type: 'bar',
            stack: 'Ad',
            data: flat,
            yAxisIndex: 1,
            itemStyle: {
              normal: {
                color: '#6B7486',
              }
            }
          },
          {
            name: '上涨家数',
            barGap: '80%',
            barWidth: '40%', // 柱子宽度
            type: 'bar',
            data: rise,
            stack: 'Ad',
            yAxisIndex: 1,
            itemStyle: {
              normal: {
                color: '#F74E4F',
                label:{
                  show: true,
                  position: 'top',
                  formatter: '{c}家',
                  textStyle: {
                    fontSize: '12',
                  }
                }
              }
            }
          },
          {
            name: '上证指数',
            barGap: '80%',
            type: 'line',
            data: szzs,
            yAxisIndex: 0,
            itemStyle: {
              normal: {
                color: '#FE9625',
              }
            }
          }
        ]
      } as EChartsOption)
      chart.value = barChart

      // barChart.on('click', (params:any) => {
      //   console.log(params)
      //   window.open(`/investment/research/list?industryName=${params['name']}&isPointOrg=${params['seriesIndex']}&time=${props.selectedTime}`)
      // })
    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {

    }
  }
})
