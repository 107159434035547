<template>
  <div style="width: 609px; text-align: center;font-size: 18px;font-weight: 500;color: #333333;line-height: 25px;margin-bottom: 8px">
    <span>北向资金持有{{ title }}板块趋势</span>
    <div
      :id="id"
      :class="className"
      :style="{height: height, width: width}"
    />
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onActivated,
  onBeforeUnmount,
  onDeactivated,
  onMounted,
  nextTick,
  watch,
} from 'vue'
import resize from '@/components/charts/mixins/resize'
import { init, EChartsOption } from 'echarts'
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '350px',
      required: true
    },
    chartData: {
      type: Object,
      default: {}
    },
    selectedTime: {
      type: String || Number,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
  },
  setup(props) {

    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()

    watch(props, (newName, oldName) => {
      initChart()
    });
    const initChart = () => {
      const barChart = init(document.getElementById(props.id) as HTMLDivElement)
      const xAxisList = Array.from(props.chartData.boardDaily.points, ({ x }) => x);
      // 行业指数
      const industryIndex = Array.from(props.chartData.boardDaily.points, ({ y }) => y);
      const industryList = industryIndex.filter((item) => item !== null)
      const maxIndustry = Math.ceil(Math.max(...industryList));
      const minIndustry = Math.floor(Math.min(...industryList));
      // 持股市值
      const holdMarketIndex = Array.from(props.chartData.holdMarketDaily.points, ({ y }) => y);
      const maxHoldMarket = Math.ceil(Math.max(...holdMarketIndex));
      const minHoldMarket = Math.floor(Math.min(...holdMarketIndex));
      const integerData: any = [];
      holdMarketIndex.map((item) => {
        let obj = {
          value: item,
          itemStyle: {
            color: item > 0 ? '#F74E4F' : '#5CBC7C'
          }
        }
        integerData.push(obj)
      })
      // 持股占北向资金比变化
      const RatioIndex = Array.from(props.chartData.holdMarketRatioDaily.points, ({ y }) => y);
      const maxRatio = Math.ceil(Math.max(...RatioIndex));
      const minRatio = Math.floor(Math.min(...RatioIndex));
      const iconUrl = 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAHCAYAAAFy0i3pAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAEqADAAQAAAABAAAABwAAAAAyqaqnAAAAR0lEQVQoFWNgwAYYY/bU/AdJLHFpYfzu5w9mYyiEq5o96TJYEi4AU4pXO0wR2TRR1jGRbTzNNGK4G90mULCBxHCGPLoGSvkA1Yoe51xpxlAAAAAASUVORK5CYII='
      barChart.setOption({
        tooltip: {
          trigger: 'axis',
          formatter:function(params: any) {
            let text = `<div style="display: flex; justify-content: space-between"><span>${ params[0].axisValue }</span><span></span></div>`;
            for (let i = 0; i < params.length; i++) {
              const item = params[i]
              text += `<div style="display: flex; justify-content: space-between">
                      <div style="display: flex;align-items: center">
                        <span style='width:8px;height:8px;display:inline-block;border-radius:50%;margin-right:3px;background: ${item.color}'></span>
                        <span style='display:inline-block;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;line-height: 17px;'>${item.seriesName}：</span>
                      </div>

                        <span style='display:inline-block;font-size: 14px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #333333;line-height: 20px;'>${item.seriesName == '持股市值变化' ? (Math.abs(item.value) > 10000 ? (item.value / 10000).toFixed(2) + '亿' : item.value.toFixed(2) + '万')  : item.value}</span>
                    </div>`
            }
            return text
          }
        },
        grid: {
          top: '30px',
          left: '45px',
          right: '10px',
          bottom: '40px',
          containLabel: true
        },
        color: ['#AEAEAE', '#0086FB'],
        legend: {
          icon: 'line',
          itemWidth: 8,  // 设置宽度
          itemHeight: 3, // 设置高度
          x: 'center',
          bottom: '0',
          itemGap: 30,
          textStyle: {
            fontSize: 12,// 字体大小
            color:'#666666',
          },
          data: [
            {
              name: '行业指数'
            },
            {
              name: '持股市值变化',
              icon: iconUrl,
            },
            {
              name: '持股占北向资金比变化'
            },
          ]
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xAxisList,
          axisLabel: {
            textStyle: {
              color: '#999',
              fontSize: 12,
            }
          },
        },
        yAxis: [
          {
            type: 'value',
            splitLine: { show: false },
            offset: 90,
            position: 'left',
            name: '行业指数',
            max: maxIndustry,
            min: minIndustry,
            axisPointer: {
              type: 'none',
              label: {show: false}
            },
            axisLabel: {
              show: true,
              align: 'right',
            },
          },
          {
            type: 'value',
            splitLine: {show: false},
            position: 'left',
            name: '持股市值变化',
            max: maxHoldMarket,
            min: minHoldMarket,
            axisPointer: {
              type: 'none',
              label: { show: false }
            },
            axisLabel: {
              show: true,
              align: 'right',
              formatter:function(param: any) {
                return Math.abs(param) >= 10000 ? (param / 10000).toFixed(2) + '亿' : param.toFixed(2) + '万'
              }
            }
          },
          {
            type: 'value',
            splitLine: { show: true, lineStyle: { type: 'dashed' } },
            position: 'right',
            name: '单位：‰',
            max: maxRatio,
            min: minRatio,
            axisPointer: {
              type: 'none',
              label: { show: false }
            },
            axisLabel: {
              show: true,
              align: 'left'
            }
          },
        ],
        series: [
          {
            name: '行业指数',
            type: 'line',
            symbol: 'none',
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 1,
                  color: '#AEAEAE'
                }
              }
            },
            data: industryIndex
          },
          {
            name: '持股市值变化',
            type: 'bar',
            yAxisIndex: 1,
            barWidth: 3,
            itemStyle: {
              normal: {
                color: '#F74E4F'
              }
            },
            data: integerData
          },
          {
            name: '持股占北向资金比变化',
            type: 'line',
            yAxisIndex: 2,
            symbol: 'none',
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 1,
                }
              }
            },
            data: RatioIndex
          },
        ]
      } as EChartsOption)
      chart.value = barChart
    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {}
  }
})
</script>
