<template>
  <div class="quarter_table">
    <div class="top_item_box">
      <span style="color: #333333;">成长能力指标(单季度)</span>
      <span style="color: #333333;">总营收</span>
      <span style="color: #333333;">归母净利润</span>
      <span style="color: #333333;">营收同比(%)</span>
      <span style="color: #333333;">归属净利润同比(%)</span>
      <span style="color: #333333;">营收环比(%)</span>
      <span style="color: #333333;">归属净利润环比(%)</span>
    </div>
    <div class="item_box" v-for="(item, index) in tableList" :key="index">
      <span style="color: #333333; display: flex;flex-direction: column;justify-content: center;line-height: 23px">{{ item.date }}<span style="display: block; font-size: 10px;color: #3B7FEE">{{item.reportDate.slice(0,4)}}{{ item.reportDateDesc }}</span></span>
      <span>{{ item.totalOperateIncome }}</span>
      <span>{{ item.netProfit }}</span>
      <span>{{ item.incomeYoy }}</span>
      <span>{{ item.profitYoy }}</span>
      <span>{{ item.incomeMom }}</span>
      <span>{{ item.profitMom }}</span>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue'
export default defineComponent({
  props: {
    tableList: {
      type: Array,
      default: []
    }

  },
  setup() {
    const defineData = reactive({

    })
    return {
      ...toRefs(defineData)
    }
  }
})
</script>
<style scoped lang="scss">
.quarter_table {
  width: 90%;
  margin: 51px auto 0 auto;
  > .top_item_box {
    display: flex;
    > span {
      display: block;
      flex: 1;
      border-right: 1px solid #E8E8E8;
      border-top: 1px solid #E8E8E8;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #3B7FEE;
      line-height: 38px;
      &:first-child {
        border-left: 1px solid #E8E8E8;
      }
      text-align: center;
    }
  }
  > .item_box {
    display: flex;
    &:last-child {
      border-bottom: 1px solid #E8E8E8;
    }
    &:first-child {
      border-left: 1px solid #E8E8E8;
    }
    text-align: center;
    > span {
      flex: 1;
      border-right: 1px solid #E8E8E8;
      border-top: 1px solid #E8E8E8;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #3B7FEE;
      line-height: 42px;
      &:first-child {
        border-left: 1px solid #E8E8E8;
      }
      text-align: center;
    }
  }
}
</style>
