<template>
  <div class="market_table_box">
    <SearchForm @search='onSearch' ref='sf' />
    <el-table
      :data="tableData.list"
      class="table-style-2 cursor"
      @row-click='goDetail'
      border
      :cell-class-name='addClass'
      :header-cell-style="headerMethod"
      :default-sort ="{prop:'publishDate',order:'descending'}"
      @sort-change="sortChange"
      style="width: 100%">
        <el-table-column prop="name" label="公司名称" width="100" align="center" />
        <el-table-column prop="code" label="个股代码" width="100" align="center" />
        <el-table-column prop="publishDate" label="财报公布日" align="center" sortable='custom'>
          <template #default='{row}'>
            <div>
              {{ row.publishDate }}
              <img v-if="row.ahead === 1" class="e" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAA0CAYAAADFeBvrAAACA0lEQVRoQ+1aPU8CQRB9Q4gVsSEWNnZaaLT0B1iY2Fja2dkYIRErEi0oxFCB8SM0NnbGP6AxxsbC3gQLqdSGQgoDQtTzxixKIMQj7OZOjnOuu2Tf7rydt3Mze0PoeDIJnrItrDAwz4wxAJHOMX1+rxLhkYCLUBhHyRwV2u2h5stpioeKZWQZWAUj1Geje1ueYBOQH49iYylF7wrUIKTI3JdxBsZcbzP5bBThaiKKBUWqQSgd5wNmrPnMTC1ziHC4uU8xUmfGsnA7MDJzokmww2HM0E6cczZjXWs7fDo4RNildJwLzJj0qY1aZhHhjrZjXPFhaNYi0ja4qgixKdqPOCNCI6NAZNhbOg9FwLb11zAitLgMTM/qL6aDyCaB+qsO4nusEFK7IB7SVw76Ljm2gZtLA8sdINfngPWhP59rZ+jTAjIJfQPcRgghp6AgHnJbaz/zieREch5Jy7HOM8m2f8sUJCh45DnXgoKyz+TL3smrXgP2tszZukrI3IwWUpUMKo8zfYSQ1+WDbzykbiaeS6ZCaeHe6sBxznwe1yQnYdvcCV2R4iHJ5TySluRy3TZWktM/lJ1EuX8T5VTqU3pyT1u1KnCS15/PNcnpL90dUXkxq4uEkNflQ9Nv4qG2i8Zg/TQO3G/9wDVeBK41Rp2lQDUvKUKBay9rkgpMA2B7EjLoLZpfWHyAVHsoqAMAAAAASUVORK5CYII=" alt="">
              <!-- <span class="e" >E</span> -->
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="reportDate" label="报告期" align="center" />
        <el-table-column prop="netProfit" label="财报公布日归母TTM" align="center" sortable='custom'>
          <template #default='{row}'>
            <div>{{ row.netProfit || row.netProfit == 0 ? bigNumberTransform(row.netProfit) : '-' }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="profitIncr" label="业绩增速" align="center" sortable='custom'>
          <template #default='{row}'>
            <div>{{ row.profitIncr || row.profitIncr == 0 ? row.profitIncr.toFixed(2) + '%' : '-' }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="peTtm" label="财报公布日PE-TTM" align="center" sortable='custom'>
          <template #default='{row}'>
          <div>{{ row.peTtm || row.peTtm == 0 ? row.peTtm.toFixed(2) : '-' }}</div>
        </template>
      </el-table-column>
        <el-table-column prop="boardName" label="所属行业" align="center" />
    </el-table>
    <PagingComponent ref='paging' :showPageSizes='true' :pageSize='50' :pageData='tableData' @fetchData='fetchData' />
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue'
import { getFinanceReportHistory } from '@/apis/financial-report'
import PagingComponent from '@/components/pagination/Index.vue'
import SearchForm from './SearchForm.vue'
import { bigNumberTransform } from '@/utils/data-format'

export default defineComponent({
  components: {
    PagingComponent,
    SearchForm,
  },
  setup() {
    const dataMap = reactive({
      tableData: {},
      loading: true
    })
    let params = {
      // pageNum: '1',
      // pageSize: '10',
      // market: 'ALL',
      // timeUnit: 'WEEKS',
      // num: '1',
      sortField: 'publishDate'
    }
    const fetchData = async (value: any) => {
      if(value.pageNum){
        params['pageNum'] = value.pageNum
      }
      if(value.pageSize){
        params['pageSize'] = value.pageSize
      }
      
      dataMap.loading = true
      let marginList:any = await getFinanceReportHistory(params)
      dataMap.tableData = marginList.data;
      dataMap.loading = false
    }

    const sortChange = (column: any) => {
      let isAsc = column.order === "ascending" ? 'asc' : 'desc';
      params['sortField'] = column["prop"]
      params['sort'] = isAsc
      fetchData({})
    }

    const onSearch = (param: Object) => {
      params = {
        ...params,
        ...param,
      }
      fetchData({});
    }

    const addClass = (column:any) => {
      if( column.column.property === params.sortField ){
        return 'sort-bg'
      }
    }

    const goDetail = (item: any) => {
      const path = '/financial/detailed'
      window.open( `/investment${path}?stockCode=${item.code}&stockName=${item.name}`)
    }

    return {
      ...toRefs(dataMap),
      bigNumberTransform,
      fetchData,
      sortChange,
      onSearch,
      addClass,
      goDetail
    }
  }
})
</script>
<style scoped lang='scss'>
.e{
  vertical-align: top;
  width: 20px;
}
</style>