

import { defineComponent, reactive, toRefs, ref, nextTick } from 'vue'
import CTitle from "@/components/title/Index.vue"
import Search from '@/components/search-input/Index.vue'

import dicts from '@/utils/dicts'
import breadcrumb from '@/components/breadcrumb/Index.vue'
import Compare from './components/Compare.vue'
import CPagination from "@/components/pagination/Index.vue"

import { getSurveyStockDetail, getSurveyStockDetailOrgs} from '@/apis/research'

import { useRoute  } from "vue-router";
import { getSurveyOrg } from '@/apis/home';
import moment from 'moment';

export default defineComponent({
  components:{
    CTitle,
    Search,
    breadcrumb,
    CPagination,
    Compare,
  },
  setup() {
    const route = useRoute();
    const stockCode = route.query.code;
    const isPointOrg = Number(route.query.isPointOrg) === 1;
    const pagin = ref<InstanceType<typeof CPagination>>() 
    const compareRef = ref<InstanceType<typeof Compare>>() 

    const dataMap = reactive({
      stockInfo: {},
      formInline:{
        stockCode: stockCode,
        timeLevel: '1w',
        isPointOrg: isPointOrg ? ['只看重点机构'] : [],
      },
      dstxActive  : 0,
      showTimeSelect: false,
      selectTime: ['', ''],

      stockList1: {},
      stockList2: {},

      searchList2: [],
      tabActive: 0
    })
    

    //新版时间tab选择
    const swichItem = async (idx: any) => {
        dataMap.dstxActive = idx
        dataMap.showTimeSelect = idx === 5 ? true : false
        if( idx === 5 ){
          // delete dataMap.formInline['timeLevel']
          dataMap.formInline['timeLevel'] = ''
        }else{
          dataMap.formInline['timeLevel'] = dicts.timeSelection[idx].timeLevel
          delete dataMap.formInline['startTime'];
          delete dataMap.formInline['endTime'];
        }
    }

    if(route.query.timeLevel){
      swichItem(Number(route.query.timeLevel))

      //自定义时间
      if( dataMap.showTimeSelect ){
        const beginDate = `${route.query.beginDate ?? ''}`;
        const endDate = `${route.query.endDate ?? ''}`;
        dataMap.formInline['startTime'] = beginDate ? moment(beginDate).format('x') : ''
        dataMap.formInline['endTime'] = endDate ? moment(endDate).format('x') : ''
        dataMap['selectTime'] = [String(route.query.beginDate), String(route.query.endDate)]
      }else{
        dataMap.selectTime = []
      }
    }else{
      dataMap.selectTime = []
    }

    let ajaxNum = 0
    const fetchData = async () => {
      let data1 = await getSurveyStockDetail({
        ...dataMap.formInline,
        isPointOrg: dataMap.formInline.isPointOrg.length ? 1 : 0,
      })
      dataMap.stockList1 = (data1 as any).data

      if( ajaxNum === 0 ){
        compareRef.value!.setParams(dataMap.formInline)
      }

      ajaxNum++;

      nextTick(() => {
        if( dataMap.stockList1['page']['list'].length ){
          const row = dataMap.stockList1['page']['list'][0]
          rowParams['pageNum'] = 1
          rowParams['stockCode'] = row.stockCode
          rowParams['surveyDate'] = row.surveyDate
          pagin.value!.changeCurPage(1)
        }else{
          rowParams['stockCode'] = ''
          dataMap.stockList2 = {}
        }
      })
    }
    fetchData()

    const getStockInfo = (info:any) => {
      dataMap.stockInfo = info
    }

    //表格行点击
    let rowParams = {
      pageNum: 1,
      pageSize: 10
    }
    const handleRowClick = async (row: any,column:any) => {
      if( column !== undefined ){
        dataMap.tabActive = row.index
      }
      
      if( row.stockCode ){
        rowParams['pageNum'] = 1
        rowParams['stockCode'] = row.stockCode
        rowParams['surveyDate'] = row.surveyDate
        pagin.value!.changeCurPage(1)
        return false
      }
      if( row.pageNum ){
        rowParams['pageNum'] = row.pageNum
      }

      if( !rowParams['stockCode'] ){
        return false
      }
      rowParams['isPointOrg'] = dataMap.formInline.isPointOrg.length ? 1 : 0;
      let data2 = await getSurveyStockDetailOrgs(rowParams)
      dataMap.stockList2 = (data2 as any).data

      
    }

    
    //搜索按钮
    const onSearch = async (_parms:any) => {
      dataMap.tabActive = 0
      //自定义时间
      if( dataMap.showTimeSelect ){
        dataMap.formInline['startTime'] = window.moment(dataMap.selectTime[0]).format('x')
        dataMap.formInline['endTime'] = window.moment(dataMap.selectTime[1]).format('x')
      }
      fetchData()

      compareRef.value!.setParams(dataMap.formInline)


      console.log(dataMap.formInline)
    }

    //清空
    const onClearForm = async () => {
      delete dataMap.formInline['startTime'];
      delete dataMap.formInline['endTime'];
      dataMap.formInline['orgName'] = ''
      dataMap.formInline['isPointOrg'] = []
      
      swichItem(0)
      fetchData()
    }



     const blurHandler2 = (res:any) => {
      setTimeout(()=>{
        dataMap.searchList2 = []
      },200)
    }
    let timer2:any = null;
    
    const fetchSearchData2 = () => {
      if( timer2 ){
        clearTimeout(timer2)
      }
      timer2 = setTimeout(async ()=>{
        let data = await getSurveyOrg({ keyword: dataMap.formInline['orgName'], limit: 20})
        let aaa = (data as any).data;
        dataMap.searchList2 = aaa;
      },500)
      
    }
    const selectedSearch2 = (item:any) => {
      dataMap.formInline['orgName'] = item.name
    }

    const rowClassName222 = ({row, rowIndex}:any) => {
      row.index = rowIndex
      if (rowIndex === dataMap.tabActive) {
        return 'row-active'
      } 
      return ''
    }

    // 跳转调研机构列表
    const toResearchList = (row:any, column:any) => {
      const { formInline, dstxActive, selectTime } = dataMap;
      console.log(selectTime, 'selectTimeselectTimeselectTime');
      const startTime = selectTime[0];
      const endTime = selectTime[1];
      const isOrg = row.isFocusOrg ? 1 : 0;
      if(formInline['startTime']) {
        window.open(`/investment/research/list?isPointOrg=${isOrg}&timeLevel=${dstxActive}&orgName=${row.orgName}&beginDate=${startTime}&endDate=${endTime}`)
      }else{
        window.open(`/investment/research/list?isPointOrg=${isOrg}&timeLevel=${dstxActive}&orgName=${row.orgName}`)
      }
    }

    return {
      ...toRefs(dataMap),
      pagin,
      compareRef,
      getStockInfo,
      swichItem,
      handleRowClick,
      onSearch,
      onClearForm,
      dicts,
      blurHandler2,
      fetchSearchData2,
      selectedSearch2,
      rowClassName222,
      toResearchList
    };
  }
})
