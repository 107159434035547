


import { reactive, defineComponent, toRefs, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  props: {
    // eslint-disable-next-line vue/require-default-prop
    mode: {
      type: Number
    },
    title: {
      type: String
    },
    viceTitle: {
      type: String
    },
    sixIcon: {
      type: Boolean
    },
    titleData: {
      type: Object
    },
    type: {
      type: String,
      default: 'month'
    },
    code: {
      type: String,
      default: ''
    },
    moreUrl: {
      type: String,
      default: ''
    },
    state: {
      type: Number,
      default: ''
    }
  },
  setup(props, context) {
    const router = useRouter()
    const route = useRoute()
    const stockCode = route.query.code
    const value = ref(0)
    let options:any = []
    if( props.type === 'year' ){
      options = [
        {
          value: 0,
          label: '最近3年',
          timeLevel: '3y'
        },
        {
          value: 1,
          label: '最近5年',
          timeLevel: '5y'
        },
        {
          value: 2,
          label: '最近10年',
          timeLevel: '10y'
        }
      ]
    }else{
      options = [
        {
          value: 0,
          label: '最近1月',
          timeLevel: '1m'
        },
        {
          value: 1,
          label: '最近3月',
          timeLevel: '3m'
        },
        {
          value: 2,
          label: '最近6月',
          timeLevel: '6m'
        },
        {
          value: 3,
          label: '最近1年',
          timeLevel: '1y'
        }
      ]
    }

    
    const changeHandle = (val:any) => {
      context.emit('change', options[val])
    }
    context.emit('change', options[0])


    const lookMore = ()=>{
      // router.push({ 
      //   path: props.moreUrl, 
      //   query: {
      //     code: stockCode
      //   } 
      // })
      window['$props'].push({
        path: `/investment${props.moreUrl}`,
        query:{
          code: stockCode
        }
      })
    }

    const changeTips = ()=>{
      context.emit('changeTips')
    }

    return {
      value,
      options,
      changeHandle,
      lookMore,
      changeTips
    }
  }
})
