<template>
  <div
    :id="id"
    :class="className"
    :style="{height: height, width: width}"
  />
</template>

<script lang="ts">
import {
  defineComponent,
  onActivated,
  onBeforeUnmount,
  onDeactivated,
  onMounted,
  nextTick,
  watch
} from 'vue'
import resize from '@/components/charts/mixins/resize'
import { init, EChartsOption } from 'echarts'
import {algo} from "crypto-ts";
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '250px',
      required: true
    },
    chartData: {
      type: Object,
      default: {}
    },
    selectedTime: {
      type: String || Number,
      default: ''
    },
  },
  setup(props) {
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()

    watch(props, (newName, oldName) => {
      initChart()
    });

    const initChart = () => {
      const xAxisList = Array.from(props.chartData.dataList, ({ date }) => date);
      const yAxisList = Array.from(props.chartData.dataList, ({ szzs }) => szzs);
      const rqye = Array.from(props.chartData.dataList, ({ rqye }) => rqye);
      const rzye = Array.from(props.chartData.dataList, ({ rzye }) => rzye);
      const rqyeMax = Math.ceil(Math.max(...rqye));
      const rzyeMax = Math.ceil(Math.max(...rzye));
      const multiple = Math.round(rzyeMax / rqyeMax)
      // const rzyeList = Array.from(props.chartData.dataList, ({ rzye }) => rzye / multiple);
      const MaxArrpLeft = Math.ceil(Math.max(...yAxisList));
      const MinArrpLeft = Math.floor(Math.min(...yAxisList));
      const MaxArrpRzye = Math.ceil(Math.max(...rzye));
      const MinArrpRzye = Math.floor(Math.min(...rzye));
      const MaxArrpRqye = Math.ceil(Math.max(...rqye));
      const MinArrpRqye = Math.floor(Math.min(...rqye));
      const barChart = init(document.getElementById(props.id) as HTMLDivElement)
      barChart.setOption({
        grid: {
          top: '30px',
          left: '5px',
          right: '0%',
          bottom: '40px',
          containLabel: true
        },
        legend: {
          icon: 'line',
          data: ['融资余额', '融券余额', '上证指数'],
          itemWidth: 8,  // 设置宽度
          itemHeight: 3, // 设置高度
          x: 'center',
          bottom: '0',
          itemGap: 30,
          textStyle: {
            fontSize: 12,// 字体大小
            color:'#666666',
          }
        },
        tooltip: {
          trigger: 'axis',
          formatter:function(param:any){
            // console.log(param, 'params-----')
            let text = ''
            for (let i = 0; i < param.length; i++) {
              const item = param[i]
              text += `<div>
                        <span style='width:8px;height:8px;display:inline-block;border-radius:50%;margin-right:3px;background: ${item.color}'></span>
                        <span style='display:inline-block;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;line-height: 17px;'>${item.seriesName}：</span>
                        <span style='display:inline-block;font-size: 14px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #333333;line-height: 20px;'>${item.seriesName !== '上证指数' ? Math.round(item.data / 100000000) + '亿' : item.data}</span>
                    </div>`
            }
            return text
          }
        },
        xAxis: {
          type: 'category',
          data: xAxisList,
          axisLabel: { rotate: 45, fontSize: 10 }
        },
        yAxis: [
          {
            type: 'value',
            splitLine: { show: false },
            position: 'left',
            name: '价格指数',
            max: MaxArrpLeft + 50,
            min: MinArrpLeft - 50,
            axisPointer: {
              type: 'none',
              label: { show: false }
            },
            axisLabel: {
              show: true,
              align: 'right',
            }
          },
          {
            type: 'value',
            splitLine: { show: false },
            max: MaxArrpRzye + 10000000000,
            min: MinArrpRzye - 10000000000,
            axisLabel: {
              show: false,
            }
          },
          // {
          //   type: 'value',
          //   splitLine: { show: true, lineStyle: { type: 'dashed' } },
          //   max: MaxArrpRqye,
          //   min: MinArrpRqye,
          //   axisLabel: {
          //     show: false,
          //   }
          // }
        ],
        series: [
          {
            name: '上证指数',
            type: 'line',
            yAxisIndex: 0,
            itemStyle: {
              normal: {
                color: '#AEAEAE',
              }
            },
            data: yAxisList
          },
          {
            type: 'bar',
            name: '融资余额',
            yAxisIndex: 1,
            itemStyle: {
            normal: {
              label: {
                show: true,
                position: 'top',
                fontSize: 8,
                formatter: (params: any) => {
                  return Math.round(params.value /100000000) + '亿'
                }
              },
              color: '#3B7FEE',
              }
            },
            barWidth: 20, // 柱子宽度
            data: rzye
            // data: rzyeList
          },
          // {
          //   name: '融券余额',
          //   type: 'bar',
          //   yAxisIndex: 1,
          //   itemStyle: {
          //     normal: {
          //       color: '#FE9625',
          //     }
          //   },
          //   barWidth: 10, // 柱子宽度
          //   data: rqye
          // },
          ]
      } as EChartsOption)
      chart.value = barChart
    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {
    }
  }
})
</script>
