
import {
  defineComponent,
  toRefs,
  reactive
} from 'vue'
import { useRoute } from "vue-router";

// import CPagination from "@/components/pagination/Index.vue"
import CustomTab from '../../components/CustomTab.vue'
import { getPeer } from '@/apis/northboundFunds'
export default defineComponent({
  components: {
    // CPagination,
    CustomTab
  },
  setup(props, context) {
    const route = useRoute();
    const dataMap = reactive({
      loading: false,
      timeSelected: {},
      stockList: [
        
      ],
    })

    let params = { 
      stockCode: route.query.code,//'000913.SZ',
      timeUnit: '',
      orderBy: 'addHold',
      order: 'DESC'
    }

    const fetchData = async (value:any) => {
      console.log('ccccc', value)
      if( value.value ){
        dataMap.timeSelected = value
        params['timeUnit'] = value.value
      }

      dataMap.loading = true

      // let list = await getPeer(params)
      // const newList = (list as any).data
      // const filterStock = newList.filter((item:any) => item.stockCode === route.query.code)
      // list = filterStock.concat(newList)
      // dataMap.stockList = (list as any);
      const list = await getPeer(params)
      dataMap.stockList = (list as any).data;
      dataMap.loading = false
    }

    const sortChange = (column: any) => {
      let isAsc = column.order === "ascending" ? 'ASC' : 'DESC';
      params['orderBy'] = column["prop"]
      params['order'] = isAsc
      fetchData({})
    };
    
    const addClass = (column:any) => { 
      if( column.column.property === params.orderBy ){
        return 'sort-bg'
      }
    }
    
    return {
      ...toRefs(dataMap),
      fetchData,
      sortChange,
      addClass
    }
  }
})
