<template>
  <div class="table_box">
    <div class="top_nav_box">
      <ul v-for="item in titleList" :key="item.id">
        <li>{{ item.name }}</li>
      </ul>
    </div>
<!--    {{titleList}}-->
    <div :class="index % 2 == 0 ? 'item_box' : 'active_item_box'" v-for="(item, index) in tableList" :key="item.id">
      <ul>
        <li>{{ item.name }}</li>
        <li>{{ (item.holdMarketCap / 10000).toFixed(2) }}</li>
        <li :style="item.dayAddMarketCap > 0 ? 'color: #F54F4C' : 'color: #5CBC7C'">{{ (item.dayAddMarketCap / 10000).toFixed(2) }}</li>
        <li>{{ (item.weekAddMarketCap / 10000).toFixed(2) }}</li>
        <li>{{ (item.monthAddMarketCap / 10000).toFixed(2) }}</li>
        {{

        }}
        <li><trend-chart :id="`trend-${index}`" :chart-data="item.trendValues" :yMax="Math.max(...sumList)" :yMin="Math.min(...sumList)" /></li>
      </ul>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, toRefs, watch } from 'vue'
import trendChart from './trend-chart.vue'
export default defineComponent({
  props: {
    tableList: {
      type: Array,
      default: []
    }
  },
  components: {
    trendChart
  },
  setup(props) {
    const dataMap = reactive({
      titleList: [
        {
          id: 1,
          name: '行业名称'
        },
        {
          id: 2,
          name: '持股市值（亿元）'
        },
        {
          id: 3,
          name: '日净流向（亿元）'
        },
        {
          id: 4,
          name: '周净买入（亿元）'
        },
        {
          id: 5,
          name: '月净买入（亿元）'
        },
        {
          id: 6,
          name: '趋势图'
        }
      ],
      sumList: 0
    })

    let sum:any = []
    watch(props, (newName, oldName) => {
      props.tableList.map((item:any)=>{
        sum.push(...item.trendValues)
      })
      dataMap.sumList = sum
    });
    return {
      ...toRefs(dataMap)
    }
  }
})

</script>
<style scoped lang="scss">
.table_box {
  // width: 926px;
  margin: 38px auto 38px auto;
  border: 1px solid #E8E8E8;
  > .top_nav_box {
    width: 100%;
    height: 47px;
    background: #F2F6FF;
    display: flex;
    align-items: center;
    > ul {
      font-size: 16px;
      height: 100%;
      border-right: 1px solid #E8E8E8;
      &:first-child {
        // width: 147px;
        width: 14%;
      }
      &:nth-child(2) {
        // width: 183px;
        width: 19%;
      }
      &:nth-child(3) {
        // width: 151px;
        width: 17%;
      }
      &:nth-child(4) {
        // width: 151px;
        width: 17%;
      }
      &:nth-child(5) {
        // width: 151px;
        width: 17%;
      }
      &:last-child {
        // width: 138px;
        width: 15%;
        border-right: none;
      }
      > li {
        text-align: center;
        margin-top: 13px;
      }
    }
  }
  > .item_box {
    width: 100%;
    //height: 35px;
    background: #FFFFFF;
    > ul {
      font-size: 16px;
      display: flex;
      align-items: center;
      height: 100%;
      > li {
        border-right: 1px solid #E8E8E8;
        padding-top: 8px;
        &:first-child {
          // width: 147px;
          width: 14%;
          text-align: center;
        }
        &:nth-child(2) {
          // width: 183px;
          width: 19%;
          text-align: right;
          padding-right: 37px;
        }
        &:nth-child(3) {
          // width: 151px;
          width: 17%;
          text-align: right;
          padding-right: 28px;
        }
        &:nth-child(4) {
          // width: 151px;
          width: 17%;
          text-align: right;
          padding-right: 28px;
        }
        &:nth-child(5) {
          // width: 151px;
          width: 17%;
          text-align: right;
          padding-right: 28px;
        }
        &:last-child {
          // width: 138px;
          width: 15%;
          border-right: none;
        }
      }
    }
  }
  >  .active_item_box {
    width: 100%;
    //height: 35px;
    background: #F4F7FA;
    > ul {
      font-size: 16px;
      display: flex;
      align-items: center;
      height: 100%;
      > li {
        border-right: 1px solid #E8E8E8;
        padding-top: 8px;
        &:first-child {
          // width: 147px;
          width: 14%;
          text-align: center;
        }
        &:nth-child(2) {
          // width: 183px;
          width: 19%;
          text-align: right;
          padding-right: 37px;
        }
        &:nth-child(3) {
          // width: 151px;
          width: 17%;
          text-align: right;
          padding-right: 28px;
        }
        &:nth-child(4) {
          // width: 151px;
          width: 17%;
          text-align: right;
          padding-right: 28px;
        }
        &:nth-child(5) {
          // width: 151px;
          width: 17%;
          text-align: right;
          padding-right: 28px;
        }
        &:last-child {
          // width: 138px;
          width: 15%;
          border-right: none;
        }
      }
    }
  }
}
</style>
