<template>
  <!--  行业排行表格-->
  <div class="table_box">
    <el-table
      border
      v-loading='loading'
      @row-click='goDetail'
      :data="marginTableData.list || []"
      style="width: 100%"
      :cell-class-name='addClass'
      :default-sort ="{prop:'rzjmr',order:'descending'}"
      :header-cell-style="{background:'#F2F6FF',color:'#333333'}"
      @sort-change="sortChange">
      <el-table-column fixed prop="rankingNo" label="排名" width="68" align="center">
        <template #default='{row}'>
          {{ row.rankingNo }}<br />
          <span v-if="row.rankingChg > 0" v-dClass="row.rankingChg" style="display: flex; align-items: center; justify-content: center">
            {{ row.rankingChg }}
            <i class="el-icon-top" />
          </span>
          <span v-if="row.rankingChg < 0" v-dClass="row.rankingChg" style="display: flex; align-items: center; justify-content: center">
            {{ row.rankingChg }}
            <i class="el-icon-bottom" />
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="名称" width="100" align="center" />
      <el-table-column prop="incr" :label="`${incrLabel}涨幅`" width="91" align="center">
        <template #default='{row}'><span v-dClass='row.incr'>{{ row.incr }}%</span></template>
      </el-table-column>
      <el-table-column prop="stockNum" label="相关个股" width="95" align="center" />
      <el-table-column label="融资" align="center">
        <el-table-column prop="rzye" label="余额" width="105" align="center" sortable="custom">
          <template #default='{row}'>{{ amount(row.rzye) }}</template>
        </el-table-column>
        <el-table-column prop="rzyezb" label="余额占流通市值比" width="113" align="center" sortable="custom">
          <template #default='{row}'>{{ row.rzyezb }}%</template>
        </el-table-column>
        <el-table-column prop="rzmr" label="买入额(元)" width="106" align="center" sortable="custom">
          <template #default='{row}'>{{ amount(row.rzmr) }}</template>
        </el-table-column>
        <el-table-column prop="rzjmr" label="净买入(元)" width="105" align="center" sortable="custom">
          <template #default='{row}'>{{ amount(row.rzjmr) }}</template>
        </el-table-column>
        <el-table-column prop="rzjmrzb" label="净买入占流通市值比" width="110" align="center" sortable="custom">
          <template #default='{row}'>{{ row.rzjmr > 0 ? row.rzjmrzb.toFixed(2) + '%' : row.rzjmr === 0 ? '0.00%' : '-' }}</template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="融券" align="center">
        <el-table-column prop="rqye" label="余额(元)" width="105" align="center" sortable="custom">
          <template #default='{row}'>{{ amount(row.rqye) }}</template>
        </el-table-column>
        <el-table-column prop="rqmce" label="卖出额" width="105" align="center" sortable="custom">
          <template #default='{row}'>{{ amount(row.rqmce) }}</template>
        </el-table-column>
        <el-table-column prop="rqjme" label="净卖额" width="115" align="center" sortable="custom">
          <template #default='{row}'>{{ amount(row.rqjme) }}</template>
        </el-table-column>
      </el-table-column>
      <el-table-column prop="rzrqye" label="融资融券余额(元)" min-width="95" align="center" sortable="custom">
        <template #default='{row}'>{{ amount(row.rzrqye) }}</template>
      </el-table-column>
      <el-table-column prop="rzjmrzbMaxName" label="融资净买入占流通市值最大比" width="137" align="center" />
      <el-table-column prop="rqjmczbMaxName" label="融券净卖出占流通股最大比" width="111" align="center" />
    </el-table>
    <paging-component ref='paging' :showPageSizes='true' :pageSize='10'  :pageData='marginTableData' @fetchData='fetchData' />
  </div>
</template>
<script lang="ts">
import pagingComponent from '@/components/pagination/Index.vue'
import { defineComponent, reactive, toRefs } from 'vue'
import { getMargin } from '@/apis/margin-trading'
export default defineComponent({
  components: {
    pagingComponent
  },
  props:{
    incrLabel: {
      type: String,
      default: '1日'
    },
  },
  setup(props) {
    const dataMap = reactive({
      loading: true,
      marginTableData: {}
    })
    let params = {
      timeLevel: '',
      boardType: 'swl1',
      parentBoardName: '',
      pageNum: '1',
      pageSize: '10',
      sortField: 'rzjmr'
    }
    const fetchData = async (value:any, item: any) => {
      if( value.value ){
        params['timeLevel'] = value.value
      }
      if( value.pageNum ){
        params['pageNum'] = value.pageNum
      }
      if( value.pageSize ){
        params['pageSize'] = value.pageSize
      }
      if( item && item.boardType ){
        params['boardType'] = item.boardType
      }
      if( item && item.timeLevel ){
        params['timeLevel'] = item.timeLevel
      }
      if( params.boardType ){
        dataMap.loading = true
        const marginList = await getMargin(params)
        dataMap.marginTableData = (marginList as any).data;
        dataMap.loading = false
      }
    }
    const amount = (value: any, fixed = 2, unit = '元') => {
      if (unit == '万') {
        value = value * 10000
      } else if (unit == '亿') {
        value = value * 100000000
      }
      if (Math.abs(value / 100000000) >= 1) {
        return (value / 100000000).toFixed(fixed) + '亿'
      }
      if (Math.abs(value / 10_000) >= 1) {
        return (value / 10000).toFixed(fixed) + '万'
      }
      return value
    }
    const sortChange = (column: any) => {
      let isAsc = column.order === "ascending" ? 'asc' : 'desc';
      params['sortField'] = column["prop"]
      params['sort'] = isAsc
      fetchData({}, {})
    };
    const goDetail = (item: any, index: any) => {
      console.log(item, 'item', index.label, 'index')
      if (index.label === '名称') {
        const path = '/marginTrading/industry'
        window.open( `/investment${path}?code=${item.name}&boardType=${item.type}`)
      } else if (index.label === '融资净买入占流通市值最大比') {
        const path = '/marginTrading/shares'
        window.open( `/investment${path}?name=${item.rzjmrzbMaxName}&code=${item.rzjmrzbMaxCode}`)
      } else if (index.label === '融券净卖出占流通股最大比') {
        const path = '/marginTrading/shares'
        window.open( `/investment${path}?name=${item.rqjmczbMaxName}&code=${item.rqjmczbMaxCode}`)
      }
    };
    const addClass = (column:any) => {
      if( column.column.property === params.sortField ){
        return 'sort-bg'
      }
    }
    return {
      ...toRefs(dataMap),
      fetchData,
      amount,
      sortChange,
      goDetail,
      addClass
    }
  }
})
</script>
<style scoped lang="scss">
.table_box {
  margin-top: 20px;
  padding-bottom: 40px;
  margin-left: 30px;
}
</style>
