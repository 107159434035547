<template>
<!--  个股统计表格-->
  <div class="table_box">
    <div class="search_box">
      <shares-table @search="getSearchParams" />
    </div>
    <el-table
      border
      v-loading='loading'
      @row-click='goDetail'
      :data="tableData.list || []"
      style="width: 100%"
      :header-cell-style="headerMethod">
      <el-table-column prop="date" label="日期" width="103" align="center" />
      <el-table-column prop="stockName" label="个股名称" width="105" align="center" />
      <el-table-column prop="incr" label="当日涨幅" width="95" align="center">
        <template #default='{row}'><div v-dClass='row.incr'>{{ row.incr }}%</div></template>
      </el-table-column>
      <el-table-column prop="rzye" label="融资余额" width="105" align="center">
        <template #default='{row}'>{{ amount(row.rzye) }}</template>
      </el-table-column>
      <el-table-column prop="rzyezb" label="融资余额占流通市值比" width="112" align="center">
        <template #default='{row}'>{{ row.rzyezb.toFixed(2) }}%</template>
      </el-table-column>
      <el-table-column prop="rzjmr" label="融资净买入" width="106" align="center">
        <template #default='{row}'>{{ amount(row.rzjmr) }}</template>
      </el-table-column>
      <el-table-column prop="rzjmrzb" label="融资净买入占流通市值比" width="104" align="center">
        <template #default='{row}'>{{ row.rzjmr > 0 ? row.rzjmrzb.toFixed(2) + '%' : row.rzjmr === 0 ? '0.00%' : '-' }}</template>
      </el-table-column>
      <el-table-column prop="rzrqye" label="融资融券余额" width="109" align="center">
        <template #default='{row}'>{{ amount(row.rzrqye) }}</template>
      </el-table-column>
      <el-table-column prop="vol" label="成交量(股)" width="105" align="center">
        <template #default='{row}'>{{ amount(row.vol) }}</template>
      </el-table-column>
      <el-table-column prop="amt" label="成交额(元)" width="105" align="center">
        <template #default='{row}'>{{ amount(row.amt) }}</template>
      </el-table-column>
      <el-table-column prop="turnover" label="换手率" width="95" align="center">
        <template #default='{row}'>{{ row.turnover ? row.turnover.toFixed(2) + '%' : '-' }}</template>
      </el-table-column>
      <el-table-column prop="boardName" label="所属行业" width="95" align="center" />
      <el-table-column prop="stockPeTTM" label="个股/行业PE-TTM" width="60" align="center">
        <template #default='{row}'>{{ row.stockPeTTM ? row.stockPeTTM.toFixed(2) : '-' }}</template>
      </el-table-column>
      <el-table-column prop="boardPeTTM" label="个股/行业PE-TTM" width="60" align="center" />
      <el-table-column prop="valuation" label="估值水位" width="77" align="center" />
      <el-table-column prop="marketCap" label="总市值" width="88" align="center">
        <template #default='{row}'>{{ amount(row.marketCap) }}</template>
      </el-table-column>
    </el-table>
    <paging-component ref='paging' :showPageSizes='true' :pageSize='10'  :pageData='tableData' @fetchData='fetchData' />
  </div>
</template>
<script lang="ts">
import pagingComponent from '@/components/pagination/Index.vue'
import { defineComponent, reactive, toRefs } from 'vue'
import { getStockRank } from '@/apis/margin-trading'
import sharesTable from './shares-table-form.vue'
export default defineComponent({
  props: {
    tableList: {
      type: Array,
      default: []
    }
  },
  components: {
    pagingComponent,
    sharesTable
  },
  setup(props) {
    const dataMap = reactive({
      loading: true,
      tableData: {},
      pageItem: {},
      navItem: {},
      tabItem: {}
    })
    let params = {
      pageNum: '1',
      pageSize: '10',
      stockMarket: 'ALL',
      timeUnit: 'DAYS',
      num: 1,
    }
    const fetchData = async (value:any, sharesTimeItem: any, sharesNavItem: any) => {
      dataMap.pageItem = value
      dataMap.navItem = sharesNavItem
      dataMap.tabItem = sharesTimeItem
      if( value.pageNum ){
        params['pageNum'] = value.pageNum
      }
      if( value.pageSize ){
        params['pageSize'] = value.pageSize
      }
      if(sharesNavItem){
        params['stockMarket'] = sharesNavItem.code
      }
      if(sharesTimeItem){
        params['timeUnit'] = sharesTimeItem.timeUnit
        params['num'] = sharesTimeItem.num
      }
      dataMap.loading = true
      const statisticsList = await getStockRank(params)
      dataMap.tableData = (statisticsList as any).data;
      dataMap.loading = false
    }
    const headerMethod = (item: any) => {
      const { row, columnIndex } = item
      if (row[0].level == 1) {
        row[11].colSpan = 0
        row[12].colSpan = 2
        if (columnIndex === 13) {
          return { display: 'none' }
        }
      }
    }
    const amount = (value: any, fixed = 2, unit = '元') => {
      if (unit == '万') {
        value = value * 10000
      } else if (unit == '亿') {
        value = value * 100000000
      }
      if (Math.abs(value / 100000000) >= 1) {
        return (value / 100000000).toFixed(fixed) + '亿'
      }
      if (Math.abs(value / 10_000) >= 1) {
        return (value / 10000).toFixed(fixed) + '万'
      }
      return value
    }

    const getSearchParams = (val:any) => {
      params['conditions'] = val
      fetchData(dataMap.pageItem, dataMap.tabItem, dataMap.navItem)
    }

    const goDetail = (item: any, index: any) => {
      console.log(item, 'item', index.label, 'index')
      if (index.label === '个股名称') {
        const path = '/marginTrading/shares'
        window.open( `/investment${path}?name=${item.stockName}&code=${item.stockCode}`)
      } else if (index.label === '所属行业') {
        const path = '/marginTrading/industry'
        window.open( `/investment${path}?code=${item.boardName}&boardType=swl2`)
      }
    };
    return {
      ...toRefs(dataMap),
      fetchData,
      headerMethod,
      amount,
      goDetail,
      getSearchParams
    }
  }
})
</script>
<style scoped lang="scss">
.table_box {
  margin-top: 20px;
  padding-bottom: 40px;
  margin-left: 30px;
  > .search_box {
    margin-bottom: 20px;
  }
}
</style>
