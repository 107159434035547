
import { reactive, defineComponent, toRefs } from 'vue'
import dicts from '@/utils/dicts'
export default defineComponent({
  props:{
    title:{
      type: String
    },
    subTitle:{
      type: String
    }
  },
  setup() {
    const dataMap = reactive({

    })

    const goHome = () => {
      window.open(`/investment/northboundFunds/index`)
    }
    return { ...toRefs(dataMap), goHome }
  }
})
