<template>
  <div class="layout " >
    <el-container>
      <el-header>
        <search-input :path="'/home/detail'" icon-position="prefix" holder="请输入个股名称、股票代码" :is-stock-detail="true" rightText="详情"/>
      </el-header>
      <el-container class="c-el-container">
        <el-aside width="82" >
          <el-scrollbar>
            <ul>
              <li v-for="(item,idx) in asideList" :key="idx">
                 <router-link :to="item['detailUrl'] + `?code=${route.query.stockCode || route.query.code}&name=${baseDetail.stockName}&stockCode=${route.query.stockCode || route.query.code}&stockName=${baseDetail.stockName}`" target="_blank">
                  <img :src='require(`@/assets/images/stock-detail/${item["icon"]}.png`)' alt="" class="default">
                  <img :src='require(`@/assets/images/stock-detail/${item["icon"]}-active.png`)' alt="" class="active">
                  <div>{{item["text"]}}</div>
                 </router-link>
              </li>
            </ul>
          </el-scrollbar>
        </el-aside>
        <el-main>
          <el-scrollbar>
            <div class="module">
              <div class="stock-info" v-if="baseDetail.stockName">
                <div class="name">{{baseDetail.stockName}}（{{baseDetail.stockCode.split('.')[0]}}）</div>
                <div class="num">
                  <div class="price" v-dClass='baseDetail.incr'>{{Number(baseDetail.price)?.toFixed(2)}}</div>
                  <div class="avg" v-dClass='baseDetail.incr'>{{baseDetail.incr > 0 ? '+' : ''}}{{baseDetail.chg}}/{{baseDetail.incr > 0 ? '+' : ''}}{{baseDetail.incr}}%</div>
                </div>
                <div class="info">
                  <ul>
                    <li>
                      <span class="des">换 手：</span>
                      <span class="ans">{{baseDetail.turnover}}%</span>
                    </li>
                    <li>
                      <span class="des">总 手：</span>
                      <span class="as">{{$stock.mktcap(baseDetail.vol / 100, 10000, 2, "万")}}</span>
                    </li>
                    <li>
                      <span class="des">金 额：</span>
                      <span class="ans">{{$stock.mktcap(baseDetail.amt , 100000000, 2, "亿")}}</span>
                    </li>
                  </ul>
                </div>
  <!--              <div class="btn">加自选</div>-->
              </div>
              <div class="tit">基本概况</div>
              <el-table
                border
                :data="[baseDetail]"
              >
                <el-table-column prop="intro" label="公司简介及主营业务">
                  <template #default="{row}">
                    <el-tooltip
                      :content="row.intro"
                      trigger="hover"
                      effect="light"
                      popper-class='c-popover-detail-list'
                    >
                      <div class="intro">{{row.intro}}</div>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column prop="concept" label="所属概念" width="340" >
                  <template #default="{row}">
                    <el-tooltip
                      :content="row.concept"
                      trigger="hover"
                      effect="light"
                      popper-class="c-popover-detail-list"
                    >
                      <div class="concept">{{row.concept}}</div>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column prop="area" label="地域" width="100" />
                <el-table-column prop="industry" label="所属行业" width="200">
                  <template #default="{row}">
                    <div v-if="row.industry">申万一级：<span class="industry" @click="goAllA(row.industry.split(';')[0], 'swl1')">{{row.industry.split(';')[0]}}</span></div>
                    <div v-if="row.industry">申万二级：<span class="industry" @click="goAllA(row.industry.split(';')[1], 'swl2')">{{row.industry.split(';')[1]}}</span></div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="module">
              <div class="tit">近期大事件</div>
              <el-table
                border
                :data="companyEvents"
              >
                <el-table-column prop="date" label="大事件公告时间" />
                <el-table-column prop="eventType" label="大事件名称" >
                  <template #default="{ row }">
                    <div class="tag">{{ row.eventType }}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="content" label="大事件内容" />
              </el-table>
            </div>
            <div class="module">
              <div class="tit">财务数据</div>
              <el-table
                border
                :data="leastList"
              >
                <el-table-column prop="date" label="成长能力指标">
                  <template #default='{row}'>
                    <div class="reportDateDesc">
                      <span>{{row.date}}</span>
                      <span>{{row.reportDate.slice(0,4)}}{{ row.reportDateDesc }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="totalOperateIncome" label="总营收">
                  <template #default='{row}'>
                    {{row.totalOperateIncome}}
<!--                    {{$stock.mktcap(row.totalOperateIncome, 100000000, 2, "亿")}}-->
                  </template>
                </el-table-column>
                <el-table-column prop="netProfit" label="归母净利润">
                  <template #default='{row}'>
                    {{row.netProfit}}
<!--                    {{$stock.mktcap(row.netProfit, 100000000, 2, "亿")}}-->

                  </template>
                </el-table-column>
                <el-table-column prop="incomeYoy" label="营业总收入同比增长(%)" />
                <el-table-column prop="profitYoy" label="归属净利润同比增长(%)" />
                <el-table-column prop="incomeMom" label="营业总收入环比增长(%)" />
                <el-table-column prop="profitMom" label="归属净利润环比增长(%)" />
                <el-table-column prop="roejq" label="净资产收益率(%)" />
                <el-table-column prop="xsmll" label="销售毛利率(%)" />
              </el-table>
            </div>
            <div class="module">
              <div class="tit">常用指标</div>
              <el-table
                border
                :data="[stockIndicators]"
              >
                <el-table-column prop="totalShares" label="总股本(股)">
                  <template #default='{row}'>
                    {{$stock.mktcap(row.totalShares, 100000000, 2, "亿")}}
                  </template>
                </el-table-column>
                <el-table-column prop="totalMarketCap" label="总市值(元)">
                  <template #default='{row}'>
                    {{$stock.mktcap(row.totalMarketCap, 100000000, 2, "亿")}}
                  </template>
                </el-table-column>
                <el-table-column prop="circulateMarketCap" label="流通市值(元)">
                  <template #default='{row}'>
                    {{$stock.mktcap(row.circulateMarketCap, 100000000, 2, "亿")}}
                  </template>
                </el-table-column>
                <el-table-column prop="peTtm" label="市盈率(TTM)" />
                <el-table-column prop="pbMrq" label="市净率" />
                <el-table-column prop="pcfOcfTtm" label="市现率" />
                <el-table-column prop="psTtm" label="市销率" />
               </el-table>
            </div>
            <div class="module">
              <div class="tit">北向资金</div>
              <Bxzj />
            </div>
            <div class="module">
              <div class="tit">融资融券</div>
              <Rzrj />
            </div>
            <div class="module">
              <div class="tit">估值分析</div>
              <Gzfx />
            </div>
            <div class="module">
              <div class="tit">资讯</div>
              <el-tabs v-model="infoActiveName">
                <el-tab-pane label="新闻" >
                  <div class="news-box">
                    <div class="news-list" v-for="(item,idx) in newsList.list || []" :key="idx" @click="goNewsDetail(item)">
                      <div class="news-tit">{{baseDetail.stockName}}({{baseDetail.stockCode}})<span>：{{item.title}}</span></div>
                      <div class="news-content" v-if="!item.html" style="opacity: 0">{{getOssJsonContent(item)}}</div>
                      <div class="news-content" v-else v-html="item.html"></div>
                      <div class="news-time">
                        <div>发布时间：{{item.date}}</div>
                        <div>来源：{{item.source}}</div>
                      </div>
                    </div>
                  </div>
                  <el-pagination background layout="prev, pager, next" :total="newsList.totalSize || 0" :page-size="2" @current-change="handleNewsSizeChange" v-if="newsList.totalSize > 0" />
                  <el-empty description="暂无数据" v-else />
                </el-tab-pane>
                <el-tab-pane label="公告" >
                  <div class="news-box">
                    <div class="news-list" v-for="(item,idx) in noticeList.list || []" :key="idx" @click="goGbDetail(item)">
                      <div class="news-tit">{{baseDetail.stockName}}({{baseDetail.stockCode}})<span>：{{item.title}}</span></div>
                      <div class="news-content" v-if="!item.html" style="opacity: 0">{{getOssJsonContent(item)}}</div>
                      <div class="news-content" v-else v-html="item.html"></div>
                      <div class="news-time">
                        <div>发布时间：{{item.date}}</div>
  <!--                      <div>来源：{{item.source}}</div>-->
                      </div>
                    </div>
                  </div>
                  <el-pagination background layout="prev, pager, next" :total="noticeList.totalSize || 0" :page-size="2" @current-change="handleNoticeSizeChange" v-if="noticeList.totalSize > 0" />
                  <el-empty description="暂无数据" v-else />
                </el-tab-pane>
                <el-tab-pane label="研报" >
                  <div class="yb">
                    <div class="tit-master">机构评级（仅半年内）</div>
                    <div class="tit-assistant">最近180天共{{chartData.orgCount}}家机构给出评级。综合投资为{{chartData.ratingName}}</div>
                    <div class="content">
                      <div class="left">
                        <ChartA :chart-data="chartData" v-if="infoActiveName === '2'"/>
                      </div>
                      <div class="right">
                        <div class="list">
                          <div class="item" v-for="(item,idx) in ybList.list || []" :key="idx" @click="goYbDetail(item)">
                            <div class="t">
                              <div class="name">
                                <span class="stock-name">{{ item.orgName }}</span>
                                <span class="stock-teacher" :class="innerItem.isTop === 1 ? 'fxs' : ''"  v-for="(innerItem,innerIdx) in item.fxsList || []" :key="innerIdx">
                                  {{ innerItem.fxsName }}<img src="@/assets/images/new-fxs.png" v-if="innerItem.isTop === 1" />
                                </span>
                              </div>
                              <div class="time">{{ item.date.slice(5) }}</div>
                            </div>
                            <div class="des">{{ item.title }}</div>
                          </div>
                        </div>
                        <el-pagination background layout="prev, pager, next" :total="ybList.totalSize || 0" :page-size="3" @current-change="handleYbSizeChange" v-if="ybList.totalSize !== 0" />
                        <el-empty description="暂无数据" v-else />
                      </div>
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
            <div class="module">
              <div class="tit">综合诊股</div>
              <div class="btn btn-watch-detail" @click="goDetail">查看详情</div>
            </div>
          </el-scrollbar>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'
// import { Search } from '@element-plus/icons-vue'
import ChartA from './components/ChartA'
import Bxzj from './components/Bxzj'
import Rzrj from './components/Rzrj'
import Gzfx from './components/Gzfx'
import SearchInput from '@/components/search-input/Index.vue'
import { getBaseDetail, getCompanyEvents, getLeastList, getStockIndicators, getNewsList, getAnnouncementList, getReportOrgCountRanking, getStockDetailRating, getJsonContent } from '@/apis/home'
import { asideList } from './config'

export default {
  components:{
    SearchInput,
    ChartA,
    Bxzj,
    Rzrj,
    Gzfx
  },
  setup() {
    const route = useRoute()
    const stockCode = route.query.stockCode || route.query.code
    const dataMap = reactive({
      asideList,
      // asideActiveIdx: 0,
      infoActiveName: '0',
      baseDetail: {},
      companyEvents: [],
      leastList: [],
      stockIndicators: {},
      newsList: {},
      noticeList: {},
      ybList: {},
      chartData: [],
      boardDetail: {}
    })

    const fetchData = async () => {
      const baseDetail = await getBaseDetail({ stockCode })
      dataMap.baseDetail = baseDetail.data

      const companyEvents = await getCompanyEvents({ stockCode })
      dataMap.companyEvents = companyEvents.data

      const leastList = await getLeastList({ stockCode })
      dataMap.leastList = leastList.data || []

      const stockIndicators = await getStockIndicators({ stockCode })
      dataMap.stockIndicators = stockIndicators.data

      const chartData = await getReportOrgCountRanking({ stockCode })
      dataMap.chartData = chartData.data
    }
    fetchData()

    //新闻
    const handleNewsSizeChange = async (pageSize) => {
      const newsList = await getNewsList({ stockCode, pageNum: pageSize, pageSize: 2 })
      dataMap.newsList = newsList.data
    }
    handleNewsSizeChange(1)

    //公告
    const handleNoticeSizeChange = async (pageSize) => {
      console.log(pageSize)
      const noticeList = await getAnnouncementList({ stockCode, pageNum: pageSize, pageSize: 2 })
      dataMap.noticeList = noticeList.data
    }
    handleNoticeSizeChange(1)

    //研报列表
    const handleYbSizeChange = async (pageSize) => {
      const ybList = await getStockDetailRating({ stockCode, pageNum: pageSize, pageSize: 3 })
      if( ybList ){
        dataMap.ybList = ybList.data
      }
    }
    handleYbSizeChange(1)

    const getOssJsonContent = async (item) => {
      //本地请求会跨域 所以把oss链接直接显示出来
      if( window.location.host.indexOf('localhost') !== -1 ){
        item.html = item.content
      }else{
        if( item.content ){
          const content = await getJsonContent(item.content)
          console.log('获取oss')
          item.html = removeHtmlStyle(content.content || content.text)
        }
      }
    }
    handleYbSizeChange(1)

    const removeHtmlStyle =(html)=> {
      let relStyle = /style\s*?=\s*?([‘"])[\s\S]*?\1/g; //去除样式
      let relTag = /<.+?>/g; //去除标签
      let relClass = /class\s*?=\s*?([‘"])[\s\S]*?\1/g;// 清除类名
      let newHtml = "";
      if (html) {
        newHtml = html.replace(relStyle, "");
        newHtml = newHtml.replace(relTag, '');
        newHtml = newHtml.replace(relClass, '');
      }
      return newHtml;
    };

    const goYbDetail = (item) => {
      window.open('/investment/agency/report?reportId='+item['reportId'])
    }

    const goGbDetail = (item) => {
      localStorage.setItem('notice', JSON.stringify(item))
      window.open(`/investment/agency/reportgg`)
    }

    const goNewsDetail = (item) => {
      window.open(item['url'])
    }

    const goDetail = () =>{
      window.open(`/investment/diagnosis/index?code=${stockCode}`)
    }

    const goAllA = (name, type) => {
      window.open(`/page/data_center_all?industryName=${name}&boardType=${type}`)
    }

    return {
      // Search,
      ...toRefs(dataMap),
      route,
      goDetail,
      goYbDetail,
      goGbDetail,
      handleNewsSizeChange,
      handleNoticeSizeChange,
      handleYbSizeChange,
      getOssJsonContent,
      goNewsDetail,
      goAllA
    }
  }
}
</script>

<style scoped lang="scss">
.layout{
  background: #F7F7F7;
  font-family: PingFangSC-Medium, PingFang SC;
  .el-header{
    height: 80px!important;
    background: #fff;
    position: relative;
    padding-left: 36px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #DEDEDE;
    &::after{
      width: 4px;
      height: 30px;
      background: #1645FF;
      margin: auto;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
    }
    //.el-input{
    //  width: 670px;
    //  height: 50px;
    //  ::v-deep input{
    //    height: 50px;
    //  }
    //}
    ::v-deep .search{
      margin: 0;
      .el-input__prefix{
        left: 40px;
        color: #999999;
        font-size: 18px;
        top: 3px;
      }
      input{
        border-color: #E2E2E2;
        font-size: 14px;
        padding-left: 75px;
      }
    }
  }
  .c-el-container{
    height: calc(100vh - 90px);
  }
  .el-container{
    .el-aside{
      width: 82px;
      margin-top: 10px;
      height: calc(100vh - 90px);
      ul{
        li{
          height: 84px;
          cursor: pointer;
          font-weight: 500;
          a{
            display: block;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
          }
          &:hover{
            color: #1645FF;
            background: #E4EAF8;
            .default{
              display: none;
            }
            .active{
              display: block;
            }
          }
          img{
            width: 24px;
            margin-bottom: 5px;
          }
          .active{
            display: none;
          }
        }
      }
    }
    .el-main{
      padding: 0;
      .module{
        background: #FFFFFF;
        border-radius: 10px;
        margin: 10px 0;
        padding: 14px 25px 20px 24px;
        .tit{
          font-size: 16px;
          font-weight: 500;
          color: #333333;
          line-height: 22px;
          margin-bottom: 10px;
          padding-left: 10px;
          position: relative;
          &::after{
            width: 4px;
            height: 22px;
            background: #1645FF;
            margin: auto;
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
          }
        }
        .stock-info{
          display: flex;
          align-items: center;
          padding-bottom: 10px;
          border-bottom: 1px solid #F1F3F7;
          margin-bottom: 20px;
          .name{
            font-size: 22px;
            font-weight: 500;
            color: #333333;
            margin-right: 18px;
          }
          .num{
            font-size: 20px;
            font-weight: 600;
            color: #0B9D58;
            display: flex;
            align-items: flex-end;
            margin-right: 73px;
            .avg{
              font-size: 16px;
              font-weight: 400;
              //color: #0B9D58;
              margin-left: 17px;
              padding-bottom: 2px;
            }
          }
          .info{
            margin-right: 30px;
            ul{
              display: flex;
              li{
                margin-right: 48px;
                .des{
                  color: #999999;
                }
              }
            }
          }
          .btn{
            width: 110px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            color: #fff;
            background: #1645FF;
            border-radius: 4px;
          }
        }
        .news-box{
          margin: 0 0 40px;
          .news-list{
            padding: 20px 0 30px;
            border-bottom: 1px dashed #F1F3F7;
            cursor: pointer;
            .news-tit{
              font-size: 16px;
              font-weight: 500;
              color: #FF0000;
              line-height: 26px;
              margin-bottom: 8px;
              span{
                color: #333333;
              }
            }
            .news-content{
              font-size: 14px;
              font-weight: 400;
              color: #666666;
              line-height: 26px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              &:hover{
                color: #1645FF;
              }
            }
            .news-time{
              display: flex;
              font-size: 12px;
              font-weight: 400;
              color: #666666;
              line-height: 26px;
              div{
                padding-right: 43px;
              }
            }
          }
        }
        .yb{
          .tit-master{
            font-size: 16px;
            font-weight: 500;
            color: #333333;
            line-height: 26px;
            margin: 15px 0 5px;
          }
          .tit-assistant{
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 26px;
            margin-bottom: 26px;
          }
          .content{
            display: flex;
            > div{
              width: 471px;
              height: 323px;
              border: 1px solid #EBEBEB;
              margin-right: 24px;
            }
            .right{
              padding: 16px;
              .list{
                margin-bottom: 20px;
                .item{
                  cursor: pointer;
                  border-bottom: 1px dashed #EBEBEB;
                  padding-bottom: 12px;
                  margin-bottom: 16px;
                  .t{
                    display: flex;
                    .name{
                      span{
                        padding-right: 10px;
                      }
                      .stock-name{
                        font-size: 16px;
                        font-weight: 500;
                        color: #333333;
                        line-height: 26px;
                      }
                      .stock-teacher{
                        font-size: 14px;
                        font-weight: 400;
                        color: #333333;
                        line-height: 26px;
                        &.fxs{
                          color: #1645FF;
                        }
                        img{
                          vertical-align: middle;
                          margin: -2px 0 0 2px;
                        }
                      }
                    }
                    .time{
                      font-size: 12px;
                      font-weight: 400;
                      color: #999999;
                      line-height: 26px;
                      margin-left: auto;
                    }
                  }
                  .des{
                    font-size: 12px;
                    font-weight: 400;
                    color: #666666;
                    line-height: 22px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    &:hover{
                      color: #1645FF;
                    }
                  }
                }
              }
            }
          }
        }
        .btn-watch-detail{
          font-size: 12px;
          font-weight: 400;
          color: #1645FF;
          line-height: 17px;
          cursor: pointer;
          text-decoration: underline;
        }
        ::v-deep .el-table{
          .el-table__header-wrapper{
            th{
              height: 38px;
              background: #F5F5F5;
              font-size: 12px;
            }
          }
          td{
            padding: 5px 0;
            font-size: 12px;
          }
          .reportDateDesc{
            span{
              display: block;
              text-align: center;
              line-height: 20px;
            }
          }
          .tag{
            display: inline-block;
            color: #FE0000;
            padding: 0 10px;
            height: 30px;
            line-height: 30px;
            background: #FFEDE8;
          }
          .industry{
            color: rgb(22, 69, 255);
            cursor: pointer;
          }
          .intro,.concept{
            overflow:hidden;
            text-overflow:ellipsis;
            display:-webkit-box;
            -webkit-box-orient:vertical;
            -webkit-line-clamp:3;
          }
        }
        ::v-deep .el-tabs{
          .is-active,.el-tabs__item:hover{
            color: #1645FF;
          }
          .el-tabs__active-bar{
            background: #1645FF;
          }
          .el-tabs__nav-wrap{
            &::after{
              height: 1px;
            }
          }
        }
        ::v-deep .el-pagination{
          .active{
            background: #1645FF;
          }
        }
      }
    }
  }

}
</style>
<style >
/*.app-main{*/
/*  height: 100vh;*/
/*}*/
</style>
