<template>
  <div class="tab">
    <ul>
      <li :class="dstxActive === idx ? 'active' : ''" @click="swichItem(idx)" v-for="(item,idx) in optionsData" :key='idx'>{{item.label}}</li>
    </ul>
    <el-date-picker
      v-if="showCustom"
      class="date-picker"
      unlink-panels
      v-model="value1"
      type="daterange"
      value="YYYY-MM"
      value-format="yyyy-MM-dd HH:mm"
      start-placeholder="开始时间"
      end-placeholder="结束时间"
      @change='handleChange'
    />
  </div>
</template>

<script lang="ts">
import { reactive, defineComponent, toRefs } from 'vue'
import dicts from '@/utils/dicts'
export default defineComponent({
  props:{
    mode : {
      type : Number,
      default : 1
    },
    activeIdx : {
      type : Number,
      default : 0
    },
    init:{
      type: Boolean,
      default: true
    },
    isCustom:{
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const dataMap = reactive({
      dicts,
      dstxActive: props.activeIdx,
      optionsData: {},
      value1: '',
      showCustom: false,
      swichItem: (idx: any) => {
        dataMap.dstxActive = idx
        if( dataMap.optionsData[idx].type === "custom" ){
          dataMap.showCustom = true
          return false
        }else{
          dataMap.showCustom = false
        }
        context.emit('change', dataMap.optionsData[idx])
      },
    })

    console.log('!props.isCustom',!props.isCustom)

    if( props.mode === 1 ){
      dataMap.optionsData = props.isCustom ? dicts.day : dicts.day.filter((item:any)=>item.type !== 'custom')
    }else if( props.mode === 2 ){
      dataMap.optionsData = props.isCustom ? dicts.week : dicts.week.filter((item:any)=>item.type !== 'custom')
    }

    if( props.init ){
      context.emit('change', dataMap.optionsData[0])
    }

    const handleChange = (val:any) => {
      context.emit('change', {lbValue : `${window.moment(val[0]).format('YYYY-MM-DD')},${ window.moment(val[1]).format('YYYY-MM-DD')}`})
    }

    return { ...toRefs(dataMap),handleChange }
  }
})
</script>

<style scoped lang="scss">

.tab{
  display: flex;
  justify-content: right;
  ul{
    display: flex;
    margin-right: 10px;
    // border: 1px solid #DDDDDD;
    border-radius: 4px;
    overflow: hidden;
    // font-size: 0;
    li{
      width: 57px;
      height: 32px;
      line-height: 32px;
      cursor: pointer;
      background: #FFFFFF;
      text-align: center;
      // border-right: 1px solid #DDDDDD;
      color: #666666;
      border: 1px solid #DDDDDD;
      border-right: none;
      &.active{
        color: #386FF2;
        // background: #386FF2;
        border-color: #386FF2;
        // border-top: #386FF2;
        // border-bottom: #386FF2;
        &+li{
          border-left-color: #386FF2;
        }
        // position: relative;
        // &::after{
        //   width: 1px;
        //   height: 100%;
        //   background:#386FF2;
        //   content: '';
        //   position: absolute;
        //   right: 0;
        //   top: 0;
        // }
      }
      &:last-child.active{
          border-right-color: #386FF2;
      }
      &:last-child{
        border-right: 1px solid #DDDDDD;
        border-radius: 0 4px 4px 0;
      }
      &:first-child{
        border-radius: 4px 0 0 4px;
      }
    }
  }
}
</style>
