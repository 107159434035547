

import { defineComponent, reactive, toRefs, ref } from 'vue'
import CTitle from '@/components/title/Index.vue'
import NewTitle from '@/components/new-title/Index.vue'
import { getStockReportBuy, getStockReportUp, getStockReportFirst } from '@/apis/agency'
import moment from 'moment' // 导入文件
import dicts from '../../../utils/dicts'
interface Inter {
  order: string | undefined,
  orderBy: string | undefined,
}
export default defineComponent({
  props: {
    dataMap: {
      type: Array,
      default: []
    },
    title: {
      type: String,
      default: '机构评级'
    }
  },
  components: {
    CTitle,
    NewTitle
  },
  setup() {
    const refNewTitle1 = ref(null)
    const refNewTitle2 = ref(null)
    const refNewTitle3 = ref(null)
    const dataMap = reactive({
      stockList1: [],
      stockList2: [],
      stockList3: [],
      // sortParams1: {
        
      // },
    })
    let sortParams1:Inter = reactive({
      order: 'DESC',
      orderBy: 'orgCount',
    })
    let sortParams2:Inter = reactive({
      order: 'DESC',
      orderBy: 'date',
    })
    let sortParams3:Inter = reactive({
      order: 'DESC',
      orderBy: 'date',
    })
    
    const changeHandle1 = async (params:any) => {
      if( sortParams1['timeLevel'] !== 0 ){
        delete sortParams1['dateFrom']
        delete sortParams1['dateTo']
      }
      sortParams1 = {...sortParams1, ...params}
      const data = await getStockReportBuy(sortParams1)
      dataMap.stockList1 = (data as any).data
    }
    const sortChange1 = (item:any) => {
      if( item.prop ){
        changeHandle1({
          order: item.order === "ascending" ? 'ASC' : 'DESC',
          orderBy: item.prop
        })
      }else{
        delete sortParams1.order
        delete sortParams1.orderBy
        changeHandle1({})
      }
    }

    const changeHandle2 = async (params:any) => {
      if( sortParams2['timeLevel'] !== 0 ){
        delete sortParams2['dateFrom']
        delete sortParams2['dateTo']
      }
      sortParams2 = {...sortParams2, ...params}
      const data = await getStockReportUp(sortParams2)
      dataMap.stockList2 = (data as any).data
    }
    const sortChange2 = (item:any) => {
      if( item.prop ){
        changeHandle2({
          order: item.order === "ascending" ? 'ASC' : 'DESC',
          orderBy: item.prop
        })
      }else{
        delete sortParams2.order
        delete sortParams2.orderBy
        changeHandle2({})
      }
    }

    const changeHandle3 = async (params:any) => {
      if( sortParams3['timeLevel'] !== 0 ){
        delete sortParams3['dateFrom']
        delete sortParams3['dateTo']
      }
      sortParams3 = {...sortParams3, ...params}
      const data = await getStockReportFirst(sortParams3)
      dataMap.stockList3 = (data as any).data
    }
    const sortChange3 = (item:any) => {
      if( item.prop ){
        changeHandle3({
          order: item.order === "ascending" ? 'ASC' : 'DESC',
          orderBy: item.prop
        })
      }else{
        delete sortParams3.order
        delete sortParams3.orderBy
        changeHandle3({})
      }
    }

    
    const goDetail1 = async (item:any) => {
      const Index = dicts.timeSelection.findIndex((item) => item.value === sortParams1['timeLevel']);
      if(Number(sortParams1['timeLevel']) === 0){
        const beginDate = window.moment(sortParams2['dateFrom']).format('YYYY-MM-DD')
        const endDate = window.moment(sortParams2['dateTo']).format('YYYY-MM-DD')
        window.open(`/investment/agency/index?idx=1&timeLevel=4&beginDate=${beginDate}&endDate=${endDate}&organizationSName=${item.stockName}`)
      }else{
        window.open(`/investment/agency/index?idx=1&timeLevel=${Index - 1}&organizationSName=${item.stockName}`)
      }
    }
    const goDetail2 = async (item:any) => {
      (refNewTitle2 as any).value.handleJump(item.stockName)
    }
    const goDetail3 = async (item:any) => {
      (refNewTitle3 as any).value.handleJump(item.stockName)
    }

    const goList2 = async (name:any) => {
      (refNewTitle2 as any).value.handleJump2(name)
    }
    const goList3 = async (name:any) => {
      (refNewTitle3 as any).value.handleJump2(name)
    }

    return {
      refNewTitle1,
      refNewTitle2,
      refNewTitle3,
      moment,
      sortChange1,
      sortChange2,
      sortChange3,
      changeHandle1,
      changeHandle2,
      changeHandle3,
      goDetail1,
      goDetail2,
      goDetail3,
      goList2,
      goList3,
      ...toRefs(dataMap)
    }
  }
})
