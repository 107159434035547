
import {
  defineComponent,
  onActivated,
  onBeforeUnmount,
  onDeactivated,
  onMounted,
  nextTick,
  watch
} from 'vue'
import resize from '@/components/charts/mixins/resize'
import { init, EChartsOption } from 'echarts'
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '350px',
      required: true
    },
    chartData: {
      type: Object,
      default: {}
    },
    selectedTime: {
      type: String || Number,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
  },
  setup(props, context) {
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()

    watch(props, (newName, oldName) => {
      initChart()
    });

    const initChart = () => {
      const xAxisList = Array.from(props.chartData.holdMarketDaily.points, ({ x }) => x);
      // 折线
      const yAxisRightList = Array.from(props.chartData.holdMarketRatioDaily.points, ({ y }) => y);
      const compositeIndex = yAxisRightList.filter((item) => item !== null);
      // 柱状图
      const yAxisLeftList = Array.from(props.chartData.holdMarketDaily.points, ({ y }) => y);
      const MaxArrpLeft = Math.ceil(Math.max(...yAxisLeftList));
      const MinArrpLeft = Math.floor(Math.min(...yAxisLeftList));
      const integerData: any = [];
      yAxisLeftList.map((item) => {
        let obj = {
          value: item,
          itemStyle: {
            color: item > 0 ? '#F74E4F' : '#5CBC7C'
          }
        }
        integerData.push(obj)
      })
      const iconUrl = 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAHCAYAAAFy0i3pAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAEqADAAQAAAABAAAABwAAAAAyqaqnAAAAR0lEQVQoFWNgwAYYY/bU/AdJLHFpYfzu5w9mYyiEq5o96TJYEi4AU4pXO0wR2TRR1jGRbTzNNGK4G90mULCBxHCGPLoGSvkA1Yoe51xpxlAAAAAASUVORK5CYII='
      const barChart = init(document.getElementById(props.id) as HTMLDivElement)
      barChart.setOption({
        grid: {
          top: '40px',
          left: '0',
          right: '10px',
          bottom: '40px',
          containLabel: true
        },
        legend: {
          icon: 'line',
          itemWidth: 12,  // 设置宽度
          itemHeight: 6, // 设置高度
          x: 'center',
          bottom: '0',
          itemGap: 30,
          textStyle: {
            fontSize: 12,// 字体大小
            color:'#666666',
          },
          data: [
            {
              name: '持股市值',
              // icon: iconUrl,
            },
            {
              name: '持股占北向资金比',
            }
          ]
        },
        tooltip: {
          trigger: 'axis',
          formatter:function(params: any) {
            let text = `<div style="display: flex; justify-content: space-between"><span>${ params[0].axisValue }</span><span></span></div>`;
            for (let i = 0; i < params.length; i++) {
              const item = params[i]
              text += `<div style="display: flex; justify-content: space-between">
                      <div style="display: flex;align-items: center">
                        <span style='width:8px;height:8px;display:inline-block;border-radius:50%;margin-right:3px;background: ${item.color}'></span>
                        <span style='display:inline-block;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;line-height: 17px;'>${item.seriesName}：</span>
                        </div>
                        <span style='display:inline-block;font-size: 14px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #333333;line-height: 20px;'>${item.seriesName == '持股市值' ? (Math.abs(item.data.value) > 10000 ? (item.data.value / 10000).toFixed(2) + '亿' : item.data.value.toFixed(2) + '万')  : item.data}</span>
                    </div>`
            }
            return text
          }
        },
        xAxis: {
          data: xAxisList,
          axisLine: { show: true, onZero: true, lineStyle: { type: 'dashed' } },
          splitLine: { show: false },
          axisTick: { show: false },
          splitArea: { show: false },
          axisLabel: {
            textStyle: {
              color: '#999',
              fontSize: 12,
            }
          },
        },
        yAxis: [
          {
            type: 'value',
            name: '单位：元',
            splitLine: { show: false },
            max: MaxArrpLeft + (MaxArrpLeft - MinArrpLeft) / 10,
            min: MinArrpLeft - (MaxArrpLeft - MinArrpLeft) / 80,
            axisPointer: {
              type: 'none',
              label: { show: false }
            },
            axisLabel: {
              show: true,
              align: 'right',
              formatter:function(param: any) {
                return Math.abs(param) >= 10000 ? (param / 10000).toFixed(2) + '亿' : param.toFixed(2) + '万'
              }
            }
          },
          {
            type: 'value',
            name: '单位：‰',
            splitLine: { show: true, lineStyle: { type: 'dashed' } },
            axisLabel: {
              show: true,
            }
          }
        ],
        series: [
          {
            name: '持股市值',
            type: 'bar',
            yAxisIndex: 0,
            barWidth: 20,
            itemStyle: {
              normal: {
                color: '#F74E4F'
              }
            },
            data: integerData
          },
          {
            name: '持股占北向资金比',
            type: 'line',
            yAxisIndex: 1,
            symbol: 'none',
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 1.5,
                  color: '#0086FB'
                }
              }
            },
            data: compositeIndex
          },
        ]
      } as EChartsOption)
      chart.value = barChart
    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {
    }
  }
})
