
import { defineComponent, reactive, toRefs, nextTick } from 'vue'
import dateComponent from '@/components/timeLevel/Index.vue'
import titleName from '../component/title.vue'
import tableComponent from '../component/table.vue'
import netTable from '../component/net-purchase-table.vue'
import compositeChart from '../component/composite-chart.vue'
import dailyChart from '../component/daily-fund-chart.vue'
import purchaseChart from '../component/purchase-chart.vue'
import sellChart from '../component/sell-out-chart.vue'
import {  getReportSummaryDate, getFlow, getAddHold, getSubHold, getBoard, getDaily, getFundsSummary } from '@/apis/northboundFunds'
import moment from 'moment'
import html2canvas from 'html2canvas';
import QRCode from 'qrcodejs2-fix';

import { ElMessage } from 'element-plus'
// import { useRouter } from 'vue-router'
export default defineComponent({
  components: {
    titleName,
    tableComponent,
    netTable,
    compositeChart,
    dailyChart,
    purchaseChart,
    sellChart,
    dateComponent
  },
  setup() {
    // const router = useRouter();
    const dataMap = reactive({
      domian: window.location.href === 'https://tmgr.jufxt.com/investment/northboundFunds/share-page' ? 's.jfinfo.com/zm6VTb' : window.location.href === 'https://mgr.jufxt.com/investment/northboundFunds/share-page' ? 's.jfinfo.com/zm6VNb' : window.location.href,
      shuiyin:'',
      logoSrc:'',
      // reportData: [],
      time: '',
      flowData: [],
      renderImgSrc: '',
      flowAddHoldData: [], // 买入
      flowSubHoldData: [], // 卖出
      boardData: [], // 陆股通近30天资金流向与上证指数走势
      dailyData: [], // 陆股通行业日资金流向
      periodType: '',

      seletedTime: {},
      fundsSummaryList: {},
      chartShow: [true, true, true, true]
    })

    let date = ''
    const fetchData = async () => {
      // const reportList = await getReportSummary({periodType:dataMap.periodType})
      // if ((reportList as any).code === 200) {
      //   dataMap.reportData = (reportList as any).data
      // }
      const fundsSummaryList = await getFundsSummary({periodType:dataMap.periodType, date})
      if ((fundsSummaryList as any).code === 200) {
        dataMap.fundsSummaryList = (fundsSummaryList as any).data;
      }
      const reportDateList = await getReportSummaryDate({periodType:dataMap.periodType, date})
      if ((reportDateList as any).code === 200) {
        dataMap.time = moment((reportDateList as any).data.prevNbTradeDate).format('YYYYMMDD');
      }
      const flowList = await getFlow({periodType:dataMap.periodType, date})
      if ((flowList as any).code === 200) {
        dataMap.flowData = (flowList as any).data;
      }

      const flowBoardList = await getBoard({periodType:dataMap.periodType, date})
      if ((flowBoardList as any).code === 200) {
        dataMap.boardData = (flowBoardList as any).data;
      }
      const flowDailyList = await getDaily({periodType:dataMap.periodType, date})
      if ((flowDailyList as any).code === 200) {
        dataMap.dailyData = (flowDailyList as any).data;
      }
      const flowAddHoldList = await getAddHold({periodType:dataMap.periodType, date})
      if ((flowAddHoldList as any).code === 200) {
        dataMap.flowAddHoldData = (flowAddHoldList as any).data;
      }
      const flowSubHoldList = await getSubHold({periodType:dataMap.periodType, date})
      if ((flowSubHoldList as any).code === 200) {
        dataMap.flowSubHoldData = (flowSubHoldList as any).data;
      }
    }

    const refreshChart = async (idx:any) => {
      if( idx === 0 || idx === -1 ){
        dataMap.chartShow = [false, true, true, true]
        nextTick(()=>{
          dataMap.chartShow = [true, true, true, true]
        })
      }
      if( idx === 1 || idx === -1 ){
        dataMap.chartShow = [true, false, true, true]
        nextTick(()=>{
          dataMap.chartShow = [true, true, true, true]
        })
      }
      if( idx === 2 || idx === -1 ){
        dataMap.chartShow = [true, true, false, true]
        nextTick(()=>{
          dataMap.chartShow = [true, true, true, true]
        })
      }
      if( idx === 3 || idx === -1 ){
        dataMap.chartShow = [true, true, true, false]
        nextTick(()=>{
          dataMap.chartShow = [true, true, true, true]
        })
      }
    }
    // fetchData()

    const getDate = (time:any) => {
      if( time.hasOwnProperty('custom') ){
        date = time.custom
      }else{
        dataMap.seletedTime = time
        dataMap.periodType = time.periodType
      }
      fetchData()
    }

    const exportImg = () => {
      const capture = document.querySelector('#capture') as HTMLElement
      html2canvas(capture).then((canvas) => {
        let imgName = '北向资金'
        let a = document.createElement('a')
        a.style.display = 'none'
        let blob = dataURLToBlob(canvas.toDataURL('image/png'))
        a.setAttribute('href',URL.createObjectURL(blob))
        a.setAttribute('download',imgName+'.png')
        document.body.appendChild(a)
        a.click()
        URL.revokeObjectURL(blob as any)
        document.body.removeChild(a)
      });
    }

    const dataURLToBlob = (dataUrl:any) => {
      let arr = dataUrl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while(n--){
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr],{type:mime})
    }

    const copyDomain = () => {
        let url = dataMap.domian;
        let oInput = document.createElement('input');
        oInput.value = url;
        document.body.appendChild(oInput);
        oInput.select(); // 选择对象;
        console.log(oInput.value)
        document.execCommand("Copy"); // 执行浏览器复制命令

        ElMessage({
          message: '复制成功',
          type: 'success',
        })
    }

    const _isMobile = () => {
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
	      return flag;
    }

    const getBase64 = (url:any, callback:any) => {
      var Img = new Image(),
      dataURL = '';
      Img.src = url +"?v=" + Math.random();
      Img.setAttribute("crossOrigin", 'Anonymous')
      Img.onload = function () {
          var canvas = document.createElement("canvas"),
          width = Img.width,
          height = Img.height;
          canvas.width = width;
          canvas.height = height;
          if( canvas ){
            (canvas as any).getContext("2d").drawImage(Img, 0, 0, width, height);
            dataURL = canvas.toDataURL('image/png');
            callback ? callback(dataURL) : null;
          }
      };
    }
    getBase64('https://jfinfo.oss-cn-beijing.aliyuncs.com/shuiyin.png', (data:any) => {
      dataMap.shuiyin = data
    })
    getBase64(require('../images/logo.png'), (data:any) => {
      dataMap.logoSrc = data
    })

    if( _isMobile() ){
      const meta = document.getElementsByTagName('meta')['viewport']
      meta.content = ''
    }


    const fallback = () => {
      window.open(`/investment/northboundFunds/index`)
      // router.push(`/northboundFunds/index`)
    }

    const generateCode = () => {
      nextTick(() => {
        // document.getElementById("imgContainer").innerHTML = ""; //1
        new QRCode(document.getElementById("imgContainer") as HTMLElement, {
          text: window.location.href,
          width: '100',
          height: '100',
        });
      })
    }
    generateCode()

    return {
      ...toRefs(dataMap),
      exportImg,
      copyDomain,
      _isMobile,
      fallback,
      getDate,
      refreshChart
    }
  }
})

