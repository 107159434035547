<template>
  <div>
    <el-table
      class="table-style-2 cursor"
      border
      align="center"
      v-loading='loading'
      :cell-class-name='addClass'
      @row-click='goDetail'
      :data="stockList.list || []"
      :default-sort ="{prop:'addMarkeyCap',order:'descending'}"
      @sort-change="sortChange">
      <el-table-column label="名称" prop="name" align="center" />
      <el-table-column :label="`${tableHeader.text || ''}涨幅`" prop="boardIncrease" align="center" >
        <template #default='{row}'>
            <div v-dClass='row.boardIncrease'><template v-if="row.boardIncrease > 0">+</template>{{ row.boardIncrease ? row.boardIncrease.toFixed(2) : 0.00 }}%</div>
          </template>
      </el-table-column>
      <el-table-column label="北向资金持股" align="center" >
        <el-table-column label="北向持股家数" prop="holdNum" align="center" sortable="custom" />
        <el-table-column label="市值" prop="holdMarketCap" align="center" sortable="custom">
          <template #default='{row}'>{{ row.holdMarketCap }}亿</template>
        </el-table-column>
        <el-table-column label="市值占板块比" prop="mvBoardRatio" align="center" sortable="custom">
          <template #default='{row}'>{{ row.mvBoardRatio.toFixed(2) }}%</template>
        </el-table-column>
        <el-table-column label="占北向资金比" prop="mvBxzzRatio"  align="center" sortable="custom">
          <template #default='{row}'>{{ row.mvBxzzRatio.toFixed(2) }}%</template>
        </el-table-column>
      </el-table-column>
      <el-table-column prop="pb" :label='`北向资金${tableHeader.label||""}增持估计`'  align="center">
        <el-table-column label="增持股票家数" prop="addHoldNum"  align="center" sortable="custom" />
        <el-table-column label="增持市值" prop="addMarkeyCap"  align="center" sortable="custom">
          <template #default='{row}'>
            <div v-dClass='row.addMarkeyCap'>{{ row.addMarkeyCap.toFixed(2) }}亿</div>
          </template>
        </el-table-column>
        <el-table-column label="增持市值增幅" prop="addRangeRatio"  align="center" sortable="custom">
          <template #default='{row}'>
            <div v-dClass='row.addRangeRatio'>{{ row.addRangeRatio.toFixed(2) }}%</div>
          </template>
        </el-table-column>
        <el-table-column label="占板块比" prop="addMvBoardRatio"  align="center" sortable="custom" >
          <template #default='{row}'>
            <div v-dClass='row.addMvBoardRatio'>{{ row.addMvBoardRatio.toFixed(2) }}‰</div>
          </template>
        </el-table-column>
        <el-table-column label="占北向资金比" prop="addMvBxzzRatio"  align="center" sortable="custom" >
          <template #default='{row}'>
            <div v-dClass='row.addMvBxzzRatio'>{{ row.addMvBxzzRatio }}‰</div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column :label="`${tableHeader.label || ''}增持占市值比最大股`" prop="addMvCodeRatioMax" align="center">
        <template #default='{row}'>
          <div @click.stop="goStock(row.addMvCodeRatioMaxCode)">{{ row.addMvCodeRatioMax }}</div>
        </template>
      </el-table-column>
      <el-table-column :label="`${tableHeader.label || ''}减持占市值比最大股`" prop="addMvCodeRatioMin" align="center">
        <template #default='{row}'>
          <div @click.stop="goStock(row.addMvCodeRatioMinCode)">{{ row.addMvCodeRatioMin }}</div>
        </template>
      </el-table-column>
    </el-table>
    <CPagination ref='pagin' :showPageSizes='true' :pageSize='10'  :pageData='stockList' @fetchData='fetchData'/>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  toRefs,
  reactive
} from 'vue'

import CPagination from "@/components/pagination/Index.vue"
import CustomTab from '../../components/CustomTab.vue'
import { getBoardIncreaseList } from '@/apis/northboundFunds'
export default defineComponent({
  components: {
    CPagination,
    CustomTab
  },
  props:{
    boardType: {
      type: String,
      default: 'swl1'
    },
  },
  setup(props, context) {
    const dataMap = reactive({
      loading: true,
      stockList: [

      ],
      tableHeader: {
        label: '1日',
        text: '1日'
      }
    })

    let params = {
      // timeLeve: 时间 1d,5d,10d,1m,1q,1y
      boardType: '',
      sortFiled: 'addMarkeyCap',
      sort: 'DESC',
      pageNum: '1',
      pageSize: '10'
    }


    const fetchData = async (value:any) => {

      if( value.pageNum ){
        params['pageNum'] = value.pageNum
      }
      if( value.pageSize ){
        params['pageSize'] = value.pageSize
      }
      if( value.lbValue ){
        dataMap.tableHeader = value
        params['timeLeve'] = value.lbValue
      }
      if( value.boardType ){
        params['boardType'] = value.boardType
      }
      // console.log('cccccccccccccccccccccccccccccc')
      if( params.boardType ){
        dataMap.loading = true
        const list = await getBoardIncreaseList(params)
        dataMap.stockList = (list as any).data;
        dataMap.loading = false
      }

    }

    const sortChange = (column: any) => {
      let isAsc = column.order === "ascending" ? 'asc' : 'desc';
      params['sortFiled'] = column["prop"]
      params['sort'] = isAsc
      fetchData({})
    };

    const goDetail = (item: any) => {
      const path = '/northboundFunds/industry'
      window.open( `/investment${path}?code=${item.name}&boardType=${props.boardType}`)
    };

    const goStock = (code: any) => {
      console.log(code)
      // return false;
      const path = '/northboundFunds/shares'
      window.open( `/investment${path}?code=${code}`)
    };

    const addClass = (column:any) => {
      if( column.column.property === params.sortFiled ){
        return 'sort-bg'
      }
    }


    return {
      ...toRefs(dataMap),
      fetchData,
      sortChange,
      goDetail,
      goStock,
      addClass,
    }
  }
})
</script>
