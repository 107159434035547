
import pagingComponent from '@/components/pagination/Index.vue'
import { defineComponent, reactive, toRefs } from 'vue'
import { getStockTable } from '@/apis/margin-trading'
export default defineComponent({
  props: {
    tableList: {
      type: Array,
      default: []
    },
    textStatus: {
      type: String,
      default: ''
    }
  },
  components: {
    pagingComponent
  },
  setup(props) {
    const dataMap = reactive({
      loading: true,
      tableData: {},
      pageItem: {},
      navItem: {},
      tabItem: {}
    })
    let params = {
      pageNum: '1',
      pageSize: '10',
      market: 'ALL',
      timeUnit: 'DAYS',
      num: 1,
      orderBy: 'rzjmr'
    }
    const fetchData = async (value: any, item: any, tabItem: any) => {
      dataMap.pageItem = value
      dataMap.navItem = item
      dataMap.tabItem = tabItem
      if(value.pageNum){
        params['pageNum'] = value.pageNum
      }
      if(value.pageSize){
        params['pageSize'] = value.pageSize
      }
      if(item){
        params['market'] = item.code
      }
      if(tabItem){
        params['timeUnit'] = tabItem.timeUnit
        params['num'] = tabItem.num
      }
      dataMap.loading = true
      const marginList = await getStockTable(params)
      dataMap.tableData = (marginList as any).data;
      dataMap.loading = false
    }
    const headerMethod = (item: any) => {
      const { row, columnIndex } = item
      if (row[0].level == 1) {
        row[9].colSpan = 0
        row[10].colSpan = 2
        if (columnIndex === 11) {
          return { display: 'none' }
        }
      }
    }
    const sortChange = (column: any) => {
      params['orderBy'] = column["prop"]
      fetchData(dataMap.pageItem, dataMap.navItem, dataMap.tabItem)
    };
    const addClass = (column:any) => {
      if( column.column.property === params.orderBy ){
        return 'sort-bg'
      }
    }
    const amount = (value: any, fixed = 2, unit = '元') => {
      if (unit == '万') {
        value = value * 10000
      } else if (unit == '亿') {
        value = value * 100000000
      }
      if (Math.abs(value / 100000000) >= 1) {
        return (value / 100000000).toFixed(fixed) + '亿'
      }
      if (Math.abs(value / 10_000) >= 1) {
        return (value / 10000).toFixed(fixed) + '万'
      }
      return value
    }
    const goDetail = (item: any, index: any) => {
      console.log(item, 'item', index.label, 'index')
      if (index.label === '个股名称/代码') {
        const path = '/marginTrading/shares'
        window.open( `/investment${path}?name=${item.stockName}&code=${item.stockCode}`)
      } else if (index.label === '所属行业') {
        const path = '/marginTrading/industry'
        window.open( `/investment${path}?code=${item.boardName}&boardType=swl2`)
      }
    };
    return {
      ...toRefs(dataMap),
      fetchData,
      headerMethod,
      sortChange,
      addClass,
      amount,
      goDetail
    }
  }
})
