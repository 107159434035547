<template>
  <!--  融资净买入排行-->
  <div class="table_box">
    <el-table
      border
      v-loading='loading'
      :data="tableData.list || []"
      @row-click='goDetail'
      style="width: 100%"
      :header-cell-style="{background:'#F2F6FF',color:'#333333'}">
      <el-table-column prop="name" label="个股" width="102" align="center">
        <template #default='{row}'>{{ row.name }}<br />{{ row.code }}</template>
      </el-table-column>
      <el-table-column v-if="currentValue.id === 1" prop="continueSubDays" label="连降天数" width="95" align="center" />
      <el-table-column v-if="currentValue.id === 2" prop="continueAddDays" label="连增天数" width="95" align="center" />
      <el-table-column v-if="currentValue.id === 1" prop="continueSubRangeIncrease" label="连降区间涨幅" width="105" align="center">
        <template #default='{row}'><div v-dClass='row.continueSubRangeIncrease'>{{ Number(row.continueSubRangeIncrease).toFixed(2) }}%</div></template>
      </el-table-column>
      <el-table-column v-if="currentValue.id === 2" prop="continueAddRangeIncrease" label="连增区间涨幅" width="105" align="center">
        <template #default='{row}'><div v-dClass='row.continueAddRangeIncrease'>{{ Number(row.continueAddRangeIncrease).toFixed(2) }}%</div></template>
      </el-table-column>
      <el-table-column v-if="currentValue.id === 1" prop="subToAddIncrease" label="转增区间涨幅" width="116" align="center">
        <template #default='{row}'><div v-dClass='row.subToAddIncrease'>{{ Number(row.subToAddIncrease).toFixed(2) }}%</div></template>
      </el-table-column>
      <el-table-column v-if="currentValue.id === 2" prop="addToSubIncrease" label="转降区间涨幅" width="116" align="center">
        <template #default='{row}'><div v-dClass='row.addToSubIncrease'>{{ Number(row.addToSubIncrease).toFixed(2) }}%</div></template>
      </el-table-column>
      <el-table-column v-if="currentValue.id === 1" prop="subToAddDate" label="转增日期" width="115" align="center" />
      <el-table-column v-if="currentValue.id === 2" prop="addToSubDate" label="转降日期" width="115" align="center" />
      <el-table-column prop="remainder" label="余额" width="115" align="center" />
      <el-table-column prop="buy" label="净买入" width="116" align="center" />
    </el-table>
    <paging-component ref='paging' :showPageSizes='true' :pageSize='10' :pagerCount="3"  :pageData='tableData' @fetchData='fetchData' />
  </div>
</template>
<script lang="ts">
import pagingComponent from '@/components/pagination/Index.vue'
import { defineComponent, reactive, toRefs } from 'vue'
import { getStockSub, getStockAdd } from '@/apis/margin-trading'
export default defineComponent({
  components: {
    pagingComponent
  },
  setup(props) {
    const dataMap = reactive({
      loading: true,
      currentValue: {
        id: 1
      },
      tableData: {}
    })
    let params = {
      pageNum: '1',
      pageSize: '10'
    }
    const fetchData = async (value:any) => {
      if (value.id) {
        dataMap.currentValue = value
      }
      if( value.pageNum ){
        params['pageNum'] = value.pageNum
      }
      if( value.pageSize ){
        params['pageSize'] = value.pageSize
      }
      const apiFun = dataMap.currentValue.id === 2 ? getStockAdd(params) : getStockSub(params)
      dataMap.loading = true
      const marginList = await apiFun
      dataMap.tableData = (marginList as any).data;
      dataMap.loading = false
    }
    const goDetail = (item: any) => {
      const path = '/marginTrading/shares'
      window.open( `/investment${path}?name=${item.name}&code=${item.code}`)
    };
    return {
      ...toRefs(dataMap),
      fetchData,
      goDetail
    }
  }
})
</script>
<style scoped lang="scss">
.table_box {
  //width: 765px;
  padding-bottom: 40px;
  margin-left: 30px;
}
</style>
