<template>
  <div class="compare">
    <el-row >
        <el-col :span="24" class="tit">
          同业比较
        </el-col>
        <!-- <el-col :span="20" class="time-select">
          <div class="tab">
            <ul>
              <li :class="dstxActive === idx ? 'active' : ''" @click="swichItem(idx)" v-for="(item,idx) in dicts.timeSelection" :key='idx'>{{item.label}}</li>
              <li :class="dstxActive === 5 ? 'active' : ''" @click="swichItem(5)">自定义</li>
            </ul>
          </div>
          <el-date-picker
              class="date-picker"
              unlink-panels
              v-model="selectTime"
              v-if="showTimeSelect"
              type="daterange"
              value="YYYY-MM"
              value-format="yyyy-MM-dd HH:mm"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              @change='handleChange'
            />
        </el-col> -->
    </el-row>

    <el-table
      class="table-style-2 cursor"
      :data="stockList1"
      border
      style="width: 100%"
      @row-click='handleRowClick'
      :default-sort ="{prop:'researchCount',order:'descending'}"
      @sort-change="sortChange"
    >
      <el-table-column
        prop="rankingNo"
        align="center"
        label="排名"
      />
      <el-table-column
        prop="code"
        align="center"
        label="代码"
      />
      <el-table-column
        prop="name"
        align="center"
        label="名称"
      />
      <el-table-column
        prop="industryName"
        align="center"
        label="所属行业"
      />
      <el-table-column
        prop="researchCount"
        align="center"
        label="调研次数"
        sortable='custom'
      />
      <el-table-column
        prop="orgResearchNumber"
        align="center"
        label="调研机构家数"
        sortable='custom'
      >
        <template #default='{row}'>
          <div >{{row.orgResearchNumber ?? '-'}}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="focusOrgResearchCount"
        align="center"
        label="重点机构次数"
        sortable='custom'
      >
        <template #default='{row}'>
          <div >{{row.focusOrgResearchCount ?? '-'}}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="focusOrgResearchNumber"
        align="center"
        label="重点机构家数"
        sortable='custom'
      >
        <template #default='{row}'>
          <div >{{row.focusOrgResearchNumber ?? '-'}}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="increase"
        align="center"
        label="区间涨幅"
      >
        <template #default='{row}'>
          <div v-dClass='row.increase' v-if="row.increase">
            <template v-if='row.increase > 0'>+</template>{{row.increase}}%
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>

  </el-table>
  </div>
</template>

<script lang="ts">

import { defineComponent, reactive, toRefs } from 'vue'
import dicts from '@/utils/dicts'
import { getSurveyIndustryCountRecords} from '@/apis/research'

import { useRoute  } from "vue-router";

export default defineComponent({
  components:{
    
  },
  setup() {
    const route = useRoute();
    const stockCode = route.query.code

    const dataMap = reactive({
      stockList1: [],

      formInline:{
        stockCode: stockCode,
        timeLevel: '1w',
      },
      dstxActive  : 0,
      showTimeSelect: false,
      selectTime: [],
    })

    const fetchData = async () => {
      let data1 = await getSurveyIndustryCountRecords(dataMap.formInline)
      dataMap.stockList1 = (data1 as any).data
    }
    // fetchData()


    //新版时间tab选择
    const swichItem = async (idx: any) => {
      dataMap.dstxActive = idx
      dataMap.showTimeSelect = idx === 5 ? true : false
      
      if( idx === 5 ){
        // delete dataMap.formInline['timeLevel']
        dataMap.formInline['timeLevel'] = ''
      }else{
        dataMap.formInline['timeLevel'] = dicts.timeSelection[idx].timeLevel
        delete dataMap.formInline['startTime'];
        delete dataMap.formInline['endTime'];
        fetchData()
      }
    }

    // 时间范围选择事件
    const handleChange = async () => {
      dataMap.formInline['startTime'] = window.moment(dataMap.selectTime[0]).format('x')
      dataMap.formInline['endTime'] = window.moment(dataMap.selectTime[0]).format('x')
      fetchData()
    }

    //清空
    const onClearForm = async () => {
      delete dataMap.formInline['startTime'];
      delete dataMap.formInline['endTime'];
      dataMap.formInline['orgName'] = ''
      dataMap.formInline['isPointOrg'] = []
      swichItem(0)
      fetchData()
    }

    //表格排序
    const sortChange = (column:any) => {
      let order = column.order === "ascending" ? true : false;
      if( column['prop'] ){
        dataMap.formInline['isAsc'] = order
        dataMap.formInline['sortKey'] = column["prop"]
      }else{
        delete dataMap.formInline['order']
        delete dataMap.formInline['orderField']
      }
      fetchData()
    }

    const setParams = (params:any) => {
      dataMap.formInline['timeLevel'] = params['timeLevel']
      if( params['timeLevel'] ){
        delete dataMap.formInline['startTime'] 
        delete dataMap.formInline['endTime'] 
      }else{
        dataMap.formInline['startTime'] = params['startTime'] 
        dataMap.formInline['endTime'] = params['endTime'] 
      }
      fetchData()
    }

    const handleRowClick = (row:any) => {
      window.open(`/investment/research/detail?code=${row.code}`)
    }

    return {
      ...toRefs(dataMap),
      swichItem,
      onClearForm,
      handleChange,
      sortChange,
      setParams,
      handleRowClick,
      dicts
    };
  }
})
</script>
<style lang='scss' scoped>
.tit{
  font-size: 20px;
  font-weight: 500;
  color: #333333;
  line-height: 36px;
}
::v-deep .date-picker{
  margin-left: 30px;
}

.tab{
    display: inline-block;
    ul{
      display: flex;
      // border: 1px solid #DDDDDD;
      border-radius: 4px;
      overflow: hidden;
      // font-size: 0;
      li{
        width: 52px;
        height: 32px;
        line-height: 32px;
        cursor: pointer;
        background: #FFFFFF;
        text-align: center;
        // border-right: 1px solid #DDDDDD;
        color: #666666;
        border: 1px solid #DDDDDD;
        border-right: none;
        &.active{
          color: #fff;
          background: #386FF2;
          border-color: transparent;
          border-top: none;
          border-bottom: none;
        }
        &:last-child{
          // border: 0;
          border-right: 1px solid #DDDDDD;
          border-radius: 0 4px 4px 0;
        }
        &:first-child{
          // border: 0;
          // border-right: 1px solid #DDDDDD;
          border-radius: 4px 0 0 4px;
        }
      }
    }
}
.time-select{
  text-align: right;
}

.table-style-2{
  margin-top: 12px;
}
</style>
