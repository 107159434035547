
import { defineComponent, reactive, toRefs, watch } from 'vue'
import trendChart from './trend-chart.vue'
export default defineComponent({
  props: {
    tableList: {
      type: Array,
      default: []
    }
  },
  components: {
    trendChart
  },
  setup(props) {
    const dataMap = reactive({
      titleList: [
        {
          id: 1,
          name: '行业名称'
        },
        {
          id: 2,
          name: '持股市值（亿元）'
        },
        {
          id: 3,
          name: '日净流向（亿元）'
        },
        {
          id: 4,
          name: '周净买入（亿元）'
        },
        {
          id: 5,
          name: '月净买入（亿元）'
        },
        {
          id: 6,
          name: '趋势图'
        }
      ],
      sumList: 0
    })

    let sum:any = []
    watch(props, (newName, oldName) => {
      props.tableList.map((item:any)=>{
        sum.push(...item.trendValues)
      })
      dataMap.sumList = sum
    });
    return {
      ...toRefs(dataMap)
    }
  }
})

