<template>
  <div class="wrap">
    <pageTitle title='北向资金' sub-title="> 个股明细"></pageTitle>
    <div class="industry_box bg">
      <div class="title_box">
        <div class="left_box">
          <span>{{ fundsData.stockName }}</span>
          <div class="background_box"></div>
        </div>
        <div class="right_box" v-if="fundsData.statsTime">(截至{{ moment(fundsData.statsTime).format('YYYY年MM月DD日') }}）</div>
      </div>
      <div class="describe">
        <div class="tips">
          <span>北向资金 <span v-if="Math.abs(fundsData.continueAddHoldDay) > 1"><span :style="fundsData.continueAddHoldDay > 0 ? 'color: #F74E4F;font-weight:600' : 'color: #5CBC7C;font-weight:600'">连续{{ Math.abs(fundsData.continueAddHoldDay) }}天{{ fundsData.continueAddHoldDay >= 2  ? '增持' : fundsData.continueAddHoldDay <= -2 ? '减持' : ''}}</span>，</span> 当前持有该股票<span style="font-size: 16px;font-weight: 600; color: #333333">{{ fundsData.holdShares}}股</span>，市值<span style="font-size: 16px;font-weight: 600; color: #333333">{{ mathAbs(fundsData.holdMarketCap) }}{{ mathAbsText(fundsData.holdMarketCap) }}</span>，在北向持仓中市值排名<span style="font-size: 16px;font-weight: 600; color: #333333">{{ fundsData.ranking }}</span>，占北向整体<span :style="fundsData.continueAddHoldDay > 0 ? 'color: #F74E4F;font-weight:600' : 'color: #5CBC7C;font-weight:600'">{{ fundsData.proportion }}%</span></span>
        </div>
        <div class="list">
          <ul>
            <li>
              <div class="t">当日净买入</div>
              <div class="b" :style="styleMathe(fundsData.buy)">{{ mathAbs(fundsData.buy) }}<span>{{ mathAbsText(fundsData.buy)}}</span></div>
            </li>
            <li>
              <div class="t">近5日净买入</div>
              <div class="b" :style="styleMathe(fundsData.buy5)">{{ mathAbs(fundsData.buy5) }}<span>{{ mathAbsText(fundsData.buy5)}}</span></div>
            </li>
            <li>
              <div class="t">近20日净买入</div>
              <div class="b" :style="styleMathe(fundsData.buy20)">{{ mathAbs(fundsData.buy20) }}<span>{{ mathAbsText(fundsData.buy20)}}</span></div>
            </li>
          </ul>
          <ul>
            <li>
              <div class="t">持股市值</div>
              <div class="b" :style="styleMathe(fundsData.holdMarketCap)">{{ mathAbs(fundsData.holdMarketCap) }}<span>{{ mathAbsText(fundsData.holdMarketCap)}}</span></div>
            </li>
            <li>
              <div class="t">近5日增持比例</div>
              <div class="b" :style="styleMathe(fundsData.addHoldRatio5)">{{ fundsData.addHoldRatio5 }}<span>%</span></div>
            </li>
            <li>
              <div class="t">近20日增持比例</div>
              <div class="b" :style="styleMathe(fundsData.addHoldRatio20)">{{ fundsData.addHoldRatio20 }}<span>%</span></div>
            </li>
          </ul>
        </div>
      </div>
      <day-charts id="dayLine" :chart-data="dayData" />
    </div>
    <div class="bg">
      <div class="title_box" style="margin-bottom:30px">
        <div class="left_box">
          <span>{{fundsData.stockName}}北向持股统计</span>
          <div class="background_box"></div>
        </div>
      </div>
      <ShareholdingStatistics />
    </div>
    <div class="bg">
      <div class="title_box">
        <div class="left_box">
          <span>北向增持统计同业排名</span>
          <div class="background_box"></div>
        </div>
      </div>
      <PeerRanking />
    </div>
    <div class="bg">
      <div class="title_box">
        <div class="left_box">
          <span>{{fundsData.stockName}}北向机构统计</span>
          <div class="background_box"></div>
        </div>
      </div>
      <InstitutionalStatistics />
    </div>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import pageTitle from '../components/PageTitle.vue'
import ShareholdingStatistics from './components/ShareholdingStatistics.vue'
import PeerRanking from './components/PeerRanking.vue'
import InstitutionalStatistics from './components/InstitutionalStatistics.vue'
import dayCharts from './components/day-charts.vue'
import { getDayKData, getFundsStock } from '@/apis/northboundFunds'
import { defineComponent, reactive, toRefs } from 'vue'
import moment from 'moment'
export default defineComponent({
  components: {
    pageTitle,
    ShareholdingStatistics,
    PeerRanking,
    InstitutionalStatistics,
    dayCharts
  },
  setup() {
    const route = useRoute();
    const dataMap = reactive({
      dayData: {},
      fundsData: {},
    })
    const fetchData = async () => {
      const stockList = await getDayKData({ stockCode: route.query.code })
      dataMap.dayData = stockList
      const fundsList = await getFundsStock({ stockCode: route.query.code })
      dataMap.fundsData = fundsList.data
    }
    fetchData();
    const styleMathe = (item) => {
      if( item ){
        const itemNumber = item
        return itemNumber < 0 ?  'color: #5CBC7C' : itemNumber > 0 ?  'color: #F74E4F' : 'color: #606266'
      }
    }
    const mathAbs = (item) => {
      const number = Number(Math.abs(item));
      const numberText = Number(item);
      return number > 10000  ? (numberText / 10000).toFixed(2) : numberText.toFixed(2)
    }
    const mathAbsText = (item) => {
      const number = Number(Math.abs(item));
      return number > 10000  ? '亿' : '万'
    }
    return {
      ...toRefs(dataMap),
      styleMathe,
      mathAbs,
      mathAbsText,
      moment
    }
  }
})
</script>

<style scoped lang="scss">
.wrap {
  margin: 0 35px;
  .bg{
    background: #fff;
    margin-bottom: 30px;
    padding: 0 20px 50px;
    .pl10{
      padding-left: 10px;
    }
  }
  .title_box {
    padding-top: 29px;
    display: flex;
    > .left_box {
      position: relative;
      margin-right: 10px;
      > span {
        display: block;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 28px;
      }
      > .background_box {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 80px;
        height: 9px;
        background: linear-gradient(270deg, rgba(56,111,242,0) 0%, #386FF2 100%);
      }
    }
    > .right_box {
      font-size: 17px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 24px;
    }
  }

  .describe{
    margin-bottom: 57px;
    .tips{
      margin: 20px 0;
      font-size: 16px;
      font-family: none;
    }
    .list{
      display: inline-block;
      padding: 0 15px;
      background: #F7F8FA;
      ul{
        // display: flex;
        clear: both;
        overflow: hidden;
        &:first-child{
          border-bottom: 1px solid #EBF0F9;
        }
        li{
          width: 147px;
          text-align: center;
          padding: 5px 0;
          margin: 7px 0;
          border-right: 1px solid #EBF0F9;
          display: inline-block;
          &:last-child{
            border: 0;
          }
          .t{
            font-size: 14px;
            font-weight: 500;
            color: #333333;
          }
          .b{
            font-size: 20px;
            font-weight: 600;
            > span {
              font-size: 12px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              line-height: 17px;
            }
          }
        }
      }
    }
  }
}
</style>
