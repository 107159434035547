<template>
  <div class="title_box">
    <img src="../images/ellipse.png" alt="">
    <span>{{ titleName }}</span>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    titleName: {
      type: String,
      default: ''
    }
  }
})

</script>
<style scoped lang="scss">
.title_box {
  padding: 5px 0;
  display: inline-flex;
  align-items: center;
  border-bottom: #021A5D 2px solid;
  margin-top: 20px;
  > img {
    display: block;
    width: 22px;
    height: 22px;
  }
  > span {
    display: block;
    font-size: 30px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #021A5D;
    line-height: 42px;
    margin-left: 10px;
  }
}
</style>
