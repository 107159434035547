
import {
  defineComponent,
  onActivated,
  onBeforeUnmount,
  onDeactivated,
  onMounted,
  nextTick,
  watch,
  reactive,
  toRefs,
  ref
} from 'vue'
import { init, EChartsOption } from 'echarts'
import resize from '@/components/charts/mixins/resize'
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '350px',
      required: true
    },
    chartData: {
      type: Object,
      default: {}
    },
    nameStatus: {
      type: Object,
      default: {}
    }
  },
  setup(props, context) {
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()
    const dataMap = reactive({
    })

    watch(props, (newName, oldName) => {
      initChart()
    });

    const initChart = () => {
      const iconUrl = 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAHCAYAAAFy0i3pAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAEqADAAQAAAABAAAABwAAAAAyqaqnAAAAR0lEQVQoFWNgwAYYY/bU/AdJLHFpYfzu5w9mYyiEq5o96TJYEi4AU4pXO0wR2TRR1jGRbTzNNGK4G90mULCBxHCGPLoGSvkA1Yoe51xpxlAAAAAASUVORK5CYII='
      const xAxisList = Array.from((props.chartData as any), ({ stockName }) => stockName);
      const yAxisLeftList = Array.from((props.chartData as any), ({ incr }) => incr);
      // 3日融资净买入(元)
      const netPurchase = Array.from((props.chartData as any), ({ rzjmr }) => rzjmr);
      // 3日融资买入额(元)
      const purchase = Array.from((props.chartData as any), ({ rzmr }) => rzmr);
      // 3日融券净卖出(股)
      const salesLending = Array.from((props.chartData as any), ({ rqjmc }) => rqjmc);
      // 3日融券卖出量(股)
      const volumeLending = Array.from((props.chartData as any), ({ rqmc }) => rqmc);

      const integerData: any = [];
      yAxisLeftList.map((item) => {
        let obj = {
          value: item,
          itemStyle: {
            color: item > 0 ? '#F74E4F' : '#5CBC7C'
          }
        }
        integerData.push(obj)
      })

      const barChart = init(document.getElementById(props.id) as HTMLDivElement)
      let selected = {}
      const legend_data = [
        {
          name: `${ props.nameStatus.label }融资净买入(元)`,
        },
        {
          name: `${ props.nameStatus.label }融券净卖出(股)`,
        },
        {
          name: `${ props.nameStatus.label }融券卖出量(股)`,
        },
        {
          name: `${ props.nameStatus.label }融资买入额(元)`,
        },
        {
          name: `${ props.nameStatus.label }涨跌幅(%)`,
          icon: iconUrl
        }
      ];
      legend_data.map((item:any, index:any) => {
        if( index === legend_data.length-1 ){
          selected[item.name] = true;
        }else{
          selected[item.name] = index == 0 ? true : false;
        }
      });
      let yAxisMax = 1;
      let yAxisMin = 1;
      let options = {
        grid: {
          top: '30px',
          left: '20px',
          right: '0',
          bottom: '50px',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          formatter:function(params: any) {
            let text = `<div style="display: flex; justify-content: space-between"><span>${ params[0].axisValue }</span><span></span></div>`;
            for (let i = 0; i < params.length; i++) {
              const item = params[i]
              text += `<div style="display: flex; justify-content: space-between">
                      <div style="display: flex;align-items: center">
                        <span style='width:8px;height:8px;display:inline-block;border-radius:50%;margin-right:3px;background: ${item.color}'></span>
                        <span style='display:inline-block;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;line-height: 17px;'>${ item.seriesName }：</span>
                        </div>
                        <span style='display:inline-block;font-size: 14px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #333333;line-height: 20px;'>${item.seriesName !==  `${props.nameStatus.label}涨跌幅(%)` ? Math.abs(item.value) >= 100000000 ? (item.value / 100000000).toFixed(2) + '亿' : Math.abs(item.value) >= 10000 ? (item.value / 10000).toFixed(2) + '万' : item.value.toFixed(2) : item.value }</span>
                    </div>`
            }
            return text
          }
        },
        legend: {
          icon: 'line',
          itemWidth: 12,  // 设置宽度
          itemHeight: 6, // 设置高度
          x: 'center',
          bottom: '0',
          itemGap: 30,
          textStyle: {
            fontSize: 12,// 字体大小
            color:'#666666',
          },
          data: legend_data,
          selected: selected
        },
        xAxis: {
          data: xAxisList,
          axisLine: {
            show: true,
            onZero: true,
            lineStyle: {
              color: "#CCCCCC",
            }
          },
          splitLine: { show: false },
          axisPointer: {
            label: { show: false }
          },
          axisLabel:{ show: true },
          triggerEvent: true,
          axisTick: { show: false },
          splitArea: { show: false }
        },
        yAxis: [
          {
            type: 'value',
            splitLine: { show: true, lineStyle: { type: 'dotted',color: '#CCCCCC' } },
            axisPointer: {
              type: 'none',
              label: { show: false }
            },
            axisLabel: {
              show: true
            },
            max: function (value:any) {
                yAxisMax = value.max <= 5 ? 20 : value.max;
                return (yAxisMax * 1.2).toFixed(0);
            },
            min: function (value:any) {
                if (value.min < 0) {
                    yAxisMin = value.min > -5 ? -5 : value.min;
                    return (yAxisMin * 1.2).toFixed(0);
                }
                else {
                    yAxisMin = 0;
                    return 0;
                }
            },
          },
          {
            type: 'value',
            splitLine: { show: false },
            position: 'right',
            axisPointer: {
              type: 'none',
              label: { show: false }
            },
            axisLabel: {
              show: true,
              align: 'left',
              formatter: function(param: any) {
                return `${Math.abs(param) >= 100000000 ? (param / 100000000).toFixed(2) + '亿' : Math.abs(param) >= 10000 ? (param / 10000).toFixed(2) + '万' : param.toFixed(2) }`
              }
            },
            max: function (value:any) {
                let ymax = isFinite(value.max) && value.max <= yAxisMax ? yAxisMax : value.max;
                return (ymax * 1.2).toFixed(0);
            },
            min: function (value:any) {
                let ymax = isFinite(value.max) && value.max <= yAxisMax ? yAxisMax : value.max;
                return ((ymax * yAxisMin) / yAxisMax * 1.2).toFixed(0);
            },
          }
        ],
        series: [
          {
            name: `${ props.nameStatus.label }融资净买入(元)`,
            type: 'bar',
            yAxisIndex: 1,
            barWidth: 20,
            barGap: '0%',
            itemStyle: {
              normal: {
                color: '#3B7FEE'
              }
            },
            data: netPurchase
          },
          {
            name: `${ props.nameStatus.label }融资买入额(元)`,
            type: 'bar',
            yAxisIndex: 1,
            barWidth: 20,
            barGap: '0%',
            itemStyle: {
              normal: {
                color: '#3B7FEE'
              }
            },
            data: purchase
          },
          {
            name: `${ props.nameStatus.label }融券净卖出(股)`,
            type: 'bar',
            yAxisIndex: 1,
            barWidth: 20,
            barGap: '0%',
            itemStyle: {
              normal: {
                color: '#3B7FEE'
              }
            },
            data: salesLending
          },
          {
            name: `${ props.nameStatus.label }融券卖出量(股)`,
            type: 'bar',
            yAxisIndex: 1,
            barWidth: 20,
            barGap: '0%',
            itemStyle: {
              normal: {
                color: '#3B7FEE'
              }
            },
            data: volumeLending
          },
          {
            name: `${ props.nameStatus.label }涨跌幅(%)`,
            type: 'bar',
            barWidth: 20,
            yAxisIndex: 0,
            barGap: '0%',
            itemStyle: {
              normal: {
                color: '#F74E4F'
              }
            },
            data: integerData
          }
        ]
      }
      barChart.setOption(options as any)
      chart.value = barChart

      barChart.on("legendselectchanged", function(param: any) {
        if (param.name != legend_data[4].name) {
          legend_data.map((item:any, index:any) => {
              selected[item.name] = (item.name == param.name || index == 4) ? true : false;
          });
          options.legend.selected = selected;
          barChart.setOption(options as any)
        }
        else {
          options.legend.selected = selected;
          barChart.setOption(options);
        }
      })
    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {
      ...toRefs(dataMap),
    }
  }
})
