<template>
  <div class="">
    <div class="content">
      <div class="left">
        <CTitle
          :mode="3"
          :type="'year'"
          title="个股估值排名"
          @change='changeHandle1'
        />
        <el-table
          class="table-style-2 cursor"
          :class=" 'color'+index "
          :data="stockList6['ranking'+(index+1)]"
          border
          v-for="(item,index) in 3"
          :key='index'
          @sort-change="(column)=>sortChange1(column,index)"
          :default-sort ="{prop:'valuation',order:'descending'}"
          @row-click='goDetail'
        >
          <el-table-column
            prop="date"
            align="center"
            :label="index === 0 ? '0-50估值分位' : index === 1 ? '50-80估值分位' : '>80估值分位'"
          />
          <el-table-column
            prop="name"
            align="center"
            label="股票名称"
          />
          <el-table-column
            prop="valuation"
            align="center"
            label="综合估值"
            sortable="custom"
          />
          <el-table-column
            prop="maxValuation"
            align="center"
            label="阶段最高"
            sortable="custom"
          />
          <el-table-column
            prop="minValuation"
            align="center"
            label="阶段最低"
            sortable="custom"
          />
        </el-table>
      </div>
      <div class="right">
        <CTitle
          :mode="3"
          :type="'year'"
          title="行业估值排名"
          @change='changeHandle2'
        />
        <el-table
          class="table-style-2"
          :class=" 'color'+index "
          :data="stockList7['ranking'+(index+1)]"
          border
          @sort-change="(column)=>sortChange2(column,index)"
          v-for="(item,index) in 3"
          :key='index'
          :default-sort ="{prop:'valuation',order:'descending'}"
        >
          <el-table-column
            prop="date"
            align="center"
            :label="index === 0 ? '0-50估值分位' : index === 1 ? '50-80估值分位' : '>80估值分位'"
          />
          <el-table-column
            prop="name"
            align="center"
            label="股票名称"
          />
          <el-table-column
            prop="valuation"
            align="center"
            label="综合估值"
            sortable="custom"
          />
          <el-table-column
            prop="maxValuation"
            align="center"
            label="阶段最高"
            sortable="custom"
          />
          <el-table-column
            prop="minValuation"
            align="center"
            label="阶段最低"
            sortable="custom"
          />
        </el-table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import { defineComponent, reactive, toRefs } from 'vue'
import CTitle from '@/components/title/Index.vue'
import { getValuationStockQuery, getValuationIndustryRanking } from '@/apis/home'

export default defineComponent({
  props: {
    dataMap: {
      type: Array,
      default: []
    }
  },
  components: {
    CTitle
  },
  setup() {

    const dataMap = reactive({
      stockList6: {
        ranking1:[],
        ranking2:[],
        ranking3:[],
      },
      stockList7: {
        ranking1:[],
        ranking2:[],
        ranking3:[],
      },
    })

    let tableSort1 = [
      {
        sortKey: 'valuation',
        isAsc: false,
      },
      {
        sortKey: 'valuation',
        isAsc: false,
      },
      {
        sortKey: 'valuation',
        isAsc: false,
      }
    ]
    let timeLevel1 = '';
    // {
    //   // valuationType: '',//估值类型[1:0-50分位,2:50-80分位,3:>80估值分位]
    //   timeLevel: '',
    //   sortKey: '',
    //   isAsc: '',
    // }
    const changeHandle1 =  async (val:any, sortKey:any, isAsc:any, idx:any) => {
      console.log(idx)
      if(val){timeLevel1 = val.timeLevel}

      if(sortKey !== undefined){tableSort1[idx]['sortKey'] = sortKey}
      if(isAsc !== undefined){tableSort1[idx]['isAsc'] = isAsc}
      console.log(tableSort1)
      // const data5 = await getValuationStockQuery({...tableSort1})
      // dataMap.stockList6 = (data5 as any).data;
      if( idx === 0 ){
        fetchData1()
      }else if ( idx === 1 ) {
        fetchData2()
      }else if ( idx === 2 ) {
        fetchData3()
      }else{
        fetchData1()
        fetchData2()
        fetchData3()
      }
    }

    let tableSort2 = [{
        sortKey: 'valuation',
        isAsc: false,
      },
      {
        sortKey: 'valuation',
        isAsc: false,
      },
      {
        sortKey: 'valuation',
        isAsc: false,
      }
    ]
    let timeLevel2 = '';
    // {
    //   timeLevel: '',
    //   sortKey: '',
    //   isAsc: '',
    // }
    const changeHandle2 =  async (val:any, sortKey:any, isAsc:any, idx:any) => {
      console.log(tableSort2[idx])
      if(val){timeLevel2 = val.timeLevel}
      if(sortKey !== undefined){tableSort2[idx]['sortKey'] = sortKey}
      if(isAsc !== undefined){tableSort2[idx]['isAsc'] = isAsc}
      // const data6 = await getValuationIndustryRanking({...tableSort2})
      // dataMap.stockList7 = (data6 as any).data;
     
      if( idx === 0 ){
        fetchData4()
      }else if ( idx === 1 ) {
        fetchData5()
      }else if ( idx === 2 ) {
        fetchData6()
      }else{
        fetchData4()
        fetchData5()
        fetchData6()
      }
    }

    const sortChange1 = (column:any,idx:any) => {
      let isAsc = column.order === "ascending" ? true : false
      changeHandle1({timeLevel: timeLevel1}, column['prop'], isAsc, idx)
    }

    const sortChange2 = (column:any,idx:any) => {
      let isAsc = column.order === "ascending" ? true : false
      changeHandle2({timeLevel: timeLevel2}, column['prop'], isAsc, idx)
    }

    const fetchData1 = async () => {
      const data5 = await getValuationStockQuery({...tableSort1[0],valuationType: 1, timeLevel: timeLevel1})
      dataMap.stockList6.ranking1 = (data5 as any).data;
    }
    const fetchData2 = async () => {
      const data5 = await getValuationStockQuery({...tableSort1[1],valuationType: 2, timeLevel: timeLevel1})
      dataMap.stockList6.ranking2 = (data5 as any).data;
    }
    const fetchData3 = async () => {
      const data5 = await getValuationStockQuery({...tableSort1[2],valuationType: 3, timeLevel: timeLevel1})
      dataMap.stockList6.ranking3 = (data5 as any).data;
    }
     const fetchData4 = async () => {
      const data5 = await getValuationIndustryRanking({...tableSort2[0],valuationType: 1, timeLevel: timeLevel2})
      dataMap.stockList7.ranking1 = (data5 as any).data;
    }
    const fetchData5 = async () => {
      const data5 = await getValuationIndustryRanking({...tableSort2[1],valuationType: 2, timeLevel: timeLevel2})
      dataMap.stockList7.ranking2 = (data5 as any).data;
    }
    const fetchData6 = async () => {
      const data5 = await getValuationIndustryRanking({...tableSort2[2],valuationType: 3, timeLevel: timeLevel2})
      dataMap.stockList7.ranking3 = (data5 as any).data;
    }

    /**
     * 跳转详情页面
     */
    const goDetail = (item:any) => {
      window['$props'].push({
        path: `/investment/analysis/detail`,
        query:{
          code: item.code
        }
      })
    }
    
    return {
      ...toRefs(dataMap),
      changeHandle1,
      changeHandle2,
      sortChange1,
      sortChange2,
      goDetail
    }
  }
})
</script>
<style scoped lang='scss'>
.content{
  display: flex;
  justify-content: space-between;
  &>div{
    width: 46%;
  }
}
/* 如果高度小于600px */ 
@media (max-width: 1600px) {
  .content{
    display: block;
    &>div{
      width: 100%;
    }
    .right{
      margin-top: 40px;
    }
  }
}
</style>
