
import { defineComponent, reactive, toRefs, ref } from 'vue';
import NewTitle from '@/components/new-title/Index.vue';
import dicts from '@/utils/dicts';
import { getStockReportBuy } from '@/apis/agency';
import moment from 'moment'; // 导入文件

interface Inter {
    order: string | undefined,
    orderBy: string | undefined,
}
export default defineComponent({
    props: {
        dataMap: {
            type: Array,
            default: []
        },
        title: {
            type: String,
            default: '机构评级'
        }
    },
    components: {
        NewTitle
    },
setup() {
    const refNewTitle1 = ref(null)
    const dataMap = reactive({
        loading: false,
        stockList1: [],
        timeLevel: -1,
        selectedTime: 0 as any,
        dateFrom: undefined as any,
        dateTo: undefined as any,
        page: {
            totalSize: 0,
            pageSize: 20,
            pageNum: 1,
        },
    })

    let sortParams1:Inter = reactive({
        order: 'DESC',
        orderBy: 'orgCount',
    })
    
    const changeHandle1 = async (params:any) => {
        console.log(params, 'selectTimeselectTime');
        dataMap.timeLevel = params.timeLevel;
        if(params.timeLevel !== 0) {
          dataMap.selectedTime = (dicts.timeSelection || []).findIndex((item:any) => item.value === params.timeLevel);
          dataMap.dateFrom = undefined;
          dataMap.dateTo = undefined;
        }else {
          dataMap.selectedTime = 5;
          dataMap.dateFrom = params.dateFrom ? params.dateFrom : undefined;
          dataMap.dateTo = params.dateTo ? params.dateTo : undefined;
        }
        dataMap.page.pageNum = 1;
        dataMap.page.pageSize = 20;
        getList();
    }

    const getList = async () => {
        const newParams = {
            timeLevel: dataMap?.timeLevel,
            dateFrom: dataMap.dateFrom ? dataMap.dateFrom : undefined,
            dateTo: dataMap.dateTo ? dataMap.dateTo : undefined,
            pageNum: dataMap.page.pageNum,
            pageSize: dataMap.page.pageSize,
        }
        const data = await getStockReportBuy({...sortParams1, ...newParams})
        dataMap.stockList1 = (data as any).data
    }

    const sortChange1 = (item:any) => {
        if( item.prop ){
            sortParams1 = {
                order: item.order === "ascending" ? 'ASC' : 'DESC',
                orderBy: item.prop,
            }
            getList()
        }else{
            delete sortParams1.order
            delete sortParams1.orderBy
            getList()
        }
    }

    const goDetail1 = async (item:any) => {
        const level = dicts.timeSelection.findIndex((item) => item.value === dataMap.timeLevel);
        if(level === -1){
            const beginDate = moment(`${dataMap.dateFrom}`).format('YYYY-MM-DD')
            const endDate = moment(`${dataMap.dateTo}`).format('YYYY-MM-DD')
            window.open(`/investment/agency/index?idx=1&isBuyFlag=1&timeLevel=4&beginDate=${beginDate}&endDate=${endDate}&organizationSName=${item.stockName}`)
        }else{
            window.open(`/investment/agency/index?idx=1&isBuyFlag=1&timeLevel=${level - 1}&organizationSName=${item.stockName}`)
        }
    }

    // （切换每页多少条）
    const handleSizeChange = (val:any) => {
          dataMap.page.pageSize = val;
          dataMap.page.pageNum = 1;
          getList()
      }

      //（切换页码）
      const handleCurrentChange = (val:any) => {
          dataMap.page.pageNum = val;
          getList()
      }

    return {
        refNewTitle1,
        moment,
        sortChange1,
        changeHandle1,
        goDetail1,
        handleSizeChange,
        handleCurrentChange,
        ...toRefs(dataMap)
    }
}
})
