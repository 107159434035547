/*
 * @Description: 财报详情
 */
import https from '@/utils/https'
import { RequestParams, ContentType, Method } from 'axios-mapper'

// 获取板块列表
export const getFinancialDetailed = (params: RequestParams) => {
  return https().request('/agg/financeReport/report/detail', Method.GET, params, ContentType.form)
}

// 获取最新4个季度的单季度总营收和归母净利润的数据
export const getFinancialReport = (params: RequestParams) => {
  return https().request('/agg/financeReport/leastList', Method.GET, params, ContentType.form)
}
