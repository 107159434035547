<template>
  <div class="app-container" :class="from" @click="visible = false">
    <!-- <breadcrumb @getStockInfo='getStockInfo' v-if="!from"></breadcrumb> -->
    <div class="bg">
      <CTitle :mode='12' title="研报查询" @changeTips='dialogVisible2 = true' v-if="!from"></CTitle>
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-row>
          <el-form-item label="研究机构">
            <el-input 
              size="mini"
              v-model="formInline.organization" 
              placeholder="输入机构名称" 
              @input="fetchSearchData2"
              @focus='fetchSearchData2'
              @blur='blurHandler2'
            />
            <div class="search_box" :class="searchList2.length>0?'showsearch_box':''" catchtap="noclose">
              <div class="search_box_list_box" scroll-y enhanced='{{true}}'  binddragging='binddragging1'>
                <div class="search_box_list">
                  <!-- :class="item.state === 1 || item.state === 4 ? 'disabled' : ''" -->
                  <div class="search_box_item"  v-for="(item,index) in searchList2" :key="index" @click.native="selectedSearch2(item)">
                    <div class="search_box_item_box">
                      <div class="search_box_item_left">
                        <div class="search_box_item_left_text">{{item}}</div>
                        <div class="search_box_item_left_num">{{item.code}}</div>
                      </div>
                        <!-- 股票状态[1:未上市,2:正常,3:停牌,4:退市] -->
                        <!-- <div class="search_box_item_right" v-if="item.state === 1">未上市</div>
                        <div class="search_box_item_right" v-else-if="item.state === 4">退市</div>
                        <div class="search_box_item_right" v-else>诊股</div> -->
                    </div>
                  </div>
                  <!-- <div class="search_box_last" @click="showMore" v-if="isMore">查看更多内容 ></div> -->
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="证券简称" v-if="!from">
            <el-input 
              size="mini"
              v-model="formInline.organizationSName" 
              placeholder="输入股票代码或名称" 
              @input="fetchSearchData"
              @focus='fetchSearchData'
              @blur='blurHandler'/>
            <div class="search_box" :class="searchList.length>0?'showsearch_box':''" catchtap="noclose">
              <div class="search_box_list_box" scroll-y enhanced='{{true}}'  binddragging='binddragging1'>
                <div class="search_box_list">
                  <!-- :class="item.state === 1 || item.state === 4 ? 'disabled' : ''" -->
                  <div class="search_box_item"  v-for="(item,index) in searchList" :key="index" @click.native="selectedSearch(item)">
                    <div class="search_box_item_box">
                      <div class="search_box_item_left">
                        <div class="search_box_item_left_text">{{item.name}}</div>
                        <div class="search_box_item_left_num">{{item.code}}</div>
                      </div>
                        <!-- 股票状态[1:未上市,2:正常,3:停牌,4:退市] -->
                        <!-- <div class="search_box_item_right" v-if="item.state === 1">未上市</div>
                        <div class="search_box_item_right" v-else-if="item.state === 4">退市</div>
                        <div class="search_box_item_right" v-else>诊股</div> -->
                    </div>
                  </div>
                  <!-- <div class="search_box_last" @click="showMore" v-if="isMore">查看更多内容 ></div> -->
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="分析师">
            <!-- filterable -->
            <el-select size="mini" v-model="formInline.reporter" filterable remote :remote-method="remoteMethod" popper-class='c-el-select'>
              <el-option 
                value=''
                label="不限"/>
              <el-option 
                value='新财富分析师'
                label="新财富分析师"/>
              <el-option 
                v-for="(item,idx) in fxList"
                :key="idx"
                :label="item"
                :value="item" />
            </el-select>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="时间" class="time-item">
            <div class="tab">
              <ul>
                <li :class="dstxActive === idx ? 'active' : ''" @click="swichItem(idx)" v-for="(item,idx) in dicts.timeSelection" :key='idx'>{{item.label}}</li>
                <li :class="dstxActive === 5 ? 'active' : ''" @click="swichItem(5)">自定义</li>
              </ul>
            </div>
          </el-form-item>
          <el-form-item v-if="showTimeSelect" class="time-item">
            <el-date-picker
              size="mini"
              class="date-picker"
              unlink-panels
              v-model="selectTime"
              type="daterange"
              value="YYYY-MM"
              value-format="yyyy-MM-dd HH:mm"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              @change='getTime'
            />
          </el-form-item>
        </el-row>
        <!-- <el-form-item label="时间">
          <el-select v-model="formInline.region" @change="handleChange" popper-class='c-el-select'>
            <el-option
              v-for="item in dicts.month"
              :key="item.value"
              :label="item.label"
              :value="item"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-if="formInline.region.value === 4">
          <el-date-picker
            
            v-model="formInline.time"
            type="daterange"
            value="YYYY-MM"
            value-format="YYYY-MM-DD HH:mm:ss"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            unlink-panels
          />
        </el-form-item> -->
      </el-form>
      <div class="navList">
        <div class="list" v-if="!from">
          <div class="des">行业</div>
          <div class="ans">
            <ul>
              <li :class="industryActive === -1 ? 'active' : ''" @click="handleIndustryTab(-1)">不限</li>
              <li 
                :class="industryActive === idx ? 'active' : ''"
                @click="handleIndustryTab(idx, item)"
                v-for="(item,idx) in industryList"
                :key="idx">
                {{item}}
              </li>
              <li v-if='industryLastText' :class="industryActive === -2 ? 'active' : ''" @click="handleIndustryTab(-2, industryLastText)">{{industryLastText}}</li>
              <li class="more">
                <div>
                  <el-popover
                    :width="600"
                    trigger="click"
                    :visible='visible'
                    popper-class='c-popover-list'
                  >
                    <div class="more-list">
                      <div 
                        :key="idx"
                        v-for="(item,idx) in industryMoreList"
                        >
                        <span class="letter">{{idx}}</span>
                        <span class="letter-ans" 
                          @click="handleMoreClick(innnerItem)"
                          :key="innerIdx"
                          v-for="(innnerItem,innerIdx) in item">
                          {{innnerItem}}
                        </span>
                      </div>
                    </div>
                    <template #reference>
                      <span @click="visible = !visible">更多</span>
                    </template>
                  </el-popover>
                </div>
              </li>
            </ul>
          </div>
          
        </div>
        <div class="list">
          <div class="des">评级相关</div>
          <div class="ans">
            <ul>
              <li 
                v-for="(item,idx) in dicts.ratingRelated" 
                :key='idx' 
                @click="handleRatingClick(item,idx)" 
                :class="ratingActive.indexOf(idx) !== -1 ? 'active' : ''">{{item}}</li>
            </ul>
          </div>
        </div>
        <div class="list">
          <div class="des">类型</div>
          <div class="ans">
            <ul>
              <li 
                v-for="(item,idx) in dicts.type" 
                :key='idx' 
                @click="handleTypeClick(item,idx)" 
                :class="typeActive.indexOf(idx) !== -1 ? 'active' : ''">{{item}}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="search-btn">
        <div @click="onClearForm">清空所有条件</div>
        <div @click="onSearch">查询</div>
      </div>
    </div>

    <div class="bg bg-1" v-loading='loading'>
      <CTitle :mode='13' :title="textIndustry + '行业：'" :titleData='{num:stockList1.organizationCount}' v-if="!from && textIndustry"></CTitle>
      <el-table
        class="table-style-3"
        :data="stockList1.reportData ? stockList1.reportData.list : stockList1.list"
        border
        style="width: 100%"
        :default-sort ="{prop:'date',order:'descending'}"
        @sort-change="sortChange"
        ref='refTab'
      >
        <!-- <el-table-column
          prop="stockCode"
          align="center"
          label="股票代码"
          v-if="!from"
        >
          <template #default='{row}'>
            {{row.stockCode.split('.')[0]}}
          </template>
        </el-table-column> -->
        <el-table-column
          prop="stockName"
          align="center"
          label="个股名称"
        >
          <template #default='{row}'>
            <div style="cursor:pointer" @click="handleGoDetail(row)">{{row.stockName}}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="reportName"
          align="center"
          label="报告名称"
        >
          <template #default='{row}'>
            <div class="reportName line2" @click="handleReport(row)">{{row.reportName}}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="reportType"
          align="center"
          label="研报类型"
        />
        <el-table-column
          prop="reportRating"
          align="center"
          label="机构评级"
        >
          <template #default='{row}'>
            <div>
              {{row.reportRating ? row.reportRating : '-'}}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="reportRatingChange"
          align="center"
          label="评级变动"
        />
        <el-table-column
          prop="organization"
          align="center"
          label="机构"
        >
          <template #default='{row}'>
            <div style="cursor:pointer" @click="organizationSelect(row.organization)">
              {{row.organization}}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="reporter"
          align="center"
          label="分析师"
        >
          <template #default='{row}'>
            <div v-if="row.isTop">
              <span v-for="(item, idx) in row.fxsList" :key='idx' :class="item.isTop === 1 ? 'fxs' : ''">
                {{item.fxsName}}<img src="@/assets/images/new-fxs.png" v-if="item.isTop === 1" />
                <template v-if="idx !== row.fxsList.length-1">,</template>
              </span>
            </div>
            <div v-else>
              {{row.reporter}}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width='110'
          prop="date"
          align="center"
          label="评级日期"
          sortable="custom"
        />
        <el-table-column
          prop="day1Change"
          align="center"
          label="次日涨幅"
          sortable="custom"
        >
          <template #default='{row}'>
            <div class="line1" v-dClass='row.day1Change' v-if="row.day1Change">
              {{row.day1Change[0] !== '-' && !/[\u4E00-\u9FA5]+/g.test(row.day1Change[0]) ? '+' : '' }}{{row.day1Change}}
            </div>
            <div v-else>-</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="day5Change"
          align="center"
          label="5日涨幅"
          sortable="custom"
        >
          <template #default='{row}'>
            <div class="line1" v-dClass='row.day5Change' v-if="row.day5Change">
              {{row.day5Change[0] !== '-' ? '+' : '' }}{{row.day5Change}}
            </div>
            <div v-else>-</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="day10Change"
          align="center"
          label="10日涨幅"
          sortable="custom"
        >
          <template #default='{row}'>
            <div class="line1" v-dClass='row.day10Change' v-if="row.day10Change">
              {{row.day10Change[0] !== '-' ? '+' : '' }}{{row.day10Change}}
            </div>
            <div v-else>-</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="day20Change"
          align="center"
          label="20日涨幅"
          sortable="custom"
        >
          <template #default='{row}'>
            <div class="line1" v-dClass='row.day20Change' v-if="row.day20Change">
              {{row.day20Change[0] !== '-' ? '+' : '' }}{{row.day20Change}}
            </div>
            <div v-else>-</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="customIncome"
          align="center"
          label="自定义周期收益"
        >
          <template #default='{row}'>
            <div class="line1" v-dClass='row.customIncome'>
              {{row.customIncome ?? '-'}}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="自定义周期"
        >
          <template #default='{row}'>
            <div @click.stop>
              <div class="blue" v-if="!row.time">选择</div>
              <div class="blue" v-else>{{ moment(row.time).format('YYYY-MM-DD')}}</div>
              <el-date-picker
                class="table-picker"
                unlink-panels
                v-model="row.time"
                type="date"
                value-format="yyyy-MM-dd"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                @change='HandleRowTime(row)'
              />
            </div>
          </template> 
        </el-table-column>
        <el-table-column
          prop="industry"
          align="center"
          label="行业"
          v-if="!from"
        >
          <template #default='{row}'>
            <div style="cursor:pointer" @click="industrySelect(row.industry)">
              {{row.industry ? row.industry : '-'}}
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="date"
          align="center"
          label="日期"
          sortable="custom"
        />  -->
      </el-table>
      
      <!-- <CPagination /> -->
      <CPagination ref='pagin' :pageSize='20' :showPageSizes='true' :pageData='stockList1.reportData ? stockList1.reportData : stockList1' @fetchData='fetchData'/>

    </div>
  </div>
</template>

<script lang="ts">

import { defineComponent, reactive, toRefs, ref } from 'vue'
import breadcrumb from '@/components/breadcrumb/Index.vue'
import CTitle from "@/components/title/Index.vue"
import CPagination from "@/components/pagination/Index.vue"

import { useRoute  } from "vue-router";

import { getReportFxsList, getStockRatingDetailList, getStockRatingList } from '@/apis/agency'
import { getOrgIndustryBoardList, getOrgIndustryBoardMore, getReportCalculateGains } from '@/apis/research'
import dicts from '@/utils/dicts'
import { getStockQuery, getReportOrganizationList } from '@/apis/home'

import moment from 'moment'
export default defineComponent({
  components: {
    CTitle,
    breadcrumb,
    CPagination
  },
  props: {
    from: {
      type: String
    }
  },
  setup(props) {
    
    const route = useRoute()
    const stockCode = route.query.code
    const prevPageRatingIdx = Number(route.query.idx)
    const pagin = ref<InstanceType<typeof CPagination>>() 
    const refTab = ref(null)

    const dataMap = reactive({
      loading: true,
      visible: false,
      textIndustry:'',
      selectedIndustry: '',
      industryActive: -1,
      ratingActive: prevPageRatingIdx ? [prevPageRatingIdx] : [0],
      typeActive: [0],
      formInlineinit: {
        region: '',
        time: [],
        order: 'DESC'
      },
      formInline: {
        region: route.query.timeLevel ? dicts.month[Number(route.query.timeLevel)] : '',
        time: [],
        order: 'DESC',
        rating: prevPageRatingIdx ? dicts.ratingRelated[prevPageRatingIdx] : ''
      },
      stockList1: {},
      fxList: [],
      industryList: [],
      industryLastText: '',
      industryMoreList: [],


      dstxActive  : 0,
      showTimeSelect: false,
      selectTime: ['', ''],

      searchList: [],
      searchList2: [],

    })

    if( route.query.keywork ){
      dataMap.ratingActive = [Number(route.query.keywork)]
    }

    if( route.query.organizationSName ){
      dataMap.formInline['organizationSName'] = route.query.organizationSName
    }

    if( route.query.orgSName ){
      dataMap.formInline['organization'] = route.query.orgSName
    }

    //新版时间tab选择
    const swichItem = async (idx: any) => {
        dataMap.dstxActive = idx
        dataMap.showTimeSelect = idx === 5 ? true : false
        if( idx === 5 ){
          delete dataMap.formInline['timeLevel']
        }else{
          dataMap.formInline['timeLevel'] = dicts.timeSelection[idx].value
        }
    }

    if( route.query.timeLevel ){
      swichItem(Number(route.query.timeLevel) + 1)

      //自定义时间
      if( dataMap.showTimeSelect ){
        dataMap.formInline['beginDate'] = route.query.beginDate
        dataMap.formInline['endDate'] = route.query.endDate
        dataMap['selectTime'] = [String(route.query.beginDate), String(route.query.endDate)]
      }else{
        dataMap.selectTime = []
      }
    }else{
      dataMap.selectTime = []
    }

    //获取分析师列表
    const remoteMethod = async (item:any) => {
      let data1 = await getReportFxsList({ fxsStrLike: item })
      dataMap.fxList = (data1 as any).data
    }
    remoteMethod('')

    const fetchList = async () => {
      

      //获取分析师列表
      let data1 = await getReportFxsList({ })
      dataMap.fxList = (data1 as any).data

      if( !props['from'] ){
        //获取行业列表
        let data3 = await getOrgIndustryBoardList({ })
        dataMap.industryList = (data3 as any).data

        //获取行业更多列表
        let data2 = await getOrgIndustryBoardMore({ })
        dataMap.industryMoreList = (data2 as any).data
      }
      
      // industryMoreList
    }
    fetchList()

    const fetchData = async (_parms:any) => {
      // console.log(22222, props['from'])
      dataMap.loading = true
      dataMap.formInline = {...dataMap.formInline, ..._parms}

      const newForm = JSON.parse(JSON.stringify(dataMap.formInline))
      delete newForm['region']
      delete newForm['time']
      // delete newForm['industry']
      let rating:any = []
      dataMap.ratingActive.map((item:any)=>{
        if( item !== 0 ){
          rating.push(dicts.ratingRelated[item])
        }
      })
      newForm['rating'] = rating.join(',');

      let type:any = []
      dataMap.typeActive.map((item:any)=>{
        if( item !== 0 ){
          type.push(dicts.type[item])
        }
      })
      newForm['type'] = type.join(',');

      
      let data1 = props['from'] ? await getStockRatingDetailList({ 
        stockCode,
        ...newForm,
      }) : await getStockRatingList({ 
        stockCode,
        ...newForm,
        // ...route.query
      })
      
      dataMap.stockList1 = (data1 as any).data 
      
      if( !props.from ){
        dataMap['textIndustry'] = dataMap.selectedIndustry
      }
      dataMap.loading = false
    }
    
    //搜索按钮
    const onSearch = async () => {

      //自定义时间
      if( dataMap.showTimeSelect ){
        // delete time
        
        dataMap.formInline['beginDate'] = window.moment(dataMap.selectTime[0]).format('YYYY-MM-DD')
        dataMap.formInline['endDate'] = window.moment(dataMap.selectTime[1]).format('YYYY-MM-DD')
      }
      // moment(this.dialogcontent.kssj, 'YYYY-MM-DD kk:mm:ss').format('x')
      // 个股选择后查询必须清空行业选择，两者属于互斥，经产品确定
      if(dataMap.formInline['organizationSName']){
        dataMap.industryActive = -1
        dataMap.textIndustry = ''
        dataMap.selectedIndustry = ''
        dataMap.formInline['industry'] = ''
      }

      if( pagin && pagin.value ){
        pagin.value!.changeCurPage(1)
      }
    }

    //清空
    const onClearForm = async () => {
      dataMap.formInline = {
        pageNum: dataMap.formInline['pageNum'],
        pageSize: dataMap.formInline['pageSize'],
        ...JSON.parse(JSON.stringify(dataMap.formInlineinit))
      }
      dataMap.industryActive = -1

      dataMap.ratingActive = [0]
      dataMap.formInline['rating'] = ''

      dataMap.typeActive = [0]
      dataMap.formInline['type'] = ''

      dataMap.textIndustry = ''
      dataMap.selectedIndustry = ''

      swichItem(0)


      if( refTab ){
        (refTab as any).value.sort('date','descending')
      }

      if( pagin && pagin.value ){
        pagin.value!.changeCurPage(1)
      }
      // fetchData({});

    }
    
    

    

    //时间下拉框
    const handleChange = (obj:any) => {
      if( !obj ){
        obj = dicts.month[Number(route.query.timeLevel)]
      }

      const { timeLevelwz } = obj
      
      if( obj.value !== 4 ){
        dataMap.formInline['timeLevel'] = timeLevelwz
      }else{
        delete dataMap.formInline['timeLevel']
      }
    }
    
    
    //表格机构点击
    const organizationSelect = (organization:any) => {
      dataMap.formInline['organization'] = organization
      onSearch()
    }

    //表格行业点击
    const industrySelect = (industry:any) => {
      dataMap.industryList.map((item:any, idx:any)=>{
        if(item === industry){
          handleIndustryTab(idx, item)
          onSearch()
        }
      })
      //console.log(dataMap.industryMoreList)
      for (const key in dataMap.industryMoreList) {
        (dataMap.industryMoreList[key] as any).map((innerItem:any)=>{
          if(innerItem === industry){
            handleMoreClick(innerItem)
            onSearch()
          }
        })
      }
        
    }

    //行业选择
    const handleIndustryTab = (idx:any, item:any) => {
      console.log(111)
      dataMap['industryActive'] = idx
      dataMap['formInline']['industry'] = item
      dataMap.selectedIndustry = item
      // dataMap['industryLastText'] = ''
    }

    //行业更多选择
    const handleMoreClick = (item:any) => {
      console.log(item)
      dataMap['formInline']['industry'] = item
      dataMap.industryLastText = item
      dataMap.industryActive = -2
      dataMap.selectedIndustry = item
      dataMap.visible = false
    }

    //评级相关选择
    const handleRatingClick = (item:any, idx:any) => {

      // if( idx === 0 ){
      //   dataMap.ratingActive = [0]
      // }else{
      //   let removedArr =  dataMap.ratingActive.filter((x) => x !== 0);
      //   dataMap.ratingActive = removedArr

      //   const findIdx = dataMap.ratingActive.findIndex(item=>item===idx);
      //   if( findIdx !== -1 ){
      //     dataMap.ratingActive.splice(findIdx, 1)
      //   }else{
      //     dataMap.ratingActive.push(idx)
      //   }
      // }

      checkBox('ratingActive', idx)

      
      dataMap.formInline['rating'] = item === '不限' ? '' : item
    }

    //类型选择
    const handleTypeClick = (item:any, idx:any) => {
      
      checkBox('typeActive', idx)

      // dataMap.typeActive = idx
      dataMap.formInline['type'] = item === '不限' ? '' : item
    }

    //评级相关和类型多选
    const checkBox = (active:any, idx:any) => {

      if( idx === 0 ){
        dataMap[active] = [0]
      }else{
        let removedArr =  dataMap[active].filter((x:any) => x !== 0);
        dataMap[active] = removedArr

        const findIdx = dataMap[active].findIndex((item:any)=>item===idx);
        if( findIdx !== -1 ){
          dataMap[active].splice(findIdx, 1)
        }else{
          dataMap[active].push(idx)
        }
      }
    }

    //日期排序
    const sortChange = (column:any) => {
      let order = column.order === "ascending" ? 'ASC' : 'DESC';
      // getTableData2(column["prop"], isAsc);
      fetchData({order, orderBy:column["prop"]});
    }

    //报告名称
    const handleReport = (item:any) => {
      console.log(item)
      
      window.open(`/investment/agency/report?reportId=${item.reportId}`)
      // window['$props'].push({
      //   path: `/investment/agency/report`,
      //   query:{
      //     reportId: item.reportId
      //     // industryName: params['name'],
      //     // isPointOrg: params['seriesIndex']
      //   }
      // })
    }

    //列表跳转详情
    const handleGoDetail = (item:any) => {
      if( !props.from ){
        window.open(`/investment/agency/detail?code=${item.stockCode}`)
      }
    }

    //表格时间选择
    const HandleRowTime = async (item:any) => {
      let data2 = await getReportCalculateGains({ 
        stockCode: item.stockCode,
        beginDate: item.date + ' 23:59:59',
        endDate:   window.moment(item.time).format('YYYY-MM-DD HH:mm:ss')
      })
      item.customIncome = (data2 as any).data
      console.log(data2)
      // dataMap.industryMoreList = (data2 as any).data
    }


    const blurHandler = (res:any) => {
      setTimeout(()=>{
        dataMap.searchList = []
      },200)
    }
    let timer:any = null;
    const fetchSearchData = () => {
      if( timer ){
        clearTimeout(timer)
      }
      timer = setTimeout(async ()=>{
        let data = await getStockQuery({ keyword: dataMap.formInline['organizationSName'], limit: 20})
        let aaa = (data as any).data;
        // aaa = aaa.filter((item:any)=>item.state !== 1 && item.state !== 4)
        dataMap.searchList = aaa;
      },500)
      
    }
    const selectedSearch = (item:any) => {
      dataMap.formInline['organizationSName'] = item.name
      // console.log(item)
    }

    const blurHandler2 = (res:any) => {
      setTimeout(()=>{
        dataMap.searchList2 = []
      },200)
    }
    let timer2:any = null;
    const fetchSearchData2 = () => {
      if( timer2 ){
        clearTimeout(timer2)
      }
      timer2 = setTimeout(async ()=>{
        let data = await getReportOrganizationList({ orgStrLike: dataMap.formInline['organization']})
        let aaa = (data as any).data;
        dataMap.searchList2 = aaa;
      },500)
      
    }
    const selectedSearch2 = (item:any) => {
      dataMap.formInline['organization'] = item
    }

    
  
    return {
      ...toRefs(dataMap),
      refTab,
      pagin,
      fetchData,
      onSearch,
      onClearForm,
      handleChange,
      handleIndustryTab,
      handleMoreClick,
      handleRatingClick,
      handleTypeClick,
      sortChange,
      handleReport,
      remoteMethod,
      handleGoDetail,
      swichItem,
      HandleRowTime,
      dicts,
      blurHandler,
      fetchSearchData,
      selectedSearch,
      blurHandler2,
      fetchSearchData2,
      selectedSearch2,
      moment,
      industrySelect,
      organizationSelect,
      
    }
  }
})
</script>
<style lang='scss' scoped>
@import '@/styles/searchForm.scss';
.app-container{
  &.detail{
    padding: 0;
    .bg{
      padding: 0;
    }
    .bg-1{
      padding-bottom: 0!important;
    }
  }
}
.bg-1{
  padding-bottom: 100px!important;
}
.tab{
    display: inline-block;
    ul{
      display: flex;
      // border: 1px solid #DDDDDD;
      border-radius: 4px;
      overflow: hidden;
      // font-size: 0;
      li{
        width: 52px;
        height: 32px;
        line-height: 32px;
        cursor: pointer;
        background: #FFFFFF;
        text-align: center;
        // border-right: 1px solid #DDDDDD;
        color: #666666;
        border: 1px solid #DDDDDD;
        border-right: none;
        &.active{
          color: #fff;
          background: #386FF2;
          border-color: transparent;
          border-top: none;
          border-bottom: none;
        }
        &:last-child{
          // border: 0;
          border-right: 1px solid #DDDDDD;
          border-radius: 0 4px 4px 0;
        }
        &:first-child{
          // border: 0;
          // border-right: 1px solid #DDDDDD;
          border-radius: 4px 0 0 4px;
        }
      }
    }
}
.empty-from-item{

}

.time-item{
  ::v-deep .el-form-item__content{
    width: auto!important;
  }
}



.fxs{
  color: #FFBA42;
  img{
    vertical-align: middle;
    margin: -4px 2px 0;
  }
}

.search_box{
    width: 100%;
    background: #fff;
    max-height: 670px;
    overflow-y: auto;
    position: absolute;
    top: 50px;
    left: 0;
    z-index: 200;
    // padding: 0 10px;
    display: none;
    /* padding-top: 10px; */
}
.showsearch_box{
    display: block;
}
.search_box_list_box{
    // max-height: 670px;
    box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.2);
    padding-top: 20px;
    .search_box_list{
      overflow-x: hidden;
    }
}
.search_box_item{
    padding: 0 8px;
    margin-bottom: 8px;
    cursor: pointer;
    &.disabled{
      cursor: context-menu;
      .search_box_item_right{
        background: #ccc;
      }
    }
}
.search_box_item_box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    // height: 57px;
    border-bottom: 2px solid #F4F4F4;
}
.search_box_item_right{
    width: 55px !important;
    height: 25px;
    padding: 0;
    margin-right: 0 !important;
    line-height: 25px;
    border-radius: 12px;
    background: #FF423A;
    font-size: 14px;
    text-align: center;
    color: #fff;
    
}
.search_box_item_left_text{
    font-size: 14px;
    color: RGBA(66, 66, 66, 1);
    line-height: 14px;
}
.search_box_item_left_num{
  padding-bottom: 4px;
    font-size: 12px;
    line-height: 20px;
    color:RGBA(170, 170, 170, 1);
    margin-top: 3px;
}
</style>
<style lang='scss'>
.reportName{
  cursor: pointer;
}
.table-picker{
  opacity: 0;
  width: 100%!important;
  height: 20px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  *{
    cursor: pointer;
  }
}
.date-picker{
  width: 220px !important;
}
</style>
