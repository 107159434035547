
import {
  defineComponent,
  toRefs,
  reactive
} from 'vue'

import CPagination from "@/components/pagination/Index.vue"
import CustomTab from '../../components/CustomTab.vue'
import { getBoardIncreaseList } from '@/apis/northboundFunds'
export default defineComponent({
  components: {
    CPagination,
    CustomTab
  },
  props:{
    boardType: {
      type: String,
      default: 'swl1'
    },
  },
  setup(props, context) {
    const dataMap = reactive({
      loading: true,
      stockList: [

      ],
      tableHeader: {
        label: '1日',
        text: '1日'
      }
    })

    let params = {
      // timeLeve: 时间 1d,5d,10d,1m,1q,1y
      boardType: '',
      sortFiled: 'addMarkeyCap',
      sort: 'DESC',
      pageNum: '1',
      pageSize: '10'
    }


    const fetchData = async (value:any) => {

      if( value.pageNum ){
        params['pageNum'] = value.pageNum
      }
      if( value.pageSize ){
        params['pageSize'] = value.pageSize
      }
      if( value.lbValue ){
        dataMap.tableHeader = value
        params['timeLeve'] = value.lbValue
      }
      if( value.boardType ){
        params['boardType'] = value.boardType
      }
      // console.log('cccccccccccccccccccccccccccccc')
      if( params.boardType ){
        dataMap.loading = true
        const list = await getBoardIncreaseList(params)
        dataMap.stockList = (list as any).data;
        dataMap.loading = false
      }

    }

    const sortChange = (column: any) => {
      let isAsc = column.order === "ascending" ? 'asc' : 'desc';
      params['sortFiled'] = column["prop"]
      params['sort'] = isAsc
      fetchData({})
    };

    const goDetail = (item: any) => {
      const path = '/northboundFunds/industry'
      window.open( `/investment${path}?code=${item.name}&boardType=${props.boardType}`)
    };

    const goStock = (code: any) => {
      console.log(code)
      // return false;
      const path = '/northboundFunds/shares'
      window.open( `/investment${path}?code=${code}`)
    };

    const addClass = (column:any) => {
      if( column.column.property === params.sortFiled ){
        return 'sort-bg'
      }
    }


    return {
      ...toRefs(dataMap),
      fetchData,
      sortChange,
      goDetail,
      goStock,
      addClass,
    }
  }
})
