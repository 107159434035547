<template>
  <div
    :id="id"
    :class="className"
    :style="{height: height, width: width}"
  />
</template>

<script lang="ts">
import {
  defineComponent,
  onActivated,
  onBeforeUnmount,
  onDeactivated,
  onMounted,
  nextTick,
  watch,
} from 'vue'
import resize from '@/components/charts/mixins/resize'
import { init, EChartsOption } from 'echarts'
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '350px',
      required: true
    },
    chartData: {
      type: Array,
      default: []
    },
    chartKey: {
      type: String,
      default: ''
    },
    showChartIdx: {
      type: Number,
      default: 1
    },
  },
  setup(props) {

    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()

    watch(props, (newName, oldName) => {
      initChart()
    });

    const initChart = () => {
      if( !props.chartData.length ){
        return false
      }
      let dataList:any = []
      const barChart = init(document.getElementById(props.id) as HTMLDivElement)
      const xAxisList = Array.from((props as any).chartData, ({date})=>date);
      const spjList = Array.from((props as any).chartData, ({close})=>close);
      (props as any).chartData.map((item:any)=>{
        dataList.push(item[props.chartKey])
      })
      const toolKey = {
        rzye : '融资余额(元)',
        rzjmr : '融资净买入(元)',
        rzyezb : '融资余额占流通市值比(%)',
        rzrqye : '融资融券余额(元)',
        rqye : '融券余额(元)',
        rqjmc : '融券净卖出(股)',
      }
      barChart.setOption({
        tooltip: {
          trigger: 'axis',
          formatter: function (param:any) {
            let list = param.length
            let txt = '<div style="padding-bottom:10px;">' + param[0].name + "</div>"
            for (var i=0;i<list;i++){
              txt += `<div> 
                          <span style='width:12px;height:12px;display:inline-block;border-radius:50%;margin-right:3px;background:${param[i].color}'></span>
                          <span style='width:150px;display:inline-block'>${param[i].seriesName}</span>
                          <span style='width:60px;display:inline-block;text-align:right'>${zh(param[i].data)}</span> 
                      </div>`
            }
            return txt
          }
        },
        grid: {
          top: '30px',
          left: '45px',
          right: '10px',
          bottom: '0',
          containLabel: true
        },
        legend: {
          show: false
        },
        xAxis: {
          type: 'category',
          data: xAxisList,
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            margin: 15, //刻度标签与轴线之间的距离
            textStyle: {
              color: '#999',
              fontSize: 12,
            }
          },
        },
        yAxis: [
          
          {
            type: 'value',
            position: 'right',
            // max: maxHoldMarket,
            // min: minHoldMarket,
            axisPointer: {
              type: 'none',
              label: { show: false }
            },
            axisLabel: {
              formatter:function(param: any) {
                return `${Math.abs(param) >= 100000000 ? (param / 100000000).toFixed(2) + '亿' : Math.abs(param) >= 10000 ? (param / 10000).toFixed(2) + '万' : param.toFixed(2) }`
              }
            }
          },
          {
            type: 'value',
            splitLine: {show: false},
            position: 'left',
            name: '单位（元）',
            // max: maxHoldMarket,
            // min: minHoldMarket,
            axisPointer: {
              type: 'none',
              label: { show: false }
            },
            axisLabel: {
              formatter:function(param: any) {
                return `${Math.abs(param) >= 100000000 ? (param / 100000000).toFixed(2) + '亿' : Math.abs(param) >= 10000 ? (param / 10000).toFixed(2) + '万' : param.toFixed(2) }`
              }
            }
          },
        ],
        series: [
          {
            name: toolKey[props.chartKey],
            type: props.showChartIdx === 1 ? 'line' : 'bar',
            symbol: 'none',
            yAxisIndex: 0,
            itemStyle: {
              normal: {
                color: '#3B7FEE',
                lineStyle: {
                  width: 1
                },
                emphasis:{ 
                  lineStyle: {
                    width: 1,
                  },
                }
              }
            },
            data: dataList
          },
          {
            name: '收盘价',
            type: 'line',
            yAxisIndex: 1,
            symbol: 'none',
            itemStyle: {
              normal: {
                color: '#FE9625',
                lineStyle: {
                  width: 1
                },
                emphasis:{ 
                  lineStyle: {
                    width: 1,
                  },
                }
              }
            },
            data: spjList
          },
        ]
      } as EChartsOption)
      chart.value = barChart
    }

    const zh = (param:any) => {
      return `${Math.abs(param) >= 100000000 ? (param / 100000000).toFixed(2) + '亿' : Math.abs(param) >= 10000 ? (param / 10000).toFixed(2) + '万' : param.toFixed(2) }`
    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {}
  }
})
</script>
