
import { defineComponent, reactive, toRefs } from 'vue'
import CPagination from "@/components/pagination/Index.vue"
import NavTitle from '../../components/nav-title.vue'
import { getMarginStockLog } from '@/apis/trading'
import { useRoute  } from "vue-router";
import { bigNumberTransform } from '@/utils/data-format'
export default defineComponent({
  components:{
    CPagination,
    NavTitle
  },
  setup() {
    const route = useRoute();
    const dataMap = reactive({
      stockName: route.query.name,
      timeSelected: {
        text: ''
      },
      stockList : {
        list: []
      }
    })

    const params = {
      timeUnit: '',
      pageSize: 10,
      pageNum: 1,
      stockCode: route.query.code,
      orderBy:'rzjmr',
      order:'DESC'
    }

    const fetchData = async (value:any) => {
      if(value.pageNum){
        params['pageNum'] = value.pageNum
      }
      if(value.pageSize){
        params['pageSize'] = value.pageSize
      }
      const boardDetail = await getMarginStockLog(params)
      if( boardDetail ){
        dataMap.stockList = (boardDetail as any).data;
      }
    }

    const changeTime = async (value:any) => {
      console.log('修改时间了 ', value)
      if( value.value ){
        dataMap.timeSelected = value
        params['timeUnit'] = value.value
        if( value.count !== 'DAYS' ){
          delete params['num']
        }
      }
      if( value.count ){
        params['num'] = value.count
      }
      fetchData({})
    }

    const addClass = (column:any) => {
      if( column.column.property === params.orderBy ){
        return 'sort-bg'
      }
    }

    const sortChange = (column: any) => {
      let isAsc = column.order === "ascending" ? 'ASC' : 'DESC';
      params['orderBy'] = column["prop"]
      params['order'] = isAsc
      fetchData({})
    }

    const amount = (value: any, fixed = 2, unit = '元') => {
      if (unit == '万') {
        value = value * 10000
      } else if (unit == '亿') {
        value = value * 100000000
      }
      if (Math.abs(value / 100000000) >= 1) {
        return (value / 100000000).toFixed(fixed) + '亿'
      }
      if (Math.abs(value / 10_000) >= 1) {
        return (value / 10000).toFixed(fixed) + '万'
      }
      return value
    }

    const goDetail = async (item:any) => {
      window.open(`/investment/marginTrading/industry?code=${item.boardName}`)
    }

    return {
      ...toRefs(dataMap),
      fetchData,
      changeTime,
      addClass,
      sortChange,
      bigNumberTransform,
      amount,
      goDetail
    }
  }
})
