<template>
  <div>
    <el-table
      class="table-style-2"
      :data="stockList.list || []"
      border
      align="center"
      v-loading='loading'
      :cell-class-name='addClass'
      :default-sort ="{prop:'date',order:'descending'}"
      @sort-change="sortChange">
      <el-table-column label="持股日期" prop="date" sortable="custom" align="center" />
      <el-table-column label="日涨幅" align="center" >
        <template #default='{row}'>
          <div v-dClass='row.incr' v-if="row.incr"><template v-if="row.incr[0] !== '-'">+</template>{{ row.incr }}%</div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column label="持股数量（股）" prop="holdShares" sortable="custom" align="center" />
      <el-table-column label="持股市值（元）" prop="holdMarketCap" sortable="custom" align="center" />
      <el-table-column label="持股数量占A股 百分比（%）" prop="totalSharesRatio" sortable="custom" align="center" />
      <el-table-column label="持股比例较5日 均值变化" prop="holdRatioDay5Avg" sortable="custom" align="center" />
    </el-table>
    <CPagination ref='pagin' :showPageSizes='true' :pageSize='20'  :pageData='stockList' @fetchData='fetchData'/>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  toRefs,
  reactive
} from 'vue'
import CPagination from "@/components/pagination/Index.vue"
import CustomTab from '../../components/CustomTab.vue'
import { getFundsStockList } from '@/apis/northboundFunds'
import { useRoute } from "vue-router";
export default defineComponent({
  components: {
    CPagination,
    CustomTab
  },
  setup(props, context) {
    const route = useRoute();
    const dataMap = reactive({
      loading: false,
      stockList: [

      ],
    })

    let params = {
      stockCode: route.query.code,//'000913.SZ',
      timeUnit: '',
      orderBy: 'date',
      order: 'DESC',
      pageNum: '1',
      pageSize: '10'
    }

    const fetchData = async (value:any) => {
      if( value && value.pageNum ){
        params['pageNum'] = value.pageNum
      }
      if( value && value.pageSize ){
        params['pageSize'] = value.pageSize
      }

      dataMap.loading = true
      const list = await getFundsStockList(params)
      dataMap.stockList = (list as any).data;
      dataMap.loading = false
    }

    const sortChange = (column: any) => {
      let isAsc = column.order === "ascending" ? 'ASC' : 'DESC';
      params['orderBy'] = column["prop"]
      params['order'] = isAsc
      fetchData(undefined)
    };

    const addClass = (column:any) => {
      if( column.column.property === params.orderBy ){
        return 'sort-bg'
      }
    }

    return {
      ...toRefs(dataMap),
      fetchData,
      sortChange,
      addClass
    }
  }
})
</script>
