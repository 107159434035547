
import { defineComponent, reactive, toRefs, ref, nextTick } from 'vue'
import NavTitle from '../components/nav-title.vue'
import PageTitle from '../components/page-title.vue'
import DateTab from '../../northbound-funds/components/CustomTab.vue'
import ChartA from './components/ChartA.vue'
import TableA from './components/TableA.vue'
import TableB from './components/TableB.vue'
import CTab from '../components/nav-switch.vue'
import { getMarginTrend, getMarginDetail } from '@/apis/trading'
import { bigNumberTransform } from '@/utils/data-format'
import { useRoute  } from "vue-router";
import { getStockTradeDate } from '@/apis/market'
import moment from 'moment/moment'
export default defineComponent({
  components:{
    NavTitle,
    PageTitle,
    DateTab,
    ChartA,
    TableA,
    TableB,
    CTab,
  },
  setup() {
    const route = useRoute();
    const refTA = ref(null)
    const refTB = ref(null)
    const dataMap = reactive({
      stockDate: '',
      stockName: route.query.code,
      initialStatus: 1,
      showChartIdx: 1,
      showChartClass: 0,
      tabList: [
        {
          id: 1,
          name: route.query.code + '行业持股明细'
        },
        {
          id: 2,
          name: route.query.code + '行业持股历史'
        }
      ],
      stockList: {
        list: []
      },
      chartData : {},
      chartKey : 'rzyeDaily',
      tableData : {},
      boardDetail: {}
    })

    const getTime = async () => {
      const data6 = await getStockTradeDate({ })
      dataMap.stockDate = moment((data6 as any).data.prevRzrqTradeDate).format('YYYY年MM月DD日');
    }
    getTime()

    const fetchData = async () => {
      
      // 图表
      const trendList = await getMarginTrend({ boardType: route.query.boardType, boardName: route.query.code })
      dataMap.chartData = (trendList as any).data;

      // 明细
      const boardDetail = await getMarginDetail({ boardType: route.query.boardType, boardName: route.query.code})
      dataMap.boardDetail = (boardDetail as any).data;
    }
    fetchData()

    const getInitTime = async (val:any) => {
      nextTick(()=>{
        // (refTA as any).value.fetchData({timeLevel: val.lbValue})
        (refTA as any).value.timeChange(val)
      })
    }
    // fetchData()
    
    
    const tabChart = (idx:any, type:any, key:any) => {
      dataMap.showChartClass = idx
      dataMap.showChartIdx = type
      dataMap.chartKey = key
    }

    const changeTab = (item: any) => {
      console.log(item, 'item')
      dataMap.initialStatus = item.id
    }

    return {
      ...toRefs(dataMap),
      tabChart,
      changeTab,
      getInitTime,
      refTA,
      refTB,
      bigNumberTransform
    }
  }
})
