
import { defineComponent, onActivated, onBeforeUnmount, onDeactivated, onMounted, nextTick, watch } from 'vue'
import resize from '@/components/charts/mixins/resize'
import { init, EChartsOption } from 'echarts'
import * as echarts from 'echarts'
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '600px',
      required: true
    },
    chartData: {
      type: Object,
      default: {}
    },
    selectedTime: {
      type: String || Number,
      default: ''
    },
  },
  setup(props) {
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()

    watch(props, (newName, oldName) => {
      initChart()
    });

    const initChart = () => {
      const chartData = JSON.parse(JSON.stringify(props.chartData))
      if( !chartData.list ){
        return false;
      }
      const barChart = init(document.getElementById(props.id) as HTMLDivElement)
      const intervalIncrNumber = chartData.list.map((item:any)=>item.intervalIncrNumber)
      const name = chartData.list.map((item:any)=>item.name)
      
      // const incrTop = chartData.incrTop.map((item:any,idx:number)=>{
      //   return {
      //     value: item.incr,
      //     itemStyle: {
      //         normal: {
      //           color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
      //             offset: 0,
      //             color: '#FD0A2D'
      //           }, {
      //             offset: 1,
      //             color: '#FF4B23'
      //           }])
      //         }
      //     },
      //   }
      // })
    

      barChart.setOption({
        grid: {
          top: '40px',
          left: '0',
          right: '10px',
          bottom: '20px',
          containLabel: true
        },
        legend:{
          selectedMode: false,
          icon: 'rect',
          itemWidth: 8,
          itemHeight: 8,
          // color: ['#F93F41', 'green'],
          textStyle: {
            height: 10,
            rich: {
              a: {
                verticalAlign: 'bottom',
              },
            }
          }
          // padding: [1,2,3,4]
        },
        toolbox: {
          show: true
        },
        tooltip: {

        },
        calculable: true,
        xAxis: [
          {
            type: 'category',
            data: name,
            axisLine: {
                show: true,
                lineStyle: {
                    color: "#333333",
                }
            },
            axisLabel: {
              show: true,
              // rotate: -90,
              // verticalAlign: 'middle', // 垂直居中
              // interval: 0,//使x轴文字显示全
              formatter: function (value:any) {
                return value.split("").join("\n") //垂直显示
              },
            },
            axisTick: {
              show: false
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: { show: false },
            // axisTick: { show: false },
            axisLabel: { show: true }
          }
        ],
        series: [
          {
            name: '变动金额',
            stack: 'total',
            barGap: '80%',
            barWidth: '60%',
            type: 'bar',
            data: intervalIncrNumber,
            itemStyle: {
              normal: {
                label: {
                  // formatter: '{c}',
                  show: true,
                  position: 'top',
                  textStyle: {
                    fontWeight: 'bolder',
                    fontSize: '12',
                    color: '#000'
                  }
                },
                // color: 'green',
                opacity: 1
              }
            },
            color: '#3B7FEE',
            barMinHeight: 70,
          },
        ]
      } as EChartsOption)
      chart.value = barChart


    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {

    }
  }
})
