<!--
 * @Description: 7*24快讯
-->
<template>
  <div class="hot-stock">
    <CTitle
      :mode="1"
      title="热门股票"
      class="hot-title"
    />
    <CTitle
      :mode="1"
      title="突破新高"
      class="new-heights"
    />
    <el-table
        class="table-style-2 cursor"
        :data="!isOpen ? stockList1.slice(0, 10) : stockList1"
        border
        style="width: 100%"
        @row-click='goDetail'
      >
        <el-table-column
          width='100'
          prop="code"
          align="center"
          label="代码"
        >
          <template #default='{row}'>
            <div class="blue">{{row.code.split('.')[0]}}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          align="center"
          label="股票名称"
        >
          <template #default='{row}'>
            <div class="blue">{{row.name}}</div>
          </template>
        </el-table-column>
        <el-table-column
          width='200'
          prop="topDate"
          align="center"
          label="突破时间"
        />
        <el-table-column
          prop="topPrice"
          align="center"
          label="突破高位"
        />
        <el-table-column
          prop="price"
          align="center"
          label="现价"
        />
        <el-table-column
          prop="change"
          align="center"
          label="涨跌幅"
        >
          <template #default='{row}'>
            <div v-dClass='row.change'>
              <template v-if="row.change[0] !== '-'">+</template>{{row.change}}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="turnover"
          align="center"
          label="换手率"
        />
    </el-table>
    <div class="btns" v-if="stockList1.length > 10">
      <div v-if="!isOpen" @click="handleToggle">展开全部<i class="el-icon-arrow-down" /></div>
      <div v-else @click="handleToggle">收起<i class="el-icon-arrow-up" /></div>
    </div>
    <el-row class="row">
      <el-col :span="8">
        <CTitle
          :mode="1"
          title="行业涨幅榜"
        />
        <el-table
          class="table-style-2 cursor"
          :data="stockList2.upList"
          border
          style="width: 100%"
          @row-click='goData'
        >
          <el-table-column
            prop="boardName"
            align="center"
            label="行业名称"
          />
          <el-table-column
            prop="range"
            align="center"
            label="涨幅"
          >
            <template #default='{row}'>
              <div class="red">+{{row.range}}</div>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
      <el-col :span="8" :offset='2'>
        <CTitle
          :mode="1"
          title="行业跌幅榜"
        />
        <el-table
          class="table-style-2 cursor"
          :data="stockList2.downList"
          border
          style="width: 100%"
          @row-click='goData'
        >
          <el-table-column
            prop="boardName"
            align="center"
            label="行业名称"
          />
          <el-table-column
            prop="range"
            align="center"
            label="涨幅"
          >
            <template #default='{row}'>
              <div class="green">{{row.range}}</div>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
import CTitle from '@/components/title/Index.vue'
import { getAggStockChangeData, getAggBoardChangeData } from '@/apis/home'
export default defineComponent({
  components: {
    CTitle,
  },
  setup() {
    const dataMap = reactive({
      isOpen: false,
      list: [],
      stockList1: [],
      stockList2: {},
    })

    const fetchData = async () => {
      const data = await getAggStockChangeData({  })
      dataMap.stockList1 = (data as any).data;

      const data1 = await getAggBoardChangeData({  })
      dataMap.stockList2 = (data1 as any).data;
    }
    fetchData()

    onMounted(() => {
    })

    const handleToggle = () => {
      dataMap.isOpen = !dataMap.isOpen
    }

    //跳转数据中心
    const goData = (row:any) => {
      // console.log(row)
      window.open(`/page/data_center_all?industryName=${row['boardName']}`)
      // dataMap.isOpen = !dataMap.isOpen
    }

    //跳转详情
    const goDetail = (row:any) => {
      window.open(`/investment/home/detail?stockCode=${row.code}`)
    }

    return {
      ...toRefs(dataMap),
      handleToggle,
      goData,
      goDetail
    }
  }
})

</script>
<style scoped lang="scss">
.hot-stock{
  background: #fff;
  padding: 28px 29px 46px;
  margin-bottom: 28px;
  .hot-title{
    margin-bottom: 33px;
  }
  .new-heights{
    margin-bottom: 22px;
    ::v-deep div{
      font-size: 20px;
      text-align: center;
    }
  }
  .btns{
    text-align: center;
    margin-top: 20px;
    color: #1283D5;
    div{
      cursor: pointer;
    }
  }
  .row{
    ::v-deep .mode{
      margin: 38px 0 26px;
      div{
        font-size: 20px;
        text-align: center;
      }
    }
  }
}
</style>
