
import { defineComponent, reactive, toRefs } from 'vue'
import CPagination from "@/components/pagination/Index.vue"
import { getMarginHistoryStock } from '@/apis/trading'
import { bigNumberTransform } from '@/utils/data-format'
import { useRoute  } from "vue-router";
export default defineComponent({
  components:{
    CPagination,
  },
  props: {
    stockList : {
      type: Object,
      default: {}
    },
  },
  setup() {
    const route = useRoute();
    const dataMap = reactive({
      stockList: {
        list: []
      }
    })

    let params = {
      pageNum: 1,
      pageSize: 10,
      boardType: route.query.boardType,
      boardName: route.query.code,
      // timeLevel: ''
    }
    const fetchData = async (obj:any) => {
      if( obj && obj.pageNum ) {
        params.pageNum = obj.pageNum
      }
      if( obj && obj.pageSize ) {
        params.pageSize = obj.pageSize
      }
      // if( obj && obj.timeLevel ){
      //   params.timeLevel = obj.timeLevel
      // }
      // if( !params.timeLevel ){
      //   return false
      // }
      const stockList = await getMarginHistoryStock(params)
      dataMap.stockList = (stockList as any).data;
    }

    const goDetail1 = async (item:any) => {
      console.log(item)
      // return false
      window.open(`/investment/marginTrading/shares?name=${item.rzjmrzbMaxName}&code=${item.rzjmrzbMaxCode}`)
    }
    const goDetail2 = async (item:any) => {
      console.log(item)
      window.open(`/investment/marginTrading/shares?name=${item.rqjmczbMaxName}&code=${item.rqjmczbMaxCode}`)
    }

    // const goDetail1 = async (item:any) => {
    //   console.log(item)
    //   return false
    //   window.open(`/investment/marginTrading/shares?name=长沙银行&code=601577.SH`)
    // }

    return {
      ...toRefs(dataMap),
      fetchData,
      bigNumberTransform,
      goDetail1,
      goDetail2
    }
  }
})
