
import { defineComponent, reactive, toRefs } from 'vue'
import trendChart from './trend-chart.vue'
export default defineComponent({
  props: {
    tableList: {
      type: Array,
      default: []
    }
  },
  components: {
    trendChart
  },
  setup() {
    const dataMap = reactive({
      titleList: [
        {
          id: 1,
          name: '行业'
        },
        {
          id: 2,
          name: '两融余额(元)'
        },
        {
          id: 3,
          name: '融资余额(元)'
        },
        {
          id: 4,
          name: '融资日净买入(元)'
        },
        {
          id: 5,
          name: '融资日净买入额占流通市值（%）'
        },
        {
          id: 6,
          name: '融资周净买入(元)'
        },
        {
          id: 7,
          name: '融资月净买入(元)'
        },
        {
          id: 8,
          name: '趋势图'
        }
      ]
    })
    const amount = (value: any, fixed = 2, unit = '元') => {
      if (unit == '万') {
        value = value * 10000
      } else if (unit == '亿') {
        value = value * 100000000
      }
      if (Math.abs(value / 100000000) >= 1) {
        return (value / 100000000).toFixed(fixed) + '亿'
      }
      if (Math.abs(value / 10_000) >= 1) {
        return (value / 10000).toFixed(fixed) + '万'
      }
      return value
    }
    return {
      ...toRefs(dataMap),
      amount
    }
  }
})

