/*
 * @Description: 定增
 */
import https from '@/utils/https'
import { RequestParams, ContentType, Method } from 'axios-mapper'

//行业分布
export const getFixedIncrBoard = (params: RequestParams) => {
  return https().request('/fixedIncr/board', Method.GET, params, ContentType.form)
}

//类型分布
export const getFixedIncrType = (params: RequestParams) => {
  return https().request('/fixedIncr/type', Method.GET, params, ContentType.form)
}

//定增市值分布
export const getSZFB = (params: RequestParams) => {
  return https().request('/fixedIncr/fixedIncrTotalMarketCapDistribute', Method.GET, params, ContentType.form)
}

//定增收益分布
export const getFixedIncrPartIncr = (params: RequestParams) => {
  return https().request('/fixedIncr/fixedIncrPartIncr', Method.GET, params, ContentType.form)
}

//定增全部数据
export const getFixedIncrList = (params: RequestParams) => {
  return https().request('/fixedIncr/fixedIncrList', Method.GET, params, ContentType.form)
}

//定增行业列表
export const getFixedIncrBoards = (params: RequestParams) => {
  return https().request('/fixedIncr/boards', Method.GET, params, ContentType.form)
}


