

import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    seletedTime:{
      type: String,
      default: ''
    },
    stockList: {
      type: [],
      default: []
    },
  },
  emits: ['sortChangeHandler'],
  setup(_,context) {
    const sortChange = (column:any)=>{
      context.emit('sortChangeHandler', column)
    }

    const handleRowClick = (column:any)=>{
      // console.log(_.seletedTime)

      window.open(`/investment/agency/detail?code=${column.code}&timeLevel=${_.seletedTime}`)
      // context.emit('sortChangeHandler', column.name)
    }

    return {
      sortChange,
      handleRowClick
    }
  }
})
