/*
 * @Description:
 */
import https from '@/utils/https'
import { RequestParams, ContentType, Method } from 'axios-mapper'

//获取个股同行业估值记录
export const getReportRanking = (params: RequestParams) => {
  return https().request('/report/count/ranking', Method.GET, params, ContentType.form)
}

//获取个股同行业估值记录
export const getReportMonthRanking = (params: RequestParams) => {
  return https().request('/report/rating/price/score/month/records', Method.GET, params, ContentType.form)
}

//通过股票代码获取资本回报率记录
export const getReportIndustryRanking = (params: RequestParams) => {
  return https().request('/report/industry/count/ranking', Method.GET, params, ContentType.form)
}

//获取个股评级机构数
export const getReportOrgCountRanking = (params: RequestParams) => {
  return https().request('/report/org/count/ranking', Method.GET, params, ContentType.form)
}


//个股评级价格记录数据
export const getReportPriceDaily = (params: RequestParams) => {
  return https().request('/report/rating/price/daily', Method.GET, params, ContentType.form)
}


//新财富分析师列表
export const getReportFxsList = (params: RequestParams) => {
  return https().request('/report/fxsList', Method.GET, params, ContentType.form)
}

//获取机构评级详情列表
export const getStockRatingDetailList = (params: RequestParams) => {
  return https().request('/report/getStockRatingDetailList', Method.GET, params, ContentType.form)
}

//获取机构评级研报列表
export const getStockRatingList = (params: RequestParams) => {
  return https().request('/report/getStockRatingList', Method.GET, params, ContentType.form)
}

//研报详情
export const getStockReportDetail = (params: RequestParams) => {
  return https().request('/report/stockReportDetail', Method.GET, params, ContentType.form)
}

//机构评级-买入评级
export const getStockReportBuy = (params: RequestParams) => {
  return https().request('/report/stock/report/buy', Method.GET, params, ContentType.form)
}

//机构评级-评级调高
export const getStockReportUp = (params: RequestParams) => {
  return https().request('/report/stock/report/up', Method.GET, params, ContentType.form)
}

//机构评级-首次覆盖
export const getStockReportFirst = (params: RequestParams) => {
  return https().request('/report/stock/report/first', Method.GET, params, ContentType.form)
}

//行业-研报
export const getBoardStockReportDetail = (params: RequestParams) => {
  return https().request('/report/board/report/detail', Method.GET, params, ContentType.form)
}


