
import BoardItem from "./components/BoardItem.vue";
import chartA from "./charts/chartA.vue";
import chartB from "./charts/chartB.vue";
import chartC from "./charts/chartC.vue";
import chartD from "./charts/chartD.vue";
import chartE from "./charts/chartE.vue";
import chartF from "./charts/chartF.vue";
import chartG from "./charts/chartG.vue";
import chartH from "./charts/chartH.vue";
import marketTop from './components/market-overview.vue'
import marketTable from './components/market-table.vue'
import valuationTable from './components/index-valuation-table.vue'

import { getDataCenterBoardUnusualAction, getDataCenterBoardTypes, getDataCenterBoardMvStatistic, getDataCenterBoardIndustryStatistic, getDataCenterMarketTransaction, marginTradingData, getDataCenterMarketOverviewToday, getDataCenterMarketOverviewHistory, northboundFundsTimeSharing, northboundFundsHistory, getStockTradeDate, getCurrentBoardData, getRegionStatistic } from '@/apis/market'

import { stockFormat } from '@/utils/data-format'

import moment from 'moment'

import { defineComponent, reactive, toRefs, ref, onBeforeUnmount } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    BoardItem,
    chartA,
    chartB,
    chartC,
    chartD,
    chartE,
    chartF,
    chartG,
    chartH,
    marketTop,
    marketTable,
    valuationTable
  },
  setup() {
    const router = useRouter();
    const time = ref()
    const dataMap = reactive({
      bktjActiveIdx1: 0,
      bktjActiveIdx2: 0,
      stockList1: {},
      stockList2: [],
      stockList3: null as any,
      stockList4: {},
      stockList5: [],
      stockList6: [],
      stockList7: [],
      stockList8: [],
      zlMaxMinVal: {},
      marginTradingData: {
        dayOnDayData: {}
      },
      tagType: 1,
      northboundFunds: {
        timeSharing: {},
        history: {}
      },
      bxMaxMinVal: {},
      stockDate: '',
      stockBxDate: '',
      currentBoardData: {},
      time: null,
      zcLoading: true
    })
    const northboundFundsFn = async (item: any) => {
      // 北向资金
      // 分时
      if (item === 1) {
        const timeSharingData = await northboundFundsTimeSharing({ })
        if ((timeSharingData as any).data.state === 0) {
          (timeSharingData as any).data.list = []
        }
        dataMap.northboundFunds.timeSharing = (timeSharingData as any).data;
      } else if (item === 2) {
        const historyData = await northboundFundsHistory({ })
        console.log(historyData, 'historyData')
        dataMap.northboundFunds.history = (historyData as any).data;
      }
    }
    const tagClick = (item: any) => {
      dataMap.tagType = item
      northboundFundsFn(item)
    }
    tagClick(1)
    time.value = setInterval(() => {
      let openingTime = new Date().setHours(9,0,0,0)
      let closingTime = new Date().setHours(15,30,0,0)
      let currentTime = Date.now()
      if (currentTime >= openingTime && currentTime <= closingTime) {
        fetchData()
        northboundFundsFn(1)
      } else {
        clearInterval(time.value)
      }
    }, 60000)
    const fetchData = async () => {
      //板块异动
      const data1 = await getDataCenterBoardUnusualAction({ })
      dataMap.stockList1 = (data1 as any).data;

      //板块统计 导航
      const data2 = await getDataCenterBoardTypes({ type: 2 })
      dataMap.stockList2 = (data2 as any).data;
      getIndustry(dataMap.stockList2[dataMap.bktjActiveIdx2])

      //两市成交额
      const data3 = await getDataCenterMarketTransaction({ })
      dataMap.stockList5 = (data3 as any).data;

      //当日涨跌数据
      const data4 = await getDataCenterMarketOverviewToday({ })
      dataMap.stockList6 = (data4 as any).data;

      //历史涨跌数据
      const data5 = await getDataCenterMarketOverviewHistory({ })
      dataMap.stockList7 = (data5 as any).data;

      //获取交易日信息 //statisticDate
      const data6 = await getStockTradeDate({ })
      dataMap.stockDate = moment((data6 as any).data.statisticDate).format('YYYY-MM-DD HH:mm:ss');
      dataMap.stockBxDate = moment((data6 as any).data.prevNbTradeDate).format('YYYY-MM-DD HH:mm:ss');

      const currentBoardList = await getCurrentBoardData({})
      if ((currentBoardList as any).code === 200) {
        dataMap.currentBoardData = (currentBoardList as any).data
        dataMap.zcLoading = false
      }


    }
    fetchData()
    const marginTrading = async () => {
      //融资融券数据
      const tradingData = await marginTradingData({ })
      dataMap.marginTradingData = (tradingData as any).data;
    }
    marginTrading()

    //板块统计 行业统计
    const getIndustry = async (item:any) => {
      // if( dataMap.stockList4[item.type] ){
      //   return false
      // }
      const data4 = await getDataCenterBoardIndustryStatistic({ boardType: item.type })
      dataMap.stockList4[item.type] = (data4 as any).data;
    }

    //板块统计 行业市值 tab切换
    const handleOutNavClick = async (item:any) => {
      if( item.index === '1' && !dataMap.stockList3 ){
        //请求板块统计  市值统计
        const data = await getDataCenterBoardMvStatistic({ })
        dataMap.stockList3 = (data as any).data;
      }

      console.log(item.index)

      if( item.index === '2' && !dataMap.stockList8.length ){
        //请求板块统计  市值统计
        const data = await getRegionStatistic({ })
        dataMap.stockList8 = (data as any).data;
      }

    }

    //板块统计 行业 二级导航 tab切换
    const handleInnerNavClick = async (item:any) => {
      getIndustry(dataMap.stockList2[item.index])
    }

    //获取总览最大最小值
    const getZlMaxMinVal = (obj:any) => {
      dataMap.zlMaxMinVal = obj
    }
    //获取总览最大最小值
    const getbxVal = (obj:any) => {
      dataMap.bxMaxMinVal = obj
    }
    const styleMath = (item: any) => {
      if( item ){
        const itemNumber = Number(item.slice(0, item.length - 1))
        return itemNumber < 0 ?  'color: #5CBC7C' : itemNumber > 0 ?  'color: #F74E4F' : 'color: #606266'
      }
    }
    const styleMathe = (item: any) => {
      if( item ){
        const itemNumber = item
        return itemNumber < 0 ?  'color: #5CBC7C' : itemNumber > 0 ?  'color: #F74E4F' : 'color: #606266'
      }
    }
    const goMore = () => {
      router.push(`/northboundFunds/index`)
    }
    const goMoreMargin = () => {
      router.push(`/marginTrading/index`)
    }
    onBeforeUnmount(() => {
      clearInterval(time.value)
    })


    return {
      ...toRefs(dataMap),
      stockFormat,
      getZlMaxMinVal,
      tagClick,
      handleOutNavClick,
      handleInnerNavClick,
      getbxVal,
      styleMath,
      styleMathe,
      goMore,
      goMoreMargin
    };
  },
});
