
import { defineComponent, reactive, toRefs, nextTick } from 'vue'
import dateComponent from '../../../components/timeLevel/Index.vue'
import titleName from './component/title.vue'
import tableComponent from './component/table.vue'
import netTable from './component/net-purchase-table.vue'
import compositeChart from './component/composite-chart.vue'
import dailyChart from './component/daily-fund-chart.vue'
import purchaseChart from './component/purchase-chart.vue'
import sellChart from './component/sell-out-chart.vue'
import { getReportSummaryDate } from '@/apis/northboundFunds'
import { getReportSummary, getBoard, getDaily, getAddHold, getSubHold, getSubTen, getUpTen, getDownTen } from '@/apis/margin-trading'
import moment from 'moment'
import html2canvas from 'html2canvas';
import QRCode from 'qrcodejs2-fix';
import purchaseTable from './component/purchase-table.vue'
import netTableComponent from './component/net-table.vue'

import { ElMessage } from 'element-plus'
export default defineComponent({
  components: {
    titleName,
    tableComponent,
    netTable,
    compositeChart,
    dailyChart,
    purchaseChart,
    sellChart,
    purchaseTable,
    netTableComponent,
    dateComponent
  },
  setup() {
    // const router = useRouter();
    const dataMap = reactive({
      domian: window.location.href === 'https://tmgr.jufxt.com/investment/marginTrading/share-page' ? 's.jfinfo.com/zm6VTm' : window.location.href === 'https://mgr.jufxt.com/investment/marginTrading/share-page' ? 's.jfinfo.com/zm6VNm' : window.location.href,
      shuiyin:'',
      logoSrc:'',
      reportData: [],
      time: '',
      flowData: [],
      renderImgSrc: '',
      flowAddHoldData: [], // 买入
      flowSubHoldData: [], // 卖出
      boardData: {}, // 陆股通近30天资金流向与上证指数走势
      dailyData: [], // 陆股通行业日资金流向
      upData: [],
      downData: [],
      timeLevel: 'DAY',
      chartShow: [true, true, true, true],
    })
    //"DAY", "WEEK", "MONTH", "QUARTER", "YEAR"
    let date = ''
    const fetchData = async () => {
      const reportList = await getReportSummary({ timeLevel: dataMap.timeLevel, date })
      if ((reportList as any).code === 200) {
        dataMap.reportData = (reportList as any).data
      }
      const reportDateList = await getReportSummaryDate({timeLevel: dataMap.timeLevel, date})
      if ((reportDateList as any).code === 200) {
        dataMap.time = moment((reportDateList as any).data.prevRzrqTradeDate).format('YYYYMMDD');
      }
      const flowList = await getSubTen({timeLevel: dataMap.timeLevel, date})
      if ((flowList as any).code === 200) {
        dataMap.flowData = (flowList as any).data;
      }
      const flowAddHoldList = await getAddHold({timeLevel: dataMap.timeLevel, date})
      if ((flowAddHoldList as any).code === 200) {
        dataMap.flowAddHoldData = (flowAddHoldList as any).data;
      }
      const flowSubHoldList = await getSubHold({timeLevel: dataMap.timeLevel, date})
      if ((flowSubHoldList as any).code === 200) {
        dataMap.flowSubHoldData = (flowSubHoldList as any).data;
      }
      const flowBoardList = await getBoard({timeLevel: dataMap.timeLevel, date})
      if ((flowBoardList as any).code === 200) {
        dataMap.boardData = (flowBoardList as any).data;
      }
      const flowDailyList = await getDaily({ timeLevel: dataMap.timeLevel, date })
      if ((flowDailyList as any).code === 200) {
        dataMap.dailyData = (flowDailyList as any).data;
      }
      const upList = await getUpTen({timeLevel: dataMap.timeLevel, date})
      if ((upList as any).code === 200) {
        dataMap.upData = (upList as any).data;
      }
      const downList = await getDownTen({timeLevel: dataMap.timeLevel, date})
      if ((downList as any).code === 200) {
        dataMap.downData = (downList as any).data;
      }
      // refreshChart(-1)
    }

    const refreshChart = (idx:any) => {
      if( idx === 0 || idx === -1 ){
        dataMap.chartShow = [false, true, true, true]
        nextTick(()=>{
          dataMap.chartShow = [true, true, true, true]
        })
      }
      if( idx === 1 || idx === -1 ){
        dataMap.chartShow = [true, false, true, true]
        nextTick(()=>{
          dataMap.chartShow = [true, true, true, true]
        })
      }
      if( idx === 2 || idx === -1 ){
        dataMap.chartShow = [true, true, false, true]
        nextTick(()=>{
          dataMap.chartShow = [true, true, true, true]
        })
      }
      if( idx === 3 || idx === -1 ){
        dataMap.chartShow = [true, true, true, false]
        nextTick(()=>{
          dataMap.chartShow = [true, true, true, true]
        })
      }
    }
    // fetchData()


    const getDate = (time:any) => {
      if( time.hasOwnProperty('custom') ){
        date = time.custom
      }else{
        dataMap.timeLevel = time.val
      }
      fetchData()
    }

    const exportImg = () => {
      const capture = document.querySelector('#capture') as HTMLElement
      html2canvas(capture).then((canvas) => {
        let imgName = '融资融券'
        let a = document.createElement('a')
        a.style.display = 'none'
        let blob = dataURLToBlob(canvas.toDataURL('image/png'))
        a.setAttribute('href',URL.createObjectURL(blob))
        a.setAttribute('download',imgName+'.png')
        document.body.appendChild(a)
        a.click()
        URL.revokeObjectURL(blob as any)
        document.body.removeChild(a)
      });
    }

    const dataURLToBlob = (dataUrl:any) => {
      let arr = dataUrl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while(n--){
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr],{type:mime})
    }

    const copyDomain = () => {
      let url = dataMap.domian;
      let oInput = document.createElement('input');
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      document.execCommand("Copy"); // 执行浏览器复制命令

      ElMessage({
        message: '复制成功',
        type: 'success',
      })
    }

    const _isMobile = () => {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    }

    const getBase64 = (url:any, callback:any) => {
      var Img = new Image(),
        dataURL = '';
      Img.src = url +"?v=" + Math.random();
      Img.setAttribute("crossOrigin", 'Anonymous')
      Img.onload = function () {
        var canvas = document.createElement("canvas"),
          width = Img.width,
          height = Img.height;
        canvas.width = width;
        canvas.height = height;
        if( canvas ){
          (canvas as any).getContext("2d").drawImage(Img, 0, 0, width, height);
          dataURL = canvas.toDataURL('image/png');
          callback ? callback(dataURL) : null;
        }
      };
    }
    getBase64('https://jfinfo.oss-cn-beijing.aliyuncs.com/shuiyin.png', (data:any) => {
      dataMap.shuiyin = data
    })
    getBase64(require('./images/logo.png'), (data:any) => {
      dataMap.logoSrc = data
    })

    if( _isMobile() ){
      const meta = document.getElementsByTagName('meta')['viewport']
      meta.content = ''
    }


    const fallback = () => {
      window.open(`/investment/marginTrading/index`)
    }

    const generateCode = () => {
      nextTick(() => {
        new QRCode(document.getElementById("imgContainer") as HTMLElement, {
          text: window.location.href,
          width: '100',
          height: '100',
        });
      })
    }
    generateCode()



    return {
      ...toRefs(dataMap),
      exportImg,
      copyDomain,
      _isMobile,
      fallback,
      getDate,
      refreshChart
    }
  }
})

