/*
 * @Description: 估值分析相关接口
 */
import { RootObject } from '@/model/rootObject'
import { UserInfoModel, Users } from '@/model/userModel'
import https from '@/utils/https'
import { LoginModel } from '@/views/user-manager/login/model/loginModel'
import { RequestParams, ContentType, Method } from 'axios-mapper'

//获取个股同行业估值记录
export const getIndustryRecords = (params: RequestParams) => {
  return https().request('/valuation/stock/same/industry/valuation/records', Method.GET, params, ContentType.form)
}

//通过股票代码获取PE/PE分位Band每日数据
export const getStockBandDaily = (params: RequestParams) => {
  return https().request('/valuation/stock/band/daily', Method.GET, params, ContentType.form)
}

//通过股票代码获取资本回报率走势图
export const getStockRating = (params: RequestParams) => {
  return https().request('/valuation/stock/return/rating', Method.GET, params, ContentType.form)
}

//通过股票代码获取资本回报率记录
export const getStockIndustryRecords = (params: RequestParams) => {
  return https().request('/valuation/stock/same/industry/return/records', Method.GET, params, ContentType.form)
}

// 查询股票估值列表
export const getStockIndustryList = (params: RequestParams) => {
  return https().request('/valuation/stock/list', Method.GET, params, ContentType.form)
}

// 查询行业估值列表
export const getStockBoardList = (params: RequestParams) => {
  return https().request('/valuation/board/list', Method.GET, params, ContentType.form)
}

// 查询股票PE-TTM每日数据
export const getStockPettmDaily = (params: RequestParams) => {
  return https().request('/valuation/stock/pettm/daily', Method.GET, params, ContentType.form)
}
