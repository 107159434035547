<template>
  <div>
    <!-- <div class="list">
      <div class="item">
        <span class="circle" />
        <span class="texxt">机构来访量</span>
      </div>
      <div class="item">
        <span class="circle" />
        <span class="texxt">重点机构来访量</span>
      </div>
    </div> -->
    <div class="content">
      <div class="left">
        <div class="des">
          目标均价
        </div>
        <div class="num" >
          {{chartData.avgGoalPrice ? chartData.avgGoalPrice.toFixed(2) : 0}}
        </div>
        <div class="des">
          上涨空间
        </div>
        <div class="num">
          {{$stock.numFilter(chartData.upwardSpace)}}
        </div>
      </div>
      <div class="right">
        <div
          :id="id"
          :class="className"
          :style="{height: height, width: width}"
        />
        <div class="content_item_timebox" v-if="chartData.dailyRecords && chartData.dailyRecords.length">
          <div>{{chartData.dailyRecords[0].date}}</div>
          <div>{{chartData.dailyRecords[chartData.dailyRecords.length-1].date}}</div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onActivated, onBeforeUnmount, onDeactivated, onMounted, nextTick, watch } from 'vue'
import resize from './mixins/resize'
import { init, EChartsOption } from 'echarts'
import * as echarts from 'echarts'
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '170px',
      required: true
    },
    chartData: {
      type: Object,
      default: {}
    }
  },
  setup(props) {
    watch(props, (newName, oldName) => {
      initChart()
    });
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()
    const initChart = () => {
      const barChart = init(document.getElementById(props.id) as HTMLDivElement)

      let map = props.chartData.dailyRecords,
                xdata = [],
                seriesData1 = [],
                seriesData2 = [],
                seriesData3 = [],
                seriesData4 = [];
      if( !map ){
        return false;
      }
      for (let index = 0; index < map.length; index++) {
        // const element = array[index];
        xdata.push(map[index].date)
        seriesData1.push(map[index].stockPrice)
        seriesData2.push(map[index].avgGoalPrice)
        seriesData3.push(map[index].maxGoalPrice)
        seriesData4.push(map[index].minGoalPrice)
      }
      barChart.setOption({
        grid: {
          containLabel: true,
          bottom:0,
          top:10,
          left:0,
          right:0
        },
        tooltip: {
          show: true,
          trigger: 'axis', 
          position:'inside',
          // padding:4,
          textStyle:{
            color:'#999999',
            fontSize:'10',
          },
          // positio1n:function(point,params,dom,rect){
          //   let x = 0
          //   let y= 0
          //   if(point[0]>210){
          //     x = point[0] - 100
          //   }else{
          //     x = point[0] +10
          //   }
          //   if(point[1]>55){
          //     y = point[1] - 60
          //   }else{
          //     y = point[1]
          //   }
          //   return [x,y]
          // },
          // formatter:function(param:any){
          //  // 利用循环更改显示的内容
          //   let list = param.length
          //   let Oparam=param
          //   let txt =" "+ param[0].name+"\n"
          //   for (var i=0;i<list;i++){
          //    if(i==list-1){
          //     txt +=param[i].marker+ param[i].seriesName + ":" + param[i].data
          //    }else{
          //      txt +=param[i].marker+ param[i].seriesName + ":" + param[i].data + '\n'
          //    }
          //   }
          //   return txt
          // }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xdata,
          show:  false,
          axisLine:{
            lineStyle:{
              color: '#BFC0C4',
              width:1,
            }
          },
          axisLabel:{
            // interval:Math.floor(list1.length/3),
            interval:2,
            formatter:function(val:any){      
              return ''
            },
            margin:20,
            textStyle:{
              
            }
          }
        },
        yAxis: {
          show:  false,
          axisLabel : {
              formatter: function(){
                  return "";
              }
          }
          // x: 'center',
          // type: 'value',
          // splitLine: {
          //   lineStyle: {
          //     // type: 'dashed'
          //     color: '#e6e6e6',
          //     width:1,
          //   }
          // },
          // axisLabel : {
          //   textStyle: {
          //       color: '#0086FB'
          //   }
          // },
          // min:18,
          // max:38,
          // interval:2,
          // show: false
        },
        series: [{
          name: '最高目标价',
          type: 'line',
          smooth: false,
          showSymbol:false,
          itemStyle:{
            normal:{
              lineStyle:{
                color:'transparent'
              },
            }
          },
          data:seriesData3
        },{
          name: '目标均价',
          type: 'line',
          smooth: false,
          showSymbol:false,
          itemStyle:{
            normal:{
              lineStyle:{
                color:'red'
              },
            }
          },
          data:seriesData2
        },{
          name: '股价',
          type: 'line',
          smooth: false,
          showSymbol:false,
          itemStyle:{
            normal:{
              lineStyle:{
                color:'#0285FA'
              },
            }
          },
          areaStyle:{
            normal: {
              color: {
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                    offset: 0,
                    color: "#BCDEFD" // 0% 处的颜色
                  }, {
                    offset:  1,
                    color: "#F9FBFF" // 100% 处的颜色
                  }],
    
                  }
                }
          },
          data:seriesData1
        },{
          name: '目标最低价',
          type: 'line',
          smooth: false,
          showSymbol:false,
          itemStyle:{
            normal:{
              lineStyle:{
                color:'transparent'
              },
            }
          },
          data:seriesData4
        }]
      } as EChartsOption)
      chart.value = barChart
    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {

    }
  }
})
</script>

<style lang="scss" scoped>
// .list{
//   display: flex;
//   .item{
//     margin-right: 30px;
//     &:last-child{
//       .circle{
//         background: #3C7FEE;
//       }
//     }
//     .circle{
//       width: 12px;
//       height: 12px;
//       background: #FF9625;
//       border-radius: 50%;
//       display: inline-block;
//       margin-right: 8px;
//     }
//   }
// }
.content{
  display: flex;
  text-align: center;
  margin-top: 70px;
  .left{
    width: 151px;
    height: 191px;
    background: #F6F8FB;
    margin-right: 0;
    .des{
      font-size: 18px;
      // font-family: PingFang;
      font-weight: 400;
      color: #999999;
      &:nth-child(1){
        margin: 20px 0 14px;
      }
      &:nth-child(3){
        margin: 33px 0 14px;
      }
    }
    .num{
      font-size: 20px;
      // font-family: PingFang;
      font-weight: 500;
      color: #FB160C;
      &:nth-child(4){
        // padding-bottom: 22px;
      }
    }
  }
  .right{
    // height: 191px;
    flex: 1;
    margin-left: 1%;
  }
}
.content_item_timebox{
  margin-left: 20px;
}
</style>
