

import { defineComponent, reactive, toRefs, ref } from 'vue'
import PageTitle from '../components/PageTitle.vue'
import Search from '@/components/search-input/Index.vue'
import DayTable from './components/DayTable.vue'
import HistoryTable from './components/HistoryTable.vue'
import { getStockTradeDate } from '@/apis/market'
import moment from 'moment'

export default defineComponent({
  components: {
    PageTitle,
    Search,
    DayTable,
    HistoryTable
  },
  setup() {
    
    const dataMap = reactive({
      stockDate: '',
      
    })
    const getTime = async () => {
      const data = await getStockTradeDate({ })
      if( (data as any).data.timestamp ){
        dataMap.stockDate = moment((data as any).data.timestamp).format('YYYY年MM月DD日');
      }
    }
    getTime()
    return { ...toRefs(dataMap) }
  }
})
