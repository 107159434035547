
import pagingComponent from '@/components/pagination/Index.vue'
import { defineComponent, reactive, toRefs } from 'vue'
import { getStockRank } from '@/apis/margin-trading'
import sharesTable from './shares-table-form.vue'
export default defineComponent({
  props: {
    tableList: {
      type: Array,
      default: []
    }
  },
  components: {
    pagingComponent,
    sharesTable
  },
  setup(props) {
    const dataMap = reactive({
      loading: true,
      tableData: {},
      pageItem: {},
      navItem: {},
      tabItem: {}
    })
    let params = {
      pageNum: '1',
      pageSize: '10',
      stockMarket: 'ALL',
      timeUnit: 'DAYS',
      num: 1,
    }
    const fetchData = async (value:any, sharesTimeItem: any, sharesNavItem: any) => {
      dataMap.pageItem = value
      dataMap.navItem = sharesNavItem
      dataMap.tabItem = sharesTimeItem
      if( value.pageNum ){
        params['pageNum'] = value.pageNum
      }
      if( value.pageSize ){
        params['pageSize'] = value.pageSize
      }
      if(sharesNavItem){
        params['stockMarket'] = sharesNavItem.code
      }
      if(sharesTimeItem){
        params['timeUnit'] = sharesTimeItem.timeUnit
        params['num'] = sharesTimeItem.num
      }
      dataMap.loading = true
      const statisticsList = await getStockRank(params)
      dataMap.tableData = (statisticsList as any).data;
      dataMap.loading = false
    }
    const headerMethod = (item: any) => {
      const { row, columnIndex } = item
      if (row[0].level == 1) {
        row[11].colSpan = 0
        row[12].colSpan = 2
        if (columnIndex === 13) {
          return { display: 'none' }
        }
      }
    }
    const amount = (value: any, fixed = 2, unit = '元') => {
      if (unit == '万') {
        value = value * 10000
      } else if (unit == '亿') {
        value = value * 100000000
      }
      if (Math.abs(value / 100000000) >= 1) {
        return (value / 100000000).toFixed(fixed) + '亿'
      }
      if (Math.abs(value / 10_000) >= 1) {
        return (value / 10000).toFixed(fixed) + '万'
      }
      return value
    }

    const getSearchParams = (val:any) => {
      params['conditions'] = val
      fetchData(dataMap.pageItem, dataMap.tabItem, dataMap.navItem)
    }

    const goDetail = (item: any, index: any) => {
      console.log(item, 'item', index.label, 'index')
      if (index.label === '个股名称') {
        const path = '/marginTrading/shares'
        window.open( `/investment${path}?name=${item.stockName}&code=${item.stockCode}`)
      } else if (index.label === '所属行业') {
        const path = '/marginTrading/industry'
        window.open( `/investment${path}?code=${item.boardName}&boardType=swl2`)
      }
    };
    return {
      ...toRefs(dataMap),
      fetchData,
      headerMethod,
      amount,
      goDetail,
      getSearchParams
    }
  }
})
