/*
 * @Description: 市场综述接口
 */
import https from '@/utils/https'
import { RequestParams, ContentType, Method } from 'axios-mapper'

//三大指数
export const getIndexSummary = (params: RequestParams) => {
  return https().request('/index/summary', Method.GET, params, ContentType.form)
}

//三大指数
export const getMarketSummaryBoardIncr = (params: RequestParams) => {
  return https().request('/market/summary/board/incr', Method.GET, params, ContentType.form)
}

//概念题材涨跌回顾
export const getMarketConcept = (params: RequestParams) => {
  return https().request('/market/summary/concept/incr', Method.GET, params, ContentType.form)
}

//宽基指数
export const getKjSummary = (params: RequestParams) => {
  return https().request('/index/kj/summary', Method.GET, params, ContentType.form)
}



//牛熊股回顾
export const getStockRankList = (params: RequestParams) => {
  return https().request('/stock/rank/list', Method.GET, params, ContentType.form)
}

//主力资金
export const getStockFlow = (params: RequestParams) => {
  return https().request('/market/summary/main_funds/stock/flow', Method.GET, params, ContentType.form)
}

//换手率
export const getStockTurnover = (params: RequestParams) => {
  return https().request('/market/summary/turnover', Method.GET, params, ContentType.form)
}

//换手率
export const getNorthboundFlow = (params: RequestParams) => {
  return https().request('/market/summary/northbound/stock/flow', Method.GET, params, ContentType.form)
}

//融资融券动向
export const getMarginFlow = (params: RequestParams) => {
  return https().request('/market/summary/margin/stock/flow', Method.GET, params, ContentType.form)
}


