
import { defineComponent, onActivated, onBeforeUnmount, onDeactivated, onMounted, nextTick, watch } from 'vue'
import resize from './mixins/resize'
import { init, EChartsOption } from 'echarts'
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '250px',
      required: true
    },
    chartData: {
      type: Object,
      default: {}
    },
    selectedTime: {
      type: String || Number,
      default: ''
    },
  },
  setup(props) {
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()

    watch(props, (newName, oldName) => {
      initChart()
    });

    const initChart = () => {
      if( !props.chartData.researchRatings ){
        return false;
      }
      const barChart = init(document.getElementById(props.id) as HTMLDivElement)
      let xData = [];
      let serviesData1 = [];
      let serviesData2 = [];
      for (let index = 0; index < props.chartData.researchRatings.length; index++) {
        xData.push(props.chartData.researchRatings[index].code)
        serviesData1.push(props.chartData.researchRatings[index].orgResearchCount)
        serviesData2.push(props.chartData.researchRatings[index].focusOrgResearchCount)
      }
      
      barChart.setOption({
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          top: '80px',
          left: '151px',
          right: '0%',
          bottom: '0%',
          containLabel: true
        },
        legend: {
          // show: false,
          data: ['机构来访量', '重点机构来访量'],
          icon: 'circle',
          x: '0',
          left: '0',
          top: '0',
          itemGap: 30,
          textStyle: {
            align: 'left', // 这个是可以左右调整的
            fontSize: 14,// 字体大小
            color:'#999999'
            // padding:[0,0,25,-45]//主要就是用这个，可以把文字上下调整
            // color: 'rgba(255,255,255,0.65)'//字体颜色
          }
        },
        toolbox: {
          show: true
          // feature: {
          //   dataView: { show: true, readOnly: false },
          //   magicType: { show: true, type: ['line', 'bar'] },
          //   restore: { show: true },
          //   saveAsImage: { show: true }
          // }
        },
        calculable: true,
        xAxis: [
          {
            type: 'category',
            // prettier-ignore
            data: xData,
            // splitLine: { show: false },
            // axisLabel:{show:false},
            axisTick: { show: false },
            axisLine: { show: false },
            axisLabel: {
              //x轴文字的配置
              show: true,
              interval: 0,//使x轴文字显示全
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: { show: false },
            // axisTick: { show: false },
            axisLabel: { show: false }
          }
        ],
        series: [
          {
            name: '机构来访量',
            barGap: '80%',
            barWidth: 15, // 柱子宽度
            type: 'bar',
            data: serviesData1,
            itemStyle: {
              normal: {
                barBorderRadius: [4, 4, 0, 0],
                label: {
                  formatter: '{c}' + '次',
                  show: true,
                  position: 'top',
                  textStyle: {
                    fontWeight: 'bolder',
                    fontSize: '12',
                    color: '#FF9626'
                  }
                },
                color: '#FF9626',
                opacity: 1
              }
            }
            // markPoint: {
            //   data: [
            //     { type: 'max', name: 'Max' },
            //     { type: 'min', name: 'Min' }
            //   ]
            // },
            // markLine: {
            //   data: [{ type: 'average', name: 'Avg' }]
            // }
          },
          {
            name: '重点机构来访量',
            type: 'bar',
            barWidth: 15, // 柱子宽度
            data: serviesData2,
            // data: [
            //   2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3, 2.3, 2.3
            // ],
            itemStyle: {
              normal: {
                barBorderRadius: [4, 4, 0, 0],
                label: {
                  formatter: '{c}' + '次',
                  show: true,
                  position: 'top',
                  textStyle: {
                    fontWeight: 'bolder',
                    fontSize: '12',
                    color: '#466EEA'
                  }
                },
                color: '#466EEA',
                opacity: 1
              }
            }
            // markPoint: {
            //   data: [
            //     { name: 'Max', value: 182.2, xAxis: 7, yAxis: 183 },
            //     { name: 'Min', value: 1222.7, xAxis: 11, yAxis: 3 }
            //   ]
            // },
            // markLine: {
            //   data: [{ type: 'average', name: 'Avg' }]
            // }
          }
        ]
      } as EChartsOption)
      chart.value = barChart

      barChart.on('click', (params:any) => {
        console.log(params)
        window.open(`/investment/research/list?industryName=${params['name']}&isPointOrg=${params['seriesIndex']}&time=${props.selectedTime}`)
        // window['$props'].push({
        //   path: `/investment/research/list`,
        //   query:{
        //     industryName: params['name'],
        //     isPointOrg: params['seriesIndex'],
        //     time: props.selectedTime
        //   }
        // })
      })
    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {

    }
  }
})
