
import { defineComponent, reactive, toRefs } from 'vue'
import CTitle from "@/components/title/Index.vue"
import RadarChart from "@/components/charts/RadarChart.vue"
import Technical from "./components/aspect/Technical.vue"
import Basic from "./components/aspect/Basic.vue"
import Capital from "./components/aspect/Capital.vue"
import LineChartF from '@/components/charts/LineChartF.vue'
// import LineChartJ from '@/components/charts/LineChartJ.vue'
import LineChartD from '@/components/charts/LineChartD.vue'
import BarChartA from '@/components/charts/BarChartA.vue'
import BarChartC from '@/components/charts/BarChartC.vue'
import BarChartE from '@/components/charts/BarChartE.vue'
import BarChartH from '@/components/charts/BarChartH.vue'
import SurveyOrgRanking from "@/views/research/components/SurveyOrgRanking.vue"
import Search from '@/components/search-input/Index.vue'

import CSelect from "@/components/select/Index.vue"
import { getDiagnosticAssessment, getStockRealtime, getValuationDesc, getValuationDaily, getValuationQuantileDaily, getReportRanking, getReportPriceDaily } from '@/apis/diagnosis'
import { useRoute } from 'vue-router'
import useClipboard from 'vue-clipboard3';
import { ElMessage } from 'element-plus'
// import { ArrowRight } from '@element-plus/icons-vue'

export default defineComponent({
  components: {
    CTitle,
    CSelect,
    RadarChart,
    LineChartF,
    LineChartD,
    // LineChartJ,
    BarChartA,
    BarChartC,
    BarChartE,
    BarChartH,
    Technical,
    Basic,
    Capital,
    SurveyOrgRanking,
    Search
  },
  setup() {
    const route = useRoute()
    const stockCode = route.query.code//'600519.SH'
    const dataMap = reactive({
      ajaxRes:false,
      activeIdx: 1,
      activeAnalysisIdx: 0,
      stockInfo: {},
      stockList1: {},
      stockList2: {},
      stockList3: {},
      stockList4: [],
      stockList5: {},
      stockList6: {},
      stockList7: {},
      // stockList8: {},
      stockList5ChartData: {},
      assessmentInfo:{}
    })
    
    const fetchData = async () => {
      const data1 = await getDiagnosticAssessment({ stockCode: stockCode })
      dataMap.stockList1 = (data1 as any).data;

      let assessmentInfo = (data1 as any).data
      if( assessmentInfo ){
        gettext(assessmentInfo,'cashFlowScore')
        gettext(assessmentInfo,'growUpScore')
        gettext(assessmentInfo,'operationScore')
        gettext(assessmentInfo,'profitabilityScore')
        gettext(assessmentInfo,'ranking')
        gettext(assessmentInfo,'valueScore')
        gettext(assessmentInfo,'valuationScore')
        dataMap.assessmentInfo = assessmentInfo
      }
      
      
      const data2 = await getStockRealtime({ stockCode: stockCode })
      dataMap.stockList2 = (data2 as any).data;

      const data3 = await getValuationDesc({ stockCode: stockCode })
      dataMap.stockList3 = (data3 as any).data;

      // let data4 = await getValuationDaily({ stockCode: stockCode,timeLevel: '5y' })
      // dataMap.stockList4 = (data4 as any).data;

      // let data5 = await getValuationQuantileDaily({ stockCode: stockCode,timeLevel: '5y' })
      // dataMap.stockList5 = (data5 as any).data;

      // let data8 = await getStockInfo({ stockCode: stockCode })
      // dataMap.stockInfo = (data8 as any).data;

      // let data6 = await getReportRanking({ stockCode: stockCode,timeLevel: '1m' })
      // dataMap.stockList6 = (data6 as any).data;

      // let data7 = await getReportPriceDaily({ stockCode: stockCode,timeLevel: '5y' })
      // dataMap.stockList7 = (data7 as any).data;

      // let data8 = await getSurveyOrgRanking({ stockCode: stockCode })
      // dataMap.stockList8 = (data8 as any).data;

      dataMap.ajaxRes = true;
    }
    const gettext = (obj:any,text:any) => {
      obj[text+'text'] = obj[text].split('|')[0]
      obj[text+'color'] = obj[text].split('|')[1]
      return obj
    }

    const swichItem=(item:any)=>{
      dataMap.activeIdx = item
    }

    const swichAnalysisItem=(idx:any)=>{
      dataMap.activeAnalysisIdx = idx
          console.log(11111,dataMap.stockList5)
      if( idx ){
        if( idx === 1 ){
          dataMap.stockList5ChartData = {
            max: dataMap.stockList5['peMax'],
            mid: dataMap.stockList5['peMid'],
            min: dataMap.stockList5['peMin'],
            data: dataMap.stockList5['peRecords']
          }
          
        }else if( idx === 2 ){
          dataMap.stockList5ChartData = {
            max: dataMap.stockList5['pbMax'],
            mid: dataMap.stockList5['pbMid'],
            min: dataMap.stockList5['pbMin'],
            data: dataMap.stockList5['pbRecords']
          }
        }else if( idx === 3 ){
          dataMap.stockList5ChartData = {
            max: dataMap.stockList5['psMax'],
            mid: dataMap.stockList5['psMid'],
            min: dataMap.stockList5['psMin'],
            data: dataMap.stockList5['psRecords']
          }
        }else if( idx === 4 ){
          dataMap.stockList5ChartData = {
            max: dataMap.stockList5['pcfMax'],
            mid: dataMap.stockList5['pcfMid'],
            min: dataMap.stockList5['pcfMin'],
            data: dataMap.stockList5['pcfRecords']
          }
        }
      }
    }

    const changeHandle = async (res:any) => {
      let data4 = await getValuationDaily({ stockCode: stockCode,timeLevel: res.timeLevel })
      dataMap.stockList4 = (data4 as any).data;

      let data5 = await getValuationQuantileDaily({ stockCode: stockCode,timeLevel: res.timeLevel })
      dataMap.stockList5 = (data5 as any).data;
      swichAnalysisItem(dataMap.activeAnalysisIdx)
    }

    const changeJggdHandle = async (res:any) => {
      let data6 = await getReportRanking({ stockCode: stockCode,timeLevel: res.timeLevel })
      dataMap.stockList6 = (data6 as any).data;

      let data7 = await getReportPriceDaily({ stockCode: stockCode,timeLevel: res.timeLevel })
      dataMap.stockList7 = (data7 as any).data;
    }

    const getStockInfo = (info:any) => {
      dataMap.stockInfo = info
    }

    const shareUrl = () => {
      const { toClipboard } = useClipboard();
      try {
        console.log(process.env)
        const origin = location.origin
          const host = origin === 'https://business-admin.jufxt.com' || origin === 'http://business-admin.jufxt.com' ? 'https://investment-research.jufxt.com' : 'https://tinvestment-research.jufxt.com'
          toClipboard(host + '/investment/diagnosismoblie/index?code=' + stockCode);
          ElMessage.success('复制成功！');
      } catch (e) {
          ElMessage.error('复制失败！');
      }
    }

    fetchData()
    return {
      ...toRefs(dataMap),
      swichItem,
      swichAnalysisItem,
      changeHandle,
      changeJggdHandle,
      getStockInfo,
      shareUrl
    }
  }
})
