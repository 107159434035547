<template>
  <div class="app-container">
    <breadcrumb @getStockInfo='getStockInfo'></breadcrumb>
    <Search  open></Search>
    <div class="bg mt50">
      <CTitle :mode='11' :title="stockInfo.name" :code='stockInfo.code' @change='changeHandle'></CTitle>
      <CTitle :mode='6' title="机构评级" :sixIcon='true' class="mt41" @changeTips='dialogVisible2 = true'></CTitle>
      <div class="pj flex">
        <div class="left">
          <BarChartE id='chart-jggd' width='100%' :chartData='stockList3'></BarChartE>
        </div>
        <div class="right" v-if="stockList4.upwardSpace && stockList4.avgGoalPrice">
          <BarChartC id='chart-jggd-right' width='100%' :chartData='stockList4' ></BarChartC>
        </div>
      </div>
      <div class="flex mt41">
        <div class="left">
          <CTitle :mode= '6' title="机构评级变动" :sixIcon='true' @changeTips='dialogVisible1 = true'></CTitle>
          <LineChartA height='300px' :chartData='stockList1.scoreRecords'></LineChartA>
        </div>
        <div class="right">
          <CTitle :mode= '6' title="目标价变动" ></CTitle>
          <BarChartB id='barchart-1' height='300px' :chartData='stockList1.priceRecords'></BarChartB>
        </div>
      </div>
    </div>

    <div class="bg">
      <CTitle :mode= '1' title="研报" ></CTitle>
      <CList from='detail' />
    </div>

    <div class="bg">
      <div class="tit">同业比较</div>
      <CTable :stockList='stockList2' @sortChangeHandler='sortChange' class="first-active" style="margin-top:0"/>
    </div>

    <!-- 机构评级变动提示弹窗 -->
    <el-dialog v-model="dialogVisible1" title="机构评级变动" width="30%" :before-close="handleClose">
      <div class="antd-pro-components-comment-styles-modalContent">
        <div class="antd-pro-components-comment-styles-commentContent">将来自各研究机构的投资评级进行1-5档标准化，给予最高1分最低5分的标准分值(1分_相当于买入、2分_相当于增持、3分_相当于中性、4分_相当于减持、5分_相当于卖出)。</div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <!-- <el-button @click="dialogVisible1 = false">Cancel</el-button> -->
          <el-button type="primary" @click="dialogVisible1 = false">
            知道了
          </el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 机构评级提示弹窗 -->
    <el-dialog v-model="dialogVisible2" title="机构评级" width="30%" :before-close="handleClose">
      <div class="antd-pro-components-comment-styles-modalContent">
        <div class="antd-pro-components-comment-styles-title">综合评级<div class="antd-pro-components-comment-styles-titleBottom"></div>
        </div>
        <div class="antd-pro-components-comment-styles-commentContent">【释义】：对最近90天来自个机构的投资评级进行1-5标准化，给予最高1分最低5分的标准分值，1分相当于买入、2分相当于增持、3分相当于中性、4分相当于减持、5分相当于卖出，并根据这一评级进进一步细分</div>
        <div class="antd-pro-components-comment-styles-title">目标均价<div class="antd-pro-components-comment-styles-titleBottom"></div>
        </div>
        <div class="antd-pro-components-comment-styles-commentContent" style="margin-bottom: 15px;">【释义】：最近90天内所有机构对该证券未来目标价做出的预测数据的算数平均值，以及最新价到目标均价之间的上涨空间。</div>
        <div class="antd-pro-components-comment-styles-title">
          <div class="antd-pro-components-comment-styles-titleBottom"></div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <!-- <el-button @click="dialogVisible2 = false">Cancel</el-button> -->
          <el-button type="primary" @click="dialogVisible2 = false">
            知道了
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="ts">

import { defineComponent, reactive, toRefs } from 'vue'
import CTitle from "@/components/title/Index.vue"
import CTable from './components/Table.vue'
import LineChartA from "@/components/charts/LineChartA.vue"
import BarChartB from "@/components/charts/BarChartB.vue"
import BarChartC from '@/components/charts/BarChartC.vue'
import BarChartE from '@/components/charts/BarChartE.vue'
import Search from '@/components/search-input/Index.vue'
import breadcrumb from '@/components/breadcrumb/Index.vue'
import CList from './List.vue'

import { useRoute  } from "vue-router";
import { getReportIndustryRanking, getReportMonthRanking, getReportOrgCountRanking, getReportPriceDaily } from '@/apis/agency'


export default defineComponent({
  components:{
    Search,
    CTitle,
    CTable,
    LineChartA,
    BarChartC,
    BarChartE,
    BarChartB,
    breadcrumb,
    CList
  },
  setup() {
    // const stockCode = '600519.SH'
    const route = useRoute();
    const stockCode = route.query.code
    const dataMap = reactive({
      stockInfo: {},
      stockList1: {},
      stockList2: [],
      stockList3: [],
      stockList4: [],
      dialogVisible1: false,
      dialogVisible2: false
      // stockList5:[],
      // stockList6:[],
      // stockList7:[],
    })

    const fetchData = async () => {
      const data1 = await getReportMonthRanking({ stockCode: stockCode })
      dataMap.stockList1 = (data1 as any).data;
    }
    fetchData()

    const changeHandle = async (val:any) => {
      
      getTableData(val, null, null)

      const data3 = await getReportOrgCountRanking({ stockCode: stockCode, timeLevel: val.timeLevel })
      dataMap.stockList3 = (data3 as any).data;

      const data4 = await getReportPriceDaily({ stockCode: stockCode, timeLevel: val.timeLevel })
      dataMap.stockList4 = (data4 as any).data;

    }

    
    let tableSort = {
      stockCode: stockCode,
      timeLevel: '',
      sortKey: '',
      isAsc: '',
    }
    const getTableData = async (val:any, sortKey:any, isAsc:any) => {
      if(val){tableSort.timeLevel = val.timeLevel}
      if(sortKey !== undefined){tableSort.sortKey = sortKey}
      if(isAsc !== undefined){tableSort.isAsc = isAsc}
      const data2 = await getReportIndustryRanking( tableSort )
      dataMap.stockList2 = (data2 as any).data;
    }

    const sortChange = async (column:any) => {
      let isAsc = column.order === "ascending" ? true : false
      getTableData({timeLevel: tableSort.timeLevel}, column['prop'], isAsc)
    }

    const getStockInfo = (info:any) => {
      dataMap.stockInfo = info
    }
    
    return {
      ...toRefs(dataMap),
      changeHandle,
      sortChange,
      getStockInfo
    }
  }
})
</script>
<style lang='scss' scoped>
.bg{
  background: #fff;
  padding: 30px 32px 54px;
  margin-bottom: 30px;
  .table-style-2{
    margin-top: 60px
  }
}
.tit{
  font-size: 20px;
  font-weight: 500;
  color: rgb(51, 51, 51);
  line-height: 36px;
  margin-bottom: 20px;
}
.flex{
  display: flex;
  &>div{
    flex: 1;
  }
  .left{
    padding-right: 30px;
  }
  .right{
    padding-left: 30px;
  }
}
.pj{
  // display: flex;
  &>div{
    width: 50%;
    flex: none;
  }
  .left{
    // width: 550px;
    // margin-right: 160px;
  }
  .right{
    // margin-left: 10%;
  }
}
.mt41{
  margin-top: 41px;
  clear: both;
}
@media (max-width:1250px) {
  .pj{
    flex-direction: column;
    &>div{
      width: 100%;
    }
    .left,.right{
      padding: 0;
    }
  }
}
</style>
