<template>
  <div class="app-h5-container">
    <!-- 头部 -->
    <div class="top__box">
      <div class="top_box_left">{{stockList2?.name || '--'}}<text>（{{stockList2?.code || $route.query.code?.split('.')[0]}}）</text></div>
      <div class="top_box_right" v-if="stockList1">打败了<text class="text2">{{stockList1?.beat}}</text>的股票</div>
    </div>

    <!-- 个股综评 -->
    <div class="bg ggzp" v-if="stockList2 && ajaxRes">
      <template v-if="stockList1">
        <CTitle :mode='6' title="个股综评" class="mt27"></CTitle>
        <div class="chart">
          <RadarChart :chartData='stockList1' width='100%' :isMobile='true'></RadarChart>
          <div class="right">
            <div class="t">
              <div>
                <div>
                  <span>次日上涨概率</span>
                  <span v-dClass='stockList1.risingProbability'>{{stockList1.risingProbability}}</span>
                </div>
              </div>
            </div>
            <div class="b">
              <div class="content_item_assessment_graph_foot">
                <div class="pingyu"><text>评语:</text>
                  <div class="xiahua1"></div>
                </div> 盈利能力<text :style="{color:assessmentInfo.profitabilityScorecolor}">{{assessmentInfo.profitabilityScoretext}}</text> ，成长能力<text :style="{color:assessmentInfo.growUpScorecolor}">{{assessmentInfo.growUpScoretext}}</text>，营运债偿能力<text :style="{color:assessmentInfo.operationScorecolor}">{{assessmentInfo.operationScoretext}}</text>，现金流<text :style="{color:assessmentInfo.cashFlowScorecolor}">{{assessmentInfo.cashFlowScoretext}}</text>，价值<text :style="{color:assessmentInfo.valuationScorecolor}">{{assessmentInfo.valuationScoretext}}</text>，整体质地<text :style="{color:assessmentInfo.valueScorecolor}">{{assessmentInfo.valueScoretext}}</text>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <div class="tabbar_box ">
      <div class="tabbar_box_item_box" bindtap="settabbar" @click="swichItem(1)">
        <div class="tabbar_box_item_btn" :class="activeIdx === 1 ? 'type' : ''">技术面</div>
        <div class="tabbar_box_item_box_righttype"></div>
      </div>
      <div class="tabbar_box_item_box" bindtap="settabbar" @click="swichItem(2)">
        <div class="tabbar_box_item_btn" :class="activeIdx === 2 ? 'type' : ''">资金面</div>
        <div class="tabbar_box_item_box_righttype"></div>
      </div>
      <div class="tabbar_box_item_box" bindtap="settabbar" @click="swichItem(3)">
        <div class="tabbar_box_item_btn" :class="activeIdx === 3 ? 'type' : ''">基本面</div>
        <div class="tabbar_box_item_box_righttype"></div>
      </div>
      <!-- <div class="tabbar_box_item_box" bindtap="settabbar" data-type="4">
        <div class="tabbar_box_item_btn {{tabbartype==4?'type':''}}">机构观点</div>
        <div class="tabbar_box_item_box_righttype"></div>
      </div> -->
    </div>

    <!-- <div class="tab">
      <ul>
        <li :class="activeIdx === 1 ? 'active' : ''" @click="swichItem(1)">技术面</li>
        <li :class="activeIdx === 2 ? 'active' : ''" @click="swichItem(2)">资金面</li>
        <li :class="activeIdx === 3 ? 'active' : ''" @click="swichItem(3)">基本面</li>
      </ul>
    </div> -->
    <div class="tab-content">
      <!-- 技术面 -->
      <Technical v-if="activeIdx === 1"></Technical>
      <!-- 资金面 -->
      <Capital v-else-if="activeIdx === 2"></Capital>
      <!-- 基本面 -->
      <Basic v-else></Basic>
    </div>

    <div class="b-tab" v-if="ajaxRes">
      <div class="modular">
        <CTitle :mode='6' title="估值分析" moreUrl='/analysis/detail'></CTitle>
        <CSelect @change="changeHandle" type='year' class="pselect"></CSelect>

        <div class="b-tab-nav" v-if="ajaxRes">
          <ul>
            <li :class="activeAnalysisIdx === 0 ? 'active' : ''" @click="swichAnalysisItem(0)">
              <div>综合估值</div>
              <div>{{stockList3.valuationDesc}}</div>
            </li>
            <li :class="activeAnalysisIdx === 1 ? 'active' : ''" @click="swichAnalysisItem(1)">
              <div>市盈率</div>
              <div>{{stockList3.pe.toFixed(2)}}</div>
            </li>
            <li :class="activeAnalysisIdx === 2 ? 'active' : ''" @click="swichAnalysisItem(2)">
              <div>市净率</div>
              <div>{{stockList3.pb.toFixed(2)}}</div>
            </li>
            <li :class="activeAnalysisIdx === 3 ? 'active' : ''" @click="swichAnalysisItem(3)">
              <div>市销率</div>
              <div>{{stockList3.ps.toFixed(2)}}</div>
            </li>
            <li :class="activeAnalysisIdx === 4 ? 'active' : ''" @click="swichAnalysisItem(4)">
              <div>市现率</div>
              <div>{{stockList3.pcf.toFixed(2)}}</div>
            </li>
          </ul>
        </div>

        <div class="analysis-container flex">
          <div class="flex analysis" v-if="activeAnalysisIdx === 0">
            <div class="left">
              <div class="analysis-title">
                <div class="analysis-title-left">
                  公司最新估值分位 <span class="red">{{stockList3.valuation}}%</span> ，估值<span class="red">{{stockList3.valuationDesc}}</span>
                </div>
                <div class="analysis-ti tle-right">
                </div>
              </div>
              <div class="analysis-left-date">
                <LineChartF id='chart-analysis-left' :chartData='stockList4' v-if="ajaxRes" width='100%'></LineChartF>
                <div class="content_item_timebox">
                  <div class="content_item_timebox_item" v-if="stockList4[0]">{{stockList4[0].date}}</div>
                  <div class="content_item_timebox_item" v-if="stockList4.length">{{stockList4[stockList4.length-1].date}}</div>
                </div>
              </div>
            </div>
            <div class="right"></div>
          </div>
          <div class="analysis-right" v-else>
            <div class="flex">
              <p v-if="activeAnalysisIdx === 1">{{stockList5.peContent}}</p>
              <p v-if="activeAnalysisIdx === 2">{{stockList5.pbContent}}</p>
              <p v-if="activeAnalysisIdx === 3">{{stockList5.psContent}}</p>
              <p v-if="activeAnalysisIdx === 4">{{stockList5.pcfContent}}</p>
            </div>
            <div class="content_item_trend_content">
              <div class="content_item_trend_content_line">
                <div class="trend_content_legend">
                  <ul>
                    <li><span></span> 高估：{{stockList5ChartData.max}}</li>
                    <li><span></span> 中值：{{stockList5ChartData.mid}}</li>
                    <li><span></span> 低估：{{stockList5ChartData.min}}</li>
                  </ul>
                </div>
                <template v-if="stockList5ChartData.data && stockList5ChartData.data.length">
                  <LineChartD id='chart-analysis-right' width='100%' :chartData='stockList5ChartData'></LineChartD>
                  <div class="content_item_timebox">
                    <div class="content_item_timebox_item">{{stockList5ChartData.data[0].x}}</div>
                    <div class="content_item_timebox_item">{{stockList5ChartData.data[stockList5ChartData.data.length-1].x}}</div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="jgpj modular">
        <CTitle :mode='6' title="机构评级" moreUrl='/agency/detail' class=""></CTitle>
        <div v-if="ajaxRes">
          <div class="left">
            <CSelect @change="changeJggdHandle" type='month' class="pselect"></CSelect>
            <BarChartE id='chart-jggd' width='100%' height='150px' :chartData='stockList6' :isMobile='true'></BarChartE>
          </div>
          <div class="right-box">
            <BarChartC id='chart-jggd-right' width='100%' height='100px' :chartData='stockList7'  :isMobile='true'></BarChartC>
          </div>
        </div>
      </div>

      <div class="modular" style='padding-bottom: 40px;'>
        <!-- <CTitle :mode='6' title="机构调研" moreUrl='/research/detail' class=""></CTitle>
        <div>
          <BarChartH id='chart-jgdy' width='550px' :chartData='stockList8'></BarChartH>
        </div> -->
        <SurveyOrgRanking :isMobile='true'></SurveyOrgRanking>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from "vue";
import CTitle from "@/components/title/Index.vue";
import RadarChart from "@/components/charts/RadarChart.vue";
import Technical from "./components/aspect/Technical.vue";
import Basic from "./components/aspect/Basic.vue";
import Capital from "./components/aspect/Capital.vue";
import LineChartF from "@/components/charts/LineChartF.vue";
// import LineChartJ from '@/components/charts/LineChartJ.vue'
import LineChartD from "@/components/charts/LineChartD.vue";
import BarChartA from "@/components/charts/BarChartA.vue";
import BarChartC from "@/components/charts/BarChartC.vue";
import BarChartE from "@/components/charts/BarChartE.vue";
import BarChartH from "@/components/charts/BarChartH.vue";
import SurveyOrgRanking from "@/views/research/components/SurveyOrgRanking.vue";

import CSelect from "@/components/select/Index.vue";
import {
  getDiagnosticAssessment,
  getStockRealtime,
  getValuationDesc,
  getValuationDaily,
  getValuationQuantileDaily,
  getReportRanking,
  getReportPriceDaily,
  // getSurveyOrgRanking 
} from "@/apis/diagnosisMoble";
import { useRoute } from "vue-router";

// import { ArrowRight } from '@element-plus/icons-vue'

export default defineComponent({
  components: {
    CTitle,
    CSelect,
    RadarChart,
    LineChartF,
    LineChartD,
    BarChartA,
    BarChartC,
    BarChartE,
    BarChartH,
    Technical,
    Basic,
    Capital,
    SurveyOrgRanking,
  },
  setup() {
    const route = useRoute();
    const stockCode = route.query.code; //'600519.SH'
    const dataMap = reactive({
      ajaxRes: false,
      activeIdx: 1,
      activeAnalysisIdx: 0,
      stockInfo: {},
      stockList1: {},
      stockList2: {},
      stockList3: {},
      stockList4: [],
      stockList5: {},
      stockList6: {},
      stockList7: {},
      stockList8: {},
      stockList5ChartData: {},
      assessmentInfo: {},
    });

    const fetchData = async () => {
      const data1 = await getDiagnosticAssessment({ stockCode: stockCode });
      dataMap.stockList1 = (data1 as any).data;

      let assessmentInfo = (data1 as any).data;
      if (assessmentInfo) {
        gettext(assessmentInfo, "cashFlowScore");
        gettext(assessmentInfo, "growUpScore");
        gettext(assessmentInfo, "operationScore");
        gettext(assessmentInfo, "profitabilityScore");
        gettext(assessmentInfo, "ranking");
        gettext(assessmentInfo, "valueScore");
        gettext(assessmentInfo, "valuationScore");
        dataMap.assessmentInfo = assessmentInfo;
      }

      const data2 = await getStockRealtime({ stockCode: stockCode });
      dataMap.stockList2 = (data2 as any).data;

      const data3 = await getValuationDesc({ stockCode: stockCode });
      dataMap.stockList3 = (data3 as any).data;

      // let data4 = await getValuationDaily({ stockCode: stockCode,timeLevel: '5y' })
      // dataMap.stockList4 = (data4 as any).data;

      // let data5 = await getValuationQuantileDaily({ stockCode: stockCode,timeLevel: '5y' })
      // dataMap.stockList5 = (data5 as any).data;

      // let data8 = await getStockInfo({ stockCode: stockCode })
      // dataMap.stockInfo = (data8 as any).data;

      // let data6 = await getReportRanking({ stockCode: stockCode,timeLevel: '1m' })
      // dataMap.stockList6 = (data6 as any).data;

      // let data7 = await getReportPriceDaily({ stockCode: stockCode,timeLevel: '5y' })
      // dataMap.stockList7 = (data7 as any).data;

      // let data8 = await getSurveyOrgRanking({ stockCode: stockCode })
      // dataMap.stockList8 = (data8 as any).data;

      dataMap.ajaxRes = true;
    };
    const gettext = (obj: any, text: any) => {
      obj[text + "text"] = obj[text].split("|")[0];
      obj[text + "color"] = obj[text].split("|")[1];
      return obj;
    };

    const swichItem = (item: any) => {
      dataMap.activeIdx = item;
      console.log(item)
    };

    const swichAnalysisItem = (idx: any) => {
      dataMap.activeAnalysisIdx = idx;
      console.log(11111, dataMap.stockList5);
      if (idx) {
        if (idx === 1) {
          dataMap.stockList5ChartData = {
            max: dataMap.stockList5["peMax"],
            mid: dataMap.stockList5["peMid"],
            min: dataMap.stockList5["peMin"],
            data: dataMap.stockList5["peRecords"],
          };
        } else if (idx === 2) {
          dataMap.stockList5ChartData = {
            max: dataMap.stockList5["pbMax"],
            mid: dataMap.stockList5["pbMid"],
            min: dataMap.stockList5["pbMin"],
            data: dataMap.stockList5["pbRecords"],
          };
        } else if (idx === 3) {
          dataMap.stockList5ChartData = {
            max: dataMap.stockList5["psMax"],
            mid: dataMap.stockList5["psMid"],
            min: dataMap.stockList5["psMin"],
            data: dataMap.stockList5["psRecords"],
          };
        } else if (idx === 4) {
          dataMap.stockList5ChartData = {
            max: dataMap.stockList5["pcfMax"],
            mid: dataMap.stockList5["pcfMid"],
            min: dataMap.stockList5["pcfMin"],
            data: dataMap.stockList5["pcfRecords"],
          };
        }
      }
    };

    const changeHandle = async (res: any) => {
      let data4 = await getValuationDaily({
        stockCode: stockCode,
        timeLevel: res.timeLevel,
      });
      dataMap.stockList4 = (data4 as any).data;

      let data5 = await getValuationQuantileDaily({
        stockCode: stockCode,
        timeLevel: res.timeLevel,
      });
      dataMap.stockList5 = (data5 as any).data;
      swichAnalysisItem(dataMap.activeAnalysisIdx);
    };

    const changeJggdHandle = async (res: any) => {
      let data6 = await getReportRanking({
        stockCode: stockCode,
        timeLevel: res.timeLevel,
      });
      dataMap.stockList6 = (data6 as any).data;

      let data7 = await getReportPriceDaily({
        stockCode: stockCode,
        timeLevel: res.timeLevel,
      });
      dataMap.stockList7 = (data7 as any).data;
    };

    const getStockInfo = (info: any) => {
      dataMap.stockInfo = info;
    };

    fetchData();
    return {
      ...toRefs(dataMap),
      swichItem,
      swichAnalysisItem,
      changeHandle,
      changeJggdHandle,
      getStockInfo,
    };
  },
});
</script>
<style lang='scss' scoped>


.app-h5-container {
  // width: 100vh;
  padding: 0 15px;
}
.top__box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e8e8e8;
  height: 42px;
  .top_box_left {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    text {
      font-size: 12px;
      font-weight: normal;
    }
  }
  .top_box_right {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    font-weight: 500;
    .text1 {
      color: #fb160c;
      font-size: 32px;
      margin-right: 10px;
    }
    .text2 {
      color: #fb160c;
    }
  }
}


.ggzp{
  .chart{
    position: relative;
    .right{
      .t{
        width: 100%;
        text-align: center;
        position: absolute;
        left: 0;
        top: 30px;
      }
    }
  }
}

.b-tab-nav{
  width: 100%;
  background: #EFF2F6;
  // box-shadow: 0px 2px 12px 0px rgba(24,24,24,0.1);
  border-radius: 8px;
  padding: 4px;
  display: inline-block;
  margin-top: 20px;
  ul{
    display: flex;
    li{
      flex: 1;
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #9192A2;
      padding: 10px 0;
      cursor: pointer;
      &.active{
        color: #333;
        background: #fff;
        // box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.2);
        border-radius: 8px;
      }
      div:last-child{
        font-size: 18px;
        padding-top: 4px;
      }
    }
  }
}

.analysis-right{
  p{
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    padding-right: 30px;
    margin-bottom: 7px;
  }
  .trend_content_legend{
    margin-bottom: 10px;
    ul{
      display: flex;
      margin: 7px 0 10px;
      li{
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        margin-right: 15px;
        &:last-child{
          margin-right: 0;
        }
        span{
          display: inline-block;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background: #951EFD;
        }
        &:nth-child(2){
          span{
            background: #D0031B;
          }
        }
        &:nth-child(3){
          span{
            background: #E9C036;
          }
        }
      }
    }
  }
}

.analysis-container{
  .analysis-title-left{
    margin: 20px 0;
  }
  .analysis-right{
    p{
      margin: 20px 0;
    }
  }
}

.jgpj{
  .right-box{
    margin-top: 50px;
  }
  ::v-deep .content{
    .right{
      margin-left: 16px;
      margin: 10px;
    }
  }
}
::v-deep .content{
  margin-top: 20px;
  .left{
    width: 90px;
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    div{
      padding: 2px 0;
    }
    .des{
      font-size: 14px;
      margin: 0!important;
    }
    .num{
      font-size: 16px;
      margin: 0!important;
      &:nth-child(2){
        margin-bottom: 18px!important;
      }
    }
    
  }
  .right{
  }
}

.content_item_trend_header_text{
    font-size: 30px;
    color: #333333;
    margin-bottom: 30px;
}





.content_item_assessment .content_item_mechanism_line_header_item:first-child .content_item_mechanism_line_header_item_icon{
    background: #1667EC !important;
}
.content_item_assessment .content_item_mechanism_line_header_item:nth-child(2) .content_item_mechanism_line_header_item_icon{
    background: #FE9625  !important;
}
.content_item_assessment .content_item_mechanism_line_header_item:nth-child(3) .content_item_mechanism_line_header_item_icon{
    background: #1667EC  !important;
}
.content_item_assessment_graph_foot{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #343434;
    // padding: 0 20px;
    line-height: 20px;
    margin: 36px 0 40px;
    .pingyu{
      color: #333333;
      display: inline-block;
      position: relative;
      text{
        position: relative;
        z-index: 2;
      }
      .xiahua{
        height: 6px;
        width: 100%;
        background: #FE9625;
        position: absolute;
        bottom: 6px;
        left: 0;
        z-index: 1;
      }
    }
}


.tabbar_box{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 4px 24px 0px rgba(24, 24, 24, 0.1);
  border-radius: 8px;
  padding: 2px 0;
  margin:18px 0 15px;
  .tabbar_box_item_box{
    flex: 1;
    padding: 4px 6px;
    position: relative;
    &:not(:last-child) .tabbar_box_item_box_righttype{
      width: 1px;
      height: 20px;
      margin: auto;
      background: rgba(232, 220, 220, .5);
      /* background: #000; */
      position: absolute;
      top: 0;
      bottom:0;
      right: -1px;
    }
  }
  .tabbar_box_item_btn{
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    height: 32px;
    line-height: 32px;
    text-align: center;
    &.type{
      width: 100%;
      height: 32px;
      line-height: 32px;
      background: #386FF2;
      box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.2);
      border-radius: 8px;
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      text-align: center;
    }
  }
}



.c074e84{
    color: #074e84;
}
.cc00c00{
    color: #c00c00;
}
.c008102{
    color: #008102;
}

</style>
<style lang='scss'>
.modular{
  box-shadow: 0px 2px 12px 0px rgba(24,24,24,0.1);
  border-radius: 8px;
  padding: 16px 10px 20px;
  margin-bottom: 10px;
  position: relative;
  .pselect{
    position: absolute;
    right: 0;
    top: 10px;
  }
}
</style>