
import { defineComponent, onBeforeUnmount, onMounted, reactive, toRefs, ref } from 'vue'

export default defineComponent({
  props: {
    right: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: 'month'
    }
  },
  setup(props,context) {
    const value = ref(0)
    let options:any = []

    if( props.type === 'year' ){
      options = [
        {
          value: 0,
          label: '最近3年',
          timeLevel: '3y'
        },
        {
          value: 1,
          label: '最近5年',
          timeLevel: '5y'
        },
        {
          value: 2,
          label: '最近10年',
          timeLevel: '10y'
        }
      ]
    }else{
      options = [
        {
          value: 0,
          label: '最近1月',
          timeLevel: '1m'
        },
        {
          value: 1,
          label: '最近3月',
          timeLevel: '3m'
        },
        {
          value: 2,
          label: '最近6月',
          timeLevel: '6m'
        },
        {
          value: 3,
          label: '最近1年',
          timeLevel: '1y'
        }
      ]
    }

    const changeHandle = (val:any) => {
      context.emit('change', options[val])
    }
    context.emit('change', options[0])
    return {
      value,
      options,
      changeHandle
    }

    // return {
    //   ...toRefs(state)
    // }
  }
})

