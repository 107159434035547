/*
 * @Description: 董监高接口
 */
import https from '@/utils/https'
import { RequestParams, ContentType, Method } from 'axios-mapper'

//获取板块排行
export const getBoardRanking = (params: RequestParams) => {
  return https().request('/company/leader/news/board/ranking', Method.GET, params, ContentType.form)
}
//获取类型排行
export const getTypeRanking = (params: RequestParams) => {
  return https().request('/company/leader/news/type/ranking', Method.GET, params, ContentType.form)
}
//获取市值排行
export const getMvRanking = (params: RequestParams) => {
  return https().request('/company/leader/news/mv/ranking', Method.GET, params, ContentType.form)
}
//行业列表
export const getNewsRanking = (params: RequestParams) => {
  return https().request('/company/leader/news/boards', Method.GET, params, ContentType.form)
}
//行业股票列表
export const getStocksRanking = (params: RequestParams) => {
  return https().request('/company/leader/news/board/stocks', Method.GET, params, ContentType.form)
}
//董监高信息列表
export const getLeader = (params: RequestParams) => {
  return https().request('/company/leader/news/stock/news', Method.GET, params, ContentType.form)
}
