<template>
    <div class="agency-buy-rating-wrap">
        <div class="bg">
            <el-row>
                <el-col :span="24" >
                    <NewTitle
                        title="买入评级"
                        @change="changeHandle1"
                        ref='refNewTitle1'
                    />
                    <el-table
                        v-loading="loading"
                        class="table-style-2 cursor"
                        :data="stockList1.list"
                        border
                        style="width: 100%"
                        @sort-change="sortChange1"
                        @row-click='goDetail1'
                        :default-sort ="{prop:'orgCount',order:'descending'}"
                    >
                        <el-table-column
                            prop="stockName"
                            align="center"
                            label="股票名称"
                        />
                        <el-table-column
                            prop="orgCount"
                            align="center"
                            label="评级家数"
                            sortable='custom'
                        />
                        <el-table-column
                            prop="maxIncr"
                            align="center"
                            label="阶段最高涨幅"
                            sortable='custom'
                        >
                            <template #default='{row}'>
                                <div v-dClass='row.maxIncr'>
                                    <template v-if="row.maxIncr[0] !== '-'">+</template>{{row.maxIncr}}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="intervalIncr"
                            align="center"
                            label="区间涨幅"
                            sortable='custom'
                        >
                            <template #default='{row}'>
                                <div v-dClass='row.intervalIncr'>
                                    <template v-if="row.intervalIncr[0] !== '-'">+</template>{{row.intervalIncr}}
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="pagination">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="page.pageNum"
                            :page-sizes="[10, 20, 30, 50, 100]"
                            :page-size="page.pageSize"
                            layout="sizes, prev, pager, next, jumper"
                            :total="stockList1.totalSize">
                        </el-pagination>
                    </div>   
                </el-col>

            </el-row>
        </div>    
    </div>
</template>
  
<script lang="ts">
import { defineComponent, reactive, toRefs, ref } from 'vue';
import NewTitle from '@/components/new-title/Index.vue';
import dicts from '@/utils/dicts';
import { getStockReportBuy } from '@/apis/agency';
import moment from 'moment'; // 导入文件

interface Inter {
    order: string | undefined,
    orderBy: string | undefined,
}
export default defineComponent({
    props: {
        dataMap: {
            type: Array,
            default: []
        },
        title: {
            type: String,
            default: '机构评级'
        }
    },
    components: {
        NewTitle
    },
setup() {
    const refNewTitle1 = ref(null)
    const dataMap = reactive({
        loading: false,
        stockList1: [],
        timeLevel: -1,
        selectedTime: 0 as any,
        dateFrom: undefined as any,
        dateTo: undefined as any,
        page: {
            totalSize: 0,
            pageSize: 20,
            pageNum: 1,
        },
    })

    let sortParams1:Inter = reactive({
        order: 'DESC',
        orderBy: 'orgCount',
    })
    
    const changeHandle1 = async (params:any) => {
        console.log(params, 'selectTimeselectTime');
        dataMap.timeLevel = params.timeLevel;
        if(params.timeLevel !== 0) {
          dataMap.selectedTime = (dicts.timeSelection || []).findIndex((item:any) => item.value === params.timeLevel);
          dataMap.dateFrom = undefined;
          dataMap.dateTo = undefined;
        }else {
          dataMap.selectedTime = 5;
          dataMap.dateFrom = params.dateFrom ? params.dateFrom : undefined;
          dataMap.dateTo = params.dateTo ? params.dateTo : undefined;
        }
        dataMap.page.pageNum = 1;
        dataMap.page.pageSize = 20;
        getList();
    }

    const getList = async () => {
        const newParams = {
            timeLevel: dataMap?.timeLevel,
            dateFrom: dataMap.dateFrom ? dataMap.dateFrom : undefined,
            dateTo: dataMap.dateTo ? dataMap.dateTo : undefined,
            pageNum: dataMap.page.pageNum,
            pageSize: dataMap.page.pageSize,
        }
        const data = await getStockReportBuy({...sortParams1, ...newParams})
        dataMap.stockList1 = (data as any).data
    }

    const sortChange1 = (item:any) => {
        if( item.prop ){
            sortParams1 = {
                order: item.order === "ascending" ? 'ASC' : 'DESC',
                orderBy: item.prop,
            }
            getList()
        }else{
            delete sortParams1.order
            delete sortParams1.orderBy
            getList()
        }
    }

    const goDetail1 = async (item:any) => {
        const level = dicts.timeSelection.findIndex((item) => item.value === dataMap.timeLevel);
        if(level === -1){
            const beginDate = moment(`${dataMap.dateFrom}`).format('YYYY-MM-DD')
            const endDate = moment(`${dataMap.dateTo}`).format('YYYY-MM-DD')
            window.open(`/investment/agency/index?idx=1&isBuyFlag=1&timeLevel=4&beginDate=${beginDate}&endDate=${endDate}&organizationSName=${item.stockName}`)
        }else{
            window.open(`/investment/agency/index?idx=1&isBuyFlag=1&timeLevel=${level - 1}&organizationSName=${item.stockName}`)
        }
    }

    // （切换每页多少条）
    const handleSizeChange = (val:any) => {
          dataMap.page.pageSize = val;
          dataMap.page.pageNum = 1;
          getList()
      }

      //（切换页码）
      const handleCurrentChange = (val:any) => {
          dataMap.page.pageNum = val;
          getList()
      }

    return {
        refNewTitle1,
        moment,
        sortChange1,
        changeHandle1,
        goDetail1,
        handleSizeChange,
        handleCurrentChange,
        ...toRefs(dataMap)
    }
}
})
</script>
<style scoped lang='scss'>
.agency-buy-rating-wrap{
    padding: 21px 35px;
    .bg{
        background: rgb(255, 255, 255);
        padding: 20px;
        margin-top: 30px;
    }
    .pagination{
      text-align: center;
      margin-top: 20px;
    }
}
</style>
  