<template>
  <div>
    <!-- <div @click="exportImg">导出图片</div> -->
    <dateComponent @input="getDate" :initShowInput="true" :isCustom="false" :init="true" style="top:84px"/>
    <div class="header" v-if="!_isMobile()">
      <div class="contet">
        <div class="left" @click="fallback">融资融券 > <span>数据分享</span> </div>
        <div class="right">
          <el-popover
            popper-class='c-share'
            placement="top-start"
            :width="300"
            trigger="hover"
          >
            <template #reference>
              <div class="btn-share">分享<img src="./images/share.png" alt=""> </div>
            </template>
            <div class="share-content">
              <div class="des">通过链接分享</div>
              <div class="share-link-box">
                <div class="input">{{domian}}</div>
                <div class="btn-copy-link btn" @click="copyDomain">复制链接</div>
              </div>
              <div class="b">
                <div class='l'>
                  <div class="des">通过二维码分享</div>
                  <div class="share-code-box">
                    <img src="./images/s-logo.png" width='29' class="s-logo" alt="">
                    <div id="imgContainer"></div>
                  </div>
                </div>
                <div class='r'>
                  <div class="des">通过图片分享</div>
                  <div class="share-img-box">
                    <div class="btn-download btn" @click="exportImg" >下载图片<img src="./images/download.png" width='14' alt=""></div>
                  </div>
                </div>
              </div>
            </div>
          </el-popover>

        </div>
      </div>
    </div>
    <div class="wrap" >
      <div class="capture" id='capture' :class="_isMobile() ? 'mobile' : ''">
        <div class="content_box">
          <div class="header_box">
            <span>融资融券</span>
            <img :src="logoSrc" alt="">
          </div>
          <div class="title_box">
            <div class="summary_box">
              <img src="./images/ellipse.png" alt="">
              <span>小结</span>
            </div>
            <div class="trading_day_box">
              <span>融资融券最后交易日：{{ time }}</span>
            </div>
          </div>
          <div class="sub_content_box">
            <img :src="shuiyin" alt="">
            <div class="relative">
              <div class="text_box">{{ reportData.indexDesc }}</div>
              <div class="text_box">{{ reportData.marginDesc }}</div>
<!--              <div class="text_box">-->
<!--                <span>在净买入个股前二十中：</span>-->
<!--             {{  reportData[2] }}</div>-->
<!--              <div class="text_box">-->
<!--                <span>在净卖出前二十中：</span>-->
<!--                {{ reportData[3] }}</div>-->
              <div class="thirty_box">
                <title-name title-name="融资余额近30天与上证指数走势（亿元）" />
                <div class="chart-box">
                  <i class="el-icon-refresh" @click="refreshChart(0)" />
                  <composite-chart id="compositess" :chart-data="boardData" v-if="chartShow[0]" />
                </div>
              </div>
              <div class="daily_fund_box">
                <title-name title-name="融资行业净买入前20（亿元）" />
                <div class="chart-box">
                  <i class="el-icon-refresh" @click="refreshChart(1)" />
                  <daily-chart id="daily" :chart-data="dailyData.list" v-if="chartShow[1]" />
                </div>
                <div class="text_box">{{ dailyData.boardRzjmrDesc }}</div>
                <table-component :table-list="dailyData.list" />
                <div class="text_box">{{ dailyData.boardTrendDesc }}</div>
              </div>
              <div class="purchase_box">
                <title-name title-name="融资个股净买入前20（亿元）" />
                <div class="chart-box">
                  <i class="el-icon-refresh" @click="refreshChart(2)" />
                  <purchase-chart id="purchase" :chart-data="flowAddHoldData.list" v-if="chartShow[2]" />
                </div>
                <purchase-table :table-list="flowAddHoldData.list" status-type="1" />
                <div class="text_box">{{ flowAddHoldData.desc }}</div>

              </div>
              <div class="purchase_box">
                <title-name title-name="融资余额最高TOP10" />
                <purchase-table :table-list="flowData" status-type="2" />
              </div>
              <div class="purchase_box">
                <title-name title-name="融资余额涨幅TOP10" />
                <net-table-component :table-list="upData" />
              </div>
              <div class="purchase_box">
                <title-name title-name="融资余额降幅TOP10" />
                <net-table-component :table-list="downData" :chart-id="1" />
              </div>
              <div class="sell_out_box">
                <title-name title-name="融券个股净卖出前20（亿元）" />
                <div class="chart-box">
                  <i class="el-icon-refresh" @click="refreshChart(3)" />
                  <sell-chart id="sell" :chart-data="flowSubHoldData" v-if="chartShow[3]" />
                </div>
                <net-table thaderClass='redBg' chart-id='purchase' :table-list="flowSubHoldData" />
              </div>
              <div class="data_sources_box">
                <span>数据来源：Wind 巨丰投顾</span>
                <span>融资融券最后交易日：{{ time }}</span>
              </div>
              <div class="risk_disclosure_box">
                <div class="nav_box">
                  <div class="icon_box"></div>
                  <span>风险揭示声明</span>
                </div>
                <div class="text_box">
                  <span>本报告/产品/服务仅供本公司的客户使用，本公司不会仅因接收人收到本报告/产品/服务而视其为客户。</span><br />
                  <span>本报告/产品/服务的信息均来源于本公司认为可信的公开资料，但本公司及研究人员对这些信息的准确性和完整性 不作任何保证，也不保证本报告/产品/服务所包含的信息或建议在本报告/产品/服务发出后不会发生任何变更，且 本报告/产品/服务中的资料、意见和预测均仅反映本报告/产品/服务发布时的资料、意见和预测，可能在随后会作 出调整。我们已力求报告/产品/服务内容的客观、公正，但文中的观点、结论和建议仅供参考，不构成投资者在投 资、法律、会计或税务等方面的最终操作建议。</span><br />
                  <span>本公司不就报告/产品/服务中的内容对投资者作出的最终操作建议做任何担保，投资者据此做出的任何决策与本公 司和本报告/产品服务作者无关。</span><br />
                  <span>在法律允许的情况下，本公司及其关联机构可能会持有本报告/产品/服务中提到的公司所发行的证券并进行交易， 也可能为这些公司提供或者争取提供财务顾问或类似的金融服务。</span><br />
                  <span>本报告/产品/服务版权仅为本公司所有。未经本公司书面许可，任何机构和或个人不得以任何形式翻版、复制和发布本报告。任何机构和个人如引用、刊发本报告/产品/服务，须同时注明出处为陕西巨丰投资资讯有限责任公司证券研究部，且不得对本报告/产品/服务进行任何有悖原意的引用、删节和或修改。 本公司具备证券投资咨询业务资格，证券投资咨询业务资格编号: ZX0057,且本报告/产品/服务作者为在中国证券业 协会登记注册的投资顾问，以勤勉尽责的职业态度，独立、客观地出具本报告。</span><br />
                  <span>本报告清晰准确地反映了作者的研究观点。本文作者不曾也将不会因本报告中的具体推荐意见或观点而直接或间接收到任何形式的补偿。</span><br />
                  <span>股市有风险，入市需谨慎。</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, nextTick } from 'vue'
import dateComponent from '../../../components/timeLevel/Index.vue'
import titleName from './component/title.vue'
import tableComponent from './component/table.vue'
import netTable from './component/net-purchase-table.vue'
import compositeChart from './component/composite-chart.vue'
import dailyChart from './component/daily-fund-chart.vue'
import purchaseChart from './component/purchase-chart.vue'
import sellChart from './component/sell-out-chart.vue'
import { getReportSummaryDate } from '@/apis/northboundFunds'
import { getReportSummary, getBoard, getDaily, getAddHold, getSubHold, getSubTen, getUpTen, getDownTen } from '@/apis/margin-trading'
import moment from 'moment'
import html2canvas from 'html2canvas';
import QRCode from 'qrcodejs2-fix';
import purchaseTable from './component/purchase-table.vue'
import netTableComponent from './component/net-table.vue'

import { ElMessage } from 'element-plus'
export default defineComponent({
  components: {
    titleName,
    tableComponent,
    netTable,
    compositeChart,
    dailyChart,
    purchaseChart,
    sellChart,
    purchaseTable,
    netTableComponent,
    dateComponent
  },
  setup() {
    // const router = useRouter();
    const dataMap = reactive({
      domian: window.location.href === 'https://tmgr.jufxt.com/investment/marginTrading/share-page' ? 's.jfinfo.com/zm6VTm' : window.location.href === 'https://mgr.jufxt.com/investment/marginTrading/share-page' ? 's.jfinfo.com/zm6VNm' : window.location.href,
      shuiyin:'',
      logoSrc:'',
      reportData: [],
      time: '',
      flowData: [],
      renderImgSrc: '',
      flowAddHoldData: [], // 买入
      flowSubHoldData: [], // 卖出
      boardData: {}, // 陆股通近30天资金流向与上证指数走势
      dailyData: [], // 陆股通行业日资金流向
      upData: [],
      downData: [],
      timeLevel: 'DAY',
      chartShow: [true, true, true, true],
    })
    //"DAY", "WEEK", "MONTH", "QUARTER", "YEAR"
    let date = ''
    const fetchData = async () => {
      const reportList = await getReportSummary({ timeLevel: dataMap.timeLevel, date })
      if ((reportList as any).code === 200) {
        dataMap.reportData = (reportList as any).data
      }
      const reportDateList = await getReportSummaryDate({timeLevel: dataMap.timeLevel, date})
      if ((reportDateList as any).code === 200) {
        dataMap.time = moment((reportDateList as any).data.prevRzrqTradeDate).format('YYYYMMDD');
      }
      const flowList = await getSubTen({timeLevel: dataMap.timeLevel, date})
      if ((flowList as any).code === 200) {
        dataMap.flowData = (flowList as any).data;
      }
      const flowAddHoldList = await getAddHold({timeLevel: dataMap.timeLevel, date})
      if ((flowAddHoldList as any).code === 200) {
        dataMap.flowAddHoldData = (flowAddHoldList as any).data;
      }
      const flowSubHoldList = await getSubHold({timeLevel: dataMap.timeLevel, date})
      if ((flowSubHoldList as any).code === 200) {
        dataMap.flowSubHoldData = (flowSubHoldList as any).data;
      }
      const flowBoardList = await getBoard({timeLevel: dataMap.timeLevel, date})
      if ((flowBoardList as any).code === 200) {
        dataMap.boardData = (flowBoardList as any).data;
      }
      const flowDailyList = await getDaily({ timeLevel: dataMap.timeLevel, date })
      if ((flowDailyList as any).code === 200) {
        dataMap.dailyData = (flowDailyList as any).data;
      }
      const upList = await getUpTen({timeLevel: dataMap.timeLevel, date})
      if ((upList as any).code === 200) {
        dataMap.upData = (upList as any).data;
      }
      const downList = await getDownTen({timeLevel: dataMap.timeLevel, date})
      if ((downList as any).code === 200) {
        dataMap.downData = (downList as any).data;
      }
      // refreshChart(-1)
    }

    const refreshChart = (idx:any) => {
      if( idx === 0 || idx === -1 ){
        dataMap.chartShow = [false, true, true, true]
        nextTick(()=>{
          dataMap.chartShow = [true, true, true, true]
        })
      }
      if( idx === 1 || idx === -1 ){
        dataMap.chartShow = [true, false, true, true]
        nextTick(()=>{
          dataMap.chartShow = [true, true, true, true]
        })
      }
      if( idx === 2 || idx === -1 ){
        dataMap.chartShow = [true, true, false, true]
        nextTick(()=>{
          dataMap.chartShow = [true, true, true, true]
        })
      }
      if( idx === 3 || idx === -1 ){
        dataMap.chartShow = [true, true, true, false]
        nextTick(()=>{
          dataMap.chartShow = [true, true, true, true]
        })
      }
    }
    // fetchData()


    const getDate = (time:any) => {
      if( time.hasOwnProperty('custom') ){
        date = time.custom
      }else{
        dataMap.timeLevel = time.val
      }
      fetchData()
    }

    const exportImg = () => {
      const capture = document.querySelector('#capture') as HTMLElement
      html2canvas(capture).then((canvas) => {
        let imgName = '融资融券'
        let a = document.createElement('a')
        a.style.display = 'none'
        let blob = dataURLToBlob(canvas.toDataURL('image/png'))
        a.setAttribute('href',URL.createObjectURL(blob))
        a.setAttribute('download',imgName+'.png')
        document.body.appendChild(a)
        a.click()
        URL.revokeObjectURL(blob as any)
        document.body.removeChild(a)
      });
    }

    const dataURLToBlob = (dataUrl:any) => {
      let arr = dataUrl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while(n--){
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr],{type:mime})
    }

    const copyDomain = () => {
      let url = dataMap.domian;
      let oInput = document.createElement('input');
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      document.execCommand("Copy"); // 执行浏览器复制命令

      ElMessage({
        message: '复制成功',
        type: 'success',
      })
    }

    const _isMobile = () => {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    }

    const getBase64 = (url:any, callback:any) => {
      var Img = new Image(),
        dataURL = '';
      Img.src = url +"?v=" + Math.random();
      Img.setAttribute("crossOrigin", 'Anonymous')
      Img.onload = function () {
        var canvas = document.createElement("canvas"),
          width = Img.width,
          height = Img.height;
        canvas.width = width;
        canvas.height = height;
        if( canvas ){
          (canvas as any).getContext("2d").drawImage(Img, 0, 0, width, height);
          dataURL = canvas.toDataURL('image/png');
          callback ? callback(dataURL) : null;
        }
      };
    }
    getBase64('https://jfinfo.oss-cn-beijing.aliyuncs.com/shuiyin.png', (data:any) => {
      dataMap.shuiyin = data
    })
    getBase64(require('./images/logo.png'), (data:any) => {
      dataMap.logoSrc = data
    })

    if( _isMobile() ){
      const meta = document.getElementsByTagName('meta')['viewport']
      meta.content = ''
    }


    const fallback = () => {
      window.open(`/investment/marginTrading/index`)
    }

    const generateCode = () => {
      nextTick(() => {
        new QRCode(document.getElementById("imgContainer") as HTMLElement, {
          text: window.location.href,
          width: '100',
          height: '100',
        });
      })
    }
    generateCode()



    return {
      ...toRefs(dataMap),
      exportImg,
      copyDomain,
      _isMobile,
      fallback,
      getDate,
      refreshChart
    }
  }
})

</script>

<style scoped lang="scss">
.header{
  height: 74px;
  background: #F0F2F5;
  .contet{
    width: 1000px;
    margin: auto;
    display: flex;
    .left{
      color: #386FF2;
      line-height: 74px;
      span{
        font-size: 14px;
        font-weight: 500;
        color: #333333;
      }
    }
    .right{
      margin-left: auto;
      .btn-share{
        cursor: pointer;
        width: 70px;
        height: 38px;
        color: #fff;
        line-height: 38px;
        text-align: center;
        background: #021A5D;
        margin-top: 18px;
        img{
          margin-left: 4px;
          transform: translateY(1px);
        }
      }

    }
  }
}
.wrap {
  width: 100%;
  min-height: 100vh;
  background-color: #FFFFFF;
  .text_box {
    font-size: 22px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #021A5D;
    line-height: 34px;
    padding: 12px 0;
    > span {
      display: block;
    }
  }
  .chart-box{
    position: relative;
    text-align: right;
    margin: 10px 0;
    .el-icon-refresh{
      margin-right: 38px;
      cursor: pointer;
      font-size: 18px;
    }
  }
  > .capture{
    width: 1100px;
    margin: auto;
    //padding-bottom: 150px;
    &.mobile{
      width: 90%;
      .content_box{
        width: 100%;
      }
    }
    > .content_box {
      width: 1000px;
      margin: 0 auto;
      > .header_box {
        padding: 60px 0 8px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: #021A5D solid 6px;
        > span {
          display: block;
          font-size: 47px;
          font-family: AlibabaPuHuiTi_2_115_Black;
          color: #021A5D;
          line-height: 66px;
          font-weight: bold;
        }
        > img {
          width: 227px;
          height: 49px;
        }
      }
      > .title_box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 28px;
        padding-bottom: 7px;
        border-bottom: 2px solid #021A5D;
        > .summary_box {
          display: flex;
          align-items: center;
          > img {
            width: 22px;
            height: 22px;
          }
          > span {
            display: block;
            font-size: 30px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #021A5D;
            line-height: 42px;
            margin-left: 10px;
          }
        }
        > .trading_day_box {
          background: #021A5D;
          border-radius: 1px;
          padding: 5px 22px 4px 26px;
          > span {
            display: block;
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 28px;
          }
        }
      }
      > .sub_content_box {
        padding-top: 2px;
        position: relative;
        padding-bottom: 150px;
        > img {
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
        > .relative{
          position: relative;
          z-index: 1;
          > .thirty_box {
            margin-top: 12px;
          }
          > .risk_disclosure_box {
            > .nav_box {
              display: flex;
              align-items: center;
              margin-bottom: 39px;
              > .icon_box {
                width: 13px;
                height: 23px;
                background: #021A5D;
              }
              > span {
                display: block;
                font-size: 22px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #021A5D;
                line-height: 30px;
                margin-left: 12px;
              }
            }
            > .text_box {
              > span {
                display: block;
                font-size: 22px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 35px;
              }
            }
          }
          > .data_sources_box {
            display: flex;
            justify-content: right;
            margin-bottom: 49px;
            > span {
              display: block;
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #6D6D6D;
              line-height: 22px;
              &:first-child {
                margin-right: 42px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
