/*
 * @Description: 北向资金接口
 */
import https from '@/utils/https'
import { RequestParams, ContentType, Method } from 'axios-mapper'

// 获取北向资金持有板块趋势
export const getTrend = (params: RequestParams) => {
  return https().request('/northbound/funds/board/trend', Method.GET, params, ContentType.form)
}
// 获取北向资金持有板块趋势
export const getStats = (params: RequestParams) => {
  return https().request('/northbound/funds/board/stats', Method.GET, params, ContentType.form)
}

// 北向个股明细增持统计同业排名
export const getPeer = (params: RequestParams) => {
  return https().request('/northbound/funds/stock/peer', Method.GET, params, ContentType.form)
}
// 北向个股明细机构统计
export const getOrgList = (params: RequestParams) => {
  return https().request('/northbound/funds/stock/orgList', Method.GET, params, ContentType.form)
}
// 股票DayK数据
export const getDayKData = (params: RequestParams) => {
  return https().request('/northbound/funds/stock/dayKData', Method.GET, params, ContentType.form)
}
// 北向个股明细持股记录表
export const getFundsStockList = (params: RequestParams) => {
  return https().request('/northbound/funds/stock/list', Method.GET, params, ContentType.form)
}

// 北向资金增持行业板块列表
export const getBoardIncreaseList = (params: RequestParams) => {
  return https().request('/northbound/funds/board/increaseList', Method.GET, params, ContentType.form)
}

// 北向资金增持个股列表
export const getStockIncreaseList = (params: RequestParams) => {
  return https().request('/northbound/funds/stock/increaseList', Method.GET, params, ContentType.form)
}
// 北向资金增持行业板块排行
export const getIncreaseRanking = (params: RequestParams) => {
  return https().request('/northbound/funds/board/increaseRanking', Method.GET, params, ContentType.form)
}

// 搜索股票和行业
export const getFundsSearch = (params: RequestParams) => {
  return https().request('/northbound/funds/search', Method.GET, params, ContentType.form)
}
// 获取北向资金持有板块明细
export const getFundsBoard = (params: RequestParams) => {
  return https().request('/northbound/funds/board/detail', Method.GET, params, ContentType.form)
}
// 获取北向资金持有板块明细
export const getFundsStock = (params: RequestParams) => {
  return https().request('/northbound/funds/stock/detail', Method.GET, params, ContentType.form)
}

// 获取北向资金板块下股票列表
export const getBoardStock = (params: RequestParams) => {
  return https().request('/northbound/funds/board/stock', Method.GET, params, ContentType.form)
}
// 分享页小结
export const getReportSummary = (params: RequestParams) => {
  return https().request('/northbound/funds/reportSummary', Method.GET, params, ContentType.form)
}
// 分享页小结日期
export const getReportSummaryDate = (params: RequestParams) => {
  return https().request('/stock/trade/date', Method.GET, params, ContentType.form)
}
// 分享页板块资金流向
export const getFlow = (params: RequestParams) => {
  return https().request('/northbound/funds/board/funds/flow', Method.GET, params, ContentType.form)
}
// 分享页北向资金个股净买入前20
export const getAddHold = (params: RequestParams) => {
  return https().request('/northbound/funds/stock/addHold/list', Method.GET, params, ContentType.form)
}
// 分享页北向资金个股净卖出前20
export const getSubHold = (params: RequestParams) => {
  return https().request('/northbound/funds/stock/subHold/list', Method.GET, params, ContentType.form)
}
// 分享页陆股通近30天资金流向与上证指数走势
export const getBoard = (params: RequestParams) => {
  return https().request('/northbound/funds/board/day30In', Method.GET, params, ContentType.form)
}
// 陆股通行业日资金流向
export const getDaily = (params: RequestParams) => {
  return https().request('/northbound/funds/board/day1In', Method.GET, params, ContentType.form)
}
// 总结
export const getFundsSummary = (params: RequestParams) => {
  return https().request('/northbound/funds/summary', Method.GET, params, ContentType.form)
}

