export default {
  tabList: [
    {
      label: '1周',
      incrLabel: '1周',
      value: '1w'
    },
    {
      label: '1月',
      incrLabel: '1月',
      value: '1m'
    },
    {
      label: '3月',
      incrLabel: '3月',
      value: '3m'
    },
    {
      label: '6月',
      incrLabel: '6月',
      value: '6m'
    },
    {
      label: '1年',
      incrLabel: '1年',
      value: '1y'
    },
    {
      label: '自定义',
      type: 'custom',
      value: 'z'
    }
  ],
}
