
import {
  defineComponent,
  onActivated,
  onBeforeUnmount,
  onDeactivated,
  onMounted,
  nextTick,
  watch
} from 'vue'
import resize from '@/components/charts/mixins/resize'
import { init, EChartsOption } from 'echarts'
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '400px',
      required: true
    },
    chartData: {
      type: Object,
      default: {}
    },
    selectedTime: {
      type: String || Number,
      default: ''
    },
  },
  setup(props, context) {
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()

    watch(props, (newName, oldName) => {
      initChart()
    });

    const initChart = () => {
      const xAxisList = Array.from((props.chartData as any), ({ date }) => date);
      const yAxisLeftList = Array.from((props.chartData as any), ({ szClose }) => szClose);
      const compositeIndex = yAxisLeftList.filter((item) => item !== null);
      const MaxArrpLeft = Math.ceil(Math.max(...compositeIndex));
      const MinArrpLeft = Math.floor(Math.min(...compositeIndex));
      const yAxisRightList = Array.from((props.chartData as any), ({ northboundFundsIn }) => northboundFundsIn);
      const integerData: any = [];
      yAxisRightList.map((item) => {
        let obj = {
          value: item,
          itemStyle: {
            color: item > 0 ? '#F74E4F' : '#5CBC7C'
          }
        }
        integerData.push(obj)
      })
      const iconUrl = 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAHCAYAAAFy0i3pAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAEqADAAQAAAABAAAABwAAAAAyqaqnAAAAR0lEQVQoFWNgwAYYY/bU/AdJLHFpYfzu5w9mYyiEq5o96TJYEi4AU4pXO0wR2TRR1jGRbTzNNGK4G90mULCBxHCGPLoGSvkA1Yoe51xpxlAAAAAASUVORK5CYII='
      const barChart = init(document.getElementById(props.id) as HTMLDivElement)
      barChart.setOption({
        grid: {
          top: '30px',
          left: '38px',
          right: '38px',
          bottom: '40px',
          containLabel: true
        },
        color: ['#AEAEAE', '#AEAEAE'],
        legend: {
          icon: 'line',
          itemWidth: 12,  // 设置宽度
          itemHeight: 6, // 设置高度
          x: 'center',
          bottom: '0',
          itemGap: 30,
          textStyle: {
            fontSize: 14,// 字体大小
            color:'#666666',
          },
          data: [
            {
              name: '北上净买入(亿元)',
              icon: iconUrl,
            },
            {
              name: '上证指数',
            }
          ]
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        xAxis: {
          data: xAxisList,
          axisLine: { show: false, onZero: false },
          splitLine: { show: false },
          axisLabel:{
            show: true,
            fontSize: 14,
            showMaxLabel:true, // 展示最大值
            showMinLabel:true, // 展示最小值
            formatter: function(value: any, index: any){
              if(index === 0) {
                return '        ' + value;
              }
              if(index === xAxisList.length -1) {
                return value + '        ';
              }
              return value
            }
          },
          axisTick: { show: false },
          splitArea: { show: false }
        },
        yAxis: [
          {
            type: 'value',
            splitLine: { show: true, lineStyle: { type: 'dashed' } },
            axisLabel: {
              show: true,
              fontSize: 14,
            }
          },
          {
            type: 'value',
            splitLine: { show: false },
            max: MaxArrpLeft + 50,
            min: MinArrpLeft - 50,
            axisPointer: {
              type: 'none',
              label: { show: false }
            },
            axisLabel: {
              show: true,
              fontSize: 14,
              // align: 'right',
            }
          },
        ],
        series: [
          {
            name: '北上净买入(亿元)',
            type: 'bar',
            yAxisIndex: 0,
            barWidth: 20,
            itemStyle: {
              normal: {
                color: '#F74E4F'
              }
            },
            data: integerData
          },
          {
            name: '上证指数',
            type: 'line',
            yAxisIndex: 1,
            symbol: 'none',
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 1.5,
                  color: '#AEAEAE'
                }
              }
            },
            data: compositeIndex
          },
        ]
      } as EChartsOption)
      chart.value = barChart
    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {
    }
  }
})
