<template>
    <div class="valuation-analysis-main">
        <div class="content">
            <div class="left">
                <el-row>
                    <el-col :span="8">
                        <NewTitle
                            :showTime="false"
                            title="个股估值排名"
                            :icon='true'
                            @goToList="toValuationList"
                            :isEmitJump="true"
                        />
                    </el-col>
                    <el-col :span="16" >
                        <div class="check-list">
                            <el-checkbox-group 
                                v-model="checkboxGroupYear1" 
                                size="small" 
                                fill="rgb(56, 111, 242)"
                            >
                                <el-checkbox-button v-for="keys in yearKeyList" :label="keys" :key="keys" @change="switchValuationYear('personal',keys)" >{{keys}}</el-checkbox-button>
                            </el-checkbox-group>
                        </div>
                    </el-col>
                    <el-col :span="24">
                        <div class="synthesize">
                            <ul>
                                <li>PETTM：</li>
                                <li 
                                    v-for="item in synthesizeValueList" 
                                    :key="item.key"
                                    @click="changeSynthesizeValue(item.key, 'personal')"
                                    :class="personalActiveKey === item.key ? 'is-active': ''"
                                >
                                    {{item.label}}
                                </li>
                                <el-popover
                                    placement="bottom"
                                    width="400"
                                    trigger="click"
                                    :visible="personalVisible"
                                    >
                                    <el-form size="small" :model="ruleForm" :rules="rules" ref="myForm" label-width="80px" class="demo-ruleForm">
                                        <el-form-item label="大于等于" prop="valuationFrom">
                                            <el-input v-model="ruleForm.valuationFrom" @input="changeCustomValue($event, 'personal', 'value1')"></el-input>
                                        </el-form-item>
                                        <el-form-item label="小于等于" prop="valuationTo">
                                            <el-input v-model="ruleForm.valuationTo" @input="changeCustomValue($event, 'personal', 'value2')"></el-input>
                                        </el-form-item>
                                        <div class="c-custom-tips">
                                            <span>最多精确到小数点后面两位</span>
                                        </div>
                                        <el-form-item style="margin-bottom:0px;text-algin:right;">
                                            <el-button size="mini" @click="resetForm('personal')">取消</el-button>
                                            <el-button size="mini" type="primary" :disabled="isDisabled1" @click="submitForm('personal')">保存</el-button>
                                        </el-form-item>
                                    </el-form>
                                    <template #reference>
                                        <li :class="personalActiveKey === 6 ? 'is-active': ''" @click="showPersonalPopover">自定义</li>
                                    </template>
                                </el-popover>
                            </ul>
                        </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:20px;">
                        <el-table
                            class="table-style-2 color0 cursor"
                            :data="dataList"
                            border
                            style="width: 100%;"
                            :header-cell-style="{background : '#FDE6E4'}"
                            :default-sort = "{prop: 'peTtm', order: 'ascending'}"
                            @sort-change="sortDataList"
                            @row-click="toValuationDetail"
                        >
                            <el-table-column
                                prop="name"
                                align="center"
                                label="股票名称"
                            />
                            <el-table-column
                                prop="valuation"
                                align="center"
                                label="综合估值"
                                sortable="custom"
                                :sort-orders="['ascending', 'descending']"
                            >
                                <template #default='{row}'>
                                    <div>{{row.valuation ?? '-'}}</div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="peTtm"
                                align="center"
                                label="PE-TTM"
                                sortable="custom"
                                :sort-orders="['ascending', 'descending']"
                            >
                                <template #default='{row}'>
                                    <div>{{row.peTtm ?? '-'}}</div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="quantile"
                                align="center"
                                label="分位数"
                                sortable="custom"
                                :sort-orders="['ascending', 'descending']"
                            />
                            <el-table-column
                                prop="boardName"
                                align="center"
                                label="所属行业"
                            >
                                <template #default='{row}'>
                                    <div>{{row.boardName || '-'}}</div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="boardValuation"
                                align="center"
                                label="行业估值"
                                sortable="custom"
                                :sort-orders="['ascending', 'descending']"
                            >
                                <template #default='{row}'>
                                    <div>{{row.boardValuation ?? '-'}}</div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-col>
                </el-row>
            </div>
            <div class="right">
                <el-row>
                    <el-col :span="8">
                        <NewTitle
                            :showTime="false"
                            title="行业估值排名"
                            :icon='true'
                            @goToList="toIndustryValList"
                            :isEmitJump="true"
                        />
                    </el-col>
                    <el-col :span="16">
                        <div class="check-list">
                            <el-checkbox-group 
                                v-model="checkboxGroupYear2" 
                                size="small" 
                                fill="rgb(56, 111, 242)"
                            >
                                <el-checkbox-button v-for="keys in yearKeyList" :label="keys" :key="keys" @change="switchValuationYear('industry', keys)" >{{keys}}</el-checkbox-button>
                            </el-checkbox-group>
                        </div>
                    </el-col>
                    <el-col :span="24">
                        <div class="synthesize">
                            <ul>
                                <li>PETTM：</li>
                                <li 
                                    v-for="item in synthesizeValueList" 
                                    :key="item.key"
                                    @click="changeSynthesizeValue(item.key, 'industry')"
                                    :class="industryActiveKey === item.key ? 'is-active': ''"
                                >
                                    {{item.label}}
                                </li>
                                <el-popover
                                    placement="bottom"
                                    width="400"
                                    trigger="click"
                                    :visible="industryVisible"
                                    >
                                    <el-form size="small" :model="ruleForm2" :rules="rules" ref="myForm2" label-width="80px" class="demo-ruleForm">
                                        <el-form-item label="大于等于" prop="valuationFrom">
                                            <el-input size="small" v-model="ruleForm2.valuationFrom" @input="changeCustomValue($event, 'industry', 'value1')"></el-input>
                                        </el-form-item>
                                        <el-form-item label="小于等于" prop="valuationTo">
                                            <el-input size="small" v-model="ruleForm2.valuationTo" @input="changeCustomValue($event, 'industry', 'value2')"></el-input>
                                        </el-form-item>
                                        <div class="custom-tips">
                                            <span>最多精确到小数点后面两位</span>
                                        </div>
                                        <el-form-item style="margin-bottom:0px;text-algin:right;">
                                            <el-button size="mini" @click="resetForm('industry')">取消</el-button>
                                            <el-button size="mini" type="primary" :disabled="isDisabled2" @click="submitForm('industry')">保存</el-button>
                                        </el-form-item>
                                    </el-form>
                                    <template #reference>
                                        <li :class="industryActiveKey === 6 ? 'is-active': ''" @click="showIndustryPopover">自定义</li>
                                    </template>
                                </el-popover>
                            </ul>
                        </div>
                    </el-col>
                    <el-col :span="24" style="margin-top:20px;">
                        <el-table
                            class="table-style-2 color0 cursor"
                            :data="dataList1"
                            border
                            style="width: 100%;"
                            :header-cell-style="{background : '#FDE6E4'}"
                            :default-sort = "{prop: 'peTtm', order: 'ascending'}"
                            @sort-change="sortDataList2"
                            @row-click="toIndustryList"
                        >
                            <el-table-column
                                prop="name"
                                align="center"
                                label="行业名称"
                            />
                            <el-table-column
                                prop="valuation"
                                align="center"
                                label="综合估值"
                                sortable="custom"
                                :sort-orders="['ascending', 'descending']"
                            >
                                <template #default='{row}'>
                                    <div>{{row.valuation ?? '-'}}</div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="peTtm"
                                align="center"
                                label="PE-TTM"
                                sortable="custom"
                                :sort-orders="['ascending', 'descending']"
                            >
                                <template #default='{row}'>
                                    <div>{{row.peTtm ?? '-'}}</div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="quantile"
                                align="center"
                                label="分位数"
                                sortable="custom"
                                :sort-orders="['ascending', 'descending']"
                            >
                                <template #default='{row}'>
                                    <div>{{row.quantile ?? '-'}}</div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent, reactive, toRefs, ref, unref } from 'vue'
import CTitle from '@/components/title/Index.vue'
import NewTitle from '@/components/new-title/Index.vue'
import { getValuationStockQueryV2, getValuationIndustryRankingV2 } from '@/apis/home'

export default defineComponent({
    props: {
        dataMap: {
            type: Array,
            default: []
        }
    },
    components: {
        CTitle,
        NewTitle,
    },
    setup() {
        const yearKeyMap = ['3Y','5Y','10Y'];
        const synthesizeValueMap = [{
            key: 0,
            label: '0以下',
            jumpKey: '2-0',
            valuationFrom: -1000000,
            valuationTo: -0.001
        },{
            key: 1,
            label: '0-10',
            jumpKey: '2-1',
            valuationFrom: 0,
            valuationTo: 10
        },{
            key: 2,
            label: '10-20',
            jumpKey: '2-2',
            valuationFrom: 10,
            valuationTo: 20
        },{
            key: 3,
            label: '20-30',
            jumpKey: '2-3',
            valuationFrom: 20,
            valuationTo: 30
        },{
            key: 4,
            label: '30-50',
            jumpKey: '2-4',
            valuationFrom: 30,
            valuationTo: 50
        },{
            key: 5,
            label: '50以上',
            jumpKey: '2-5',
            valuationFrom: 50.001,
            valuationTo: 1000000
        }]
        const myForm = ref(null);
        const myForm2 = ref(null);

        const dataMap = reactive({
            checkboxGroupYear1: ['3Y'],
            checkboxGroupYear2: ['3Y'],
            yearKeyList: yearKeyMap,
            synthesizeValueList: synthesizeValueMap,
            personalActiveKey: 2,
            personalVisible: false,
            industryActiveKey: 2,
            industryVisible: false,
            dataList: [],
            dataList1: [],
            ruleForm: {
                valuationFrom: '',
                valuationTo: ''
            },
            ruleForm2: {
                valuationFrom: '',
                valuationTo: ''
            },
            rules:  {
                valuationFrom: [
                    { pattern: /^(0|([1-9][0-9]*))(\.[\d]{1,2})?$/, message: '请输入正确的值' }
                ],
                valuationTo: [
                    { pattern: /^(0|([1-9][0-9]*))(\.[\d]{1,2})?$/, message: '请输入正确的值' }
                ]
            },
            sortPage: {
                sortKey: 'peTtm',
                isAsc: true
            },
            sortPage2: {
                sortKey: 'peTtm',
                isAsc: true
            },
            isDisabled1: true,
            isDisabled2: true,
            isVaild1: {
                value1: false,
                value2: false
            },
            isVaild2: {
                value1: false,
                value2: false
            },
            
            // 切换估值年份条件
            switchValuationYear: (type:String, val:any) => {
                if(type === 'personal'){
                    dataMap.checkboxGroupYear1 = [];
                    dataMap.checkboxGroupYear1.push(val);
                    getValuationStockQuery();
                }else {
                    dataMap.checkboxGroupYear2 = [];
                    dataMap.checkboxGroupYear2.push(val);
                    getValuationIndustryRanking();
                }
                
            },

            // 个股
            showPersonalPopover: () => {
                dataMap.personalVisible = true;
            },

            // 行业
            showIndustryPopover: () => {
                dataMap.industryVisible = true;
            },
            
            // 选择综合估值
            changeSynthesizeValue: (key: any, type:String) => {
                dataMap.personalVisible = false;
                dataMap.industryVisible = false;
                dataMap.ruleForm.valuationFrom = '';
                dataMap.ruleForm.valuationTo = '';
                dataMap.ruleForm2.valuationFrom = '';
                dataMap.ruleForm2.valuationTo = '';
                if(type === 'personal'){
                    dataMap.personalActiveKey = key;
                    getValuationStockQuery();
                }else {
                    dataMap.industryActiveKey = key;
                    getValuationIndustryRanking();
                }
            },

            // 个股筛选排序
            sortDataList: (column: any) => {
                dataMap.sortPage = {
                    sortKey: column.prop,
                    isAsc: column.order === 'ascending'
                };
                getValuationStockQuery();
            },

            // 行业筛选排序
            sortDataList2: (column: any) => {
                dataMap.sortPage2 = {
                    sortKey: column.prop,
                    isAsc: column.order === 'ascending'
                }
                getValuationIndustryRanking();
            },

            submitForm: (type: String) => {
                if(type === 'personal') {
                    let { validate } = myForm.value as any;
                    validate((valid:any) => {
                        if (valid) {
                            const { ruleForm } = dataMap;
                            if(ruleForm.valuationFrom || ruleForm.valuationTo){
                                dataMap.personalActiveKey = 6;
                                dataMap.personalVisible = false;
                                getValuationStockQuery();
                            }
                        } else {
                            console.log('error submit!!');
                            return false;
                        }
                    });
                }else {
                    let { validate } = myForm2.value as any;
                    validate((valid:any) => {
                        if (valid) {
                            const { ruleForm2 } = dataMap;
                            if(ruleForm2.valuationFrom || ruleForm2.valuationTo){
                                dataMap.industryActiveKey = 6;
                                dataMap.industryVisible = false;
                                getValuationIndustryRanking();
                            }
                        } else {
                            console.log('error submit!!');
                            return false;
                        }
                    });
                }
                
            },
            resetForm: (type: String) => {
                if(type === 'personal'){
                    dataMap.personalVisible = false;
                    let { resetFields } = myForm.value as any;
                    resetFields();
                    dataMap.personalActiveKey = dataMap.personalActiveKey;
                    dataMap.isDisabled1 = true;
                    // getValuationStockQuery();
                }else {
                    dataMap.industryVisible = false;
                    dataMap.isDisabled2 = true;
                    let { resetFields } = myForm2.value as any;
                    resetFields();
                    dataMap.industryActiveKey = dataMap.industryActiveKey;
                    // getValuationIndustryRanking();
                }
            }
        })

        // 输入框输入
        const changeCustomValue = (e:any, type:String, valType: String) => {
            const reg = /^(0|([1-9][0-9]*))(\.[\d]{1,2})?$/; 
            const result = reg.test(e);
            console.log(e, type, valType);
            const { ruleForm, ruleForm2, isVaild1, isVaild2 } = dataMap;
            if(type === 'personal') {
                isVaild1[`${valType}`] = !result;
                if(!ruleForm.valuationFrom && !ruleForm.valuationTo){
                    dataMap.isDisabled1 = true;
                }else{
                    if(ruleForm.valuationFrom && !isVaild1.value1 && ruleForm.valuationTo && isVaild1.value2){
                        dataMap.isDisabled1 = true;
                    }else if(ruleForm.valuationFrom && isVaild1.value1 && ruleForm.valuationTo && !isVaild1.value2){
                        dataMap.isDisabled1 = true;
                    }else if(ruleForm.valuationFrom && isVaild1.value1 && !ruleForm.valuationTo){
                        dataMap.isDisabled1 = true;
                    }else{
                        dataMap.isDisabled1 = false;
                    }
                }
            }else {
                isVaild2[`${valType}`] = !result;
                if(!ruleForm2.valuationFrom && !ruleForm2.valuationTo){
                    dataMap.isDisabled2 = true;
                }else{
                    if(ruleForm2.valuationFrom && !isVaild2.value1 && ruleForm2.valuationTo && isVaild2.value2){
                        dataMap.isDisabled2 = true;
                    }else if(ruleForm2.valuationFrom && isVaild2.value1 && ruleForm2.valuationTo && !isVaild2.value2){
                        dataMap.isDisabled2 = true;
                    }else if(ruleForm2.valuationFrom && isVaild2.value1 && !ruleForm2.valuationTo){
                        dataMap.isDisabled2 = true;
                    }else{
                        dataMap.isDisabled2 = false;
                    }
                }
            }
            
        }

        // 获取个股价值排名
        const getValuationStockQuery = async () => {
            const obj = (synthesizeValueMap || []).find((item) => item.key === dataMap.personalActiveKey);
            const { ruleForm, sortPage } = dataMap;
            const newYear = dataMap.checkboxGroupYear1[0].replace('Y', 'y');
            const data = await getValuationStockQueryV2({ 
                timeLevel: newYear,
                from: dataMap.personalActiveKey === 6 ? ruleForm.valuationFrom : obj?.valuationFrom,
                to: dataMap.personalActiveKey === 6 ? ruleForm.valuationTo :  obj?.valuationTo,
                sortKey: sortPage.sortKey,
                isAsc: sortPage.isAsc
            })
            dataMap.dataList = (data as any).data;
            // ruleForm.valuationFrom = ''
            // ruleForm.valuationTo = ''
        }

        // 获取行业估值排名
        const getValuationIndustryRanking = async () => {
            const obj = (synthesizeValueMap || []).find((item) => item.key === dataMap.industryActiveKey);
            const { ruleForm2, sortPage2 } = dataMap;
            const newYear = dataMap.checkboxGroupYear2[0].replace('Y', 'y');
            const data = await getValuationIndustryRankingV2({ 
                timeLevel: newYear,
                from: dataMap.industryActiveKey === 6 ? ruleForm2.valuationFrom : obj?.valuationFrom,
                to: dataMap.industryActiveKey === 6 ? ruleForm2.valuationTo :  obj?.valuationTo,
                sortKey: sortPage2.sortKey,
                isAsc: sortPage2.isAsc
            })
            dataMap.dataList1 = (data as any).data;
            // ruleForm2.valuationFrom = '';
            // ruleForm2.valuationTo = '';
        }

        const fetchData = async () => {
            await getValuationStockQuery();
            await getValuationIndustryRanking();
        }
        fetchData();

        // to估值分析（个股估值排名）
        const toValuationList = () => {
            const { personalActiveKey, ruleForm } = dataMap;
            const obj = (synthesizeValueMap || []).find((item) => item.key === personalActiveKey);
            const from = personalActiveKey === 6 ? ruleForm.valuationFrom : obj?.valuationFrom;
            const to = personalActiveKey === 6 ? ruleForm.valuationTo :  obj?.valuationTo;
            const jumpKey = personalActiveKey === 6 ? '2-6' : obj?.jumpKey;
            if(from || to){
                window.open(`/investment/analysis/index?checkLevel=${dataMap.checkboxGroupYear1[0]}&jumpKey=${jumpKey}&isCustom=${personalActiveKey === 6 ? 1 : 2}&section=${from},${to}`);
            }else{
                window.open(`/investment/analysis/index?checkLevel=${dataMap.checkboxGroupYear1[0]}`);
            }
        }

        // 跳转个股估值详情页面
        const toValuationDetail = (column:any) => {
            window.open(`/investment/analysis/detail?code=${column.code}&timeLevel=${dataMap.checkboxGroupYear1[0]}`);
        }
        
        // to估值分析（列表行业点击）
        const toIndustryList = (row:any) => {
            const { industryActiveKey, ruleForm2 } = dataMap;
            const obj = (synthesizeValueMap || []).find((item) => item.key === industryActiveKey);
            const from = industryActiveKey === 6 ? ruleForm2.valuationFrom : obj?.valuationFrom;
            const to = industryActiveKey === 6 ? ruleForm2.valuationTo :  obj?.valuationTo;
            const jumpKey = industryActiveKey === 6 ? '2-6' : obj?.jumpKey;
            if(from || to){
                window.open(`/investment/analysis/index?checkLevel=${dataMap.checkboxGroupYear2[0]}&jumpKey=${jumpKey}&isCustom=${industryActiveKey === 6 ? 1 : 2}&section=${from},${to}&industryName=${row.name}`);
            }else{
                window.open(`/investment/analysis/index?checkLevel=${dataMap.checkboxGroupYear1[0]}&industryName=${row.name}`);
            }
            
        }

        // to估值分析（行业估值排名）
        const toIndustryValList = () => {
            const { industryActiveKey, ruleForm2 } = dataMap;
            const obj = (synthesizeValueMap || []).find((item) => item.key === industryActiveKey);
            const from = industryActiveKey === 6 ? ruleForm2.valuationFrom : obj?.valuationFrom;
            const to = industryActiveKey === 6 ? ruleForm2.valuationTo :  obj?.valuationTo;
            const jumpKey = industryActiveKey === 6 ? '2-6' : obj?.jumpKey;
            if(from || to){
                window.open(`/investment/analysis/index?checkLevel=${dataMap.checkboxGroupYear2[0]}&jumpKey=${jumpKey}&isCustom=${industryActiveKey === 6 ? 1 : 2}&section=${from},${to}`);
            }else{
                window.open(`/investment/analysis/index?checkLevel=${dataMap.checkboxGroupYear1[0]}`);
            }
        }

        return {
            ...toRefs(dataMap),
            toValuationList,
            toIndustryList,
            toIndustryValList,
            toValuationDetail,
            changeCustomValue,
            myForm,
            myForm2,
        }
    }
})

</script>

<style lang="scss" scoped>
.valuation-analysis-main{
    .content{
        display: flex;
        justify-content: space-between;
        .left{
            width: 48%;
        }
        .right{
            width: 48%;
        }
        .check-list{
            text-align: right;
        }
        .synthesize{
            padding: 10px 15px;
            background: #FFFFFF;
            border: 1px solid #EEEEEE;
            ul{
                display: flex;
                padding: 0;
                margin: 0;
                li{
                    font-size: 14px;
                    font-weight: 500;
                    color: #333333;
                    margin-right: 25px;
                }
                li:not(:first-child):hover{
                    cursor: pointer;
                }
                .is-active{
                    color: #369DE4;
                }
            }
        }
    }
}
@media (max-width: 1350px){
    .valuation-analysis-main{
        .content {
            flex-direction: column;
            .left{
                width: 100%;
            }
            .right{
                margin-top: 20px;
                width: 100%;
            }
        }
    }
}
</style>