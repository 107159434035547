<template>
  <div class="app-container">
    <div class="bg">
      <div class="title">
        {{stockList?.reportName}}
      </div>
      <div class="des">
        <div class="organization ccc">
          {{stockList.organization}}
        </div>
        <div class="industry">
          {{stockList.industry}}
        </div>
        <div class="reporter ccc">
          {{stockList.reporter}}
        </div>
        <div class="btn">
          <div class="zy" :class="active === 0 ? 'active' : ''" @click="handleTab(0)">摘要</div>
          <div class="yw" :class="active === 1 ? 'active' : ''" @click="handleTab(1)" v-if="stockList.url">原文</div>
        </div>
        <div class="open" @click="openPdf" v-if="stockList.url">
          <img src="@/assets/images/pdf.png" width="18" />
          <span>打开原文PDF</span>
        </div>
        <div class="date ccc">
          {{stockList.date}}
        </div>
      </div>
      <div class="content">
        <template v-if="active === 0">
          <CTitle :mode='6' title="摘要" ></CTitle>
          <div class="text" v-html="stockList.content"></div>
        </template>
        <template v-else>
          <div class="iframe-box" style="padding: 30px 0px 5px; height: 100vh;">
            <!-- <pdf  :src="stockList.url" ></pdf> -->

            <iframe :src="stockList.url + '#page=1&toolbar=0&statusbar=0&width=100%'"  frameborder="0" marginheight="0" scrolling="no"></iframe>
          </div>
        </template>

      </div>
    </div>
  </div>
</template>

<script lang="ts">

import { defineComponent, reactive, toRefs } from 'vue'
import Search from '@/components/search-input/Index.vue'
import CTitle from '@/components/title/Index.vue'
import RecommendList from './components/RecommendList.vue'
import CTable from './components/Table.vue'
import AgencyRating from '@/views/agency/components/AgencyRating.vue'
import { getStockReportDetail, getBoardStockReportDetail } from '@/apis/agency'
import { useRoute } from 'vue-router'
// import pdf from 'vue-pdf'

export default defineComponent({
  components: {
    CTitle,
    RecommendList,
    CTable,
    Search,
    AgencyRating,
    // pdf
  },
  setup() {
    const route = useRoute()
    const dataMap = reactive({
      stockList: [],
      active: 0
    })

    const fetchData = async () => {
      console.log(route.query.page)
      if( route.query.page && route.query.page === 'alla' ){
        const data = await getBoardStockReportDetail({ reportId: route.query.reportId })
        if( data ){
          dataMap.stockList = (data as any).data;
        }
        return false
      }
      const data = await getStockReportDetail({ reportId: route.query.reportId })
      if( data ){
        dataMap.stockList = (data as any).data;
      }
    }
    fetchData()

    const openPdf = () => {
      window.open(dataMap.stockList['url']+'#page=1&toolbar=0&statusbar=0')
    }

    const handleTab = (idx:any) => {
      dataMap.active = idx
    }


    return {
      ...toRefs(dataMap),
      openPdf,
      handleTab
    }
  }
})
</script>
<style lang='scss' scoped>
$blue: #386FF2;
.bg{
  background: #fff;
  padding: 38px 32px 54px;
}
.title{
  font-size: 24px;
  font-weight: 400;
  color: #333333;
}
.des{
  // display: flex;
  padding-bottom: 10px;
  margin: 20px 0 15px;
  border-bottom: 1px solid #E9E9E9;
  &>div{
    margin-right: 50px;
    margin-bottom: 10px;
    display: inline-block;
    &.ccc{
      font-size: 14px;
      font-weight: 400;
      color: #999999;
    }
  }
  .industry{
    color: $blue;
    padding: 0 6px;
    // height: 18px;
    line-height: 18px;
    border-radius: 2px;
    border: 1px solid $blue;
  }
  .btn{
    display: inline-block;
    div{
      width: 40px;
      // height: 18px;
      text-align: center;
      border: 1px solid #D9D9D9;
      cursor: pointer;
      display: inline-block;
      &.zy{
        border-radius: 2px 0px 0px 2px;
      }
      &.yw{
        border-radius: 0px 2px 2px 0px;
      }
      &.active{
        border: 1px solid $blue;
        color: $blue;
      }
    }
  }
  .open{
    margin-right: 120px;
    font-size: 12px;
    font-weight: 400;
    color: $blue;
    display: inline-block;
    align-items: center;
    cursor: pointer;
    img{
      margin-right: 7px;
      vertical-align: middle;
    }
  }
}
.content{
  ::v-deep .mode .mode-6{
    padding-left: 15px;
  }
  .text{
    margin-top: 20px;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 36px;
  }
  .iframe-box{
    max-width: 800px;
    // margin: auto;
  }
  iframe{
    width: 100%;
    height: 100%;
  }
}
</style>
