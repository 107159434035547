const asideList = [
  {
    text: '公司评级',
    icon: 'gspj',
    detailUrl: '/agency/detail'
  },
  {
    text: '机构调研',
    icon: 'jgdy',
    detailUrl: '/research/detail'
  },
  {
    text: '估值分析',
    icon: 'gzfx',
    detailUrl: '/analysis/detail'
  },
  {
    text: '财务指标',
    icon: 'cwzb',
    detailUrl: '/financial/detailed'
  },
  {
    text: '北向资金',
    icon: 'bxzj',
    detailUrl: '/northboundFunds/shares'
  },
  {
    text: '融资融券',
    icon: 'rzrj',
    detailUrl: '/marginTrading/shares'
  }
]

export {
  asideList
}
