
import {
  defineComponent,
  toRefs,
  reactive
} from 'vue'
import CPagination from "@/components/pagination/Index.vue"
import CustomTab from '../../components/CustomTab.vue'
import { getFundsStockList } from '@/apis/northboundFunds'
import { useRoute } from "vue-router";
export default defineComponent({
  components: {
    CPagination,
    CustomTab
  },
  setup(props, context) {
    const route = useRoute();
    const dataMap = reactive({
      loading: false,
      stockList: [

      ],
    })

    let params = {
      stockCode: route.query.code,//'000913.SZ',
      timeUnit: '',
      orderBy: 'date',
      order: 'DESC',
      pageNum: '1',
      pageSize: '10'
    }

    const fetchData = async (value:any) => {
      if( value && value.pageNum ){
        params['pageNum'] = value.pageNum
      }
      if( value && value.pageSize ){
        params['pageSize'] = value.pageSize
      }

      dataMap.loading = true
      const list = await getFundsStockList(params)
      dataMap.stockList = (list as any).data;
      dataMap.loading = false
    }

    const sortChange = (column: any) => {
      let isAsc = column.order === "ascending" ? 'ASC' : 'DESC';
      params['orderBy'] = column["prop"]
      params['order'] = isAsc
      fetchData(undefined)
    };

    const addClass = (column:any) => {
      if( column.column.property === params.orderBy ){
        return 'sort-bg'
      }
    }

    return {
      ...toRefs(dataMap),
      fetchData,
      sortChange,
      addClass
    }
  }
})
