<template>
  <div class="">
    <template v-if="stockList1">
      <CTitle :mode='6' title="资金动向" ></CTitle>
      <div class="t-content" v-if="ajaxRes">
        <div class="left">
          <div class="des">今日主力{{stockList1.flowFund.warehouse}}，行业资金呈{{stockList1.flowFund.capital}}状态</div>
          <ul class="t-list">
            <li>今日主力净流入{{stockList1.flowFund.majorNetFlow?stockList1.flowFund.majorNetFlow:0}}元，占流通盘比{{stockList1.flowFund.mnfOccuMktVal?stockList1.flowFund.mnfOccuMktVal:0}}</li>
            <li>五日主力净流入{{stockList1.flowFund.day5MajorNetFlow?stockList1.flowFund.day5MajorNetFlow:0}}元，占流通比{{stockList1.flowFund.day5MnfOccuMktVal?stockList1.flowFund.day5MnfOccuMktVal:0}}</li>
            <li>所属{{stockList1.flowFund.industryName}}行业，五日行业资金流入共{{stockList1.flowFund.industryDay5MajorNet}}元</li>
          </ul>
          <div class="des des-b">今日无明显成交活跃时间段，全天统计如下</div>
          <ul class="b-list">
            <li>
              <div>涨跌幅</div>
              <div v-dClass='stockList1.flowFund.rchange'>{{stockList1.flowFund.rchange}}</div>
            </li>
            <li>
              <div>成交活跃价格</div>
              <div>{{stockList1.flowFund.activePrice}}</div>
            </li>
            <li>
              <div>成交活跃手数</div>
              <div>{{stockList1.flowFund.activeCount}}</div>
            </li>
          </ul>
        </div>
        <div class="right">
          <view class="content_item_type">
            <view class="content_item_type_item">
              <view class="icon"></view>
              <view class="text">资金净流入</view>
            </view>
            <view class="content_item_type_item">
              <view class="icon"></view>
              <view class="text">资金净流出</view>
            </view>
            <view class="content_item_type_item">
              <view class="icon"></view>
              <view class="text">行业平均线</view>
            </view>
          </view>
          <LineChartG id='jggd-t' :chartData='stockList1' @getTimeList='getTimeList'></LineChartG>
          <view class="content_item_timebox">
          <view class="content_item_timebox_item" v-for="(item,idx) in timearr" :key="idx">{{item}}</view>
        </view>
        </div>
      </div>
      <CTitle :mode='6' title="机构持仓" style="margin-top:50px"></CTitle>
      <div class="t-content">
        <div class="left vhtml">
          <div v-html="stockList1.orgHold"></div>
        </div>
        <div class="right">
          <view class="content_item_type">
            <view class="content_item_type_item">
              <view class="icon"></view>
              <view class="text">基金</view>
            </view>
            <view class="content_item_type_item">
              <view class="icon blue"></view>
              <view class="text">其他机构</view>
            </view>
            <view class="content_item_type_item">
              <view class="icon orange"></view>
              <view class="text">股价</view>
            </view>
          </view>
          <LineChartH id='jggd-t1' width='100%' :chartData='stockList1'></LineChartH>
        </div>
      </div>
    </template>
    <div  class="wushuju_box" v-else>
      <img class="wushuju" src="http://resource.jufxt.com/static/zhengu/home/wushuju.png" />
      <div class="wushuju_text">该项目暂无数据</div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue'
import CTitle from '@/components/title/Index.vue'
import LineChartG from '@/components/charts/LineChartG.vue'
import LineChartH from '@/components/charts/LineChartH.vue'
import { getDiagnosticAggregation } from '@/apis/diagnosis'
import { useRoute } from 'vue-router'

export default defineComponent({
  components: {
    CTitle,
    LineChartG,
    LineChartH
  },
  setup() {
    const route = useRoute()
    const stockCode = route.query.code//'600519.SH'
    // const stockCode = '600751.SH'
    const dataMap = reactive({
      ajaxRes: false,
      stockList1: {},
      timearr: []
    })
    const fetchData = async () => {
      console.log('dddd')
      const data1 = await getDiagnosticAggregation({ stockCode: stockCode })
      dataMap.stockList1 = (data1 as any).data;
      dataMap.ajaxRes = true;
    }
    fetchData()

    const getTimeList = (res:any) => {
      dataMap.timearr = res
    }

    return {
      ...toRefs(dataMap),
      getTimeList
    }
  }
})
</script>
<style scoped lang='scss'>
.t-content{
  display: flex;
  margin-left: 12px;
  &>div{
    // width: 520px;
    flex: 1;
  }
  .vhtml{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 26px;
  }
  .left{
    // margin-right: 173px;
    margin-right: 50px;
    .des{
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      margin-bottom: 14px;
      &.des-b{
        margin-top: 35px;
      }
    }
    .t-list{
      li{
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        position: relative;
        padding-left: 10px;
        margin-bottom: 8px;
        &::after{
          width: 4px;
          height: 5px;
          background: linear-gradient(135deg, #FF7045 0%, #FB0A0A 100%);
          margin:auto;
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
        }
      }
    }
    .b-list{
      display: flex;
      justify-content: space-between;
      max-width: 400px;
      li{
        // width: 120px;
        width: 30%;
        height: 48px;
        background: #F3F5F9;
        border-radius: 4px;
        opacity: 0.7;
        display: flex;
        align-content: center;
        flex-direction: column;
        justify-content: center;
        // margin-right: 15px;
        div{
          text-align: center;
          &:first-child{
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            padding-bottom: 2px;
          }
          &:last-child{
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            // color: #FB160C;
          }
        }
        &:not(:first-child){
          div:last-child{
            color: #F6C52A;
          }
        }
      }
    }
  }
  .right{
    margin-left: 50px;
  }
}

.content_item_type{
  display: flex;
  align-items: center;
  .content_item_type_item{
    margin-right: 20px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    &:nth-child(2) .icon{
      background: #02C165;
    }
    &:last-child .icon{
      background: #3B7FEE;
    }
  }
  .icon{
    width: 12px;
    height: 12px;
    background: #FB160C;
    border-radius: 50%;
    margin-right: 5px;
    &.orange{
      background: #FE9625!important;
    }
    &.blue{
      background: #3B7FEE!important;
    }
  }
}

.content_item_timebox{
  padding: 0 12px 0 30px;
  margin-top: 0;
}


</style>
<style>
.cc00c00{
    color: #c00c00;
}
.c008102{
    color: #008102;
}
.c074e84{
    color: #666666;
}
</style>