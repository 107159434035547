<template>
  <div
    :id="id"
    :class="className"
    :style="{height: height, width: width}"
  />
</template>

<script lang="ts">
import { defineComponent, onActivated, onBeforeUnmount, onDeactivated, onMounted, nextTick, watch } from 'vue'
import resize from './mixins/resize'
import { init, EChartsOption } from 'echarts'
import * as echarts from 'echarts'
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '550px',
      required: true
    },
    height: {
      type: String,
      default: '200px',
      required: true
    },
    chartData: {
      type: Object,
      default: {}
    },
  },
  setup(props) {
    watch(props, (newName, oldName) => {
      initChart()
    });

    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()
    const initChart = () => {
      if( !props.chartData.marketChart ){
        return false;
      }
      const barChart = init(document.getElementById(props.id) as HTMLDivElement)
      let macdList = props.chartData.marketChart.data
      let title = props.chartData.marketChart.title
      let seriesdata1 = [],
          seriesdata2 = [],
          seriesdata3 = [],
          timedata=[],
          max:any='',
          min:any=''
      if(macdList&&macdList.length>0){

        for (let i = 0; i < macdList.length; i++) {
          const item = macdList[i];
          seriesdata1.push(item.stock)
          seriesdata2.push(item.trade)
          seriesdata3.push(item.ind)
          timedata.push(item.date)
          if(i==0){
            // starttime = item.date
            max = item.stock*1
            min = item.stock*1
            if(max<item.trade*1){
              max = item.trade*1
            }
            if(max<item.ind*1){
              max = item.ind*1
            }
            if(min>item.trade*1){
              min = item.trade*1
            }
            if(min>item.ind*1){
              min = item.ind*1
            }
          }else{
            if(max<item.stock*1){
              max = item.stock*1
            }
            if(max<item.trade*1){
              max = item.trade*1
            }
            if(max<item.ind*1){
              max = item.ind*1
            }
            if(min>item.stock*1){
              min = item.stock*1
            }
            if(min>item.trade*1){
              min = item.trade*1
            }
            if(min>item.ind*1){
              min = item.ind*1
            }
          }
         
        }
      }
      max = ((Math.floor(max*140)+(Math.floor(max*140)%5==0?0:(5-(Math.floor(max*140)%5))))/100).toFixed(2)
      min = ((Math.floor(min>0?min*80:min*120)+(Math.floor(min>0?min*80:min*120)%5==0?0:(5-(Math.floor(min>0?min*80:min*120)%5))))/100).toFixed(2)
      barChart.setOption({
        color:['#0086FB','#333333','#FE9625'],      //关键加上这句话，legend的颜色和折线的自定义颜色就一致了
        legend: {
          show: true,
          icon: 'circle',
          x: '0',
          left: '0',
          textStyle: {
            align: 'left', // 这个是可以左右调整的
            fontSize: 14,// 字体大小
            // padding:[0,0,25,-45]//主要就是用这个，可以把文字上下调整
            color: '#666666'//字体颜色
          }
        },
        tooltip: {
          show: true,
          trigger: 'axis', 
          position:'inside',
          padding:4,
          textStyle:{
            color:'#999999',
            fontSize:'10',
          },
          // posi1tion:function(point,params,dom,rect){
          //   let x = 0
          //   let y= 0
          //   if(point[0]>140){
          //     x = point[0] - 100
          //   }else{
          //     x = point[0] +10
          //   }
          //   if(point[1]>70){
          //     y = point[1] - 100
          //   }else{
          //     y = point[1]
          //   }
          //   return [x,y]
          // },
          // formatter:function(param){
          //  // 利用循环更改显示的内容
          //   let list = param.length
          //   let Oparam=param
          //   let txt =" "+ param[0].name+"\n"
          //   for (var i=0;i<list;i++){
          //    if(i==list-1){
          //     txt +=param[i].marker+ param[i].seriesName + ":" + param[i].data+'%'
          //    }else{
          //      txt +=param[i].marker+ param[i].seriesName + ":" + param[i].data+'%' + '\n'
          //    }
          //   }
          //   return txt
          // }
        },
        grid: {
          left: 0,
          right: 0,
          bottom: 15,
          top: 50,
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: timedata,
            show:false,
            axisLine: {
              lineStyle: {
                color: '#999',
                width:1,
              }
            },
            axisLabel: {
                show:false,
                color: '#666'
            },
            axisTick:{
                show:false
            },
    
          }
        ],
        yAxis: [
          {
            type: 'value',
            show:true,
            axisTick: { show: false },
            // axisLine: {
            //   lineStyle: {
            //     color: 'red'
            //   }
            // },
            min:min,
            max:max,
            interval:(max-min)/4,
            axisLabel:{
              color: '#0E1018',
              formatter: function(item:any){
                return item.toFixed(2) +'%'
              },
            },
            splitLine: {
                lineStyle: {
                    // type: 'dashed'
                    color: '#CCCCCC',
                    width:1,
                }
            }
          }
        ],
        series: [
          {
            name: title.stock,
            type: 'line',
            smooth: true,  //这个可以显示为平滑过渡的线条
            showSymbol:false,
            itemStyle:{
              normal:{
                lineStyle:{
                  width:1,
                  barBorderRadius:0,
                },
              },
              emphasis:{
                lineStyle:{
                  width:1,
                },
              }
            },
            data:seriesdata1
          },
          {
            name: title.trade,
            type: 'line',
            smooth: true,  //这个可以显示为平滑过渡的线条
            showSymbol:false,
            itemStyle:{
              normal:{
                lineStyle:{
                  width:1,
                  barBorderRadius:0,
                },
              },
              emphasis:{
                lineStyle:{
                  width:1,
                },
              }
            },
            data:seriesdata2
          },
          {
            name: title.ind,
            type: 'line',
            smooth: true,  //这个可以显示为平滑过渡的线条
            showSymbol:false,
            itemStyle:{
              normal:{
                lineStyle:{
                  width:1,
                  barBorderRadius:0,
                },
              },
              emphasis:{
                lineStyle:{
                  width:1,
                },
              }
            },
            data:seriesdata3
          }
        ]
      } as unknown as EChartsOption)
      chart.value = barChart
    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {

    }
  }
})
</script>

<style lang="scss" scoped>

</style>
