<template>
  <div class="recommend">
    <div class="b-title">
      <CTitle
        :mode="3"
        title="新财富分析师推荐"
        @change='changeHandle'
      />
    </div>
    <div class="b-list-box">
      <div class="b-list" v-if="stockList1.buyRankings && stockList1.buyRankings.length">
        <div class="padding" v-for="(item) in stockList1.buyRankings" :key="item.id" @click="goDetail(item)">
          <div class="content">
            <div class="t">
              <div class="left">
                <p>{{item.name}}</p>
                <p>{{item.code.split('.')[0]}}</p>
              </div>
              <div class="right">
                <p v-dClass='item[activeKey]'>{{$stock.numFilter(item[activeKey])}}</p>
                <!-- <p>+19.7%</p> -->
                <p>近一个月涨跌幅</p>
              </div>
            </div>
            <div class="b1">
              {{item.reportTitle}}
            </div>
            <div class="b2">
              <div v-for="(innerItem,innerIdx) in item.tags" :key="innerIdx">{{innerItem}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="b-list" v-if="stockList1.upRankings && stockList1.upRankings.length">
        <div class="padding" v-for="(item) in stockList1.upRankings" :key="item.id"  @click="goDetail(item)">
          <div class="content" >
            <div class="t">
              <div class="left">
                <p>{{item.name}}</p>
                <p>{{item.code.split('.')[0]}}</p>
              </div>
              <div class="right">
                <p v-dClass='item[activeKey]'>{{$stock.numFilter(item[activeKey])}}</p>
                <!-- <p>+19.7%</p> -->
                <p>近一个月涨跌幅</p>
              </div>
            </div>
            <div class="b1">
              {{item.reportTitle}}
            </div>
            <div class="b2">
              <div v-for="(innerItem,innerIdx) in item.tags" :key="innerIdx">{{innerItem}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="b-list" v-if="stockList1.firstRankings && stockList1.firstRankings.length">
        <div class="padding" v-for="(item) in stockList1.firstRankings" :key="item.id"  @click="goDetail(item)">
          <div class="content" >
            <div class="t">
              <div class="left">
                <p>{{item.name}}</p>
                <p>{{item.code.split('.')[0]}}</p>
              </div>
              <div class="right">
                <p v-dClass='item[activeKey]'>{{$stock.numFilter(item[activeKey])}}</p>
                <!-- <p>+19.7%</p> -->
                <p>近一个月涨跌幅</p>
              </div>
            </div>
            <div class="b1">
              {{item.reportTitle}}
            </div>
            <div class="b2">
              <div v-for="(innerItem,innerIdx) in item.tags" :key="innerIdx">{{innerItem}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import { defineComponent, reactive, toRefs } from 'vue'
import CTitle from '@/components/title/Index.vue'
import { getAnalystRecommendList } from '@/apis/home'
import dicts from '@/utils/dicts'

export default defineComponent({
  props: {
    dataMap: {
      type: Array,
      default: []
    }
  },
  components: {
    CTitle
  },
  setup() {
    // const keyTimeMap = ['1m','3m','6m','1y'];
    const keyMap = ['month1Change','month3Change','month6Change','year1Change'];
    // const keyTextMap = ['1个月内','3月','6月','1年'];
    const dataMap = reactive({
      stockList1 : {},
      activeKey : keyMap[0],
    })
    let selectedTime = ''

    const fetchData = async (timeLevel:any) => {
      const data = await getAnalystRecommendList({ limit:3,onlyUp:false,timeLevel:timeLevel })
      dataMap.stockList1 = (data as any).data;
    }

    const changeHandle = (val:any) => {
      selectedTime = val.value
      // console.log('ccccccc',val)
      fetchData(val.timeLevel)
      dataMap.activeKey = keyMap[val.value]
    }

    const goDetail = (item:any) => {
      var str = ''
      dicts.ratingRelated.map((outer:any, idx:any)=>{
        item.tags.map((inner:any)=>{
          if( inner === outer ){
            str = idx
          }
        })
      })
      console.log(str)
      // dicts.ratingRelated
      window.open(`/investment/agency/detail?code=${item.code}&keywork=${str}&timeLevel=${selectedTime}`)
      // window['$props'].push({
      //   path: `/investment/agency/detail`,
      //   query:{
      //     code: item.code,
      //     keywork: str,
      //     timeLevel: selectedTime
      //   }
      // })
    }


    // fetchData(keyTimeMap[0]);
    return {
      ...toRefs(dataMap),
      changeHandle,
      goDetail
    }
  }
})
</script>
<style scoped lang='scss'>
.recommend{
  background: #fff;
  // margin-top: 30px;
  // padding: 34px 32px 52px;
  overflow: hidden;
  clear: both;
  // max-width: 1200px;
  .t-title{
    width: 950px;
  }
  .b-title{
    clear: both;
    // padding-top: 56px;
  }
  .b-list-box{
    display: flex;
    justify-content: space-between;
  }
  .b-list{
    width: 30%;
    min-width: 352px;
    // max-width: 500px;
    // float: left;
    // margin-right: 58px;
    border: 1px solid #EEEEEE;
    // padding-bottom: 15px;
    // padding: 20px 15px;
    &:last-child{
      margin-right: 0;
    }
    .padding{
      cursor: pointer;
      padding: 20px 15px 0;
      position: relative;
      &:hover{
        background: #F1F8FF;
        &::after{
          position: absolute;
          left: 0;
          top: 0;
          content:'';
          width: 3px;
          height: 100%;
          background: #1283D5;
        }
      }
      &:last-child{
        .content:last-child{
          border: 0;
          margin-bottom: 0;
          .b2{
            // padding-bottom: 0;
          }
        }
      }
    }
    .content{
      border-bottom: 1px solid #EEEEEE;
      // margin-bottom: 15px;
    }
    .t{
      display: flex;
      div{
        flex: 1;
        text-align: center;
      }
      .left{
        border-right: 1px solid #EEEEEE;
        p{
          &:nth-child(1){
            font-size: 18px;
            font-family: PingFang;
            font-weight: 800;
            color: #333333;
            margin-bottom: 7px;
          }
          &:nth-child(2){
            font-size: 14px;
            font-family: PingFang;
            font-weight: 500;
            color: #666666;
          }
        }
      }
      .right{
        p{
          &:nth-child(1){
            font-size: 20px;
            font-family: PingFang;
            font-weight: 500;
            // color: #EA5651;
            margin-bottom: 7px;
          }
          &:nth-child(2){
            font-size: 14px;
            font-family: PingFang;
            font-weight: 500;
            color: #666666;
          }
        }
      }
    }
    .b1{
      margin: 29px 0 25px;
      font-size: 12px;
      font-family: PingFang;
      font-weight: 500;
      color: #666666;
      min-height: 40px;
      line-height: 20px;
    }
    .b2{
      padding-bottom: 15px;
      div{
        padding: 5px;
        margin-right: 9px;
        border-radius: 4px;
        display: inline-block;
        border: 1px solid #EA8E3C;
        font-size: 12px;
        font-family: PingFang;
        font-weight: 500;
        color: #EA8E3C;
        margin-bottom: 10px;
      }
    }
  }
}
</style>
