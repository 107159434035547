<template>
  <el-table
    class="table-style-2 cursor"
    :data="stockList"
    border
    style="width: 100%"
    :default-sort ="{prop:'reportCount',order:'descending'}"
    @sort-change="sortChange"
    @row-click='handleRowClick'
  >
    <el-table-column
      prop="rankingNo"
      align="center"
      label="排名"
    />
    <el-table-column
      prop="code"
      align="center"
      label="代码"
    >
      <template #default='{row}'>
        {{row.code.split('.')[0]}}
      </template>
    </el-table-column>
    <el-table-column
      prop="name"
      align="center"
      label="股票名称"
    />
    <el-table-column
      prop="reportCount"
      align="center"
      label="研报总数"
      sortable="custom"
    />
    <el-table-column
      prop="reportBuyCount"
      align="center"
      label="买入评级"
      sortable="custom"
    />
    <el-table-column
      prop="reportFollowCount"
      align="center"
      label="首次关注"
      sortable="custom"
    />
    <el-table-column
      prop="reportIncrCount"
      align="center"
      label="增持评级"
      sortable="custom"
    />
    <el-table-column
      prop="fxsReportCount"
      align="center"
      label="新财富分析师评级数"
      sortable="custom"
    />
    <el-table-column
      prop="fxsReportBuyCount"
      align="center"
      label="买入评级"
      sortable="custom"
    />
    <el-table-column
      prop="fxsReportFollowCount"
      align="center"
      label="首次关注"
      sortable="custom"
    />
    <el-table-column
      prop="fxsReportIncrCount"
      align="center"
      label="增持评级"
      sortable="custom"
    />
  </el-table>
</template>

<script lang="ts">

import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    seletedTime:{
      type: String,
      default: ''
    },
    stockList: {
      type: [],
      default: []
    },
  },
  emits: ['sortChangeHandler'],
  setup(_,context) {
    const sortChange = (column:any)=>{
      context.emit('sortChangeHandler', column)
    }

    const handleRowClick = (column:any)=>{
      // console.log(_.seletedTime)

      window.open(`/investment/agency/detail?code=${column.code}&timeLevel=${_.seletedTime}`)
      // context.emit('sortChangeHandler', column.name)
    }

    return {
      sortChange,
      handleRowClick
    }
  }
})
</script>
<style lang='scss' scoped>

</style>
