<template>
  <div
    :id="chartId"
    :class="className"
    :style="{height: height, width: width}"
  />
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  nextTick,
  watch
} from 'vue'
import resize from '@/components/charts/mixins/resize'
import { init, EChartsOption } from 'echarts'
import { uuid } from "vue-uuid";
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '100%',
      required: true
    },
    chartData: {
      type: Object,
      default: {}
    },
    selectedTime: {
      type: String || Number,
      default: ''
    },
  },
  setup(props) {
    const {
      mounted,
      chart
    } = resize()

    const chartId = uuid.v1()

    watch(props, () => {
      initChart()
    });

    const initChart = () => {
      if( !props.chartData['ratingCounts'] || !props.chartData['ratingCounts'].length ){
        return false
      }
      const barChart = init(document.getElementById(chartId) as HTMLDivElement)
      const ratingName = Array.from(props.chartData['ratingCounts'], ({ratingName}) => ratingName)
      const orgCount = Array.from(props.chartData['ratingCounts'], ({orgCount}) => orgCount)
      const barColor = ['#FB160C', '#F6842A', '#F6C52A', '#C9E014', '#02C165']
      const data = orgCount.map((item,idx) => {
        return {
          value:item,
          label:{
            color: barColor[idx]
          },
          itemStyle:{
            color:barColor[idx]
          }
        }
      })
      barChart.setOption({
        grid: {
          top: '130px',
          left: '30px',
          right: '30px',
          bottom: '60px',
          containLabel: true
        },
        legend: {
          show: false
        },
        tooltip: {
          trigger: 'axis',
          // formatter:function(param:any){
          //   let text = ''
          //   for (let i = 0; i < param.length; i++) {
          //     const item = param[i]
          //     if (item.data != 'null') {
          //       text += `<div>
          //               <span style='width:8px;height:8px;display:inline-block;border-radius:50%;margin-right:3px;background: ${item.color}'></span>
          //               <span style='display:inline-block;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;line-height: 17px;'>${item.seriesName}：</span>
          //               <span :style='display:inline-block;font-size: 14px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #333333;line-height: 20px;'>${item.seriesName === '环比增长率' ? item.data > 0 ? '+' + item.data + '%' : item.data + '%' : amount(item.data)}</span>
          //           </div>`
          //     } else {
          //       return ''
          //     }
          //   }
          //   return text
          // }
        },
        xAxis: {
          show: true,
          type: 'category',
          axisLine: {
            show: false,
            lineStyle: {
              color: "#BFBFBF",
              type: "dashed"
            }
          },
          axisLabel:{
            show: true,
            // rotate: 45
          },
          axisTick: { show: false },
          data: ratingName
        },
        yAxis: [
          {
            show: false,
            type: 'value',
            axisLabel:{
              show: false
            },
            splitLine: { show: false }
          }
        ],
        series: [
          {
            type: 'bar',
            // name: 'PE-TTM',
            itemStyle: {
              normal: {
                // barBorderRadius: ['4px', '4px', '0', '0'],
                barBorderRadius:[4, 4, 0, 0],
                label: {
                  show: true,
                  position: 'top',
                  // color: ['red', 'green', 'yellow'],
                  formatter: (params: any) => {
                    return params.value + '家'
                  }
                },
              }
            },
            barWidth: 58, // 柱子宽度
            data: data
          }
        ]
      } as EChartsOption)
      chart.value = barChart
    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    return {
      chartId
    }
  }
})
</script>
