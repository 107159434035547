<template>
  <div class="technical">
    <template v-if="stockList1">
      <div class="modular">
        <CTitle :mode='6' title="技术面分析" class="technical-title"></CTitle>
        <div class="des" v-html="stockList1.technical"></div>
        <!--components/info/technicalAspect/index.wxml-->
        <div class="container ">
          <div class="content_item">
            <div class="content_item_title">MACD</div>
            <div class="content_item_type">
              <div class="content_item_type_item">DIF:{{Number(difnum).toFixed(2)}}</div>
              <div class="content_item_type_item">DEA:{{Number(deanum).toFixed(2)}}</div>
              <div class="content_item_type_item">M:{{Number(macdnum).toFixed(2)}}</div>
            </div>
            <div class="content_item_ecanvas">
              <div class="content_item_ecanvas_dea">
                <div class="content_item_ecanvas_deamax">{{Number(max1).toFixed(2)}}</div>
                <div class="content_item_ecanvas_deamin">{{Number(min1).toFixed(2)}}</div>
              </div>
              <!-- <block wx:if="{{isecbar1}}">
                <ec-canvas class="canvas_main" id="mychart-dom-bar" canvas-id="mychart-bar" ec="{{ ecbar }}"></ec-canvas>
              </block> -->
              <LineChartB id='chart-macd' width='100%' :chartData='stockList1.macdRsis' @getMacdVal="getMacdVal"></LineChartB>
            </div>
              <div class="content_item_timebox" v-if="stockList1.macdRsis && stockList1.macdRsis.length">
                <div class="content_item_timebox_item">{{stockList1.macdRsis[0].date}}</div>
                <div class="content_item_timebox_item">{{stockList1.macdRsis[stockList1.macdRsis.length-1].date}}</div>
              </div>
          </div>
          <div class="content_item">
            <div class="content_item_title">RSI</div>
            <div class="content_item_type">
              <div class="content_item_type_item">RSI1:{{Number(rsi1).toFixed(2)}}</div>
              <div class="content_item_type_item">RSI2:{{Number(rsi2).toFixed(2)}}</div>
              <div class="content_item_type_item">RSI3:{{Number(rsi3).toFixed(2)}}</div>
            </div>
            <div class="content_item_ecanvas">
              <div class="content_item_ecanvas_dea">
                <div class="content_item_ecanvas_deamax">{{max2.toFixed(2)}}</div>
                <div class="content_item_ecanvas_deamin">{{min2.toFixed(2)}}</div>
              </div>
              <!-- <block wx:if="{{isecbar2}}"> -->
                <!-- <ec-canvas class="canvas_main" id="mychart-dom-bar2" canvas-id="mychart-bar2" ec="{{ ecbar2 }}"></ec-canvas> -->
              <!-- </block> -->
              <LineChartC id='chart-rsi' :chartData='stockList1.macdRsis' @getRsiVal="getRsiVal"></LineChartC>

            </div>
              <div class="content_item_timebox" v-if="stockList1.macdRsis && stockList1.macdRsis.length">
                <div class="content_item_timebox_item">{{stockList1.macdRsis[0].date}}</div>
                <div class="content_item_timebox_item">{{stockList1.macdRsis[stockList1.macdRsis.length-1].date}}</div>
              </div>
          </div>
        </div>
      </div>

      <div class="modular">
        <CTitle class="technical-title" :mode='6' title="趋势研判" ></CTitle>
        <div class="des">{{stockList1.essentials}}</div>
        <div class="content_item_trend">
          <div class="content_item_trend_header">
          <div class="content_item_trend_header_text">{{trendInfo}}</div>
          <div class="content_item_trend_header_num">
                <text class="text1">短线支撑位  {{Number(stockList1.supportPosition).toFixed(2)}}</text>
                <!-- <text class="text2">短线压力位  {{Number(stockList1.supportPosition).toFixed(2)}}</text> -->
                <text class="text2">短线压力位  {{Number(stockList1.pressurePosition).toFixed(2)}}</text>
          </div>
          </div>
          <div class="content_item_trend_content">
            <div class="content_item_trend_content_line">
              <!-- <ec-canvas class="ec_canvas"  id="mychart-dom-line-2" canvas-id="mychart-line-2" ec="{{ ecline1 }}" ></ec-canvas>  -->
              <LineChartK :chartData='stockList1' id='chart-qsyp' width='100%' height='120px' @getTimeList='getTimeList'></LineChartK>
              <div class="content_item_timebox qsyp-time">
                <view class="left_data left">{{timeList[0]}}</view>
                <view class="center_data">{{timeList[1]}}</view>
                <view class="right_data right">{{timeList[2]}}</view>
              </div>
            </div>
          </div>
          <div class="content_item_trend_foot">
            <div class="content_item_trend_foot_item">
              <div class="content_item_trend_foot_item_icon"></div>
              <div class="content_item_trend_foot_item_text">六十日区间涨跌幅{{stockList1.rchange60}}%，日均换手率{{stockList1.turnoverRate60}}%</div>
            </div>
          </div>
        </div>
      </div>

      <div class="modular">
        <CTitle class="scbx-title" :mode='6' title="市场表现"></CTitle>
        <div class="scbx-box">
          <LineChartE id='scbx' :chartData='stockList1' width='100%'></LineChartE>
          <div class="content_item_timebox" v-if="stockList1.marketChart && stockList1.marketChart.data.length">
            <div class="content_item_timebox_item">{{stockList1.marketChart.data[0].date}}</div>
            <div class="content_item_timebox_item">{{stockList1.marketChart.data[stockList1.marketChart.data.length-1].date}}</div>
          </div>
        </div>
      </div>
    </template>
    <div  class="wushuju_box" v-else>
      <img class="wushuju" src="http://resource.jufxt.com/static/zhengu/home/wushuju.png" />
      <div class="wushuju_text">该项目暂无数据</div>
    </div>
  </div>
</template>

<script lang="ts">

import { defineComponent, reactive, toRefs } from 'vue'
import CTitle from '@/components/title/Index.vue'
import LineChartB from '@/components/charts/LineChartB.vue'
import LineChartC from '@/components/charts/LineChartC.vue'
import LineChartK from '@/components/charts/LineChartK.vue'
import LineChartE from '@/components/charts/LineChartE.vue'
import { getDiagnosticRrendInfo } from '@/apis/diagnosisMoble'
import { useRoute } from 'vue-router'

export default defineComponent({
  props: {
    dataMap: {
      type: Array,
      default: []
    }
  },
  components: {
    CTitle,
    LineChartB,
    LineChartC,
    LineChartK,
    LineChartE
  },
  setup() {
    // const stockCode = '600519.SH'
    const route = useRoute()
    const stockCode = route.query.code
    const dataMap = reactive({
      ajaxRes: false,
      activeIdx: 1,
      difnum: 0,
      deanum: 0,
      macdnum: 0,
      max1: 0,
      min1: 0,
      max2: 0,
      min2: 0,
      rsi1: 0,
      rsi2: 0,
      rsi3: 0,
      stockList1: {},
      timeList: []
    })
    
    const fetchData = async () => {
      const data1 = await getDiagnosticRrendInfo({ stockCode: stockCode })
      dataMap.stockList1 = (data1 as any).data;
      if( dataMap.stockList1 ){

        let list = (dataMap.stockList1 as any).macdRsis;
        dataMap.difnum = list[list.length-1].diff
        dataMap.deanum = list[list.length-1].dea
        dataMap.macdnum = list[list.length-1].macd
        let max = 0,min = 0,max2 = 0, min2 = 0
        for (let index = 0; index < list.length; index++) {
          const element = list[index]
          if(index==0){
            max = Number(element.diff)
            min = Number(element.diff)
            
            max2 = Number(element.rsi6)
            min2 = Number(element.rsi6)
          }else{
            if( max < Number(element.diff) ){
              max= Number(element.diff)
            }
            if(min > Number(element.diff) ){
              max= Number(element.diff)
            }

            if(max2<element.rsi6*1){
              max2 = element.rsi6*1
            }
            if(min2>element.rsi6*1){
              min2 = element.rsi6*1
            }
          }

          if( index === list.length-1 ){
            dataMap.rsi1 = element.rsi6
            dataMap.rsi2 = element.rsi12
            dataMap.rsi3 = element.rsi24
          }
          dataMap.max1 = max
          dataMap.min1 = min
          dataMap.max2 = max2
          dataMap.min2 = min2
        }
      }
     
      dataMap.ajaxRes = true;
    }
    fetchData()

    const getRsiVal = (res:any) => {
      dataMap.rsi1 = res[0]
      dataMap.rsi2 = res[1]
      dataMap.rsi3 = res[2]
    }
    const getMacdVal = (res:any) => {
      dataMap.difnum = res[0]
      dataMap.deanum = res[1]
      dataMap.macdnum = res[2]
    }
    const getTimeList = (res:any) => {
      dataMap.timeList = res
    }

    
    return {
      ...toRefs(dataMap),
      getRsiVal,
      getMacdVal,
      getTimeList
    }
  }
})
</script>
<style scoped lang='scss'>

.des{
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  margin: 20px 0 30px;
}
.flex{
  display: flex;
}

// .technical{
//   .mode{
//     margin-left: -10px;
//   }
// }

.scbx-title{
  margin-bottom: 28px;
}
.scbx-box{
  // width: 550px;
  margin-left: 12px;
  .content_item_timebox{
  }
}

//复制小程序
.container{
  width: 100%;
  // margin-left: 12px;
  .content_item{
    &:first-child{
      margin-bottom: 30px;
    }
  }
}
.content_item_ecanvas{
    width: 100%;
    height: 200px;
    margin-top: 10px;
    border-top: 1px solid #CCCCCC;
    border-bottom:1px solid #CCCCCC;
    position: relative;
}
.content_item_ecanvas_dea{
    // z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction:column;
    justify-content: space-between;
    padding: 4px 0;
    div{
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #999;
    }
}

.canvas_main {
    width: 100%;
    height: 100%;
}

.content_item_title{
  font-size: 19px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  margin-bottom: 26px;
}
// .hei .content_item_title{
//     color: #FFFFFF;
// }
.content_item_type{
    display: flex;
    align-items: center;
}
.content_item_type_item{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    margin-right: 20px;
}
.content_item_type_item:nth-child(2){
    color: #F6C52A;
}
.content_item_type_item:last-child{
    color: #951EFD;
    margin-right: 0;
}

.content_item_trend{
  // width: 550px;
  // margin-left: 12px;
  .content_item_trend_header{
    margin-bottom: 8px;
    .content_item_trend_header_text{
      color: #333333;
    }
    .content_item_trend_header_num{
      font-size: 14px;
      margin-bottom: 30px;
      .text1{
        color: #ECBD2A;
        margin-right: 20px;
      }
      .text2{
        color: #D0031B;
        margin-right: 20px;
      }
      .text3{
        color: #951EFD;
      }
      text:not(:last-child){
          margin-right: 30px;
      }
    }
  }
}

.content_item_trend_content_line{
    position: relative;
    width: 100%;
    // height: 260px;
    margin-top: 30px;
    .qsyp-time{
      // width: 510px;
      margin-left: 30px;
      margin-top: -10px;
      margin-bottom: 22px;
      .left_data {
        margin-right: 10px;
      }
      .center_data {
        margin-right: 10px;
      }
      .right_data {
        margin-right: 10px;
      }
    }
}
.content_item_trend_foot{
  margin-top: 40px;
  .content_item_trend_foot_item{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .content_item_trend_foot_item_icon{
        width: 4px;
        height: 4px;
        background: #FD472D;
        margin-right: 8px;
        position: relative;
        top: 0;
    }
  }
}


.content_item_trend_foot_item_text{
    color: #666666;
    font-size: 12px;
}
.content_item_trend_title{
    font-size: 30px;
    color: #333333;
    margin-bottom: 30px;
}
.content_item_trend_title.title2{
    margin-top: 40px;
}
.content_item_trend_typebox{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.content_item_trend_type_item{
    width: 31%;
    padding: 20px;
    background: #F6F8FA;
    text-align: center;
}
.content_item_trend_type_item_title{
    color: #9C9C9C;
    font-size: 26px;
}

.content_item_trend_type_item_num{
    color: #F6C52A;
    font-size: 30px;
    margin-top: 10px;
}
.content_item_trend_type_item:first-child .content_item_trend_type_item_num{
    color: #FB160C;
}


</style>
