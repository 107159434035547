<template>
  <div>
    <div class="synthesize-valuation">
        <div class="factor-item-wrapper">
          <div 
            class="factor-item-data"
            :class="(currentCheckValuetion === factorItem.code || rangeCheckKeyList.includes(factorItem.code)) ? 'factor-item-data-active' : ''"
            v-for="factorItem in factorDataList" 
            :key="factorItem.key" 
            @click="changeFactorType(factorItem)"
          >
            {{factorItem.name}}
          </div>
        </div>
        <div class="factor-value-box">
          <div class="factor-item-value-wrapper" v-if="currentCheckValuetion">
            <div 
              class="factor-children-item"
              :class="rangeCheckChildKeyList.includes(item.key) ? 'factor-children-item-active' : ''"
              v-for="item in factorChildrenList" :key="item.key" 
              @click="checkChildrenRange(item)"
            >
              <span v-if="item.type !== 'custom'">{{item.name}}</span>
              <el-popover
                v-else
                ref="itemRefs"
                placement="bottom"
                :visible-arrow="true"
                width="300"
                trigger="hover"
              >
                <div class="c-custom-form">
                  <div class="c-custom-form-warp">
                    <div class="c-custom-form-item">
                      <div class="c-custom-form-item-label">
                        <span>大于等于</span>
                      </div>
                      <div class="c-custom-form-item-input">
                        <el-input
                          :class="(customValue1 && isVaild1) ? 'c-custom-valid-failed' : ''"
                          size="mini"
                          placeholder=""
                          v-model="customValue1"
                          @input="changeCustomValue($event, 'value1')"
                        >
                        </el-input>
                      </div>
                    </div>
                    <div class="c-custom-form-item">
                      <div class="c-custom-form-item-label">
                        <span>小于等于</span>
                      </div>
                      <div class="c-custom-form-item-input">
                        <el-input
                          :class="(customValue2 && isVaild2) ? 'c-custom-valid-failed' : ''"
                          size="mini"
                          placeholder=""
                          v-model="customValue2"
                          @input="changeCustomValue($event, 'value2')"
                          >
                        </el-input>
                      </div>
                    </div>
                    <div class="c-custom-form-tips">
                      <span>最多精确到小数点后面两位</span>
                    </div>
                    <div class="c-custom-form-btn">
                      <div class="c-custom-form-btn-warp">
                        <el-button size="mini" @click="cancelCustomValue">取消</el-button>
                        <el-button size="mini" type="primary" :disabled="isDisabled" @click="saveCustomValue(item)">保存</el-button>
                      </div>
                    </div>
                  </div>
                </div>
                <template  #reference><span>{{item.name}}</span></template>
              </el-popover>
            </div>
          </div>
          <div class="btn" @click="onSearch">查询</div>
        </div>
    </div>
    <div class="filter-criteria">
      <div class="filter-criteria-title">
        筛选条件:
      </div>
      <div class="filter-criteria-wrapper">
        <div class="filter-criteria-item" v-if="selectedIndustry">
          <span>{{selectedIndustry}}</span>
          <i class="el-icon-close" @click="delIndustryTab()" />
        </div>
        <div class="filter-criteria-item" v-for="item in rangeCheckList" :key="item.key">
          <span>{{`${item.parentName}: ${item.name}`}}</span>
          <i class="el-icon-close" @click="delChildrenRange(item)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  toRefs,
  reactive,
  nextTick,
  ref
} from 'vue'


import { useRoute } from "vue-router";
export default defineComponent({
  components: {
  },
  setup(props, context) {
    const factorDataList = [
      {
      firstLevel: 1,
      key: 2,
      code: 'pettm',
      name: 'PETTM(倍）',
      children: [{
        parentKey: 2,
        parentCode: 'pettm',
        parentName: 'PETTM(倍）',
        prop: 'peTtm',
        key: '2-0',
        name: '0以下',
        maxValue: -0.001,
        minValue: -1000000
      },{
        parentKey: 2,
        parentCode: 'pettm',
        parentName: 'PETTM(倍）',
        prop: 'peTtm',
        key: '2-1',
        name: '0-10',
        maxValue: 10,
        minValue: 0
      },{
        parentKey: 2,
        parentCode: 'pettm',
        parentName: 'PETTM(倍）',
        prop: 'peTtm',
        key: '2-2',
        name: '10-20',
        maxValue: 20,
        minValue: 10
      },{
        parentKey: 2,
        parentCode: 'pettm',
        parentName: 'PETTM(倍）',
        prop: 'peTtm',
        key: '2-3',
        name: '20-30',
        maxValue: 30,
        minValue: 20
      },{
        parentKey: 2,
        parentCode: 'pettm',
        parentName: 'PETTM(倍）',
        prop: 'peTtm',
        key: '2-4',
        name: '30-50',
        maxValue: 50,
        minValue: 30
      },{
        parentKey: 2,
        parentCode: 'pettm',
        parentName: 'PETTM(倍）',
        prop: 'peTtm',
        key: '2-5',
        name: '50以上',
        maxValue: 1000000,
        minValue: 50.001
      },{
        parentKey: 2,
        parentCode: 'pettm',
        parentName: 'PETTM(倍）',
        prop: 'peTtm',
        type: 'custom',
        key: '2-6',
        name: '自定义',
        maxValue: '',
        minValue: ''
      }]
    },{
      firstLevel: 1,
      key: 3,
      code: 'asz',
      name: '按市值(亿）',
      children: [{
        parentKey: 3,
        parentCode: 'asz',
        parentName: '按市值(亿）',
        prop: 'pb',
        key: '3-1',
        name: '30以下',
        maxValue: 30,
        minValue: ''
      },{
        parentKey: 3,
        parentCode: 'asz',
        parentName: '按市值(亿）',
        prop: 'pb',
        key: '3-2',
        name: '30-50',
        maxValue: 50,
        minValue: 30
      },{
        parentKey: 3,
        parentCode: 'asz',
        parentName: '按市值(亿）',
        prop: 'pb',
        key: '3-3',
        name: '50-100',
        maxValue: 100,
        minValue: 50
      },{
        parentKey: 3,
        parentCode: 'asz',
        parentName: '按市值(亿）',
        prop: 'pb',
        key: '3-4',
        name: '100-300',
        maxValue: 300,
        minValue: 100
      },{
        parentKey: 3,
        parentCode: 'asz',
        parentName: '按市值(亿）',
        prop: 'pb',
        key: '3-5',
        name: '300-1000',
        maxValue: '1000',
        minValue: 300
      },{
        parentKey: 3,
        parentCode: 'asz',
        parentName: '按市值(亿）',
        prop: 'pb',
        key: '3-6',
        name: '1000以上',
        maxValue: '',
        minValue: 1000
      },{
        parentKey: 3,
        parentCode: 'asz',
        parentName: '按市值(亿）',
        prop: 'pb',
        type: 'custom',
        key: '3-7',
        name: '自定义',
        maxValue: '',
        minValue: ''
      }]
    }]

    const route = useRoute();
    const itemRefs = ref(null);
    const sortTable = ref(null);

    const dataMap = reactive({
      formInline: {
        industryName: '',//行业名称
      },
      factorChildrenList: factorDataList[0].children as any,
      currentCheckValuetion: 'pettm',
      selectedIndustry: '',
      rangeCheckKeyList: ['pettm'], // 范围筛选的父级key集合
      // rangeCheckChildKeyList: ['2-2'], // 范围筛选的子集的key集合
      rangeCheckChildKeyList: [], // 范围筛选的子集的key集合
      rangeCheckList: [],
      // rangeCheckList: [{
      //   parentKey: 2,
      //   parentCode: 'pettm',
      //   parentName: 'PETTM(倍）',
      //   prop: 'peTtm',
      //   key: '2-0',
      //   name: '10-20',
      //   maxValue: 20,
      //   minValue: 10
      // }], // 范围筛选条件合集(默认综合估值10-20)
      industryActive: -1,
      page: {
        totalSize: 0,
        pageSize: 10,
        pageNum: 1,
      },
      page2: {
        totalSize: 0,
        pageSize: 10,
        pageNum: 1,
      },
      sortMap: {
        sortKey: 'peTtm',
        isAsc: true,
      },
      customValue1: '',
      customValue2: '',
      isVaild1: false,
      isVaild2: false,
      isDisabled: true,
    })

    // 删除行业
    const delIndustryTab = () => {
      dataMap.formInline.industryName = ''
      dataMap.selectedIndustry = ''
      dataMap.industryActive = -1
      dataMap.page.pageNum = 1
      dataMap.page2.pageNum = 1
      // fetchData()
    }

    // 选中范围子集
    const checkChildrenRange = (item:any) => {
      if(item.type === 'custom') return;
      const newArr = JSON.parse(JSON.stringify(dataMap.rangeCheckList)).filter((items:any) => items.parentCode !== item.parentCode).concat(item); 
      dataMap.rangeCheckList = newArr
      dataMap.rangeCheckKeyList = newArr.map((items:any) => items.parentCode);
      dataMap.rangeCheckChildKeyList = newArr.map((items:any) => items.key);
      const defaultSort = dataMap.rangeCheckList[0];
      // nextTick(() => {
      //   let { clearSort, sort } = sortTable.value as any;
      //   clearSort();
      //   sort(defaultSort['prop'], 'ascending');
      // })
      dataMap.sortMap = { sortKey: defaultSort ? defaultSort['prop'] : '',  isAsc: true }
      dataMap.page.pageNum = 1
      dataMap.page2.pageNum = 1
      // getDataList2()
    }

    // 删除范围子集
    const delChildrenRange = (item:any) => {
      const newArr = JSON.parse(JSON.stringify(dataMap.rangeCheckList)).filter((items:any) => items.parentCode !== item.parentCode); 
      dataMap.rangeCheckList = newArr
      dataMap.rangeCheckKeyList = newArr.map((items:any) => items.parentCode);
      dataMap.rangeCheckChildKeyList = newArr.map((items:any) => items.key);
      const defaultSort = dataMap.rangeCheckList[0];
      if(defaultSort) {
        // nextTick(() => {
        //   let { clearSort, sort } = sortTable.value as any;
        //   clearSort();
        //   sort(defaultSort['prop'], 'ascending');
        // })
        // dataMap.sortMap = { sortKey: defaultSort ? defaultSort['prop'] : '',  isAsc: true };
      }
      dataMap.page.pageNum = 1
      dataMap.page2.pageNum = 1
      // fetchData()
    }

    const saveCustomValue = (item:any) => {
      const newItem = {
        ...item,
        name: `${item.name}:[${dataMap.customValue1},${dataMap.customValue2}]`,
        maxValue: dataMap.customValue2,
        minValue: dataMap.customValue1
      }
      const newArr = JSON.parse(JSON.stringify(dataMap.rangeCheckList)).filter((items:any) => items.parentCode !== item.parentCode).concat(newItem); 
      dataMap.rangeCheckList = newArr
      dataMap.rangeCheckKeyList = newArr.map((items:any) => items.parentCode);
      dataMap.rangeCheckChildKeyList = newArr.map((items:any) => items.key);
      const defaultSort = dataMap.rangeCheckList[0];
      nextTick(() => {
        // let { clearSort, sort } = sortTable.value as any;
        // clearSort();
        // sort(defaultSort['prop'], 'ascending');
      })
      dataMap.customValue1 = '';
      dataMap.customValue2 = '';
      dataMap.isDisabled = true;
      dataMap.isVaild1 = false;
      dataMap.isVaild2 = false;
      nextTick(() => {
        // let obj = itemRefs.value as any;
        // obj.doDestroy();
        // obj.visibility = false
      })
      dataMap.sortMap = { sortKey: defaultSort ? defaultSort['prop'] : '',  isAsc: true }
      dataMap.page.pageNum = 1
      dataMap.page2.pageNum = 1
      // getDataList2() 
    }

    // 切换估值类型
    const changeFactorType = (item:any) => {
      dataMap.customValue1 = '';
      dataMap.customValue2 = '';
      dataMap.isDisabled = true;
      dataMap.isVaild1 = false;
      dataMap.isVaild2 = false;
      dataMap.currentCheckValuetion = item.code;
      dataMap.factorChildrenList = item.children || [];
    }


    const getParams = () => {
      
      // PETTM
      const pettmObj = (dataMap.rangeCheckList || []).find((item:any) => item.parentCode === 'pettm') || {};
      const aszObj = (dataMap.rangeCheckList || []).find((item:any) => item.parentCode === 'asz') || {};
      
      const params = {
        peTtmRange: pettmObj['minValue'] || pettmObj['maxValue'] ? pettmObj['minValue'] + ',' + pettmObj['maxValue'] : '',
        marketCapRange: aszObj['minValue'] || aszObj['maxValue'] ? aszObj['minValue'] + ',' + aszObj['maxValue'] : '',
      }
      return params;
    }

    const changeCustomValue = (e:any, type:String) => {
      const reg = /^(0|([1-9][0-9]*))(\.[\d]{1,2})?$/; 
      const result = reg.test(e);
      if(type === 'value1'){
        dataMap.isVaild1 = !result;
      }else {
        dataMap.isVaild2 = !result;
      }
      if(!dataMap.customValue1 && !dataMap.customValue2){
        dataMap.isDisabled = true;
      }else{
        if(dataMap.customValue1 && !dataMap.isVaild1 && dataMap.customValue2 && dataMap.isVaild2){
          dataMap.isDisabled = true;
        }else if(dataMap.customValue1 && dataMap.isVaild1 && dataMap.customValue2 && !dataMap.isVaild2){
          dataMap.isDisabled = true;
        }else if(dataMap.customValue1 && dataMap.isVaild1 && !dataMap.customValue2){
          dataMap.isDisabled = true;
        }else{
          dataMap.isDisabled = false;
        }
      }
    }

    // 搜索
    const onSearch = (item:any) => {
      // console.log(getParams())
      // const params = {
      //   ...getParams(),

      // }
      context.emit('search', getParams())
    }



    return {
      ...toRefs(dataMap),
      factorDataList,
      sortTable,
      delChildrenRange,
      delIndustryTab,
      saveCustomValue,
      checkChildrenRange,
      changeFactorType,
      changeCustomValue,
      onSearch
    }
  }
})
</script>
<style scoped lang='scss'>
.synthesize-valuation{
  .factor-item-wrapper{
    display: flex;
    align-items: center;
    .factor-item-title{
      font-size: 14px;
      font-weight: 500;
      color: #000000;
      line-height: 28px;
    }
    .factor-item-data{
      cursor: pointer;
      width: 12%;
      // text-align: center;
      font-size: 14px;
      font-weight: 500;
      color: #666666;
      line-height: 28px;
    }
    .factor-item-data-active{
      color: #44A2E6;
    }
  }
  .factor-value-box{
    margin-top: 15px;
    display: flex;
    .btn{
      width: 110px;
      height: 34px;
      line-height: 34px;
      color: #fff;
      background: #386FF2;
      border-radius: 4px;
      text-align: center;
      margin-left: 60px;
      cursor: pointer;
    }
  }
  .factor-item-value-wrapper{
    // width: 60%;
    display: flex;
    background: #f7f7f7;
    padding: 3px 17px;
    .factor-children-item{
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      color: #666666;
      line-height: 28px;
      margin-right: 40px;
    }
    .factor-children-item:hover{
      color: #44A2E6;
    }
    .factor-children-item-active{
      color: #44A2E6;
    }
  }
}
.filter-criteria{
  margin: 20px 0;
  display: flex;
  align-items: center;
  .filter-criteria-title{
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    line-height: 28px;
    margin-right: 20px;
  }
  .filter-criteria-wrapper{
    display: flex;
    .filter-criteria-item{
      margin-right: 20px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 10px;
      background: #f7f7f7;
      border: 1px solid #E9E9E9;
      border-radius: 3px;
      span{
        font-size: 14px;
        color: #666666;
      }
      i{
        font-size: 10px;
        margin-left: 5px;
      }
    }
    .filter-criteria-item:hover{
      background: #F1F9FF;
      border: 1px solid #44A2E6;
      i{
        color: #44A2E6;
      }
    }
  }
}
</style>