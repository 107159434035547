
import {
  defineComponent,
  onMounted,
  nextTick,
  watch
} from 'vue'
import resize from '@/components/charts/mixins/resize'
import { init, EChartsOption } from 'echarts'
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '90%',
      required: true
    },
    height: {
      type: String,
      default: '300px',
      required: true
    },
    chartData: {
      type: Object,
      default: {}
    },
    selectedTime: {
      type: String || Number,
      default: ''
    },
  },
  setup(props) {
    const {
      mounted,
      chart
    } = resize()

    watch(props, (newName, oldName) => {
      initChart()
    });

    const initChart = () => {
      const quarterData = props.chartData.quarterData
      const xAxisList = Array.from(props.chartData.quarterData, ({ year }) => year);
      // 环比
      const oneQuarter = Array.from(props.chartData.quarterData, ({ quarter1NetProfit }) => quarter1NetProfit);

      const twoQuarter = Array.from(props.chartData.quarterData, ({ quarter2NetProfit }) => quarter2NetProfit);

      const threeQuarter = Array.from(props.chartData.quarterData, ({ quarter3NetProfit }) => quarter3NetProfit);

      const fourQuarter = Array.from(props.chartData.quarterData, ({ quarter4NetProfit }) => quarter4NetProfit);

      oneQuarter.map((_:any,idx:any)=>{
        oneQuarter[idx] = oneQuarter[idx] === null ? undefined : oneQuarter[idx]
      })
      twoQuarter.map((_:any,idx:any)=>{
        twoQuarter[idx] = twoQuarter[idx] === null ? undefined : twoQuarter[idx]
      })
      threeQuarter.map((_:any,idx:any)=>{
        threeQuarter[idx] = threeQuarter[idx] === null ? undefined : threeQuarter[idx]
      })
      fourQuarter.map((_:any,idx:any)=>{
        fourQuarter[idx] = fourQuarter[idx] === null ? undefined : fourQuarter[idx]
      })

      const lastData = quarterData[quarterData.length-1]
      if( lastData.quarter4NetProfit ){
        fourQuarter[quarterData.length-1] = {
          value : fourQuarter[quarterData.length-1],
          itemStyle: {
            normal:{
              color:'#FE9625'
            }
          }
        }
      }else if( lastData.quarter3NetProfit ){
        threeQuarter[quarterData.length-1] = {
          value : threeQuarter[quarterData.length-1],
          itemStyle: {
            normal:{
              color:'#FE9625'
            }
          }
        }
      }else if( lastData.quarter2NetProfit ){
        twoQuarter[quarterData.length-1] = {
          value : twoQuarter[quarterData.length-1],
          itemStyle: {
            normal:{
              color:'#FE9625'
            }
          }
        }
      }else if( lastData.quarter1NetProfit ){
        oneQuarter[quarterData.length-1] = {
          value : oneQuarter[quarterData.length-1],
          itemStyle: {
            normal:{
              color:'#FE9625'
            }
          }
        }
      }
      const barChart = init(document.getElementById(props.id) as HTMLDivElement)
      barChart.setOption({
        grid: {
          top: '60px',
          left: '41px',
          right: '41px',
          bottom: '40px',
          containLabel: true
        },
        legend: {
          icon: 'line',
          data: ['一季度', '二季度', '三季度', '四季度'],
          itemWidth: 8,  // 设置宽度
          itemHeight: 5, // 设置高度
          x: 'center',
          bottom: '0',
          itemGap: 30,
          textStyle: {
            fontSize: 12,// 字体大小
            color:'#666666',
          }
        },
        tooltip: {
          trigger: 'axis',
          formatter:function(param:any){
            let text = param[0].axisValue
            for (let i = 0; i < param.length; i++) {
              const item = param[i]
              if (item.value || props.chartData.quarterData[item.dataIndex].quarter1PeTtm || props.chartData.quarterData[item.dataIndex].quarter2PeTtm || props.chartData.quarterData[item.dataIndex].quarter3PeTtm || props.chartData.quarterData[item.dataIndex].quarter4PeTtm) {
                text += `<div style="display: flex; align-items: center; width: 350px;">
                                <span style='width:8px;height:8px;display:inline-block;border-radius:50%;margin-right:3px;background: ${item.color}'></span>
                                    <span style=''>${item.seriesName}：</span>
                                    <span style='display: flex; justify-content: space-between'>
                                    <span style="display: block; width: 75px">${item.value ? amount(item.value) : '-'}</span>
                                    <span style="display: block; width: 100px">环比:${item.seriesName === '一季度' ? props.chartData.quarterData[item.dataIndex].quarter1ProfitIncr ? props.chartData.quarterData[item.dataIndex].quarter1ProfitIncr + '%' : '-' : item.seriesName === '二季度' ? props.chartData.quarterData[item.dataIndex].quarter2ProfitIncr ? props.chartData.quarterData[item.dataIndex].quarter2ProfitIncr + '%' : '-' : item.seriesName === '三季度' ? props.chartData.quarterData[item.dataIndex].quarter3ProfitIncr ? props.chartData.quarterData[item.dataIndex].quarter3ProfitIncr + '%' : '-' : props.chartData.quarterData[item.dataIndex].quarter4ProfitIncr ? props.chartData.quarterData[item.dataIndex].quarter4ProfitIncr + '%' : '-'}</span>
                                    <span style="display: block; width: 125px">PE-TTM:${item.seriesName === '一季度' ? props.chartData.quarterData[item.dataIndex].quarter1PeTtm ? props.chartData.quarterData[item.dataIndex].quarter1PeTtm : '-' : item.seriesName === '二季度' ? props.chartData.quarterData[item.dataIndex].quarter2PeTtm ? props.chartData.quarterData[item.dataIndex].quarter2PeTtm : '-' : item.seriesName === '三季度' ? props.chartData.quarterData[item.dataIndex].quarter3PeTtm ? props.chartData.quarterData[item.dataIndex].quarter3PeTtm : '-' : props.chartData.quarterData[item.dataIndex].quarter4PeTtm ? props.chartData.quarterData[item.dataIndex].quarter4PeTtm : '-'}</span>
                                </span>
                            </div>`
              } else {
                return ''
              }
            }
            return text
          }
        },
        xAxis: {
          show: false,
          type: 'category',
          data: xAxisList,
        },
        yAxis: [
          {
            type: 'value',
            splitLine: { show: false },
            axisLabel: {
              show: false
            }
          }
        ],
        series: [
          {
            type: 'bar',
            name: '一季度',
            yAxisIndex: 0,
            barGap: '60%',
            itemStyle: {
              normal: {
                label: {
                  show: false
                },
                color: '#D8E5FC',
              }
            },
            barWidth: 20, // 柱子宽度
            data: oneQuarter
          },
          {
            type: 'bar',
            name: '二季度',
            yAxisIndex: 0,
            barGap: '60%',
            itemStyle: {
              normal: {
                label: {
                  show: false
                },
                color: '#B1CCF8',
              }
            },
            barWidth: 20, // 柱子宽度
            data: twoQuarter
          },
          {
            type: 'bar',
            name: '三季度',
            barGap: '60%',
            yAxisIndex: 0,
            itemStyle: {
              normal: {
                label: {
                  show: false
                },
                color: '#75A5F3',
              }
            },
            barWidth: 20, // 柱子宽度
            data: threeQuarter
          },
          {
            type: 'bar',
            name: '四季度',
            yAxisIndex: 0,
            barGap: '60%',
            itemStyle: {
              normal: {
                label: {
                  show: false
                },
                color: '#3B7FEE',
              }
            },
            barWidth: 20, // 柱子宽度
            data: fourQuarter
          }
        ]
      } as EChartsOption)
      chart.value = barChart
    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    const amount = (value: any, fixed = 2, unit = '元') => {
      if (unit == '万') {
        value = value * 10000
      } else if (unit == '亿') {
        value = value * 100000000
      }
      if (Math.abs(value / 100000000) >= 1) {
        return (value / 100000000).toFixed(fixed) + '亿'
      }
      if (Math.abs(value / 10_000) >= 1) {
        return (value / 10000).toFixed(fixed) + '万'
      }
      return value
    }
    return {
      amount
    }
  }
})
