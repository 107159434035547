<template>
  <div
    :id="id"
    :class="className"
    :style="{height: height, width: width}"
  />
</template>

<script lang="ts">
import { defineComponent, onActivated, onBeforeUnmount, onDeactivated, onMounted, nextTick, watch } from 'vue'
import resize from '@/components/charts/mixins/resize'
import { init, EChartsOption } from 'echarts'
import * as echarts from 'echarts'
export default defineComponent({
  props: {
    chartData: {
      type: Object,
      default: {}
    },
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '193px',
      required: true
    }
  },
  setup(props) {
    watch(props, (newName, oldName) => {
      console.log(99999,props)
      initChart()
    });
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()
    const initChart = () => {
      const map = props.chartData;
      if( !Object.keys(map).length ){
        return false;
      }

      const barChart = init(document.getElementById(props.id) as HTMLDivElement)
      const xdata = map.data.map((item:any)=>item.x)
      const seriesData = map.data.map((item:any)=>item.q)
      console.log('ddd', parseInt(props.height) * 0.25 + 'px' )
      barChart.setOption({
        grid: {
          // containLabel: true,
          bottom:0,
          top:0,
          left:0,
          right:0
        },
        tooltip: {
          show: true,
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xdata,
          show:  false,
          axisLine:{
            show: false,
            lineStyle:{
              // color: '#BFC0C4',
              width:0
            }
          }
        },
        yAxis: {
          x: 'center',
          type: 'value',
          min: 0,
          max: 100,
          // show: false,
          splitLine: {
            lineStyle: {
              width:1,
            }
          },
          axisLabel : {
            show: false,
            textStyle: {
                color: '#86878B'
            }
          }
        },
        series: [{
          type: 'line',
          smooth: false,
          showSymbol:false,
          itemStyle:{
            normal:{
              lineStyle:{
                width: '1.2',
                color:'#0285FA'
              },
            }
          },
          markArea:{
            silent: true,
            data: [
              [
                {
                  name: '高估',
                  itemStyle: {
                    color: '#ffded9'
                  },
                  label: {
                    color: '#666',
                    position: ['2%', '40%']
                  },
                  x: 0,
                  y: 0 //距离上边的距离
                },
                {
                  x: '100%',
                  y: '25%'
                }
              ],
              [
                {
                  name: '适中',
                  itemStyle: {
                    color: '#e9f9f8'
                  },
                  label: {
                    color: '#666',
                    position: ['2%', '40%']
                  },
                  x: 0,
                  y: '25%'
                },
                {
                  x: '100%',
                  y: '75%'
                }
              ],
              [
                {
                  name: '低估',
                  itemStyle: {
                    color: '#b9f3da'
                  },
                  label: {
                    color: '#666',
                    position: ['2%', '40%']
                  },
                  x: 0,
                  y: '75%' //距离上边的距离
                },
                {
                  x: '100%',
                  y: '100%'
                }
              ]
            ]
          },
          data:seriesData
        }]
      } as unknown as EChartsOption)
      chart.value = barChart
    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {

    }
  }
})
</script>

<style lang="scss" scoped>

</style>
