<template>
  <div class="all_data_component">
      <div class="top_nav_box">
        <span>行业</span>
        <div :class="tradeStatus === index  ? 'item_box_active' : 'item_box'" v-for="(item, index) in tradeApiList" :key="index" @click="tradeTag(item, index)">{{ item.name }}</div>
      </div>
    <div class="bottom_content_box">
      <div class="left_list_box">
        <span>个股名称</span>
          <div class="name_box">
            <div :class="stockStatus === index ? 'item_name_box_active' : 'item_name_box'" v-for="(item, index) in stockApiList" :key="index" @click="stockTag(item, index)">{{ item.name }}<br />{{ item.code }}</div>
          </div>
      </div>
      <div class="right_data_box">
        <div class="data_nav_box">
          <div class="item_nav_box">管理者</div>
          <div class="item_nav_box">职位</div>
          <div class="item_nav_box">时间</div>
          <div class="item_nav_box">标题</div>
          <div class="item_nav_box">链接</div>

        </div>
          <div class="data_content_box">
            <div class="item_list_box" v-for="(item, index) in leaderApiList" :key="index">
              <div class="item_list"><span v-for="(leadersItem, index) in item.leaders" :key="index">{{ leadersItem }}</span></div>
              <div class="item_list"><span v-for="(positionsItem, index) in item.positions" :key="index">{{ positionsItem }}</span></div>
              <div class="item_list">{{ moment(item.pubdate).format('YYYY-MM-DD HH:mm:ss') }}</div>
              <div class="item_list">{{ item.title }}</div>
              <div class="item_list"><span @click="skip(item)">{{ item.url }}</span></div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, toRefs, reactive } from 'vue'
import moment from 'moment'
export default defineComponent({
  props: {
    tradeApiList: {
      type: Array,
      default: []
    },
    stockApiList: {
      type: Array,
      default: []
    },
    leaderApiList: {
      type: Array,
      default: []
    }
  },
  setup(props, context) {
    const defineData = reactive({
      tradeStatus: 0,
      stockStatus: 0,
      moment: moment
    })
    const tradeTag = (item: any, index: any) => {
      if (item.name === '不限') {
        context.emit('tradeTopClick', { name: '' })
      } else {
        context.emit('tradeTopClick', item)
      }
      defineData.tradeStatus = index
      defineData.stockStatus = 0
    }
    const stockTag = (item: any, index: any) => {
      context.emit('stockTopTag', item)
      defineData.stockStatus = index
    }
    const skip = (item: any) => {
      window.open(item.url)
    }
    const initialization = () => {
      defineData.tradeStatus = 0
    }
    return {
      ...toRefs(defineData),
      tradeTag,
      stockTag,
      skip,
      initialization
    }
  }
})
</script>
<style scoped lang="scss">
.all_data_component {
  width: 100%;
  .top_nav_box {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    span {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      margin: 0 8px 0 15px;
      white-space: nowrap;
    }
    .item_box {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
      border-radius: 4px;
      border: 1px dashed #D9D9D9;
      padding: 2px 26px;
      margin-right: 10px;
      margin-bottom: 10px;
      white-space: nowrap;
      cursor: pointer;
    }
    .item_box_active {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #386FF2;
      line-height: 20px;
      border-radius: 4px;
      border: 0.5px solid #386FF2;
      padding: 2px 26px;
      margin-right: 10px;
      margin-bottom: 10px;
      white-space: nowrap;
      cursor: pointer;
      background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAmCAYAAACoPemuAAAAAXNSR0IArs4c6QAAA1ZJREFUWEfN1etLU2EcB/Dvs+3ocZkpCElIRERQLwosYhsSvjEyKOhmF8QudrGcuXWRjCyReiNEUk5NTZGKwKBeRERRCd0JfCFoFwSh6A8Iz3Rnbj6/OGctvMxt52xz2+vnOfs83+9zYUjBn8XpPsRSzWWtcZcTo56UgilJgagbRIaUgU1HKS2mBGw2KiVgNof7MAfdVeqbvt+Tmth8qKQmZnO6j3CirtlJBVNLSmKRUElJzOoYP0rgnfMllZTEVBTxLoAiNhVxQLxeBi2oBavS6hyrIM46o0lqwarUg0p4YhbH2DEQ69CSVMITiwWVsMRiRSUEZqsZO87B7uipL2FvpV5Ufi5DbamIK70y/oyT6ovbPWZ1SCeI0K41qbwchrYzZuTlGDAw4ofd5YkfTC8qNyuAys81wO0h2F0T+PGbxwemF5WTydBanYEVS40Ylwk1bRMY/hlAxVyltUY6SUCb1vqyzIDLbsaqZUbIkwRHuweDo1MzXj/de0wvypwOtFSZsWa5EV4f4VyHBwMjM1G6E7M4pUoj0HqsRGD3Xk1iwhvdUy+mAc2VGVi/0gSfn1Db5cHn73NRumAKChytjeXprLhAwOCoX61CngyPSzMBN05kYONqE/xThLoeD94PhUZphtkc0ilOcCl7qrjAhKtlIowGph5zpRKvLzTOZASaKjJgXWvCFCfU98roH/SHXUnUe2w6KvjFrRtNqD8owmBg+PzNr1bjmxWC0QBcOyyiaJ0AzgmND2S8GAiPijqxUKggbtsmEy4fEMEYw7shP+q6PZj6d+oZAxrKRGzZIICIcP2hjGdfIqOigtmc0mnO0RLuSthhEXBxX7qK6x/0qVUpuEv7RWy3COoamvpkPPk4T9chSg1bZQBFrmjO3E6bgAt7A7iXAz5IHsLuwjR16s3HMvreakK554VZHVIVEbVEgwqO2V0o4PweccYU11Mv7r+OcGTn/An7GhKmBxX8dulmAc5dAVzncy+6X2hFQUm9eQ7M6pTsxOm2lqRmjz1QJGDJIob2Z9pRYIwbGdbNgMUDFcuC1NPImOtT82L7f1iKoN7kZ2eWPGpgkyrM4pCqQXQr1tXqns8YZ0BbfnbmWQWlJpcsFAPcBPZLuV0MDF0fbi4enr6wv4kajVtUVov9AAAAAElFTkSuQmCC') right bottom / 19px 19px no-repeat;
    }
  }
  .bottom_content_box {
    margin-top: 28px;
    display: flex;
    .left_list_box {
      min-width: 114px;
      span {
        display: block;
        height: 48px;
        background: #F2F6FF;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 48px;
        text-align: center;
        border: 0.5px solid #D9D9D9;
      }
      .name_box {
        max-height: 520px;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 2px;
        }
        &::-webkit-scrollbar-thumb {
          background: rgba(20, 20, 20, 0.2);
          border-radius: 3px;
        }
        &::-webkit-scrollbar-track {
          background: transparent;
        }
        scrollbar-width: thin;
        .item_name_box {
          text-align: center;
          padding: 12px 0;
          border: 0.5px solid #E8E8E8;
          cursor: pointer;
        }
        .item_name_box_active {
          text-align: center;
          padding: 12px 0;
          border: 0.5px solid #E8E8E8;
          color: #3B7FEE;
          background: #F4F7FA;
          cursor: pointer;
        }
      }
    }
    .right_data_box {
      margin-left: 20px;
      .data_nav_box {
        display: flex;
        .item_nav_box {
          line-height: 48px;
          text-align: center;
          background: #F2F6FF;
          border: 0.5px solid #E8E8E8;
          &:first-child {
            width: 103px;
          }
          &:nth-child(2) {
            width: 211px;
            //width: 143px;
          }
          &:nth-child(3) {
            width: 110px;
          }
          &:nth-child(4) {
            width: 313px;
            //width: 381px;
          }
          &:last-child {
            width: 212px;
          }
        }
      }
      .data_content_box {
        max-height: 520px;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 2px;
        }
        &::-webkit-scrollbar-thumb {
          background: rgba(20, 20, 20, 0.2);
          border-radius: 3px;
        }
        &::-webkit-scrollbar-track {
          background: transparent;
        }
        scrollbar-width: thin;
        .item_list_box {
          display: flex;
          .item_list {
            padding: 13px 10px;
            border: 0.5px solid #E8E8E8;
            &:first-child {
              width: 103px;
              display: flex;
              justify-content: center;
              flex-direction: column;
              span {
                display: block;
                margin-bottom: 15px;
                &:last-child {
                  margin-bottom: 0px;
                }
              }
            }
            &:nth-child(2) {
              //width: 143px;
              width: 211px;
              display: flex;
              justify-content: center;
              flex-direction: column;
              span {
                display: block;
                margin-bottom: 15px;
                &:last-child {
                  margin-bottom: 0px;
                }
              }
            }
            &:nth-child(3) {
              width: 110px;
              display: flex;
              align-items: center;
            }
            &:nth-child(4) {
              //width: 381px;
              width: 313px;
              display: flex;
              align-items: center;
            }
            &:last-child {
              width: 212px;
              word-wrap: break-word;
              color: #3B7FEE;
              cursor: pointer;
            }
          }

        }
      }
    }
  }
}
</style>
