
import { defineComponent, onActivated, onBeforeUnmount, onDeactivated, onMounted, nextTick, watch } from 'vue'
import resize from './mixins/resize'
import { init, EChartsOption } from 'echarts'
import * as echarts from 'echarts'
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '150px',
      required: true
    },
    chartData: {
      type: Object,
      default: {}
    },
    profitabilityInfo: {
      type: Object,
      default: {}
    },
    profitType: {
      type: Number,
      default: 0
    },
    profitTypeobj: {
      type: Object,
      default: {}
    },
    dupontInfo: {
      type: Object,
      default: {}
    },
    profitType1arr1: {
      type: Object,
      default: []
    },
    profitType1arr2: {
      type: Object,
      default: []
    },
  },
  setup(props) {
    watch(props, (newName, oldName) => {
      initChart()
    });

    const getData = () => {
      let profitabilityInfo = props.profitabilityInfo
      
      let profitType = props.profitType
      let profitTypeobj = props.profitTypeobj
      let colorarr1 = ['#0086FB','#FE9625']
      let servearr = []
      let xData = []
      if(profitType==0){
        let arr = profitabilityInfo[profitTypeobj[0]]
        let dataarr = []
        for(let j=0;j<2;j++){
          if(dataarr[j]){

          }else{
            dataarr[j] = []
          }
          dataarr[j] = [
            arr['roeDupont'+(j+1)].roe,
            arr['roeDupont'+(j+1)].netprofitmarginttm2,
            arr['roeDupont'+(j+1)].turnoverttm,
            arr['roeDupont'+(j+1)].assetstoequity,
          ]
        }
        
        for (let index = 0; index < dataarr[0].length; index++) {
          xData.push(index)
        }
        for (let i = 0; i < dataarr.length; i++) {
          const item = dataarr[i];
          servearr.push({
            type: 'bar',
            name:props.dupontInfo['roeDupont'+(i+1)].name,
            barWidth:'10',
            barGap: '80%',
            label: {
              normal: {
                show: false,
              }
            },
            data: item,
            itemStyle: {
              normal: {
                barBorderRadius: [5,5,0,0],
                color:colorarr1[i]
              },
              emphasis: {
                color: '#37a2da'
              }
            }
          })
        }
        
        return {
          // legend: {
          //   icon: 'rect',//形状  类型包括 circle，rect,line，roundRect，triangle，diamond，pin，arrow，none
          //   itemWidth: 10,  // 设置宽度
          //   itemHeight: 4, // 设置高度
          //   itemGap: 24, // 设置间距
          //   // data: ['金钟股份', '车身附件及饰件'],
          //   textStyle: {
          //       //文字样式
          //       color: '#c1dafc',
          //       fontSize: '12'
          //   },
          //   right: '30%'
          // },
          grid: {
            left: -20,
            right: 20,
            bottom: 0,
            top: 0,
            containLabel: true
          },
          tooltip: {
            show: true,
            trigger: 'axis', 
            // position:'inside',
            // padding:4,
            // textStyle:{
            //   color:'#999999',
            //   fontSize:'10',
            // },
            // position:function(point,params,dom,rect){
            //   let x = 0
            //   let y= 0
            //   if(point[0]>140){
            //     x = point[0] - 100
            //   }else{
            //     x = point[0] +10
            //   }
            //   if(point[1]>55){
            //     y = point[1] - 60
            //   }else{
            //     y = point[1]
            //   }
            //   return [x,y]
            // },
            formatter:function(param:any){
              // 利用循环更改显示的内容
              let list = param.length
              let Oparam=param
              // console.log(list)
              let txt =''
              for (var i=0;i<list;i++){
                if(i==0){
                  txt += '<div style="padding-bootom:10px">' + param[i].marker+ param[i].seriesName + ":" + param[i].data+ '</div>'
                }else{
                  txt +=param[i].marker+ param[i].seriesName + ":" + param[i].data 
                }
              }
              return txt
            }
          },
          xAxis: [
            {
              type: 'category',
              data: xData,
              // show:false,
              axisTick:{
                show:false
              },
              axisLabel: {
                show:false
              },
              axisLine:{
                show:true,
                lineStyle: {
                  color:'#F4F4F4',
                }
              }
            }
          ],
          yAxis: [
            {
              // type: 'category',
              type: 'value',
              show:false,
              axisTick: { show: false },
              axisLine: {
                lineStyle: {
                  color: '#999'
                }
              },
              axisLabel: {
                color: '#666'
              }
            }
          ],
          series:servearr
        }

      }else{
        let servearr = [],dataarr:any = []

        for(let z =0;z<2;z++){
          if(props['profitType1arr'+(z+1)]&&props['profitType1arr'+(z+1)].length>0){
            if(dataarr[z]){

            }else{
              dataarr[z] = []
            }
            props['profitType1arr'+(z+1)].forEach((item:any)=>{
              if(!item.value){
                dataarr[z].push(0)
              }else{
                dataarr[z].push(item.value)
              }
            })
          }

        }
        for (let zz = 0; zz < dataarr.length; zz++) {
          const item = dataarr[zz];
          servearr.push(
            {
              name: props.dupontInfo['roeDupont'+(zz+1)].name,
              type: 'line',
              smooth: false,
              symbol:'circle',
              showSymbol:true,
              symbolSize: 6,
              label : {
                show:zz==0?true:false,
                color:colorarr1[zz],
                formatter:function(val:any){                 
                  return val.value.toFixed(2)*1 +'%'
                }
              },
              itemStyle:{
                normal:{
                  lineStyle:{
                    color:colorarr1[zz],
                  
                  },
                }
              },
              data: item
            }
          )
        }

         // console.log(servearr)

        return {
          //  backgroundColor: "#ffffff",
          grid: {
            containLabel: true,
            bottom:10,
            top:20,
            left:0,
            right:20
          },
          // tooltip: {
          //   // show: true,
          //   trigger: 'axis'
          // },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: [],
            // show: false,
            axisLine:{
              lineStyle:{
                color: '#F4F4F4',
                width:1,
              }
            },
            axisLabel:{
              margin:20,
            show: false,
              textStyle:{
                color:'#999999',
                fontSize:'12',
              }
            }
          },
          yAxis:{
            x: 'center',
            type: 'value',
            splitNumber:3,
            show:false,
            splitLine: {
      
              lineStyle: {
           
                // type: 'dashed'
                color:'#141518'
              }
            },
            axisLabel:{
              formatter: '{value} %',
            },
    
          },
          series: servearr
        }

      }
    }
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()
    const initChart = () => {
      if( !Object.keys(props.profitabilityInfo).length ){
        return false;
      }
      const barChart = init(document.getElementById(props.id) as HTMLDivElement)

      barChart.setOption(getData() as EChartsOption)
      chart.value = barChart
    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {

    }
  }
})
