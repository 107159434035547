
import { defineComponent, reactive, toRefs } from 'vue'
export default defineComponent({
  props:{
    init: {
      type: Boolean,
      default: false
    },
    timeFilter: {
      type: Array,
      default: []
    },
    isCustom:{
      type: Boolean,
      default: true
    },
    showCustom2:{
      type: Boolean,
      default: true
    },
    initShowInput: {
      type: Boolean,
      default: false
    }
  },
  setup(props,contex) {
    const dataMap = reactive({
      activeIdx: 0,
      showCustom: false,
      value1: '',
      value2: '',
      timeArr: [
        {
          name: '日',
          val: "DAY",
          periodType: 1,
          idx: 0
        },{
          name: '周',
          val: "WEEK",
          periodType: 2,
          idx: 1
        },{
          name: '月',
          val: "MONTH",
          periodType: 3,
          idx: 2
        },{
          name: '季',
          val: "QUARTER",
          periodType: 4,
          idx: 3
        },{
          name: '年',
          val: "YEAR",
          periodType: 5,
          idx: 4
        },{
          name: '自定义',
          type: 'custom'
        }
      ],
    })
    //日期筛选
    if( props.timeFilter && props.timeFilter.length ){
      dataMap.timeArr = dataMap.timeArr.filter((item:any)=>props.timeFilter?.includes(item.val))
    }
    //不显示自定义
    if( !props.isCustom ){
      dataMap.timeArr = dataMap.timeArr.slice(0,-1)
    }
    //默认展示日期选择框
    if( props.initShowInput ){
      dataMap.showCustom = true
    }

    //初始化请求接口
    if ( props.init ){
      contex.emit('input', dataMap.timeArr[dataMap.activeIdx])
    }

    //时间切换
    const handleTimeChange = (idx:number) => {
      dataMap.activeIdx = idx
      if( dataMap.timeArr[idx].type === "custom" ){
          dataMap.showCustom = true
          return false
      }else{
          dataMap.showCustom = props.initShowInput ? true : false
      }
      contex.emit('input', dataMap.timeArr[dataMap.activeIdx])
    }

    const handleChange = () => {
      const { value1, value2 } = dataMap
      if( props.showCustom2 ){
        contex.emit('input', {custom: [!value1 ? value1 : window.moment(value1).format('YYYY-MM-DD'),!value2 ? value2 : window.moment(value2).format('YYYY-MM-DD')].join(',')})
      }else{
        contex.emit('input', {custom: value1})
      }
    }

    const handleChange2 = () => {
      const { value1, value2 } = dataMap
      const date = [!value1 ? value1 : window.moment(value1).format('YYYY-MM-DD'),!value2 ? value2 : window.moment(value2).format('YYYY-MM-DD')].join(',')
      contex.emit('input', {custom: date})
    }

    return {
      ...toRefs(dataMap),
      handleTimeChange,
      handleChange,
      handleChange2
    };
  },
});
