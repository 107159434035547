
import { defineComponent, toRefs, reactive } from 'vue'
import moment from 'moment'
export default defineComponent({
  props: {
    tradeApiList: {
      type: Array,
      default: []
    },
    stockApiList: {
      type: Array,
      default: []
    },
    leaderApiList: {
      type: Array,
      default: []
    }
  },
  setup(props, context) {
    const defineData = reactive({
      tradeStatus: 0,
      stockStatus: 0,
      moment: moment
    })
    const tradeTag = (item: any, index: any) => {
      if (item.name === '不限') {
        context.emit('tradeTopClick', { name: '' })
      } else {
        context.emit('tradeTopClick', item)
      }
      defineData.tradeStatus = index
      defineData.stockStatus = 0
    }
    const stockTag = (item: any, index: any) => {
      context.emit('stockTopTag', item)
      defineData.stockStatus = index
    }
    const skip = (item: any) => {
      window.open(item.url)
    }
    const initialization = () => {
      defineData.tradeStatus = 0
    }
    return {
      ...toRefs(defineData),
      tradeTag,
      stockTag,
      skip,
      initialization
    }
  }
})
