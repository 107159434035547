
import chartA from "./charts/chartA.vue";
import timeList from "./components/timeList.vue"
import { defineComponent, reactive, toRefs } from 'vue'
import { getMarketEvent, getRrrMarketIncr, getStampTaxMarketIncr, getInterestRate } from '@/apis/stampduty'
export default defineComponent({
  components: {
    chartA,
    timeList
  },
  setup() {
    const dataMap = reactive({
      jsIdx: 0,
      jsTabList: [{
        value: 7,
        name: '7日逆回购利率'
      },{
        value: 5,
        name: '7天SLF操作利率'
      },{
        value: 6,
        name: '1年期MLF利率'
      },{
        value: 3,
        name: '1年期LPR利率'
      },{
        value: 4,
        name: '5年期LPR利率'
      }],
      jzChartList: [],
      rrrMarketIncrList: [],
      jsList: [],
      interestRateList: [],
      yhsChartList: [],
      yhsTable: [],
      jxtimeLevel: '5y',
    })

    const tableEmptyRepalceSyl = (list:any, filed:any) => {
      const newList = list.map((item:any)=>{
        filed.map((innerItem:any)=>{
          if( item[innerItem] !== 0 && !item[innerItem] ){
            item[innerItem] = '-'
          }
        })
        return item
      })
      return newList
    }

    const periodType = 1 //1:日K,2:周K,3:月K
    const handleJyhsTimeChange = async (val:any) => {
      //1:降准,2:降印花税,3:1年贷款利率LPR,4:5年贷款利率LPR,5:常备借贷便利SLF利率, 6:中期借贷便利MLF利率, 7:逆回购利率
      const yhsChartList = await getMarketEvent({ periodType,timeLevel: val,eventType: 2 })
      dataMap.yhsChartList = (yhsChartList as any).data;
      const yhsTable = await getStampTaxMarketIncr({ periodType,timeLevel: val,eventType: 2 })
      dataMap.yhsTable = (yhsTable as any).data;
    }

    //降息切换
    const handleJxTimeChange = async (val:any) => {
      dataMap.jxtimeLevel = val
      const jsList = await getMarketEvent({ periodType,timeLevel: val, eventType: dataMap.jsTabList[dataMap.jsIdx].value })
      dataMap.jsList = (jsList as any).data;
      const interestRateList = await getInterestRate({ periodType,timeLevel: val,eventType: 2 })
      dataMap.interestRateList = tableEmptyRepalceSyl((interestRateList as any)['data'], ['nhgDate', 'nhgBp', 'mlfBp', 'slfDate', 'slfBp', 'mlfDate', 'lpr1Date', 'lpr1Bp', 'lpr5Date', 'lpr5Bp'])
    }

    //降准切换
    const handleJzTimeChange = async (val:any) => {
      const jzChartList = await getMarketEvent({ periodType,timeLevel: val,eventType: 1 })
      dataMap.jzChartList = (jzChartList as any).data;
      const rrrMarketIncrList = await getRrrMarketIncr({ periodType,timeLevel: val,eventType: 1 })
      dataMap.rrrMarketIncrList = (rrrMarketIncrList as any).data;
    }

    //降息tab切换
    const handleJsChange = (idx:number) => {
      dataMap.jsIdx = idx
      handleJxTimeChange(dataMap.jxtimeLevel)
    }

    return {
      ...toRefs(dataMap),
      handleJsChange,
      handleJzTimeChange,
      handleJxTimeChange,
      handleJyhsTimeChange
    };
  },
});
