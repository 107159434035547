
import { defineComponent, reactive, toRefs } from 'vue'
import { useRoute } from "vue-router"
import { getFinancialDetailed, getFinancialReport } from '@/apis/financial'
import quarterCharts from './component/quarter-charts.vue'
import yearCharts from './component/year-charts.vue'
import pageTitle from '../components/PageTitle.vue'
import navTab from '../components/nav-switch.vue'
import tableComponent from './component/table.vue'
import quarterTable from './component/quarter-table.vue'
import { getStockTradeDate } from '@/apis/market'
import moment from 'moment'
export default defineComponent({
  components: {
    pageTitle,
    navTab,
    quarterCharts,
    yearCharts,
    tableComponent,
    quarterTable
  },
  setup() {
    const route = useRoute()
    const defineData = reactive({
      stockDate: '',
      titleName: route.query.stockName,
      stockCodeName: route.query.stockCode,
      tabList: [
        {
          id: 1,
          name: '按业绩增速'
        },
        {
          id: 2,
          name: '按归母净利润'
        }
      ],
      financialData: {},
      type: 1,
      reportData: []
    })
    const fetchData = async () => {
      const list = await getFinancialDetailed({ stockCode: route.query.stockCode })
      if ((list as any).code === 200) {
        defineData.financialData = (list as any).data
      }

    }
    const fetchList = async () => {
      const listReport = await getFinancialReport({ stockCode: route.query.stockCode })
      if ( listReport && (listReport as any).code === 200 ) {
        defineData.reportData = (listReport as any).data
      }
    }
    fetchData()
    fetchList()
    const changeTab = (item: any) =>  {
      defineData.type = item.id
    }
    const getTime = async () => {
      const data = await getStockTradeDate({ })
      if( (data as any).data.timestamp ){
        defineData.stockDate = moment((data as any).data.timestamp).format('YYYY年MM月DD日');
      }
    }
    getTime()
    return {
      ...toRefs(defineData),
      changeTab
    }
  }
})
