
import { defineComponent, reactive, toRefs, ref, nextTick } from 'vue'
import NavTitle from '../components/nav-title.vue'
import PageTitle from '../components/page-title.vue'
import DateTab from '../../northbound-funds/components/CustomTab.vue'
import ChartA from './components/ChartA.vue'
import TableA from './components/TableA.vue'
import TableB from './components/TableB.vue'
import CTab from '../components/nav-switch.vue'
import { getMarginStockDetail } from '@/apis/trading'

import { getStockTradeDate } from '@/apis/market'
import moment from 'moment/moment'
import { useRoute  } from "vue-router";

export default defineComponent({
  components:{
    NavTitle,
    PageTitle,
    DateTab,
    ChartA,
    CTab,
    TableA,
    TableB,
  },
  setup() {
    const route = useRoute();
    const refTA = ref(null)
    const refTB = ref(null)
    const dataMap = reactive({
      stockName: route.query.name,
      stockDate: '',
      initialStatus: 1,
      showChartIdx: 1,
      showChartClass: 0,
      chartKey : 'rzye',
      tabList: [
        {
          id: 1,
          name: '电池行业持股明细'
        },
        {
          id: 2,
          name: '电池行业持股历史'
        }
      ],
      stockList: {
        list: []
      },
      boardDetail: {}
    })

    const getTime = async () => {
      const data6 = await getStockTradeDate({ })
      dataMap.stockDate = moment((data6 as any).data.prevRzrqTradeDate).format('YYYY年MM月DD日');
    }
    getTime()

    const fetchData = async () => {
      // 明细
      const boardDetail = await getMarginStockDetail({ stockCode: route.query.code })
      dataMap.boardDetail = (boardDetail as any).data;
    }
    fetchData()

    const tabChart = (idx:any, type:any, key:any) => {
      dataMap.showChartClass = idx
      dataMap.showChartIdx = type
      dataMap.chartKey = key
    }

    const changeTab = (item: any) => {
      console.log(item, 'item')
      dataMap.initialStatus = item.id
    }

    const getInitTime = async (val:any) => {
      nextTick(()=>{
        (refTA as any).value.changeTime(val)
      })
    }

    const zh = (param:any) => {
      return `${Math.abs(param) >= 100000000 ? (param / 100000000).toFixed(2) + '亿' : Math.abs(param) >= 10000 ? Math.abs((param / 10000)).toFixed(2) + '万' : param.toFixed(2) }`
    }

    return {
      ...toRefs(dataMap),
      tabChart,
      changeTab,
      getInitTime,
      refTA,
      refTB,
      zh
    }
  }
})
