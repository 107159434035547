
import { defineComponent, reactive, toRefs } from 'vue'
import { getFinanceReportToday } from '@/apis/financial-report'
import PagingComponent from '@/components/pagination/Index.vue'
import { bigNumberTransform } from '@/utils/data-format'

export default defineComponent({
  components: {
    PagingComponent,
  },
  setup() {
    const dataMap = reactive({
      tableData: {},
      loading: true
    })
    let params = {
      // pageNum: '1',
      // pageSize: '10',
      // market: 'ALL',
      // timeUnit: 'WEEKS',
      // num: '1',
      sortField: 'publishDate'
    }
    const fetchData = async (value: any) => {
      if(value.pageNum){
        params['pageNum'] = value.pageNum
      }
      if(value.pageSize){
        params['pageSize'] = value.pageSize
      }
      
      dataMap.loading = true
      let marginList:any = await getFinanceReportToday(params)
      dataMap.tableData = marginList.data;
      dataMap.loading = false
    }

    const sortChange = (column: any) => {
      let isAsc = column.order === "ascending" ? 'asc' : 'desc';
      params['sortField'] = column["prop"]
      params['sort'] = isAsc
      fetchData({})
    }

    const addClass = (column:any) => {
      if( column.column.property === params.sortField ){
        return 'sort-bg'
      }
    }

    const goDetail = (item: any) => {
      const path = '/financial/detailed'
      window.open( `/investment${path}?stockCode=${item.code}&stockName=${item.name}`)
    }

    return {
      ...toRefs(dataMap),
      fetchData,
      sortChange,
      bigNumberTransform,
      addClass,
      goDetail
    }
  }
})
