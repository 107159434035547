
import { defineComponent, reactive, toRefs } from 'vue'
export default defineComponent({
  props: {
    tableList: {
      type: Object,
      default: {}
    }

  },
  setup() {
    const defineData = reactive({

    })
    const amount = (value: any, fixed = 2, unit = '元') => {
      if (unit == '万') {
        value = value * 10000
      } else if (unit == '亿') {
        value = value * 100000000
      }
      if (Math.abs(value / 100000000) >= 1) {
        return (value / 100000000).toFixed(fixed) + '亿'
      }
      if (Math.abs(value / 10_000) >= 1) {
        return (value / 10000).toFixed(fixed) + '万'
      }
      return value
    }
    return {
      ...toRefs(defineData),
      amount
    }
  }
})
