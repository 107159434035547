<template>
  <div class="table_box">
    <div class="top_nav_box">
      <ul v-for="item in titleList" :key="item.id">
        <li>{{ item.name }}</li>
      </ul>
    </div>
    <div :class="index % 2 == 0 ? 'item_box' : 'active_item_box'" v-for="(item, index) in tableList" :key="item.id">
      <ul>
        <li>{{ item.stockName }} <br /> {{ item.stockCode }}</li>
        <li>{{ amount(item.rzye) }}</li>
        <li>{{ (item.rzyezb).toFixed(2) }}%</li>
        <li>{{ amount(item.rzmr) }}</li>
        <li>{{ amount(item.rzjmr) }}</li>
        <li>{{ amount(item.rzrqye) }}</li>
        <li>{{ item.swl1 }}</li>
        <li><trend-chart :id="`traaender-${chartId}-${index}`" :chart-data="Array.from(item.dataList, ({ rzye }) => rzye)" /></li>
      </ul>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue'
import trendChart from './trend-chart.vue'
export default defineComponent({
  props: {
    tableList: {
      type: Array,
      default: []
    },
    chartId: {
      type: String,
      default: ''
    },
  },
  components: {
    trendChart
  },
  setup() {
    const dataMap = reactive({
      titleList: [
        {
          id: 1,
          name: '个股名称/代码'
        },
        {
          id: 2,
          name: '融资余额(元)'
        },
        {
          id: 3,
          name: '融资余额占流通市值比'
        },
        {
          id: 4,
          name: '融资买入额(元)'
        },
        {
          id: 5,
          name: '融资净买入(元)'
        },
        {
          id: 6,
          name: '融资融券余额(元)'
        },
        {
          id: 7,
          name: '申万一级'
        },
        {
          id: 8,
          name: '趋势图'
        }
      ]
    })
    const amount = (value: any, fixed = 2, unit = '元') => {
      if (unit == '万') {
        value = value * 10000
      } else if (unit == '亿') {
        value = value * 100000000
      }
      if (Math.abs(value / 100000000) >= 1) {
        return (value / 100000000).toFixed(fixed) + '亿'
      }
      if (Math.abs(value / 10_000) >= 1) {
        return (value / 10000).toFixed(fixed) + '万'
      }
      return value
    }
    return {
      ...toRefs(dataMap),
      amount
    }
  }
})

</script>
<style scoped lang="scss">
.table_box {
  margin: 38px auto 38px auto;
  border: 1px solid #E8E8E8;
  > .top_nav_box {
    width: 100%;
    height: 60px;
    background: #F2F6FF;
    display: flex;
    align-items: center;
    > ul {
      font-size: 16px;
      height: 100%;
      &:first-child {
        width: 14%;
      }
      &:nth-child(2) {
        width: 9%;
      }
      &:nth-child(3) {
        width: 12%;
        li{
          padding: 0 6px 0 6px;
        }
      }
      &:nth-child(4) {
        width: 12%;
      }
      &:nth-child(5) {
        width: 13%;
      }
      &:nth-child(6) {
        width: 16%;
      }
      &:nth-child(7) {
        width: 12%;
      }
      &:last-child {
        width: 12%;
        border-right: none;
      }
      > li {
        text-align: center;
        height: 100%;
        border-right: 1px solid #E8E8E8;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  > .item_box {
    width: 100%;
    background: #FFFFFF;
    > ul {
      font-size: 16px;
      display: flex;
      height: 100%;
      > li {
        border-right: 1px solid #E8E8E8;
        &:first-child {
          width: 14%;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 6px 0;
        }
        &:nth-child(2) {
          width: 9%;
          text-align: right;
          padding-right: 10px;
          display: flex;
          align-items: center;
          justify-content: right;
        }
        &:nth-child(3) {
          width: 12%;
          text-align: right;
          padding-right: 28px;
          display: flex;
          align-items: center;
          justify-content: right;
        }
        &:nth-child(4) {
          width: 12%;
          text-align: right;
          padding-right: 28px;
          display: flex;
          align-items: center;
          justify-content: right;
        }
        &:nth-child(5) {
          width: 13%;
          text-align: right;
          padding-right: 28px;
          display: flex;
          align-items: center;
          justify-content: right;
        }
        &:nth-child(6) {
          width: 16%;
          text-align: right;
          padding-right: 8px;
          display: flex;
          align-items: center;
          justify-content: right;
        }
        &:nth-child(7) {
          width: 12%;
          text-align: right;
          padding-right: 8px;
          display: flex;
          align-items: center;
          justify-content: right;
        }
        &:last-child {
          width: 12%;
          border-right: none;
          display: flex;
          align-items: center;
        }
      }
    }
  }
  >  .active_item_box {
    width: 100%;
    background: #F4F7FA;
    > ul {
      font-size: 16px;
      display: flex;
      height: 100%;
      > li {
        border-right: 1px solid #E8E8E8;
        &:first-child {
          width: 14%;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 6px 0;
        }
        &:nth-child(2) {
          width: 9%;
          text-align: right;
          padding-right: 10px;
          display: flex;
          align-items: center;
          justify-content: right;
        }
        &:nth-child(3) {
          width: 12%;
          text-align: right;
          padding-right: 28px;
          display: flex;
          align-items: center;
          justify-content: right;
        }
        &:nth-child(4) {
          width: 12%;
          text-align: right;
          padding-right: 28px;
          display: flex;
          align-items: center;
          justify-content: right;
        }
        &:nth-child(5) {
          width: 13%;
          text-align: right;
          padding-right: 28px;
          display: flex;
          align-items: center;
          justify-content: right;
        }
        &:nth-child(6) {
          width: 16%;
          text-align: right;
          padding-right: 8px;
          display: flex;
          align-items: center;
          justify-content: right;
        }
        &:nth-child(7) {
          width: 12%;
          text-align: right;
          padding-right: 8px;
          display: flex;
          align-items: center;
          justify-content: right;
        }
        &:last-child {
          width: 12%;
          border-right: none;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
</style>
