

import { defineComponent, reactive, toRefs } from 'vue'
import CLIST from './List.vue'

export default defineComponent({
  components: {
    CLIST
  },
  setup() {
    
    

    return {
      // ...toRefs(dataMap),
    }
  }
})
