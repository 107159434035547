
import { defineComponent, onActivated, onBeforeUnmount, onDeactivated, onMounted, nextTick, watch } from 'vue'
import resize from './mixins/resize'
import { init, EChartsOption } from 'echarts'
import * as echarts from 'echarts'
export default defineComponent({
  props: {
    chartData: {
      type: Object,
      default: {}
    },
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '300px',
      required: true
    },
    activeRate: {
      type: Number,
      default: 0
    },
    legend: {
      type: Object,
      default: []
    },
    
  },
  setup(props,context) {
    watch(props, (newName, oldName) => {
      initChart()
    });

    const rate = ['pe','pb','psTtm','pcfOcfTtm']
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()
    const initChart = () => {
      const map = props.chartData;
      if( !Object.keys(map).length ){
        return false;
      }

      const barChart = init(document.getElementById(props.id) as HTMLDivElement)
      let series = props.chartData;
      let minValue = series['price'][0].y ?? 0;
      let priceList = [];
      let xdata:any = [],seriesData:any = []
      for (let index = 0; index < series['price'].length; index++) {
          const item = series['price'][index];
          if( item.y < minValue ){ minValue = item.y }
          priceList.push(item.y)
      }
      for (let iii = 0; iii < 5; iii++) {
        if( !xdata[iii] ){
          xdata[iii] = []
        }
        if( !seriesData[iii] ){
          seriesData[iii] = []
        }
        let type = rate[props.activeRate]
        for (let index = 0; index < series[type+(iii+1)+'x'].length; index++) {
          
          const item = series[type+(iii+1)+'x'][index];
          
          xdata[iii].push(window.moment(item['x'+(iii+1)]).format('YYYY-MM-DD'))

          const val = item['y'+(iii+1)];
          if( val < minValue ){ minValue = val }
          seriesData[iii].push(val)
        }
      }


      context.emit('getpepdTime',[window.moment(xdata[0][0]).format('YYYY-MM-DD'),window.moment(xdata[0][xdata[0].length-1]).format('YYYY-MM-DD')])
      
      console.log(999999999999, minValue)
      barChart.setOption({
        //  backgroundColor: "#ffffff",
        legend:{
          // width: 300,
          icon: 'circle',
          left: '0',
          top: '20',
          textStyle: {
            align: 'left', // 这个是可以左右调整的
            fontSize: 14,// 字体大小
            color:'#666666'
            // padding:[0,0,25,-45]//主要就是用这个，可以把文字上下调整
            // color: 'rgba(255,255,255,0.65)'//字体颜色
          },
          // formatter:function(res:any){
          //   console.log(2222,res)
          //   return res + '\n'
          // }
        },
        grid: {
          containLabel: true,
          bottom:10,
          top:85,
          left:0,
          right:0
        },
        tooltip: {
          show: true,
          trigger: 'axis', 
          // position:'inside',
          // padding:4,
          textStyle:{
            color:'#999999',
            fontSize:'10',
          },
          position:function(point:any,params:any,dom:any,rect:any){
            let x = 0
            let y= 0
            if(point[0]>210){
              x = point[0] - 100
            }else{
              x = point[0] +10
            }
            if(point[1]>55){
              y = point[1] - 60
            }else{
              y = point[1]
            }
            return [x,y]
          },
          formatter:function(param:any){
           // 利用循环更改显示的内容
            let list = param.length
            // console.log(list)
            let txt =" "+ param[0].name+"\n"
            for (var i=0;i<list;i++){
             if(i==list-1){
              txt += '<div style="padding-top:6px"><div style="width:60px;display:inline-block">' + param[i].marker+ param[i].seriesName + '</div><div style="width:40px;text-align:right;display:inline-block">' + param[i].data.toFixed(2) + '</div></div>'
             }else{
               txt += '<div style="padding-top:6px"><div style="width:60px;display:inline-block">' + param[i].marker+ param[i].seriesName + '</div><div style="width:40px;text-align:right;display:inline-block">' + param[i].data.toFixed(2)  + '</div></div>'
             }
            }
            return txt
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xdata[0],
          show:  false,
          axisLine:{
            lineStyle:{
              color: '#BFC0C4',
              width:1,
            }
          },
          axisLabel:{
            // interval:Math.floor(list1.length/3),
            formatter:function(val:any){      
              return ''
            },
            margin:20,
            textStyle:{
              
            }
          }
        },
        yAxis: {
          x: 'center',
          type: 'value',
          splitLine: {
            lineStyle: {
              // type: 'dashed'
              color: '#e6e6e6',
              width:1,
            }
          },
          axisLabel : {
            textStyle: {
              color: '#0E1018',
            }
          },
          
          min:parseInt(minValue),
          // max:max,
          // interval:interval,
          // show: false
        },
        color: ['#F24643','#FFDC92','#00CAFA','#3E7FFF','#9F44E9','#FB93B6'],
        series: [{
          name: '股价',
          type: 'line',
          smooth: false,
          showSymbol:false,
          data:priceList,
          lineStyle:{
            normal:{
              width:1
            }
          }
        },{
          name: props.legend[0],
          type: 'line',
          smooth: false,
          showSymbol:false,
          data:seriesData[0],
          lineStyle:{
            normal:{
              width:1
            }
          }
        },{
          name: props.legend[1],
          type: 'line',
          smooth: false,
          showSymbol:false,
          data:seriesData[1],
          lineStyle:{
            normal:{
              width:1
            }
          }
        },{
          name: props.legend[2],
          type: 'line',
          smooth: false,
          showSymbol:false,
          data:seriesData[2],
          lineStyle:{
            normal:{
              width:1
            }
          }
        },{
          name: props.legend[3],
          type: 'line',
          smooth: false,
          showSymbol:false,
          data:seriesData[3],
          lineStyle:{
            normal:{
              width:1
            }
          }
        },{
          name: props.legend[4],
          type: 'line',
          smooth: false,
          showSymbol:false,
          data:seriesData[4],
          lineStyle:{
            normal:{
              width:1
            }
          }
        }]
      } as unknown as EChartsOption)
      chart.value = barChart
    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {

    }
  }
})
