
import { defineComponent, onActivated, onBeforeUnmount, onDeactivated, onMounted, nextTick, watch } from 'vue'
import resize from '@/components/charts/mixins/resize'
import { init, EChartsOption } from 'echarts'
import * as echarts from 'echarts'
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '600px',
      required: true
    },
    chartData: {
      type: Object,
      default: {}
    },
    selectedTime: {
      type: String || Number,
      default: ''
    },
  },
  setup(props) {
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()

    watch(props, (newName, oldName) => {
      initChart()
    });

    const initChart = () => {
      const chartData = JSON.parse(JSON.stringify(props.chartData))
      if( !chartData.decrTop ){
        return false;
      }
      const barChart = init(document.getElementById(props.id) as HTMLDivElement)
      // const xData = chartData.map((item:any)=>item.name)
      const incrTop = chartData.incrTop.map((item:any,idx:number)=>{
        return {
          value: item.incr,
          itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: '#FD0A2D'
                }, {
                  offset: 1,
                  color: '#FF4B23'
                }])
              }
          },
        }
      })
      const decrTop = chartData.decrTop.map((item:any)=>{
        return {
          value: item.incr,
          itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: '#00AD88'
                }, {
                  offset: 1,
                  color: '#00B43F'
                }])
              }
          }
        }
      })
      const incrTopPoint = chartData.incrTop.map((item:any,idx:number)=>{
        return {
          value: item.boardName,
          symbolSize: 0,
          coord: [idx, 0],
          label:{
            color: '#fff',
            position: 'top',
            formatter: function(param:any){
              return param.value.split('').join('\n')
            }
          }
        }
      })
      const decrTopPoint = chartData.decrTop.map((item:any,idx:number)=>{
        return {
          value: item.boardName,
          symbolSize: 0,
          coord: [idx, 0],
          label:{
            color: '#fff',
            position: 'bottom',
            formatter: function(param:any){
              return param.value.split('').join('\n')
            }
          }
        }
      })

      barChart.setOption({
        grid: {
          top: '40px',
          left: '0',
          right: '10px',
          bottom: '20px',
          containLabel: true
        },
        legend:{
          selectedMode: false,
          icon: 'rect',
          itemWidth: 8,
          itemHeight: 8,
          // color: ['#F93F41', 'green'],
          textStyle: {
            height: 10,
            rich: {
              a: {
                verticalAlign: 'bottom',
              },
            }
          }
          // padding: [1,2,3,4]
        },
        toolbox: {
          show: true
        },
        calculable: true,
        xAxis: [
          {
            type: 'category',
            data: incrTop.length > decrTop.length ? incrTop : decrTop,
            axisLine: {
                show: true,
                lineStyle: {
                    color: "#333333",
                }
            },
            axisLabel: {
              show: false,
              interval: 0,//使x轴文字显示全
            },
            axisTick: {
              show: false
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: { show: false },
            // axisTick: { show: false },
            axisLabel: { show: false }
          }
        ],
        series: [
          {
            name: '区间涨幅前五(%)',
            stack: 'total',
            barGap: '80%',
            barWidth: '60%',
            type: 'bar',
            data: incrTop,
            itemStyle: {
              normal: {
                label: {
                  // formatter: '{c}',
                  show: true,
                  position: 'top',
                  textStyle: {
                    fontWeight: 'bolder',
                    fontSize: '12',
                    color: '#000'
                  }
                },
                // color: 'green',
                opacity: 1
              }
            },
            color: '#F93F41',
            barMinHeight: 70,
            markPoint: {
              // symbol: 'none',
              data: incrTopPoint
            }
          },
          {
            name: '区间跌幅前五(%)',
            stack: 'total',
            barGap: '80%',
            barWidth: '60%',
            type: 'bar',
            data: decrTop,
            itemStyle: {
              normal: {
                label: {
                  // formatter: '{c}',
                  show: true,
                  position: 'bottom',
                  textStyle: {
                    fontWeight: 'bolder',
                    fontSize: '12',
                    color: '#000'
                  }
                }
              }
            },
            color: '#5CBC7C',
            barMinHeight: 70,
            markPoint: {
              // symbol: 'none',
              data: decrTopPoint
            }
          }
        ]
      } as EChartsOption)
      chart.value = barChart


    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {

    }
  }
})
