<template>
  <div
    :id="id"
    :class="className"
    :style="{height: height, width: width}"
  />
</template>

<script lang="ts">
import { defineComponent, onActivated, onBeforeUnmount, onDeactivated, onMounted, nextTick } from 'vue'
import resize from './mixins/resize'
import { init, EChartsOption } from 'echarts'
import * as echarts from 'echarts'
export default defineComponent({
  props: {
    chartData:{
      type: Object,
      default: {}
    },
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '340px',
      required: true
    },
    height: {
      type: String,
      default: '200px',
      required: true
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    
  },
  setup(props) {
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()
    const initChart = () => {
      const barChart = init(document.getElementById(props.id) as HTMLDivElement)
      let dimensionalGraphList = props.chartData.dimensionalGraphList
      let color=['#FE9625','#1667EC','#1667EC']
      // let colorbg = ['#3C454C','#353D46','#1A2736']
      let max=0,serieslist=[],legend=[];
      if(dimensionalGraphList.length>0){
        for(var i=0;i<dimensionalGraphList.length;i++){
          let item = dimensionalGraphList[i]
          legend.push(item.ReportDate)
   
          for (const key in item) {
            if (item.hasOwnProperty.call(item, key)) {
              if(item[key]*1){
                if(max==0){
                  max = item[key]
                }else if(max<item[key]*1){
                  max = item[key]
                }
              }
              
            }
          }
          let iiitem = {
            type:'radar',
            name:item.ReportDate,
            value: [item.ProfitabilityScore*1, item.CashFlowScore*1, item.ValuationScore*1, item.OperationScore*1, item.GrowUpScore*1],
            symbol:'circle',
            symbolSize: 6,
            lineStyle: { 
                width: 2,
                color: color[i],
                borderColor: color[i],
                borderWidth: 1,
            },
            itemStyle:{
              normal: {
                // borderColor: color[i],
                // borderWidth: 0.5,
                color:color[i]
              }
            },
          }
          // if(this.data.huyan==1){
          //   iiitem.areaStyle={ 
          //     normal: {
          //         color: colorbg[i]
          //     }
          //   }
          // }
          serieslist.push(iiitem)
          
        }
      }
      max = Math.floor(max*1.2)
      // console.log(legend)
      // console.log(999,serieslist,max)
      barChart.setOption({
          // title: {
          //   text: 'Basic Radar Chart'
          // },
          legend: {
            icon: 'circle',
            data: legend,//['Allocated Budget', 'Actual Spending'],
            left: '0',
            // top: 10
          },
          radar: {
            // shape: 'circle',
            radius: 50,//缩放
            center: props.isMobile ? ['50%', '70%'] : ['58%', '70%'],//位置
            indicator: [
              { name: '盈利能力', max: max },
              { name: '成长能力', max: max },
              { name: '营运能力', max: max },
              { name: '偿债能力', max: max },
              { name: '现金流', max: max },
            ]
          },
          series:[{
            type: 'radar',
            data:serieslist
          }]
          // series: [
          //   {
          //     name: 'Budget vs spending',
          //     type: 'radar',
              
          //     data: [
          //       {
          //         value: [4200, 3000, 20000, 35000, 50000, 18000],
          //         name: 'Allocated Budget'
          //       },
          //       {
          //         value: [5000, 14000, 28000, 26000, 42000, 21000],
          //         name: 'Actual Spending'
          //       }
          //     ]
          //   }
          // ]
        } as unknown as EChartsOption)
      chart.value = barChart
    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {

    }
  }
})
</script>

<style lang="scss" scoped>
.list{
  display: flex;
  .item{
    margin-right: 30px;
    &:last-child{
      .circle{
        background: #3C7FEE;
      }
    }
    .circle{
      width: 12px;
      height: 12px;
      background: #FF9625;
      border-radius: 50%;
      display: inline-block;
      margin-right: 8px;
    }
  }
}
.content{
  display: flex;
  text-align: center;
  margin-top: 54px;
  .left{
    width: 134px;
    height: 193px;
    background: #F6F8FB;
    margin-right: 80px;
    .des{
      font-size: 18px;
      font-family: PingFang;
      font-weight: 500;
      color: #999999;
      &:nth-child(1){
        margin: 30px 0 20px;
      }
      &:nth-child(3){
        margin: 27px 0 20px;
      }
    }
    .num{
      font-size: 20px;
      font-family: PingFang;
      font-weight: 800;
      color: #FB160C;
      &:nth-child(4){
        padding-bottom: 22px;
      }
    }
  }
}
</style>
