
import { defineComponent, reactive, toRefs, ref } from 'vue'
import navSwitch from './component/nav-switch.vue'
import tabSwitch from './component/tab-switch.vue'
import navTitle from './component/nav-title.vue'
import allData from './component/all-data.vue'
import industryChart from './component/industry-chart.vue'
import typeChart from './component/type-chart.vue'
import { getBoardRanking, getTypeRanking, getMvRanking, getNewsRanking, getStocksRanking, getLeader } from '@/apis/information-acquisition'
export default defineComponent({
  components: {
    navSwitch,
    tabSwitch,
    navTitle,
    allData,
    industryChart,
    typeChart
  },
  setup() {
    const allDataRef = ref(null)
    const defineData = reactive({
      initialStatus: 1,
      industryTabList: [
        {
          id: 1,
          name: '申万一级',
          value: 'swl1'
        },
        {
          id: 2,
          name: '申万二级',
          value: 'swl2'
        }
      ],
      tradeItem: {},
      tradeNavItem: {value: 'swl1'},
      tradeList: {},
      industryChart: false,
      industryChartBoardName: '',
      beforeTime: {
        startDate: '',
        endDate: ''
      },
      typeList: {},
      marketList: {},
      allTabItem: {},
      allNavItem: {value: 'swl1'},
      allBeforeTime: {
        startDate: '',
        endDate: ''
      },
      tradeApiList: [],
      stockApiItem: { name: '' },
      stockApiList: [],
      leaderApiItem: { code: '' },
      leaderApiList: []
    })
    const tradeNav = (item: any) => {
      defineData.tradeNavItem = item;
      defineData.industryChartBoardName = '';
      if (defineData.industryChart) {
        defineData.industryChart = false
      }
      tradeJoggle();

    }
    const tradeJoggle = async () => {
      const params = {
        boardType: (defineData.tradeNavItem as any).value,
        parentBoardName: defineData.industryChartBoardName,
        timeLevel: (defineData.tradeItem as any).value,
        startDate: defineData.beforeTime.startDate,
        endDate: defineData.beforeTime.endDate,
      }
      const tradeData = await getBoardRanking(params)
      if ((tradeData as any).code === 200) {
        defineData.tradeList = (tradeData as any)
      }
    }
    const tradeTab = (item: any, time: any) => {
      defineData.tradeItem = item;
      if (item.type === 'custom') {
        defineData.beforeTime = time
      }
      tradeJoggle()
    }
    tradeTab({label: '1年', incrLabel: '1年', value: '1y'}, {})

    const industryChartClick = (item: any, style: any) => {
      defineData.industryChartBoardName = item
      defineData.industryChart = style
      defineData.tradeNavItem = { value: 'swl2' }
      tradeJoggle()
    }

    const typeTab = async (item: any, time: any) => {
      const params = {
        timeLevel: item.value,
        startDate: time.startDate,
        endDate: time.endDate
      }
      const typeData = await getTypeRanking(params)
      if ((typeData as any).code === 200) {
        defineData.typeList = (typeData as any)
      }
    }
    typeTab({label: '1年', incrLabel: '1年', value: '1y'}, {})
    const marketTab = async (item: any, time: any) => {
      const params = {
        timeLevel: item.value,
        startDate: time.startDate,
        endDate: time.endDate
      }
      const marketData = await getMvRanking(params)
      if ((marketData as any).code === 200) {
        defineData.marketList = (marketData as any)
      }
    }
    marketTab({label: '1年', incrLabel: '1年', value: '1y'}, {})


    const stockApi = async () => {
      const params = {
        boardType: (defineData.allNavItem as any).value,
        boardName: (defineData.stockApiItem as any).name,
        timeLevel: (defineData.allTabItem as any).value,
        startDate: defineData.allBeforeTime.startDate,
        endDate: defineData.allBeforeTime.endDate
      }
      const stockApiData = await getStocksRanking(params)
      if ((stockApiData as any).code === 200) {
        defineData.stockApiList = (stockApiData as any).data
        defineData.leaderApiItem.code = (stockApiData as any).data[0].code
        leaderApi()
      }

    }
    const leaderApi = async () => {
      const params = {
        stockCode: (defineData.leaderApiItem as any).code,
        timeLevel: (defineData.allTabItem as any).value,
        startDate: defineData.allBeforeTime.startDate,
        endDate: defineData.allBeforeTime.endDate
      }
      const leaderApiData = await getLeader(params)
      if ((leaderApiData as any).code === 200) {
        defineData.leaderApiList = (leaderApiData as any).data
      }
    }

    const tradeApi = async () => {
      const params = {
        boardType: (defineData.allNavItem as any).value,
        timeLevel: (defineData.allTabItem as any).value,
        startDate: defineData.allBeforeTime.startDate,
        endDate: defineData.allBeforeTime.endDate
      }
      const tradeApiData = await getNewsRanking(params)
      if ((tradeApiData as any).code === 200) {
        (tradeApiData as any).data.unshift({ name: '不限' })
        defineData.tradeApiList = (tradeApiData as any).data
        // defineData.stockApiItem.name = (tradeApiData as any).data[0].name
        defineData.stockApiItem.name = ''
        stockApi()
      }

    }

    const allChangeTab = (item: any) => {
      if(allDataRef.value) {
        (allDataRef.value as any).initialization()
      }
      defineData.allNavItem = item;
      (defineData.tradeApiList as any) = {};
      (defineData.stockApiList as any) = {};
      (defineData.leaderApiList as any) = {}
      tradeApi()
    }

    const allTab = (item: any, time: any) => {
      if(allDataRef.value) {
        (allDataRef.value as any).initialization()
      }
      defineData.allTabItem = item;
      if (item.type === 'custom') {
        defineData.allBeforeTime = time
      }
      (defineData.tradeApiList as any) = {};
      (defineData.stockApiList as any) = {};
      (defineData.leaderApiList as any) = {}
      tradeApi()
    }
    allTab({label: '1年', incrLabel: '1年', value: '1y'}, {})

    const tradeTopClick = (item: any) => {
      defineData.stockApiItem = item;
      stockApi()
    }
    const stockTopTag = (item: any) => {
      defineData.leaderApiItem = item;
      leaderApi()
    }

    return {
      ...toRefs(defineData),
      tradeNav,
      tradeTab,
      industryChartClick,
      typeTab,
      marketTab,
      allTab,
      allChangeTab,
      tradeTopClick,
      stockTopTag,
      allDataRef
    }
  }
})

