<template>
  <div class="wrap">
    <PageTitle title='融资融券' :sub-title="`> ${stockName}融资融券明细`" />
    <div class="bg">
      <NavTitle :title='`${stockName}行业融资融券明细`' :date="`截至${stockDate}`" style="padding-left:0" />
      <div class="describe">
        昨日融资余额<span class="black">{{bigNumberTransform(boardDetail.rzye)}}元</span>，
        较前一日{{boardDetail.rzyeChgRatio > 0 ? '增加' : '减少'}}<span v-dClass='boardDetail.rzyeChgRatio'>{{boardDetail.rzyeChgRatio}}%</span>，
        <span v-if="boardDetail.rzyeAddDays > 1">融资余额<span :class="boardDetail.rzyeIsAdd ? 'red' : 'green'">{{boardDetail.rzyeAddDays}}{{ boardDetail.rzyeIsAdd ? '连升' : '连降' }}</span>；</span>
        融券余额<span class="black">{{bigNumberTransform(boardDetail.rqye)}}元</span>，
        <span class="black">{{ boardDetail.rqye > boardDetail.rqyeM20 ? '大于' : '小于' }}20日均值</span>
      </div>
      <div class="chart-box">
        
        <ChartA id='ChartA' :chartData='chartData' :chartKey='chartKey' :showChartIdx='showChartIdx' />
        <div class="select-chart-list">
          <ul>
            <li :class="showChartClass === 0 ? 'active' : ''" @click="tabChart(0, 1, 'rzyeDaily')">
              <div class="icon circle"></div>
              <div class="text">融资余额(元)</div>
            </li>
            <li :class="showChartClass === 1 ? 'active' : ''" @click="tabChart(1, 2, 'rzjmrDaily')">
              <div class="icon line"></div>
              <div class="text">融资净买入(元)</div>
            </li>
            <li :class="showChartClass === 2 ? 'active' : ''" @click="tabChart(2, 1, 'rzyezbDaily')">
              <div class="icon circle"></div>
              <div class="text">融资余额占流通市值比(%)</div>
            </li>
          </ul>
          <ul>
            <li :class="showChartClass === 3 ? 'active' : ''" @click="tabChart(3, 1, 'rqyeDaily')">
              <div class="icon circle"></div>
              <div class="text">融券余额(元)</div>
            </li>
            <li :class="showChartClass === 4 ? 'active' : ''" @click="tabChart(4, 2, 'rqjmcDaily')"> 
              <div class="icon line"></div>
              <div class="text">融券净卖出额(元)</div>
            </li>
            <li :class="showChartClass === 5 ? 'active' : ''" @click="tabChart(5, 1, 'rzrqyeDaily')">
              <div class="icon circle"></div>
              <div class="text">融资融券余额(元)</div>
            </li>
          </ul>
        </div>
      </div>

      <div class="table-box">
        <div class="date-tab">
          <DateTab @change='getInitTime' />
        </div>
        <CTab :tab-list="tabList" :initial-type="initialStatus" @changeTab="changeTab" style="margin-left:0" />
        <TableA v-show="initialStatus === 1" ref='refTA'/>
        <TableB v-show="initialStatus === 2" ref='refTB'/>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, toRefs, ref, nextTick } from 'vue'
import NavTitle from '../components/nav-title.vue'
import PageTitle from '../components/page-title.vue'
import DateTab from '../../northbound-funds/components/CustomTab.vue'
import ChartA from './components/ChartA.vue'
import TableA from './components/TableA.vue'
import TableB from './components/TableB.vue'
import CTab from '../components/nav-switch.vue'
import { getMarginTrend, getMarginDetail } from '@/apis/trading'
import { bigNumberTransform } from '@/utils/data-format'
import { useRoute  } from "vue-router";
import { getStockTradeDate } from '@/apis/market'
import moment from 'moment/moment'
export default defineComponent({
  components:{
    NavTitle,
    PageTitle,
    DateTab,
    ChartA,
    TableA,
    TableB,
    CTab,
  },
  setup() {
    const route = useRoute();
    const refTA = ref(null)
    const refTB = ref(null)
    const dataMap = reactive({
      stockDate: '',
      stockName: route.query.code,
      initialStatus: 1,
      showChartIdx: 1,
      showChartClass: 0,
      tabList: [
        {
          id: 1,
          name: route.query.code + '行业持股明细'
        },
        {
          id: 2,
          name: route.query.code + '行业持股历史'
        }
      ],
      stockList: {
        list: []
      },
      chartData : {},
      chartKey : 'rzyeDaily',
      tableData : {},
      boardDetail: {}
    })

    const getTime = async () => {
      const data6 = await getStockTradeDate({ })
      dataMap.stockDate = moment((data6 as any).data.prevRzrqTradeDate).format('YYYY年MM月DD日');
    }
    getTime()

    const fetchData = async () => {
      
      // 图表
      const trendList = await getMarginTrend({ boardType: route.query.boardType, boardName: route.query.code })
      dataMap.chartData = (trendList as any).data;

      // 明细
      const boardDetail = await getMarginDetail({ boardType: route.query.boardType, boardName: route.query.code})
      dataMap.boardDetail = (boardDetail as any).data;
    }
    fetchData()

    const getInitTime = async (val:any) => {
      nextTick(()=>{
        // (refTA as any).value.fetchData({timeLevel: val.lbValue})
        (refTA as any).value.timeChange(val)
      })
    }
    // fetchData()
    
    
    const tabChart = (idx:any, type:any, key:any) => {
      dataMap.showChartClass = idx
      dataMap.showChartIdx = type
      dataMap.chartKey = key
    }

    const changeTab = (item: any) => {
      console.log(item, 'item')
      dataMap.initialStatus = item.id
    }

    return {
      ...toRefs(dataMap),
      tabChart,
      changeTab,
      getInitTime,
      refTA,
      refTB,
      bigNumberTransform
    }
  }
})
</script>
<style scoped lang="scss">
.wrap{
  margin: 0 35px;
  .bg{
    background: #fff;
    padding: 0 20px;
    .describe{
      color: #666666;
      margin: 20px 0 30px;
      .black{
        color: #333;
        font-weight: bold;
      }
      .red,.green{
        font-weight: bold;
      }
    }
    .chart-box{
      .select-chart-list{
        ul{
          width: 600px;
          margin: auto;
          margin-top: 28px;
          display: flex;
          li{
            flex: 1;
            margin-bottom: 9px;
            cursor: pointer;
            display: flex;
            align-items: center;
            &.active{
              .circle{
                &:before,&::after{
                  border:1px solid #3B7FEE;
                }
              }
              .line{
                background: #3B7FEE;
              }
            }
            .circle{
              width: 19px;
              position: relative;
              &:before{
                content: '';
                width: 9px;
                height: 9px;
                border-radius: 50%;
                background: #FFFFFF;
                border: 1px solid #CDCDCD;
                z-index: 1;
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                margin: auto;
              }
              &::after{
                content: '';
                width: 19px;
                height: 1px;
                background: #CDCDCD;
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                margin: auto;
              }
            }
            .line{
              width: 8px;
              height: 3px;
              background: #CDCDCD;
            }
            .text{
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              padding-left: 5px;
            }
          }
        }
      }
    }
  }
  .date-tab{
    text-align: right;
    margin-top: 50px;
  }
}
</style>
