
import pageTitle from '../components/PageTitle.vue'
import rankingChart from './component/industry-ranking.vue'
import industryTableRanking from './component/Industry-table-ranking.vue'
import individualStocksRaking from './component/individual-stocks-raking.vue'
import barTab from '../components/CustomTab.vue'
import Search from '@/components/search-input/Index.vue'
import { getIncreaseRanking } from '@/apis/northboundFunds'
import { getStockTradeDate } from '@/apis/market'
// import { useRouter } from 'vue-router'

import moment from 'moment'
import { defineComponent, toRefs, reactive, ref, nextTick } from 'vue'
export default defineComponent({
  components: {
    rankingChart,
    industryTableRanking,
    individualStocksRaking,
    pageTitle,
    barTab,
    Search
  },
  setup() {
    // const router = useRouter();
    const individualRaking = ref(null)
    const industryRanking = ref(null)
    const dataMap = reactive({
      titleTab: '',
      stockDate: '',
      radio: 1,
      chartIdx: 'swl1',
      chartName: '',
      styleStatus: false,
      aggregate: {
        boardRatioChange: {}, // 持股占板块比变化
        marketValueChange: {}, // 持股市值变化
        northboundFundsRationChange: {} // 持股占北向资金比变化
      },
      increaseList: {}

    })

    //获取交易日信息 //statisticDate


    const getTime = async () => {
      const data6 = await getStockTradeDate({ })
      dataMap.stockDate = moment((data6 as any).data.prevNbTradeDate).format('YYYY年MM月DD日');
    }
    getTime()

    const radioClick = (item: any) => {
      console.log(dataMap.aggregate)
      dataMap.radio = item;
      if (item === 1) {
        dataMap.increaseList = dataMap.aggregate.marketValueChange
        dataMap.titleTab = '亿'
      } else if (item === 2) {
        dataMap.increaseList = dataMap.aggregate.boardRatioChange
        dataMap.titleTab = ''
      } else if (item === 3) {
        dataMap.increaseList = dataMap.aggregate.northboundFundsRationChange
        dataMap.titleTab = ''
      }
    }
    const fetchData = async (item: any, tabItem: any, boardName: any) => {
      const rankingList = await getIncreaseRanking({ timeLevel: item, boardType: tabItem, boardName })
      if( rankingList ){
        nextTick(() => {
          // console.log('vvvvvvv',(rankingList as any).data)
          radioClick(dataMap.radio)
        })
        dataMap.aggregate = (rankingList as any).data
      }
    }
    const tabClick = (item: any) => {
      fetchData(item.lbValue, dataMap.chartIdx, dataMap.chartName)
      if( industryRanking.value ){
        (industryRanking as any).value.fetchData(item)
      }
    }


    const swichChartItem = (item: any, itemName: any, styleType: any) => {
      dataMap.chartName = ''
      dataMap.chartIdx = item
      if (styleType) {
        dataMap.styleStatus = false
      }
      fetchData('1d', item, itemName || dataMap.chartName)
      nextTick(() => {
        (industryRanking as any).value.fetchData({boardType: item})
      })
    }
    swichChartItem('swl1', '', false)
    const chartsClick = (itemName: any, styleStatus: any) => {
      dataMap.styleStatus = styleStatus
      dataMap.chartName = itemName;
      swichChartItem(dataMap.chartIdx, itemName, false)
    }

    const getGgData = (val: any) => {
      (individualRaking as any).value.timeChange(val)
    }
    const goShare = () => {
      window.open(`/investment/northboundFunds/share-page`)
    }

    return {
      individualRaking,
      industryRanking,
      ...toRefs(dataMap),
      radioClick,
      goShare,
      getGgData,
      tabClick,
      swichChartItem,
      chartsClick
    }

  }
})
