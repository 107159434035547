
import pagingComponent from '@/components/pagination/Index.vue'
import { defineComponent, reactive, toRefs } from 'vue'
import { getMargin } from '@/apis/margin-trading'
export default defineComponent({
  components: {
    pagingComponent
  },
  props:{
    incrLabel: {
      type: String,
      default: '1日'
    },
  },
  setup(props) {
    const dataMap = reactive({
      loading: true,
      marginTableData: {}
    })
    let params = {
      timeLevel: '',
      boardType: 'swl1',
      parentBoardName: '',
      pageNum: '1',
      pageSize: '10',
      sortField: 'rzjmr'
    }
    const fetchData = async (value:any, item: any) => {
      if( value.value ){
        params['timeLevel'] = value.value
      }
      if( value.pageNum ){
        params['pageNum'] = value.pageNum
      }
      if( value.pageSize ){
        params['pageSize'] = value.pageSize
      }
      if( item && item.boardType ){
        params['boardType'] = item.boardType
      }
      if( item && item.timeLevel ){
        params['timeLevel'] = item.timeLevel
      }
      if( params.boardType ){
        dataMap.loading = true
        const marginList = await getMargin(params)
        dataMap.marginTableData = (marginList as any).data;
        dataMap.loading = false
      }
    }
    const amount = (value: any, fixed = 2, unit = '元') => {
      if (unit == '万') {
        value = value * 10000
      } else if (unit == '亿') {
        value = value * 100000000
      }
      if (Math.abs(value / 100000000) >= 1) {
        return (value / 100000000).toFixed(fixed) + '亿'
      }
      if (Math.abs(value / 10_000) >= 1) {
        return (value / 10000).toFixed(fixed) + '万'
      }
      return value
    }
    const sortChange = (column: any) => {
      let isAsc = column.order === "ascending" ? 'asc' : 'desc';
      params['sortField'] = column["prop"]
      params['sort'] = isAsc
      fetchData({}, {})
    };
    const goDetail = (item: any, index: any) => {
      console.log(item, 'item', index.label, 'index')
      if (index.label === '名称') {
        const path = '/marginTrading/industry'
        window.open( `/investment${path}?code=${item.name}&boardType=${item.type}`)
      } else if (index.label === '融资净买入占流通市值最大比') {
        const path = '/marginTrading/shares'
        window.open( `/investment${path}?name=${item.rzjmrzbMaxName}&code=${item.rzjmrzbMaxCode}`)
      } else if (index.label === '融券净卖出占流通股最大比') {
        const path = '/marginTrading/shares'
        window.open( `/investment${path}?name=${item.rqjmczbMaxName}&code=${item.rqjmczbMaxCode}`)
      }
    };
    const addClass = (column:any) => {
      if( column.column.property === params.sortField ){
        return 'sort-bg'
      }
    }
    return {
      ...toRefs(dataMap),
      fetchData,
      amount,
      sortChange,
      goDetail,
      addClass
    }
  }
})
