
import Driver from 'driver.js'
import 'driver.js/dist/driver.min.css'
import steps from './steps'
import { defineComponent, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  setup() {
    const { t } = useI18n()
    let driver: Driver | null = null
    onMounted(() => {
      driver = new Driver()
    })

    const guide = () => {
      if (driver) {
        driver.defineSteps(steps)
        driver.start()
      }
    }

    return {
      t,
      guide
    }
  }
})

