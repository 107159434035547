

import { defineComponent, reactive, toRefs } from 'vue'
import Search from '@/components/search-input/Index.vue'
import CTitle from '@/components/title/Index.vue'
import { getJsonContent } from '@/apis/home'

export default defineComponent({
  components: {
    CTitle,
    Search,
  },
  setup() {
    // const route = useRoute()
    const dataMap = reactive({
      stockList: {},
      active: 0
    })

    const fetchData = async () => {
      // const data = await getStockReportDetail({ reportId: route.query.reportId })
      // if( data ){
      //   dataMap.stockList = (data as any).data;
      // }
      dataMap.stockList = JSON.parse(localStorage.getItem('notice') as any);
      const content:any = await getJsonContent(dataMap.stockList['content'], {})
      dataMap.stockList['text'] = content['text']
    }
    fetchData()

    const openPdf = () => {
      window.open(dataMap.stockList['pdfUrl']+'#page=1&toolbar=0&statusbar=0')
    }

    const handleTab = (idx:any) => {
      dataMap.active = idx
    }

    const openUrl = (item:any) => {
      window.open(dataMap.stockList['url'])
    }

    return {
      ...toRefs(dataMap),
      openPdf,
      handleTab,
      openUrl
    }
  }
})
