
import { defineComponent, reactive, toRefs } from 'vue';
import NewTitle from '@/components/new-title/Index.vue';
import { getSurveyRateCountList } from '@/apis/research';
import moment from 'moment';
import dicts from '@/utils/dicts';
import data from '../pdf/content';
  
interface Inter {
    order: string | undefined,
    orderField: string | undefined,
}
export default defineComponent({
    components: {
        NewTitle
    },
    setup() {
        const dataMap = reactive({
            dataList1: [],
            selectedTime1: 0,
            beginDate1: undefined as any,
            endDate1: undefined as any,
            loading: true,
            page: {
                totalSize: 0,
                pageSize: 30,
                pageNum: 1,
            },
            timeLevel: -1
        })
    
        let sortParams1:Inter = reactive({
            order: 'DESC',
            orderField: 'researchCount',
            timeLevel: '1w',
        })
        
        const changeHandle1 = async (params:any) => {
            dataMap.timeLevel = params.timeLevel;
            if(params.timeLevel !== 0) {
                dataMap.selectedTime1 = (dicts.timeSelection || []).findIndex((item:any) => item.value === params.timeLevel);
                dataMap.beginDate1 = undefined;
                dataMap.endDate1 = undefined;
            }else {
                dataMap.selectedTime1 = 5;
                dataMap.beginDate1 = params.dateFrom ? params.dateFrom : undefined;
                dataMap.endDate1 = params.dateTo ? params.dateTo : undefined;
            }
            dataMap.page.pageNum = 1;
            dataMap.page.pageSize = 30;
            getList();         
        }

        const getList = async () => {
            const timeLevelStr = (dicts.timeSelection || []).find((item:any) => item.value === dataMap.timeLevel);
            const newParams = {
                timeLevel: timeLevelStr?.timeLevel,
                startTime: dataMap.beginDate1 ? moment(dataMap.beginDate1).valueOf() : undefined,
                endTime: dataMap.endDate1 ? moment(dataMap.endDate1).valueOf() : undefined,
                pageNum: dataMap.page.pageNum,
                pageSize: dataMap.page.pageSize,
            }
            dataMap.loading = true;
            const data = await getSurveyRateCountList( {...sortParams1, ...newParams})
            dataMap.loading = false;
            dataMap.dataList1 = (data as any).data
        }

        const sortChange1 = (item:any) => {
            if( item.prop ){
                sortParams1 = {
                    order: item.order === "ascending" ? 'ASC' : 'DESC',
                    orderField: item.prop,
                }
                getList()
            }else{
                delete sortParams1.order
                delete sortParams1.orderField
                getList()
            }
        }

        const goResearchList = (row:any, column:any)=>{
            const { property } = column;
                // 行业
                if(!['industry'].includes(property)){
                    let url = `/investment/research/detail?code=${row.code}&timeLevel=${dataMap.selectedTime1}`
                // 重点
                if(['orgResearchCount', 'focusOrgResearchCount'].includes(property)){
                    if(dataMap.beginDate1) {
                    url = `${url}&isPointOrg=1&beginDate=${dataMap.beginDate1}&endDate=${dataMap.endDate1}`
                    }else {
                    url = `${url}&isPointOrg=1`
                    }
                }else {
                    if(dataMap.beginDate1) {
                    url = `${url}&isPointOrg=0&beginDate=${dataMap.beginDate1}&endDate=${dataMap.endDate1}`
                    }else {
                    url = `${url}&isPointOrg=0`
                    }
                }
                window.open(url);
            }else {
                if(dataMap.beginDate1) {
                    window.open(`/investment/research/list?isPointOrg=0&industryName=${row.industry}&timeLevel=${dataMap.selectedTime1}&beginDate=${dataMap.beginDate1}&endDate=${dataMap.endDate1}`)
                }else{
                    window.open(`/investment/research/list?isPointOrg=0&industryName=${row.industry}&timeLevel=${dataMap.selectedTime1}`)
                }
            }
        }

        // （切换每页多少条）
        const handleSizeChange = (val:any) => {
            dataMap.page.pageSize = val;
            dataMap.page.pageNum = 1;
            getList()
        }

        //（切换页码）
        const handleCurrentChange = (val:any) => {
            dataMap.page.pageNum = val;
            getList()
        }

        return {
            sortChange1,
            changeHandle1,
            goResearchList,
            handleSizeChange,
            handleCurrentChange,
            ...toRefs(dataMap)
        }
    }
})
