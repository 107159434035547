
import { defineComponent, reactive, toRefs } from "vue";
import CTitle from "@/components/title/Index.vue";
import LineChartD from "@/components/charts/LineChartD.vue";
import LineChartF from "@/components/charts/LineChartF.vue";
import LineChartL from "@/components/charts/LineChartL.vue";
import LineChartM from "@/components/charts/LineChartM.vue";
import LineChartO from '@/components/charts/LineChartO.vue'
import Search from "@/components/search-input/Index.vue";
import breadcrumb from '@/components/breadcrumb/Index.vue';
import CSelect from '@/components/pub-radio/Index.vue';
import { useRoute } from "vue-router";

import {
  getDiagnosticAssessment,
  getValuationDaily,
  getValuationDesc,
  getValuationQuantileDaily,
} from "@/apis/diagnosis";
import {
  getIndustryRecords,
  getStockBandDaily,
  getStockRating,
  getStockIndustryRecords,
  getStockPettmDaily
} from "@/apis/analysis";


export default defineComponent({
  components: {
    CTitle,
    LineChartD,
    LineChartF,
    LineChartL,
    LineChartM,
    CSelect,
    Search,
    breadcrumb,
    LineChartO,
  },
  setup() {
    // const stockCode = '600519.SH'
    const route = useRoute();
    const stockCode = route.query.code;
    const dataMap = reactive({
      defultValue: route.query.timeLevel || '3Y',
      ajaxRes: false,
      stockInfo: {},
      activeAnalysisIdx: 0,
      activeZbIdx: 0,
      stockList1: [],
      stockList2: {},
      stockList3: {},
      stockList4: [],
      stockList5: {},
      stockList6: [],
      stockList7: [],
      stockList8: [],
      stockList5ChartData: {},
      assessmentInfo: {},
      pepdTime: [],
      profitTypearr: ["市盈率", "市净率", "市销率", "市现率"],
      rate: ["perate", "pbrate", "psTtmrate", "pcfOcfTtmrate"],
      rate1: ["board2Roe", "board2Roic", "board2Roa"],
      rate2: ["roe", "roic", "roa"],
      activeRate: 0,
      yearKey: [],
      dialogVisible1: false,
      dialogVisible2: false,
    });

    const swichAnalysisItem = (idx: any) => {
      dataMap.activeAnalysisIdx = idx;
      // console.log(dataMap.stockList5)
      if (idx && dataMap.stockList5) {
        if (idx === 1) {
          dataMap.stockList5ChartData = {
            max: dataMap.stockList5["peMax"],
            mid: dataMap.stockList5["peMid"],
            min: dataMap.stockList5["peMin"],
            data: dataMap.stockList5["peRecords"],
          };
        } else if (idx === 2) {
          dataMap.stockList5ChartData = {
            max: dataMap.stockList5["pbMax"],
            mid: dataMap.stockList5["pbMid"],
            min: dataMap.stockList5["pbMin"],
            data: dataMap.stockList5["pbRecords"],
          };
        } else if (idx === 3) {
          dataMap.stockList5ChartData = {
            max: dataMap.stockList5["psMax"],
            mid: dataMap.stockList5["psMid"],
            min: dataMap.stockList5["psMin"],
            data: dataMap.stockList5["psRecords"],
          };
        } else if (idx === 4) {
          dataMap.stockList5ChartData = {
            max: dataMap.stockList5["pcfMax"],
            mid: dataMap.stockList5["pcfMid"],
            min: dataMap.stockList5["pcfMin"],
            data: dataMap.stockList5["pcfRecords"],
          };
        }
      }
    };

    const swichZbItem = (idx: any) => {
      dataMap.activeZbIdx = idx;
    };

    const gettext = (obj: any, text: any) => {
      if (!obj) {
        return {};
      }
      obj[text + "text"] = obj[text].split("|")[0];
      obj[text + "color"] = obj[text].split("|")[1];
      return obj;
    };

    const changeHandle = async (res: any) => {
      if(!res.timeLevel) return;
      const data3 = await getValuationDesc({ stockCode: stockCode, timeLevel: res.timeLevel });
      dataMap.stockList3 = (data3 as any).data;

      let data4 = await getValuationDaily({
        stockCode: stockCode,
        timeLevel: res.timeLevel,
      });
      dataMap.stockList4 = (data4 as any).data;

      let data5 = await getValuationQuantileDaily({
        stockCode: stockCode,
        timeLevel: res.timeLevel,
      });
      dataMap.stockList5 = (data5 as any).data;
      swichAnalysisItem(dataMap.activeAnalysisIdx);
    };

    const tabRate = (res: any) => {
      dataMap.activeRate = res;
    };

    const changePePdHandle = async (res: any) => {
      if(res.timeLevel){
        let data7 = await getStockBandDaily({
          stockCode: stockCode,
          timeLevel: res.timeLevel,
        });
        dataMap.stockList2 = (data7 as any).data;
      }
    };

    const getpepdTime = (res: any) => {
      dataMap.pepdTime = res;
    };

    const getTableData2 = async (sortKey: any, isAsc: any) => {
      if (sortKey !== undefined) {
        tableSort2.sortKey = sortKey;
      }
      if (isAsc !== undefined) {
        tableSort2.isAsc = isAsc;
      }
      let data6 = await getIndustryRecords(tableSort2);
      dataMap.stockList1 = (data6 as any).data;
    };

    const fetchData = async () => {
      const data1 = await getDiagnosticAssessment({ stockCode: stockCode });
      let assessmentInfo = (data1 as any).data;
      if (assessmentInfo) {
        gettext(assessmentInfo, "cashFlowScore");
        gettext(assessmentInfo, "growUpScore");
        gettext(assessmentInfo, "operationScore");
        gettext(assessmentInfo, "profitabilityScore");
        gettext(assessmentInfo, "ranking");
        gettext(assessmentInfo, "valueScore");
        gettext(assessmentInfo, "valuationScore");
        dataMap.assessmentInfo = assessmentInfo;
      }

      // const data3 = await getValuationDesc({ stockCode: stockCode });
      // dataMap.stockList3 = (data3 as any).data;

      getTableData2(null, null);

      let data8 = await getStockRating({ stockCode: stockCode });
      dataMap.stockList6 = (data8 as any).data;

      getTableData(null, null);

      dataMap.ajaxRes = true;
    };
    fetchData();

    let tableSort = {
      stockCode: stockCode,
      // timeLevel: '',
      sortKey: "",
      isAsc: "",
    };
    const getTableData = async (sortKey: any, isAsc: any) => {
      // if(val){tableSort.timeLevel = val.timeLevel}
      if (sortKey !== undefined) {
        tableSort.sortKey = sortKey;
      }
      if (isAsc !== undefined) {
        tableSort.isAsc = isAsc;
      }
      let data9 = await getStockIndustryRecords(tableSort);
      dataMap.stockList7 = (data9 as any).data;
      if ((data9 as any).data[0]) {
        dataMap.yearKey = (data9 as any).data[0]["roa-date"];
      }
    };
    const sortChange = (column: any) => {
      let isAsc = column.order === "ascending" ? true : false;
      // changeHandle({timeLevel: timeLevel}, column['prop'], isAsc, idx)
      getTableData(column["prop"], isAsc);
    };

    let tableSort2 = {
      stockCode: stockCode,
      // timeLevel: '',
      sortKey: "",
      isAsc: "",
    };

    const sortChange2 = (column: any) => {
      let isAsc = column.order === "ascending" ? true : false;
      // changeHandle({timeLevel: timeLevel}, column['prop'], isAsc, idx)
      getTableData2(column["prop"], isAsc);
    };

    const getStockInfo = (info:any) => {
      dataMap.stockInfo = info
    }

    const changePettmHandle = async (res: any) => {
      if(res.timeLevel){
        let pettmData = await getStockPettmDaily({ stockCode: stockCode, timeLevel: res.timeLevel });
        dataMap.stockList8 = (pettmData as any).data;
      }
    }

    return {
      ...toRefs(dataMap),
      swichAnalysisItem,
      swichZbItem,
      changeHandle,
      changePePdHandle,
      getpepdTime,
      tabRate,
      sortChange,
      sortChange2,
      getStockInfo,
      changePettmHandle,
    };
  },
});
