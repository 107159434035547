<!--
 * @Description: 首页
-->
<template>
  <div class="app-container" style="margin-top:0">

    <!-- 搜索框 :path='"/diagnosis/index"' -->
    <Search :path='"/home/detail"' isStockDetail="true" rightText="详情" style="margin:22px auto 34px" />

    <!-- 导航 -->
    <div class="nav-list">
      <div>
        <img src="@/assets/images/nav-list-1.png" @click="navPush(0)">
        <p>机构评级</p>
      </div>
      <div>
        <img src="@/assets/images/nav-list-2.png" @click="navPush(1)">
        <p>机构调研</p>
      </div>
      <div>
        <img src="@/assets/images/nav-list-3.png" @click="navPush(2)">
        <p>估值分析</p>
      </div>
    </div>

    <!-- 热门股票 -->
    <NewsFlash />



    <!-- 热门股票 -->
    <Hot />

    <!--
    <div class="table-list">
      <div class="list">
        <div class="tit" v-if="stockList1">
          中证全指 一级行业指数涨跌榜@
          <template v-if="stockList1 && stockList1.length">{{$moment(stockList1[0].date).format('YYYY年MM月DD日')}}</template>
        </div>

        <el-table
          class="table-style-1"
          :data="stockList1"
          border
          align="center"
          style="width: 700px"
        >
          <el-table-column
            label="序号"
            width="50"
            type='index'
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="code"
            label="代码"
            width="108"
            align="center"
          />
          <el-table-column
            prop="name"
            label="名称"
            width="90"
            align="center"
          />
          <el-table-column
            prop="todayChange"
            label="今日涨跌%"
            width="90"
            align="center"
          />
          <el-table-column
            prop="yesterdayChange"
            label="昨日涨跌%"
            width="90"
            align="center"
          />
          <el-table-column
            prop="firstDate"
            label="首涨日期"
            width="90"
            align="center"
          >
            <template #default='{row}'>
              {{row.firstDate || '- -'}}
            </template>
          </el-table-column>
          <el-table-column
            label="连涨天数"
            width="90"
            align="center"
          >
            <template #default="scope">
              <span v-dClass='scope.row.streakDay'>{{ scope.row.streakDay > 0 ? '+' : '' }}{{ scope.row.streakDay }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="streakChange"
            label="连涨幅度%"
            width="90"
            align="center"
          >
            <template #default="scope">
              <span v-dClass='scope.row.streakChange'>{{ scope.row.streakChange }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="list">
        <div class="tit">
          个股连涨榜单@
          <template v-if="stockList2 && stockList2.length">{{$moment(stockList2[0].date).format('YYYY年MM月DD日')}}</template>
        </div>
        <el-table
          class="table-style-1"
          :data="stockList2"
          border
          align="center"
          style="width: 808px"
        >
          <el-table-column
            label="序号"
            width="50"
            type='index'
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="code"
            label="代码"
            width="108"
            align="center"
          />
          <el-table-column
            prop="name"
            label="名称"
            width="90"
            align="center"
          />
          <el-table-column
            prop="todayChange"
            label="今日涨跌%"
            width="90"
            align="center"
          />
          <el-table-column
            prop="yesterdayChange"
            label="昨日涨跌%"
            width="90"
            align="center"
          />
          <el-table-column
            prop="firstDate"
            label="首涨日期"
            width="90"
            align="center"
          />
          <el-table-column
            prop="streakDay"
            label="连涨天数"
            width="90"
            align="center"
          >
            <template #default="scope">
              <span v-dClass='scope.row.streakDay'>{{ scope.row.streakDay > 0 ? '+' : '' }}{{ scope.row.streakDay }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="streakChange"
            label="连涨幅度%"
            width="90"
            align="center"
          >
            <template #default="scope">
              <span v-dClass='scope.row.streakChange'>{{ scope.row.streakChange }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="zxl1"
            label="中信一级行业"
            width="110"
            align="center"
          >
            <template #default='{row}'>
              {{row.zxl1 || '未知/待定'}}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="list">
        <div class="tit">
          个股上涨榜单@
          <template v-if="stockList3 && stockList3.length">{{$moment(stockList3[0].date).format('YYYY年MM月DD日')}}</template>
        </div>
        <el-table
          class="table-style-1"
          :data="stockList3"
          border
          align="center"
          style="width: 808px"
        >
          <el-table-column
            label="序号"
            width="50"
            type='index'
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="code"
            label="代码"
            width="108"
            align="center"
          />
          <el-table-column
            prop="name"
            label="名称"
            width="90"
            align="center"
          />
          <el-table-column
            prop="todayChange"
            label="今日涨跌%"
            width="90"
            align="center"
          />
          <el-table-column
            prop="yesterdayChange"
            label="昨日涨跌%"
            width="90"
            align="center"
          />
          <el-table-column
            prop="firstDate"
            label="首涨日期"
            width="90"
            align="center"
          />
          <el-table-column
            prop="streakDay"
            label="连涨天数"
            width="90"
            align="center"
          >
            <template #default="scope">
              <span v-dClass='scope.row.streakDay'>{{ scope.row.streakDay > 0 ? '+' : '' }}{{ scope.row.streakDay }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="streakChange"
            label="连涨幅度%"
            width="90"
            align="center"
          >
            <template #default="scope">
              <span v-dClass='scope.row.streakChange'>{{ scope.row.streakChange }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="zxl1"
            label="中信一级行业"
            width="110"
            align="center"
          >
            <template #default='{row}'>
              {{row.zxl1 || '未知/待定'}}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div> -->

    <!-- 机构评级 -->
    <div class="agency-rating">
      <AgencyRating />
      <!-- <RecommendList style="padding-top:58px" /> -->
    </div>

    <div class="institutional-research">
      <div class="t-title">
        <CTitle
          :mode="1"
          title="机构调研"
          style="margin-bottom:25px"
        />
      </div>
      <div class="institutional-research-main">
        <SurveyFrequency />
      </div>
      <!-- <SurveyRankingtList></SurveyRankingtList>

      <SurveyCountRecord></SurveyCountRecord> -->
    </div>
    <div class="valuation-analysis">
      <CTitle
        :mode="1"
        title="估值分析"
        style="margin-bottom:25px"
      />
      <Analysis />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue'
import CTitle from '@/components/title/Index.vue'
import AnalysisTable from '@/views/analysis/components/Table.vue'
import RecommendList from '@/views/agency/components/RecommendList.vue'
import AgencyRating from '@/views/agency/components/AgencyRating.vue'
import SurveyCountRecord from '@/views/research/components/SurveyCountRecord.vue'
import SurveyRankingtList from '@/views/research/components/SurveyRankingtList.vue'
import Search from '@/components/search-input/Index.vue'
import NewsFlash from './components/NewsFlash.vue'
import Hot from './components/Hot.vue'
import Analysis from './components/Analysis.vue'
import SurveyFrequency from '@/views/research/components/SurveyFrequency.vue'

import { useRouter } from 'vue-router'
// import { getIndexRisingList, getIndividualStocksRose } from '@/apis/home'

export default defineComponent({
  components: {
    CTitle,
    RecommendList,
    AnalysisTable,
    AgencyRating,
    SurveyCountRecord,
    SurveyRankingtList,
    Search,
    NewsFlash,
    Hot,
    Analysis,
    SurveyFrequency
  },
  setup() {
    const router = useRouter()
    // const keyTimeMap = ['1m','3m','6m','1y'];
    const dataMap = reactive({
      ajaxRes:false,
      // stockList1:[],
      // stockList2:[],
      // stockList3:[],
      // stockList4:{},
      // stockList5:[],
      // stockList6:[],
      // stockList7:[],
      // stockList6:[],
      // stockList7:[],
    })

    const fetchData = async () => {
      // const data = await getIndexRisingList({  })
      // const data1 = await getIndividualStocksRose({ sortKey: 'streakDay' })
      // const data2 = await getIndividualStocksRose({ sortKey: 'streakChange' })
      // const data3 = await getSurveyRankingtList({  })
      // const data4 = await getSurveyRecordsList({  })

      // const data3 = await getAgencyRatingList({  })

      // dataMap.stockList1 = (data as any).data;
      // dataMap.stockList2 = (data1 as any).data;
      // dataMap.stockList3 = (data2 as any).data;
      // dataMap.stockList4 = (data3 as any).data;
      // dataMap.stockList5 = (data4 as any).data;

      dataMap.ajaxRes = true;
      // console.log(data3)
    }
    fetchData();


    // const changeHandle = async (val:any) => {
    //   const data3 = await getSurveyRankingtList({ timeLevel: val.timeLevel })
    //   dataMap.stockList4 = (data3 as any).data;
    // }



    const navPush = (num:number) => {
      let list = ['/agency', '/research', '/analysis']
      // router.push({ path: list[num] }).catch(err => {
      //   console.warn(err)
      // })
      window['$props'].push({
        path: `/investment${list[num]}/index`,

      })
    }

    return {
      ...toRefs(dataMap),
      // changeHandle,
      navPush
      // goDetail
    }
  }
})
</script>
<style lang="scss" scoped>
.nav-list {
  text-align: center;
  //margin-bottom: 21px;
  margin-top: -15px;
  display: flex;
  justify-content: center;
  div{
    position: relative;
    img {
      width: 166px;
      margin: 0 14px;
      cursor: pointer;
    }
    p {
      font-size: 16px;
      color: #666;
      padding-left: 3px;
      letter-spacing: 3px;
      font-weight: 600;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 42px;
    }
  }

}
.table-list{
  display: flex;
  overflow-x: auto;
  background: #fff;
  padding-bottom: 20px;
  .list{
    margin: 0 32px;
    .tit{
      text-align: center;
      margin: 42px 0 24px;
      font-size: 20px;
      font-family: PingFang;
      font-weight: bold;
      color: #000000;
    }
  }
  .red{
    width: 57px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    background: #EA5651;
    font-size: 14px;
    font-family: PingFang;
    font-weight: 500;
    color: #FFFFFF;
    display: inline-block;
  }
  .green{
    width: 57px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    background: #5CBC7C;
    font-size: 14px;
    font-family: PingFang;
    font-weight: 500;
    color: #FFFFFF;
    display: inline-block;
  }
}
.agency-rating{
  background: #fff;
  margin-top: 30px;
  padding: 34px 32px 52px;
  overflow: hidden;
  .t-title{
    width: 950px;
  }
  .list{
    width: 266px;
    float: left;
    margin-right: 79px;
    // height: 371px;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    border-radius: 6px;
    .header{
      border-bottom: 1px solid #EEEEEE;
      padding: 11px 0 8px;
      position: relative;
      .btn{
        width: 120px;
        height: 36px;
        color: #010101;
        line-height: 36px;
        text-align: center;
        background: #E4EBF9;
        margin: 0 auto;
      }
      img{
        position: absolute;
        right: 0;
        top: 0;
      }
    }
    .content{
      ul{
        margin: 22px 0 33px;
        li{
          padding: 0 13px;
          margin-bottom: 18px;
          display: flex;
          div{
            font-size: 18px;
            font-family: PingFang;
            font-weight: bold;
            &:nth-child(1){
              color: #878787;
              padding-right: 10px;
            }
            &:nth-child(2){
              color: #000;
              margin-right: 40px;
            }
            &:nth-child(3){
              color: #EA5651;
            }
          }
        }
      }
    }
    .footer{
      border-top: 1px solid #EEEEEE;
      padding: 24px 35px 27px;
      text-align: center;
      font-size: 16px;
      font-family: PingFang;
      font-weight: 500;
      color: #000000;
      line-height: 28px;
      .tips{
        color: #EA5651;
      }
    }
  }
}
.institutional-research{
  background: #fff;
  margin-top: 30px;
  padding: 31px 33px 55px;
}
.valuation-analysis{
  padding: 33px 33px 55px;
  background: #fff;
  margin-top: 30px;
  // .content{
  //   display: flex;
  //   &>div{
  //     flex: 1;
  //     width: 50%;
  //     &:first-child{
  //       padding-right: 50px;
  //     }
  //     &:last-child{
  //       padding-left: 50px;
  //     }
  //   }
  // }
}
::v-deep .agency-rating-container{
  margin-top: 0;
}
::v-deep .mode{
  margin-bottom: 43px;
}
</style>
