/*
 * @Description: 首页相关接口
 */
import { RootObject } from '@/model/rootObject'
import { UserInfoModel, Users } from '@/model/userModel'
import https from '@/utils/https'
import { LoginModel } from '@/views/user-manager/login/model/loginModel'
import { RequestParams, ContentType, Method } from 'axios-mapper'

//指数上涨/连涨榜单
export const getIndexRisingList = (params: RequestParams) => {
  // return https(false).request('/ranking/stock/index/change', Method.POST, params, ContentType.json)
  return https().request('/ranking/stock/index/change', Method.GET, params, ContentType.json)
}
//个股上涨/连涨榜单
export const getIndividualStocksRose = (params: RequestParams) => {
  // return https(false).request('/ranking/stock/index/change', Method.POST, params, ContentType.json)
  return https().request('/ranking/stock/change', Method.GET, params, ContentType.form)
}
//聚合个股机构评级排行
export const getAgencyRatingList = (params: RequestParams) => {
  return https().request('/report/stock/ranking/agg', Method.GET, params, ContentType.form)
}
//获取分析师推荐个股评级排行
export const getAnalystRecommendList = (params: RequestParams) => {
  return https().request('/report/stock/report/ranking/agg', Method.GET, params, ContentType.form)
}
//获取分析师推荐个股评级排行
export const getReporCounRankingtList = (params: RequestParams) => {
  return https().request('/report/count/ranking', Method.GET, params, ContentType.form)
}
//获取行业调研排行
export const getSurveyRankingtList = (params: RequestParams) => {
  return https().request('/survey/catalog/count/ranking', Method.GET, params, ContentType.form)
}
//获取股票机构调研排名记录
export const getSurveyRecordsList = (params: RequestParams) => {
  return https().request('/survey/count/records', Method.GET, params, ContentType.form)
}
//个股估值排名聚合数据
export const getStockRankingList = (params: RequestParams) => {
  return https().request('/valuation/stock/ranking/agg', Method.GET, params, ContentType.form)
}
//行业估值排名聚合数据
export const getIndustryRankingList = (params: RequestParams) => {
  return https().request('/valuation/industry/ranking/agg', Method.GET, params, ContentType.form)
}
//检索股票
export const getStockQuery = (params: RequestParams) => {
  return https().request('/stock/query', Method.GET, params, ContentType.form)
}

//个股估值排名
export const getValuationStockQuery = (params: RequestParams) => {
  return https().request('/valuation/stock/ranking', Method.GET, params, ContentType.form)
}
//行业估值排名
export const getValuationIndustryRanking = (params: RequestParams) => {
  return https().request('/valuation/industry/ranking', Method.GET, params, ContentType.form)
}


//7*24
export const getNewsNewsList = (params: RequestParams) => {
  return https().request('/news/newsList', Method.GET, params, ContentType.form)
}
//热门股票 => 突破新高
export const getAggStockChangeData = (params: RequestParams) => {
  return https().request('/agg/board/aggStockChangeData', Method.GET, params, ContentType.form)
}
//热门股票 => 行业涨幅榜 => 行业跌幅榜
export const getAggBoardChangeData = (params: RequestParams) => {
  return https().request('/agg/board/aggBoardChangeData', Method.GET, params, ContentType.form)
}



export const userInfoRequest = () => {
  return https().request<RootObject<UserInfoModel>>('user/userInfo', Method.GET, undefined, ContentType.form)
}

export const getUsers = (user: any) => {
  return https().request<RootObject<Users>>('user/getUsers', Method.GET, user, ContentType.form)
}

// 个股估值排名3.0
export const getValuationStockQueryV2 = (params: RequestParams) => {
  return https().request('/valuation/stock/ranking/v2', Method.GET, params, ContentType.form)
}

// 行业估值排名3.0
export const getValuationIndustryRankingV2 = (params: RequestParams) => {
  return https().request('/valuation/industry/ranking/v2', Method.GET, params, ContentType.form)
}

// 研究机构列表
export const getReportOrganizationList = (params: RequestParams) => {
  return https().request('/report/organizationList', Method.GET, params, ContentType.form)
}

// 调研机构查询
export const getSurveyOrg = (params: RequestParams) => {
  return https().request('/survey/org', Method.GET, params, ContentType.form)
}


//////////////////////////////////////////个股详情
// 基本信息
export const getBaseDetail = (params: RequestParams) => {
  return https().request('/stock/baseDetail', Method.GET, params, ContentType.form)
}
// 近期大事件
export const getCompanyEvents = (params: RequestParams) => {
  return https().request('/stock/company/events', Method.GET, params, ContentType.form)
}
// 财务数据
export const getLeastList = (params: RequestParams) => {
  return https().request('/agg/financeReport/leastList', Method.GET, params, ContentType.form)
}
// 常用指标
export const getStockIndicators = (params: RequestParams) => {
  return https().request('/stock/indicators', Method.GET, params, ContentType.form)
}
// 资讯-新闻列表
export const getNewsList = (params: RequestParams) => {
  return https().request('/news/getNewsList', Method.GET, params, ContentType.form)
}
// 资讯-公告
export const getAnnouncementList = (params: RequestParams) => {
  return https().request('/news/getAnnouncementList', Method.GET, params, ContentType.form)
}
// 资讯-研报-图表
export const getReportOrgCountRanking = (params: RequestParams) => {
  return https().request('/report/org/count/ranking', Method.GET, params, ContentType.form)
}
// 资讯-研报-列表
export const getStockDetailRating = (params: RequestParams) => {
  return https().request('report/stock/getStockDetailRating', Method.GET, params, ContentType.form)
}
// 获取oss json内容
export const getJsonContent = (url:any, params: RequestParams) => {
  return https().request(url, Method.GET, params, ContentType.form)
}



