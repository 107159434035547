
import { useRoute } from "vue-router";
import trendCharts from './component/trend-charts.vue'
import plateCharts from './component/plate-charts.vue'
import pageTitle from '../components/PageTitle.vue'
import individualStocksRaking from '../detailed/component/individual-stocks-raking.vue'
import barTab from '../components/CustomTab.vue'
import { defineComponent, toRefs, reactive, ref } from 'vue'
import { getTrend, getStats, getFundsBoard } from '@/apis/northboundFunds'
import moment from 'moment/moment'
export default defineComponent({
  components: {
    barTab,
    trendCharts,
    pageTitle,
    plateCharts,
    individualStocksRaking
  },
  setup() {
    const individualRaking = ref(null)
    const route = useRoute();
    const dataMap = reactive({
      codeTitle: route.query.code,
      trendData: {},
      statsData: {},
      boardData: {
        todayBuy: 0.00,
        day5Buy: 0.00,
        day20Buy: 0.00,
        todayHoldMarket: 0.00,
        day5AddRatio: 0.00,
        day20AddRatio: 0.00,
        intro: {
          addDays: 0.00,
          holdStockNum: 0.00,
          holdMarketCap: 0.00,
          rankingNo: 0.00,
          holdBoardNum: 0.00,
          holdRatio: 0.00
        }
      },
      stockDate: ''
    })
    const fetchData = async () => {
      // 持有板块趋势
      const trendList = await getTrend({ boardName: route.query.code, boardType: route.query.boardType })
      dataMap.trendData = (trendList as any).data;
      // 持有板块趋势
      const statsList = await getStats({ boardName: route.query.code, boardType: route.query.boardType })
      dataMap.statsData = (statsList as any).data;
      // 板块排行
      const boardList = await getFundsBoard({ boardName: route.query.code, boardType: route.query.boardType })
      dataMap.boardData = (boardList as any).data;
      dataMap.stockDate = moment((boardList as any).data.statsTime).format('YYYY年MM月DD日');
    }
    fetchData();
    const styleMathe = (item: any) => {
      if( item ){
        const itemNumber = item
        return itemNumber < 0 ?  'color: #5CBC7C' : itemNumber > 0 ?  'color: #F74E4F' : 'color: #606266'
      }
    }
    const mathAbs = (item: any) => {
      const number = Number((item));
      const numberText = Number(item);
      return number > 10000  ? numberText > 0 ? '+' + (numberText / 10000).toFixed(2) : numberText.toFixed(2) : (numberText / 10000).toFixed(2)
    }
    const mathAbse = (item: any) => {
      const number = Number(Math.abs(item));
      const numberText = Number(item);
      return number > 10000  ? (numberText / 10000).toFixed(2) : numberText.toFixed(2)
    }
    const mathAbss = (item: any) => {
      const numberText = Number(item);
      return numberText > 0  ? '+' + numberText.toFixed(2) : numberText.toFixed(2)
    }
    const mathAbsText = (item: any) => {
      const number = Number(Math.abs(item));
      return number > 10000  ? '亿' : '万'
    }

    const getGgData = (val: any) => {
      (individualRaking as any).value.timeChange(val)
    }
    return {
      ...toRefs(dataMap),
      styleMathe,
      mathAbs,
      mathAbsText,
      mathAbse,
      mathAbss,
      getGgData,
      individualRaking
    }
  }
})
