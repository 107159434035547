<template>
  <div class="survey-mechanism-warp">
    <div class="bg">
      <el-row>
        <el-col :span="24">
          <NewTitle
            title="调研机构统计列表"
            @change="changeHandle2"
          />
          <el-table
            v-loading="loading"
            class="table-style-2 cursor"
            :data="dataList2.list"
            border
            style="width: 100%"
            @sort-change="sortChange2"
            @row-click="goResearchMechanism"
            :default-sort ="{prop:'researchCount',order:'descending'}"
          >
            <el-table-column
              prop="orgName"
              align="center"
              label="机构名称"
            />
            <el-table-column
              prop="orgType"
              align="center"
              label="类型"
            />
            <el-table-column
              prop="researchCount"
              align="center"
              label="调研总次数"
              sortable="custom"
            />
            <el-table-column
              prop="stockCount"
              align="center"
              label="调研个股总数"
              sortable="custom"
            />
            <el-table-column
              prop="shMainBoard"
              align="center"
              label="上证主板(家)"
              sortable="custom"
            />
            <el-table-column
              prop="szMainBoard"
              align="center"
              label="深证主板(家)"
              sortable="custom"
            />
            <el-table-column
              prop="szSTIBoard"
              align="center"
              label="创业板(家)"
              sortable="custom"
            />
            <el-table-column
              prop="shSTIBoard"
              align="center"
              label="科创板(家)"
              sortable="custom"
            />
            <el-table-column
              prop="follows1"
              align="center"
              label="关注度NO.1"
            >
              <template #default='{row}'>
                <div>
                  <span>{{row.follows[0] ? fmtFollowsEmptyData(row.follows[0], 0) : '-'}}</span> <br>
                  <span>{{row.follows[0] ? fmtFollowsEmptyData(row.follows[0], 1) : '-'}}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="follows2"
              align="center"
              label="关注度NO.2"
            >
              <template #default='{row}'>
                <div>
                  <span>{{row.follows[1] ? fmtFollowsEmptyData(row.follows[1], 0) : '-'}}</span> <br>
                  <span>{{row.follows[1] ? fmtFollowsEmptyData(row.follows[1], 1) : '-'}}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="follows3"
              align="center"
              label="关注度NO.3"
            >
              <template #default='{row}'>
                <div>
                  <span>{{row.follows[2] ? fmtFollowsEmptyData(row.follows[2], 0) : '-'}}</span> <br>
                  <span>{{row.follows[2] ? fmtFollowsEmptyData(row.follows[2], 1) : '-'}}</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination">
              <el-pagination
                  background
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="page.pageNum"
                  :page-sizes="[30, 50, 100]"
                  :page-size="page.pageSize"
                  layout="sizes, prev, pager, next, jumper"
                  :total="dataList2.totalSize">
              </el-pagination>
          </div>   
        </el-col>
      </el-row>
    </div>  
  </div>
</template>
    
<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue'
import NewTitle from '@/components/new-title/Index.vue'
import { getSurveyOrgCountList } from '@/apis/research'
import moment from 'moment'
import dicts from '@/utils/dicts'
  
  interface Inter {
    order: string | undefined,
    orderField: string | undefined,
  }
  export default defineComponent({
    components: {
      NewTitle,
    },
    setup() {
      const dataMap = reactive({
        dataList2: [],
        selectedTime2: 0 as any,
        beginDate2: undefined as any,
        endDate2: undefined as any,
        loading: true,
        page: {
            totalSize: 0,
            pageSize: 30,
            pageNum: 1,
        },
        timeLevel: -1
      })

      let sortParams2:Inter = reactive({
        order: 'DESC',
        orderField: 'researchCount',
        timeLevel: '1w'
      })
      
  
      const changeHandle2 = async (params:any) => {
        dataMap.timeLevel = params.timeLevel;
        if(params.timeLevel !== 0) {
          dataMap.selectedTime2 = (dicts.timeSelection || []).findIndex((item:any) => item.value === params.timeLevel);
          dataMap.beginDate2 = undefined;
          dataMap.endDate2 = undefined;
        }else {
          dataMap.selectedTime2 = 5;
          dataMap.beginDate2 = params.dateFrom ? params.dateFrom : undefined;
          dataMap.endDate2 = params.dateTo ? params.dateTo : undefined;
        }
        dataMap.page.pageNum = 1;
        dataMap.page.pageSize = 30;
        getList();
      }

      const getList = async () => {
          const timeLevelStr = (dicts.timeSelection || []).find((item:any) => item.value === dataMap.timeLevel);
          const newParams = {
              timeLevel: timeLevelStr?.timeLevel,
              startTime: dataMap.beginDate2 ? moment(dataMap.beginDate2).valueOf() : undefined,
              endTime: dataMap.endDate2 ? moment(dataMap.endDate2).valueOf() : undefined,
              pageNum: dataMap.page.pageNum,
              pageSize: dataMap.page.pageSize,
          }
          dataMap.loading = true;
          const data = await getSurveyOrgCountList( {...sortParams2, ...newParams})
          dataMap.loading = false;
          dataMap.dataList2 = (data as any).data
      }
      
      
      const sortChange2 = (item:any) => {
        const timeLevel = dataMap.selectedTime2 !== 5 ? (dicts.timeSelection || [])[dataMap.selectedTime2].value : 0;
        if(item.prop){
          sortParams2 = {
            order: item.order === "ascending" ? 'ASC' : 'DESC',
            orderField: item.prop,
          }
          getList()
        }else{
          delete sortParams2.order
          delete sortParams2.orderField
          getList()
        }
      }
  
      // 过滤关注度数据
      const fmtFollowsEmptyData = (val:any, type:any) => {
        const newVal = val.split(',')
        return newVal[type];
      }
  
      // 调研机构30内容跳转
      const goResearchMechanism = (row:any, column:any) => {
        const { property } = column;
        // 行业
        if(!['follows1', 'follows2', 'follows3'].includes(property)){
          let url = `/investment/research/list?isPointOrg=0&timeLevel=${dataMap.selectedTime2}`
          // 机构名称
          if(['orgName'].includes(property)){
            if(dataMap.beginDate2) {
              url = `${url}&orgName=${row.orgName}&beginDate=${dataMap.beginDate2}&endDate=${dataMap.endDate2}`
            }else {
              url = `${url}&orgName=${row.orgName}`
            }
          }else {
            if(dataMap.beginDate2) {
              url = `${url}&beginDate=${dataMap.beginDate2}&endDate=${dataMap.endDate2}`
            }else {
              url = `${url}`
            }
          }
          window.open(url);
        }else {
          const { follows } = row;
          let vals = '';
          switch (property) {
            case 'follows1':
              vals = follows[0]
              break;
            case 'follows2':
              vals = follows[1]
              break;
            case 'follows3':
              vals = follows[2]
              break;  
            default:
              break;
          }
          const newCode = vals ? vals.split(',')[1] : ''
          if(newCode) {
            if(dataMap.beginDate2) {
              window.open(`/investment/research/detail?code=${newCode}&timeLevel=${dataMap.selectedTime2}&isPointOrg=0&beginDate=${dataMap.beginDate2}&endDate=${dataMap.endDate2}`)
            }else{
              window.open(`/investment/research/detail?code=${newCode}&timeLevel=${dataMap.selectedTime2}&isPointOrg=0`)
            }
          }
        }
      }

      // （切换每页多少条）
      const handleSizeChange = (val:any) => {
          dataMap.page.pageSize = val;
          dataMap.page.pageNum = 1;
          getList()
      }

      //（切换页码）
      const handleCurrentChange = (val:any) => {
          dataMap.page.pageNum = val;
          getList()
      }
  
      return {
        sortChange2,
        changeHandle2,
        fmtFollowsEmptyData,
        goResearchMechanism,
        handleSizeChange,
        handleCurrentChange,
        ...toRefs(dataMap)
      }
    }
  })
</script>
<style scoped lang='scss'>
.survey-mechanism-warp{
  padding: 21px 35px;
  .bg{
      background: rgb(255, 255, 255);
      padding: 20px;
      margin-top: 30px;
  }
  .pagination{
      text-align: center;
      margin-top: 20px;
      // .el-pagination{
      //     margin-top: 40px;
      //     display: flex;
      //     justify-content: center;
      //     align-items: center;
      //     ::v-deep .el-pagination__total{
      //         display: none;
      //     }
      //     ::v-deep .el-pager{
      //         li{
      //         min-width: 37px;
      //         height: 37px;
      //         line-height: 37px;
      //         border-radius: 4px;
      //         font-size: 14px;
      //         border: 1px solid #CCCCCC;
      //         color: #333333;
      //         margin: 0 8px;
      //         &.active{
      //             background: #386FF2;
      //             border-color: #386FF2;
      //             color: #fff;
      //         }
      //         }
      //     }
      //     ::v-deep .btn-prev,::v-deep .btn-next{
      //         padding: 0;
      //         width: 82px;
      //         height: 37px;
      //         border-radius: 4px;
      //         border: 1px solid #CCCCCC!important;
      //     }
      //     ::v-deep .el-pagination__jump{
      //         height: 37px;
      //         .el-input__inner{
      //         height: 37px;
      //         }
      //     }
      // }
  }
}
</style>
    