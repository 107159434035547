

import { defineComponent, reactive, toRefs } from 'vue'
import CTitle from "@/components/title/Index.vue"
import CTable from './components/Table.vue'
import LineChartA from "@/components/charts/LineChartA.vue"
import BarChartB from "@/components/charts/BarChartB.vue"
import BarChartC from '@/components/charts/BarChartC.vue'
import BarChartE from '@/components/charts/BarChartE.vue'
import Search from '@/components/search-input/Index.vue'
import breadcrumb from '@/components/breadcrumb/Index.vue'
import CList from './List.vue'

import { useRoute  } from "vue-router";
import { getReportIndustryRanking, getReportMonthRanking, getReportOrgCountRanking, getReportPriceDaily } from '@/apis/agency'


export default defineComponent({
  components:{
    Search,
    CTitle,
    CTable,
    LineChartA,
    BarChartC,
    BarChartE,
    BarChartB,
    breadcrumb,
    CList
  },
  setup() {
    // const stockCode = '600519.SH'
    const route = useRoute();
    const stockCode = route.query.code
    const dataMap = reactive({
      stockInfo: {},
      stockList1: {},
      stockList2: [],
      stockList3: [],
      stockList4: [],
      dialogVisible1: false,
      dialogVisible2: false
      // stockList5:[],
      // stockList6:[],
      // stockList7:[],
    })

    const fetchData = async () => {
      const data1 = await getReportMonthRanking({ stockCode: stockCode })
      dataMap.stockList1 = (data1 as any).data;
    }
    fetchData()

    const changeHandle = async (val:any) => {
      
      getTableData(val, null, null)

      const data3 = await getReportOrgCountRanking({ stockCode: stockCode, timeLevel: val.timeLevel })
      dataMap.stockList3 = (data3 as any).data;

      const data4 = await getReportPriceDaily({ stockCode: stockCode, timeLevel: val.timeLevel })
      dataMap.stockList4 = (data4 as any).data;

    }

    
    let tableSort = {
      stockCode: stockCode,
      timeLevel: '',
      sortKey: '',
      isAsc: '',
    }
    const getTableData = async (val:any, sortKey:any, isAsc:any) => {
      if(val){tableSort.timeLevel = val.timeLevel}
      if(sortKey !== undefined){tableSort.sortKey = sortKey}
      if(isAsc !== undefined){tableSort.isAsc = isAsc}
      const data2 = await getReportIndustryRanking( tableSort )
      dataMap.stockList2 = (data2 as any).data;
    }

    const sortChange = async (column:any) => {
      let isAsc = column.order === "ascending" ? true : false
      getTableData({timeLevel: tableSort.timeLevel}, column['prop'], isAsc)
    }

    const getStockInfo = (info:any) => {
      dataMap.stockInfo = info
    }
    
    return {
      ...toRefs(dataMap),
      changeHandle,
      sortChange,
      getStockInfo
    }
  }
})
