
import { defineComponent, reactive, toRefs } from 'vue'
export default defineComponent({
  props: {
    tabList: {
      type: Array,
      default: []
    },
    initialType: {
      type: Number || String,
      default: 1
    }
  },
  setup(props, context) {
    const dataMap = reactive({
      initialStatus: (props.initialType as any)
    })
    const tabClick = (item: any) => {
      dataMap.initialStatus = item.id;
      context.emit('changeTab', item)
    }
    return {
      ...toRefs(dataMap),
      tabClick
    }
  }
})
