/*
 * @Description: 首页相关接口
 */
import { RootObject } from '@/model/rootObject'
import { UserInfoModel, Users } from '@/model/userModel'
import https from '@/utils/https'
import { LoginModel } from '@/views/user-manager/login/model/loginModel'
import { RequestParams, ContentType, Method } from 'axios-mapper'

//获取股票市场调研总计
export const getResearchCount = (params: RequestParams) => {
  return https().request('/survey/market/research/and/org/count', Method.GET, params, ContentType.json)
}

//获取股票市场调研总计
export const getSurveyDailyCount = (params: RequestParams) => {
  return https().request('/survey/daily/count', Method.GET, params, ContentType.form)
}

//获取股票市场调研总计
export const getSurveyIndustryCountRecords = (params: RequestParams) => {
  return https().request('/survey/stock/industry/count/records', Method.GET, params, ContentType.form)
}

//机构调研详情
export const getSurveyStockDetail = (params: RequestParams) => {
  return https().request('/survey/stock/detail', Method.GET, params, ContentType.form)
}

//机构调研详情 机构列表
export const getSurveyStockDetailOrgs = (params: RequestParams) => {
  return https().request('/survey/stock/detail/orgs', Method.GET, params, ContentType.form)
}



//机构调研明细 - 行业列表
export const getOrgIndustryBoardList = (params: RequestParams) => {
  return https().request('/survey/org/industryBoardList', Method.GET, params, ContentType.form)
}

//机构调研明细 - 行业更多
export const getOrgIndustryBoardMore = (params: RequestParams) => {
  return https().request('/survey/org/industryBoardMore', Method.GET, params, ContentType.form)
}

//机构新增调研覆盖个股行业分布
export const getSurveyStockFirstCount = (params: RequestParams) => {
  return https().request('/survey/stock/first/count', Method.GET, params, ContentType.form)
}

//机构新增调研个股总市值分布
export const getSurveyMarketCapFirstCount = (params: RequestParams) => {
  return https().request('/survey/marketCap/first/count', Method.GET, params, ContentType.form)
}

//获取机构调研每日次数
// export const getSurveyDailyCount = (params: RequestParams) => {
//   return https().request('/survey/daily/count', Method.GET, params, ContentType.form)
// }


//机构调研列表
export const getSurveyOrgList = (params: RequestParams) => {
  return https().request('/survey/org/list', Method.GET, params, ContentType.form)
}


// 机构调研频次个股前30
export const getSurveyRateCount = (params: RequestParams) => {
  return https().request('/survey/stock/rate/count', Method.GET, params, ContentType.form)
}

// 调研机构前30
export const getSurveyOrgCount = (params: RequestParams) => {
  return https().request('/survey/org/count', Method.GET, params, ContentType.form)
}

// 机构调研行业分布前30
export const getSurveyIndustryCount = (params: RequestParams) => {
  return https().request('/survey/industry/count', Method.GET, params, ContentType.form)
}

// 机构调研行业分布前30
export const getReportCalculateGains = (params: RequestParams) => {
  return https().request('/report/calculateGains', Method.GET, params, ContentType.form)
}

// 调研搜索
export const getSurveySearch = (params: RequestParams) => {
  return https().request('/survey/search', Method.GET, params, ContentType.form)
}


// 机构调研频次个股统计列表
export const getSurveyRateCountList = (params: RequestParams) => {
  return https().request('/survey/stock/rate/count/list', Method.GET, params, ContentType.form)
}

// 调研机构统计列表
export const getSurveyOrgCountList = (params: RequestParams) => {
  return https().request('/survey/org/count/list', Method.GET, params, ContentType.form)
}
