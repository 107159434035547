<template>
  <div>
    <div class="c-tab">
      <CustomTab @change='fetchData' />
    </div>
    <el-table 
      class="table-style-2" 
      :data="stockList.list || []" 
      :cell-class-name='addClass'
      border 
      align="center" 
      v-loading='loading'
      :default-sort ="{prop:'date',order:'descending'}"
      @sort-change="sortChange">
      <el-table-column label="持股日期" prop="date" sortable="custom"  align="center" />
      <el-table-column :label="`${timeSelected.text}涨幅`" align="center">
        <template #default='{row}'>
          <div v-dClass='row.incr' v-if="row.incr && row.incr !== 'null'"><template v-if="row.incr[0] !== '-'">+</template>{{ row.incr }}%</div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column label="机构名称" prop="orgName"  align="center" />
      <el-table-column label="持股数量（股）" prop="holdNum" sortable="custom"  align="center" >
        <template #default='{row}'>
          {{ row.holdNum }}
        </template>
      </el-table-column>
      <el-table-column label="持股市值（元）" prop="holdMarketCap" sortable="custom"  align="center" >
        <template #default='{row}'>
          {{ row.holdMarketCap }}
        </template>
      </el-table-column>
      <el-table-column label="持股数量占A股 百分比（%）" sortable="custom" prop="holdRatioShares"  align="center" />
    </el-table>
    <CPagination ref='pagin' :pageSize='20' :showPageSizes='true' :pageData='stockList' @fetchData='fetchData'/>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  toRefs,
  reactive
} from 'vue'
import CPagination from "@/components/pagination/Index.vue"
import CustomTab from '../../components/CustomTab.vue'
import { getOrgList } from '@/apis/northboundFunds'
import { useRoute } from "vue-router";
export default defineComponent({
  components: {
    CPagination,
    CustomTab
  },
  setup(props, context) {
    const route = useRoute();
    const dataMap = reactive({
      loading: false,
      timeSelected: {},
      stockList: [
      
      ],
    })

    let params = { 
      stockCode: route.query.code,//'000913.SZ',
      timeUnit: '',
      orderBy: 'date',
      order: 'DESC',
      pageNum: '1',
      pageSize: '10'
    }

    const fetchData = async (value:any) => {
      if( value.value ){
        dataMap.timeSelected = value
        params['timeUnit'] = value.value
        if( value.count !== 'DAYS' ){
          delete params['count']
        }
      }
      if( value.pageNum ){
        params['pageNum'] = value.pageNum
      }
      if( value.pageSize ){
        params['pageSize'] = value.pageSize
      }
      if( value.count ){
        params['count'] = value.count
      }
      

      dataMap.loading = true
      const list = await getOrgList(params)
      if( list ){
        dataMap.stockList = (list as any).data;
      }
      dataMap.loading = false
    }

    const sortChange = (column: any) => {
      let isAsc = column.order === "ascending" ? 'ASC' : 'DESC';
      params['orderBy'] = column["prop"]
      params['order'] = isAsc
      fetchData({})
    };

    const addClass = (column:any) => { 
      if( column.column.property === params.orderBy ){
        return 'sort-bg'
      }
    }
    
    return {
      ...toRefs(dataMap),
      fetchData,
      sortChange,
      addClass
    }
  }
})
</script>
<style scoped lang="scss">
.c-tab{
  text-align: right;
  margin: 12px 0 20px;
}
</style>
