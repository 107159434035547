<template>
  <div class="container">

    <div class="title1">
      <span>历次公告降准后大盘表现</span>
    </div>
    <div class="title2">近10年央行降准与大盘走势(变动公告日期)</div>
    <timeList @change="handleJzTimeChange" :init="true" />
    <chartA :chart-data="jzChartList" id="chart1" />
    <div class="title2">历次公告降准后大盘表现(“日”均指交易日)</div>
    <el-table
        class="scgl-table"
        :data="rrrMarketIncrList || []"
        border
        style="width: 100%"
      >
        <el-table-column
          min-width="20%"
          prop="date"
          align="center"
          label="公告日期"
        />
        <el-table-column
          min-width="20%"
          prop="bp"
          align="center"
          label="降准幅度 (基点)"
        >
        </el-table-column>
        <el-table-column
          min-width="25%"
          prop="day1Incr"
          align="center"
          label="次日涨幅 (%)"
        >
          <template #default="{row}">
            <div v-dClass="row.day1Incr" v-if="row.dataType !==  2">{{row.day1Incr.toFixed(2)}}</div>
            <div v-else>{{row.day1Incr.toFixed(2)}}</div>
          </template>
        </el-table-column>
        <el-table-column
          min-width="15%"
          prop="day5Incr"
          align="center"
          label="后5日累计 (%)"
        >
          <template #default="{row}">
            <div v-dClass="row.day5Incr" v-if="row.dataType !==  2">{{row.day5Incr.toFixed(2)}}</div>
            <div v-else>{{row.day5Incr.toFixed(2)}}</div>
          </template>
        </el-table-column>
        <el-table-column
          min-width="20%"
          prop="day20Incr"
          align="center"
          label="后20日累计 (%)"
        >
          <template #default="{row}">
            <div v-dClass="row.day20Incr" v-if="row.dataType !==  2">{{row.day20Incr.toFixed(2)}}</div>
            <div v-else>{{row.day20Incr.toFixed(2)}}</div>
          </template>
        </el-table-column>
    </el-table>
    <div class="title1">
      <span>近年来央行降息回顾</span>
    </div>
    <timeList @change="handleJxTimeChange" :init="true" />
    <chartA :chart-data="jsList" id="chart2" />
    <div class="jsTab">
      <ul>
        <li :class="jsIdx === idx ? 'active' : ''" v-for="(item, idx) in jsTabList" :key="item" @click="handleJsChange(idx)" >{{item.name}}</li>
      </ul>
    </div>
    <el-table
        class="scgl-table"
        :data="interestRateList || []"
        border
        style="width: 100%;margin-top: 50px"
      >
        <el-table-column
          prop="month"
          align="center"
          label="月份"
        />
        <el-table-column
          align="center"
          label="7日逆回购利率"
        >
          <el-table-column
            prop="nhgDate"
            align="center"
            label="变动日期"
          />
          <el-table-column
            prop="nhgBp"
            align="center"
            label="降幅 (BP)"
          />
        </el-table-column>
        <el-table-column
          align="center"
          label="7天 SLF 操作利率"
        >
          <el-table-column
            prop="slfDate"
            align="center"
            label="变动日期"
          />
          <el-table-column
            prop="slfBp"
            align="center"
            label="降幅(BP)"
          />
        </el-table-column>
        <el-table-column
          align="center"
          label="1年期 MLF 利率"
        >
          <el-table-column
            prop="mlfDate"
            align="center"
            label="变动日期"
          />
          <el-table-column
            prop="mlfBp"
            align="center"
            label="降幅(BP)"
          />
        </el-table-column>
        <el-table-column
          align="center"
          label="1年期LPR 利率"
        >
          <el-table-column
            prop="lpr1Date"
            align="center"
            label="变动日期"
          />
          <el-table-column
            prop="lpr1Bp"
            align="center"
            label="降幅(BP)"
          />
        </el-table-column>
        <el-table-column
          align="center"
          label="5年期LPR 利率"
        >
          <el-table-column
            prop="lpr5Date"
            align="center"
            label="变动日期"
          />
          <el-table-column
            prop="lpr5Bp"
            align="center"
            label="降幅(BP)"
          />
        </el-table-column>

    </el-table>
    <div class="title1">
      <span>国内股市印花税调整后上证综指表现</span>
    </div>
    <timeList @change="handleJyhsTimeChange" :init="true" :initActive="2" />
    <chartA v-if="yhsChartList.length" :chart-data="yhsChartList" id="chart3" />
    <el-empty v-else />
    <el-table
        class="scgl-table"
        :data="yhsTable || []"
        border
        style="width: 100%;margin-top: 70px"
      >
        <el-table-column
          prop="date"
          align="center"
          label="日期"
        />
        <el-table-column
          prop="taxChg"
          align="center"
          label="税收变动"
        >
        </el-table-column>
        <el-table-column
          prop="todayPrice"
          align="center"
          label="当日股价"
        >
        </el-table-column>
        <el-table-column
          prop="todayIncr"
          align="center"
          label="当日涨幅"
        >
          <template #default="{row}">
            <div v-if="row.todayIncr && row.todayIncr!==0 && row.todayIncr!=='0' ">{{row.todayIncr.toFixed(2)}}%</div>
            <div v-else>{{row.todayIncr}}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="weekPrice"
          align="center"
          label="一周后"
        />
        <el-table-column
          prop="monthPrice"
          align="center"
          label="一月后"
        />
        <el-table-column
          prop="weekIncr"
          align="center"
          label="周度变动"
        >
          <template #default="{row}">
            <div v-if="row.weekIncr && row.weekIncr!==0 && row.weekIncr!=='0' ">{{row.weekIncr.toFixed(2)}}%</div>
            <div v-else>{{row.weekIncr}}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="monthIncr"
          align="center"
          label="月度变动"
        >
          <template #default="{row}">
            <div v-if="row.monthIncr && row.monthIncr!==0 && row.monthIncr!=='0' ">{{row.monthIncr.toFixed(2)}}%</div>
            <div v-else>{{row.monthIncr}}</div>
          </template>
        </el-table-column>
    </el-table>
  </div>
</template>

<script lang="ts">
import chartA from "./charts/chartA.vue";
import timeList from "./components/timeList.vue"
import { defineComponent, reactive, toRefs } from 'vue'
import { getMarketEvent, getRrrMarketIncr, getStampTaxMarketIncr, getInterestRate } from '@/apis/stampduty'
export default defineComponent({
  components: {
    chartA,
    timeList
  },
  setup() {
    const dataMap = reactive({
      jsIdx: 0,
      jsTabList: [{
        value: 7,
        name: '7日逆回购利率'
      },{
        value: 5,
        name: '7天SLF操作利率'
      },{
        value: 6,
        name: '1年期MLF利率'
      },{
        value: 3,
        name: '1年期LPR利率'
      },{
        value: 4,
        name: '5年期LPR利率'
      }],
      jzChartList: [],
      rrrMarketIncrList: [],
      jsList: [],
      interestRateList: [],
      yhsChartList: [],
      yhsTable: [],
      jxtimeLevel: '5y',
    })

    const tableEmptyRepalceSyl = (list:any, filed:any) => {
      const newList = list.map((item:any)=>{
        filed.map((innerItem:any)=>{
          if( item[innerItem] !== 0 && !item[innerItem] ){
            item[innerItem] = '-'
          }
        })
        return item
      })
      return newList
    }

    const periodType = 1 //1:日K,2:周K,3:月K
    const handleJyhsTimeChange = async (val:any) => {
      //1:降准,2:降印花税,3:1年贷款利率LPR,4:5年贷款利率LPR,5:常备借贷便利SLF利率, 6:中期借贷便利MLF利率, 7:逆回购利率
      const yhsChartList = await getMarketEvent({ periodType,timeLevel: val,eventType: 2 })
      dataMap.yhsChartList = (yhsChartList as any).data;
      const yhsTable = await getStampTaxMarketIncr({ periodType,timeLevel: val,eventType: 2 })
      dataMap.yhsTable = (yhsTable as any).data;
    }

    //降息切换
    const handleJxTimeChange = async (val:any) => {
      dataMap.jxtimeLevel = val
      const jsList = await getMarketEvent({ periodType,timeLevel: val, eventType: dataMap.jsTabList[dataMap.jsIdx].value })
      dataMap.jsList = (jsList as any).data;
      const interestRateList = await getInterestRate({ periodType,timeLevel: val,eventType: 2 })
      dataMap.interestRateList = tableEmptyRepalceSyl((interestRateList as any)['data'], ['nhgDate', 'nhgBp', 'mlfBp', 'slfDate', 'slfBp', 'mlfDate', 'lpr1Date', 'lpr1Bp', 'lpr5Date', 'lpr5Bp'])
    }

    //降准切换
    const handleJzTimeChange = async (val:any) => {
      const jzChartList = await getMarketEvent({ periodType,timeLevel: val,eventType: 1 })
      dataMap.jzChartList = (jzChartList as any).data;
      const rrrMarketIncrList = await getRrrMarketIncr({ periodType,timeLevel: val,eventType: 1 })
      dataMap.rrrMarketIncrList = (rrrMarketIncrList as any).data;
    }

    //降息tab切换
    const handleJsChange = (idx:number) => {
      dataMap.jsIdx = idx
      handleJxTimeChange(dataMap.jxtimeLevel)
    }

    return {
      ...toRefs(dataMap),
      handleJsChange,
      handleJzTimeChange,
      handleJxTimeChange,
      handleJyhsTimeChange
    };
  },
});
</script>
<style lang='scss' scoped>
.container{
  padding: 15px;
  background: url("https://jufxt-investment.oss-cn-beijing.aliyuncs.com/calf/scgl-bg.jpg");
  .blue{
    color: #1B56AC;
  }

  .title1{
    text-align: center;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    margin: 57px 0 20px;
    span{
      padding: 3px 10px;
      background: linear-gradient(135deg, #FF431A 0%, #FE0D2D 100%);
    }
  }
  .title2{
    font-size: 15px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    text-align: center;
    margin: 30px 0 10px;
  }
  .jsTab{
    margin-top: 25px;
    ul {
      display: flex;
      flex-flow: wrap;
      li {
        width: 31%;
        margin: 0 1% 10px;
        padding: 4px 2px;
        text-align: center;
        color: #B1B1B1;
        border-radius: 13px;
        border: 1px solid #E2E2E2;
        font-size: 12px;
        white-space: nowrap;
        &.active{
          color: #1B56AC;
          border-color: #1B56AC;
        }
      }
    }
  }
}

</style>
<style lang='scss'>
.scgl-table{
  font-size: 12px;
  .cell{
    padding: 0!important;
  }
  .el-table__header-wrapper{
    th{
      background: #E4EFF8!important;
      border-right: 1px solid #fff;
      //border-top: 1px solid #fff;
    }
    tr:nth-child(2){
      th{
        border-top: 1px solid #fff;
      }
    }
  }
  tbody{
    tr{
      &:nth-child(odd) {
        td{
          background: #F6FBFF;
        }
      }
      &:nth-child(even) {
        td{
          background: #E4EFF8;
        }
      }
      td {
        border-right: 1px solid #fff;
      }
    }
  }
}
</style>
