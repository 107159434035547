
import { defineComponent, reactive, toRefs } from 'vue'

export default defineComponent({
  props: {
    init: {
      type: Boolean,
      default: false
    },
    initActive: {
      type: Number,
      default: 0
    }
  },
  emits: ['change'],
  setup(props, contex) {
    const dataMap = reactive({
      activeIdx: 0,
      timeArr: [{
        value: '5y',
        name: '近5年'
      },{
        value: '10y',
        name: '近10年'
      },{
        value: '100y',
        name: '历次以来'
      }]
    })

    //时间切换
    const handleTimeChange = (idx:number) => {
      dataMap.activeIdx = idx
      contex.emit('change', dataMap.timeArr[dataMap.activeIdx].value)
    }

    if( props.init ){
      dataMap.activeIdx = props.initActive
      contex.emit('change', dataMap.timeArr[props.initActive].value)
    }

    return {
      ...toRefs(dataMap),
      handleTimeChange
    }
  }
})
