
import { defineComponent, onActivated, onBeforeUnmount, onDeactivated, onMounted, nextTick, watch } from 'vue'
import resize from './mixins/resize'
import { init, EChartsOption } from 'echarts'
import * as echarts from 'echarts'
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '193px',
      required: true
    },
    chartData: {
      type: Object,
      default: []
    }
  },
  setup(props) {
    watch(props, (newName, oldName) => {
      initChart()
    });
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()
    const initChart = () => {
      if( !props.chartData.length ){
        return false
      }
      const barChart = init(document.getElementById(props.id) as HTMLDivElement)
      let xData = [];
      let serviesData1 = [];
      let serviesData2 = [];
      let serviesData3 = [];
      let mapData = JSON.parse(JSON.stringify( props.chartData))
      for (let index = 0; index < mapData.length; index++) {
        // mapData[index].maxGoalPrice = Number(mapData[index].maxGoalPrice.toFixed(2))
        // mapData[index].minGoalPrice = Number(mapData[index].minGoalPrice.toFixed(2))

        xData.push(mapData[index].dateLabel) 
        serviesData1.push(mapData[index].minGoalPrice)
        serviesData2.push(Number(mapData[index].maxGoalPrice - mapData[index].minGoalPrice).toFixed(2) )
        serviesData3.push(mapData[index].avgGoalPrice)
      }
      barChart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          backgroundColor: "rgba(21,84,155,0.67)", // 修改背景颜色
          borderColor: "transparent", // 修改边框颜色
          borderRadius: 10,
          formatter:function(param:any){
            // console.log(mapData[param[0].dataIndex].maxGoalPrice)
            // 利用循环更改显示的内容
            let list = param.length
            // let Oparam=param
            let txt = '<div style="padding-bottom:10px;color:#fff">' + param[0].name+"</div>"
            for (var i=0;i<list;i++){
              if(i==list-1){
                //最小值
                txt += '<div style="color:#fff">' + param[i].seriesName + "：" + mapData[param[0].dataIndex].minGoalPrice.toFixed(2) + '</div>'
              }else if(i === 0){
                txt += '<div style="padding-bottom:10px;color:#fff">' +  param[i].seriesName + "：" + mapData[param[0].dataIndex].maxGoalPrice.toFixed(2) + '</div>'
              }else{
                txt += '<div style="padding-bottom:10px;color:#fff">' + param[i].seriesName + "：" + mapData[param[0].dataIndex].avgGoalPrice.toFixed(2) + '</div>'
              }
            }
            return txt
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: xData,
            axisTick: {
              show:false,
              alignWithLabel: true
            },
            axisLabel: {
              color:  'rgba(66, 129, 236, 0.5)',
            },
            axisLine: {
              show:true,
              lineStyle: {
                color: '#EEEEEE',
                width:1
              }
            },
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLine: {
              show:true,
              lineStyle: {
                color: '#E8E8E8',
                width:1
              }
            },
            axisLabel: {
              color:  '#2E2E2E',
            },
          }
        ],
        series: [
          
          {
            name: '最大值',
            type: 'bar',
            stack: 'Total',
            barWidth: '60%',
            data: serviesData1,
            itemStyle: {
              borderColor: 'transparent',
              color: 'transparent'
            },
            emphasis: {
              itemStyle: {
                borderColor: 'transparent',
                color: 'transparent'
              }
            },
          },
          {
            name: '一致目标价',
            type: 'line',
            // yAxisIndex: 2,
            data: serviesData3,
            symbol:  function(params:any) {
              console.log(params)
                if(params[2] === '0') {
                    return ''
                } else  {
                    return ''
                }
            } ,
            symbolSize: [10, 10],
            color: '#F77B0E'
          },
          {
            name: '最小值',
            type: 'bar',
            stack: 'Total',
            barWidth: '60%',
            data: serviesData2,
             itemStyle: {
                normal: {
                    // barBorderRadius: [10, 10, 0, 0],
                    color: new echarts.graphic.LinearGradient(
                        0, 0, 1, 0,
                        [
                            {offset: 0, color: '#3977E6'},
                            {offset: 1, color: '#37BBF8'}
                        ]
                    )
                }
            }
          },
        ]
      } as EChartsOption)
      chart.value = barChart
    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {

    }
  }
})
