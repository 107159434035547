
import pagingComponent from '@/components/pagination/Index.vue'
import { defineComponent, reactive, toRefs, ref } from 'vue'
import navSwitch from './nav-switch.vue'

import { getIndexBoardValuation } from '@/apis/market'
export default defineComponent({
  components: {
    pagingComponent,
    navSwitch
  },
  setup(props) {
    const paging = ref(null)
    const dataMap = reactive({
      loading: true,
      tableData: {},
      valuationNvaList: [
        {
          id: 1,
          name: '沪深',
          val: 'index'
        },
        {
          id: 2,
          name: '一级行业',
          val: 'swl1'
        },
        {
          id: 3,
          name: '二级行业',
          val: 'swl2'
        },
        {
          id: 4,
          name: '三级行业',
          val: 'swl3'
        },
      ],
    })
    let params = {
      type: 'index',
      pageNum: '1',
      pageSize: '10'
    }
    const fetchData = async (value:any) => {
      if( value.pageNum ){
        params['pageNum'] = value.pageNum
      }
      if( value.pageSize ){
        params['pageSize'] = value.pageSize
      }
      if( value.type ){
        params['type'] = value.type
      }
      dataMap.loading = true
      const marginList = await getIndexBoardValuation(params)
      dataMap.tableData = (marginList as any).data;
      dataMap.loading = false
    }
    const headerMethod = (item: any) => {
      const { row, columnIndex } = item
      // row[0].colSpan = 0
      if (row[0].level == 1) {
        row[8].colSpan = 0
        row[1].colSpan = 2
        if (columnIndex === 2) {
          return { display: 'none' }
        }
      }
    }
    const goDetail = (item: any) => {
      const path = '/page/data_center_all'
      window.open( `${path}?industryName=${item.name}&boardType=${item.type}`)
    }
    const changeTab = (item: any) => {
      if( paging.value ){
        (paging as any).value.setCurrentPage(1)
      }
      fetchData({
        type: item.val,
        pageNum: 1
      })
    }
    const sortChange = (column: any) => {
      let isAsc = column.order === "ascending" ? 'asc' : 'desc';
      params['sortFiled'] = column["prop"]
      params['sort'] = isAsc
      fetchData({})
    }

    const dataFormat = (val: any) => {
      if( typeof val !== 'number' ){
        return '-'
      }
      if( val > 0 ){
        return '+' + val.toFixed(2) + '%'
      }else{
        return val.toFixed(2) + '%'
      }
    }


    return {
      ...toRefs(dataMap),
      paging,
      fetchData,
      goDetail,
      headerMethod,
      changeTab,
      sortChange,
      dataFormat
    }
  }
})
