
import { defineComponent, onActivated, onBeforeUnmount, onDeactivated, onMounted, nextTick, watch } from 'vue'
import resize from './mixins/resize'
import { init, EChartsOption } from 'echarts'
import * as echarts from 'echarts'
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '193px',
      required: true
    },
    chartData: {
      type: Object,
      default: {}
    }
  },
  setup(props,context) {
    watch(props, (newName, oldName) => {
      initChart()
    });

    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()
    const initChart = () => {
      const barChart = init(document.getElementById(props.id) as HTMLDivElement)
      let xData = [];
      let serviesData1 = [];
      let serviesData2 = [];
      let serviesData3 = [];
      for (let index = 0; index < props.chartData.length; index++) {
        xData.push(props.chartData[index].date)
        serviesData1.push(props.chartData[index].macd)
        serviesData2.push(props.chartData[index].diff || props.chartData[index].dif)
        serviesData3.push(props.chartData[index].dea)
        // serviesData2.push(props.chartData[index].focusOrgResearchCount)
      }

      barChart.setOption({
        tooltip: {
          show: true,
          trigger: 'axis', 
          // position:'inside',
          padding:4,
          textStyle:{
            color:'#999999',
            fontSize:'10',
          },
          // position: function(point:any,params:any,dom:any,rect:any){

          //   let x = 0
          //   let y= 0
          //   if(point[0]>140){
          //     x = point[0] - 100
          //   }else{
          //     x = point[0] +10
          //   }
          //   if(point[1]>55){
          //     y = point[1] - 60
          //   }else{
          //     y = point[1]
          //   }
          //   return [x,y]
          // },
          formatter:function(param:any){
            // 利用循环更改显示的内容
            // that.setData({
            //     difnum:(param[1].data*1).toFixed(2)*1,
            //     deanum:(param[2].data*1).toFixed(2)*1,
            //     macdnum:(param[0].data*1).toFixed(2)*1,
            //   })
            context.emit('getMacdVal',[param[0].data,param[1].data,param[2].data])

            return 
          }
        },
          grid: {
            left: -30,
            right: 0,
            bottom: 0,
            top: 0,
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: xData,
              axisLine: {
                lineStyle: {
                  color: '#999',
                  width:1,
                }
              },
              axisLabel: {
                  show:false,
                  color: '#666'
              },
              axisTick:{
                  show:false
              },
      
            }
          ],
          yAxis: [
            {
              type: 'value',
              show:false,
              axisTick: { show: false },
              axisLine: {
                lineStyle: {
                  color: '#999'
                }
              },
              axisLabel: {
                color: '#666'
              },
              // splitLine: {
              //     lineStyle: {
              //         // type: 'dashed'
              //         color: '#142232',
              //         width:1,
              //     }
              // }
            }
          ],
          series: [
            {
              name: 'macd',
              type: 'bar',
              // barWidth:'2',
              label: {
                normal: {
                  show: false,
                  // position: 'top'
                }
              },
              barWidth: 1, // 柱子宽度
              data: serviesData1,
              itemStyle: {
                  normal: {
                    width: '1',
                      barBorderRadius: [5,5,0,0],
                      // color:'#FB160C',
                      color:function(params:any) {
                        var index_color = params.value;
                            if(index_color>=0){
                                return '#FB160C';
                            }else {
                                return '#02C165';
                            }
                        }
                  },
                  label:{
                      show:false
                  }
              }
            },
            {
              name: 'diff',
              type: 'line',
              smooth: true,  //这个可以显示为平滑过渡的线条
              showSymbol:false,
              itemStyle:{
                normal:{
                  lineStyle:{
                    // color:huyan==1?'#FFFFFF':'#333333',
                    color:'#333333',
                    width:1,
                    barBorderRadius:0,
                  },
                },
                emphasis:{
                  lineStyle:{
                    width:1,
                  },
                }
              },
              data: serviesData2,
            },
            {
              name: 'dea',
              type: 'line',
              smooth: true,  //这个可以显示为平滑过渡的线条
              showSymbol:false,
              itemStyle:{
                normal:{
                  
                  lineStyle:{
                    color:'#F6C52A',
                    width:1,
                    barBorderRadius:0,
                  },
                },
                emphasis:{
                  lineStyle:{
                    width:1,
                  },
                }
              },
              data: serviesData3
            }
          ]
      } as unknown as EChartsOption)
      chart.value = barChart
    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {

    }
  }
})
