/*
 * @Description: 融资融券接口
 */
import https from '@/utils/https'
import { RequestParams, ContentType, Method } from 'axios-mapper'

// 获取板块列表
export const getMargin = (params: RequestParams) => {
  return https().request('/margin/data/board/list', Method.GET, params, ContentType.form)
}
// 获取板块列表图表
export const getMarginRanking = (params: RequestParams) => {
  return https().request('/margin/data/board/ranking', Method.GET, params, ContentType.form)
}
// 搜索股票和行业
export const getMarginSearch = (params: RequestParams) => {
  return https().request('/margin/data/search', Method.GET, params, ContentType.form)
}
// 板块融资净买入-由降转增
export const geBoardSub = (params: RequestParams) => {
  return https().request('/margin/data/rzjmr/board/subToAdd', Method.GET, params, ContentType.form)
}
// 板块融资净买入-由增转降
export const getBoardAdd = (params: RequestParams) => {
  return https().request('/margin/data/rzjmr/board/addToSub', Method.GET, params, ContentType.form)
}
// 个股融资净买入-由降转增
export const getStockSub = (params: RequestParams) => {
  return https().request('/margin/data/rzjmr/stock/subToAdd', Method.GET, params, ContentType.form)
}
// 个股融资净买入-由增转降
export const getStockAdd = (params: RequestParams) => {
  return https().request('/margin/data/rzjmr/stock/addToSub', Method.GET, params, ContentType.form)
}
// 获取首页融资融券个股排行列表数据 前十
export const getStockTable = (params: RequestParams) => {
  return https().request('/margin/data/stock/getMarginStockRankList', Method.GET, params, ContentType.form)
}
// 获取首页融资融券个股排行图表数据 前十
export const getStockCharts = (params: RequestParams) => {
  return https().request('/margin/data/stock/getMarginTop10ByJmr', Method.GET, params, ContentType.form)
}
// 融资融券个股统计
export const getStockRank = (params: RequestParams) => {
  return https().request('/margin/data/stock/rank', Method.POST, params, ContentType.json)
}
// 分享页 小结
export const getReportSummary = (params: RequestParams) => {
  return https().request('/margin/data/summary', Method.GET, params, ContentType.form)
}
// 分享页 小结
export const getBoard = (params: RequestParams) => {
  return https().request('/margin/data/whole/trend', Method.GET, params, ContentType.form)
}
// 分享页 小结
export const getDaily = (params: RequestParams) => {
  return https().request('/margin/data/board/top', Method.GET, params, ContentType.form)
}
// 分享页 小结
export const getAddHold = (params: RequestParams) => {
  return https().request('/margin/data/stock/rzjmr/top20', Method.GET, params, ContentType.form)
}
// 分享页 小结
export const getSubHold = (params: RequestParams) => {
  return https().request('/margin/data/stock/rqjmc/top20', Method.GET, params, ContentType.form)
}
// 分享页 最高前10table
export const getSubTen = (params: RequestParams) => {
  return https().request('/margin/data/stock/rzye/top10', Method.GET, params, ContentType.form)
}
// 分享页 最高涨幅前10table
export const getUpTen = (params: RequestParams) => {
  return https().request('/margin/data/stock/rzyeUp/top10', Method.GET, params, ContentType.form)
}
// 分享页 最高涨幅前10table
export const getDownTen = (params: RequestParams) => {
  return https().request('/margin/data/stock/rzyeDown/top10', Method.GET, params, ContentType.form)
}
