<template>
  <div>
    <span @click="goHome">{{title}}</span>
    <span>{{ subTitle }}</span>
  </div>
</template>

<script lang="ts">
import { reactive, defineComponent, toRefs } from 'vue'
import dicts from '@/utils/dicts'
export default defineComponent({
  props:{
    title:{
      type: String
    },
    subTitle:{
      type: String
    }
  },
  setup() {
    const dataMap = reactive({

    })

    const goHome = () => {
      window.open(`/investment/financial/index`)
    }
    return { ...toRefs(dataMap), goHome }
  }
})
</script>

<style scoped lang="scss">

div{
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #0E1018;
  line-height: 20px;
  padding: 20px 0;
  display: flex;
  span {
    display: block;
    &:first-child {
      color: #386FF2;
      margin-right: 6px;
      cursor: pointer;
    }
  }
}
</style>
