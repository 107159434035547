<template>
  <CLIST />
</template>

<script lang="ts">

import { defineComponent, reactive, toRefs } from 'vue'
import CLIST from './List.vue'

export default defineComponent({
  components: {
    CLIST
  },
  setup() {
    
    

    return {
      // ...toRefs(dataMap),
    }
  }
})
</script>
<style lang='scss' scoped>
.bg{
  background: #fff;
  padding: 38px 32px 54px;
}
.agency-rating{
  background: none;
  padding: 38px 32px 54px;
}
.agency-rating-container{
  margin: 0;
  padding: 0;
}
::v-deep .mode{
  margin-bottom: 36px;
}
</style>
