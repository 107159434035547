<template>
  <div
    :id="id"
    :class="className"
    :style="{height: height, width: width}"
  />
</template>

<script lang="ts">
import { defineComponent, onActivated, onBeforeUnmount, onDeactivated, onMounted, nextTick, watch } from 'vue'
import resize from './mixins/resize'
import { init, EChartsOption } from 'echarts'
import * as echarts from 'echarts'
export default defineComponent({
  props: {
    chartData: {
      type: Object,
      default: {}
    },
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '193px',
      required: true
    }
  },
  setup(props) {
    watch(props, (newName, oldName) => {
      initChart()
    });
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()
    const initChart = () => {
      const barChart = init(document.getElementById(props.id) as HTMLDivElement)
      let xdata = [],seriesData = []
      for (let index = 0; index < props.chartData.length; index++) {
        // const element = array[index];
        xdata.push(props.chartData[index].date)
        seriesData.push(props.chartData[index].valuation)
      }
      // console.log(seriesData,xdata)
      barChart.setOption({
        grid: {
          containLabel: true,
          bottom:10,
          top:10,
          left:0,
          right:0
        },
        tooltip: {
          show: true,
          trigger: 'axis', 
          position:'inside',
          // padding:4,
          // textStyle:{
          //   color:'#999999',
          //   fontSize:'10',
          // },
          // positio1n:function(point,params,dom,rect){
          //   let x = 0
          //   let y= 0
          //   if(point[0]>210){
          //     x = point[0] - 100
          //   }else{
          //     x = point[0] +10
          //   }
          //   if(point[1]>55){
          //     y = point[1] - 60
          //   }else{
          //     y = point[1]
          //   }
          //   return [x,y]
          // },
          // formatter:function(param:any){
          //  // 利用循环更改显示的内容
          //   let list = param.length
          //   let Oparam=param
          //   let txt =" "+ param[0].name+"\n"
          //   for (var i=0;i<list;i++){
          //    if(i==list-1){
          //     txt +=param[i].marker+ param[i].seriesName + ":" + param[i].data
          //    }else{
          //      txt +=param[i].marker+ param[i].seriesName + ":" + param[i].data + '\n'
          //    }
          //   }
          //   return txt
          // }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xdata,
          show:  false,
          axisLine:{
            // lineStyle:{
            //   color: '#BFC0C4',
            //   width:1,
            // }
          },
          axisLabel:{
            // interval:Math.floor(list1.length/3),
            // interval:2,
            // formatter:function(val:any){      
            //   return ''
            // },
            // // margin:20,
            // textStyle:{
              
            // }
          }
        },
        yAxis: {
          x: 'center',
          type: 'value',
          splitLine: {
            lineStyle: {
              // type: 'dashed'
              color: '#e6e6e6',
              width:1,
            }
          },
          axisLabel : {
            textStyle: {
                color: '#86878B'
            },
            formatter:function(param:any){
              return param + '%'
            }
          },
          
          // min:18,
          // max:38,
          // interval:2,
          // show: false
        },
        series: [{
          // name: '股价',
          type: 'line',
          smooth: false,
          showSymbol:false,
          itemStyle:{
            normal:{
              lineStyle:{
                color:'#FE9625'
              },
            }
          },
          areaStyle:{
            normal: {
              color: {
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                    offset: 0,
                    color: "rgba(254,150,37,0.3)" // 0% 处的颜色
                  }, {
                    offset:  1,
                    color: "rgba(254,150,37,0)" // 100% 处的颜色
                  }],
    
                  }
                }
          },
          // markLine:{
          //   data: [{
          //     yAxis: '20.68',
          //     lineStyle:{
          //       normal:{
          //         type:'dashed',
          //         color:'#F6C52A',
          //         width:1,
          //       }
          //     }
          //   },{
          //     yAxis: "26.58",
          //     lineStyle:{
          //       normal:{
          //         type:'dashed',
          //         color: '#E8828E',
          //         width:1,
          //       }
          //     }
          //   }],
          //   symbol:'none',
          //   silent: true,
          //   label:{
          //     show:false
          //   },
            
          // },
          data: seriesData
        }]
      } as unknown as EChartsOption)
      chart.value = barChart
    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {

    }
  }
})
</script>

<style lang="scss" scoped>

</style>
