

import { defineComponent,reactive,toRefs } from 'vue'
import CTitle from '@/components/title/Index.vue'
import BarChartD from '@/components/charts/BarChartD.vue'
import { getDiagnosticRiskInvestment, getDiagnosticSentiment, getDiagnosticPerformance, getDiagnosticMajorEvent, getDiagnosticProfitability } from '@/apis/diagnosis'
import { useRoute } from 'vue-router'

interface Role {
  code: number
}
export default defineComponent({
  props: {
    dataMap: {
      type: Array,
      default: []
    }
  },
  components: {
    CTitle,
    BarChartD
  },
  setup() {
    // const stockCode = '600751.SH'
    // const stockCode = '002821.SZ'
    const route = useRoute()
    const stockCode = route.query.code//'600519.SH'
    const dataMap = reactive({
      dstxActive:0,
      ajaxRes: false,
      stockList1: [],
      sentimentInfo: [] as Role[],
      timearr: [],
      clicktype: 0,

      morePerformance:false,
      dupontInfo:{},//杜邦分析
      profitTypearr:[
        '杜邦分析','回报率','利润率','费用率'
      ],
      returnRate:['roe','roic','roa'],
      profitMargin:['allExpense','netProfitMargin','optoor'],
      profitMargintext:['毛利率','净利率','营业率'],
      costRate:['allExpense','accountsReceivableRatios','adminExpense','rdExpense','finaExpense'],
      costRatetext:['总费用','销售费用','管理费用','研发费用','财务费用'],
      profitTypeobj:['dupontInfo','returnRate','profitMargin','costRate'],
      profitType:0,
      profitType1:0,
      profitType1arr1:[],
      profitType1arr2:[],
      profitabilityInfo:[],//盈利能力数据
      profitTable:[
        'ROM(TTM)','销售净利润', '总资产周转率','权益乘数'
      ],
    })
    
    

    // let sentimentInfo = [{"id":4158395,"stocksId":9093,"batchNum":264,"title":"金钟股份:关于2022年限制性股票激励计划授予价格确定方法的更正说明公告","typeName":"公告","source":"金钟股份","infoCode":"AN202210141579162101","infoTime":"10-14"},{"id":4158396,"stocksId":9093,"batchNum":264,"title":"金钟股份:广州市金钟汽车零件股份有限公司2022年限制性股票激励计划首次授予部分激励对象名单","typeName":"公告","source":"金钟股份","infoCode":"AN202210131579134257","infoTime":"10-13"},{"id":4158397,"stocksId":9093,"batchNum":264,"title":"金钟股份:广州市金钟汽车零件股份有限公司监事会关于公司2022年限制性股票激励计划(草案)的核查意见","typeName":"公告","source":"金钟股份","infoCode":"AN202210131579134263","infoTime":"10-13"}]


    const dstxList = ['舆情关注','公司业绩','内部交易','股权激励','大额解禁'];

    const swichItem=(idx:any)=>{
      dataMap.dstxActive = idx
      dataMap.clicktype = idx
    }

    const swichYlnlItem=(idx:any)=>{
      const { profitabilityInfo, profitTypeobj, profitType } = dataMap;

      let arr = profitabilityInfo[profitTypeobj[idx]][dataMap[profitTypeobj[idx]][0]+1]
      let arr2 = profitabilityInfo[profitTypeobj[idx]][dataMap[profitTypeobj[idx]][0]+2]

      if(arr&&arr.length>0){
        arr.forEach((item:any)=>{
          item.reportPeriodtext = item.reportPeriod.substr(0,4)
        })
      }
      // let that = this
      console.log(99999,arr)

      dataMap.profitType = idx
      dataMap.profitType1 = 0
      dataMap.profitType1arr1 = arr
      dataMap.profitType1arr2 = arr2
      
      // this.setData({
      //   profitType:e.currentTarget.dataset.index,
      //   isecbar2:false,
      //   profitType1:0,
      //   profitType1arr1:arr,
      //   profitType1arr1:arr2
      // },()=>{
      //   that.getecbar2()
      // })
      // setTimeout(() => {
        
        
      // }, 10);
    }

    const setprofitType1 = (idx:any) => {
       // console.log(e)
      const { profitabilityInfo, profitTypeobj, profitType } = dataMap;

      console.log(profitabilityInfo,profitType)
      let arr = profitabilityInfo[profitTypeobj[profitType]][dataMap[profitTypeobj[profitType]][idx]+1]
      let arr2 = profitabilityInfo[profitTypeobj[profitType]][dataMap[profitTypeobj[profitType]][idx]+2]

      if(arr&&arr.length>0){
        arr.forEach((item:any)=>{
          if(item.reportPeriod){
            item.reportPeriodtext = item.reportPeriod.substr(0,4)
          }
        })
      }
      arr = arr ? arr : []
      arr2 = arr2 ? arr2 : []
      if( !arr2 ){

      }
       console.log('arr111',arr,arr2)
        
      dataMap.profitType1 = idx
      dataMap.profitType1arr1 = arr
      dataMap.profitType1arr2 = arr2
      // let that = this
      // this.setData({
      //   profitType1:e.currentTarget.dataset.index*1,
      //   isecbar2:false,
      //   profitType1arr1:arr,
      //   profitType1arr2:arr2,

      // }, ()=>{
      //   that.getecbar2()
      // })
    }

    const getmoreInfo = () => {
      dataMap.morePerformance = !dataMap.morePerformance
    }

    const gettimetext2 = (time:any) => {
      let time2 = new Date(time)
      let yue = time2.getMonth()+1<10?('0'+(time2.getMonth()+1)):time2.getMonth()+1
      let day = time2.getDate()+1<10?('0'+(time2.getDate()+1)):time2.getDate()+1
      return yue +'-'+day
    }

    const fetchData = async () => {
      const data1 = await getDiagnosticRiskInvestment({ stockCode: stockCode })
      dataMap.stockList1 = (data1 as any).data;

      const data2 = await getDiagnosticSentiment({ stockCode: stockCode })
      const data3 = await getDiagnosticPerformance({ stockCode: stockCode })
      const data4 = await getDiagnosticMajorEvent({ stockCode: stockCode })
      const data5 = await getDiagnosticProfitability({ stockCode: stockCode })
      
      let arr = (data3 as any).data
      if(arr&&arr.length>0){
        arr.forEach((item:any)=>{
          if(item.sourceTime){
            item.infoTime = item.sourceTime.substr(5)
          }
        })
      }
      let arr2 = (data4 as any).data?.trans
      if(arr2&&arr2.length>0){
        arr2.forEach((item:any)=>{
          if(item.annDtNew){
            item.infoTime = item.annDtNew.substr(5)
          }
        })
      }
      

      let obj = (data4 as any).data?.stockUps
      if(obj && obj.length>0){
        obj.forEach((item:any)=>{
            // console.log(item)
          item.infoTime = item.date.substr(4,2)+'-'+item.date.substr(6)
        })
      }

      let obj2 = (data4 as any).data?.shares
      if(obj2 && obj2.length>0){
        obj2.forEach((item:any)=>{
          item.infoTime = gettimetext2(item.date)
        })
      }
      dataMap.sentimentInfo = [
        (data2 as any).data, 
        arr, 
        arr2, 
        obj, 
        obj2];

      dataMap.dupontInfo = (data5 as any).data?.dupontInfo
      dataMap.profitabilityInfo = (data5 as any).data;

      dataMap.ajaxRes = true;
    }
    fetchData()


    return {
      dstxList,
      swichItem,
      swichYlnlItem,
      setprofitType1,
      getmoreInfo,
      ...toRefs(dataMap),
    }
  }
})
