

import { defineComponent, reactive, toRefs } from 'vue'
import CTitle from '@/components/title/Index.vue'
import CSelect from "@/components/select/Index.vue"
import { getSurveyRecordsList } from '@/apis/home'
import { getSurveyIndustryCountRecords } from '@/apis/research'
import { useRoute  } from "vue-router";

export default defineComponent({
  props: {
    dataMap: {
      type: Array,
      default: []
    },
    title: {
      type: String,
      default: '机构评级'
    }
  },
  components: {
    CTitle,
    CSelect
  },
  setup() {
    // const stockCode = '002821.SZ'
    const route = useRoute();
    const stockCode = route.query.code
    const dataMap = reactive({
      ajaxRes: false,
      stockList5:[],
    })
    let selectedTime = ''


    let tableSort = {
      stockCode: stockCode,
      timeLevel: '',
      sortKey: 'researchCount',
      isAsc: false,
    }
    const changeHandle = async (val:any, sortKey:any, isAsc:any) => {
      if(val){tableSort.timeLevel = val.timeLevel;selectedTime = val.value}
      if(sortKey !== undefined){tableSort.sortKey = sortKey}
      if(isAsc !== undefined){tableSort.isAsc = isAsc}
      console.log('route.path', route.path)
      if( route.path === '/research/detail' ){
        const data4 = await getSurveyIndustryCountRecords({...tableSort})
        dataMap.stockList5 = (data4 as any).data;
      }else{
        const data4 = await getSurveyRecordsList({...tableSort})
        dataMap.stockList5 = (data4 as any).data;
      }
    }

    const sortChange = (column:any) => {
      // console.log(timeLevel)
      let isAsc = column.order === "ascending" ? true : false
      changeHandle({timeLevel: tableSort.timeLevel}, column['prop'], isAsc)
    }

    /**
     * 跳转详情页面
     */
    const goDetail = (item:any) => {
      window.open(`/investment/research/detail?code=${item.code}&time=${selectedTime}`)
      // window['$props'].push({
      //   path: `/investment/research/detail`,
      //   query:{
      //     code: item.code,
      //     time: selectedTime
      //   }
      // })
    }
    // fetchData(keyTimeMap[0]);
    return {
      ...toRefs(dataMap),
      goDetail,
      changeHandle,
      sortChange,
    }
  }
})
