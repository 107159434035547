<template>
  <div>
    <div class="synthesize-valuation">
      <div class="factor-item-wrapper">
        <div
          v-for="factorItem in factorDataList"
          :key="factorItem.key"
          :class="(currentCheckValuetion === factorItem.code || rangeCheckKeyList.includes(factorItem.code)) ? 'factor-item-data-active' : ''"
          class="factor-item-data"
          @click="changeFactorType(factorItem)"
        >
          {{ factorItem.name }}
        </div>
      </div>
      <div class="factor-value-box">
        <div v-if="currentCheckValuetion" class="factor-item-value-wrapper">
          <div
            v-for="item in factorChildrenList"
            :key="item.key"
            :class="rangeCheckChildKeyList.includes(item.key) ? 'factor-children-item-active' : ''"
            class="factor-children-item"
            @click="checkChildrenRange(item)"
          >
            <span v-if="item.type !== 'custom'">{{ item.name }}</span>
            <el-popover
              v-else
              ref="itemRefs"
              :visible-arrow="true"
              placement="bottom"
              trigger="hover"
              width="300"
            >
              <div class="c-custom-form">
                <div class="c-custom-form-warp">
                  <div class="c-custom-form-item">
                    <div class="c-custom-form-item-label">
                      <span>大于等于</span>
                    </div>
                    <div class="c-custom-form-item-input">
                      <el-input
                        v-model="customValue1"
                        :class="(customValue1 && isVaild1) ? 'c-custom-valid-failed' : ''"
                        placeholder=""
                        size="mini"
                        @input="changeCustomValue($event, 'value1')"
                      >
                      </el-input>
                    </div>
                  </div>
                  <div class="c-custom-form-item">
                    <div class="c-custom-form-item-label">
                      <span>小于等于</span>
                    </div>
                    <div class="c-custom-form-item-input">
                      <el-input
                        v-model="customValue2"
                        :class="(customValue2 && isVaild2) ? 'c-custom-valid-failed' : ''"
                        placeholder=""
                        size="mini"
                        @input="changeCustomValue($event, 'value2')"
                      >
                      </el-input>
                    </div>
                  </div>
                  <div class="c-custom-form-tips">
                    <span>最多精确到小数点后面两位</span>
                  </div>
                  <div class="c-custom-form-btn">
                    <div class="c-custom-form-btn-warp">
                      <el-button size="mini" @click="cancelCustomValue">取消</el-button>
                      <el-button :disabled="isDisabled" size="mini" type="primary" @click="saveCustomValue(item)">保存
                      </el-button>
                    </div>
                  </div>
                </div>
              </div>
              <template #reference><span>{{ item.name }}</span></template>
            </el-popover>
          </div>
        </div>
        <div class="btn" @click="onSearch">查询</div>
      </div>
    </div>
    <div class="filter-criteria">
      <div class="filter-criteria-title">
        筛选条件:
      </div>
      <div class="filter-criteria-wrapper">
        <div v-if="selectedIndustry" class="filter-criteria-item">
          <span>{{ selectedIndustry }}</span>
          <i class="el-icon-close" @click="delIndustryTab()"/>
        </div>
        <div v-for="item in rangeCheckList" :key="item.key" class="filter-criteria-item">
          <span>{{ `${item.parentName}: ${item.name}` }}</span>
          <i class="el-icon-close" @click="delChildrenRange(item)"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  toRefs,
  reactive,
  ref
} from 'vue'

export default defineComponent({
  components: {},
  setup(props, context) {
    const factorDataList = [
      {
        firstLevel: 1,
        key: 2,
        code: 'increase',
        name: '涨幅',
        children: [
          {
            parentKey: 2,
            parentCode: 'increase',
            parentName: '涨幅',
            prop: 'peTtm',
            key: '2-0',
            name: '大于2%',
            maxValue: '>',
            minValue: 2
          },
          {
            parentKey: 3,
            parentCode: 'increase',
            parentName: '涨幅',
            prop: 'peTtm',
            key: '2-1',
            name: '大于5%',
            maxValue: '>',
            minValue: 5
          },
          {
            parentKey: 3,
            parentCode: 'increase',
            parentName: '涨幅',
            prop: 'peTtm',
            key: '2-2',
            name: '小于-5%',
            maxValue: '<=',
            minValue: 5
          },
          {
            parentKey: 3,
            parentCode: 'increase',
            parentName: '涨幅',
            prop: 'peTtm',
            key: '2-3',
            name: '小于-2%',
            maxValue: '<',
            minValue: -2
          },
          {
            parentKey: 3,
            parentCode: 'increase',
            parentName: '涨幅',
            prop: 'peTtm',
            type: 'custom',
            key: '2-4',
            name: '自定义',
            maxValue: '',
            minValue: ''
          }]
      },
      {
        firstLevel: 1,
        key: 3,
        code: 'turnover',
        name: '换手率',
        children: [{
          parentKey: 3,
          parentCode: 'turnover',
          parentName: '换手率',
          prop: 'pb',
          key: '3-1',
          name: '2%以下',
          maxValue: '<',
          minValue: '2'
        },{
          parentKey: 3,
          parentCode: 'turnover',
          parentName: '换手率',
          prop: 'pb',
          key: '3-2',
          name: '2%-5%',
          type: 'c',
          maxValue: '5',
          minValue: '2'
        },{
          parentKey: 3,
          parentCode: 'turnover',
          parentName: '换手率',
          prop: 'pb',
          key: '3-3',
          name: '5%-10%',
          type: 'c',
          maxValue: '10',
          minValue: '5'
        },{
          parentKey: 3,
          parentCode: 'turnover',
          parentName: '换手率',
          prop: 'pb',
          key: '3-4',
          name: '10%以上',
          maxValue: '>',
          minValue: '10'
        },{
          parentKey: 3,
          parentCode: 'turnover',
          parentName: '换手率',
          prop: 'pb',
          type: 'custom',
          key: '3-5',
          name: '自定义',
          maxValue: '',
          minValue: ''
        }]
      },
      {
        firstLevel: 1,
        key: 4,
        code: 'pettm',
        name: 'PETTM(倍）',
        children: [
          {
            parentKey: 4,
            parentCode: 'pettm',
            parentName: 'PETTM(倍）',
            prop: 'peTtm',
            key: '4-0',
            name: '0以下',
            maxValue: '<',
            minValue: '0'
          },
          {
            parentKey: 4,
            parentCode: 'pettm',
            parentName: 'PETTM(倍）',
            prop: 'peTtm',
            key: '4-1',
            name: '0-10',
            type: 'c',
            maxValue: 10,
            minValue: 0
          },
          {
            parentKey: 4,
            parentCode: 'pettm',
            parentName: 'PETTM(倍）',
            prop: 'peTtm',
            key: '4-2',
            name: '10-20',
            type: 'c',
            maxValue: 20,
            minValue: 10
          },
          {
            parentKey: 4,
            parentCode: 'pettm',
            parentName: 'PETTM(倍）',
            prop: 'peTtm',
            key: '4-3',
            name: '20-30',
            type: 'c',
            maxValue: 30,
            minValue: 20
          },
          {
            parentKey: 4,
            parentCode: 'pettm',
            parentName: 'PETTM(倍）',
            prop: 'peTtm',
            key: '4-4',
            name: '30-50',
            type: 'c',
            maxValue: 50,
            minValue: 30
          },
          {
            parentKey: 4,
            parentCode: 'pettm',
            parentName: 'PETTM(倍）',
            prop: 'peTtm',
            key: '4-5',
            name: '50以上',
            maxValue: '>',
            minValue: '50'
          },
          {
            parentKey: 4,
            parentCode: 'pettm',
            parentName: 'PETTM(倍）',
            prop: 'peTtm',
            type: 'custom',
            key: '4-6',
            name: '自定义',
            maxValue: '',
            minValue: ''
          }]
      },
      {
        firstLevel: 1,
        key: 5,
        code: 'asz',
        name: '按市值(亿）',
        children: [
          {
            parentKey: 5,
            parentCode: 'asz',
            parentName: '按市值(亿）',
            prop: 'pb',
            key: '5-1',
            name: '30以下',
            maxValue: '<',
            minValue: '30'
          },
          {
            parentKey: 5,
            parentCode: 'asz',
            parentName: '按市值(亿）',
            prop: 'pb',
            key: '5-2',
            name: '30-50',
            type: 'c',
            maxValue: 50,
            minValue: 30
          },
          {
            parentKey: 5,
            parentCode: 'asz',
            parentName: '按市值(亿）',
            prop: 'pb',
            key: '5-3',
            name: '50-100',
            type: 'c',
            maxValue: 100,
            minValue: 50
          },
          {
            parentKey: 5,
            parentCode: 'asz',
            parentName: '按市值(亿）',
            prop: 'pb',
            key: '5-4',
            name: '100-300',
            type: 'c',
            maxValue: 300,
            minValue: 100
          },
          {
            parentKey: 5,
            parentCode: 'asz',
            parentName: '按市值(亿）',
            prop: 'pb',
            key: '5-5',
            name: '300-1000',
            type: 'c',
            maxValue: '1000',
            minValue: 300
          },
          {
            parentKey: 5,
            parentCode: 'asz',
            parentName: '按市值(亿）',
            prop: 'pb',
            key: '5-6',
            name: '1000以上',
            maxValue: '>',
            minValue: 1000
          },
          {
            parentKey: 5,
            parentCode: 'asz',
            parentName: '按市值(亿）',
            prop: 'pb',
            type: 'custom',
            key: '5-7',
            name: '自定义',
            maxValue: '',
            minValue: ''
          }
        ]
      }]
    const sortTable = ref(null)
    const dataMap = reactive({
      formInline: {
        industryName: '',//行业名称
      },
      factorChildrenList: factorDataList[0].children as any,
      currentCheckValuetion: 'increase',
      selectedIndustry: '',
      rangeCheckKeyList: ['increase'], // 范围筛选的父级key集合
      rangeCheckChildKeyList: [], // 范围筛选的子集的key集合
      rangeCheckList: [],
      industryActive: -1,
      page: {
        totalSize: 0,
        pageSize: 10,
        pageNum: 1,
      },
      page2: {
        totalSize: 0,
        pageSize: 10,
        pageNum: 1,
      },
      sortMap: {
        sortKey: 'peTtm',
        isAsc: true,
      },
      customValue1: '',
      customValue2: '',
      isVaild1: false,
      isVaild2: false,
      isDisabled: true,
    })

    // 删除行业
    const delIndustryTab = () => {
      dataMap.formInline.industryName = ''
      dataMap.selectedIndustry = ''
      dataMap.industryActive = -1
      dataMap.page.pageNum = 1
      dataMap.page2.pageNum = 1
    }

    // 选中范围子集
    const checkChildrenRange = (item: any) => {
      if (item.type === 'custom') return
      const newArr = JSON.parse(JSON.stringify(dataMap.rangeCheckList)).filter((items: any) => items.parentCode !== item.parentCode).concat(item)
      dataMap.rangeCheckList = newArr
      dataMap.rangeCheckKeyList = newArr.map((items: any) => items.parentCode)
      dataMap.rangeCheckChildKeyList = newArr.map((items: any) => items.key)
      const defaultSort = dataMap.rangeCheckList[0]
      dataMap.sortMap = {
        sortKey: defaultSort ? defaultSort['prop'] : '',
        isAsc: true
      }
      dataMap.page.pageNum = 1
      dataMap.page2.pageNum = 1
    }

    // 删除范围子集
    const delChildrenRange = (item: any) => {
      const newArr = JSON.parse(JSON.stringify(dataMap.rangeCheckList)).filter((items: any) => items.parentCode !== item.parentCode)
      dataMap.rangeCheckList = newArr
      dataMap.rangeCheckKeyList = newArr.map((items: any) => items.parentCode)
      dataMap.rangeCheckChildKeyList = newArr.map((items: any) => items.key)
      dataMap.page.pageNum = 1
      dataMap.page2.pageNum = 1
    }

    const saveCustomValue = (item: any) => {
      const newItem = {
        ...item,
        name: `${item.name}:[${dataMap.customValue1},${dataMap.customValue2}]`,
        maxValue: dataMap.customValue2,
        minValue: dataMap.customValue1
      }
      const newArr = JSON.parse(JSON.stringify(dataMap.rangeCheckList)).filter((items: any) => items.parentCode !== item.parentCode).concat(newItem)
      dataMap.rangeCheckList = newArr
      dataMap.rangeCheckKeyList = newArr.map((items: any) => items.parentCode)
      dataMap.rangeCheckChildKeyList = newArr.map((items: any) => items.key)
      const defaultSort = dataMap.rangeCheckList[0]
      dataMap.customValue1 = ''
      dataMap.customValue2 = ''
      dataMap.isDisabled = true
      dataMap.isVaild1 = false
      dataMap.isVaild2 = false
      dataMap.sortMap = {
        sortKey: defaultSort ? defaultSort['prop'] : '',
        isAsc: true
      }
      dataMap.page.pageNum = 1
      dataMap.page2.pageNum = 1
    }

    // 切换估值类型
    const changeFactorType = (item: any) => {
      dataMap.customValue1 = ''
      dataMap.customValue2 = ''
      dataMap.isDisabled = true
      dataMap.isVaild1 = false
      dataMap.isVaild2 = false
      dataMap.currentCheckValuetion = item.code
      dataMap.factorChildrenList = item.children || []
    }

    const getParams = () => {
      // PETTM
      const pettmObj = (dataMap.rangeCheckList || []).find((item: any) => item.parentCode === 'pettm') || {}
      const aszObj = (dataMap.rangeCheckList || []).find((item: any) => item.parentCode === 'asz') || {}
      const turnoverItem = (dataMap.rangeCheckList || []).find((item: any) => item.parentCode === 'turnover') || {}
      const increaseItem = (dataMap.rangeCheckList || []).find((item: any) => item.parentCode === 'increase') || {}

      const params = []
      if( pettmObj['minValue'] || pettmObj['maxValue'] ){
        if( pettmObj['type'] === 'custom' || pettmObj['type'] === 'c' ){
          params.push({
            type: '3',
            symbol: '<=',
            num: pettmObj['maxValue'],
          })
          params.push({
            type: '3',
            symbol: '>=',
            num: pettmObj['minValue'],
          })
        }else{
          params.push({
            type: '3',
            symbol: pettmObj['maxValue'],
            num: pettmObj['minValue'],
          })
        }
      }
      if( aszObj['minValue'] || aszObj['maxValue'] ){
        if( aszObj['type'] === 'custom' || aszObj['type'] === 'c' ){
          params.push({
            type: '4',
            symbol: '<=',
            num: aszObj['maxValue'],
          })
          params.push({
            type: '4',
            symbol: '>=',
            num: aszObj['minValue'],
          })
        }else{
          params.push({
            type: '4',
            symbol: aszObj['maxValue'],
            num: aszObj['minValue'],
          })
        }
      }
      if( turnoverItem['minValue'] || turnoverItem['maxValue'] ){
        if( turnoverItem['type'] === 'custom' || turnoverItem['type'] === 'c' ){
          params.push({
            type: '2',
            symbol: '<=',
            num: turnoverItem['maxValue'],
          })
          params.push({
            type: '2',
            symbol: '>=',
            num: turnoverItem['minValue'],
          })
        }else{
          params.push({
            type: '2',
            symbol: turnoverItem['maxValue'],
            num: turnoverItem['minValue'],
          })
        }
      }
      if( increaseItem['minValue'] || increaseItem['maxValue'] ){
        if( increaseItem['type'] === 'custom' || increaseItem['type'] === 'c' ){
          params.push({
            type: '1',
            symbol: '<=',
            num: increaseItem['maxValue'],
          })
          params.push({
            type: '1',
            symbol: '>=',
            num: increaseItem['minValue'],
          })
        }else{
          params.push({
            type: '1',
            symbol: increaseItem['maxValue'],
            num: increaseItem['minValue'],
          })
        }
      }
      // const params = {
      //   peTtmRange: pettmObj['minValue'] || pettmObj['maxValue'] ? pettmObj['minValue'] + ',' + pettmObj['maxValue'] : '',
      //   marketCapRange: aszObj['minValue'] || aszObj['maxValue'] ? aszObj['minValue'] + ',' + aszObj['maxValue'] : '',
      //   turnoverItem: turnoverItem['minValue'] || turnoverItem['maxValue'] ? turnoverItem['minValue'] + ',' + turnoverItem['maxValue'] : '',
      //   increaseItem: increaseItem['minValue'] || increaseItem['maxValue'] ? increaseItem['minValue'] + ',' + increaseItem['maxValue'] : '',
      // }
      return params
    }

    const changeCustomValue = (e: any, type: String) => {
      const reg = /^(0|([1-9][0-9]*))(\.[\d]{1,2})?$/
      const result = reg.test(e)
      if (type === 'value1') {
        dataMap.isVaild1 = !result
      } else {
        dataMap.isVaild2 = !result
      }
      if (!dataMap.customValue1 && !dataMap.customValue2) {
        dataMap.isDisabled = true
      } else {
        if (dataMap.customValue1 && !dataMap.isVaild1 && dataMap.customValue2 && dataMap.isVaild2) {
          dataMap.isDisabled = true
        } else if (dataMap.customValue1 && dataMap.isVaild1 && dataMap.customValue2 && !dataMap.isVaild2) {
          dataMap.isDisabled = true
        } else if (dataMap.customValue1 && dataMap.isVaild1 && !dataMap.customValue2) {
          dataMap.isDisabled = true
        } else {
          dataMap.isDisabled = false
        }
      }
    }

    // 搜索
    const onSearch = (item: any) => {
      context.emit('search', getParams())
      console.log(getParams(), 'getParams()')
    }

    return {
      ...toRefs(dataMap),
      factorDataList,
      sortTable,
      delChildrenRange,
      delIndustryTab,
      saveCustomValue,
      checkChildrenRange,
      changeFactorType,
      changeCustomValue,
      onSearch
    }
  }
})
</script>
<style lang="scss" scoped>
.synthesize-valuation {

  .factor-item-wrapper {
    display: flex;
    align-items: center;

    .factor-item-title {
      font-size: 14px;
      font-weight: 500;
      color: #000000;
      line-height: 28px;
    }

    .factor-item-data {
      cursor: pointer;
      width: 12%;
      // text-align: center;
      font-size: 14px;
      font-weight: 500;
      color: #666666;
      line-height: 28px;
    }

    .factor-item-data-active {
      color: #44A2E6;
    }
  }

  .factor-value-box {
    margin-top: 15px;
    display: flex;

    .btn {
      width: 110px;
      height: 34px;
      line-height: 34px;
      color: #fff;
      background: #386FF2;
      border-radius: 4px;
      text-align: center;
      margin-left: 60px;
      cursor: pointer;
    }
  }

  .factor-item-value-wrapper {
    // width: 60%;
    display: flex;
    background: #f7f7f7;
    padding: 3px 17px;

    .factor-children-item {
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      color: #666666;
      line-height: 28px;
      margin-right: 40px;
    }

    .factor-children-item:hover {
      color: #44A2E6;
    }

    .factor-children-item-active {
      color: #44A2E6;
    }
  }
}

.filter-criteria {
  margin: 20px 0 0;
  display: flex;
  align-items: center;

  .filter-criteria-title {
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    line-height: 28px;
    margin-right: 20px;
  }

  .filter-criteria-wrapper {
    display: flex;

    .filter-criteria-item {
      margin-right: 20px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 10px;
      background: #f7f7f7;
      border: 1px solid #E9E9E9;
      border-radius: 3px;

      span {
        font-size: 14px;
        color: #666666;
      }

      i {
        font-size: 10px;
        margin-left: 5px;
      }
    }

    .filter-criteria-item:hover {
      background: #F1F9FF;
      border: 1px solid #44A2E6;

      i {
        color: #44A2E6;
      }
    }
  }
}
</style>

