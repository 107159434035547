<template>
  <!-- 定增全部数据 -->
  <div class="table_box">
    <CDate
      title=""
      @change="changeHandle"
      timeKey='custom'
      :initIdx='4'
      ref='cd'
    />
    <SearchForm @search='onSearch' ref='sf' />
    <el-table
      border
      class="table-style-2 cursor"
      v-loading='loading'
      :data="tableData.list || []"
      @row-click='goDetail'
      style="width: 100%"
      :cell-class-name='addClass'
      @sort-change="sortChange"
      :default-sort ="{prop:'date',order:'descending'}"
    >
      <el-table-column prop="valuation" label="名称" width='93' align="center">
        <template #default='{row}'>
          <div>{{row.stockName}}</div>
          <div>{{row.stockCode}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="date" label="定增日期" align="center" width='102' sortable='custom' />
      <!-- <el-table-column prop="fixedType" label="定增类型" align="center">
        <template #default='{row}'>
          <div>{{row.fixedType ?? '-'}}</div>
        </template>
      </el-table-column> -->
      <el-table-column prop="swl1" label="行业" align="center" v-if="boardType === 'swl1'" />
      <el-table-column prop="swl2" label="行业" align="center" v-else />
      <el-table-column prop="incrPart" label="期间涨幅" align="center" sortable="custom" width='102'>
        <template #default='{row}'>
          <div v-dClass='row.incrPart'>{{dataFormat(row.incrPart)}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="incr120" label="120日涨幅" align="center" sortable="custom">
        <template #default='{row}'>
          <div v-dClass='row.incr120'>{{dataFormat(row.incr120)}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="closeApply" label="定增日收盘价" align="center">
        <template #default='{row}'>
          <div>{{ row.closeApply || row.closeApply == 0 ? Number(row.closeApply).toFixed(2) : '-' }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="closeMax" label="期间最高价" align="center" width='93'>
        <template #default='{row}'>
          <div>{{ row.closeMax || row.closeMax == 0 ? Number(row.closeMax).toFixed(2) : '-' }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="closeMin" label="期间最低价" align="center" width='93'>
        <template #default='{row}'>
          <div>{{ row.closeMin || row.closeMin == 0 ? Number(row.closeMin).toFixed(2) : '-' }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="price" label="现价" align="center" width='60' />
      <el-table-column prop="totalMarketCap" label="市值(亿)" align="center" width='80'>
        <template #default='{row}'>
          <div>{{ row.totalMarketCap || row.totalMarketCap == 0 ? bigNumberTransform(row.totalMarketCap) : '-' }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="title" label="定增摘要" align="center">
        <template #default='{row}'>
          <el-popover
            class="c-popover"
            :width="500"
            trigger="hover"
            :content="row.title"
          >
            <template #reference>
              <div class="line2">{{row.title ?? '-' }}</div>
            </template>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="valuation" label="收盘价走势" align="center">
        <template #default='{row}'>
          <ChartE :id="`dz-all-data-${row.index}`" :chartData="row.closeList"  />
        </template>
      </el-table-column>
      <el-table-column prop="afterAnnouncement" label="后续公告" align="center">
        <template #default='{row}'>
          <el-popover
            class="c-popover"
            :width="500"
            trigger="hover"
            :content="row.afterAnnouncement"
          >
            <template #reference>
              <div class="line2">{{row.afterAnnouncement ?? '-' }}</div>
            </template>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <paging-component ref='paging' :showPageSizes='true' :pageSize='50'  :pageData='tableData' @fetchData='fetchData' />
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, toRefs, ref, nextTick } from 'vue'
import pagingComponent from '@/components/pagination/Index.vue'
import SearchForm from './SearchForm.vue'
import CDate from '@/components/new-title/Index.vue'
import ChartE from '../chart/ChartE.vue'
import { getFixedIncrList } from '@/apis/fixed-increase'
import { bigNumberTransform } from '@/utils/data-format'
export default defineComponent({
  props: {
    tableList: {
      type: Array,
      default: []
    },
    textStatus: {
      type: String,
      default: ''
    }
  },
  components: {
    pagingComponent,
    SearchForm,
    ChartE,
    CDate
  },
  setup(props) {
    const sf = ref(null)
    const cd = ref(null)
    const dataMap = reactive({
      loading: true,
      tableData: {},
      boardType: 'swl1'
    })
    let params = {
      // pageNum: '1',
      // pageSize: '10',
      // market: 'ALL',
      // timeUnit: 'WEEKS',
      // num: '1',
      orderBy: 'date'
    }
    const fetchData = async (value: any) => {
      if(value.pageNum){
        params['pageNum'] = value.pageNum
      }
      if(value.pageSize){
        params['pageSize'] = value.pageSize
      }
      if(value.timeUnit){
        params['timeUnit'] = value.timeUnit
      }
      if(value.num){
        params['num'] = value.num
      }
      dataMap.loading = true
      let marginList:any = await getFixedIncrList(params)
      marginList.data.list.map((item:any,idx:any)=>{
        item.index = idx
      })  
      dataMap.tableData = marginList.data;
      dataMap.loading = false
    }
    const sortChange = (column: any) => {
      let isAsc = column.order === "ascending" ? 'ASC' : 'DESC';
      params['orderBy'] = column["prop"]
      params['order'] = isAsc
      fetchData({});
    };
    const addClass = (column:any) => {
      if( column.column.property === params.orderBy ){
        return 'sort-bg'
      }
    }
    const amount = (value: any, fixed = 2, unit = '元') => {
      if (unit == '万') {
        value = value * 10000
      } else if (unit == '亿') {
        value = value * 100000000
      }
      if (Math.abs(value / 100000000) >= 1) {
        return (value / 100000000).toFixed(fixed) + '亿'
      }
      if (Math.abs(value / 10_000) >= 1) {
        return (value / 10000).toFixed(fixed) + '万'
      }
      return value
    }
    const goDetail = (item: any, index: any) => {
      if (index.label === '个股名称/代码') {
        const path = '/marginTrading/shares'
        window.open( `/investment${path}?name=${item.stockName}&code=${item.stockCode}`)
      } else if (index.label === '所属行业') {
        const path = '/marginTrading/industry'
        window.open( `/investment${path}?code=${item.boardName}&boardType=swl2`)
      }
    }

    const changeHandle = (param: Object, isAjax:any) => {
      console.log('vvvvvvvvvv',param,isAjax)
      if( param['timeLevel'] === 0 ){
        delete params['timeUnit']
        delete params['num']
        params['customDate'] = param['dateFrom'] + ':' + param['dateTo']
        if( params['pageNum'] ){
          if( !isAjax ){
            fetchData({})
          }
        }
      }else{
        delete params['customDate']
        params['timeUnit'] = param['timeUnit']
        params['num'] = param['num']
        if( params['pageNum'] ){
          if( !isAjax ){
            fetchData({})
          }
        }
      }
    }

    const onSearch = (param: Object) => {
      params = {
        ...params,
        ...param,
      }
      fetchData({});
    }

    const selectedHy = (time:any, param: Object) => {
      // console.log(999, (cd.value as any).setTime(time))
      nextTick(() => {
        (cd.value as any).setTime(time)
      })
      nextTick(() => {
        (sf.value as any).selectedHy(param)
        dataMap.boardType = param['boardType']
      })
    }

    const selectedSz = (param: any) => {
      (sf.value as any).selectedSz(param)
    }

    const dataFormat = (val: any, character:any) => {
      if( val === null ){
        return '-'
      }
      val = Number(val)
      if( val > 0 ){
        return '+' + val.toFixed(2) + '%'
      }else{
        return val.toFixed(2) + '%'
      }
    }
    
    return {
      ...toRefs(dataMap),
      fetchData,
      sortChange,
      addClass,
      amount,
      goDetail,
      changeHandle,
      dataFormat,
      bigNumberTransform,
      onSearch,
      selectedHy,
      selectedSz,
      sf,
      cd
    }
  }
})
</script>
<style scoped lang="scss">
.table_box {
  margin: 20px 60px 40px 30px;
}
.c-col{
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  &>div{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    &:nth-child(1){
      border-right: 1px solid rgb(235, 238, 245);
    }
  }
}
</style>
