<template>
  <div class="chart_box">
    <div
      :id="id"
      :class="className"
      :style="{height: height, width: width, position: 'retrieve'}"
    />
    <div v-if="chartData.state === 0" class="default_graph" style="width: 100%; line-height: 350px; font-size: 56px; position:absolute; top: 0;left: 0;text-align: center; background: #FFFFFF;opacity: 0.1">
休市
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onActivated,
  onBeforeUnmount,
  onDeactivated,
  onMounted,
  nextTick,
  watch,
} from 'vue'
import resize from '@/components/charts/mixins/resize'
import { init, EChartsOption } from 'echarts'
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '350px',
      required: true
    },
    chartData: {
      type: Object,
      default: {}
    },
    selectedTime: {
      type: String || Number,
      default: ''
    },
  },
  setup(props) {

    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()

    watch(props, (newName, oldName) => {
      initChart()
    });
    const initChart = () => {
      const barChart = init(document.getElementById(props.id) as HTMLDivElement)
      const xAxisList = Array.from(props.chartData.list, ({ time }) => time);
      const yAxisList = Array.from(props.chartData.list, ({ shIndex }) => shIndex);
      const yAxisListData = yAxisList.filter((item) => item !== null);
      const MaxArrpLeft = Math.ceil(Math.max(...yAxisListData));
      const MinArrpLeft = Math.floor(Math.min(...yAxisListData));
      const bx = Array.from(props.chartData.list, ({ bx }) => bx);
      const sz = Array.from(props.chartData.list, ({ sz }) => sz);
      const sh = Array.from(props.chartData.list, ({ sh }) => sh);
      const yAxisListRight = bx.concat(sz).concat(sh)
      const MaxArrpRight = Math.ceil(Math.max(...yAxisListRight));
      const MinArrpRight = Math.floor(Math.min(...yAxisListRight));
      barChart.setOption({
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          top: '30px',
          left: '23px',
          right: '28px',
          bottom: '40px',
          containLabel: true
        },
        color: ['#AEAEAE', '#D0041B', '#0086FB', '#FE9625'],
        legend: {
          icon: 'line',
          itemWidth: 8,  // 设置宽度
          itemHeight: 3, // 设置高度
          x: 'center',
          bottom: '0',
          itemGap: 30,
          textStyle: {
            fontSize: 12,// 字体大小
            color:'#666666',
          },
          data: ['上证指数', '北向资金', '沪股通', '深股通']
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xAxisList,
          axisLabel: {
            margin: 10,
            interval: 100000,
            showMinLabel: true,
            showMaxLabel: true,
            textStyle: {
              color: '#999',
              fontSize: 12,
            }
          },
        },
        yAxis: [
          {
            type: 'value',
            splitLine: {show: false, lineStyle: { type: 'dashed' }},
            position: 'left',
            name: '上证指数',
            // max: MaxArrpLeft + 10,
            min: MinArrpLeft - 10,
            axisPointer: {
              type: 'none',
              label: {show: false}
            },
            axisLabel: {
              show: true,
              align: 'right',
            },
          },
          {
            type: 'value',
            splitLine: {show: true, lineStyle: { type: 'dashed' }},
            position: 'right',
            name: '净买入(亿)',
            // max: MaxArrpRight,
            min: MinArrpRight,
            axisPointer: {
              type: 'none',
              label: { show: false }
            },
            axisLabel: {
              show: true,
              align: 'left'
            }
          }
        ],
        series: [
          {
            name: '上证指数',
            type: 'line',
            symbol: 'none',
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 1.5,
                  color: '#AEAEAE'
                }
              }
            },
            data: yAxisList
          },
          {
            name: '北向资金',
            type: 'line',
            yAxisIndex: 1,
            symbol: 'none',
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 1.5,
                  color: '#D0041B'
                }
              }
            },
            data: bx
          },
          {
            name: '沪股通',
            type: 'line',
            yAxisIndex: 1,
            symbol: 'none',
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 1.5,
                  color: '#0086FB'
                }
              }
            },
            data: sh
          },
          {
            name: '深股通',
            type: 'line',
            yAxisIndex: 1,
            symbol: 'none',
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 1.5,
                  color: '#FE9625'
                }
              }
            },
            data: sz
          },
        ]
      } as EChartsOption)
      chart.value = barChart
    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {}
  }
})
</script>
