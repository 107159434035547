<template>
  <div class="wrap">
    <pageTitle title='北向资金'></pageTitle>
    <!-- 搜索框 -->
    <Search  :isFunds='true' :holder='"请输入个股名称、申万行业名称"' style="margin:22px auto 34px" />
    <div class="chart_box bg">
      <div class="title_box">
        <div class="left">
          <div class="left_box">
            <span>北向资金增持行业板块排行</span>
            <div class="background_box"></div>
          </div>
          <div class="right_box">(截至 {{stockDate}}）</div>
        </div>
        <div class="right">
          <span @click="goShare">去分享>></span>
        </div>
      </div>
      <div class="pl10">
        <div class="tab_box">
          <bar-tab @change="tabClick" :isCustom="true" />
        </div>
        <div class="b-tab-nav" >
          <ul>
            <li :class="chartIdx === 'swl1' ? 'active' : ''" @click="swichChartItem('swl1', '', true)">
              <div>申万一级</div>
            </li>
            <li :class="chartIdx === 'swl2' ? 'active' : ''" @click="swichChartItem('swl2', '', true)">
              <div>申万二级</div>
            </li>
          </ul>
        </div>
        <ranking-chart id="increaseHoldings" v-if="increaseList.data && increaseList.data.length" :chart-data="increaseList" :title-tag="titleTab" :style-status="styleStatus" @chartsClick="chartsClick" />
        <el-empty v-else />
        <div class="radio_box" v-if="increaseList.data && increaseList.data.length">
          <el-radio-group v-model="radio" @change="radioClick">
            <el-radio :label="1">持股市值变化</el-radio>
            <el-radio :label="2">持股占板块比变化</el-radio>
            <el-radio :label="3">持股占北向资金比变化</el-radio>
          </el-radio-group>
        </div>
        <industryTableRanking ref='industryRanking' :boardType='chartIdx' />
      </div>
    </div>

    <div class="bg">
      <div class="title_box">
        <div class="left">
          <div class="left_box">
            <span>北向资金增减持个股排行</span>
            <div class="background_box"></div>
          </div>
          <div class="right_box">(截至 {{stockDate}}）</div>
        </div>
        <div class="right">
          <span @click="goShare">去分享>></span>
        </div>
      </div>
      <div class="pl10">
        <div class="tab_box">
          <bar-tab @change='getGgData' :activeIdx='2' :init='false' :isCustom="true" />
        </div>

        <individualStocksRaking ref='individualRaking' />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import pageTitle from '../components/PageTitle.vue'
import rankingChart from './component/industry-ranking.vue'
import industryTableRanking from './component/Industry-table-ranking.vue'
import individualStocksRaking from './component/individual-stocks-raking.vue'
import barTab from '../components/CustomTab.vue'
import Search from '@/components/search-input/Index.vue'
import { getIncreaseRanking } from '@/apis/northboundFunds'
import { getStockTradeDate } from '@/apis/market'
// import { useRouter } from 'vue-router'

import moment from 'moment'
import { defineComponent, toRefs, reactive, ref, nextTick } from 'vue'
export default defineComponent({
  components: {
    rankingChart,
    industryTableRanking,
    individualStocksRaking,
    pageTitle,
    barTab,
    Search
  },
  setup() {
    // const router = useRouter();
    const individualRaking = ref(null)
    const industryRanking = ref(null)
    const dataMap = reactive({
      titleTab: '',
      stockDate: '',
      radio: 1,
      chartIdx: 'swl1',
      chartName: '',
      styleStatus: false,
      aggregate: {
        boardRatioChange: {}, // 持股占板块比变化
        marketValueChange: {}, // 持股市值变化
        northboundFundsRationChange: {} // 持股占北向资金比变化
      },
      increaseList: {}

    })

    //获取交易日信息 //statisticDate


    const getTime = async () => {
      const data6 = await getStockTradeDate({ })
      dataMap.stockDate = moment((data6 as any).data.prevNbTradeDate).format('YYYY年MM月DD日');
    }
    getTime()

    const radioClick = (item: any) => {
      console.log(dataMap.aggregate)
      dataMap.radio = item;
      if (item === 1) {
        dataMap.increaseList = dataMap.aggregate.marketValueChange
        dataMap.titleTab = '亿'
      } else if (item === 2) {
        dataMap.increaseList = dataMap.aggregate.boardRatioChange
        dataMap.titleTab = ''
      } else if (item === 3) {
        dataMap.increaseList = dataMap.aggregate.northboundFundsRationChange
        dataMap.titleTab = ''
      }
    }
    const fetchData = async (item: any, tabItem: any, boardName: any) => {
      const rankingList = await getIncreaseRanking({ timeLevel: item, boardType: tabItem, boardName })
      if( rankingList ){
        nextTick(() => {
          // console.log('vvvvvvv',(rankingList as any).data)
          radioClick(dataMap.radio)
        })
        dataMap.aggregate = (rankingList as any).data
      }
    }
    const tabClick = (item: any) => {
      fetchData(item.lbValue, dataMap.chartIdx, dataMap.chartName)
      if( industryRanking.value ){
        (industryRanking as any).value.fetchData(item)
      }
    }


    const swichChartItem = (item: any, itemName: any, styleType: any) => {
      dataMap.chartName = ''
      dataMap.chartIdx = item
      if (styleType) {
        dataMap.styleStatus = false
      }
      fetchData('1d', item, itemName || dataMap.chartName)
      nextTick(() => {
        (industryRanking as any).value.fetchData({boardType: item})
      })
    }
    swichChartItem('swl1', '', false)
    const chartsClick = (itemName: any, styleStatus: any) => {
      dataMap.styleStatus = styleStatus
      dataMap.chartName = itemName;
      swichChartItem(dataMap.chartIdx, itemName, false)
    }

    const getGgData = (val: any) => {
      (individualRaking as any).value.timeChange(val)
    }
    const goShare = () => {
      window.open(`/investment/northboundFunds/share-page`)
    }

    return {
      individualRaking,
      industryRanking,
      ...toRefs(dataMap),
      radioClick,
      goShare,
      getGgData,
      tabClick,
      swichChartItem,
      chartsClick
    }

  }
})
</script>

<style scoped lang="scss">
.wrap {
  margin: 0 35px;
  .bg{
    background: #fff;
    margin-bottom: 30px;
    padding: 0 20px 50px;
    .pl10{
      padding-left: 10px;
    }
  }
  .b-tab-nav {
    background: #F1F2F7;
    border-radius: 8px;
    padding: 4px;
    display: inline-block;
    margin: 24px 0;
    ul {
      display: flex;
      li {
        width: 135px;
        text-align: center;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #999ba7;
        padding: 14px 0;
        cursor: pointer;
        &.active {
          color: #333333;
          background: #FFFFFF;
          box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
          border-radius: 8px;
        }
        div:last-child {
          font-size: 18px;
          padding-top: 4px;
        }
      }
    }
  }
  .title_box {
    padding-top: 29px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    > .left {
      display: flex;
      align-items: center;
      > .left_box {
        position: relative;
        margin-right: 10px;
        > span {
          display: block;
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 28px;
        }
        > .background_box {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 80px;
          height: 9px;
          background: linear-gradient(270deg, rgba(56,111,242,0) 0%, #386FF2 100%);
        }
      }
      > .right_box {
        font-size: 17px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 24px;
      }
    }
    > .right {
      > span {
        color: #386FF2;
        display: block;
        cursor: pointer;
      }
    }
  }
  .tab_box {
    text-align: right;
  }
  > .chart_box {
    .radio_box {
      text-align: center;
      margin-top: 19px;
      margin-bottom: 50px;
      ::v-deep .el-radio__input.is-checked+.el-radio__label {
        color: rgb(96, 98, 102);
      }
    }
  }
}
</style>
