
import { defineComponent, reactive, toRefs } from 'vue'
export default defineComponent({
  props: {
    tableList: {
      type: Array,
      default: []
    }

  },
  setup() {
    const defineData = reactive({

    })
    return {
      ...toRefs(defineData)
    }
  }
})
