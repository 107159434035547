

import { defineComponent, reactive, toRefs } from 'vue'
import CTitle from '@/components/title/Index.vue'
import LineChartB from '@/components/charts/LineChartB.vue'
import LineChartC from '@/components/charts/LineChartC.vue'
import LineChartK from '@/components/charts/LineChartK.vue'
import LineChartE from '@/components/charts/LineChartE.vue'
import { getDiagnosticRrendInfo, getMacd, gerRsi } from '@/apis/diagnosis'
import { useRoute } from 'vue-router'
import moment from 'moment';

export default defineComponent({
  props: {
    dataMap: {
      type: Array,
      default: []
    }
  },
  components: {
    CTitle,
    LineChartB,
    LineChartC,
    LineChartK,
    LineChartE
  },
  setup() {
    // const stockCode = '600519.SH'
    const route = useRoute()
    const stockCode = route.query.code
    const dataMap = reactive({
      ajaxRes: false,
      activeIdx: 1,
      difnum: 0,
      deanum: 0,
      macdnum: 0,
      max1: 0,
      min1: 0,
      max2: 0,
      min2: 0,
      rsi1: 0,
      rsi2: 0,
      rsi3: 0,
      stockList1: {},
      timeList: [],
      macdList: [],
      rsiList: []
    })
    
    const fetchData = async () => {
      const data1 = await getDiagnosticRrendInfo({ stockCode })
      dataMap.stockList1 = (data1 as any).data;
      
     
      dataMap.ajaxRes = true;

      const data2 = await getMacd({ code: stockCode, num: 500 })
      dataMap.macdList = (data2 as any).data;
      if( dataMap.macdList ){
        let list = (dataMap.macdList as any);
        dataMap.difnum = list[list.length-1].dif
        dataMap.deanum = list[list.length-1].dea
        dataMap.macdnum = list[list.length-1].macd
        let max = 0,min = 0
        for (let index = 0; index < list.length; index++) {
          const element = list[index]
          if(index==0){
            max = Number(element.dif)
            min = Number(element.dif)
          }else{
            if( max < Number(element.dif) ){
              max= Number(element.dif)
            }
            if(min > Number(element.dif) ){
              max= Number(element.dif)
            }
          }
          dataMap.max1 = max
          dataMap.min1 = min
        }
      }

      const data3 = await gerRsi({ code: stockCode, num: 500 })
      dataMap.rsiList = (data3 as any).data;
      if( dataMap.rsiList ){
        let list = (dataMap.rsiList as any);
        let max2 = 0, min2 = 0
        for (let index = 0; index < list.length; index++) {
          const element = list[index]
          if(index==0){
            max2 = Number(element.rsi1)
            min2 = Number(element.rsi1)
          }else{
            if(max2<element.rsi1*1){
              max2 = element.rsi1*1
            }
            if(min2>element.rsi1*1){
              min2 = element.rsi1*1
            }
          }
          if( index === list.length-1 ){
            dataMap.rsi1 = element.rsi1
            dataMap.rsi2 = element.rsi2
            dataMap.rsi3 = element.rsi3
          }
          dataMap.max2 = max2
          dataMap.min2 = min2
        }
      }
    }
    fetchData()

    const getRsiVal = (res:any) => {
      dataMap.rsi1 = res[0]
      dataMap.rsi2 = res[1]
      dataMap.rsi3 = res[2]
    }
    const getMacdVal = (res:any) => {
      console.log(res)
      dataMap.difnum = res[0]
      dataMap.deanum = res[1]
      dataMap.macdnum = res[2]
    }
    const getTimeList = (res:any) => {
      dataMap.timeList = res
    }

    
    return {
      ...toRefs(dataMap),
      getRsiVal,
      getMacdVal,
      getTimeList,
      moment
    }
  }
})
