
import { defineComponent } from 'vue'
import { stockFormat } from '@/utils/data-format'

export default defineComponent({
  props: {
    item: {
      type: Object,
      default: {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const goDataCenter = () => {
      if( props.disabled ){
        return false
      }
      const row = props.item
      window.open(`/page/data_center_all?industryName=${row['boardName']}&boardType=${row['boardType']}`)
    }

    return {
      stockFormat,
      goDataCenter
    }
  }
})
