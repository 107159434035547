<template>
<div class="financial_reporting_details">
  <page-title title="成长能力-归母净利润" sub-title="-个股明细" />
  <div class="content_box">
    <div class="tit">
      <div class="t"><span>{{ titleName }}</span></div>
      <div><span style="display: block; margin-left: 10px;">{{ stockCodeName }}</span></div>
      <div><span style="display: block; margin-left: 10px;">(截至 {{stockDate}}）</span></div>
    </div>
    <nav-tab :tab-list="tabList" @changeTab="changeTab" />
    <year-charts v-if="type === 1 && financialData.quarterData" id="year" :chart-data="financialData" />
    <quarter-charts v-if="type === 2" id="quarter" :chart-data="financialData" />
    <div class="line_box"></div>
    <table-component :table-list="financialData" />
    <quarter-table :table-list="reportData" />
  </div>
</div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue'
import { useRoute } from "vue-router"
import { getFinancialDetailed, getFinancialReport } from '@/apis/financial'
import quarterCharts from './component/quarter-charts.vue'
import yearCharts from './component/year-charts.vue'
import pageTitle from '../components/PageTitle.vue'
import navTab from '../components/nav-switch.vue'
import tableComponent from './component/table.vue'
import quarterTable from './component/quarter-table.vue'
import { getStockTradeDate } from '@/apis/market'
import moment from 'moment'
export default defineComponent({
  components: {
    pageTitle,
    navTab,
    quarterCharts,
    yearCharts,
    tableComponent,
    quarterTable
  },
  setup() {
    const route = useRoute()
    const defineData = reactive({
      stockDate: '',
      titleName: route.query.stockName,
      stockCodeName: route.query.stockCode,
      tabList: [
        {
          id: 1,
          name: '按业绩增速'
        },
        {
          id: 2,
          name: '按归母净利润'
        }
      ],
      financialData: {},
      type: 1,
      reportData: []
    })
    const fetchData = async () => {
      const list = await getFinancialDetailed({ stockCode: route.query.stockCode })
      if ((list as any).code === 200) {
        defineData.financialData = (list as any).data
      }

    }
    const fetchList = async () => {
      const listReport = await getFinancialReport({ stockCode: route.query.stockCode })
      if ( listReport && (listReport as any).code === 200 ) {
        defineData.reportData = (listReport as any).data
      }
    }
    fetchData()
    fetchList()
    const changeTab = (item: any) =>  {
      defineData.type = item.id
    }
    const getTime = async () => {
      const data = await getStockTradeDate({ })
      if( (data as any).data.timestamp ){
        defineData.stockDate = moment((data as any).data.timestamp).format('YYYY年MM月DD日');
      }
    }
    getTime()
    return {
      ...toRefs(defineData),
      changeTab
    }
  }
})
</script>

<style scoped lang="scss">
.financial_reporting_details {
  padding: 21px 35px 45px 35px;
  position: relative;
  > .content_box {
    padding-bottom: 85px;
    background: #fff;
    position: relative;
    .tit {
      display: flex;
      align-items: flex-end;
      margin-left: 20px;
      padding-top: 29px;
      .t {
        font-size: 20px;
        font-weight: 500;
        color: #333333;
        position: relative;
        span{
          position: relative;
          z-index: 2;
        }
        &::after {
          content: "";
          width: 80px;
          height: 9px;
          background: linear-gradient(
              270deg,
              rgba(56, 111, 242, 0) 0%,
              #386ff2 100%
          );
          z-index: 1;
          position: absolute;
          left: 0;
          bottom: -3px;
        }
      }
      .update-time {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        margin-left: 5px;
      }
    }
    > .line_box {
      width: 90%;
      border: 1px solid #E8E8E8;
      position: absolute;
      top: 415px;
      left: 5%;

    }
  }
}
</style>
