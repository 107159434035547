<!--
 * @Description:定增
-->
<template>
  <div class="app-container">
    <PageTitle title='成长能力-归母净利润' />
    <!-- 搜索框 -->
    <Search  :isFinancial='true' :holder='"请输入股票代码或简称"' style="margin:22px auto 34px" />
    <div class="bg">
      <div class="tit">
        <div class="t"><span>今日财报披露数据</span></div>
        <div class="update-time" v-if="stockDate">(截至 {{stockDate}}）</div>
      </div>
      <DayTable />
    </div>
    <div class="bg">
      <div class="tit">
        <div class="t"><span>历史财报披露数据</span></div>
        <div class="update-time" v-if="stockDate">(截至 {{stockDate}}）</div>
      </div>
      <HistoryTable />
    </div>
  </div>
</template>

<script lang="ts">

import { defineComponent, reactive, toRefs, ref } from 'vue'
import PageTitle from '../components/PageTitle.vue'
import Search from '@/components/search-input/Index.vue'
import DayTable from './components/DayTable.vue'
import HistoryTable from './components/HistoryTable.vue'
import { getStockTradeDate } from '@/apis/market'
import moment from 'moment'

export default defineComponent({
  components: {
    PageTitle,
    Search,
    DayTable,
    HistoryTable
  },
  setup() {
    
    const dataMap = reactive({
      stockDate: '',
      
    })
    const getTime = async () => {
      const data = await getStockTradeDate({ })
      if( (data as any).data.timestamp ){
        dataMap.stockDate = moment((data as any).data.timestamp).format('YYYY年MM月DD日');
      }
    }
    getTime()
    return { ...toRefs(dataMap) }
  }
})
</script>
<style scoped lang='scss'>
.bg{
  background: #fff;
  padding: 38px 32px 54px;
  margin-bottom: 30px;
  .date-box{
    margin-right: 60px;
  }
  .chart-box-a{
    padding: 60px 58px 50px
  }
  .chart-box-d{
    padding: 0 60px
  }
  .tit {
    display: flex;
    align-items: flex-end;
    margin-bottom: 30px;
    .t {
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      position: relative;
      span{
        position: relative;
        z-index: 2;
      }
      &::after {
        content: "";
        width: 80px;
        height: 9px;
        background: linear-gradient(
          270deg,
          rgba(56, 111, 242, 0) 0%,
          #386ff2 100%
        );
        z-index: 1;
        position: absolute;
        left: 0;
        bottom: -3px;
      }
    }
    .update-time {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      margin-left: 5px;
    }
  }
}
</style>
