<template>
  <div
    :id="id"
    :class="className"
    :style="{height: height, width: width}"
  />
</template>

<script lang="ts">
import { defineComponent, onActivated, onBeforeUnmount, onDeactivated, onMounted, nextTick, watch } from 'vue'
import resize from './mixins/resize'
import { init, EChartsOption } from 'echarts'
import * as echarts from 'echarts'
export default defineComponent({
  props: {
    chartData: {
      type: Object,
      default: {}
    },
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '193px',
      required: true
    },
    activeAnalysisIdx: {
      type: Number,
      default: 0
    },
    rate1: {
      type: Object,
      default: []
    },
    rate2: {
      type: Object,
      default: []
    },
    
  },
  setup(props,context) {
    watch(props, (newName, oldName) => {
      initChart()
    });

    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()
    const initChart = () => {
      const map = props.chartData;
      if( !Object.keys(map).length ){
        return false;
      }

      const barChart = init(document.getElementById(props.id) as HTMLDivElement)
      let series = props.chartData;
      let xdata:any = [],seriesData:any = [[], []]
      let categoryName = []

      categoryName.push(series[0].board2Name);
      categoryName.push(series[0].name);

      for (let index = 0; index < series.length; index++) {
        const item = series[index];
        xdata.push(item['date'])
        seriesData[0].push(item[props.rate1[props.activeAnalysisIdx]])
        seriesData[1].push(item[props.rate2[props.activeAnalysisIdx]])
      }
      barChart.setOption({
        //  backgroundColor: "#ffffff",
        legend:{
          left:0,
          top:0,
          icon:'circle'
        },
        grid: {
          containLabel: true,
          bottom:10,
          top:40,
          left:20,
          right:20
        },
        tooltip: {
          show: true,
          trigger: 'axis', 
          // position:'inside',
          textStyle:{
            color:'#999999',
            fontSize:'10',
          },
          position:function(point:any,params:any,dom:any,rect:any){
            let x = 0
            let y= 0
            if(point[0]>210){
              x = point[0] - 100
            }else{
              x = point[0] +10
            }
            if(point[1]>55){
              y = point[1] - 60
            }else{
              y = point[1]
            }
            return [x,y]
          },
          formatter:function(param:any){
            // console.log(param)
           // 利用循环更改显示的内容
            let list = param.length
            let txt ="<div>"+ param[0].name+"</div><br/>"
            for (var i=0;i<list;i++){
             if(i==list-1){
              txt += '<div style="padding-bottom:5px">' + param[i].marker+ param[i].seriesName + "：" + param[i].data.toFixed(2) +"%</div>"
             }else{
               txt += '<div style="padding-bottom:10px">' + param[i].marker+ param[i].seriesName + "：" + param[i].data.toFixed(2) +"%</div>"
             }
            }
            return txt
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xdata,
          show:  false,
          axisLine:{
            lineStyle:{
              color: '#BFC0C4',
              width:1,
            }
          },
          axisLabel:{
            // interval:Math.floor(list1.length/3),
            formatter:function(val:any){      
              return ''
            },
            margin:20,
            textStyle:{
              
            }
          }
        },
        yAxis: {
           x: 'center',
            type: 'value',
            splitNumber:3,
            show:false,
            splitLine: {
      
              lineStyle: {
           
                // type: 'dashed'
                color:'#141518'
              }
            },
            axisLabel:{
              formatter: '{value} %',
            },
          // min:min,
          // max:max,
          // interval:interval,
          // show: false
        },
        color: ['#3B7FEE', '#FE9625'],
        series: [{
          name: categoryName[1],
          type: 'line',
          smooth: false,
          symbol:'circle',
          showSymbol:true,
          symbolSize: 10,
          label : {
            show:true,
            color: '#3B7FEE',
            // color:colorarr1[zz],
            formatter:function(val:any){      
              console.log('ddd', val)           
              return val.value.toFixed(2)*1 +'%'
            }
          },
          itemStyle:{
            normal:{
              lineStyle:{
                width: 1
                // color: '#3B7FEE'
                // color:seriesData,
              },
            }
          },
          data: seriesData[1]
        },{
          name: categoryName[0],
          type: 'line',
          smooth: false,
          symbol:'circle',
          showSymbol:true,
          symbolSize: 10,
          // label : {
          //   show:true,
          //   // color:colorarr1[zz],
          //   formatter:function(val:any){      
          //     console.log('ddd', val)           
          //     return val.value.toFixed(2)*1 +'%'
          //   }
          // },
          itemStyle:{
            normal:{
              lineStyle:{
                width: 1
                // color: '#FE9625'
              },
            }
          },
          data: seriesData[0]
        }]
      } as unknown as EChartsOption)
      chart.value = barChart
    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {

    }
  }
})
</script>

<style lang="scss" scoped>

</style>
