<template>
  <div class="tab_box">
    <ul>
      <li :class="initialStatus === item.id ? 'active' : ''" v-for="item in tabList" :key="item.id" @click="tabClick(item)">{{ item.name }}</li>
    </ul>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue'
export default defineComponent({
  props: {
    tabList: {
      type: Array,
      default: []
    },
    initialType: {
      type: Number || String,
      default: ''
    }
  },
  setup(props, context) {
    const dataMap = reactive({
      initialStatus: (props.initialType as any)
    })
    const tabClick = (item: any) => {
      dataMap.initialStatus = item.id;
      context.emit('changeTab', item)
    }
    return {
      ...toRefs(dataMap),
      tabClick
    }
  }
})
</script>
<style scoped lang="scss">
.tab_box {
  background: #F1F2F7;
  border-radius: 8px;
  padding: 3px;
  display: inline-block;
  margin: 32px 0 20px 0;
  > ul {
    display: flex;
    > li {
      font-size: 15px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #999BA7;
      line-height: 21px;
      padding: 14px 36px;
      cursor: pointer;
      &.active {
        background: #FFFFFF;
        box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.1);
        border-radius: 8px;
        font-size: 15px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 21px;
      }
    }
  }
}
</style>
