<template>
    <div
      :id="id"
      :class="className"
      :style="{height: height, width: width}"
    />
</template>

<script lang="ts">
import {
  defineComponent,
  onActivated,
  onBeforeUnmount,
  onDeactivated,
  onMounted,
  nextTick,
  watch,
} from 'vue'
import resize from '@/components/charts/mixins/resize'
import { init, EChartsOption } from 'echarts'
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '60px',
      required: true
    },
    chartData: {
      type: Object,
      default: {}
    },
    selectedTime: {
      type: String || Number,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    yMax: {
      type: Number,
      default: 0
    },
    yMin: {
      type: Number,
      default: 0
    }
  },
  setup(props) {

    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()

    watch(props, (newName, oldName) => {
      initChart()
    });
    const initChart = () => {
      const barChart = init(document.getElementById(props.id) as HTMLDivElement)
      const series = props.chartData.map((item:any)=>{
        return {
          value: item,
          itemStyle: {
            color: item > 0 ? '#F74E4F' : '#5CBC7C'
          }
        }
      })
      barChart.setOption({
        tooltip: {
          trigger: 'axis',
          formatter:function(params: any) {
            let text = '';
            for (let i = 0; i < params.length; i++) {
              const item = params[i]
              text += `<div style="display: flex; justify-content: space-between">
                      <div style="display: flex;align-items: center">
                        <span style='width:8px;height:8px;display:inline-block;border-radius:50%;margin-right:3px;background: ${item.color}'></span>
                        <span style='margin-left: 5px;display:inline-block;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;line-height: 17px;'>${amount(item.value)}</span>
                        </div>
                    </div>`
            }
            return text
          }
        },
        grid: {
          top: '3px',
          left: '30px',
          right: '28px',
          bottom: '5px',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: props.chartData.map(()=>''),
          axisLine: { show: false, onZero: true, lineStyle: { type: 'dashed' } },
          splitLine: { show: false },
          axisTick: { show: false },
          splitArea: { show: false },
          axisLabel: {
            show: false,
            textStyle: {
              color: '#999',
              fontSize: 12,
            }
          },
        },
        yAxis: [
          {
            type: 'value',
            splitLine: { show: false },
            offset: 90,
            position: 'left',
            scale: true,
            axisPointer: {
              type: 'none',
              label: {show: false}
            },
            axisLabel: {
              show: false,
              align: 'right',
            },
            max: props.yMax,
            min: props.yMin,
          }
        ],
        series: [
          {
            type: 'bar',
            symbol: 'circle',
            // symbolSize: 5,
            barMaxWidth: 15,
            barMinHeight: 4,
            // itemStyle: {
            //   normal: {
            //     lineStyle: {
            //       width: 1,
            //       color: '#3B7FEE'
            //     }
            //   }
            // },
            data: series
          }
        ]
      } as EChartsOption)
      chart.value = barChart
    }

    const amount = (value: any, fixed = 2, unit = '元') => {
      if (unit == '万') {
        value = value * 10000
      } else if (unit == '亿') {
        value = value * 100000000
      }
      if (Math.abs(value / 100000000) >= 1) {
        return (value / 100000000).toFixed(fixed) + '亿'
      }
      if (Math.abs(value / 10_000) >= 1) {
        return (value / 10000).toFixed(fixed) + '万'
      }
      return value
    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {}
  }
})
</script>
