<template>
  <div class="agency-rating-container">
    <div v-if="$route.path === '/research/detail'">
      <CTitle
        :mode="6"
        title="个股机构调研排行"
      />
      <CSelect @change="changeHandle" right type='month'></CSelect>
    </div>
    <div
      v-else
      class="t-title"
      style="padding-top:67px"
    >
      <CTitle
        :mode="4"
        title="个股机构调研排行"
        @change='changeHandle'
        :viceTitle="'更新日期：'+$moment().format('YYYY年MM月DD日')"
      />
    </div>
      <el-table
        class="table-style-2 cursor"
        :data="stockList5"
        border
        style="width: 100%"
        @sort-change="sortChange"
        @row-click='goDetail'
        :default-sort ="{prop:'researchCount',order:'descending'}"
      >
        <el-table-column
          prop="rankingNo"
          align="center"
          label="排名"
          width='50'
        />
        <el-table-column
          prop="code"
          align="center"
          label="代码"
        />
        <el-table-column
          prop="name"
          align="center"
          label="股票名称"
        />
        <el-table-column
          prop="researchCount"
          align="center"
          label="调研总量"
          sortable="custom"
        />
        <el-table-column
          prop="orgResearchCount"
          align="center"
          label="机构调研"
          sortable="custom"
        />
        <el-table-column
          prop="orgResearchRatio"
          align="center"
          label="占比"
          sortable="custom"
        >
          <template #default='{row}'>
            {{row.orgResearchRatio}}%
          </template>
        </el-table-column>
        <el-table-column
          prop="focusOrgResearchCount"
          align="center"
          label="重点机构调研"
          sortable="custom"
        />
        <el-table-column
          prop="focusOrgResearchRatio"
          align="center"
          label="占比"
          sortable="custom"
        >
          <template #default='{row}'>
            {{row.focusOrgResearchRatio}}%
          </template>
        </el-table-column>
        <el-table-column
          prop="day5Change"
          align="center"
          label="5日涨跌幅"
          sortable="custom"
        >
          <template #default='{row}'>
            {{row.day5Change}}%
          </template>
        </el-table-column>
        <el-table-column
          prop="day10Change"
          align="center"
          label="10日涨跌幅"
          sortable="custom"
        >
          <template #default='{row}'>
            {{row.day10Change}}%
          </template>
        </el-table-column>
        <el-table-column
          prop="day20Change"
          align="center"
          label="20日涨跌幅"
          sortable="custom"
        >
          <template #default='{row}'>
            {{row.day20Change}}%
          </template>
        </el-table-column>
        <el-table-column
          prop="day60Change"
          align="center"
          label="60日涨跌幅"
          sortable="custom"
        >
          <template #default='{row}'>
            {{row.day60Change}}%
          </template>
        </el-table-column>
      </el-table>
  </div>
</template>

<script lang="ts">

import { defineComponent, reactive, toRefs } from 'vue'
import CTitle from '@/components/title/Index.vue'
import CSelect from "@/components/select/Index.vue"
import { getSurveyRecordsList } from '@/apis/home'
import { getSurveyIndustryCountRecords } from '@/apis/research'
import { useRoute  } from "vue-router";

export default defineComponent({
  props: {
    dataMap: {
      type: Array,
      default: []
    },
    title: {
      type: String,
      default: '机构评级'
    }
  },
  components: {
    CTitle,
    CSelect
  },
  setup() {
    // const stockCode = '002821.SZ'
    const route = useRoute();
    const stockCode = route.query.code
    const dataMap = reactive({
      ajaxRes: false,
      stockList5:[],
    })
    let selectedTime = ''


    let tableSort = {
      stockCode: stockCode,
      timeLevel: '',
      sortKey: 'researchCount',
      isAsc: false,
    }
    const changeHandle = async (val:any, sortKey:any, isAsc:any) => {
      if(val){tableSort.timeLevel = val.timeLevel;selectedTime = val.value}
      if(sortKey !== undefined){tableSort.sortKey = sortKey}
      if(isAsc !== undefined){tableSort.isAsc = isAsc}
      console.log('route.path', route.path)
      if( route.path === '/research/detail' ){
        const data4 = await getSurveyIndustryCountRecords({...tableSort})
        dataMap.stockList5 = (data4 as any).data;
      }else{
        const data4 = await getSurveyRecordsList({...tableSort})
        dataMap.stockList5 = (data4 as any).data;
      }
    }

    const sortChange = (column:any) => {
      // console.log(timeLevel)
      let isAsc = column.order === "ascending" ? true : false
      changeHandle({timeLevel: tableSort.timeLevel}, column['prop'], isAsc)
    }

    /**
     * 跳转详情页面
     */
    const goDetail = (item:any) => {
      window.open(`/investment/research/detail?code=${item.code}&time=${selectedTime}`)
      // window['$props'].push({
      //   path: `/investment/research/detail`,
      //   query:{
      //     code: item.code,
      //     time: selectedTime
      //   }
      // })
    }
    // fetchData(keyTimeMap[0]);
    return {
      ...toRefs(dataMap),
      goDetail,
      changeHandle,
      sortChange,
    }
  }
})
</script>
<style scoped lang='scss'>

</style>
