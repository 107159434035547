<template>
  <div class="app-container">
    <breadcrumb @getStockInfo='getStockInfo'></breadcrumb>
    <Search open></Search>
    <div class="bg mt25">
      <CTitle :mode='10' :title="stockInfo.name" :code="stockInfo.code"></CTitle>
      <div class="pettm-daily mt25">
        <CSelect @change="changePettmHandle" type='year' :defultValue="defultValue"></CSelect>
        <LineChartO id='chart-analysis-pettm-daily' :chartData='stockList8' />
      </div>
      <div class="fx mt25" >
        <CTitle :mode='6' title="估值分析" :sixIcon='true' @changeTips='dialogVisible2 = true'></CTitle>
        <CSelect @change="changeHandle" type='year' :defultValue="defultValue"></CSelect>
      </div>
      <div class="b-tab-nav" v-if="ajaxRes">
        <ul>
          <li :class="activeAnalysisIdx === 0 ? 'active' : ''" @click="swichAnalysisItem(0)">
            <div>综合估值</div>
            <div>{{stockList3.valuationDesc}}</div>
          </li>
          <li :class="activeAnalysisIdx === 1 ? 'active' : ''" @click="swichAnalysisItem(1)">
            <div>市盈率</div>
            <div>
              <span v-if="stockList3.pe >= 0">{{stockList3.pe.toFixed(2)}}</span>
              <span v-else>（亏损）</span>
            </div>
          </li>
          <li :class="activeAnalysisIdx === 2 ? 'active' : ''" @click="swichAnalysisItem(2)">
            <div>市净率</div>
            <div>
              <span v-if="stockList3.pb >= 0">{{stockList3.pb.toFixed(2)}}</span>
              <span v-else>（--）</span>
            </div>
          </li>
          <li :class="activeAnalysisIdx === 3 ? 'active' : ''" @click="swichAnalysisItem(3)">
            <div>市销率</div>
            <div>
              <span v-if="stockList3.ps >= 0">{{stockList3.ps.toFixed(2)}}</span>
              <span v-else>（--）</span>
            </div>
          </li>
          <li :class="activeAnalysisIdx === 4 ? 'active' : ''" @click="swichAnalysisItem(4)">
            <div>市现率</div>
            <div>
              <span v-if="stockList3.pcf >= 0">{{stockList3.pcf.toFixed(2)}}</span>
              <span v-else>（--）</span>
            </div>
          </li>
        </ul>
      </div>

      <div class="analysis-container">
        <div class="analysis" v-if="activeAnalysisIdx === 0">
          <div class="left">
            <div class="analysis-title">
              <div class="analysis-title-left">
                公司最新估值分位 <span class="red">{{stockList3.valuation}}%</span> ，估值<span class="red">{{stockList3.valuationDesc}}</span>
              </div>
              <div class="analysis-ti tle-right">
              </div>
            </div>
            <div class="analysis-left-date">
              <LineChartF id='chart-analysis-left' :chartData='stockList4' v-if="ajaxRes" ></LineChartF>
              <div class="content_item_timebox">
                <div class="content_item_timebox_item" v-if="stockList4[0]">{{stockList4[0].date}}</div>
                <div class="content_item_timebox_item" v-if="stockList4.length">{{stockList4[stockList4.length-1].date}}</div>
              </div>
            </div>
          </div>
          <div class="right"></div>
        </div>
        <div class="analysis-right" v-else-if='stockList5'>
          <div class="flex">
            <p v-if="activeAnalysisIdx === 1">{{stockList5.peContent}}</p>
            <p v-if="activeAnalysisIdx === 2">{{stockList5.pbContent}}</p>
            <p v-if="activeAnalysisIdx === 3">{{stockList5.psContent}}</p>
            <p v-if="activeAnalysisIdx === 4">{{stockList5.pcfContent}}</p>
          </div>
          <div class="content_item_trend_content">
            <div class="content_item_trend_content_line">
              <div class="trend_content_legend">
                <ul>
                  <li><span></span> 高估：{{stockList5ChartData.max}}</li>
                  <li><span></span> 中值：{{stockList5ChartData.mid}}</li>
                  <li><span></span> 低估：{{stockList5ChartData.min}}</li>
                </ul>
              </div>
              <div class="his" v-if="stockList5ChartData.data && stockList5ChartData.data.length">
                <LineChartD id='chart-analysis-right'  :chartData='stockList5ChartData'></LineChartD>
                <div class="content_item_timebox">
                  <view class="content_item_timebox_item">{{stockList5ChartData.data[0].x}}</view>
                  <view class="content_item_timebox_item">{{stockList5ChartData.data[stockList5ChartData.data.length-1].x}}</view>
                </div>
              </div>

            </div>
          </div>
        </div>

        <el-table 
          class="table-style-2 first-active" 
          :data="stockList1" 
          border 
          align="center" 
          :default-sort ="{prop:'pe',order:'descending'}"
          @sort-change="sortChange2">
          <el-table-column label="排名" prop="rankingNo" width="90" align="center">
          </el-table-column>
          <el-table-column prop="name" label="股票名称" align="center" />
          <el-table-column prop="pe" label="市盈率" align="center" sortable="custom">
            <template #default="{row}">
              {{row.pe.toFixed(2)}}
            </template>
          </el-table-column>
          <el-table-column prop="pb" label="市净率" sortable="custom" align="center">
            <template #default="{row}">
              {{row.pb.toFixed(2)}}
            </template>
          </el-table-column>
          <el-table-column prop="ps" label="市销率" sortable="custom" align="center">
            <template #default="{row}">
              {{row.ps.toFixed(2)}}
            </template>
          </el-table-column>
          <el-table-column prop="pcf" label="市现率" width="90" sortable="custom" align="center">
            <template #default="{row}">
              {{row.pcf.toFixed(2)}}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div class="bg pe" v-if="stockList2">
      <div class="pe-top">
        <CTitle :mode='6' title="PE/PB Band"></CTitle>
        <CSelect @change="changePePdHandle" type='year' :defultValue="defultValue"></CSelect>
      </div>
      
      <!-- <div class="tab">
        <ul>
          <li :class="activeRate === index ? 'active' : ''" v-for="(item,index) in profitTypearr" :key="index" @click="tabRate(index)">{{item}}</li>
        </ul>
      </div> -->
      <div class="b-tab-nav b-tab-nav2">
        <ul>
          <li :class="activeRate === index ? 'active' : ''" v-for="(item,index) in profitTypearr" :key="index" @click="tabRate(index)">{{item}}</li>
        </ul>
      </div>
      <!-- <div class="b-tab-nav b-tab-nav2" v-if="ajaxRes">
        <ul>
          <li :class="activeZbIdx === 0 ? 'active' : ''" @click="swichZbItem(0)">
            <div>ROE</div>
            <div v-if="stockList6.length">{{stockList6[stockList6.length-1].roe.toFixed(2)}}%</div>
          </li>
          <li :class="activeZbIdx === 1 ? 'active' : ''" @click="swichZbItem(1)">
            <div>ROIC</div>
            <div v-if="stockList6.length">{{stockList6[stockList6.length-1].roic.toFixed(2)}}%</div>
          </li>
          <li :class="activeZbIdx === 2 ? 'active' : ''" @click="swichZbItem(2)">
            <div>ROA</div>
            <div v-if="stockList6.length">{{stockList6[stockList6.length-1].roa.toFixed(2)}}%</div>
          </li>
        </ul>
        </div> -->

      <div class="chart-box">
        <!-- <div class="content_item_mechanism_line">
          <div class="content_item_mechanism_line_header">
            <div class="content_item_mechanism_line_header_item">
              <div class="content_item_mechanism_line_header_item_icon"></div>
              <div class="content_item_mechanism_line_header_item_text">股价</div>
            </div>
            <div class="content_item_mechanism_line_header_item" v-for="(item,idx) in stockList2[rate[activeRate]]" :key='idx'>
              <div class="content_item_mechanism_line_header_item_icon"></div>
              <div class="content_item_mechanism_line_header_item_text">{{item}}</div>
            </div>
          </div>
          <CSelect @change="changePePdHandle"  type='year'></CSelect>
        </div> -->
        <!-- <CSelect @change="changePePdHandle" type='year'></CSelect> -->
        <!-- {{stockList2}}33333 -->
        <LineChartL id='PE-PB' width='100%' :chartData='stockList2' :activeRate='activeRate' :legend='stockList2[rate[activeRate]]' @getpepdTime='getpepdTime' ></LineChartL>
        <div class="content_item_timebox content_item_timebox2">
          <div class="content_item_timebox_item">{{pepdTime[0]}}</div>
          <div class="content_item_timebox_item">{{pepdTime[1]}}</div>
        </div>
      </div>
    </div>

    <div class="bg" v-if="stockList6.length || stockList7.length">
      <CTitle :mode='6' title="资本回报率" :sixIcon='true' @changeTips='dialogVisible1 = true'></CTitle>
      <div v-if="stockList6.length">
        <div class="b-tab-nav b-tab-nav2 b-tab-nav3" v-if="ajaxRes">
          <ul>
            <li :class="activeZbIdx === 0 ? 'active' : ''" @click="swichZbItem(0)">
              <div>ROE</div>
              <div v-if="stockList6.length">{{stockList6[stockList6.length-1].roe.toFixed(2)}}%</div>
            </li>
            <li :class="activeZbIdx === 1 ? 'active' : ''" @click="swichZbItem(1)">
              <div>ROIC</div>
              <div v-if="stockList6.length">{{stockList6[stockList6.length-1].roic.toFixed(2)}}%</div>
            </li>
            <li :class="activeZbIdx === 2 ? 'active' : ''" @click="swichZbItem(2)">
              <div>ROA</div>
              <div v-if="stockList6.length">{{stockList6[stockList6.length-1].roa.toFixed(2)}}%</div>
            </li>
          </ul>
        </div>
        <div class="hb">
          <LineChartM :chartData='stockList6' :activeAnalysisIdx='activeZbIdx' :rate1='rate1' :rate2='rate2' :width='"100%"'></LineChartM>
          <div class="content_item_profit_table">
            <div class="content_item_profit_table_header">
              <div class="content_item_profit_table_header_item" v-for="(item,index) in stockList6" :key="index">{{item.dateLabel}}年</div>
            </div>
            <div class="content_item_profit_table_header" v-for="(item,idx) in 2" :key="idx">
              <div class="content_item_profit_table_header_item" v-for="(item,index) in stockList6" :key="index">
                {{idx === 0 ? item[rate2[activeZbIdx]].toFixed(2) : item[rate1[activeZbIdx]].toFixed(2)}}%
              </div>
            </div>
          </div>
        </div>
      </div>

      <el-table 
        v-if="stockList7.length" 
        class="table-style-2 first-active" 
        border 
        align="center" 
        :data="stockList7" 
        @sort-change="sortChange" 
        :default-sort ="{prop:'roe-'+yearKey[0],order:'descending'}"
        :style="{marginTop:stockList6.length ? '60px' : '20px'}">
        <el-table-column label="排名" align="center">
          <!-- 占位 -->
          <el-table-column prop="rankingNo" align="center" />
        </el-table-column>
        <el-table-column label="股票名称" align="center">
          <!-- 占位 -->
          <el-table-column prop="name" align="center" />
        </el-table-column>
        <el-table-column label="ROE（%）" align="center">
          <el-table-column v-for="(item,idx) in yearKey" :key='idx' :label="item" :prop="'roe-'+yearKey[idx]" sortable="custom" align="center">
            <template #default='{row}'>
              {{ row['roe-'+yearKey[idx]].toFixed(2) }}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column prop="todayChange" label="ROIC（%）" align="center">
          <el-table-column v-for="(item,idx) in yearKey" :key='idx' :label="item" :prop="'roic-'+yearKey[idx]" sortable="custom" align="center">
            <template #default='{row}'>
              {{ row['roic-'+yearKey[idx]]?.toFixed(2) || '--' }}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column prop="yesterdayChange" label="ROA（%）" align="center">
          <el-table-column v-for="(item,idx) in yearKey" :key='idx' :label="item" :prop="'roia-'+yearKey[idx]" sortable="custom" align="center">
            <template #default='{row}'>
              {{ row['roa-'+yearKey[idx]].toFixed(2) }}
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
    </div>

    <!-- 资本回报率提示弹窗 -->
    <el-dialog v-model="dialogVisible1" title="资本回报率" width="30%" :before-close="handleClose">
      <div class="antd-pro-components-comment-styles-modalContent">
        <div class="antd-pro-components-comment-styles-title">净资产收益率ROE（加权）<div class="antd-pro-components-comment-styles-titleBottom"></div>
        </div>
        <div class="antd-pro-components-comment-styles-commentContent">【释义】：净资产收益率是公司净利润和净资产的比率，该指标反映股东权益的收益水平，用以衡量公司运用自有资本的效率。
          指标值越高，说明投资带来的收益越高。一般国内业绩优的公司ROE维持在15%以上，但是在实际应用过程中还需要结合行业，负债等具体分析。
          【算法】：归属母公司股东净利润/加权平均归属母公司股东的权益*100%
          【单位】：%</div>
        <div class="antd-pro-components-comment-styles-title">总资产净利率ROA<div class="antd-pro-components-comment-styles-titleBottom"></div>
        </div>
        <div class="antd-pro-components-comment-styles-commentContent" style="margin-bottom: 15px;">【释义】：总资产净利率是企业报告期内获得的可供投资者分配的经营收益占总资产的百分比，反映投资者（含少数股东权益）利用全部资产获利的能力。指标值越高，资产利用效果越好，增加收入和节约成本的效果就越好。本指标的“资产”是合并报表的总资产（即包含归属少数股东的权益），因此，“净利润”采用全部净利润（即包含少数股东损益）。该指标剔除了母公司控股比例对投资收益的影响，可用于同行业不同公司的收益水平比较。
          【算法】：总资产净利率ROA=净利润（含少数股东损益）*2/（期初总资产+期末总资产）*100%
          【单位】：%</div>
        <div class="antd-pro-components-comment-styles-title">投入资本回报率ROIC<div class="antd-pro-components-comment-styles-titleBottom"></div>
        </div>
        <div class="antd-pro-components-comment-styles-commentContent" style="margin-bottom: 15px;">【释义】：ROIC是生产经营活动中所有投入资本赚取的收益率，而不论这些投入资本是被称为债务还是权益。分子是指公司如果完全按权益筹资所应报告的税后利润，分母是指公司所要求回报的现金来源的总和。
          【算法】：投入资本回报率ROIC=净利润*2/（期初投入资本+期末投入资本）*100
          全部投入资本=归属母公司股东权益（不含少数股东权益）+总负债-无息流动负债-无息长期负债
          【单位】：%</div>
        <div class="antd-pro-components-comment-styles-title">
          <div class="antd-pro-components-comment-styles-titleBottom"></div>
        </div>
        <div class="antd-pro-components-comment-styles-title">
          <div class="antd-pro-components-comment-styles-titleBottom"></div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <!-- <el-button @click="dialogVisible1 = false">Cancel</el-button> -->
          <el-button type="primary" @click="dialogVisible1 = false">
            知道了
          </el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 估值分析提示弹窗 -->
    <el-dialog v-model="dialogVisible2" title="估值分析" width="30%" :before-close="handleClose">
      <div class="antd-pro-components-comment-styles-modalContent">
        <div class="antd-pro-components-comment-styles-title">市盈率PE（TTM）<div class="antd-pro-components-comment-styles-titleBottom"></div>
        </div>
        <div class="antd-pro-components-comment-styles-commentContent">【释义】：最新每股市价为最近12个月每股收益的倍数。反映了在每股盈利不变的情况下，当派息率为100%时及所得股息没有进行再投资的情况下，经过多少年投资可以通过股息全部收回的状态。一般情况下，市盈率越低的股票，表明投资价值越大。
          【算法】：市盈率PE(TTM)=总市值/归属母公司股东的净利润TTM
          【单位】：倍</div>
        <div class="antd-pro-components-comment-styles-title">市净率PB（LF）<div class="antd-pro-components-comment-styles-titleBottom"></div>
        </div>
        <div class="antd-pro-components-comment-styles-commentContent" style="margin-bottom: 15px;">【释义】：普通股每股市价为每股净资产的倍数。PB越低，投资价值越高，适用于高风险、资产大量为实物资产的企业。
          【算法】：市净率PB(LF)=总市值/指定最新公告股东权益（不含少数股东权益及优先股）
          【单位】：倍</div>
        <div class="antd-pro-components-comment-styles-title">市销率PS(TTM)<div class="antd-pro-components-comment-styles-titleBottom"></div>
        </div>
        <div class="antd-pro-components-comment-styles-commentContent" style="margin-bottom: 15px;">【释义】：每股市价为过去12个月每股营业收入的倍数。PS越低，投资价值越大。适用于销售成本较低的服务类行业，或者销售成本率趋同的传统行业的企业。
          【算法】：市销率PS(TTM)=总市值/营业收入(TTM)
          【单位】：倍</div>
        <div class="antd-pro-components-comment-styles-title">市现率PCF（经营现金流TTM）<div class="antd-pro-components-comment-styles-titleBottom"></div>
        </div>
        <div class="antd-pro-components-comment-styles-commentContent" style="margin-bottom: 15px;">【释义】：每股市价为过去12个月每股经营性现金流的倍数。用于评价股票的价格水平和风险水平，市现率越小，表明上市公司的每股现金增加额越多，经营压力越小。
          【算法】：市现率PCF（经营现金流TTM）=总市值/经营活动产生的现金流量(TTM)
          【单位】：倍</div>
        <div class="antd-pro-components-comment-styles-title">
          <div class="antd-pro-components-comment-styles-titleBottom"></div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <!-- <el-button @click="dialogVisible2 = false">Cancel</el-button> -->
          <el-button type="primary" @click="dialogVisible2 = false">
            知道了
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from "vue";
import CTitle from "@/components/title/Index.vue";
import LineChartD from "@/components/charts/LineChartD.vue";
import LineChartF from "@/components/charts/LineChartF.vue";
import LineChartL from "@/components/charts/LineChartL.vue";
import LineChartM from "@/components/charts/LineChartM.vue";
import LineChartO from '@/components/charts/LineChartO.vue'
import Search from "@/components/search-input/Index.vue";
import breadcrumb from '@/components/breadcrumb/Index.vue';
import CSelect from '@/components/pub-radio/Index.vue';
import { useRoute } from "vue-router";

import {
  getDiagnosticAssessment,
  getValuationDaily,
  getValuationDesc,
  getValuationQuantileDaily,
} from "@/apis/diagnosis";
import {
  getIndustryRecords,
  getStockBandDaily,
  getStockRating,
  getStockIndustryRecords,
  getStockPettmDaily
} from "@/apis/analysis";


export default defineComponent({
  components: {
    CTitle,
    LineChartD,
    LineChartF,
    LineChartL,
    LineChartM,
    CSelect,
    Search,
    breadcrumb,
    LineChartO,
  },
  setup() {
    // const stockCode = '600519.SH'
    const route = useRoute();
    const stockCode = route.query.code;
    const dataMap = reactive({
      defultValue: route.query.timeLevel || '3Y',
      ajaxRes: false,
      stockInfo: {},
      activeAnalysisIdx: 0,
      activeZbIdx: 0,
      stockList1: [],
      stockList2: {},
      stockList3: {},
      stockList4: [],
      stockList5: {},
      stockList6: [],
      stockList7: [],
      stockList8: [],
      stockList5ChartData: {},
      assessmentInfo: {},
      pepdTime: [],
      profitTypearr: ["市盈率", "市净率", "市销率", "市现率"],
      rate: ["perate", "pbrate", "psTtmrate", "pcfOcfTtmrate"],
      rate1: ["board2Roe", "board2Roic", "board2Roa"],
      rate2: ["roe", "roic", "roa"],
      activeRate: 0,
      yearKey: [],
      dialogVisible1: false,
      dialogVisible2: false,
    });

    const swichAnalysisItem = (idx: any) => {
      dataMap.activeAnalysisIdx = idx;
      // console.log(dataMap.stockList5)
      if (idx && dataMap.stockList5) {
        if (idx === 1) {
          dataMap.stockList5ChartData = {
            max: dataMap.stockList5["peMax"],
            mid: dataMap.stockList5["peMid"],
            min: dataMap.stockList5["peMin"],
            data: dataMap.stockList5["peRecords"],
          };
        } else if (idx === 2) {
          dataMap.stockList5ChartData = {
            max: dataMap.stockList5["pbMax"],
            mid: dataMap.stockList5["pbMid"],
            min: dataMap.stockList5["pbMin"],
            data: dataMap.stockList5["pbRecords"],
          };
        } else if (idx === 3) {
          dataMap.stockList5ChartData = {
            max: dataMap.stockList5["psMax"],
            mid: dataMap.stockList5["psMid"],
            min: dataMap.stockList5["psMin"],
            data: dataMap.stockList5["psRecords"],
          };
        } else if (idx === 4) {
          dataMap.stockList5ChartData = {
            max: dataMap.stockList5["pcfMax"],
            mid: dataMap.stockList5["pcfMid"],
            min: dataMap.stockList5["pcfMin"],
            data: dataMap.stockList5["pcfRecords"],
          };
        }
      }
    };

    const swichZbItem = (idx: any) => {
      dataMap.activeZbIdx = idx;
    };

    const gettext = (obj: any, text: any) => {
      if (!obj) {
        return {};
      }
      obj[text + "text"] = obj[text].split("|")[0];
      obj[text + "color"] = obj[text].split("|")[1];
      return obj;
    };

    const changeHandle = async (res: any) => {
      if(!res.timeLevel) return;
      const data3 = await getValuationDesc({ stockCode: stockCode, timeLevel: res.timeLevel });
      dataMap.stockList3 = (data3 as any).data;

      let data4 = await getValuationDaily({
        stockCode: stockCode,
        timeLevel: res.timeLevel,
      });
      dataMap.stockList4 = (data4 as any).data;

      let data5 = await getValuationQuantileDaily({
        stockCode: stockCode,
        timeLevel: res.timeLevel,
      });
      dataMap.stockList5 = (data5 as any).data;
      swichAnalysisItem(dataMap.activeAnalysisIdx);
    };

    const tabRate = (res: any) => {
      dataMap.activeRate = res;
    };

    const changePePdHandle = async (res: any) => {
      if(res.timeLevel){
        let data7 = await getStockBandDaily({
          stockCode: stockCode,
          timeLevel: res.timeLevel,
        });
        dataMap.stockList2 = (data7 as any).data;
      }
    };

    const getpepdTime = (res: any) => {
      dataMap.pepdTime = res;
    };

    const getTableData2 = async (sortKey: any, isAsc: any) => {
      if (sortKey !== undefined) {
        tableSort2.sortKey = sortKey;
      }
      if (isAsc !== undefined) {
        tableSort2.isAsc = isAsc;
      }
      let data6 = await getIndustryRecords(tableSort2);
      dataMap.stockList1 = (data6 as any).data;
    };

    const fetchData = async () => {
      const data1 = await getDiagnosticAssessment({ stockCode: stockCode });
      let assessmentInfo = (data1 as any).data;
      if (assessmentInfo) {
        gettext(assessmentInfo, "cashFlowScore");
        gettext(assessmentInfo, "growUpScore");
        gettext(assessmentInfo, "operationScore");
        gettext(assessmentInfo, "profitabilityScore");
        gettext(assessmentInfo, "ranking");
        gettext(assessmentInfo, "valueScore");
        gettext(assessmentInfo, "valuationScore");
        dataMap.assessmentInfo = assessmentInfo;
      }

      // const data3 = await getValuationDesc({ stockCode: stockCode });
      // dataMap.stockList3 = (data3 as any).data;

      getTableData2(null, null);

      let data8 = await getStockRating({ stockCode: stockCode });
      dataMap.stockList6 = (data8 as any).data;

      getTableData(null, null);

      dataMap.ajaxRes = true;
    };
    fetchData();

    let tableSort = {
      stockCode: stockCode,
      // timeLevel: '',
      sortKey: "",
      isAsc: "",
    };
    const getTableData = async (sortKey: any, isAsc: any) => {
      // if(val){tableSort.timeLevel = val.timeLevel}
      if (sortKey !== undefined) {
        tableSort.sortKey = sortKey;
      }
      if (isAsc !== undefined) {
        tableSort.isAsc = isAsc;
      }
      let data9 = await getStockIndustryRecords(tableSort);
      dataMap.stockList7 = (data9 as any).data;
      if ((data9 as any).data[0]) {
        dataMap.yearKey = (data9 as any).data[0]["roa-date"];
      }
    };
    const sortChange = (column: any) => {
      let isAsc = column.order === "ascending" ? true : false;
      // changeHandle({timeLevel: timeLevel}, column['prop'], isAsc, idx)
      getTableData(column["prop"], isAsc);
    };

    let tableSort2 = {
      stockCode: stockCode,
      // timeLevel: '',
      sortKey: "",
      isAsc: "",
    };

    const sortChange2 = (column: any) => {
      let isAsc = column.order === "ascending" ? true : false;
      // changeHandle({timeLevel: timeLevel}, column['prop'], isAsc, idx)
      getTableData2(column["prop"], isAsc);
    };

    const getStockInfo = (info:any) => {
      dataMap.stockInfo = info
    }

    const changePettmHandle = async (res: any) => {
      if(res.timeLevel){
        let pettmData = await getStockPettmDaily({ stockCode: stockCode, timeLevel: res.timeLevel });
        dataMap.stockList8 = (pettmData as any).data;
      }
    }

    return {
      ...toRefs(dataMap),
      swichAnalysisItem,
      swichZbItem,
      changeHandle,
      changePePdHandle,
      getpepdTime,
      tabRate,
      sortChange,
      sortChange2,
      getStockInfo,
      changePettmHandle,
    };
  },
});
</script>
<style lang='scss' scoped>
.bg {
  background: #fff;
  padding: 20px 20px 50px;
  margin-bottom: 30px;
  .table-style-2 {
    margin-top: 60px;
  }
}
.pe {
  padding: 30px 20px 50px;
  position: relative;
  // margin-bottom: 25px;
  .tab {
    margin-top: 25px;
    cursor: pointer;
  }
  .b-tab-nav{
    margin-bottom: 0!important;
    li{
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .chart-box {
    // width: 500px;
    position: relative;
    ::v-deep .el-select {
      position: absolute;
      right: 0;
      z-index: 1;
    }
  }
  .pe-top{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.flex {
  display: flex;
  & > div {
    flex: 1;
  }
  .left {
    padding-right: 30px;
  }
  .right {
    padding-left: 30px;
  }
}
.b-tab-nav {
  background: #ffffff;
  box-shadow: 0px 2px 12px 0px rgba(24, 24, 24, 0.1);
  border-radius: 8px;
  padding: 4px;
  display: inline-block;
  margin-top: 10px;
  &.b-tab-nav2 {
    margin-bottom: 35px;
    margin-top: 25px;
  }
  ul {
    display: flex;
    li {
      width: 161px;
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #999ba7;
      padding: 10px 0;
      cursor: pointer;
      &.active {
        color: #fff;
        background: #386ff2;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
      }
      div:last-child {
        font-size: 18px;
        padding-top: 4px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .b-tab-nav{
    width: 80%;
    ul {
      li{
        width: 20%;
      }
    }
  }
  .b-tab-nav2{
    width: 60%;
    ul {
      li{
        width: 25%;
      }
    }
  }
  .b-tab-nav3{
    width: 50%;
    ul {
      li{
        width: 33.3%;
      }
    }
  }
}
.hb {
  // width: 600px;
}
.analysis-container {
  margin-top: 30px;
  // display: flex;
  .analysis {
    // width: 550px;
    // margin-right: 163px;
    .analysis-title {
      display: flex;
      .analysis-title-left {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        .red {
          color: #fb110e;
        }
      }
      .analysis-title-right {
        margin-left: auto;
      }
    }
  }
  .table-style-1 {
    margin-top: 55px;
    // margin-left: 163px;
  }
  .analysis-left-date {
    margin-top: 40px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
  }
  .analysis-right {
    // margin-right: 163px;
    p {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      padding-right: 30px;
    }
    .trend_content_legend {
      margin-bottom: 10px;
      ul {
        display: flex;
        margin: 7px 0 10px;
        li {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          margin-right: 20px;
          span {
            display: inline-block;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: #951efd;
          }
          &:nth-child(2) {
            span {
              background: #d0031b;
            }
          }
          &:nth-child(3) {
            span {
              background: #e9c036;
            }
          }
        }
      }
    }
    .his {
      // width: 550px;
    }
  }
  .content_item_timebox {
    margin-left: 25px;
    margin-top: -20px;
  }
}
.tab {
  display: inline-block;
  ul {
    display: flex;
    border: 1px solid #dddddd;
    border-radius: 4px;
    overflow: hidden;
    li {
      width: 79px;
      height: 36px;
      line-height: 36px;
      background: #ffffff;
      text-align: center;
      border-right: 1px solid #dddddd;
      color: #666666;
      &.active {
        color: #fff;
        background: #386ff2;
        border-color: transparent;
      }
      &:last-child {
        border: 0;
      }
    }
  }
}

.fx {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  ::v-deep .el-select {
    position: absolute;
    right: 0;
    top: -5px;
  }
}

.content_item_mechanism_line_header {
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
}
.content_item_mechanism_line_header.profit
  .content_item_mechanism_line_header_item:first-child
  .content_item_mechanism_line_header_item_icon {
  background-color: #b4c9ec;
}
.content_item_mechanism_line_header.profit
  .content_item_mechanism_line_header_item:nth-child(2)
  .content_item_mechanism_line_header_item_icon {
  background-color: #a0c4ff;
}
.content_item_mechanism_line_header.profit
  .content_item_mechanism_line_header_item:nth-child(3)
  .content_item_mechanism_line_header_item_icon {
  background-color: #6fa4fa;
}
.content_item_mechanism_line_header.profit
  .content_item_mechanism_line_header_item:nth-child(4)
  .content_item_mechanism_line_header_item_icon {
  background-color: #3b7fee;
}

.content_item_mechanism_line_header_item {
  display: flex;
  align-items: center;
}
.content_item_mechanism_line_header_item:not(:last-child) {
  margin-right: 40px;
}
.content_item_mechanism_line_header_item_icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #0086fb;
  margin-right: 14px;
}
.content_item_mechanism_line_header_item:nth-child(2)
  .content_item_mechanism_line_header_item_icon {
  background: #fe9625;
}
.content_item_mechanism_line_header_item_text {
  color: #666666;
  font-size: 24px;
}

.content_item_profit_table_header {
  display: flex;
  align-items: center;
  &:nth-child(2) {
    .content_item_profit_table_header_item {
      color: #3b7fee;
    }
  }
  &:last-child {
    border-bottom: 2px solid #f4f4f4;
    .content_item_profit_table_header_item {
      color: #fe9625;
    }
  }
}
.content_item_profit_table_header_item {
  width: 180px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #383838;
  font-size: 14px;
  border: 2px solid #f4f4f4;
  border-bottom: none;
  border-right: none;
}
.content_item_profit_table_header.type1 .content_item_profit_table_header_item {
  color: #e03434;
}
.content_item_profit_table_header_item:last-child {
  border-right: 2px solid #f4f4f4;
}
.content_item_profit_table_content_item {
  height: 68px;
  line-height: 68px;
  border: 2px solid #f4f4f4;
  display: flex;
  align-items: center;
  &:last-child {
    border-top: none;
  }
}
.content_item_profit_table_content_item .text {
  width: 23%;
  text-align: center;
  color: RGBA(0, 134, 251, 0.8);
  font-size: 26px;
}
.content_item_profit_table_content_item:nth-child(2) .text {
  color: #fe9625;
}
.content_item_profit_table_content_item .text0 {
  text-align: center;
  color: #999999;
  font-size: 26px;
}
.content_item_timebox2 {
  margin-left: 25px;
  margin-top: -20px;
}


</style>
