<template>
  <div class="content">
    <div class="left">
      <div class="des">
        评级家数
      </div>
      <div class="num">
        {{chartData.orgCount}}家
      </div>
      <div class="des">
        综合评级
      </div>
      <div class="num">
        {{chartData.ratingName}}
      </div>
    </div>
    <div
      :id="id"
      :class="className"
      :style="{height: height, width: width}"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, onActivated, onBeforeUnmount, onDeactivated, onMounted, nextTick, watch } from 'vue'
import resize from './mixins/resize'
import { init, EChartsOption } from 'echarts'
import * as echarts from 'echarts'
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '550px',
      required: true
    },
    height: {
      type: String,
      default: '231px',
      required: true
    },
    chartData: {
      type: Object,
      default: {}
    },
    isMobile: {
      type: Boolean,
      default: false
    },
  },
  setup(props) {
    watch(props, (newName, oldName) => {
      initChart()
    });
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()
    const initChart = () => {
      if( !Object.keys(props.chartData).length ){
        return false
      }
      const barChart = init(document.getElementById(props.id) as HTMLDivElement)
      let xData = [];
      let serviesData1 = [];
      let serviesData2 = [];
      for (let index = 0; index < props.chartData.ratingCounts.length; index++) {
        xData.push(props.chartData.ratingCounts[index].ratingName)
        serviesData1.push(props.chartData.ratingCounts[index].orgCount)
        serviesData2.push(props.chartData.ratingCounts[index].fxsOrgCount)
      }
      
      barChart.setOption({
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          top: props.isMobile ? '85px' : '60px',
          left: props.isMobile ? '100px' : '151px',
          right: '0%',
          bottom: '0%',
          containLabel: true
        },
        legend: {
          // show: false,
          data: ['新财富分析师评级'],
          icon: 'circle',
          x: '0',
          left: '0',
          textStyle: {
            align: 'left', // 这个是可以左右调整的
            fontSize: 14,// 字体大小
            color: '#666666',// 字体大小
            // padding:[0,0,25,-45]//主要就是用这个，可以把文字上下调整
            // color: 'rgba(255,255,255,0.65)'//字体颜色
          }
        },
        toolbox: {
          show: true
          // feature: {
          //   dataView: { show: true, readOnly: false },
          //   magicType: { show: true, type: ['line', 'bar'] },
          //   restore: { show: true },
          //   saveAsImage: { show: true }
          // }
        },
        calculable: true,
        xAxis: [
          {
            type: 'category',
            // prettier-ignore
            data: xData,
            // splitLine: { show: false },
            // axisLabel:{show:false},
            axisTick: { show: false },
            axisLine: { show: false }
          }
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: { show: false },
            // axisTick: { show: false },
            axisLabel: { show: false }
          }
        ],
        series: [
          {
            name: '评级家数',
            barGap: '70%',
            barWidth: props.isMobile ? 13 : 15, // 柱子宽度
            type: 'bar',
            data: serviesData1,
            itemStyle: {
              normal: {
                barBorderRadius: [4, 4, 0, 0],
                label: {
                  formatter: '{c}' + '家',
                  show: true,
                  position: 'top',
                  textStyle: {
                    fontWeight: 'bolder',
                    fontSize: '12',
                    color: '#FF9626'
                  }
                },
                color: '#FF9626',
                opacity: 1
              }
            }
          },
          {
            name: '新财富分析师评级',
            type: 'bar',
            barWidth: 15, // 柱子宽度
            data: serviesData2,
            itemStyle: {
              normal: {
                barBorderRadius: [4, 4, 0, 0],
                label: {
                  formatter: '{c}' + '家',
                  show: true,
                  position: 'top',
                  textStyle: {
                    fontWeight: 'bolder',
                    fontSize: '12',
                    color: '#466EEA'
                  }
                },
                color: '#466EEA',
                opacity: 1
              }
            }
            
          }
        ]
      } as EChartsOption)
      chart.value = barChart
    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {

    }
  }
})
</script>

<style lang="scss" scoped>
// .list{
//   display: flex;
//   .item{
//     margin-right: 30px;
//     &:last-child{
//       .circle{
//         background: #3C7FEE;
//       }
//     }
//     .circle{
//       width: 12px;
//       height: 12px;
//       background: #FF9625;
//       border-radius: 50%;
//       display: inline-block;
//       margin-right: 8px;
//     }
//   }
// }
.content{
  display: flex;
  text-align: center;
  margin-top: 30px;
  position: relative;
  .left{
    width: 151px;
    height: 191px;
    background: #F6F8FB;
    z-index: 1;
    position: absolute;
    top: 40px;
    .des{
      font-size: 18px;
      // font-family: PingFang;
      font-weight: 400;
      color: #999999;
      &:nth-child(1){
        margin: 20px 0 14px;
      }
      &:nth-child(3){
        margin: 33px 0 14px;
      }
    }
    .num{
      font-size: 22px;
      // font-family: PingFang;
      font-weight: 500;
      color: #FB160C;
      &:nth-child(4){
        // padding-bottom: 22px;
      }
    }
  }
  .chart{
    flex: 1;
  }
}
</style>
