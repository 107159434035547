
import { defineComponent, reactive, toRefs, watch } from 'vue'
import trendChart from './trend-chart.vue'
export default defineComponent({
  props: {
    tableList: {
      type: Array,
      default: []
    },
    chartId: {
      type: String,
      default: ''
    },
    thaderClass: {
      type: String,
      default: ''
    }
  },
  components: {
    trendChart
  },
  setup(props) {
    const dataMap = reactive({
      titleList: [
        {
          id: 1,
          name: '股票代码'
        },
        {
          id: 2,
          name: '股票名称'
        },
        {
          id: 3,
          name: '持股数量变动(万股)'
        },
        {
          id: 4,
          name: '净买入(亿元)'
        },
        {
          id: 5,
          name: '申万一级'
        },
        {
          id: 6,
          name: '趋势图'
        }
      ],
      sumList: 0
    })
    let sum:any = []
    watch(props, (newName, oldName) => {
      props.tableList.map((item:any)=>{
        sum.push(...(item.addHoldList.map((inner:any)=>Number(inner.addHoldMarketCap))))
      })
      dataMap.sumList = sum
    });
    return {
      ...toRefs(dataMap)
    }
  }
})

