<template>
  <div class="wrap">
    <div class="content_box">
      <div class="module_box">
        <nav-title title="行业分布" />
        <el-scrollbar>
          <div class="tag_box">
            <span></span>
            <tab-switch @tabChange="tradeTab" />
          </div>
          <nav-switch :tab-list="industryTabList" :initial-type="initialStatus" @changeTab="tradeNav" />
          <industry-chart id="industry" :chart-data="tradeList" :style-status="industryChart" @chartsClick="industryChartClick" />
<!--          <industry-chart id="industry" width="1028px" :chart-data="tradeList" :style-status="industryChart" @chartsClick="industryChartClick" />-->
        </el-scrollbar>
      </div>
<!--      <div class="module_box">-->
<!--        <nav-title title="类型分布" />-->
<!--        <el-scrollbar>-->
<!--          <div class="tag_type_box">-->
<!--            <span></span>-->
<!--            <tab-switch @tabChange="typeTab" />-->
<!--          </div>-->
<!--          <type-chart id="typeChart" width="850px" :chart-data="typeList" />-->
<!--        </el-scrollbar>-->
<!--      </div>-->
      <div class="module_box">
        <nav-title title="市值分布" />
        <el-scrollbar>
          <div class="tag_market_box">
            <span></span>
            <tab-switch @tabChange="marketTab" />
          </div>
          <type-chart id="marketChart" :chart-data="marketList" />
<!--          <type-chart id="marketChart" width="1040px" :chart-data="marketList" />-->
        </el-scrollbar>
      </div>
      <div class="module_box">
        <nav-title title="全部数据" />
        <el-scrollbar>
          <div class="tag_all_box">
            <span></span>
            <tab-switch @tabChange="allTab" />
          </div>
          <nav-switch :tab-list="industryTabList" :initial-type="initialStatus" @changeTab="allChangeTab" />
          <div class="all_data_box">
            <all-data ref="allDataRef" :trade-api-list="tradeApiList" @tradeTopClick="tradeTopClick" :stock-api-list="stockApiList" @stockTopTag="stockTopTag" :leader-api-list="leaderApiList" />
          </div>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, toRefs, ref } from 'vue'
import navSwitch from './component/nav-switch.vue'
import tabSwitch from './component/tab-switch.vue'
import navTitle from './component/nav-title.vue'
import allData from './component/all-data.vue'
import industryChart from './component/industry-chart.vue'
import typeChart from './component/type-chart.vue'
import { getBoardRanking, getTypeRanking, getMvRanking, getNewsRanking, getStocksRanking, getLeader } from '@/apis/information-acquisition'
export default defineComponent({
  components: {
    navSwitch,
    tabSwitch,
    navTitle,
    allData,
    industryChart,
    typeChart
  },
  setup() {
    const allDataRef = ref(null)
    const defineData = reactive({
      initialStatus: 1,
      industryTabList: [
        {
          id: 1,
          name: '申万一级',
          value: 'swl1'
        },
        {
          id: 2,
          name: '申万二级',
          value: 'swl2'
        }
      ],
      tradeItem: {},
      tradeNavItem: {value: 'swl1'},
      tradeList: {},
      industryChart: false,
      industryChartBoardName: '',
      beforeTime: {
        startDate: '',
        endDate: ''
      },
      typeList: {},
      marketList: {},
      allTabItem: {},
      allNavItem: {value: 'swl1'},
      allBeforeTime: {
        startDate: '',
        endDate: ''
      },
      tradeApiList: [],
      stockApiItem: { name: '' },
      stockApiList: [],
      leaderApiItem: { code: '' },
      leaderApiList: []
    })
    const tradeNav = (item: any) => {
      defineData.tradeNavItem = item;
      defineData.industryChartBoardName = '';
      if (defineData.industryChart) {
        defineData.industryChart = false
      }
      tradeJoggle();

    }
    const tradeJoggle = async () => {
      const params = {
        boardType: (defineData.tradeNavItem as any).value,
        parentBoardName: defineData.industryChartBoardName,
        timeLevel: (defineData.tradeItem as any).value,
        startDate: defineData.beforeTime.startDate,
        endDate: defineData.beforeTime.endDate,
      }
      const tradeData = await getBoardRanking(params)
      if ((tradeData as any).code === 200) {
        defineData.tradeList = (tradeData as any)
      }
    }
    const tradeTab = (item: any, time: any) => {
      defineData.tradeItem = item;
      if (item.type === 'custom') {
        defineData.beforeTime = time
      }
      tradeJoggle()
    }
    tradeTab({label: '1年', incrLabel: '1年', value: '1y'}, {})

    const industryChartClick = (item: any, style: any) => {
      defineData.industryChartBoardName = item
      defineData.industryChart = style
      defineData.tradeNavItem = { value: 'swl2' }
      tradeJoggle()
    }

    const typeTab = async (item: any, time: any) => {
      const params = {
        timeLevel: item.value,
        startDate: time.startDate,
        endDate: time.endDate
      }
      const typeData = await getTypeRanking(params)
      if ((typeData as any).code === 200) {
        defineData.typeList = (typeData as any)
      }
    }
    typeTab({label: '1年', incrLabel: '1年', value: '1y'}, {})
    const marketTab = async (item: any, time: any) => {
      const params = {
        timeLevel: item.value,
        startDate: time.startDate,
        endDate: time.endDate
      }
      const marketData = await getMvRanking(params)
      if ((marketData as any).code === 200) {
        defineData.marketList = (marketData as any)
      }
    }
    marketTab({label: '1年', incrLabel: '1年', value: '1y'}, {})


    const stockApi = async () => {
      const params = {
        boardType: (defineData.allNavItem as any).value,
        boardName: (defineData.stockApiItem as any).name,
        timeLevel: (defineData.allTabItem as any).value,
        startDate: defineData.allBeforeTime.startDate,
        endDate: defineData.allBeforeTime.endDate
      }
      const stockApiData = await getStocksRanking(params)
      if ((stockApiData as any).code === 200) {
        defineData.stockApiList = (stockApiData as any).data
        defineData.leaderApiItem.code = (stockApiData as any).data[0].code
        leaderApi()
      }

    }
    const leaderApi = async () => {
      const params = {
        stockCode: (defineData.leaderApiItem as any).code,
        timeLevel: (defineData.allTabItem as any).value,
        startDate: defineData.allBeforeTime.startDate,
        endDate: defineData.allBeforeTime.endDate
      }
      const leaderApiData = await getLeader(params)
      if ((leaderApiData as any).code === 200) {
        defineData.leaderApiList = (leaderApiData as any).data
      }
    }

    const tradeApi = async () => {
      const params = {
        boardType: (defineData.allNavItem as any).value,
        timeLevel: (defineData.allTabItem as any).value,
        startDate: defineData.allBeforeTime.startDate,
        endDate: defineData.allBeforeTime.endDate
      }
      const tradeApiData = await getNewsRanking(params)
      if ((tradeApiData as any).code === 200) {
        (tradeApiData as any).data.unshift({ name: '不限' })
        defineData.tradeApiList = (tradeApiData as any).data
        // defineData.stockApiItem.name = (tradeApiData as any).data[0].name
        defineData.stockApiItem.name = ''
        stockApi()
      }

    }

    const allChangeTab = (item: any) => {
      if(allDataRef.value) {
        (allDataRef.value as any).initialization()
      }
      defineData.allNavItem = item;
      (defineData.tradeApiList as any) = {};
      (defineData.stockApiList as any) = {};
      (defineData.leaderApiList as any) = {}
      tradeApi()
    }

    const allTab = (item: any, time: any) => {
      if(allDataRef.value) {
        (allDataRef.value as any).initialization()
      }
      defineData.allTabItem = item;
      if (item.type === 'custom') {
        defineData.allBeforeTime = time
      }
      (defineData.tradeApiList as any) = {};
      (defineData.stockApiList as any) = {};
      (defineData.leaderApiList as any) = {}
      tradeApi()
    }
    allTab({label: '1年', incrLabel: '1年', value: '1y'}, {})

    const tradeTopClick = (item: any) => {
      defineData.stockApiItem = item;
      stockApi()
    }
    const stockTopTag = (item: any) => {
      defineData.leaderApiItem = item;
      leaderApi()
    }

    return {
      ...toRefs(defineData),
      tradeNav,
      tradeTab,
      industryChartClick,
      typeTab,
      marketTab,
      allTab,
      allChangeTab,
      tradeTopClick,
      stockTopTag,
      allDataRef
    }
  }
})

</script>
<style scoped lang="scss">
.wrap {
  width: 100%;
  padding: 0 35px;
  > .content_box {
    margin-bottom: 30px;
    > .module_box {
      margin-top: 36px;
      background-color: #fff;
      padding-bottom: 40px;
      .tag_box {
        //width: 1017px;
        display: flex;
        justify-content: space-between;
      }
      .tag_type_box {
        //width: 919px;
        display: flex;
        justify-content: space-between;
      }
      .tag_market_box {
        //width: 1109px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 44px;
      }
      .tag_all_box {
        //width: 1109px;
        display: flex;
        justify-content: space-between;
      }
      .all_data_box {
        margin-left: 20px;
      }
    }
  }
}

</style>
