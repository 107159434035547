
import { defineComponent, onMounted, reactive, toRefs, ref, onDeactivated } from 'vue'
import { getNewsNewsList } from '@/apis/home'
import moment from 'moment'
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Virtual, Autoplay } from "swiper";
import 'swiper/swiper.scss';

SwiperCore.use([Virtual, Autoplay ]);

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const timer = ref(0);

    const dataMap = reactive({
      loading: false,
      isOpen: false,
      list: [],
      handleList: [] as any[],
    })

    const arrChange = (num:Number, arr:any) => {
      const newArr = [];
      while(arr.length > 0){
        newArr.push(arr.splice(0, num));
      }
      return newArr;
    }

    const fetchData = async () => {
      const data = await getNewsNewsList({  })
      const arr = (data as any).data;
      dataMap.list = JSON.parse(JSON.stringify(arr));
      dataMap.handleList = arrChange(5, arr);
    }
    fetchData()

    onMounted(() => {
      timer.value = window.setInterval(() => {
        fetchData()
      }, 60000)
    })

    onDeactivated(() => {
      window.clearInterval(timer.value);
    })

    const handleToggle = () => {
      dataMap.isOpen = !dataMap.isOpen
    }

    const goReport = (item:any) => {
      localStorage.setItem('notice', JSON.stringify(item))
      window.open(`/investment/agency/reportgg`)
    }

    return {
      ...toRefs(dataMap),
      handleToggle,
      moment,
      goReport,
      modules: [Autoplay],
    }
  }
})

