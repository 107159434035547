

import { defineComponent, reactive, toRefs } from 'vue'
// import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  props: {
    // eslint-disable-next-line vue/require-default-prop
    pageData: {
      type: Object
    },
    pageSize:{
      type: Number,
      default: 50
    },
    init:{
      type: Boolean,
      default: true
    },
    pagerCount:{
      type: Number,
      default: 7
    },
    showPageSizes:{
      type: Boolean,
      default: false
    },
    small:{
      type: Boolean,
      default: false
    }
  },
  emits: ['fetchData'],
  setup(props, context) {
    const dataMap = reactive({
      isShow: true,
      curPage: 1
    })
    // const router = useRouter()
    // const route = useRoute()
    // const stockCode = route.query.code
    // const value = ref(0)
    // let options:any = []

    // context.emit('change', options[0])
    const changeCurPage = (val: number)=>{
      dataMap.isShow = false
      context.emit('fetchData', {
        pageNum: Number(val)
      })
      setTimeout(()=>{
        dataMap.isShow = true
      }, 100)
    }

    const handleSizeChange = (val: number)=>{
      // context.emit('changeTips')
      console.log(val)
      context.emit('fetchData', {
        pageSize: val,
      })
    }

    const handleCurrentChange = (val: number)=>{
      dataMap.curPage = val
      context.emit('fetchData', {
        pageNum: val
      })
      console.log(val)
    }

    const setCurrentPage = (val: number)=>{
      dataMap.curPage = val
    }

    if ( props.init ){
      context.emit('fetchData', {
        pageNum: 1,
        pageSize: props.pageSize,
      })
    }


    return {
      // value,
      // options,
      ...toRefs(dataMap),
      changeCurPage,
      handleSizeChange,
      handleCurrentChange,
      setCurrentPage
    }
  }
})
