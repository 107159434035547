

import { defineComponent, reactive, toRefs } from 'vue'
import CTitle from '@/components/title/Index.vue'
import { getAnalystRecommendList } from '@/apis/home'
import dicts from '@/utils/dicts'

export default defineComponent({
  props: {
    dataMap: {
      type: Array,
      default: []
    }
  },
  components: {
    CTitle
  },
  setup() {
    // const keyTimeMap = ['1m','3m','6m','1y'];
    const keyMap = ['month1Change','month3Change','month6Change','year1Change'];
    // const keyTextMap = ['1个月内','3月','6月','1年'];
    const dataMap = reactive({
      stockList1 : {},
      activeKey : keyMap[0],
    })
    let selectedTime = ''

    const fetchData = async (timeLevel:any) => {
      const data = await getAnalystRecommendList({ limit:3,onlyUp:false,timeLevel:timeLevel })
      dataMap.stockList1 = (data as any).data;
    }

    const changeHandle = (val:any) => {
      selectedTime = val.value
      // console.log('ccccccc',val)
      fetchData(val.timeLevel)
      dataMap.activeKey = keyMap[val.value]
    }

    const goDetail = (item:any) => {
      var str = ''
      dicts.ratingRelated.map((outer:any, idx:any)=>{
        item.tags.map((inner:any)=>{
          if( inner === outer ){
            str = idx
          }
        })
      })
      console.log(str)
      // dicts.ratingRelated
      window.open(`/investment/agency/detail?code=${item.code}&keywork=${str}&timeLevel=${selectedTime}`)
      // window['$props'].push({
      //   path: `/investment/agency/detail`,
      //   query:{
      //     code: item.code,
      //     keywork: str,
      //     timeLevel: selectedTime
      //   }
      // })
    }


    // fetchData(keyTimeMap[0]);
    return {
      ...toRefs(dataMap),
      changeHandle,
      goDetail
    }
  }
})
