
import { defineComponent, reactive, toRefs } from 'vue'
import { getStockInfo } from '@/apis/diagnosis'
import { useRoute  } from "vue-router";

export default defineComponent({
  setup(_, context) {
    const route = useRoute();
    const stockCode = route.query.code
    const dataMap = reactive({
      stockInfo: {},
    })
    const fetchData = async () => {
      let data10 = await getStockInfo({ stockCode: stockCode })
      dataMap.stockInfo = (data10 as any).data;
      context.emit('getStockInfo', dataMap.stockInfo);
    }
    fetchData()
    return {
      ...toRefs(dataMap),
    };
  }

})

