<template>
  <div class="app-container">
    <div class="bread">
      <span>机构调研</span>
      <span>> </span>
      <span>机构调研详情</span>
    </div>
    <breadcrumb @getStockInfo='getStockInfo' style="display:none"></breadcrumb>
    <Search  open></Search>
    <div class="bg mt50">
      <CTitle :mode='10' :title="stockInfo.name" :code="stockInfo.code" ></CTitle>
      <div>
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="时间" class="time-item">
            <div class="tab">
              <ul>
                <li :class="dstxActive === idx ? 'active' : ''" @click="swichItem(idx)" v-for="(item,idx) in dicts.timeSelection" :key='idx'>{{item.label}}</li>
                <li :class="dstxActive === 5 ? 'active' : ''" @click="swichItem(5)">自定义</li>
              </ul>
            </div>
          </el-form-item>
          <el-form-item v-if="showTimeSelect" class="time-item">
            <el-date-picker
              class="date-picker"
              unlink-panels
              v-model="selectTime"
              type="daterange"
              value="YYYY-MM"
              value-format="yyyy-MM-dd HH:mm"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              @change='getTime'
            />
          </el-form-item>
          <el-form-item style="padding-left:50px">
            <el-checkbox-group v-model="formInline.isPointOrg" >
              <el-checkbox label="只看重点机构" />
            </el-checkbox-group>
          </el-form-item>
        </el-form>
        
        <div class="search-btn">
          <div @click="onClearForm">清空所有条件</div>
          <div @click="onSearch">查询</div>
        </div>
      </div>

      <el-row class="row">
        <el-col :span="12">
          <!-- {{stockList1}} -->
          <div class="tit" v-if="stockList1.surveyCount">
            被调研次数：<span class="red">{{stockList1.surveyCount}}</span>
          </div>
          <el-table
              class="table-style-2 cursor"
              :data="stockList1?.page?.list || []"
              border
              style="width: 100%"
              @row-click="handleRowClick"
              :row-class-name='rowClassName222'
            >
              <el-table-column
                prop="surveyDate"
                align="center"
                label="调研日期"
              >
                <template #default='{row}'>
                  <div >{{row.surveyDate}}</div>
                </template>
              </el-table-column>
              <el-table-column
                prop="noticeDate"
                align="center"
                label="公告日期"
              />
              <el-table-column
                prop="receptionModel"
                align="center"
                label="接待方式"
              >
                <template #default='{row}'>
                  <div class="line1">{{row.receptionModel ?? '-'}}</div>
                </template>
              </el-table-column>
              <el-table-column
                prop="receptionPlace"
                align="center"
                label="接待地点"
              >
                <template #default='{row}'>
                  <div >{{row.receptionPlace ?? '-'}}</div>
                </template>
              </el-table-column>
              <el-table-column
                width='200'
                prop="summary"
                align="center"
                label="调研纪要"
              >
                <template #default='{row}'>
                  <!-- <el-popover
                    placement="bottom"
                    width="400"
                    trigger="hover"
                    :content='row.summary'
                    >
                    <div class="line2">{{row.summary}}</div>
                  </el-popover> -->
                  <el-popover
                    class="c-popover"
                    :width="500"
                    trigger="hover"
                    :content="row.summary"
                  >
                    <template #reference>
                      <div class="line1">{{row.summary}}</div>
                    </template>
                  </el-popover>
                </template>
              </el-table-column>
          </el-table>
        </el-col>

        <el-col :span="11" :offset='1'>
          <div class="tit" v-if="stockList2.orgNumber">
            机构家数：<span class="red">{{stockList2.orgNumber}}</span>
          </div>
          <el-table
              class="table-style-2 cursor"
              :data="stockList2?.page?.list || []"
              border
              style="width: 100%"
              @row-click="toResearchList"
            >
              <el-table-column
                prop="orgName"
                align="center"
                label="调研机构"
              >
                <template #default='{row}'>
                  <div class="org-name" :class="row.isFocusOrg ? 'blue' : ''">
                    <span>{{row.orgName ?? '-'}}</span>
                    <img src='@/assets/images/jg.png' width="12" v-if="row.isFocusOrg" />
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="orgType"
                align="center"
                label="机构类型"
              >
                <template #default='{row}'>
                  <div >{{row.orgType ?? '-'}}</div>
                </template>
              </el-table-column>
              <el-table-column
                prop="surveyPersonnel"
                align="center"
                label="调研人员"
              >
                <template #default='{row}'>
                  <div >{{row.surveyPersonnel ?? '-'}}</div>
                </template>
              </el-table-column>
          </el-table>
          <CPagination ref='pagin'  :pagerCount='3' :init='false' :pageSize='10' :pageData='stockList2?.page ?? {}' @fetchData='handleRowClick'/>
        </el-col>
      </el-row>
    </div>

    <div class="bg">
      <Compare ref='compareRef'/>
    </div>
  </div>
</template>

<script lang="ts">

import { defineComponent, reactive, toRefs, ref, nextTick } from 'vue'
import CTitle from "@/components/title/Index.vue"
import Search from '@/components/search-input/Index.vue'

import dicts from '@/utils/dicts'
import breadcrumb from '@/components/breadcrumb/Index.vue'
import Compare from './components/Compare.vue'
import CPagination from "@/components/pagination/Index.vue"

import { getSurveyStockDetail, getSurveyStockDetailOrgs} from '@/apis/research'

import { useRoute  } from "vue-router";
import { getSurveyOrg } from '@/apis/home';
import moment from 'moment';

export default defineComponent({
  components:{
    CTitle,
    Search,
    breadcrumb,
    CPagination,
    Compare,
  },
  setup() {
    const route = useRoute();
    const stockCode = route.query.code;
    const isPointOrg = Number(route.query.isPointOrg) === 1;
    const pagin = ref<InstanceType<typeof CPagination>>() 
    const compareRef = ref<InstanceType<typeof Compare>>() 

    const dataMap = reactive({
      stockInfo: {},
      formInline:{
        stockCode: stockCode,
        timeLevel: '1w',
        isPointOrg: isPointOrg ? ['只看重点机构'] : [],
      },
      dstxActive  : 0,
      showTimeSelect: false,
      selectTime: ['', ''],

      stockList1: {},
      stockList2: {},

      searchList2: [],
      tabActive: 0
    })
    

    //新版时间tab选择
    const swichItem = async (idx: any) => {
        dataMap.dstxActive = idx
        dataMap.showTimeSelect = idx === 5 ? true : false
        if( idx === 5 ){
          // delete dataMap.formInline['timeLevel']
          dataMap.formInline['timeLevel'] = ''
        }else{
          dataMap.formInline['timeLevel'] = dicts.timeSelection[idx].timeLevel
          delete dataMap.formInline['startTime'];
          delete dataMap.formInline['endTime'];
        }
    }

    if(route.query.timeLevel){
      swichItem(Number(route.query.timeLevel))

      //自定义时间
      if( dataMap.showTimeSelect ){
        const beginDate = `${route.query.beginDate ?? ''}`;
        const endDate = `${route.query.endDate ?? ''}`;
        dataMap.formInline['startTime'] = beginDate ? moment(beginDate).format('x') : ''
        dataMap.formInline['endTime'] = endDate ? moment(endDate).format('x') : ''
        dataMap['selectTime'] = [String(route.query.beginDate), String(route.query.endDate)]
      }else{
        dataMap.selectTime = []
      }
    }else{
      dataMap.selectTime = []
    }

    let ajaxNum = 0
    const fetchData = async () => {
      let data1 = await getSurveyStockDetail({
        ...dataMap.formInline,
        isPointOrg: dataMap.formInline.isPointOrg.length ? 1 : 0,
      })
      dataMap.stockList1 = (data1 as any).data

      if( ajaxNum === 0 ){
        compareRef.value!.setParams(dataMap.formInline)
      }

      ajaxNum++;

      nextTick(() => {
        if( dataMap.stockList1['page']['list'].length ){
          const row = dataMap.stockList1['page']['list'][0]
          rowParams['pageNum'] = 1
          rowParams['stockCode'] = row.stockCode
          rowParams['surveyDate'] = row.surveyDate
          pagin.value!.changeCurPage(1)
        }else{
          rowParams['stockCode'] = ''
          dataMap.stockList2 = {}
        }
      })
    }
    fetchData()

    const getStockInfo = (info:any) => {
      dataMap.stockInfo = info
    }

    //表格行点击
    let rowParams = {
      pageNum: 1,
      pageSize: 10
    }
    const handleRowClick = async (row: any,column:any) => {
      if( column !== undefined ){
        dataMap.tabActive = row.index
      }
      
      if( row.stockCode ){
        rowParams['pageNum'] = 1
        rowParams['stockCode'] = row.stockCode
        rowParams['surveyDate'] = row.surveyDate
        pagin.value!.changeCurPage(1)
        return false
      }
      if( row.pageNum ){
        rowParams['pageNum'] = row.pageNum
      }

      if( !rowParams['stockCode'] ){
        return false
      }
      rowParams['isPointOrg'] = dataMap.formInline.isPointOrg.length ? 1 : 0;
      let data2 = await getSurveyStockDetailOrgs(rowParams)
      dataMap.stockList2 = (data2 as any).data

      
    }

    
    //搜索按钮
    const onSearch = async (_parms:any) => {
      dataMap.tabActive = 0
      //自定义时间
      if( dataMap.showTimeSelect ){
        dataMap.formInline['startTime'] = window.moment(dataMap.selectTime[0]).format('x')
        dataMap.formInline['endTime'] = window.moment(dataMap.selectTime[1]).format('x')
      }
      fetchData()

      compareRef.value!.setParams(dataMap.formInline)


      console.log(dataMap.formInline)
    }

    //清空
    const onClearForm = async () => {
      delete dataMap.formInline['startTime'];
      delete dataMap.formInline['endTime'];
      dataMap.formInline['orgName'] = ''
      dataMap.formInline['isPointOrg'] = []
      
      swichItem(0)
      fetchData()
    }



     const blurHandler2 = (res:any) => {
      setTimeout(()=>{
        dataMap.searchList2 = []
      },200)
    }
    let timer2:any = null;
    
    const fetchSearchData2 = () => {
      if( timer2 ){
        clearTimeout(timer2)
      }
      timer2 = setTimeout(async ()=>{
        let data = await getSurveyOrg({ keyword: dataMap.formInline['orgName'], limit: 20})
        let aaa = (data as any).data;
        dataMap.searchList2 = aaa;
      },500)
      
    }
    const selectedSearch2 = (item:any) => {
      dataMap.formInline['orgName'] = item.name
    }

    const rowClassName222 = ({row, rowIndex}:any) => {
      row.index = rowIndex
      if (rowIndex === dataMap.tabActive) {
        return 'row-active'
      } 
      return ''
    }

    // 跳转调研机构列表
    const toResearchList = (row:any, column:any) => {
      const { formInline, dstxActive, selectTime } = dataMap;
      console.log(selectTime, 'selectTimeselectTimeselectTime');
      const startTime = selectTime[0];
      const endTime = selectTime[1];
      const isOrg = row.isFocusOrg ? 1 : 0;
      if(formInline['startTime']) {
        window.open(`/investment/research/list?isPointOrg=${isOrg}&timeLevel=${dstxActive}&orgName=${row.orgName}&beginDate=${startTime}&endDate=${endTime}`)
      }else{
        window.open(`/investment/research/list?isPointOrg=${isOrg}&timeLevel=${dstxActive}&orgName=${row.orgName}`)
      }
    }

    return {
      ...toRefs(dataMap),
      pagin,
      compareRef,
      getStockInfo,
      swichItem,
      handleRowClick,
      onSearch,
      onClearForm,
      dicts,
      blurHandler2,
      fetchSearchData2,
      selectedSearch2,
      rowClassName222,
      toResearchList
    };
  }
})
</script>
<style lang='scss' scoped>
@import '@/styles/searchForm.scss';
.bg{
  background: #fff;
  padding: 38px 32px 54px;
  margin-bottom: 30px;
  .table-style-2{
    margin-top: 10px
  }
}
.bread{
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #386FF2;
  span:nth-child(2){
    color: #666666;
  }
}
.row{
  margin-top: 30px;
}
.tit{
  font-size: 20px;
  font-weight: 500;
  color: #333333;
}
.flex{
  display: flex;
  &>div{
    flex: 1;
  }
  .left{
    padding-right: 30px;
  }
  .right{
    padding-left: 30px;
  }
}
.tab{
    display: inline-block;
    ul{
      display: flex;
      // border: 1px solid #DDDDDD;
      border-radius: 4px;
      overflow: hidden;
      // font-size: 0;
      li{
        width: 52px;
        height: 32px;
        line-height: 32px;
        cursor: pointer;
        background: #FFFFFF;
        text-align: center;
        // border-right: 1px solid #DDDDDD;
        color: #666666;
        border: 1px solid #DDDDDD;
        border-right: none;
        &.active{
          color: #fff;
          background: #386FF2;
          border-color: transparent;
          border-top: none;
          border-bottom: none;
        }
        &:last-child{
          // border: 0;
          border-right: 1px solid #DDDDDD;
          border-radius: 0 4px 4px 0;
        }
        &:first-child{
          // border: 0;
          // border-right: 1px solid #DDDDDD;
          border-radius: 4px 0 0 4px;
        }
      }
    }
}
.time-item{
  ::v-deep .el-form-item__content{
    width: auto!important;
  }
}
.search_box{
    width: 100%;
    background: #fff;
    max-height: 670px;
    overflow-y: auto;
    position: absolute;
    top: 50px;
    left: 0;
    z-index: 200;
    // padding: 0 10px;
    display: none;
    /* padding-top: 10px; */
}
.showsearch_box{
    display: block;
}
.search_box_list_box{
    // max-height: 670px;
    box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.2);
    padding-top: 20px;
    .search_box_list{
      overflow-x: hidden;
    }
}
.search_box_item{
    padding: 0 8px;
    margin-bottom: 8px;
    cursor: pointer;
    &.disabled{
      cursor: context-menu;
      .search_box_item_right{
        background: #ccc;
      }
    }
}
.search_box_item_box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    // height: 57px;
    border-bottom: 2px solid #F4F4F4;
}
.search_box_item_right{
    width: 55px !important;
    height: 25px;
    padding: 0;
    margin-right: 0 !important;
    line-height: 25px;
    border-radius: 12px;
    background: #FF423A;
    font-size: 14px;
    text-align: center;
    color: #fff;
    
}
.search_box_item_left_text{
    font-size: 14px;
    color: RGBA(66, 66, 66, 1);
    line-height: 14px;
}
.search_box_item_left_num{
  padding-bottom: 4px;
    font-size: 12px;
    line-height: 20px;
    color:RGBA(170, 170, 170, 1);
    margin-top: 3px;
}
.org-name{
  img{
    vertical-align: middle;
    margin-left: 5px;
  }
}
</style>
<style>
.row-active{
  background: rgb(245, 247, 250)!important;
}
</style>
