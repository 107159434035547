
import { defineComponent, onActivated, onBeforeUnmount, onDeactivated, onMounted, nextTick, watch } from 'vue'
import resize from '@/components/charts/mixins/resize'
import { init, EChartsOption } from 'echarts'
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '30px',
      required: true
    },
    chartData: {
      type: Array,
      default: []
    },
  },
  setup(props) {
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()

    watch(props, (newName, oldName) => {
      initChart()
    });

    const initChart = () => {
      const chartData = (props as any).chartData
      if( !chartData || !chartData.length ){
        return false;
      }
      const barChart = init(document.getElementById(props.id) as HTMLDivElement)
      const xData = Array.from(chartData, ({ date }) => date);
      const serviesData1 = Array.from(chartData, ({ close }) => close);
      const maxnum = Math.max(...serviesData1)
      const minnum = Math.min(...serviesData1)
      // let xData:any = [1,2,3,4,5,6]
      // let serviesData1 = [1,2,3,4,-1,-2];
      barChart.setOption({
        grid: {
          top: '2px',
          left: '2px',
          right: '2px',
          bottom: '2px',
          containLabel: true
        },
        toolbox: {
          show: true
        },
        calculable: true,
        xAxis: [
          {
            type: 'category',
            // prettier-ignore
            data: xData,
            axisLine: {
              show: true,
              lineStyle: {
                color: "#BFBFBF",
                type: "dashed"
              }
            },
            // splitLine: { show: false },
            // axisLabel:{show:false},
            axisTick: { show: false },
            axisLabel: {
              //x轴文字的配置
              show: false,
              interval: 0,//使x轴文字显示全
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: { show: false },
            // axisTick: { show: false },
            axisLabel: { show: false },
            max: maxnum,
            min: minnum
          }
        ],
        visualMap: [
          {
            type: 'piecewise',
            show: false,
            pieces: [
              {
                lte: 0,   //数据<0配置
                color: '#5CBC7C',  //设置区域背景色
              },
              {
                gte: 0.1,  //数据>0配置
                color: '#F74E4F',  //设置区域背景色
              },
            ],
            seriesIndex: 0,
          },
        ],
        series: [
          {
            name: '',
            barGap: '80%',
            barWidth: '60%', // 柱子宽度
            type: 'line',
            showSymbol: false,
            data: serviesData1,
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 1,
                  // color: "#F74E4F"
                },
              }
            }
          }
        ]
      } as EChartsOption)
      chart.value = barChart
    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {

    }
  }
})
