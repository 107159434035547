
import {
  defineComponent,
  toRefs,
  reactive,
  nextTick,
  ref
} from 'vue'


import { useRoute } from "vue-router";

import { getFixedIncrBoards } from '@/apis/fixed-increase'

export default defineComponent({
  components: {
  },
  setup(props, context) {
    let factorDataList = reactive({
      list: [
        {
          firstLevel: 1,
          key: 2,
          code: 'asz',
          name: '市值(亿）',
          children: [{
            parentKey: 3,
            parentCode: 'asz',
            parentName: '按市值(亿）',
            prop: 'pb',
            key: '3-1',
            name: '30以下',
            maxValue: 30,
            minValue: ''
          },{
            parentKey: 3,
            parentCode: 'asz',
            parentName: '按市值(亿）',
            prop: 'pb',
            key: '3-2',
            name: '30-50',
            maxValue: 50,
            minValue: 30
          },{
            parentKey: 3,
            parentCode: 'asz',
            parentName: '按市值(亿）',
            prop: 'pb',
            key: '3-3',
            name: '50-100',
            maxValue: 100,
            minValue: 50
          },{
            parentKey: 3,
            parentCode: 'asz',
            parentName: '按市值(亿）',
            prop: 'pb',
            key: '3-4',
            name: '100-300',
            maxValue: 300,
            minValue: 100
          },{
            parentKey: 3,
            parentCode: 'asz',
            parentName: '按市值(亿）',
            prop: 'pb',
            key: '3-5',
            name: '300-1000',
            maxValue: '1000',
            minValue: 300
          },{
            parentKey: 3,
            parentCode: 'asz',
            parentName: '按市值(亿）',
            prop: 'pb',
            key: '3-6',
            name: '1000以上',
            maxValue: '',
            minValue: 1000
          },{
            parentKey: 3,
            parentCode: 'asz',
            parentName: '按市值(亿）',
            prop: 'pb',
            type: 'custom',
            key: '3-7',
            name: '自定义',
            maxValue: '',
            minValue: ''
          }]
        },
        {
        firstLevel: 1,
        key: 3,
        code: 'dzzt',
        name: '定增状态',
        children: [{
          parentKey: 2,
          parentCode: 'dzzt',
          parentName: '定增状态',
          prop: 'dzzt',
          key: '2-0',
          name: '已完成',
          value: 1
        },{
          parentKey: 2,
          parentCode: 'dzzt',
          parentName: '定增状态',
          prop: 'dzzt',
          key: '2-1',
          name: '进行中',
          maxValue: 10,
          value: 0
        }]
        }
      ]
    })

    

    const route = useRoute();
    const itemRefs = ref(null);
    const sortTable = ref(null);

    const dataMap = reactive({
      formInline: {
        industryName: '',//行业名称
      },
      // factorChildrenList: factorDataList.list[0].children as any,
      factorChildrenList: [],
      currentCheckValuetion: 'hy',
      selectedIndustry: '',
      rangeCheckKeyList: ['pettm'], // 范围筛选的父级key集合
      // rangeCheckChildKeyList: ['2-2'], // 范围筛选的子集的key集合
      rangeCheckChildKeyList: [], // 范围筛选的子集的key集合
      rangeCheckList: [],
      // rangeCheckList: [{
      //   parentKey: 2,
      //   parentCode: 'pettm',
      //   parentName: 'PETTM(倍）',
      //   prop: 'peTtm',
      //   key: '2-0',
      //   name: '10-20',
      //   maxValue: 20,
      //   minValue: 10
      // }], // 范围筛选条件合集(默认综合估值10-20)
      industryActive: -1,
      page: {
        totalSize: 0,
        pageSize: 10,
        pageNum: 1,
      },
      page2: {
        totalSize: 0,
        pageSize: 10,
        pageNum: 1,
      },
      sortMap: {
        sortKey: 'peTtm',
        isAsc: true,
      },
      customValue1: '',
      customValue2: '',
      isVaild1: false,
      isVaild2: false,
      isDisabled: true,
      hyList: {}
    })

    //获取行业列表
    const fetchData = async () => {
      let data:any = await getFixedIncrBoards({  })
      dataMap.hyList = JSON.parse(JSON.stringify(data.data))
      console.log('dataMap.hyList',dataMap.hyList)
      let arr:any = []
      let obj = {
        firstLevel: 1,
        key: 1,
        code: 'hy',
        name: '行业',
        children: []
      }
      data.data = [...data.data.swl1,...data.data.swl2]
      data.data.map((item:any, idx:any)=>{
        arr.push({
            parentKey: 2,
            parentCode: 'hy',
            parentName: '行业',
            prop: 'hy',
            key: `1-${idx}`,
            name: item,
            // value: item
          })
      })
      obj.children = arr
      factorDataList.list = [obj,...factorDataList.list]
      dataMap.factorChildrenList = factorDataList.list[0].children as any,
      console.log(factorDataList)
    }
    fetchData()

    // 选择行业
    const selectedHy = (param:any) => {
      // console.log(param)
      const list = factorDataList.list[0].children as any
      list.map((item:any)=>{
        if( item.name === param.code ){
          console.log(item)
          checkChildrenRange(item)
          onSearch()
        }
      })
    }

    // 选择市值
    const selectedSz = (param:any) => {
      // console.log(param)
      changeFactorType(factorDataList.list[1])
      checkChildrenRange(factorDataList.list[1].children[param])
      onSearch()
      // dataMap.currentCheckValuetion = 'asz'
      // console.log(param)
      // const list = factorDataList.list[0].children as any
      // list.map((item:any)=>{
      //   if( item.name === param.code ){
      //     console.log(item)
      //     checkChildrenRange(item)
      //     onSearch()
      //   }
      // })
    }

    // 删除行业
    const delIndustryTab = () => {
      dataMap.formInline.industryName = ''
      dataMap.selectedIndustry = ''
      dataMap.industryActive = -1
      dataMap.page.pageNum = 1
      dataMap.page2.pageNum = 1
      // fetchData()
    }

    // 选中范围子集
    const checkChildrenRange = (item:any) => {
      console.log(888)
      if(item.type === 'custom') return;
      const newArr = JSON.parse(JSON.stringify(dataMap.rangeCheckList)).filter((items:any) => items.parentCode !== item.parentCode).concat(item); 
      dataMap.rangeCheckList = newArr
      dataMap.rangeCheckKeyList = newArr.map((items:any) => items.parentCode);
      dataMap.rangeCheckChildKeyList = newArr.map((items:any) => items.key);
      const defaultSort = dataMap.rangeCheckList[0];
      // nextTick(() => {
      //   let { clearSort, sort } = sortTable.value as any;
      //   clearSort();
      //   sort(defaultSort['prop'], 'ascending');
      // })
      dataMap.sortMap = { sortKey: defaultSort ? defaultSort['prop'] : '',  isAsc: true }
      dataMap.page.pageNum = 1
      dataMap.page2.pageNum = 1
      // getDataList2()
    }

    // 删除范围子集
    const delChildrenRange = (item:any) => {
      const newArr = JSON.parse(JSON.stringify(dataMap.rangeCheckList)).filter((items:any) => items.parentCode !== item.parentCode); 
      dataMap.rangeCheckList = newArr
      dataMap.rangeCheckKeyList = newArr.map((items:any) => items.parentCode);
      dataMap.rangeCheckChildKeyList = newArr.map((items:any) => items.key);
      const defaultSort = dataMap.rangeCheckList[0];
      if(defaultSort) {
        // nextTick(() => {
        //   let { clearSort, sort } = sortTable.value as any;
        //   clearSort();
        //   sort(defaultSort['prop'], 'ascending');
        // })
        // dataMap.sortMap = { sortKey: defaultSort ? defaultSort['prop'] : '',  isAsc: true };
      }
      dataMap.page.pageNum = 1
      dataMap.page2.pageNum = 1
      // fetchData()
    }

    const saveCustomValue = (item:any) => {
      const newItem = {
        ...item,
        name: `${item.name}:[${dataMap.customValue1},${dataMap.customValue2}]`,
        maxValue: dataMap.customValue2,
        minValue: dataMap.customValue1
      }
      const newArr = JSON.parse(JSON.stringify(dataMap.rangeCheckList)).filter((items:any) => items.parentCode !== item.parentCode).concat(newItem); 
      dataMap.rangeCheckList = newArr
      dataMap.rangeCheckKeyList = newArr.map((items:any) => items.parentCode);
      dataMap.rangeCheckChildKeyList = newArr.map((items:any) => items.key);
      const defaultSort = dataMap.rangeCheckList[0];
      nextTick(() => {
        // let { clearSort, sort } = sortTable.value as any;
        // clearSort();
        // sort(defaultSort['prop'], 'ascending');
      })
      dataMap.customValue1 = '';
      dataMap.customValue2 = '';
      dataMap.isDisabled = true;
      dataMap.isVaild1 = false;
      dataMap.isVaild2 = false;
      nextTick(() => {
        // let obj = itemRefs.value as any;
        // obj.doDestroy();
        // obj.visibility = false
      })
      dataMap.sortMap = { sortKey: defaultSort ? defaultSort['prop'] : '',  isAsc: true }
      dataMap.page.pageNum = 1
      dataMap.page2.pageNum = 1
      // getDataList2() 
    }

    // 切换估值类型
    const changeFactorType = (item:any) => {
      dataMap.customValue1 = '';
      dataMap.customValue2 = '';
      dataMap.isDisabled = true;
      dataMap.isVaild1 = false;
      dataMap.isVaild2 = false;
      dataMap.currentCheckValuetion = item.code;
      dataMap.factorChildrenList = item.children || [];
    }

    const getParams = () => {
      
      const hyObj = (dataMap.rangeCheckList || []).find((item:any) => item.parentCode === 'hy') || {};
      const aszObj = (dataMap.rangeCheckList || []).find((item:any) => item.parentCode === 'asz') || {};
      const dzztObj = (dataMap.rangeCheckList || []).find((item:any) => item.parentCode === 'dzzt') || {};
      
      const params = {
        industry: hyObj['name']? hyObj['name'] : '',
        totalMarketCap: aszObj['minValue'] || aszObj['maxValue'] ? aszObj['minValue'] + ',' + aszObj['maxValue'] : '',
        fixedStatus: dzztObj ? dzztObj['value'] : ''
      }
      return params;
    }

    const changeCustomValue = (e:any, type:String) => {
      const reg = /^(0|([1-9][0-9]*))(\.[\d]{1,2})?$/; 
      const result = reg.test(e);
      if(type === 'value1'){
        dataMap.isVaild1 = !result;
      }else {
        dataMap.isVaild2 = !result;
      }
      if(!dataMap.customValue1 && !dataMap.customValue2){
        dataMap.isDisabled = true;
      }else{
        if(dataMap.customValue1 && !dataMap.isVaild1 && dataMap.customValue2 && dataMap.isVaild2){
          dataMap.isDisabled = true;
        }else if(dataMap.customValue1 && dataMap.isVaild1 && dataMap.customValue2 && !dataMap.isVaild2){
          dataMap.isDisabled = true;
        }else if(dataMap.customValue1 && dataMap.isVaild1 && !dataMap.customValue2){
          dataMap.isDisabled = true;
        }else{
          dataMap.isDisabled = false;
        }
      }
    }

    // 搜索
    const onSearch = () => {
      console.log(getParams())
      // const params = {
      //   ...getParams(),

      // }
      context.emit('search', getParams())
    }

    return {
      ...toRefs(dataMap),
      factorDataList,
      sortTable,
      delChildrenRange,
      delIndustryTab,
      saveCustomValue,
      checkChildrenRange,
      changeFactorType,
      changeCustomValue,
      onSearch,
      selectedHy,
      selectedSz
    }
  }
})
