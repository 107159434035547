
import { defineComponent, nextTick, reactive, toRefs, ref, onMounted } from 'vue'
import navTitle from '../components/nav-title.vue'
import navTab from '../components/nav-switch.vue'
import purchaseTable from './component/net-purchase-table.vue'
import sharesTableComponent from './component/individual-shares-table.vue'
import tabSwitch from '../components/tab-switch.vue'
import statisticsTable from './component/statistics-table.vue'
import rankingTable from './component/ranking-table.vue'
import industryTable from './component/industry-ranking-table.vue'
import rankingCharts from './component/ranking-charts.vue'
import { getMarginRanking, getStockCharts } from '@/apis/margin-trading'
import Search from '@/components/search-input/Index.vue'
import IndividualSharesTable from '@/views/margin-trading/home-page/component/individual-shares-table.vue'
import stockCharts from './/component/stock-charts.vue'
import { getStockTradeDate } from '@/apis/market'
import moment from 'moment'
export default defineComponent({
  components: {
    IndividualSharesTable,
    navTitle,
    navTab,
    purchaseTable,
    sharesTableComponent,
    tabSwitch,
    statisticsTable,
    rankingTable,
    industryTable,
    rankingCharts,
    Search,
    stockCharts
  },
  setup() {
    const industryTable = ref(null)
    const netTable = ref(null)
    const sharesRef = ref(null)
    const rankingTableRef = ref(null)
    const statisticsRef = ref(null)
    const dataMap = reactive({
      stockDate: '',
      initialStatus: 1,
      tabList: [
        {
          id: 1,
          name: '由降转增'
        },
        {
          id: 2,
          name: '由增转降'
        }
      ],
      incrLabelBefore: '',
      industryStatus: 1, // 行业排行nav初始状态
      industryNavType: 'swl1',
      tabStatus: '1d',
      parentBoardName: '',
      styleStatus: false,
      // beforeTime: {
      //   startDate: '',
      //   endDate: ''
      // },
      industryTabList: [
        {
          id: 1,
          name: '申万一级',
          value: 'swl1'
        },
        {
          id: 2,
          name: '申万二级',
          value: 'swl2'
        }
      ],
      sharesTabList: [
        {
          id: 1,
          name: '全部',
          code: 'ALL'
        },
        {
          id: 2,
          name: '沪深A股',
          code: 'HU_SHEN_A'
        },
        {
          id: 3,
          name: '沪市A股',
          code: 'HU_A'
        },
        {
          id: 4,
          name: '科创板',
          code: 'KE_CHUANG'
        },
        {
          id: 5,
          name: '深市A股',
          code: 'SHEN_A'
        },
        {
          id: 6,
          name: '创业板',
          code: 'CHUANG_YE'
        }
      ],
      radio: 1,
      marginRankData: {
        rzjme: [],
        rzmre: [],
        rzye: [],
        rqjme: [],
        rqye: [],
      }, // 排行图表总数居
      marginCurrentData: [], // 排行图表当前数居
      rankingNavItem: {}, // 前十切换当前值
      rankingTabItem: {}, // 前十tab切换当前值
      stockChartsList: [], // 前十图表
      sharesTimeItem: {},
      sharesNavItem: {}
    })
    const getTime = async () => {
      const data6 = await getStockTradeDate({ })
      dataMap.stockDate = moment((data6 as any).data.prevRzrqTradeDate).format('YYYY年MM月DD日');
    }
    getTime()
    const netBuyingRanking = (item: any) => {
      if (netTable.value) {
        (netTable as any).value.fetchData(item)
      }
    }
    netBuyingRanking({id: 1, name: '由降转增'})
    const changeNetTab = (item: any) => {
      netBuyingRanking(item)
    }
    const sharesRanking = (item: any) => {
      if (sharesRef.value) {
        (sharesRef as any).value.fetchData(item)
      }
    }
    sharesRanking({id: 1, name: '由降转增'})
    const changeSharesTab = (item: any) => {
      sharesRanking(item)
    }
    const radioClick = (item: any) => {
      dataMap.radio = item;
      if (item === 1) {
        dataMap.marginCurrentData = dataMap.marginRankData.rzjme
      } else if (item === 2) {
        dataMap.marginCurrentData = dataMap.marginRankData.rzmre
      } else if (item === 3) {
        dataMap.marginCurrentData = dataMap.marginRankData.rzye
      } else if (item === 4) {
        dataMap.marginCurrentData = dataMap.marginRankData.rqjme
      } else if (item === 5) {
        dataMap.marginCurrentData = dataMap.marginRankData.rqye
      }
    }
    const fetchData = async () => {
      const params = {
        boardType: dataMap.industryNavType,
        parentBoardName: dataMap.parentBoardName,
        timeLevel: dataMap.tabStatus,
        // startDate: dataMap.beforeTime.startDate,
        // endDate: dataMap.beforeTime.endDate,
      }
      const marginListRank = await getMarginRanking(params)
      if ((marginListRank as any).code === 200) {
        dataMap.marginRankData = (marginListRank as any).data
        nextTick(() => {
          radioClick(dataMap.radio)
        })
      }
    }
    const tabChangeIndustry = (item: any, time: any) => {
      dataMap.tabStatus = item.value
      dataMap.incrLabelBefore = item.incrLabel
      // if (time.type === 'custom') {
      //   dataMap.beforeTime = time
      // }
      fetchData()
      if (industryTable.value) {
        (industryTable as any).value.fetchData(item, { boardType: dataMap.industryNavType,
          parentBoardName: dataMap.parentBoardName,
          timeLevel: dataMap.tabStatus,
        })
      }
    }
    tabChangeIndustry({label: '今日', value: '1d'}, {})
    const changeIndustryTab = (item: any) => {
      dataMap.parentBoardName = ''
      if (dataMap.styleStatus) {
        dataMap.styleStatus = false
      }
      dataMap.industryNavType = item.value
      fetchData()
      if (industryTable.value) {
        (industryTable as any).value.fetchData(dataMap.tabStatus, { boardType: dataMap.industryNavType,
          parentBoardName: dataMap.parentBoardName,
          timeLevel: dataMap.tabStatus,
        })
      }
    }
    const chartsClick = (item: any, styleType: any) => {
      dataMap.parentBoardName = item
      dataMap.industryNavType = 'swl2'
      dataMap.styleStatus = styleType
      fetchData()
    }
    // 融资融券个股排行
    const rankTableList = () => {
      if (rankingTableRef.value) {
        (rankingTableRef as any).value.fetchData({}, dataMap.rankingNavItem, dataMap.rankingTabItem)
      }
    }
    const rankNav = (item: any) => {
      dataMap.rankingNavItem = item;
      rankTableList()
    }
    rankNav({ name: '全部', id: 1 })
    const stockChartList = async (item: any) => {
      const params = {
        timeUnit: item.timeUnit,
        num: item.num,
      }
      const stockList = await getStockCharts(params)
      if ((stockList as any).code === 200) {
        dataMap.stockChartsList = (stockList as any).data
      }
    }
    const tabChangeRanking = (item: any) => {
      dataMap.rankingTabItem = item
      stockChartList(item)
      rankTableList()
    }
    tabChangeRanking({ label: '1日', value: '1d', timeUnit: 'DAYS', num: 1 })
    const statisticalTable = () => {
      if (statisticsRef.value) {
        (statisticsRef as any).value.fetchData({}, dataMap.sharesTimeItem, dataMap.sharesNavItem)
      }
    }
    const sharesChangeTab = (item: any) => {
      dataMap.sharesNavItem = item
      statisticalTable()
    }
    sharesChangeTab({ name: '全部', id: 1 })
    const sharesTabChange = (item: any) => {
      dataMap.sharesTimeItem = item;
      statisticalTable()
    }
    sharesTabChange({ label: '今日', value: '1d', timeUnit: 'DAYS', num: 1 })
    return {
      industryTable,
      netTable,
      sharesRef,
      rankingTableRef,
      statisticsRef,
      ...toRefs(dataMap),
      changeNetTab,
      changeIndustryTab,
      changeSharesTab,
      radioClick,
      tabChangeIndustry,
      chartsClick,
      rankTableList,
      tabChangeRanking,
      rankNav,
      sharesChangeTab,
      sharesTabChange
    }
  }
})
