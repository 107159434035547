
import { defineComponent, reactive, toRefs } from 'vue'
export default defineComponent({
  props: {
    boardList: {
      type: Array,
      default: []
    }
  },
  setup() {
    const dataMap = reactive({
    })
    const headerMethod = (item: any) => {
      const { row, columnIndex } = item
      if (row[0].level == 1) {
        row[4].colSpan = 0
        row[5].colSpan = 2
        if (columnIndex === 4) {
          return { display: 'none' }
        }
      }
    }
    const unitConversion = (item: any) => {
      return ((Number(item) / 100000000) / 10000).toFixed(2) + '万亿'
    }
    const goDetail = (item: any) => {
      window.open(`/page/data_center_all?industryName=${item.boardName}&boardType=market`)
    }
    const keepTwo = (item: any) => {
      return Number(item).toFixed(2)
    }
    return {
      ...toRefs(dataMap),
      headerMethod,
      unitConversion,
      goDetail,
      keepTwo
    }
  }
})
