<template>
    <el-scrollbar>
    <div class="market_box">
      <div class="item_box" v-for="(item, index) in zsList" :key="index" @click="goDetails(item)">
        <div :class="item.incr > 0 ? 'content_box' : 'content_green_box'">
          <div class="title_box">{{ item.boardName }}</div>
          <div class="number_box" v-dClass="item.incr">{{ keepTwo(item.price) }}</div>
          <div class="two_number_box">
            <span v-dClass="item.incr">{{ item.incrAmt > 0 ? '+' + keepTwo(item.incrAmt) : keepTwo(item.incrAmt) }}</span>
            <span v-dClass="item.incr">{{ item.incr > 0 ? '+' + keepTwo(item.incr) : keepTwo(item.incr) }}%</span>
          </div>
        </div>
        <div class="progress_bar_box">
          <div class="rise_box" :style="{width: item.riseCount / (item.riseCount + item.flatCount + item.fallCount) * 100 + '%'}"></div>
          <div class="flat_box" :style="{width: item.fallCount / (item.riseCount + item.flatCount + item.fallCount) * 100 + '%'}"></div>
          <div class="fall_box" :style="{width: item.flatCount / (item.riseCount + item.flatCount + item.fallCount) * 100 + '%'}"></div>
        </div>
      </div>
    </div>
    </el-scrollbar>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    zsList: {
      type: Array,
      default: []
    }
  },
  setup() {
    const goDetails = (item: any) => {
      window.open(`/page/data_center_all?industryName=${item.boardName}&boardType=index`)
    }
    const keepTwo = (item: any) => {
      return Number(item).toFixed(2)
    }
    return {
      goDetails,
      keepTwo
    }
  }
})
</script>
<style scoped lang="scss">
 .market_box {
    width: 1092px;
    min-height: 240px;
    margin-right: 80px;
    display: flex;
    flex-wrap: wrap;
    > .item_box {
      width: 146px;
      height: 110px;
      margin: 0 10px 10px 0;
      cursor: pointer;
      > .content_box {
        background: linear-gradient(180deg, #FEF2F3 0%, #FFFCFC 100%);
        border-radius: 6px;
        padding-bottom: 8px;
        > .title_box {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 22px;
          text-align: center;
          padding-top: 10px;
        }
        > .number_box {
          font-size: 21px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          line-height: 29px;
          margin-top: 3px;
          text-align: center;
        }
        > .two_number_box {
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #F74E4F;
          line-height: 17px;
          margin-top: 9px;
          text-align: center;
          > span {
            &:first-child {
              margin-right: 11px;
            }
          }
        }
      }
      > .content_green_box {
        background: linear-gradient(180deg, #EAF9F2 0%, #F7FCFB 100%);
        border-radius: 6px;
        padding-bottom: 8px;
        > .title_box {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 22px;
          text-align: center;
          padding-top: 10px;
        }
        > .number_box {
          font-size: 21px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          line-height: 29px;
          margin-top: 3px;
          text-align: center;
        }
        > .two_number_box {
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #F74E4F;
          line-height: 17px;
          margin-top: 9px;
          text-align: center;
          > span {
            &:first-child {
              margin-right: 11px;
            }
          }
        }
      }
      > .progress_bar_box {
        display: flex;
        align-items: center;
        margin-top: 9px;
        > .rise_box {
          height: 3px;
          position: relative;
          background: #f54346;
          &::after{
            content:"";
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: #f54346;
            transform-origin: bottom left;
            transform: skewX(-30deg);
          }
        }
        > .flat_box {
          height: 3px;
          margin: 0 3px;
          position: relative;
          &::after{
            content:"";
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: #646d80;
            transform-origin: bottom left;
            transform: skewX(-30deg);
          }
        }
        > .fall_box {
          height: 3px;
          position: relative;
          background: #14ba71;
          overflow: hidden;
          &::after{
            content:"";
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: #ffffff;
            transform-origin: bottom left;
            transform:  rotate(-58deg);
          }
        }
      }
    }

  }
</style>
