<!--
 * @Description: 搜索
-->
<template>
  <div class="search">
    <el-input
      v-model="searchVal"
      @input="fetchData"
      @focus='fetchData'
      @blur='blurHandler'
      class="w-50 m-2"
      :prefix-icon="iconPosition === 'prefix' ? 'el-icon-search' : ''"
      :suffix-icon="iconPosition === 'suffix' ? 'el-icon-search' : ''"
      :placeholder="holder ? holder : isResearch ? '请输入股票、行业、机构名称' : '请输入股票代码或简称'"
    />
      <div class="search_box" :class="searchList.length>0?'showsearch_box':''" catchtap="noclose">
            <div class="search_box_list_box" scroll-y enhanced='{{true}}'  binddragging='binddragging1'>
              <div class="search_box_list">
                <div class="search_box_item" :class="item.state === 1 || item.state === 4 ? 'disabled' : ''" v-for="(item,index) in searchList" :key="index" @click.native="goDetail(item)">
                  <div class="search_box_item_box">
                    <div class="search_box_item_left">
                      <div class="search_box_item_left_text">{{item.name}}</div>
                      <div class="search_box_item_left_num">{{item.code}}</div>
                    </div>
                      <template v-if="isFunds || isNorthbound">
                        <div class="search_box_item_right" v-if="item.type === 'stock'">股票</div>
                        <div class="search_box_item_right" v-else>行业</div>
                      </template>
                      <!-- 股票状态[1:未上市,2:正常,3:停牌,4:退市] -->
                      <template v-else-if="!isResearch">
                        <div class="search_box_item_right" v-if="item.state === 1">未上市</div>
                        <div class="search_box_item_right" v-else-if="item.state === 4">退市</div>
                        <div class="search_box_item_right" v-else>{{rightText ?? '诊股'}}</div>
                      </template>
                      <template v-else-if='isResearch && item.type === "stock"'>
                        <div class="search_box_item_right" v-if="item.state === 1">未上市</div>
                        <div class="search_box_item_right" v-else-if="item.state === 4">退市</div>
                        <div class="search_box_item_right" v-else>诊股222</div>
                      </template>
                  </div>
                </div>
                <!-- <div class="search_box_last" @click="showMore" v-if="isMore">查看更多内容 ></div> -->
              </div>
            </div>
          </div>
    <!-- </div> -->
  </div>
</template>

<script lang="ts">
import { defineComponent, getCurrentInstance, onMounted, reactive, toRefs, ref } from 'vue'
import { getStockQuery } from '@/apis/home'
import { getSurveySearch } from '@/apis/research'
import { getFundsSearch } from '@/apis/northboundFunds'
import { getFinanceReportSearch } from '@/apis/financial-report'
// 融资融券搜索接口
import { getMarginSearch } from '@/apis/margin-trading'

import { useRouter } from 'vue-router'

export default defineComponent({
  props: {
    path: {
      type: String
    },
    open: {
      type: Boolean
    },
    isResearch: {
      type: Boolean
    },
    isFunds: {
      type: Boolean
    },
    isNorthbound: {
      type: Boolean
    },
    isFinancial: {
      type: Boolean
    },
    holder: {
      type: String
    },
    iconPosition: {
      type: String,
      default: 'suffix'
    },
    isStockDetail: {
      type: String,
    },
    rightText: {
      type: String,
    }
  },
  setup(props,context) {
    const router = useRouter()
    const dataMap = reactive({
      searchVal: '',
      searchList: [],
    })

    let timer:any = null;
    const fetchData = () => {
      if( !dataMap.searchVal ){
        clearTimeout(timer)
        // dataMap.searchList = getSearchHistoryList()
        return false
      }
      if( timer ){
        clearTimeout(timer)
      }
      timer = setTimeout(async ()=>{
        if( props.isResearch ){
          let data = await getSurveySearch({ keyWord: dataMap.searchVal, limit: 20})
          dataMap.searchList = (data as any).data;
        }else if( props.isFunds ){
          let data = await getFundsSearch({ keyword: dataMap.searchVal, limit: 20})
          dataMap.searchList = (data as any).data;
        }else if( props.isNorthbound ){
          let data = await getMarginSearch({ keyword: dataMap.searchVal, limit: 20})
          dataMap.searchList = (data as any).data;
        }else if( props.isFinancial ){
          let data = await getFinanceReportSearch({ keyword: dataMap.searchVal, limit: 20})
          dataMap.searchList = (data as any).data;
        }else{
          let data = await getStockQuery({ keyword: dataMap.searchVal, limit: 20})
          dataMap.searchList = (data as any).data;
        }
      },500)
    }

    const blurHandler = () => {
      setTimeout(()=>{
        dataMap.searchList = []
      },200)
    }

    const goDetail = (item:any) => {
      if( item.state === 1 || item.state === 4 ){
        return false;
      }

      setSearchHistoryList(item)

      if( props.isResearch ){
        if( item.type === 'stock' ){
          window['$props'].push({
            path: `/investment${props.path}`,
            query:{
              code: item.code,
              stockCode: item.name
            }
          })
        }else if( item.type === 'industry' ){
          window['$props'].push({
            path: `/investment${props.path}`,
            query:{
              industryName: item.name
            }
          })
        }else if( item.type === 'org' ){
          window['$props'].push({
            path: `/investment${props.path}`,
            query:{
              orgName: item.name
            }
          })
        }

        return false
      }

      if( props.isFunds ){
        if( item.type === 'stock' ){
          const path = '/northboundFunds/shares'
          window.open( `/investment${path}?code=${item.code}`)
        }else if( item.type === 'industry' ){
          const path = '/northboundFunds/industry'
          const boardType = item.boardType ? item.boardType : 'swl2'
          window.open( `/investment${path}?code=${item.name}&boardType=${boardType}`)
        }
        return false;
      }
      // 融资融券
      if( props.isNorthbound ){
        if( item.type === 'stock' ){
          console.log(item)
          const path = '/marginTrading/shares'
          window.open( `/investment${path}?code=${item.code}&name=${item.name}`)
        }else if( item.type === 'industry' ){
          const path = '/marginTrading/industry'
          const boardType = item.boardType ? item.boardType : 'swl2'
          window.open( `/investment${path}?code=${item.name}&boardType=${boardType}`)
        }
        return false;
      }
      // 财报
      if( props.isFinancial ){
        const path = '/financial/detailed'
        window.open( `/investment${path}?stockCode=${item.code}&stockName=${item.name}`)
        return false;
      }

      //个股详情
      if( props.isStockDetail ){
        const path = props.path
        window.open( `/investment${path}?stockCode=${item.code}&stockName=${item.name}`)
        return false;
      }

      if( props.open ){
        window.open(window['location']['origin']+window['location']['pathname']+'?code='+item.code)
        return false
      }
      window['$props'].push({
        path: `/investment${props.path}`,
        query:{
          code: item.code
        }
      })
    }

    const setSearchHistoryList = (item:any) => {
      const pathname = location.pathname.replace('/investment', '')
      const list = getSearchHistoryList()
      const { id, code, name } = item
      const obj = { id, code, name }
      if( list.filter((item:Object)=>item['code'] === code).length === 0 ){
        localStorage.setItem(pathname, list.length ? JSON.stringify([obj, ...list]) : JSON.stringify([obj]))
      }
    }

    const getSearchHistoryList = () => {
      const pathname = location.pathname.replace('/investment', '')
      const list = localStorage.getItem(pathname)
      if( list ){
        return JSON.parse(list)
      }
      return []
    }

    return {
      ...toRefs(dataMap),
      fetchData,
      goDetail,
      blurHandler
    }
  }
})

</script>
<style lang='scss' scoped>
.search{
  width: 670px;
  margin: 43px auto 0;
  position: relative;
  ::v-deep input{
    color: #212122;
    font-size: 18px;
    height: 50px;
    background: #FFFFFF;
    border: 2px solid #C2D6FF;
    border-radius: 6px;
  }
  ::v-deep .el-input__suffix{
    font-size: 24px;
    line-height: 50px;
    top: 0;
    right: 28px;
  }
}

.search_box{
    width: 100%;
    background: #fff;
    max-height: 70vh;
    overflow-y: auto;
    position: absolute;
    top: 50px;
    left: 0;
    z-index: 200;
    display: none;
}
.showsearch_box{
    display: block;
}
.search_box_list_box{
    box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.2);
    padding-top: 15px;
}
.search_box_item{
    padding: 0 20px;
    cursor: pointer;
    &.disabled{
      cursor: context-menu;
      .search_box_item_right{
        background: #ccc;
      }
    }
    &:hover{
      background: #f1f1f1;
    }
}
.search_box_item_box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 57px;
    border-bottom: 2px solid #F4F4F4;
}
.search_box_item_right{
    width: 55px !important;
    height: 25px;
    padding: 0;
    margin-right: 0 !important;
    line-height: 25px;
    border-radius: 12px;
    background: #FF423A;
    font-size: 14px;
    text-align: center;
    color: #fff;

}
.search_box_item_left_text{
    font-size: 15px;
    color: RGBA(66, 66, 66, 1);
}
.search_box_item_left_num{
    font-size: 13px;
    color:RGBA(170, 170, 170, 1);
    margin-top: 3px;
}
</style>
