<template>
  <div class="tab">
    <ul>
      <li :class="activeType === index ? 'active' : ''" @click="switchItem(item, index)" v-for="(item,index) in optionList.tabList" :key='index'>{{item.label}}</li>
    </ul>
    <el-date-picker
      v-if="isShowDate"
      class="date-picker"
      unlink-panels
      v-model="selectTime"
      type="daterange"
      value="YYYY-MM"
      value-format="yyyy-MM-dd HH:mm"
      start-placeholder="开始时间"
      end-placeholder="结束时间"
      @change='getTime'
    />
  </div>
</template>

<script lang="ts">
import { reactive, defineComponent, toRefs } from 'vue'
import optionList from '@/utils/information-acquisition'
export default defineComponent({
  props:{
    activeStatus : {
      type : Number,
      default : 4
    },
  },
  setup(props, context) {
    const dataMap = reactive({
      optionList,
      activeType: props.activeStatus,
      selectTime: '',
      isShowDate: false,
      beforeOneItem: {}
    })
    const switchItem =  (item: any, index: any) => {
      dataMap.activeType = index
      if (item.type === 'custom') {
        dataMap.isShowDate = true
        dataMap.beforeOneItem = item
      } else {
        dataMap.isShowDate = false
        dataMap.beforeOneItem = item
        context.emit('tabChange', item, {})
      }
    }
    const getTime = (item: any) => {
      console.log(item, '获取时间')
      context.emit('tabChange',dataMap.beforeOneItem, {
        startDate: window.moment(item[0]).format('YYYY-MM-DD'),
        endDate: window.moment(item[1]).format('YYYY-MM-DD'),
      })
    }
    return {
      ...toRefs(dataMap),
      switchItem,
      getTime
    }
  }
})
</script>

<style scoped lang="scss">

.tab{
  display: flex;
  margin-right: 20px;
  ul{
    display: flex;
    border-radius: 4px;
    overflow: hidden;
    li{
      width: 57px;
      height: 32px;
      line-height: 32px;
      cursor: pointer;
      background: #FFFFFF;
      text-align: center;
      color: #666666;
      border: 1px solid #DDDDDD;
      border-right: none;
      &.active{
        color: #386FF2;
        border-color: #386FF2;
        &+li{
          border-left-color: #386FF2;
        }
      }
      &:last-child.active{
        border-right-color: #386FF2;
      }
      &:last-child{
        border-right: 1px solid #DDDDDD;
        border-radius: 0 4px 4px 0;
      }
      &:first-child{
        border-radius: 4px 0 0 4px;
      }
    }
  }
  ::v-deep .date-picker{
    width: 260px!important;
    height: 32px!important;
    margin-left: 15px;
  }
}
</style>
