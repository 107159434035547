

import { defineComponent, reactive, toRefs, ref } from 'vue'
import breadcrumb from '@/components/breadcrumb/Index.vue'
import CTitle from "@/components/title/Index.vue"
import CPagination from "@/components/pagination/Index.vue"

import { useRoute  } from "vue-router";

import { getReportFxsList, getStockRatingDetailList, getStockRatingList } from '@/apis/agency'
import { getOrgIndustryBoardList, getOrgIndustryBoardMore, getReportCalculateGains } from '@/apis/research'
import dicts from '@/utils/dicts'
import { getStockQuery, getReportOrganizationList } from '@/apis/home'

import moment from 'moment'
export default defineComponent({
  components: {
    CTitle,
    breadcrumb,
    CPagination
  },
  props: {
    from: {
      type: String
    }
  },
  setup(props) {
    
    const route = useRoute()
    const stockCode = route.query.code
    const prevPageRatingIdx = Number(route.query.idx)
    const pagin = ref<InstanceType<typeof CPagination>>() 
    const refTab = ref(null)

    const dataMap = reactive({
      loading: true,
      visible: false,
      textIndustry:'',
      selectedIndustry: '',
      industryActive: -1,
      ratingActive: prevPageRatingIdx ? [prevPageRatingIdx] : [0],
      typeActive: [0],
      formInlineinit: {
        region: '',
        time: [],
        order: 'DESC'
      },
      formInline: {
        region: route.query.timeLevel ? dicts.month[Number(route.query.timeLevel)] : '',
        time: [],
        order: 'DESC',
        rating: prevPageRatingIdx ? dicts.ratingRelated[prevPageRatingIdx] : ''
      },
      stockList1: {},
      fxList: [],
      industryList: [],
      industryLastText: '',
      industryMoreList: [],


      dstxActive  : 0,
      showTimeSelect: false,
      selectTime: ['', ''],

      searchList: [],
      searchList2: [],

    })

    if( route.query.keywork ){
      dataMap.ratingActive = [Number(route.query.keywork)]
    }

    if( route.query.organizationSName ){
      dataMap.formInline['organizationSName'] = route.query.organizationSName
    }

    if( route.query.orgSName ){
      dataMap.formInline['organization'] = route.query.orgSName
    }

    //新版时间tab选择
    const swichItem = async (idx: any) => {
        dataMap.dstxActive = idx
        dataMap.showTimeSelect = idx === 5 ? true : false
        if( idx === 5 ){
          delete dataMap.formInline['timeLevel']
        }else{
          dataMap.formInline['timeLevel'] = dicts.timeSelection[idx].value
        }
    }

    if( route.query.timeLevel ){
      swichItem(Number(route.query.timeLevel) + 1)

      //自定义时间
      if( dataMap.showTimeSelect ){
        dataMap.formInline['beginDate'] = route.query.beginDate
        dataMap.formInline['endDate'] = route.query.endDate
        dataMap['selectTime'] = [String(route.query.beginDate), String(route.query.endDate)]
      }else{
        dataMap.selectTime = []
      }
    }else{
      dataMap.selectTime = []
    }

    //获取分析师列表
    const remoteMethod = async (item:any) => {
      let data1 = await getReportFxsList({ fxsStrLike: item })
      dataMap.fxList = (data1 as any).data
    }
    remoteMethod('')

    const fetchList = async () => {
      

      //获取分析师列表
      let data1 = await getReportFxsList({ })
      dataMap.fxList = (data1 as any).data

      if( !props['from'] ){
        //获取行业列表
        let data3 = await getOrgIndustryBoardList({ })
        dataMap.industryList = (data3 as any).data

        //获取行业更多列表
        let data2 = await getOrgIndustryBoardMore({ })
        dataMap.industryMoreList = (data2 as any).data
      }
      
      // industryMoreList
    }
    fetchList()

    const fetchData = async (_parms:any) => {
      // console.log(22222, props['from'])
      dataMap.loading = true
      dataMap.formInline = {...dataMap.formInline, ..._parms}

      const newForm = JSON.parse(JSON.stringify(dataMap.formInline))
      delete newForm['region']
      delete newForm['time']
      // delete newForm['industry']
      let rating:any = []
      dataMap.ratingActive.map((item:any)=>{
        if( item !== 0 ){
          rating.push(dicts.ratingRelated[item])
        }
      })
      newForm['rating'] = rating.join(',');

      let type:any = []
      dataMap.typeActive.map((item:any)=>{
        if( item !== 0 ){
          type.push(dicts.type[item])
        }
      })
      newForm['type'] = type.join(',');

      
      let data1 = props['from'] ? await getStockRatingDetailList({ 
        stockCode,
        ...newForm,
      }) : await getStockRatingList({ 
        stockCode,
        ...newForm,
        // ...route.query
      })
      
      dataMap.stockList1 = (data1 as any).data 
      
      if( !props.from ){
        dataMap['textIndustry'] = dataMap.selectedIndustry
      }
      dataMap.loading = false
    }
    
    //搜索按钮
    const onSearch = async () => {

      //自定义时间
      if( dataMap.showTimeSelect ){
        // delete time
        
        dataMap.formInline['beginDate'] = window.moment(dataMap.selectTime[0]).format('YYYY-MM-DD')
        dataMap.formInline['endDate'] = window.moment(dataMap.selectTime[1]).format('YYYY-MM-DD')
      }
      // moment(this.dialogcontent.kssj, 'YYYY-MM-DD kk:mm:ss').format('x')
      // 个股选择后查询必须清空行业选择，两者属于互斥，经产品确定
      if(dataMap.formInline['organizationSName']){
        dataMap.industryActive = -1
        dataMap.textIndustry = ''
        dataMap.selectedIndustry = ''
        dataMap.formInline['industry'] = ''
      }

      if( pagin && pagin.value ){
        pagin.value!.changeCurPage(1)
      }
    }

    //清空
    const onClearForm = async () => {
      dataMap.formInline = {
        pageNum: dataMap.formInline['pageNum'],
        pageSize: dataMap.formInline['pageSize'],
        ...JSON.parse(JSON.stringify(dataMap.formInlineinit))
      }
      dataMap.industryActive = -1

      dataMap.ratingActive = [0]
      dataMap.formInline['rating'] = ''

      dataMap.typeActive = [0]
      dataMap.formInline['type'] = ''

      dataMap.textIndustry = ''
      dataMap.selectedIndustry = ''

      swichItem(0)


      if( refTab ){
        (refTab as any).value.sort('date','descending')
      }

      if( pagin && pagin.value ){
        pagin.value!.changeCurPage(1)
      }
      // fetchData({});

    }
    
    

    

    //时间下拉框
    const handleChange = (obj:any) => {
      if( !obj ){
        obj = dicts.month[Number(route.query.timeLevel)]
      }

      const { timeLevelwz } = obj
      
      if( obj.value !== 4 ){
        dataMap.formInline['timeLevel'] = timeLevelwz
      }else{
        delete dataMap.formInline['timeLevel']
      }
    }
    
    
    //表格机构点击
    const organizationSelect = (organization:any) => {
      dataMap.formInline['organization'] = organization
      onSearch()
    }

    //表格行业点击
    const industrySelect = (industry:any) => {
      dataMap.industryList.map((item:any, idx:any)=>{
        if(item === industry){
          handleIndustryTab(idx, item)
          onSearch()
        }
      })
      //console.log(dataMap.industryMoreList)
      for (const key in dataMap.industryMoreList) {
        (dataMap.industryMoreList[key] as any).map((innerItem:any)=>{
          if(innerItem === industry){
            handleMoreClick(innerItem)
            onSearch()
          }
        })
      }
        
    }

    //行业选择
    const handleIndustryTab = (idx:any, item:any) => {
      console.log(111)
      dataMap['industryActive'] = idx
      dataMap['formInline']['industry'] = item
      dataMap.selectedIndustry = item
      // dataMap['industryLastText'] = ''
    }

    //行业更多选择
    const handleMoreClick = (item:any) => {
      console.log(item)
      dataMap['formInline']['industry'] = item
      dataMap.industryLastText = item
      dataMap.industryActive = -2
      dataMap.selectedIndustry = item
      dataMap.visible = false
    }

    //评级相关选择
    const handleRatingClick = (item:any, idx:any) => {

      // if( idx === 0 ){
      //   dataMap.ratingActive = [0]
      // }else{
      //   let removedArr =  dataMap.ratingActive.filter((x) => x !== 0);
      //   dataMap.ratingActive = removedArr

      //   const findIdx = dataMap.ratingActive.findIndex(item=>item===idx);
      //   if( findIdx !== -1 ){
      //     dataMap.ratingActive.splice(findIdx, 1)
      //   }else{
      //     dataMap.ratingActive.push(idx)
      //   }
      // }

      checkBox('ratingActive', idx)

      
      dataMap.formInline['rating'] = item === '不限' ? '' : item
    }

    //类型选择
    const handleTypeClick = (item:any, idx:any) => {
      
      checkBox('typeActive', idx)

      // dataMap.typeActive = idx
      dataMap.formInline['type'] = item === '不限' ? '' : item
    }

    //评级相关和类型多选
    const checkBox = (active:any, idx:any) => {

      if( idx === 0 ){
        dataMap[active] = [0]
      }else{
        let removedArr =  dataMap[active].filter((x:any) => x !== 0);
        dataMap[active] = removedArr

        const findIdx = dataMap[active].findIndex((item:any)=>item===idx);
        if( findIdx !== -1 ){
          dataMap[active].splice(findIdx, 1)
        }else{
          dataMap[active].push(idx)
        }
      }
    }

    //日期排序
    const sortChange = (column:any) => {
      let order = column.order === "ascending" ? 'ASC' : 'DESC';
      // getTableData2(column["prop"], isAsc);
      fetchData({order, orderBy:column["prop"]});
    }

    //报告名称
    const handleReport = (item:any) => {
      console.log(item)
      
      window.open(`/investment/agency/report?reportId=${item.reportId}`)
      // window['$props'].push({
      //   path: `/investment/agency/report`,
      //   query:{
      //     reportId: item.reportId
      //     // industryName: params['name'],
      //     // isPointOrg: params['seriesIndex']
      //   }
      // })
    }

    //列表跳转详情
    const handleGoDetail = (item:any) => {
      if( !props.from ){
        window.open(`/investment/agency/detail?code=${item.stockCode}`)
      }
    }

    //表格时间选择
    const HandleRowTime = async (item:any) => {
      let data2 = await getReportCalculateGains({ 
        stockCode: item.stockCode,
        beginDate: item.date + ' 23:59:59',
        endDate:   window.moment(item.time).format('YYYY-MM-DD HH:mm:ss')
      })
      item.customIncome = (data2 as any).data
      console.log(data2)
      // dataMap.industryMoreList = (data2 as any).data
    }


    const blurHandler = (res:any) => {
      setTimeout(()=>{
        dataMap.searchList = []
      },200)
    }
    let timer:any = null;
    const fetchSearchData = () => {
      if( timer ){
        clearTimeout(timer)
      }
      timer = setTimeout(async ()=>{
        let data = await getStockQuery({ keyword: dataMap.formInline['organizationSName'], limit: 20})
        let aaa = (data as any).data;
        // aaa = aaa.filter((item:any)=>item.state !== 1 && item.state !== 4)
        dataMap.searchList = aaa;
      },500)
      
    }
    const selectedSearch = (item:any) => {
      dataMap.formInline['organizationSName'] = item.name
      // console.log(item)
    }

    const blurHandler2 = (res:any) => {
      setTimeout(()=>{
        dataMap.searchList2 = []
      },200)
    }
    let timer2:any = null;
    const fetchSearchData2 = () => {
      if( timer2 ){
        clearTimeout(timer2)
      }
      timer2 = setTimeout(async ()=>{
        let data = await getReportOrganizationList({ orgStrLike: dataMap.formInline['organization']})
        let aaa = (data as any).data;
        dataMap.searchList2 = aaa;
      },500)
      
    }
    const selectedSearch2 = (item:any) => {
      dataMap.formInline['organization'] = item
    }

    
  
    return {
      ...toRefs(dataMap),
      refTab,
      pagin,
      fetchData,
      onSearch,
      onClearForm,
      handleChange,
      handleIndustryTab,
      handleMoreClick,
      handleRatingClick,
      handleTypeClick,
      sortChange,
      handleReport,
      remoteMethod,
      handleGoDetail,
      swichItem,
      HandleRowTime,
      dicts,
      blurHandler,
      fetchSearchData,
      selectedSearch,
      blurHandler2,
      fetchSearchData2,
      selectedSearch2,
      moment,
      industrySelect,
      organizationSelect,
      
    }
  }
})
