
import {
  defineComponent,
  toRefs,
  reactive
} from 'vue'

import CPagination from "@/components/pagination/Index.vue"
import CustomTab from '../../components/CustomTab.vue'
import searchForm from './searchForm.vue'
import { getStockIncreaseList, getBoardStock } from '@/apis/northboundFunds'

import { useRoute } from "vue-router";
export default defineComponent({
  components: {
    CPagination,
    CustomTab,
    searchForm
  },
  props:{
    ajaxIdx:{
      type: Number,
      default: 1
    }
  },
  setup(props, context) {

    const route = useRoute();
    const dataMap = reactive({
      activeZbIdx: 0,
      selectedTime:{
        label: '5日',
        text: '5日'
      },
      loading: true,
      stockList: [

      ],
    })

    let params = {
      // timeLeve: 时间 1d,5d,10d,1m,1q,1y
      timeLevel: '5d',
      type:'',//股票类型 "" :北向资金 sh:沪股通  sz:深股通
      sortFiled: 'addMarkeyCap',
      sort: 'DESC',
      pageNum: '1',
      pageSize: '10'
    }

    if( route.query.boardType ){
      params['boardType'] = route.query.boardType
    }

    const fetchData = async (value: any) => {
      console.log(value)
      if( value.pageNum ){
        params['pageNum'] = value.pageNum
      }
      if( value.pageSize ){
        params['pageSize'] = value.pageSize
      }

      dataMap.loading = true
      if( props.ajaxIdx === 1 ){
        const list = await getStockIncreaseList(params)
        if( list ){
          dataMap.stockList = (list as any).data;
        }
      }else if( props.ajaxIdx === 2 ){
        params['boardName'] = route.query.code
        const list = await getBoardStock(params)
        dataMap.stockList = (list as any).data;
      }

      dataMap.loading = false
    }

    const sortChange = (column: any) => {
      let isAsc = column.order === "ascending" ? 'asc' : 'desc';
      params['sortFiled'] = column["prop"]
      params['sort'] = isAsc
      fetchData({})
    };

    const timeChange = (timeLeve: any) => {
      dataMap.selectedTime = timeLeve
      params['timeLevel'] = timeLeve.lbValue
      fetchData({})
    };

    const typeChange = (val: any) => {
      params['type'] = val
      fetchData({})
    }

    const goDetail = (item: any) => {
      const path = '/northboundFunds/shares'
      window.open( `/investment${path}?code=${item.code}`)
    };

    const goBoard = (item: any) => {
      // console.log(item)
      // return false
      const path = '/northboundFunds/industry'
      window.open( `/investment${path}?code=${item.board}&boardType=${item.boardType}`)
    };

    const addClass = (column:any) => {
      if( column.column.property === params.sortFiled ){
        return 'sort-bg'
      }
    }

    const onSearch = (value:any) => {
      params = {
        ...params,
        ...value
      }
      fetchData({})
    }


    const swichZbItem = (idx: any) => {
      const arr = ['','sh','sz']
      dataMap.activeZbIdx = idx;
      typeChange(arr[idx])
    };

    //合并单位表头
    const handerMethod = (obj:any) => {
      const { row, columnIndex } = obj
      if (row[0].level == 1) {
        //这里有个非常坑的bug 必须是row[0]=0 row[1]=2才会生效
        row[1].colSpan = 0
        row[8].colSpan = 2
        if (columnIndex === 9) {
          return { display: 'none' }
        }

        row[9].colSpan = 0
        row[10].colSpan = 2
        if (columnIndex === 11) {
          return { display: 'none' }
        }

        row[13].colSpan = 0
        row[14].colSpan = 2
        if (columnIndex === 15) {
          return { display: 'none' }
        }
      }
    }



    return {
      ...toRefs(dataMap),
      fetchData,
      sortChange,
      typeChange,
      timeChange,
      goDetail,
      goBoard,
      addClass,
      handerMethod,
      swichZbItem,
      onSearch
    }
  }
})
