<!--
 * @Description: 7*24快讯
-->
<template>
  <div class="news-flash" :class="isOpen ? 'margin' : ''">
    <el-row >
      <el-col :span="4">
        <div class="tit">
          <span class="title-b-border">7x24快讯</span>
        </div>
      </el-col>
      <el-col :span="17">
        <swiper
          v-if="!isOpen"
          :autoHeight="true"
          :direction="'vertical'"
          :slidesPerView="1"
          :loop="true"
          :spaceBetween="30"
          :centeredSlides="true"
          :autoplay="{
            delay: 4000,
            disableOnInteraction: false,
          }"
          :modules="modules"
          class="mySwiper"
        >
          <swiper-slide v-for="(item, idx) in handleList" :key="idx">
            <ul>
              <li
                @click="goReport(items)"
                v-for="(items,idx) in item"
                :key="idx">
                <span class="pubDate">{{moment(items.pubDate).format('HH:mm')}}</span>
                <span>{{items.title}}</span>
              </li>
            </ul>
          </swiper-slide>
        </swiper>

        <ul v-else>
          <li
            @click="goReport(item)"
            v-for="(item,idx) in list"
            :key="idx">
            <span class="pubDate">{{moment(item.pubDate).format('HH:mm')}}</span>
            <span>{{item.title}}</span>
          </li>
        </ul>
      </el-col>
      <el-col :span="3" v-if="list.length > 0">
        <div class="btn" @click="handleToggle">
          <div v-if="!isOpen">展开<i class="el-icon-arrow-down" /></div>
          <div v-else>收起<i class="el-icon-arrow-up" /></div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, toRefs, ref, onDeactivated } from 'vue'
import { getNewsNewsList } from '@/apis/home'
import moment from 'moment'
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Virtual, Autoplay } from "swiper";
import 'swiper/swiper.scss';

SwiperCore.use([Virtual, Autoplay ]);

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const timer = ref(0);

    const dataMap = reactive({
      loading: false,
      isOpen: false,
      list: [],
      handleList: [] as any[],
    })

    const arrChange = (num:Number, arr:any) => {
      const newArr = [];
      while(arr.length > 0){
        newArr.push(arr.splice(0, num));
      }
      return newArr;
    }

    const fetchData = async () => {
      const data = await getNewsNewsList({  })
      const arr = (data as any).data;
      dataMap.list = JSON.parse(JSON.stringify(arr));
      dataMap.handleList = arrChange(5, arr);
    }
    fetchData()

    onMounted(() => {
      timer.value = window.setInterval(() => {
        fetchData()
      }, 60000)
    })

    onDeactivated(() => {
      window.clearInterval(timer.value);
    })

    const handleToggle = () => {
      dataMap.isOpen = !dataMap.isOpen
    }

    const goReport = (item:any) => {
      localStorage.setItem('notice', JSON.stringify(item))
      window.open(`/investment/agency/reportgg`)
    }

    return {
      ...toRefs(dataMap),
      handleToggle,
      moment,
      goReport,
      modules: [Autoplay],
    }
  }
})

</script>
<style scoped lang="scss">
.news-flash{
  width: 700px;
  min-height: 100px;
  margin: 0 auto;
  line-height: 28px;
  &.margin{
    margin-bottom: 40px;
  }
  .tit{
    font-size: 18px;
    font-weight: bold;
    color: #2A2929;
  }
  .pubDate{
    padding-right: 15px;
  }
  ul{
    li{
      display: flex;
      font-size: 16px;
      font-weight: 500;
      color: #1283D5;
      cursor: pointer;
      span:nth-child(2){
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .btn{
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 16px;
    font-weight: 500;
    color: #1283D5;
    padding: 0 10px 60px 0;
    justify-content: flex-end;
  }
}
.swiper-container{
  height: 170px;
}
</style>
