<!--
 * @Description: 面包屑组件
-->
<template>
  <el-breadcrumb separator-class="el-icon-arrow-right" class="c-el-breadcrumb" v-if="stockInfo.level1">
    <el-breadcrumb-item>{{ stockInfo.level1 }}</el-breadcrumb-item>
    <el-breadcrumb-item>{{ stockInfo.level2 }}</el-breadcrumb-item>
    <el-breadcrumb-item>{{ stockInfo.level3 }}</el-breadcrumb-item>
  </el-breadcrumb> 
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue'
import { getStockInfo } from '@/apis/diagnosis'
import { useRoute  } from "vue-router";

export default defineComponent({
  setup(_, context) {
    const route = useRoute();
    const stockCode = route.query.code
    const dataMap = reactive({
      stockInfo: {},
    })
    const fetchData = async () => {
      let data10 = await getStockInfo({ stockCode: stockCode })
      dataMap.stockInfo = (data10 as any).data;
      context.emit('getStockInfo', dataMap.stockInfo);
    }
    fetchData()
    return {
      ...toRefs(dataMap),
    };
  }

})

</script>

<style lang="scss" scoped>
.el-breadcrumb__inner,
.el-breadcrumb__inner a {
  font-weight: 400 !important;
}

.app-breadcrumb.el-breadcrumb {
  display: inline-block;
  font-size: 14px;
  line-height: 50px;
  margin-left: 8px;

  .no-redirect {
    color: #97a8be;
    cursor: text;
  }
}
</style>
