/*
 * @Description: 北向资金接口
 */
import https from '@/utils/https'
import { RequestParams, ContentType, Method } from 'axios-mapper'

// 获取融资融券板块趋势图  行业图表
export const getMarginTrend = (params: RequestParams) => {
  return https().request('/margin/data/board/trend', Method.GET, params, ContentType.form)
}

// 获取融资融券板块趋势图  行业表格
export const getMarginStock = (params: RequestParams) => {
  return https().request('/margin/data/board/stock', Method.GET, params, ContentType.form)
}

// 获取融资融券板块历史记录  行业表格
export const getMarginHistoryStock = (params: RequestParams) => {
  return https().request('/margin/data/board/history', Method.GET, params, ContentType.form)
}

// 获取融资融券板块明细 
export const getMarginDetail = (params: RequestParams) => {
  return https().request('/margin/data/board/detail', Method.GET, params, ContentType.form)
}

// 获取股票融资融券明细及日K数据 
export const getMarginStockDetail = (params: RequestParams) => {
  return https().request('/margin/data/stock/detail', Method.GET, params, ContentType.form)
}

// 分页获取股票融资融券历史数据 
export const getMarginStockLog = (params: RequestParams) => {
  return https().request('/margin/data/stock/history', Method.GET, params, ContentType.form)
}

// 获取制定股票融资融券近两年数据 
export const getMarginStockLeast = (params: RequestParams) => {
  return https().request('/margin/data/stock/least', Method.POST, params, ContentType.json)
}


