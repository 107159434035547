
import moment from 'moment'
import dateComponent from '@/components/timeLevel/Index.vue'
import chartA from "./charts/chartA.vue";
import chartB from "./charts/chartB.vue";
import chartC from "./charts/chartC.vue";
import chartD from "./charts/chartD.vue";
import chartE from "./charts/chartE.vue";
import chartF from "./charts/chartF.vue";
import { defineComponent, reactive, toRefs } from 'vue'
import { getIndexSummary, getMarketSummaryBoardIncr, getStockRankList, getStockFlow, getStockTurnover, getNorthboundFlow, getMarginFlow, getMarketConcept, getKjSummary } from '@/apis/market-overview'
export default defineComponent({
  components: {
    chartA,
    chartB,
    chartC,
    chartD,
    chartE,
    chartF,
    dateComponent
  },
  setup() {
    const dataMap = reactive({
      activeIdx: 0,
      timeArr: ['日','周','月','季','年'],
      threeIndexChg: {},
      boardIncr: {},
      conceptIncr: {},
      kjIncr: {},
      stockRankList: {},
      stockFlowList: {},
      stockTurnoverList: {},
      stockNorthboundFlowList: {},
      marginFlowList: {}
    })
    const time = {
      a: ['day','week','month','quarter','year'],
      b: ['1','2','3','4','5','6'],
    }
    let date:any = undefined

    const fetchData = async () => {
      const threeIndexChg = await getIndexSummary({ periodType: time.a[dataMap.activeIdx], date }) //日期类型 [day:日 week:周  month:月 quarter:季 year:年]
      dataMap.threeIndexChg = (threeIndexChg as any).data;

      const boardIncr = await getMarketSummaryBoardIncr({ boardType: 'swl1', periodType: time.b[dataMap.activeIdx], date })
      dataMap.boardIncr = (boardIncr as any).data;

      const conceptIncr = await getMarketConcept({ boardType: 'swl1', periodType: time.b[dataMap.activeIdx], date })
      dataMap.conceptIncr = (conceptIncr as any).data;

      const kjIncr = await getKjSummary({ periodType: time.b[dataMap.activeIdx], date })
      dataMap.kjIncr = (kjIncr as any).data;
      
      

      const stockRankList = await getStockRankList({ type: time.b[dataMap.activeIdx], date })
      dataMap.stockRankList = (stockRankList as any).data;

      const stockFlowList = await getStockFlow({ periodType: time.b[dataMap.activeIdx], date }); //1:日,2:周,3:月,4:季,5:年
      (stockFlowList as any).data.tableData = (stockFlowList as any).data.decrTop.map((item:any, index:number)=>{
        const innerItem = (stockFlowList as any).data.incrTop[index]
        return {
          mainIn: (item.mainIn / 100000000).toFixed(2),
          stockCode: item.stockCode,
          stockName: item.stockName,
          mainIn1: (innerItem.mainIn / 100000000).toFixed(2),
          stockCode1: innerItem.stockCode,
          stockName1: innerItem.stockName
        }
      })
      dataMap.stockFlowList = (stockFlowList as any).data;

      const stockTurnoverList = await getStockTurnover({ periodType: time.b[dataMap.activeIdx], date }) //1:日,2:周,3:月,4:季,5:年
      dataMap.stockTurnoverList = (stockTurnoverList as any).data;

      const stockNorthboundFlowList = await getNorthboundFlow({ periodType: time.b[dataMap.activeIdx], date }); //1:日,2:周,3:月,4:季,5:年
      (stockNorthboundFlowList as any).data.tableData = (stockNorthboundFlowList as any).data.decrTop.map((item:any, index:number)=>{
        const innerItem = (stockNorthboundFlowList as any).data.incrTop[index]
        return {
          addMarketCap: (item.addMarketCap / 100000000).toFixed(2),
          stockCode: item.stockCode,
          stockName: item.stockName,
          addMarketCap1: (innerItem.addMarketCap / 100000000).toFixed(2),
          stockCode1: innerItem.stockCode,
          stockName1: innerItem.stockName
        }
      })
      dataMap.stockNorthboundFlowList = (stockNorthboundFlowList as any).data;

      const marginFlowList = await getMarginFlow({ periodType: time.b[dataMap.activeIdx], date }); //1:日,2:周,3:月,4:季,5:年
      (marginFlowList as any).data.tableData = (marginFlowList as any).data.incrTop.map((item:any, index:number)=>{
        const innerItem = (marginFlowList as any).data.decrTop[index]
        return {
          buyAmount: (item.buyAmount / 100000000).toFixed(2),
          rzyezb: item.rzyezb,
          sellAmount: item.sellAmount,
          stockCode: item.stockCode,
          stockName: item.stockName,
          buyAmount1: innerItem ? (innerItem.buyAmount / 100000000).toFixed(2) : '-',
          rzyezb1: innerItem ? innerItem.rzyezb : '-',
          sellAmount1: innerItem ? (innerItem.sellAmount / 100000000).toFixed(2) : '-',
          stockCode1: innerItem ? innerItem.stockCode : '-',
          stockName1: innerItem ? innerItem.stockName : '-'
        }
      })
      dataMap.marginFlowList = (marginFlowList as any).data;
    }

    //时间切换
    const handleTimeChange = (item:any) => {
      if( item.hasOwnProperty('custom') ){
        date = item.custom
        dataMap.activeIdx = 5
      }else{
        dataMap.activeIdx = item.idx
      }
      fetchData()
    }

    //格式化富文本颜色
    const getTextColor = (text:any) => {
      if( !text ){
        return text
      }
      const reg = new RegExp('\\[(.+?)\\]',"g");
      const reg2 = new RegExp('\\<(.+?)\\>',"g");
      return text.replace(reg2,"<span style='color:red'>$1</span>").replace(reg,"<span style='color:green'>$1</span>");
    }

    return {
      ...toRefs(dataMap),
      moment,
      getTextColor,
      handleTimeChange
    };
  },
});
