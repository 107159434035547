<template>
  <div
    :id="id"
    :class="className"
    :style="{height: height, width: width}"
  />
</template>

<script lang="ts">
import {
  defineComponent,
  onActivated,
  onBeforeUnmount,
  onDeactivated,
  onMounted,
  nextTick,
  watch,
} from 'vue'
import resize from '@/components/charts/mixins/resize'
import { init, EChartsOption } from 'echarts'
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '300px',
      required: true
    },
    chartData: {
      type: Object,
      default: {}
    },
    selectedTime: {
      type: String || Number,
      default: ''
    },
  },
  setup(props) {
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()

    watch(props, (newName, oldName) => {
      initChart()
    });
    const initChart = () => {
      const barChart = init(document.getElementById(props.id) as HTMLDivElement)
      const xAxisList = Array.from(props.chartData.data, ({ date }) => date);
      // 收盘价
      const industryIndex = Array.from(props.chartData.data, ({ close }) => close);
      const maxIndustry = Math.ceil(Math.max(...industryIndex));
      const minIndustry = Math.floor(Math.min(...industryIndex));
      // 持股比例
      const RatioIndex = Array.from(props.chartData.data, ({ holdRatio }) => holdRatio);
      const maxRatio = Math.ceil(Math.max(...RatioIndex));
      const minRatio = Math.floor(Math.min(...RatioIndex));
      // 开盘价
      const openIndex = Array.from(props.chartData.data, ({ open }) => open);
      const maxOpen = Math.ceil(Math.max(...openIndex));
      const minOpen = Math.floor(Math.min(...openIndex));
      // 涨跌幅
      const incrIndex = Array.from(props.chartData.data, ({ incr }) => incr);
      const maxIncr = Math.ceil(Math.max(...incrIndex));
      const minIncr = Math.floor(Math.min(...incrIndex));
      // 持股市值
      const addMarkeyCap = Array.from(props.chartData.data, ({ addMarkeyCap }) => addMarkeyCap);
      const maxAddMarkeyCap = Math.ceil(Math.max(...addMarkeyCap));
      const minAddMarkeyCap = Math.floor(Math.min(...addMarkeyCap));
      const addMarkeyCapData: any = [];
      addMarkeyCap.map((item) => {
        let obj = {
          value: item,
          itemStyle: {
            color: item > 0 ? 'rgb(247, 78, 79)' : 'rgb(92, 188, 124)'
          }
        }
        addMarkeyCapData.push(obj)
      })

      const integerData: any = [];
      RatioIndex.map((item) => {
        let obj = {
          value: item,
          itemStyle: {
            color: item > 0 ? '#F74E4F' : '#5CBC7C'
          }
        }
        integerData.push(obj)
      })
      barChart.setOption({
        tooltip: {
          trigger: 'axis',
          formatter:function(params: any) {
            let text = params[0].axisValue;
            for (let i = 0; i < params.length; i++) {
              const item = params[i]
              text += `<div style="display: flex; justify-content: space-between">
                        <span style='display:block;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 500;color: #666666;line-height: 17px;'>${item.seriesName}：</span>
                        <span style='display:block;font-size: 14px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;
                                    color: ${item.seriesName === '收盘价' || item.seriesName === '涨跌幅' ? (item.data > 0 ? '#F74E4F' : item.data < 0 ? '#5CBC7C' : '#333333') : '#333333'};line-height: 20px;text-align: right;'>
                            ${item.seriesName === '涨跌幅' ? item.data > 0 ? '+' +  item.data + '%' : +  item.data + '%' : item.seriesName === '持股比例(折线)' ? item.data + '%' :  item.seriesName === '增持市值(柱状)' ? mathAbs(item.data.value) + mathAbsText(item.data.value) : item.data }
                        </span>
                    </div>`
            }
            return text
          }
        },
        grid: {
          top: '30px',
          left: '13px',
          right: '28px',
          bottom: '40px',
          containLabel: true
        },
        color: ['#FE9625', '#3B7FEE', '#FFFFFF', '#FFFFFF'],
        legend: {
          icon: 'line',
          itemWidth: 8,  // 设置宽度
          itemHeight: 3, // 设置高度
          x: 'center',
          bottom: '0',
          itemGap: 30,
          textStyle: {
            fontSize: 12,// 字体大小
            color:'#666666',
          },
          data: ['持股比例(柱状)', '持股比例(折线)', '收盘价']
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisTick: { show: false },
          axisLine: { show: false, onZero: false },
          data: xAxisList.sort(),
          axisLabel: {
            margin: 10,
            interval: 100000,
            showMinLabel: true,
            showMaxLabel: true,
            textStyle: {
              color: '#999',
              fontSize: 12,
            }
          },
        },
        yAxis: [
          {
            type: 'value',
            splitLine: {show: true, lineStyle: { type: 'dashed' }},
            position: 'left',
            name: '收盘价',
            max: maxIndustry,
            min: minIndustry,
            axisPointer: {
              type: 'none',
              label: {show: false}
            },
            axisLabel: {
              show: true,
              align: 'right',
            },
          },
          {
            type: 'value',
            splitLine: {show: false, lineStyle: { type: 'dashed' }},
            position: 'right',
            name: '持股比例',
            max: maxRatio,
            min: minRatio,
            axisPointer: {
              type: 'none',
              label: { show: false }
            },
            axisLabel: {
              show: true,
              align: 'left',
              formatter: "{value}%"
            }
          },
          {
            show: false,
            type: 'value',
            position: 'right',
            name: '开盘价',
            max: maxOpen,
            min: minOpen,
            axisPointer: {
              type: 'none',
              label: { show: false }
            },
            axisLabel: {
              show: true,
              align: 'left',
              formatter: "{value}%"
            }
          },
          {
            show: false,
            type: 'value',
            position: 'right',
            name: '涨跌幅',
            max: maxIncr,
            min: minIncr,
            axisPointer: {
              type: 'none',
              label: { show: false }
            },
            axisLabel: {
              show: true,
              align: 'left',
              formatter: "{value}%"
            }
          },
          {
            type: 'value',
            show: false,
            splitLine: {show: false, lineStyle: { type: 'dashed' }},
            position: 'right',
            name: '持股市值',
            max: maxAddMarkeyCap,
            min: minAddMarkeyCap,
            axisPointer: {
              type: 'none',
              label: { show: false }
            },
            axisLabel: {
              show: false,
              align: 'left',
              formatter: "{value}%"
            }
          },
        ],
        series: [
          {
            name: '收盘价',
            type: 'line',
            symbol: 'none',
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 1.5,
                  color: '#FE9625'
                }
              }
            },
            data: industryIndex
          },
          {
            name: '增持市值(柱状)',
            type: 'bar',
            yAxisIndex: 4,
            // symbol: 'none',
            barWidth: 1,
            itemStyle: {
              normal: {
                color: 'skyblue'
              }
            },
            show:false,
            data: addMarkeyCapData
          },
          {
            name: '持股比例(折线)',
            type: 'line',
            yAxisIndex: 1,
            symbol: 'none',
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 1.5,
                  color: '#3B7FEE'
                }
              }
            },
            data: RatioIndex
          },
          {
            name: '开盘价',
            type: 'scatter',
            yAxisIndex: 2,
            symbol: 'none',
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 1.5,
                  color: '#FFFFFF'
                }
              }
            },
            data: openIndex
          },
          {
            name: '涨跌幅',
            type: 'scatter',
            yAxisIndex: 3,
            symbol: 'none',
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 1.5,
                  color: '#FFFFFF'
                }
              }
            },
            data: incrIndex
          }
        ]
      } as EChartsOption)
      chart.value = barChart
    }
    const mathAbs = (item:any) => {
      const number = Number(Math.abs(item));
      const numberText = Number(item);
      return number > 10000  ? (numberText / 10000).toFixed(2) : numberText.toFixed(2)
    }
    const mathAbsText = (item:any) => {
      const number = Number(Math.abs(item))
      return number > 10000 ? '亿' : '万'
    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {}
  }
})
</script>
