

import { defineComponent, reactive, toRefs } from 'vue'
import dicts from '@/utils/dicts'
import { getSurveyIndustryCountRecords} from '@/apis/research'

import { useRoute  } from "vue-router";

export default defineComponent({
  components:{
    
  },
  setup() {
    const route = useRoute();
    const stockCode = route.query.code

    const dataMap = reactive({
      stockList1: [],

      formInline:{
        stockCode: stockCode,
        timeLevel: '1w',
      },
      dstxActive  : 0,
      showTimeSelect: false,
      selectTime: [],
    })

    const fetchData = async () => {
      let data1 = await getSurveyIndustryCountRecords(dataMap.formInline)
      dataMap.stockList1 = (data1 as any).data
    }
    // fetchData()


    //新版时间tab选择
    const swichItem = async (idx: any) => {
      dataMap.dstxActive = idx
      dataMap.showTimeSelect = idx === 5 ? true : false
      
      if( idx === 5 ){
        // delete dataMap.formInline['timeLevel']
        dataMap.formInline['timeLevel'] = ''
      }else{
        dataMap.formInline['timeLevel'] = dicts.timeSelection[idx].timeLevel
        delete dataMap.formInline['startTime'];
        delete dataMap.formInline['endTime'];
        fetchData()
      }
    }

    // 时间范围选择事件
    const handleChange = async () => {
      dataMap.formInline['startTime'] = window.moment(dataMap.selectTime[0]).format('x')
      dataMap.formInline['endTime'] = window.moment(dataMap.selectTime[0]).format('x')
      fetchData()
    }

    //清空
    const onClearForm = async () => {
      delete dataMap.formInline['startTime'];
      delete dataMap.formInline['endTime'];
      dataMap.formInline['orgName'] = ''
      dataMap.formInline['isPointOrg'] = []
      swichItem(0)
      fetchData()
    }

    //表格排序
    const sortChange = (column:any) => {
      let order = column.order === "ascending" ? true : false;
      if( column['prop'] ){
        dataMap.formInline['isAsc'] = order
        dataMap.formInline['sortKey'] = column["prop"]
      }else{
        delete dataMap.formInline['order']
        delete dataMap.formInline['orderField']
      }
      fetchData()
    }

    const setParams = (params:any) => {
      dataMap.formInline['timeLevel'] = params['timeLevel']
      if( params['timeLevel'] ){
        delete dataMap.formInline['startTime'] 
        delete dataMap.formInline['endTime'] 
      }else{
        dataMap.formInline['startTime'] = params['startTime'] 
        dataMap.formInline['endTime'] = params['endTime'] 
      }
      fetchData()
    }

    const handleRowClick = (row:any) => {
      window.open(`/investment/research/detail?code=${row.code}`)
    }

    return {
      ...toRefs(dataMap),
      swichItem,
      onClearForm,
      handleChange,
      sortChange,
      setParams,
      handleRowClick,
      dicts
    };
  }
})
