<template>
  <div class="app-container">
    <!-- <el-icon><FullScreen /></el-icon> -->
    <div class="header-top">
      <breadcrumb @getStockInfo='getStockInfo'></breadcrumb>
      
      <div class="share-button" @click="shareUrl">
        <span>分享</span>
        <img src="@/assets/images/share.png" alt="">
      </div>
    </div>
    <Search open></Search>
    <div class="bg b1-1 mt50" v-if="stockList2">
      <CTitle :mode='7' v-if="stockList2.name" :title="stockList2.name" :titleData='stockList2' :state='stockInfo.state'></CTitle>
      <template v-if="stockList1">
        <CTitle :mode='6' title="个股综评" class="mt27"></CTitle>
        <div class="ggzp">
          <RadarChart :chartData='stockList1' v-if="ajaxRes"></RadarChart>
          <div class="right">
            <div class="t">
              <div>
                <div>
                  <span>次日上涨概率</span>
                  <span>{{stockList1.risingProbability}}</span>
                </div>
              </div>
            </div>
            <div class="b">
              <div class="content_item_assessment_graph_foot">
              <div class="pingyu"><text>评语:</text><div class="xiahua1"></div></div> 盈利能力<text :style="{color:assessmentInfo.profitabilityScorecolor}">{{assessmentInfo.profitabilityScoretext}}</text> ，成长能力<text :style="{color:assessmentInfo.growUpScorecolor}">{{assessmentInfo.growUpScoretext}}</text>，营运债偿能力<text :style="{color:assessmentInfo.operationScorecolor}">{{assessmentInfo.operationScoretext}}</text>，现金流<text :style="{color:assessmentInfo.cashFlowScorecolor}">{{assessmentInfo.cashFlowScoretext}}</text>，价值<text :style="{color:assessmentInfo.valuationScorecolor}">{{assessmentInfo.valuationScoretext}}</text>，整体质地<text :style="{color:assessmentInfo.valueScorecolor}">{{assessmentInfo.valueScoretext}}</text>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <div class="tab">
      <ul>
        <li :class="activeIdx === 1 ? 'active' : ''" @click="swichItem(1)">技术面</li>
        <li :class="activeIdx === 2 ? 'active' : ''" @click="swichItem(2)">资金面</li>
        <li :class="activeIdx === 3 ? 'active' : ''" @click="swichItem(3)">基本面</li>
      </ul>
    </div>
    <div class="tab-content">
      <!-- 技术面 -->
      <Technical v-if="activeIdx === 1"></Technical>
      <!-- 资金面 -->
      <Capital v-else-if="activeIdx === 2"></Capital>
      <!-- 基本面 -->
      <Basic v-else></Basic>
    </div>

    <div class="b-tab" v-if="ajaxRes">
      <CTitle :mode='8' title="估值分析" moreUrl='/analysis/detail'></CTitle>
      <CSelect @change="changeHandle" type='year' class="c-select"></CSelect>

      <div class="b-tab-nav" v-if="ajaxRes">
        <ul>
          <li :class="activeAnalysisIdx === 0 ? 'active' : ''" @click="swichAnalysisItem(0)">
            <div>综合估值</div>
            <div>{{stockList3.valuationDesc}}</div>
          </li>
          <li :class="activeAnalysisIdx === 1 ? 'active' : ''" @click="swichAnalysisItem(1)">
            <div>市盈率</div>
            <div>{{stockList3.pe.toFixed(2)}}</div>
          </li>
          <li :class="activeAnalysisIdx === 2 ? 'active' : ''" @click="swichAnalysisItem(2)">
            <div>市净率</div>
            <div>{{stockList3.pb.toFixed(2)}}</div>
          </li>
          <li :class="activeAnalysisIdx === 3 ? 'active' : ''" @click="swichAnalysisItem(3)">
            <div>市销率</div>
            <div>{{stockList3.ps.toFixed(2)}}</div>
          </li>
          <li :class="activeAnalysisIdx === 4 ? 'active' : ''" @click="swichAnalysisItem(4)">
            <div>市现率</div>
            <div>{{stockList3.pcf.toFixed(2)}}</div>
          </li>
        </ul>
      </div>

      <div class="analysis-container flex">
        <div class="flex analysis" v-if="activeAnalysisIdx === 0">
          <div class="left">
            <div class="analysis-title">
              <div class="analysis-title-left">
                公司最新估值分位 <span class="red">{{stockList3.valuation}}%</span> ，估值<span class="red">{{stockList3.valuationDesc}}</span>
              </div>
              <div class="analysis-ti tle-right">
              </div>
            </div>
            <div class="analysis-left-date">
              <LineChartF id='chart-analysis-left' :chartData='stockList4' v-if="ajaxRes" width='500px'></LineChartF>
              <div class="flex date">
                <div class="left" v-if="stockList4[0]">{{stockList4[0].date}}</div>
                <div class="right" v-if="stockList4.length">{{stockList4[stockList4.length-1].date}}</div>
              </div>
            </div>
          </div>
          <div class="right"></div>
        </div>
        <div class="analysis-right" v-else>
          <div class="flex">
            <p v-if="activeAnalysisIdx === 1">{{stockList5.peContent}}</p>
            <p v-if="activeAnalysisIdx === 2">{{stockList5.pbContent}}</p>
            <p v-if="activeAnalysisIdx === 3">{{stockList5.psContent}}</p>
            <p v-if="activeAnalysisIdx === 4">{{stockList5.pcfContent}}</p>
          </div>
          <div class="content_item_trend_content">
            <div class="content_item_trend_content_line">
              <div class="trend_content_legend">
                <ul>
                  <li><span></span> 高估：{{stockList5ChartData.max}}</li>
                  <li><span></span> 中值：{{stockList5ChartData.mid}}</li>
                  <li><span></span> 低估：{{stockList5ChartData.min}}</li>
                </ul>
              </div>
              <template v-if="stockList5ChartData.data && stockList5ChartData.data.length">
                <LineChartD id='chart-analysis-right' width='500px' :chartData='stockList5ChartData'></LineChartD>
                <div class="content_item_timebox">
                  <div class="content_item_timebox_item">{{stockList5ChartData.data[0].x}}</div>
                  <div class="content_item_timebox_item">{{stockList5ChartData.data[stockList5ChartData.data.length-1].x}}</div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>

      <div class="jgpj">
        <CTitle :mode='8' title="机构评级" moreUrl='/agency/detail' class="mt50"></CTitle>
        <div class="jgpj-content" v-if="ajaxRes">
          <div class="left">
            <CSelect @change="changeJggdHandle"  type='month' class="pj-select"></CSelect>
            <BarChartE id='chart-jggd' width='100%' :chartData='stockList6'></BarChartE>
          </div>
          <div class="right">
            <BarChartC id='chart-jggd-right' width='100%' :chartData='stockList7' ></BarChartC>
          </div>
        </div>
      </div>

      <CTitle :mode='8' title="机构调研" moreUrl='/research/detail' class="mt50" ></CTitle>
      <SurveyOrgRanking :hide='true'></SurveyOrgRanking>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue'
import CTitle from "@/components/title/Index.vue"
import RadarChart from "@/components/charts/RadarChart.vue"
import Technical from "./components/aspect/Technical.vue"
import Basic from "./components/aspect/Basic.vue"
import Capital from "./components/aspect/Capital.vue"
import LineChartF from '@/components/charts/LineChartF.vue'
// import LineChartJ from '@/components/charts/LineChartJ.vue'
import LineChartD from '@/components/charts/LineChartD.vue'
import BarChartA from '@/components/charts/BarChartA.vue'
import BarChartC from '@/components/charts/BarChartC.vue'
import BarChartE from '@/components/charts/BarChartE.vue'
import BarChartH from '@/components/charts/BarChartH.vue'
import SurveyOrgRanking from "@/views/research/components/SurveyOrgRanking.vue"
import Search from '@/components/search-input/Index.vue'

import CSelect from "@/components/select/Index.vue"
import { getDiagnosticAssessment, getStockRealtime, getValuationDesc, getValuationDaily, getValuationQuantileDaily, getReportRanking, getReportPriceDaily } from '@/apis/diagnosis'
import { useRoute } from 'vue-router'
import useClipboard from 'vue-clipboard3';
import { ElMessage } from 'element-plus'
// import { ArrowRight } from '@element-plus/icons-vue'

export default defineComponent({
  components: {
    CTitle,
    CSelect,
    RadarChart,
    LineChartF,
    LineChartD,
    // LineChartJ,
    BarChartA,
    BarChartC,
    BarChartE,
    BarChartH,
    Technical,
    Basic,
    Capital,
    SurveyOrgRanking,
    Search
  },
  setup() {
    const route = useRoute()
    const stockCode = route.query.code//'600519.SH'
    const dataMap = reactive({
      ajaxRes:false,
      activeIdx: 1,
      activeAnalysisIdx: 0,
      stockInfo: {},
      stockList1: {},
      stockList2: {},
      stockList3: {},
      stockList4: [],
      stockList5: {},
      stockList6: {},
      stockList7: {},
      // stockList8: {},
      stockList5ChartData: {},
      assessmentInfo:{}
    })
    
    const fetchData = async () => {
      const data1 = await getDiagnosticAssessment({ stockCode: stockCode })
      dataMap.stockList1 = (data1 as any).data;

      let assessmentInfo = (data1 as any).data
      if( assessmentInfo ){
        gettext(assessmentInfo,'cashFlowScore')
        gettext(assessmentInfo,'growUpScore')
        gettext(assessmentInfo,'operationScore')
        gettext(assessmentInfo,'profitabilityScore')
        gettext(assessmentInfo,'ranking')
        gettext(assessmentInfo,'valueScore')
        gettext(assessmentInfo,'valuationScore')
        dataMap.assessmentInfo = assessmentInfo
      }
      
      
      const data2 = await getStockRealtime({ stockCode: stockCode })
      dataMap.stockList2 = (data2 as any).data;

      const data3 = await getValuationDesc({ stockCode: stockCode })
      dataMap.stockList3 = (data3 as any).data;

      // let data4 = await getValuationDaily({ stockCode: stockCode,timeLevel: '5y' })
      // dataMap.stockList4 = (data4 as any).data;

      // let data5 = await getValuationQuantileDaily({ stockCode: stockCode,timeLevel: '5y' })
      // dataMap.stockList5 = (data5 as any).data;

      // let data8 = await getStockInfo({ stockCode: stockCode })
      // dataMap.stockInfo = (data8 as any).data;

      // let data6 = await getReportRanking({ stockCode: stockCode,timeLevel: '1m' })
      // dataMap.stockList6 = (data6 as any).data;

      // let data7 = await getReportPriceDaily({ stockCode: stockCode,timeLevel: '5y' })
      // dataMap.stockList7 = (data7 as any).data;

      // let data8 = await getSurveyOrgRanking({ stockCode: stockCode })
      // dataMap.stockList8 = (data8 as any).data;

      dataMap.ajaxRes = true;
    }
    const gettext = (obj:any,text:any) => {
      obj[text+'text'] = obj[text].split('|')[0]
      obj[text+'color'] = obj[text].split('|')[1]
      return obj
    }

    const swichItem=(item:any)=>{
      dataMap.activeIdx = item
    }

    const swichAnalysisItem=(idx:any)=>{
      dataMap.activeAnalysisIdx = idx
          console.log(11111,dataMap.stockList5)
      if( idx ){
        if( idx === 1 ){
          dataMap.stockList5ChartData = {
            max: dataMap.stockList5['peMax'],
            mid: dataMap.stockList5['peMid'],
            min: dataMap.stockList5['peMin'],
            data: dataMap.stockList5['peRecords']
          }
          
        }else if( idx === 2 ){
          dataMap.stockList5ChartData = {
            max: dataMap.stockList5['pbMax'],
            mid: dataMap.stockList5['pbMid'],
            min: dataMap.stockList5['pbMin'],
            data: dataMap.stockList5['pbRecords']
          }
        }else if( idx === 3 ){
          dataMap.stockList5ChartData = {
            max: dataMap.stockList5['psMax'],
            mid: dataMap.stockList5['psMid'],
            min: dataMap.stockList5['psMin'],
            data: dataMap.stockList5['psRecords']
          }
        }else if( idx === 4 ){
          dataMap.stockList5ChartData = {
            max: dataMap.stockList5['pcfMax'],
            mid: dataMap.stockList5['pcfMid'],
            min: dataMap.stockList5['pcfMin'],
            data: dataMap.stockList5['pcfRecords']
          }
        }
      }
    }

    const changeHandle = async (res:any) => {
      let data4 = await getValuationDaily({ stockCode: stockCode,timeLevel: res.timeLevel })
      dataMap.stockList4 = (data4 as any).data;

      let data5 = await getValuationQuantileDaily({ stockCode: stockCode,timeLevel: res.timeLevel })
      dataMap.stockList5 = (data5 as any).data;
      swichAnalysisItem(dataMap.activeAnalysisIdx)
    }

    const changeJggdHandle = async (res:any) => {
      let data6 = await getReportRanking({ stockCode: stockCode,timeLevel: res.timeLevel })
      dataMap.stockList6 = (data6 as any).data;

      let data7 = await getReportPriceDaily({ stockCode: stockCode,timeLevel: res.timeLevel })
      dataMap.stockList7 = (data7 as any).data;
    }

    const getStockInfo = (info:any) => {
      dataMap.stockInfo = info
    }

    const shareUrl = () => {
      const { toClipboard } = useClipboard();
      try {
        console.log(process.env)
        const origin = location.origin
          const host = origin === 'https://business-admin.jufxt.com' || origin === 'http://business-admin.jufxt.com' ? 'https://investment-research.jufxt.com' : 'https://tinvestment-research.jufxt.com'
          toClipboard(host + '/investment/diagnosismoblie/index?code=' + stockCode);
          ElMessage.success('复制成功！');
      } catch (e) {
          ElMessage.error('复制失败！');
      }
    }

    fetchData()
    return {
      ...toRefs(dataMap),
      swichItem,
      swichAnalysisItem,
      changeHandle,
      changeJggdHandle,
      getStockInfo,
      shareUrl
    }
  }
})
</script>
<style lang='scss' scoped>
.bg{
  background: #fff;
  padding: 38px 32px 54px;
  .ggzp{
    display: flex;
    margin-top: 13px;
    .right{
      margin-top: 80px;
      margin-left: 20px;
      .t{
        margin-bottom: 20px;
        &>div{
          background: #F8AEA1;
          border-radius: 16px;
          padding: 0 7px;
          display: inline-block;
          &>div{
            width: 191px;
            background: linear-gradient(135deg, #FF7045 0%, #FB0A0A 100%);
            border-radius: 16px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            span{
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #FFFFFF;
            }
            span:nth-child(2){
              font-size: 20px;
            }
          }
        }
      }
      .b{
        font-size: 16px;
        color: #333333;
        font-weight: 500;
        // .orange{
        //   color: #FE9625;
        // }
        // .red{
        //   color: #FD3630;
        // }
        .content_item_assessment_graph_foot{
            // font-size: 24px;
            // font-family: PingFangSC-Regular, PingFang SC;
            // font-weight: 400;
            // color: #343434;
            // line-height: 40px;
            // padding: 0 20px;
            // margin-top: 36px;
        }
        .content_item_assessment_graph_foot .pingyu{
            color: #FE9625;
            display: inline-block;
            position: relative;
        }
        .content_item_assessment_graph_foot .pingyu text{
            position: relative;
            z-index: 2;
        }
        .content_item_assessment_graph_foot .pingyu .xiahua{
            height: 6px;
            width: 100%;
            background: #FE9625;
            position: absolute;
            bottom: 6rpx;
            left: 0;
            z-index: 1;
        }
      }
    }
  }
}
.b1-1{
  padding-top: 20px;
}

.tab{
  background: #F8FAFB;
  margin-top: 30px;
  ul{
    display: flex;
    padding: 11px 0;
    li{
      width: 117px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background: rgba(56,111,242,0.1);
      border-radius: 4px;
      margin-left: 30px;
      color: #666666;
      cursor: pointer;
      &.active{
        color: #FFFFFF;
        background: #386FF2;
      }
    }
  }
  
}
.tab-content{
  background: #fff;
  padding: 25px 28px 50px;
  ::v-deep .mode{
    margin-left: -12px;
    margin-bottom: 20px;
  }
}

.b-tab{
  margin-top: 30px;
  padding: 25px 28px;
  background: #fff;
  position: relative;
  .mode{

  }
  .c-select{
    text-align: left;
    position: absolute;
    left: 140px;
    top: 20px;
  }
  .b-tab-nav{
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(24,24,24,0.1);
    border-radius: 8px;
    padding: 4px;
    display: inline-block;
    margin-top: 20px;
    ul{
      display: flex;
      li{
        width: 161px;
        text-align: center;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #999BA7;
        padding: 10px 0;
        cursor: pointer;
        &.active{
          color: #fff;
          background: #386FF2;
          box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.2);
          border-radius: 8px;
        }
        div:last-child{
          font-size: 18px;
          padding-top: 4px;
        }
      }
    }
  }
}

.flex{
  display: flex;
  &>div{
    flex: 1;
  }
  .left{

  }
  .right{

  }
}

.analysis-container{
  margin-top: 30px;
  .analysis{
    .analysis-title{
      display: flex;
      .analysis-title-left{
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        .red{
          color: #FB110E;
        } 
      }
      .analysis-title-right{
        margin-left: auto;
      }
    }
  }
  .analysis-left-date{
    margin-top: 35px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    .date{
      width: 450px;
      margin-left: 40px;
      margin-top: -20px;
      .right{
        text-align: right;
      }
    }
  }
  .analysis-right{
    p{
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      padding-right: 30px;
      margin-bottom: 7px;
    }
    .trend_content_legend{
      margin-bottom: 10px;
      ul{
        display: flex;
        margin: 7px 0 10px;
        li{
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          margin-right: 20px;
          span{
            display: inline-block;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: #951EFD;
          }
          &:nth-child(2){
            span{
              background: #D0031B;
            }
          }
          &:nth-child(3){
            span{
              background: #E9C036;
            }
          }
        }
      }
    }
  }
}

.jgpj{
  position: relative;
  .pj-select{
    position: absolute;
    left: 120px;
    top: -4px;
  }
  .jgpj-content{
    display: flex;
    &>div{
      flex: 1;
    }
    .left{
      // margin-right: 160px;
      margin-right: 50px;
    }
    .right{
      margin-left: 50px;
    }
  }
}

.header-top{
  display: flex;
  // justify-content: center;
  // align-items: center;
  .share-button{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 84px;
    height: 31px;
    border-radius: 2px;
    border: 1px solid #000000;
    margin-left: auto;
    position: relative;
    top: -8px;
    img{
      width: 16px;
      margin-left: 9px;
    }
  }
}
.search{
  margin-top: 0;
  margin-bottom: 25px;
}
.content_item_timebox{
  width: 450px;
  margin-left: 40px;
  margin-top: -20px;
}
</style>
