<template>
<!--  个股排行表格-->
  <div class="table_box">
    <el-table
      border
      v-loading='loading'
      :data="tableData.list || []"
      @row-click='goDetail'
      :header-cell-style="headerMethod"
      style="width: 100%"
      :cell-class-name='addClass'
      @sort-change="sortChange"
      :default-sort ="{prop:'rzjmr',order:'descending'}"
    >
      <el-table-column prop="sort" label="排名" width="88" align="center">
        <template #default='{row}'>
          {{ row.sort }}<br />
          <span v-if="row.sortChange > 0" v-dClass="row.sortChange" style="display: flex; align-items: center; justify-content: center">
            {{ row.sortChange }}
            <i class="el-icon-top" />
          </span>
          <span v-if="row.sortChange < 0" v-dClass="row.sortChange" style="display: flex; align-items: center; justify-content: center">
            {{ row.sortChange }}
            <i class="el-icon-bottom" />
          </span>
        </template>
      </el-table-column>
      <el-table-column label="个股名称/代码" width="88" align="center">
        <template #default='{row}'>{{ row.stockName }}<br />{{ row.stockCode }}</template>
      </el-table-column>
      <el-table-column prop="incr" :label='`${textStatus.label}涨幅`' width="91" align="center">
        <template #default='{row}'><div v-dClass='row.incr'>{{ row.incr }}%</div></template>
      </el-table-column>
      <el-table-column label="融资" align="center">
        <el-table-column prop="rzye" label="余额" width="95" align="center" sortable="custom">
          <template #default='{row}'>{{ amount(row.rzye) }}</template>
        </el-table-column>
        <el-table-column prop="rzyezb" label="余额占流通市值比" width="105" align="center" sortable="custom">
          <template #default='{row}'>{{ row.rzyezb ? row.rzyezb.toFixed(2) : '-' }}%</template>
        </el-table-column>
        <el-table-column prop="rzmr" label="买入额(元)" width="113" align="center" sortable="custom">
          <template #default='{row}'>{{ amount(row.rzmr) }}</template>
        </el-table-column>
        <el-table-column prop="rzjmr" label="净买入(元)" width="106" align="center" sortable="custom">
          <template #default='{row}'>{{ amount(row.rzjmr) }}</template>
        </el-table-column>
        <el-table-column prop="addDays" label="融资净买入统计" width="125" align="center">
          <template #default='{row}'>
            <div class="c-col">
              <div v-if="row.continueAddDays > 1" v-dClass="row.continueAddDays">{{ row.continueAddDays }}连增</div><div v-else>-</div>
              <div v-dClass="row.addDays">共{{ row.addDays }}次</div></div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="融券" align="center">
        <el-table-column prop="rqye" label="余额(元)" width="109" align="center" sortable="custom">
          <template #default='{row}'>{{ amount(row.rqye) }}</template>
        </el-table-column>
        <el-table-column prop="rqmc" label="卖出额" width="105" align="center" sortable="custom">
          <template #default='{row}'>{{ amount(row.rqmc) }}</template>
        </el-table-column>
        <el-table-column prop="rqjmc" label="净卖额" width="105" align="center" sortable="custom">
          <template #default='{row}'>{{ amount(row.rqjmc) }}</template>
        </el-table-column>
      </el-table-column>
      <el-table-column prop="rzrqye" label="融资融券余额(元)" width="95" align="center" sortable="custom">
        <template #default='{row}'>{{ amount(row.rzrqye) }}</template>
      </el-table-column>
      <el-table-column prop="vol" label="成交量(股)" width="83" align="center">
        <template #default='{row}'>{{ amount(row.vol) }}</template>
      </el-table-column>
      <el-table-column prop="turnover" label="换手率" width="60" align="center">
        <template #default='{row}'>{{ row.turnover ? row.turnover.toFixed(2) : '-' }}%</template>
      </el-table-column>
      <el-table-column prop="amt" label="成交额" width="79" align="center">
        <template #default='{row}'>{{ amount(row.amt) }}</template>
      </el-table-column>
      <el-table-column prop="boardName" label="所属行业" width="65" align="center" />
      <el-table-column prop="stockPeTtm" label="个股/行业 PE-TTM" width='75' align="center">
        <template #default='{row}'>{{ row.stockPeTTM.toFixed(2) }}</template>
      </el-table-column>
      <el-table-column prop="boardPeTtm" label="个股/行业 PE-TTM" width='75' align="center">
        <template #default='{row}'>{{ row.boardPeTTM }}</template>
      </el-table-column>
      <el-table-column prop="valuation" label="水位估值" min-width="60" align="center" />
    </el-table>
    <paging-component ref='paging' :showPageSizes='true' :pageSize='10'  :pageData='tableData' @fetchData='fetchData' />
  </div>
</template>
<script lang="ts">
import pagingComponent from '@/components/pagination/Index.vue'
import { defineComponent, reactive, toRefs } from 'vue'
import { getStockTable } from '@/apis/margin-trading'
export default defineComponent({
  props: {
    tableList: {
      type: Array,
      default: []
    },
    textStatus: {
      type: String,
      default: ''
    }
  },
  components: {
    pagingComponent
  },
  setup(props) {
    const dataMap = reactive({
      loading: true,
      tableData: {},
      pageItem: {},
      navItem: {},
      tabItem: {}
    })
    let params = {
      pageNum: '1',
      pageSize: '10',
      market: 'ALL',
      timeUnit: 'DAYS',
      num: 1,
      orderBy: 'rzjmr'
    }
    const fetchData = async (value: any, item: any, tabItem: any) => {
      dataMap.pageItem = value
      dataMap.navItem = item
      dataMap.tabItem = tabItem
      if(value.pageNum){
        params['pageNum'] = value.pageNum
      }
      if(value.pageSize){
        params['pageSize'] = value.pageSize
      }
      if(item){
        params['market'] = item.code
      }
      if(tabItem){
        params['timeUnit'] = tabItem.timeUnit
        params['num'] = tabItem.num
      }
      dataMap.loading = true
      const marginList = await getStockTable(params)
      dataMap.tableData = (marginList as any).data;
      dataMap.loading = false
    }
    const headerMethod = (item: any) => {
      const { row, columnIndex } = item
      if (row[0].level == 1) {
        row[9].colSpan = 0
        row[10].colSpan = 2
        if (columnIndex === 11) {
          return { display: 'none' }
        }
      }
    }
    const sortChange = (column: any) => {
      params['orderBy'] = column["prop"]
      fetchData(dataMap.pageItem, dataMap.navItem, dataMap.tabItem)
    };
    const addClass = (column:any) => {
      if( column.column.property === params.orderBy ){
        return 'sort-bg'
      }
    }
    const amount = (value: any, fixed = 2, unit = '元') => {
      if (unit == '万') {
        value = value * 10000
      } else if (unit == '亿') {
        value = value * 100000000
      }
      if (Math.abs(value / 100000000) >= 1) {
        return (value / 100000000).toFixed(fixed) + '亿'
      }
      if (Math.abs(value / 10_000) >= 1) {
        return (value / 10000).toFixed(fixed) + '万'
      }
      return value
    }
    const goDetail = (item: any, index: any) => {
      console.log(item, 'item', index.label, 'index')
      if (index.label === '个股名称/代码') {
        const path = '/marginTrading/shares'
        window.open( `/investment${path}?name=${item.stockName}&code=${item.stockCode}`)
      } else if (index.label === '所属行业') {
        const path = '/marginTrading/industry'
        window.open( `/investment${path}?code=${item.boardName}&boardType=swl2`)
      }
    };
    return {
      ...toRefs(dataMap),
      fetchData,
      headerMethod,
      sortChange,
      addClass,
      amount,
      goDetail
    }
  }
})
</script>
<style scoped lang="scss">
.table_box {
  margin-top: 20px;
  padding-bottom: 40px;
  margin-left: 30px;
}
.c-col{
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  &>div{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    &:nth-child(1){
      border-right: 1px solid rgb(235, 238, 245);
    }
  }
}
</style>
