<template>
  <el-pagination
    v-if="isShow"
    :page-sizes="[10, 20, 50, 80]"
    :page-size="pageSize"
    :small="small"
    :disabled="disabled"
    :background="background"
    :pager-count='pagerCount'
    :current-page.sync="curPage"
    prev-text="上一页"
    next-text="下一页"
    :layout="showPageSizes ?  'sizes,  prev, pager, next, jumper' : ' prev, pager, next, jumper'"
    :total="pageData.totalSize"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  />
</template>

<script lang="ts">

import { defineComponent, reactive, toRefs } from 'vue'
// import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  props: {
    // eslint-disable-next-line vue/require-default-prop
    pageData: {
      type: Object
    },
    pageSize:{
      type: Number,
      default: 50
    },
    init:{
      type: Boolean,
      default: true
    },
    pagerCount:{
      type: Number,
      default: 7
    },
    showPageSizes:{
      type: Boolean,
      default: false
    },
    small:{
      type: Boolean,
      default: false
    }
  },
  emits: ['fetchData'],
  setup(props, context) {
    const dataMap = reactive({
      isShow: true,
      curPage: 1
    })
    // const router = useRouter()
    // const route = useRoute()
    // const stockCode = route.query.code
    // const value = ref(0)
    // let options:any = []

    // context.emit('change', options[0])
    const changeCurPage = (val: number)=>{
      dataMap.isShow = false
      context.emit('fetchData', {
        pageNum: Number(val)
      })
      setTimeout(()=>{
        dataMap.isShow = true
      }, 100)
    }

    const handleSizeChange = (val: number)=>{
      // context.emit('changeTips')
      console.log(val)
      context.emit('fetchData', {
        pageSize: val,
      })
    }

    const handleCurrentChange = (val: number)=>{
      dataMap.curPage = val
      context.emit('fetchData', {
        pageNum: val
      })
      console.log(val)
    }

    const setCurrentPage = (val: number)=>{
      dataMap.curPage = val
    }

    if ( props.init ){
      context.emit('fetchData', {
        pageNum: 1,
        pageSize: props.pageSize,
      })
    }


    return {
      // value,
      // options,
      ...toRefs(dataMap),
      changeCurPage,
      handleSizeChange,
      handleCurrentChange,
      setCurrentPage
    }
  }
})
</script>

<style lang="scss" scoped>
.el-pagination{
  width: 100%;
  margin-top: 40px;
  text-align: center;
  white-space: normal;
  // display: flex;
  // justify-content: center;
  // align-items: center;

  ::v-deep .el-pagination__total{
    display: none;
  }
  ::v-deep  .el-pagination__sizes{
    height: 37px;
    .el-input__inner{
      height: 37px;
    }
  }
  ::v-deep .el-pager{
    padding-bottom: 5px;
    li{
      min-width: 37px;
      height: 37px;
      line-height: 37px;
      border-radius: 4px;
      font-size: 14px;
      border: 1px solid #CCCCCC;
      color: #333333;
      margin: 0 8px;
      &.active{
        background: #386FF2;
        border-color: #386FF2;
        color: #fff;
      }
    }
  }
  ::v-deep .btn-prev,::v-deep .btn-next{
    padding: 0;
    margin-bottom: 5px;
    width: 82px;
    height: 37px;
    border-radius: 4px;
    border: 1px solid #CCCCCC!important;
  }
  ::v-deep .el-pagination__jump{
    height: 37px;
    .el-input__inner{
      height: 37px;
    }
  }
}
</style>
