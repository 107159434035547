<template>
  <div class="market_table_box">
    <el-table
      :data="boardList"
      class="table-style-2 cursor"
      @row-click='goDetail'
      border
      :header-cell-style="headerMethod"
      style="width: 100%">
      <el-table-column prop="boardName" label="行情" min-width="143" align="center" />
      <el-table-column prop="stockCount" label="成分股" min-width="145" align="center">
        <template #default="{ row }">{{ row.stockCount }}家</template>
      </el-table-column>
      <el-table-column prop="totalMarketCap" label="总市值" min-width="145" align="center">
        <template #default="{ row }">{{ unitConversion(row.totalMarketCap) }}</template>
      </el-table-column>
      <el-table-column prop="floatMarketCap" label="流通市值" min-width="160" align="center">
        <template #default="{ row }">{{ unitConversion(row.floatMarketCap) }}</template>
      </el-table-column>
      <el-table-column label="领涨股" min-width="95" align="center">
        <template #default='{row}'>{{ row.topStockName }}<br />{{ row.topStockCode }}</template>
      </el-table-column>
      <el-table-column label="领涨股" min-width="95" align="center">
        <template #default='{row}'><div v-dClass="row.topStockIncr">{{ row.topStockIncr >= 0 ? '+' + keepTwo(row.topStockIncr) : '-' + keepTwo(row.topStockIncr) }}%</div></template>
      </el-table-column>
      <el-table-column prop="riseCount" label="涨平跌" min-width="285" align="center">
        <template #default="{ row }">
          <div class="c-col">
            <div v-dClass="row.riseCount">{{ row.riseCount }}</div>
            <div>{{ row.fallCount }}</div>
            <div v-dClass="-row.fallCount">{{ row.flatCount }}</div>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue'
export default defineComponent({
  props: {
    boardList: {
      type: Array,
      default: []
    }
  },
  setup() {
    const dataMap = reactive({
    })
    const headerMethod = (item: any) => {
      const { row, columnIndex } = item
      if (row[0].level == 1) {
        row[4].colSpan = 0
        row[5].colSpan = 2
        if (columnIndex === 4) {
          return { display: 'none' }
        }
      }
    }
    const unitConversion = (item: any) => {
      return ((Number(item) / 100000000) / 10000).toFixed(2) + '万亿'
    }
    const goDetail = (item: any) => {
      window.open(`/page/data_center_all?industryName=${item.boardName}&boardType=market`)
    }
    const keepTwo = (item: any) => {
      return Number(item).toFixed(2)
    }
    return {
      ...toRefs(dataMap),
      headerMethod,
      unitConversion,
      goDetail,
      keepTwo
    }
  }
})
</script>
<style scoped lang="scss">
.market_table_box {
  margin-top: 70px;
}

.c-col{
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  &>div{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    &:nth-child(1){
      border-right: 1px solid rgb(235, 238, 245);
    }
    &:nth-child(2){
      border-right: 1px solid rgb(235, 238, 245);
    }
  }
}
</style>
