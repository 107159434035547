<template>
  <div class="wrap">
    <PageTitle title='融资融券' :sub-title="`> ${stockName}融资融券明细`" />
    <div class="bg">
      <NavTitle :title="`${stockName}融资融券明细`" :date="`截至${stockDate}`" style="padding-left:0" />
      <div class="describe" v-if="boardDetail.detail">
        昨日融资余额<span class="black">{{ zh(boardDetail.detail.preRzye) }}元</span>，
        较前一日{{boardDetail.detail.pre1RzyeAdd > 0 ? '增加' : '减少'}}<span v-dClass='boardDetail.detail.pre1RzyeAdd'>{{zh(boardDetail.detail.pre1RzyeAdd)}}元</span>
        ，{{boardDetail.detail.pre1RzyeAdd > 0 ? '增幅' : '降幅'}}为<span v-dClass='boardDetail.detail.pre1RzyeIncr'>{{Math.abs(boardDetail.detail.pre1RzyeIncr)}}%</span>，

        <template v-if="boardDetail.detail.continueRzyeAdd > 1">融资余额<span v-dClass='boardDetail.detail.continueRzyeAdd'>{{Math.abs(boardDetail.detail.continueRzyeAdd)}}{{ boardDetail.detail.continueRzyeAdd > 0 ? '连升' : '连降' }}</span>，</template>

        近5日{{boardDetail.detail.pre5RzyeAdd > 0 ? '增加' : '减少'}}<span v-dClass='boardDetail.detail.pre5RzyeAdd'>{{zh(boardDetail.detail.pre5RzyeAdd)}}</span>
        ，{{boardDetail.detail.pre5RzyeAdd > 0 ? '增幅' : '降幅'}}<span v-dClass='boardDetail.detail.pre5RzyeIncr'>{{Math.abs(boardDetail.detail.pre5RzyeIncr)}}%</span>
        ；融券余额<span class="black">{{zh(boardDetail.detail.preRqye)}}亿元</span>
        ，相较5日均值{{boardDetail.detail.pre5AvgRqyeAdd > 0 ? '增加' : '减少'}}
        <span v-dClass='boardDetail.detail.pre5AvgRqyeAdd'>{{zh(boardDetail.detail.pre5AvgRqyeAdd)}}</span>。
      </div>
      <div class="chart-box">
        
        <ChartA id='ChartA' :chartData="boardDetail.dayKList" :chartKey='chartKey' :showChartIdx='showChartIdx'  />
        <div class="select-chart-list">
          <ul>
            <li :class="showChartClass === 0 ? 'active' : ''" @click="tabChart(0, 1, 'rzye')">
              <div class="icon circle"></div>
              <div class="text">融资余额(元)</div>
            </li>
            <li :class="showChartClass === 1 ? 'active' : ''" @click="tabChart(1, 2, 'rzjmr')">
              <div class="icon line"></div>
              <div class="text">融资净买入(元)</div>
            </li>
            <li :class="showChartClass === 2 ? 'active' : ''" @click="tabChart(2, 1, 'rzyezb')">
              <div class="icon circle"></div>
              <div class="text">融资余额占流通市值比(%)</div>
            </li>
            <li :class="showChartClass === 3 ? 'active' : ''" @click="tabChart(3, 1, 'rzrqye')">
              <div class="icon circle"></div>
              <div class="text">融资融券余额(元)</div>
            </li>
          </ul>
          <ul>
            <li :class="showChartClass === 4 ? 'active' : ''" @click="tabChart(4, 1, 'rqye')">
              <div class="icon circle"></div>
              <div class="text">融券余额(元)</div>
            </li>
            <li :class="showChartClass === 5 ? 'active' : ''" @click="tabChart(5, 2, 'rqjmc')"> 
              <div class="icon line"></div>
              <div class="text">融券净卖出(股)</div>
            </li>
            <li class="active spj" >
              <div class="icon circle"></div>
              <div class="text">收盘价(元)</div>
            </li>
          </ul>
        </div>
      </div>

    </div>
    <div class="bg">
      <div class="date-tab">
        <DateTab @change='getInitTime' />
      </div>
      <TableA ref='refTA' />
      <TableB ref='refTB' />
    </div>
    
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, toRefs, ref, nextTick } from 'vue'
import NavTitle from '../components/nav-title.vue'
import PageTitle from '../components/page-title.vue'
import DateTab from '../../northbound-funds/components/CustomTab.vue'
import ChartA from './components/ChartA.vue'
import TableA from './components/TableA.vue'
import TableB from './components/TableB.vue'
import CTab from '../components/nav-switch.vue'
import { getMarginStockDetail } from '@/apis/trading'

import { getStockTradeDate } from '@/apis/market'
import moment from 'moment/moment'
import { useRoute  } from "vue-router";

export default defineComponent({
  components:{
    NavTitle,
    PageTitle,
    DateTab,
    ChartA,
    CTab,
    TableA,
    TableB,
  },
  setup() {
    const route = useRoute();
    const refTA = ref(null)
    const refTB = ref(null)
    const dataMap = reactive({
      stockName: route.query.name,
      stockDate: '',
      initialStatus: 1,
      showChartIdx: 1,
      showChartClass: 0,
      chartKey : 'rzye',
      tabList: [
        {
          id: 1,
          name: '电池行业持股明细'
        },
        {
          id: 2,
          name: '电池行业持股历史'
        }
      ],
      stockList: {
        list: []
      },
      boardDetail: {}
    })

    const getTime = async () => {
      const data6 = await getStockTradeDate({ })
      dataMap.stockDate = moment((data6 as any).data.prevRzrqTradeDate).format('YYYY年MM月DD日');
    }
    getTime()

    const fetchData = async () => {
      // 明细
      const boardDetail = await getMarginStockDetail({ stockCode: route.query.code })
      dataMap.boardDetail = (boardDetail as any).data;
    }
    fetchData()

    const tabChart = (idx:any, type:any, key:any) => {
      dataMap.showChartClass = idx
      dataMap.showChartIdx = type
      dataMap.chartKey = key
    }

    const changeTab = (item: any) => {
      console.log(item, 'item')
      dataMap.initialStatus = item.id
    }

    const getInitTime = async (val:any) => {
      nextTick(()=>{
        (refTA as any).value.changeTime(val)
      })
    }

    const zh = (param:any) => {
      return `${Math.abs(param) >= 100000000 ? (param / 100000000).toFixed(2) + '亿' : Math.abs(param) >= 10000 ? Math.abs((param / 10000)).toFixed(2) + '万' : param.toFixed(2) }`
    }

    return {
      ...toRefs(dataMap),
      tabChart,
      changeTab,
      getInitTime,
      refTA,
      refTB,
      zh
    }
  }
})
</script>
<style scoped lang="scss">
.wrap{
  margin: 0 35px;
  .bg{
    background: #fff;
    padding: 0 20px;
    margin-bottom: 30px;
    .describe{
      color: #666666;
      margin: 20px 0 30px;
      .black{
        color: #333;
        font-weight: bold;
      }
      .red,.green{
        font-weight: bold;
      }
    }
    .chart-box{
      
      .select-chart-list{
        padding-bottom: 50px;
        ul{
          width: 900px;
          margin: auto;
          margin-top: 28px;
          display: flex;
          li{
            margin: 0 100px 9px 0;
            cursor: pointer;
            display: flex;
            align-items: center;
            &.active{
              .circle{
                &:before,&::after{
                  border: 1px solid #3B7FEE;
                }
              }
              .line{
                background: #3B7FEE;
              }
            }
            &.spj{
              .circle{
                &:before,&::after{
                  border: 1px solid #FE9625;
                  // background: #FE9625;
                }
              }
            }
            .circle{
              width: 19px;
              position: relative;
              &:before{
                content: '';
                width: 9px;
                height: 9px;
                border-radius: 50%;
                background: #FFFFFF;
                border: 1px solid #CDCDCD;
                z-index: 1;
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                margin: auto;
              }
              &::after{
                content: '';
                width: 19px;
                height: 1px;
                background: #CDCDCD;
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                margin: auto;
              }
            }
            .line{
              width: 8px;
              height: 3px;
              background: #CDCDCD;
            }
            .text{
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              padding-left: 5px;
            }
          }
        }
      }
    }
    
  }
  .date-tab{
    text-align: right;
    padding-top: 30px;
  }
}
</style>
