/*
 * @Description: 个股诊断相关接口
 */
import https from '@/utils/https'
import { RequestParams, ContentType, Method } from 'axios-mapper'
const prefix = '/research/share'

//获取价值评估数据
export const getDiagnosticAssessment = (params: RequestParams) => {
  // return https(false).request('/ranking/stock/index/change', Method.POST, params, ContentType.json)
  return https().request(prefix + '/diagnostic/assessment', Method.GET, params, ContentType.form)
}

//通过股票编码获取实时数据
export const getStockRealtime = (params: RequestParams) => {
  // return https(false).request('/ranking/stock/index/change', Method.POST, params, ContentType.json)
  return https().request(prefix + '/stock/realtime/get', Method.GET, params, ContentType.form)
}

//通过股票获取估值描述信息
export const getValuationDesc = (params: RequestParams) => {
  // return https(false).request('/ranking/stock/index/change', Method.POST, params, ContentType.json)
  return https().request(prefix + '/valuation/stock/desc', Method.GET, params, ContentType.form)
}

//通过股票获取每日估值数据
export const getValuationDaily = (params: RequestParams) => {
  // return https(false).request('/ranking/stock/index/change', Method.POST, params, ContentType.json)
  return https().request(prefix + '/valuation/stock/valuation/daily', Method.GET, params, ContentType.form)
}

//通过股票获取4个比率分位数
export const getValuationQuantileDaily = (params: RequestParams) => {
  // return https(false).request('/ranking/stock/index/change', Method.POST, params, ContentType.json)
  return https().request(prefix + '/valuation/stock/quantile/daily', Method.GET, params, ContentType.form)
}

//获取个股评级机构数
export const getReportRanking = (params: RequestParams) => {
  // return https(false).request('/ranking/stock/index/change', Method.POST, params, ContentType.json)
  return https().request(prefix + '/report/org/count/ranking', Method.GET, params, ContentType.form)
}

//个股评级价格记录数据
export const getReportPriceDaily = (params: RequestParams) => {
  // return https(false).request('/ranking/stock/index/change', Method.POST, params, ContentType.json)
  return https().request(prefix + '/report/rating/price/daily', Method.GET, params, ContentType.form)
}

//获取个股机构调研数
export const getSurveyOrgRankingMoblie = (params: RequestParams) => {
  // return https(false).request('/ranking/stock/index/change', Method.POST, params, ContentType.json)
  return https().request(prefix + '/survey/stock/org/count/ranking', Method.GET, params, ContentType.form)
}

//获取技术面分析/趋势研判/市场表现数据
export const getDiagnosticRrendInfo = (params: RequestParams) => {
  // return https(false).request('/ranking/stock/index/change', Method.POST, params, ContentType.json)
  return https().request(prefix + '/diagnostic/trendInfo', Method.GET, params, ContentType.form)
}

//获取资金流向/机构持仓
export const getDiagnosticAggregation = (params: RequestParams) => {
  // return https(false).request('/ranking/stock/index/change', Method.POST, params, ContentType.json)
  return https().request(prefix + '/diagnostic/aggregation', Method.GET, params, ContentType.form)
}

//获取投资亮点和风险提示数据
export const getDiagnosticRiskInvestment = (params: RequestParams) => {
  // return https(false).request('/ranking/stock/index/change', Method.POST, params, ContentType.json)
  return https().request(prefix + '/diagnostic/riskInvestment', Method.GET, params, ContentType.form)
}

//大事提醒--舆情关注
export const getDiagnosticSentiment = (params: RequestParams) => {
  // return https(false).request('/ranking/stock/index/change', Method.POST, params, ContentType.json)
  return https().request(prefix + '/diagnostic/sentiment', Method.GET, params, ContentType.form)
}

//大事提醒--公司业绩
export const getDiagnosticPerformance = (params: RequestParams) => {
  // return https(false).request('/ranking/stock/index/change', Method.POST, params, ContentType.json)
  return https().request(prefix + '/diagnostic/performance', Method.GET, params, ContentType.form)
}

//大事提醒--内部交易/大额解禁/激励计划
export const getDiagnosticMajorEvent = (params: RequestParams) => {
  // return https(false).request('/ranking/stock/index/change', Method.POST, params, ContentType.json)
  return https().request(prefix + '/diagnostic/majorEvent', Method.GET, params, ContentType.form)
}

//获取盈利能力数据
export const getDiagnosticProfitability = (params: RequestParams) => {
  // return https(false).request('/ranking/stock/index/change', Method.POST, params, ContentType.json)
  return https().request(prefix + '/diagnostic/profitability', Method.GET, params, ContentType.form)
}

//通过股票编码获取数据
export const getStockInfo = (params: RequestParams) => {
  // return https(false).request('/ranking/stock/index/change', Method.POST, params, ContentType.json)
  return https().request(prefix + '/stock/get', Method.GET, params, ContentType.form)
}


