

import { defineComponent, reactive, toRefs } from 'vue'
import CTitle from '@/components/title/Index.vue'
import LineChartB from '@/components/charts/LineChartB.vue'
import LineChartC from '@/components/charts/LineChartC.vue'
import LineChartK from '@/components/charts/LineChartK.vue'
import LineChartE from '@/components/charts/LineChartE.vue'
import { getDiagnosticRrendInfo } from '@/apis/diagnosisMoble'
import { useRoute } from 'vue-router'

export default defineComponent({
  props: {
    dataMap: {
      type: Array,
      default: []
    }
  },
  components: {
    CTitle,
    LineChartB,
    LineChartC,
    LineChartK,
    LineChartE
  },
  setup() {
    // const stockCode = '600519.SH'
    const route = useRoute()
    const stockCode = route.query.code
    const dataMap = reactive({
      ajaxRes: false,
      activeIdx: 1,
      difnum: 0,
      deanum: 0,
      macdnum: 0,
      max1: 0,
      min1: 0,
      max2: 0,
      min2: 0,
      rsi1: 0,
      rsi2: 0,
      rsi3: 0,
      stockList1: {},
      timeList: []
    })
    
    const fetchData = async () => {
      const data1 = await getDiagnosticRrendInfo({ stockCode: stockCode })
      dataMap.stockList1 = (data1 as any).data;
      if( dataMap.stockList1 ){

        let list = (dataMap.stockList1 as any).macdRsis;
        dataMap.difnum = list[list.length-1].diff
        dataMap.deanum = list[list.length-1].dea
        dataMap.macdnum = list[list.length-1].macd
        let max = 0,min = 0,max2 = 0, min2 = 0
        for (let index = 0; index < list.length; index++) {
          const element = list[index]
          if(index==0){
            max = Number(element.diff)
            min = Number(element.diff)
            
            max2 = Number(element.rsi6)
            min2 = Number(element.rsi6)
          }else{
            if( max < Number(element.diff) ){
              max= Number(element.diff)
            }
            if(min > Number(element.diff) ){
              max= Number(element.diff)
            }

            if(max2<element.rsi6*1){
              max2 = element.rsi6*1
            }
            if(min2>element.rsi6*1){
              min2 = element.rsi6*1
            }
          }

          if( index === list.length-1 ){
            dataMap.rsi1 = element.rsi6
            dataMap.rsi2 = element.rsi12
            dataMap.rsi3 = element.rsi24
          }
          dataMap.max1 = max
          dataMap.min1 = min
          dataMap.max2 = max2
          dataMap.min2 = min2
        }
      }
     
      dataMap.ajaxRes = true;
    }
    fetchData()

    const getRsiVal = (res:any) => {
      dataMap.rsi1 = res[0]
      dataMap.rsi2 = res[1]
      dataMap.rsi3 = res[2]
    }
    const getMacdVal = (res:any) => {
      dataMap.difnum = res[0]
      dataMap.deanum = res[1]
      dataMap.macdnum = res[2]
    }
    const getTimeList = (res:any) => {
      dataMap.timeList = res
    }

    
    return {
      ...toRefs(dataMap),
      getRsiVal,
      getMacdVal,
      getTimeList
    }
  }
})
