
import { defineComponent, getCurrentInstance, onMounted, reactive, toRefs, ref } from 'vue'
import { getStockQuery } from '@/apis/home'
import { getSurveySearch } from '@/apis/research'
import { getFundsSearch } from '@/apis/northboundFunds'
import { getFinanceReportSearch } from '@/apis/financial-report'
// 融资融券搜索接口
import { getMarginSearch } from '@/apis/margin-trading'

import { useRouter } from 'vue-router'

export default defineComponent({
  props: {
    path: {
      type: String
    },
    open: {
      type: Boolean
    },
    isResearch: {
      type: Boolean
    },
    isFunds: {
      type: Boolean
    },
    isNorthbound: {
      type: Boolean
    },
    isFinancial: {
      type: Boolean
    },
    holder: {
      type: String
    },
    iconPosition: {
      type: String,
      default: 'suffix'
    },
    isStockDetail: {
      type: String,
    },
    rightText: {
      type: String,
    }
  },
  setup(props,context) {
    const router = useRouter()
    const dataMap = reactive({
      searchVal: '',
      searchList: [],
    })

    let timer:any = null;
    const fetchData = () => {
      if( !dataMap.searchVal ){
        clearTimeout(timer)
        // dataMap.searchList = getSearchHistoryList()
        return false
      }
      if( timer ){
        clearTimeout(timer)
      }
      timer = setTimeout(async ()=>{
        if( props.isResearch ){
          let data = await getSurveySearch({ keyWord: dataMap.searchVal, limit: 20})
          dataMap.searchList = (data as any).data;
        }else if( props.isFunds ){
          let data = await getFundsSearch({ keyword: dataMap.searchVal, limit: 20})
          dataMap.searchList = (data as any).data;
        }else if( props.isNorthbound ){
          let data = await getMarginSearch({ keyword: dataMap.searchVal, limit: 20})
          dataMap.searchList = (data as any).data;
        }else if( props.isFinancial ){
          let data = await getFinanceReportSearch({ keyword: dataMap.searchVal, limit: 20})
          dataMap.searchList = (data as any).data;
        }else{
          let data = await getStockQuery({ keyword: dataMap.searchVal, limit: 20})
          dataMap.searchList = (data as any).data;
        }
      },500)
    }

    const blurHandler = () => {
      setTimeout(()=>{
        dataMap.searchList = []
      },200)
    }

    const goDetail = (item:any) => {
      if( item.state === 1 || item.state === 4 ){
        return false;
      }

      setSearchHistoryList(item)

      if( props.isResearch ){
        if( item.type === 'stock' ){
          window['$props'].push({
            path: `/investment${props.path}`,
            query:{
              code: item.code,
              stockCode: item.name
            }
          })
        }else if( item.type === 'industry' ){
          window['$props'].push({
            path: `/investment${props.path}`,
            query:{
              industryName: item.name
            }
          })
        }else if( item.type === 'org' ){
          window['$props'].push({
            path: `/investment${props.path}`,
            query:{
              orgName: item.name
            }
          })
        }

        return false
      }

      if( props.isFunds ){
        if( item.type === 'stock' ){
          const path = '/northboundFunds/shares'
          window.open( `/investment${path}?code=${item.code}`)
        }else if( item.type === 'industry' ){
          const path = '/northboundFunds/industry'
          const boardType = item.boardType ? item.boardType : 'swl2'
          window.open( `/investment${path}?code=${item.name}&boardType=${boardType}`)
        }
        return false;
      }
      // 融资融券
      if( props.isNorthbound ){
        if( item.type === 'stock' ){
          console.log(item)
          const path = '/marginTrading/shares'
          window.open( `/investment${path}?code=${item.code}&name=${item.name}`)
        }else if( item.type === 'industry' ){
          const path = '/marginTrading/industry'
          const boardType = item.boardType ? item.boardType : 'swl2'
          window.open( `/investment${path}?code=${item.name}&boardType=${boardType}`)
        }
        return false;
      }
      // 财报
      if( props.isFinancial ){
        const path = '/financial/detailed'
        window.open( `/investment${path}?stockCode=${item.code}&stockName=${item.name}`)
        return false;
      }

      //个股详情
      if( props.isStockDetail ){
        const path = props.path
        window.open( `/investment${path}?stockCode=${item.code}&stockName=${item.name}`)
        return false;
      }

      if( props.open ){
        window.open(window['location']['origin']+window['location']['pathname']+'?code='+item.code)
        return false
      }
      window['$props'].push({
        path: `/investment${props.path}`,
        query:{
          code: item.code
        }
      })
    }

    const setSearchHistoryList = (item:any) => {
      const pathname = location.pathname.replace('/investment', '')
      const list = getSearchHistoryList()
      const { id, code, name } = item
      const obj = { id, code, name }
      if( list.filter((item:Object)=>item['code'] === code).length === 0 ){
        localStorage.setItem(pathname, list.length ? JSON.stringify([obj, ...list]) : JSON.stringify([obj]))
      }
    }

    const getSearchHistoryList = () => {
      const pathname = location.pathname.replace('/investment', '')
      const list = localStorage.getItem(pathname)
      if( list ){
        return JSON.parse(list)
      }
      return []
    }

    return {
      ...toRefs(dataMap),
      fetchData,
      goDetail,
      blurHandler
    }
  }
})

