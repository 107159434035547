
import { defineComponent, reactive, toRefs } from 'vue'
export default defineComponent({
  props: {
    title: {
      type: String,
      default: ''
    },
    date: {
      type: String,
      default: ''
    }
  },
  setup() {
    const dataMap = reactive({

    })
    const goShare = () => {
      window.open(`/investment/marginTrading/share-page`)
    }
    return {
      ...toRefs(dataMap),
      goShare
    }
  }
})
