<template>
  <div
    :id="id"
    :class="className"
    :style="{height: height, width: width}"
  />
</template>

<script lang="ts">
import { defineComponent, onActivated, onBeforeUnmount, onDeactivated, onMounted, nextTick, watch } from 'vue'
import resize from './mixins/resize'
import { init, EChartsOption } from 'echarts'
import * as echarts from 'echarts'
import moment from 'moment';
export default defineComponent({
  props: {
    chartData: {
      type: Object,
      default: {}
    },
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '193px',
      required: true
    }
  },
  setup(props,context) {
    watch(props, (newName, oldName) => {
      initChart()
    });
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()
    const initChart = () => {
      const map = props.chartData;
      if( !Object.keys(map).length ){
        return false;
      }

      const barChart = init(document.getElementById(props.id) as HTMLDivElement)
      // let xdata = [],seriesData = [],markLineData=[]
      // for (let index = 0; index < map.data.length; index++) {
      //   xdata.push(map.data[index].TDate)
      //   seriesData.push(map.data[index].y)
      //   // seriesData.push(props.chartData[index].valuation)
      // }
      let kxList = props.chartData.kxList
      let list1:any = [],list2:any=[],max=0,min=0
      if(kxList.length>0){
        kxList.forEach((item:any,i:any)=>{
          list2.push(item.Close)
          list1.push(moment(item.TDate).format('YYYY-MM-DD'))
          // let time =  item.TDate.split(' ')[0]
            // let timearr = time.split('/')
            // timearr.forEach((item2:any)=>{
            //   if(item2<10){
            //     item2 = '0'+ item2
            //   }
            // })
            // let timetext = timearr.join('-')
            // list1.push(timetext)
          if(i==0){
            max = item.Close*1
            min = item.Close*1
          }else{
            if(max <item.Close*1){ 
              max = item.Close*1
            }
            if(min >item.Close*1){
              min = item.Close*1
            }
          }
        })
      }
      max = Math.floor(max*1.2)+(Math.floor(max*1.2)%2==0?0:(2-(Math.floor(max*1.2)%2)))
      min = Math.floor(min*0.9)+(Math.floor(min*0.9)%2==0?0:(2-(Math.floor(min*0.9)%2)))
      let interval = (max-min)/2
      context.emit('getTimeList', [list1[0],  list1[Math.floor(list1.length/2)], list1[list1.length-1]])
      // this.setData({
      //   kxListleftdata: list1[0],
      //   kxListrightdata: list1[list1.length-1],
      //   kxListcenterdata: list1[Math.floor(list1.length/2)],
      // })

      barChart.setOption({
        //  backgroundColor: "#ffffff",
        grid: {
          containLabel: true,
          bottom:0,
          top:10,
          left:0,
          right:0
        },
        tooltip: {
          show: true,
          trigger: 'axis', 
          // position:'inside',
          padding:4,
          textStyle:{
            color:'#999999',
            fontSize:'10',
          },
          position:function(point:any,params:any,dom:any,rect:any){
            let x = 0
            let y= 0
            if(point[0]>210){
              x = point[0] - 100
            }else{
              x = point[0] +10
            }
            if(point[1]>55){
              y = point[1] - 60
            }else{
              y = point[1]
            }
            return [x,y]
          },
          // formatter:function(param:any){
          //   // console.log(param)
          //  // 利用循环更改显示的内容
          //   let list = param.length
          //   let Oparam=param
          //   // console.log(list)
          //   let txt =" "+ param[0].name+"\n"
          //   for (var i=0;i<list;i++){
          //    if(i==list-1){
          //     txt +=param[i].marker+ param[i].seriesName + ":" + param[i].data
          //    }else{
          //      txt +=param[i].marker+ param[i].seriesName + ":" + param[i].data + '\n'
          //    }
          //   }
          //   return txt
          // }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: list1,
          show:  false,
          axisLine:{
            lineStyle:{
              color: '#BFC0C4',
              width:1,
            }
          },
          axisLabel:{
            interval:Math.floor(list1.length/3),
            formatter:function(val:any){      
              return ''
            },
            margin:20,
            textStyle:{
              
            }
          }
        },
        yAxis: {
          x: 'center',
          type: 'value',
          splitLine: {
            lineStyle: {
              // type: 'dashed'
              color: '#e6e6e6',
              width:1,
            }
          },
          axisLabel : {
            textStyle: {
              color: '#0E1018',
              fontSize: '12px'
            }
          },
          min:min,
          max:max,
          interval:interval,
          // show: false
        },
        series: [{
          name: '股价',
          type: 'line',
          smooth: false,
          showSymbol:false,
          itemStyle:{
            normal:{
              lineStyle:{
                color:'#0285FA'
              },
            }
          },
          areaStyle:{
            normal: {
              color: {
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                    offset: 0,
                    color: "#BCDEFD" // 0% 处的颜色
                  }, {
                    offset:  1,
                    color: "#F9FBFF" // 100% 处的颜色
                  }],
    
                  }
                }
          },
          markLine:{
              data: [{
                yAxis: props.chartData.supportPosition,
                lineStyle:{
                  normal:{
                    type:'dashed',
                    color:'#F6C52A',
                    width:1,
                  }
                }
              },{
                yAxis: props.chartData.pressurePosition,
                lineStyle:{
                  normal:{
                    type:'dashed',
                    color: '#E8828E',
                    width:1,
                  }
                }
              }],
              symbol:'none',
              silent: true,
              label:{
                show:false
              },
              
          },
          data:list2
        }]
      } as unknown as EChartsOption)
      chart.value = barChart
    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {

    }
  }
})
</script>

<style lang="scss" scoped>

</style>
