<template>
  <div class="nav_title_box">
    <div class="left_box">
      <div class="text_box">
        <span>{{ title }}</span>
        <div class="icon_box"></div>
      </div>
      <div class="date_box" v-if="date">({{ date }}）</div>
    </div>
    <div class="right_box">
<!--      <span @click="goShare">去分享>></span>-->
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue'
export default defineComponent({
  props: {
    title: {
      type: String,
      default: ''
    },
    date: {
      type: String,
      default: ''
    }
  },
  setup() {
    const dataMap = reactive({

    })
    const goShare = () => {
      window.open(`/investment/marginTrading/share-page`)
    }
    return {
      ...toRefs(dataMap),
      goShare
    }
  }
})
</script>
<style scoped lang="scss">
.nav_title_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 29px 0 0 20px;
  > .left_box {
    display: flex;
    align-items: center;
    > .text_box {
      position: relative;
      > span {
        display: block;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 28px;
        position: relative;
        z-index: 2;
      }
      &::after {
        content: '';
        width: 80px;
        height: 9px;
        background: linear-gradient(270deg, rgba(56,111,242,0) 0%, #386FF2 100%);
        z-index: 1;
        position: absolute;
        left: 0;
        bottom: -1px;
      }
    }
    > .date_box {
      font-size: 17px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 24px;
      margin-left: 6px;
    }
  }
  > .right_box {
    > span {
      color: #386FF2;
      display: block;
      cursor: pointer;
    }
  }
}
</style>
