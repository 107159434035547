
import { defineComponent, reactive, toRefs } from 'vue'
import CTitle from "@/components/title/Index.vue"
import NewTitle from '@/components/new-title/Index.vue'
import Search from '@/components/search-input/Index.vue'
import BarChartI from '@/components/charts/BarChartI.vue'
import BarChartJ from '@/components/charts/BarChartJ.vue'
import SurveyFrequency from '@/views/research/components/SurveyFrequency.vue'

import { getSurveyStockFirstCount, getSurveyMarketCapFirstCount } from '@/apis/research'
import moment from 'moment'

export default defineComponent({
  components: {
    CTitle,
    NewTitle,
    Search,
    BarChartI,
    BarChartJ,
    SurveyFrequency
  },
  setup() {
    const dataMap = reactive({
      ajaxRes: false,
      stockList1: [],
      stockList2: []
    })
    const fetchData = async () => {
      let data1 = await getSurveyStockFirstCount({ })
      dataMap.stockList1 = (data1 as any).data

      let data2 = await getSurveyMarketCapFirstCount({ })
      dataMap.stockList2 = (data2 as any).data


      dataMap.ajaxRes = true;
      
    }
    fetchData();

    return {
      moment,
      ...toRefs(dataMap)
    }
  }
})
