export default{
  month: [
    {
      value: 0,
      label: '最近1月',
      timeLevel: '1m',
      timeLevelwz: '1'
    },
    {
      value: 1,
      label: '最近3月',
      timeLevel: '3m',
      timeLevelwz: '3'
    },
    {
      value: 2,
      label: '最近6月',
      timeLevel: '6m',
      timeLevelwz: '6'
    },
    {
      value: 3,
      label: '最近1年',
      timeLevel: '1y',
      timeLevelwz: '12'
    },
    {
      value: 4,
      label: '自定义',
      timeLevel: 'custom',
      timeLevelwz: '0'
    }
  ],
  timeSelection: [
    {
      value: -1,
      label: '1周',
      timeLevel: '1w',
      timeLevelwz: '1',
      timeUnit: 'WEEKS',
      num: 1
    },
    {
      value: 1,
      label: '1月',
      timeLevel: '1m',
      timeLevelwz: '1',
      timeUnit: 'MONTHS',
      num: 1
    },
    {
      value: 3,
      label: '3月',
      timeLevel: '3m',
      timeLevelwz: '1',
      timeUnit: 'MONTHS',
      num: 3
    },
    {
      value: 6,
      label: '6月',
      timeLevel: '6m',
      timeLevelwz: '1',
      timeUnit: 'MONTHS',
      num: 6
    },{
      value: 12,
      label: '1年',
      timeLevel: '1y',
      timeLevelwz: '1',
      timeUnit: 'YEARS',
      num: 1
    },
  ],
  //评级相关
  ratingRelated: ['不限', '买入', '增持', '中性', '减持', '卖出', '评级调高', '评级调低', '首次覆盖'],
  //类型
  type: ['不限', '业绩点评', '事件点评', '调研纪要', '公司深度', '新股研究'],
  //北向资金 日
  day: [
    {
      value: 'DAYS',
      label: '1日',
      count: '1',
      lbValue: '1d',
      text: '1日'
    },
    {
      value: 'DAYS',
      label: '3日',
      count: '3',
      lbValue: '3d',
      text: '3日'
    },
    {
      value: 'DAYS',
      label: '5日',
      count: '5',
      lbValue: '5d',
      text: '5日'
    },
    {
      value: 'DAYS',
      label: '10日',
      count: '10',
      lbValue: '10d',
      text: '10日'
    },
    {
      value: 'MONTHS',
      label: '月排行',
      lbValue: '1m',
      text: '月'
    },
    {
      value: 'QUARTERS',
      label: '季排行',
      lbValue: '1q',
      text: '季'
    },
    {
      value: 'YEARS',
      label: '年排行',
      lbValue: '1y',
      text: '年'
    },
    {
      type: 'custom',
      label: '自定义'
    }
  ],
  //北向资金 周
  week: [
    {
      value: 'WEEKS',
      label: '周排行',
      text: '周'
    },
    {
      value: 'MONTHS',
      label: '月排行',
      text: '月'
    },
    {
      value: 'QUARTERS',
      label: '季排行',
      text: '季'
    },
    {
      value: 'YEARS',
      label: '年排行',
      text: '年'
    },
    {
      type: 'custom',
      label: '自定义'
    }
  ],
}
