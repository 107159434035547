
import { reactive, defineComponent, toRefs } from 'vue'
import optionList from '@/utils/margin-trading'
export default defineComponent({
  props:{
    activeStatus : {
      type : Number,
      default : 0
    },
  },
  setup(props, context) {
    const dataMap = reactive({
      optionList,
      activeType: props.activeStatus,
      selectTime: '',
      isShowDate: false,
      beforeOneItem: {}
    })

    const switchItem =  (item: any, index: any) => {
      dataMap.activeType = index
      if (item.type === 'custom') {
        dataMap.isShowDate = true
        dataMap.beforeOneItem = item
      } else {
        dataMap.isShowDate = false
        dataMap.beforeOneItem = item
        context.emit('tabChange', item)
      }
    }
    const getTime = (item: any) => {
      console.log(item, '获取时间')
      context.emit('tabChange',dataMap.beforeOneItem, {
        startDate: window.moment(item[0]).format('YYYY-MM-DD'),
        endDate: window.moment(item[1]).format('YYYY-MM-DD'),
      })
    }
    return {
      ...toRefs(dataMap),
      switchItem,
      getTime
    }
  }
})
