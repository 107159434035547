
import { defineComponent, onActivated, onBeforeUnmount, onDeactivated, onMounted, nextTick, watch } from 'vue'
import resize from './mixins/resize'
import { init, EChartsOption } from 'echarts'
import * as echarts from 'echarts'
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '193px',
      required: true
    },
    chartData: {
      type: Object,
      default: {}
    },
  },
  setup(props,context) {
    watch(props, (newName, oldName) => {
      initChart()
    });

    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()
    const initChart = () => {
      const barChart = init(document.getElementById(props.id) as HTMLDivElement)
      let flowFundChart = props.chartData.flowFundChart
      // console.log('flowFundChart',flowFundChart)
      let seriesdata1 = [],
          seriesdata2 = [],
          timearr=[],
          timedata=[],
          // that = this,
          max=0,
          min=0
      if(flowFundChart&&flowFundChart.length>0){

        for (let i = 0; i < flowFundChart.length; i++) {
          const item = flowFundChart[i];
          
          timedata.push(item.date)
          seriesdata1.push(item.value)
          seriesdata2.push(item.field)
          if(i==0){
            max = item.field*1
            min = item.field*1
          }
          
          if(max<item.field*1){
            max = item.field*1
          }
          if(max<item.value*1){
            max = item.value*1
          }
          if(min>item.field*1){
            min = item.field*1
          }
          if(min>item.value*1){
            min = item.value*1
          }
        }
      }
      max = Number((Math.floor(max*1)+(Math.floor(max*1)%20000==0?0:(20000-(Math.floor(max*1)%20000)))).toFixed(0))
      min = Number(((Math.floor(min>0?min*0.8:min*1.2)-(Math.floor(min>0?min*0.8:min*1.2)%20000==0?0:(20000+(Math.floor(min>0?min*0.8:min*1.2)%20000))))).toFixed(2))
      timearr = [
        timedata[0],
        timedata[Math.floor(timedata.length/4*1)],
        timedata[Math.floor(timedata.length/4*2)],
        timedata[timedata.length-1]
      ]
      context.emit('getTimeList',timearr);

      barChart.setOption({
        // legend: {
        //   // data: ['Rainfall', 'Evaporation'],
        //   icon: 'circle',
        //   x: '0',
        //   left: '0',
        //   textStyle: {
        //     align: 'left', // 这个是可以左右调整的
        //     fontSize: 10// 字体大小
        //     // padding:[0,0,25,-45]//主要就是用这个，可以把文字上下调整
        //     // color: 'rgba(255,255,255,0.65)'//字体颜色
        //   }
        // },
        tooltip: {
          show: true,
          trigger: 'axis', 
          position:'inside',
          padding:4,
          textStyle:{
            color:'#999999',
            fontSize:'10',
          },
          // position:function(point,params,dom,rect){

          //   let x = 0
          //   let y= 0
          //   if(point[0]>140){
          //     x = point[0] - 100
          //   }else{
          //     x = point[0] +10
          //   }
          //   if(point[1]>55){
          //     y = point[1] - 60
          //   }else{
          //     y = point[1]
          //   }
          //   return [x,y]
          // },
          // formatter:function(param){
          //   // console.log(param)
          //   // 利用循环更改显示的内容
          //   let list = param.length
          //   let Oparam=param
          //   // console.log(list)
          //   let txt =" "+ param[0].name+"\n"
          //   for (var i=0;i<list;i++){
          //     if(i==list-1){
          //     txt +=param[i].marker+ param[i].seriesName + ":" + (param[i].data?param[i].data:0)+'万元'
          //     }else{
          //       txt +=param[i].marker+ param[i].seriesName + ":" +  (param[i].data?param[i].data:0)+'万元' + '\n'
          //     }
          //   }
          //   return txt
          // }
        },
        grid: {
          left: 0,
          right: 0,
          bottom: 10,
          top: 40,
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: timedata,
            axisLine: {
              lineStyle: {
                color: '#999',
                width:1,
              }
            },
            axisLabel: {
                show:false,
                color: '#666'
            },
            axisTick:{
                show:false
            },
    
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '万元',
            nameTextStyle:{
              color:"#999999",
              fontSize:12
            },
            show:true,
            axisTick: { show: false },
            axisLine: {
              show:true,
              lineStyle: {
                color: '#E8E8E8',
                width:1
              }
            },
            axisLabel: {
              color:  '#0E1018',
              fontSize:10,
            },
            max,
            min,
            interval:Math.floor((max-min)/30000)*10000,
            splitLine: {
                lineStyle: {
                    // type: 'dashed'
                    color:  '#E8E8E8',
                    width:1,
                }
            }
          }
        ],
        series: [
          {
            name: '基金',
            type: 'bar',
            // barWidth:'2',
            label: {
              normal: {
                show: false,
                // position: 'top'
              }
            },
            data: seriesdata1,
            itemStyle: {
                normal: {
                    barBorderRadius: [0,0,0,0],
                    // color:'#FB160C',
                    color:function(params:any) {
                      var index_color = params.value;
                          if(index_color>=0){
                              return '#FB160C';
                          }else {
                              return '#02C165';
                          }
                      }
                },
                label:{
                    show:false
                }
            }
          },
          {
            name: '行业平均线',
            type: 'line',
            smooth: true,  //这个可以显示为平滑过渡的线条
            showSymbol:true,
            symbolSize: 4,
            color:'#3B7FEE',
            itemStyle:{
              normal:{
                
                lineStyle:{
                  color:'#3B7FEE',
                  width:1,
                  barBorderRadius:0,
                },
              },
              emphasis:{
                lineStyle:{
                  width:1,
                },
              }
            },
            data:seriesdata2
          }
        ]
      } as unknown as EChartsOption)
      chart.value = barChart
    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {

    }
  }
})
