<template>
  <div>
    <div class="b-tab-nav" >
      <ul>
        <li :class="activeZbIdx === 0 ? 'active' : ''" @click="swichZbItem(0)">
          <div>北向资金</div>
        </li>
        <li :class="activeZbIdx === 1 ? 'active' : ''" @click="swichZbItem(1)">
          <div>沪股通</div>
        </li>
        <li :class="activeZbIdx === 2 ? 'active' : ''" @click="swichZbItem(2)">
          <div>深股通</div>
        </li>
      </ul>
    </div>
    <searchForm @search='onSearch' />
    <el-table
      class="table-style-2 cursor p0"
      :data="stockList.list || []"
      border
      align="center"
      :header-cell-style="handerMethod"
      :cell-class-name='addClass'
      v-loading='loading'
      @row-click='goDetail'
      :default-sort ="{prop:'addMarkeyCap',order:'descending'}"
      @sort-change="sortChange">
      <el-table-column prop="name" label="名称/代码"  align="center" width='74' />
      <el-table-column prop="increase" :label="`${selectedTime.text || ''}涨幅`" align="center" width='75' >
        <template #default='{row}'>
            <div v-dClass='row.increase' v-if="row.increase || row.increase == '0' "><template v-if="row.increase > 0">+</template>{{ row.increase.toFixed(2) }}%</div>
            <div v-else>-</div>
          </template>
      </el-table-column>
      <el-table-column prop="totalSharesRatio" label="持股比例" align="center" sortable="custom">
        <template #default='{row}'>
          <div>{{ row.totalSharesRatio ? row.totalSharesRatio.toFixed(2) + '%' : '-' }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="holdShares" label="北向持股数" align="center" sortable="custom">
        <template #default='{row}'>
          <div >{{ row.holdShares.toFixed(2) }}亿</div>
        </template>
      </el-table-column>
      <el-table-column prop="holdMarketCap" label="持股市值" align="center" sortable="custom">
        <template #default='{row}'>
            <div class="red">{{ row.holdMarketCap }}亿</div>
          </template>
      </el-table-column>
      <!-- <el-table-column prop="addHoldTimes" label="增持天数" align="center" sortable="custom" v-if="ajaxIdx === 2" /> -->
      <el-table-column prop="addSharesRepair" :label='`${selectedTime.label || ""}增持股数`' align="center" sortable="custom">
        <template #default='{row}'>
            <div v-dClass='row.addSharesRepair'>{{ row.addSharesRepair.toFixed(2) }}万</div>
          </template>
      </el-table-column>
      <el-table-column prop="addMarkeyCap" :label='`${selectedTime.label || ""}增持市值`' align="center" sortable="custom" >
        <template #default='{row}'>
            <div v-dClass='row.addMarkeyCap'>{{ row.addMarkeyCap.toFixed(2) }}亿</div>
          </template>
      </el-table-column>
      <el-table-column prop="addSharesAmp" :label='`${selectedTime.label || ""}增持市值增幅`' align="center" sortable="custom">
        <template #default='{row}'>
          <div v-dClass='row.addSharesAmp'>{{ row.addSharesAmp.toFixed(2) }}%</div>
        </template>
      </el-table-column>
      <el-table-column prop="continuousHoldTimes" label="增持统计" align="center" width='60'>
        <template #default='{row}'>
          <div class="red" v-if="row.continuousHoldTimes > 1">{{ row.continuousHoldTimes }}连增</div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column prop="addHoldTimes" label="增持统计" align="center" width='60'>
        <template #default='{row}'>
          <div class="red" v-if="row.addHoldTimes > 0">共{{ row.addHoldTimes }}次</div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column prop="continuousReduceHoldTimes" label="减持统计" width='60' align="center">
        <template #default='{row}'>
          <div class="green" v-if="row.continuousReduceHoldTimes > 1">{{ row.continuousReduceHoldTimes }}连减</div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column prop="reduceHoldTimes" label="减持统计" width='60' align="center" >
        <template #default='{row}'>
          <div class="green" v-if="row.reduceHoldTimes > 0">共{{ row.reduceHoldTimes }}次</div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column prop="board" label="所属板块" align="center">
        <template #default='{row}'>
          <div @click.stop="goBoard(row)">{{ row.board }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="totalMarketCap" label="总市值" align="center">
        <template #default='{row}'>
          <div >{{ row.totalMarketCap ? row.totalMarketCap.toFixed(2)+'亿' : '-' }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="stockPeTtm" label="个股/行业 PE-TTM" width='75' align="center">
        <template #default='{row}'>
          <div>{{ row.stockPeTtm.toFixed(2) }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="boardPeTtm" label="个股/行业 PE-TTM" width='75' align="center">
        <template #default='{row}'>
          <div>{{ row.boardPeTtm ? row.boardPeTtm.toFixed(2) : '-' }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="stockQuantile" label="估值水位" align="center" width='70'>
        <template #default='{row}'>
          <div>{{ row.stockQuantile ? row.stockQuantile.toFixed(2) : '-' }}</div>
        </template>
      </el-table-column>



    </el-table>
    <CPagination ref='pagin' :showPageSizes='true' :pageSize='10' :pageData='stockList' @fetchData='fetchData'/>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  toRefs,
  reactive
} from 'vue'

import CPagination from "@/components/pagination/Index.vue"
import CustomTab from '../../components/CustomTab.vue'
import searchForm from './searchForm.vue'
import { getStockIncreaseList, getBoardStock } from '@/apis/northboundFunds'

import { useRoute } from "vue-router";
export default defineComponent({
  components: {
    CPagination,
    CustomTab,
    searchForm
  },
  props:{
    ajaxIdx:{
      type: Number,
      default: 1
    }
  },
  setup(props, context) {

    const route = useRoute();
    const dataMap = reactive({
      activeZbIdx: 0,
      selectedTime:{
        label: '5日',
        text: '5日'
      },
      loading: true,
      stockList: [

      ],
    })

    let params = {
      // timeLeve: 时间 1d,5d,10d,1m,1q,1y
      timeLevel: '5d',
      type:'',//股票类型 "" :北向资金 sh:沪股通  sz:深股通
      sortFiled: 'addMarkeyCap',
      sort: 'DESC',
      pageNum: '1',
      pageSize: '10'
    }

    if( route.query.boardType ){
      params['boardType'] = route.query.boardType
    }

    const fetchData = async (value: any) => {
      console.log(value)
      if( value.pageNum ){
        params['pageNum'] = value.pageNum
      }
      if( value.pageSize ){
        params['pageSize'] = value.pageSize
      }

      dataMap.loading = true
      if( props.ajaxIdx === 1 ){
        const list = await getStockIncreaseList(params)
        if( list ){
          dataMap.stockList = (list as any).data;
        }
      }else if( props.ajaxIdx === 2 ){
        params['boardName'] = route.query.code
        const list = await getBoardStock(params)
        dataMap.stockList = (list as any).data;
      }

      dataMap.loading = false
    }

    const sortChange = (column: any) => {
      let isAsc = column.order === "ascending" ? 'asc' : 'desc';
      params['sortFiled'] = column["prop"]
      params['sort'] = isAsc
      fetchData({})
    };

    const timeChange = (timeLeve: any) => {
      dataMap.selectedTime = timeLeve
      params['timeLevel'] = timeLeve.lbValue
      fetchData({})
    };

    const typeChange = (val: any) => {
      params['type'] = val
      fetchData({})
    }

    const goDetail = (item: any) => {
      const path = '/northboundFunds/shares'
      window.open( `/investment${path}?code=${item.code}`)
    };

    const goBoard = (item: any) => {
      // console.log(item)
      // return false
      const path = '/northboundFunds/industry'
      window.open( `/investment${path}?code=${item.board}&boardType=${item.boardType}`)
    };

    const addClass = (column:any) => {
      if( column.column.property === params.sortFiled ){
        return 'sort-bg'
      }
    }

    const onSearch = (value:any) => {
      params = {
        ...params,
        ...value
      }
      fetchData({})
    }


    const swichZbItem = (idx: any) => {
      const arr = ['','sh','sz']
      dataMap.activeZbIdx = idx;
      typeChange(arr[idx])
    };

    //合并单位表头
    const handerMethod = (obj:any) => {
      const { row, columnIndex } = obj
      if (row[0].level == 1) {
        //这里有个非常坑的bug 必须是row[0]=0 row[1]=2才会生效
        row[1].colSpan = 0
        row[8].colSpan = 2
        if (columnIndex === 9) {
          return { display: 'none' }
        }

        row[9].colSpan = 0
        row[10].colSpan = 2
        if (columnIndex === 11) {
          return { display: 'none' }
        }

        row[13].colSpan = 0
        row[14].colSpan = 2
        if (columnIndex === 15) {
          return { display: 'none' }
        }
      }
    }



    return {
      ...toRefs(dataMap),
      fetchData,
      sortChange,
      typeChange,
      timeChange,
      goDetail,
      goBoard,
      addClass,
      handerMethod,
      swichZbItem,
      onSearch
    }
  }
})
</script>
<style scoped lang="scss">
.b-tab-nav {
    background: #F1F2F7;
    border-radius: 8px;
    padding: 4px;
    display: inline-block;
    margin: 24px 0;
    ul {
      display: flex;
      li {
        width: 135px;
        text-align: center;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #999ba7;
        padding: 14px 0;
        cursor: pointer;
        &.active {
          color: #333333;
          background: #FFFFFF;
          box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
          border-radius: 8px;
        }
        div:last-child {
          font-size: 18px;
          padding-top: 4px;
        }
      }
    }
  }

</style>
