<template>
  <div class="wrap">
    <pageTitle title='北向资金' sub-title="> 行业明细"></pageTitle>
    <div class="industry_box bg">
      <div class="title_box">
        <div class="left_box">
          <span>{{ codeTitle }}行业北向资金持股明细</span>
          <div class="background_box"></div>
        </div>
        <div class="right_box">(截至 {{ stockDate }}）</div>
      </div>
      <div class="describe">
        <div class="tips">
          <span>北向资金<span v-if="boardData.intro.addDays > 1"><span :style="boardData.intro.isAdd ? 'color: #F74E4F' : 'color: #5CBC7C'">连续{{ boardData.intro.addDays }}天{{ boardData.intro.isAdd ? '增持' : '减持'}}</span>，</span>当前持有该行业<span style="font-size: 16px;font-weight: 600; color: #333333">{{ boardData.intro.holdStockNum}}只</span>个股，市值<span style="font-size: 16px;font-weight: 600; color: #333333">{{ mathAbse(boardData.intro.holdMarketCap) }}{{ mathAbsText(boardData.intro.holdMarketCap) }}</span>，在北向持仓中市值排名<span style="font-size: 16px;font-weight: 600; color: #333333">{{ boardData.intro.rankingNo }}/{{ boardData.intro.holdBoardNum }}</span>，占北向整体<span :style="boardData.intro.holdRatio === 0 ? 'color: black' : boardData.intro.isAdd ? 'color: #F74E4F' : 'color: #5CBC7C'">{{ boardData.intro.holdRatio }}%</span></span>
        </div>
        <div class="list">
          <ul>
            <li>
              <div class="t">当日净买入</div>
              <div class="b" :style="styleMathe(boardData.todayBuy)">{{ mathAbs(boardData.todayBuy) }}<span>{{ Math.abs(boardData.todayBuy) > 10000 ? '亿' : '万'}}</span></div>
            </li>
            <li>
              <div class="t">近5日净买入</div>
              <div class="b" :style="styleMathe(boardData.day5Buy)">{{ mathAbs(boardData.day5Buy) }}<span>{{ Math.abs(boardData.day5Buy) > 10000 ? '亿' : '万'}}</span></div>
            </li>
            <li>
              <div class="t">近20日净买入</div>
              <div class="b" :style="styleMathe(boardData.day20Buy)">{{ mathAbs(boardData.day20Buy) }}<span>{{ Math.abs(boardData.day20Buy) > 10000 ? '亿' : '万'}}</span></div>
            </li>
          </ul>
          <ul>
            <li>
              <div class="t">持股市值</div>
              <div class="b" :style="styleMathe(boardData.todayHoldMarket)">{{ mathAbse(boardData.todayHoldMarket) }}<span>{{ Math.abs(boardData.todayHoldMarket) > 10000 ? '亿' : '万'}}</span></div>
            </li>
            <li>
              <div class="t">近5日增持比例</div>
              <div class="b" :style="styleMathe(boardData.day5AddRatio)">{{ mathAbss(boardData.day5AddRatio) }}<span>‰</span></div>
            </li>
            <li>
              <div class="t">近20日增持比例</div>
              <div class="b" :style="styleMathe(boardData.day20AddRatio)">{{ mathAbss(boardData.day20AddRatio) }}<span>‰</span></div>
            </li>
          </ul>
        </div>
      </div>
      <trend-charts id="trend" width="609px" :chart-data="trendData" :title="codeTitle" />
      <plate-charts id="plate" width="547px" :chart-data="statsData" :title="codeTitle" />
      <div style="margin-top:50px">
        <div style="text-align:right;margin-bottom:20px">
          <bar-tab @change='getGgData' :activeIdx='2' :init='false' />
        </div>
        <individualStocksRaking ref='individualRaking' :ajaxIdx="2" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useRoute } from "vue-router";
import trendCharts from './component/trend-charts.vue'
import plateCharts from './component/plate-charts.vue'
import pageTitle from '../components/PageTitle.vue'
import individualStocksRaking from '../detailed/component/individual-stocks-raking.vue'
import barTab from '../components/CustomTab.vue'
import { defineComponent, toRefs, reactive, ref } from 'vue'
import { getTrend, getStats, getFundsBoard } from '@/apis/northboundFunds'
import moment from 'moment/moment'
export default defineComponent({
  components: {
    barTab,
    trendCharts,
    pageTitle,
    plateCharts,
    individualStocksRaking
  },
  setup() {
    const individualRaking = ref(null)
    const route = useRoute();
    const dataMap = reactive({
      codeTitle: route.query.code,
      trendData: {},
      statsData: {},
      boardData: {
        todayBuy: 0.00,
        day5Buy: 0.00,
        day20Buy: 0.00,
        todayHoldMarket: 0.00,
        day5AddRatio: 0.00,
        day20AddRatio: 0.00,
        intro: {
          addDays: 0.00,
          holdStockNum: 0.00,
          holdMarketCap: 0.00,
          rankingNo: 0.00,
          holdBoardNum: 0.00,
          holdRatio: 0.00
        }
      },
      stockDate: ''
    })
    const fetchData = async () => {
      // 持有板块趋势
      const trendList = await getTrend({ boardName: route.query.code, boardType: route.query.boardType })
      dataMap.trendData = (trendList as any).data;
      // 持有板块趋势
      const statsList = await getStats({ boardName: route.query.code, boardType: route.query.boardType })
      dataMap.statsData = (statsList as any).data;
      // 板块排行
      const boardList = await getFundsBoard({ boardName: route.query.code, boardType: route.query.boardType })
      dataMap.boardData = (boardList as any).data;
      dataMap.stockDate = moment((boardList as any).data.statsTime).format('YYYY年MM月DD日');
    }
    fetchData();
    const styleMathe = (item: any) => {
      if( item ){
        const itemNumber = item
        return itemNumber < 0 ?  'color: #5CBC7C' : itemNumber > 0 ?  'color: #F74E4F' : 'color: #606266'
      }
    }
    const mathAbs = (item: any) => {
      const number = Number((item));
      const numberText = Number(item);
      return number > 10000  ? numberText > 0 ? '+' + (numberText / 10000).toFixed(2) : numberText.toFixed(2) : (numberText / 10000).toFixed(2)
    }
    const mathAbse = (item: any) => {
      const number = Number(Math.abs(item));
      const numberText = Number(item);
      return number > 10000  ? (numberText / 10000).toFixed(2) : numberText.toFixed(2)
    }
    const mathAbss = (item: any) => {
      const numberText = Number(item);
      return numberText > 0  ? '+' + numberText.toFixed(2) : numberText.toFixed(2)
    }
    const mathAbsText = (item: any) => {
      const number = Number(Math.abs(item));
      return number > 10000  ? '亿' : '万'
    }

    const getGgData = (val: any) => {
      (individualRaking as any).value.timeChange(val)
    }
    return {
      ...toRefs(dataMap),
      styleMathe,
      mathAbs,
      mathAbsText,
      mathAbse,
      mathAbss,
      getGgData,
      individualRaking
    }
  }
})
</script>

<style scoped lang="scss">
.wrap {
  margin: 0 35px;
  .bg{
    background: #fff;
    margin-bottom: 30px;
    padding: 0 20px 50px;
    .pl10{
      padding-left: 10px;
    }
  }
  .title_box {
    padding-top: 29px;
    display: flex;
    > .left_box {
      position: relative;
      margin-right: 10px;
      > span {
        display: block;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 28px;
      }
      > .background_box {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 80px;
        height: 9px;
        background: linear-gradient(270deg, rgba(56,111,242,0) 0%, #386FF2 100%);
      }
    }
    > .right_box {
      font-size: 17px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 24px;
    }
  }

  .describe{
    margin-bottom: 57px;
    .tips{
      margin: 20px 0;
      font-size: 16px;
      font-family: none;
    }
    .list{
      display: inline-block;
      padding: 0 15px;
      background: #F7F8FA;
      ul{
        // display: flex;
        clear: both;
        overflow: hidden;
        &:first-child{
          border-bottom: 1px solid #EBF0F9;
        }
        li{
          width: 147px;
          text-align: center;
          padding: 5px 0;
          margin: 7px 0;
          border-right: 1px solid #EBF0F9;
          display: inline-block;
          &:last-child{
            border: 0;
          }
          .t{
            font-size: 14px;
            font-weight: 500;
            color: #333333;
          }
          .b{
            font-size: 20px;
            font-weight: 600;
            > span {
              font-size: 12px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              line-height: 17px;
            }
          }
        }
      }
    }
  }
}
</style>
