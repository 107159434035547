
import { defineComponent, ref } from 'vue'
  
export default defineComponent({
    props: {
        right: {
            type: Boolean,
            default: true
        },
        type: {
            type: String,
            default: 'month'
        },
        defultValue: {
            type: String,
            default: '3Y'
        }
    },
    setup(props,context) {
        let options:any = []

        if(props.type === 'year' ){
            options = [
                // {
                //     value: 0,
                //     label: '1Y',
                //     timeLevel: '1y'
                // },
                {
                    value: 0,
                    label: '3Y',
                    timeLevel: '3y'
                },
                {
                    value: 1,
                    label: '5Y',
                    timeLevel: '5y'
                },
                {
                    value: 2,
                    label: '10Y',
                    timeLevel: '10y'
                }
            ]
        }else{
            options = [
                {
                    value: 0,
                    label: '最近1月',
                    timeLevel: '1m'
                },
                {
                    value: 1,
                    label: '最近3月',
                    timeLevel: '3m'
                },
                {
                    value: 2,
                    label: '最近6月',
                    timeLevel: '6m'
                },
                {
                    value: 3,
                    label: '最近1年',
                    timeLevel: '1y'
                }
            ]
        }

        const newDefult = options.find((items:any) => items.label === props.defultValue);
        const newValue = newDefult ? newDefult.value : 0;
        const value = ref(newValue)

        const changeHandle = (e:any) => {
            context.emit('change', options[e.target.value])
        }
        context.emit('change', options[newValue])

        return {
            value,
            options,
            changeHandle
        }
    }
})
  
