
import { defineComponent, onActivated, onBeforeUnmount, onDeactivated, onMounted, nextTick, watch } from 'vue'
import resize from '@/components/charts/mixins/resize'
import { init, EChartsOption } from 'echarts'
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '200px',
      required: true
    },
    chartData: {
      type: Object,
      default: {}
    },
    selectedTime: {
      type: String || Number,
      default: ''
    },
  },
  setup(props) {
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()

    watch(props, (newName, oldName) => {
      initChart()
    });

    const initChart = () => {
      const minuteRecords = props.chartData.minuteRecords
      if( !minuteRecords ){
        return false;
      }
      const barChart = init(document.getElementById(props.id) as HTMLDivElement)
      let xData = [];
      let serviesData1 = [];
      // {
      //     value: 2,
      //     itemStyle: {
      //       color: '#a90000'
      //     }
      //   }
      // let serviesData2 = [1,2,3,4,5];
      for (let index = 0; index < minuteRecords.length; index++) {
        const item = minuteRecords[index]
        xData.push(item.timeLabel)
        serviesData1.push(
          {
            value: item.indexVol ? item.indexVol.toFixed(2) : 0,
            itemStyle: {
              color: item.indexMinuteChg > 0 ? '#F74E4F' : '#5CBC7C'
            }
          }
        )
        
      }
      barChart.setOption({
        tooltip: {
          trigger: 'axis',
          // valueFormatter: (value:any) => value + '万'
          formatter:function(param:any){
            // console.log('param[0]',param[0])
            const item = minuteRecords[param[0].dataIndex]
            let text = ''
            text += `<div style='margin-bottom:5px'>${param[0].name}</div>`
            text += `<div> 
                        <span style='width:10px;height:10px;display:inline-block;border-radius:50%;margin-right:3px;background:#ccc'></span>
                        <span style='width:90px;display:inline-block;color:#666'>上证涨幅</span>
                        <span style='width:50px;display:inline-block;text-align:right;color:${item.indexIncr > 0 ? '#F74E4F' : '#5CBC7C'}'>${item.indexIncr > 0 ? '+' : ''}${item.indexIncr ? item.indexIncr : 0}%</span> 
                    </div>`
            text += `<div> 
                        <span style='width:10px;height:10px;display:inline-block;border-radius:50%;margin-right:3px;background:#999'></span>
                        <span style='width:90px;display:inline-block;color:#666'>沪市成交量</span>
                        <span style='width:50px;display:inline-block;text-align:right;color:#333'>${param[0].value}万</span> 
                    </div>`
            return text
          }
        },
        grid: {
          top: '0',
          left: '0',
          right: '0%',
          bottom: '0%',
          containLabel: true
        },
        toolbox: {
          show: true
          // feature: {
          //   dataView: { show: true, readOnly: false },
          //   magicType: { show: true, type: ['line', 'bar'] },
          //   restore: { show: true },
          //   saveAsImage: { show: true }
          // }
        },
        calculable: true,
        xAxis: [
          {
            type: 'category',
            // prettier-ignore
            data: xData,
            // splitLine: { show: false },
            axisLabel:{show:false},
            axisTick: { show: false },
            axisLine: { show: false },
          }
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: { show: false },
            // axisTick: { show: false },
            axisLabel: { show: false }
          }
        ],
        series: [
          {
            name: '成交量',
            barGap: '80%',
            // barWidth: 15, // 柱子宽度
            type: 'bar',
            data: serviesData1,
            
          }
        ]
      } as EChartsOption)
      chart.value = barChart

      // barChart.on('click', (params:any) => {
      //   console.log(params)
      //   window.open(`/investment/research/list?industryName=${params['name']}&isPointOrg=${params['seriesIndex']}&time=${props.selectedTime}`)
      // })
    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {

    }
  }
})
