
import { defineComponent, onActivated, onBeforeUnmount, onDeactivated, onMounted, nextTick, watch, reactive, toRefs } from 'vue'
import resize from '@/components/charts/mixins/resize'
import { init, EChartsOption } from 'echarts'
import * as echarts from 'echarts'
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '300px',
      required: true
    },
    chartData: {
      type: Object,
      default: {}
    },
    chartTitle: {
      type: String,
      default: ''
    },
    chartKey: {
      type: String,
      default: ''
    },
    chartValue: {
      type: String,
      default: ''
    },
  },
  setup(props) {
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()

    const dataMap = reactive({
      seriesLength: 0
    })

    watch(props, (newName, oldName) => {
      initChart()
    });

    const initChart = () => {
      const chartData = JSON.parse(JSON.stringify(props.chartData))
      if( !chartData[props.chartKey] ){
        return false;
      }
      const barChart = init(document.getElementById(props.id) as HTMLDivElement)
      const xData = chartData[props.chartKey].map((item:any)=>item.stockName)
      const series = chartData[props.chartKey].map((item:any)=>{
        return {
          value: (item[props.chartValue] / 100000000).toFixed(2)
        }
      })
      dataMap.seriesLength = series.length
      const options = {
        // title: { // 无数据时占位用
        //   show: series.length === 0, // 判断有没有数据，没有则show为true
        //   textStyle: {
        //     color: '#ccc',
        //     fontSize: 18,
        //     fontWeight: 500
        //   },
        //   text: "暂无数据",
        //   left: "center",
        //   top: "center"
        // },
        grid: {
          top: '30px',
          left: '0',
          right: '40px',
          bottom: '20px',
          containLabel: true
        },
        legend:{
          selectedMode: false,
          icon: 'rect',
          itemWidth: 8,
          itemHeight: 8,
          // color: ['#F93F41', 'green'],
          textStyle: {
            height: 10,
            rich: {
              a: {
                verticalAlign: 'bottom',
              },
            }
          }
          // padding: [1,2,3,4]
        },
        toolbox: {
          show: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: xData,
            axisLine: {
                show: props.chartKey === 'incrTop' && series.length ? true : false,
                lineStyle: {
                    color: "#333333",
                }
            },
            axisLabel: {
              show: true,
              formatter: function(param:any){
                return param.split('').join('\n')
              }
              // interval: 0,//使x轴文字显示全
            },
            axisTick: {
              show: false
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            scale: true,
            splitLine: {
              show: true,
              lineStyle: {
                color: "#CECECE",
                width: 1,
                type: 'dashed',
              },
            },
            // axisTick: { show: false },
            axisLabel: { show: true },
            axisLine:{
              show: series.length === 0 ? false : true
            }
          }
        ],
        series: [
          {
            name: props.chartTitle,
            // stack: 'total',
            type: 'line',
            data: series,
            itemStyle: {
              normal: {
                label: {
                  // formatter: '{c}',
                  show: true,
                  position: props.chartKey === 'incrTop' ? 'insideBottomLeft' : 'insideTopLeft',
                  textStyle: {
                    fontWeight: 'bolder',
                    fontSize: '12',
                    color: '#000'
                  }
                },
                // color: 'green',
                opacity: 1
              }
            },
            color: props.chartKey === 'incrTop' ? '#FF8F40' : '#0079E3',
            areaStyle: {
              color: props.chartKey === 'incrTop' ? new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#FF8F40'
                },
                {
                  offset: 1,
                  color: 'rgba(255,143,64,0.04)'
                }
              ])
              :
              new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(0,121,227,0.04)'
                },
                {
                  offset: 1,
                  color: '#0079E3'
                }
              ])
            },
            // markLine: {
            //   symbol: ['none', 'none'], // 去掉箭头
            //   data:[
            //     {
            //       yAxis: '-3'
            //     }
            //   ],
            //   label: {
            //     show: false,
            //     position: 'start',
            //     formatter: '{b}'
            //   },
            //   lineStyle: {
            //     width: .5,
            //     color: '#333333',
            //     type: 'solid'
            //   }
            // }
          }
        ]
      }
      barChart.setOption(options as EChartsOption)
      chart.value = barChart

      if( props.chartKey === 'decrTop' && series.length ){
        console.log()
        let newOptions = JSON.parse(JSON.stringify(options))
        newOptions.series[0].markLine= {
              symbol: ['none', 'none'],
              silent: true,
              data:[
                {
                  yAxis: (barChart as any).getModel().getComponent('yAxis').axis.scale._extent[1]
                }
              ],
              label: {
                show: false,
                position: 'start',
                formatter: '{b}'
              },
              lineStyle: {
                width: .5,
                color: '#333333',
                type: 'solid'
              }
        }
        barChart.setOption({
          series: newOptions.series
        } as EChartsOption, false)
      }

    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {
      ...toRefs(dataMap),
    }
  }
})
