<template>
  <div class="tab">
      <ul>
        <li :class="activeIdx === idx ? 'active' : ''" v-for="(item, idx) in timeArr" :key="item" @click="handleTimeChange(idx)">{{item.name}}</li>
      </ul>
    </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue'

export default defineComponent({
  props: {
    init: {
      type: Boolean,
      default: false
    },
    initActive: {
      type: Number,
      default: 0
    }
  },
  emits: ['change'],
  setup(props, contex) {
    const dataMap = reactive({
      activeIdx: 0,
      timeArr: [{
        value: '5y',
        name: '近5年'
      },{
        value: '10y',
        name: '近10年'
      },{
        value: '100y',
        name: '历次以来'
      }]
    })

    //时间切换
    const handleTimeChange = (idx:number) => {
      dataMap.activeIdx = idx
      contex.emit('change', dataMap.timeArr[dataMap.activeIdx].value)
    }

    if( props.init ){
      dataMap.activeIdx = props.initActive
      contex.emit('change', dataMap.timeArr[props.initActive].value)
    }

    return {
      ...toRefs(dataMap),
      handleTimeChange
    }
  }
})
</script>
<style lang='scss' scoped>
.tab{
    //z-index: 99;
    //position: fixed;
    //top: 10px;
    //right: 10px;
    margin-top: 20px;
    ul{
      display: flex;
      border-radius: 4px;
      overflow: hidden;
      justify-content: flex-end;
      li{
        //width: 52px;
        padding: 0 10px;
        height: 32px;
        line-height: 32px;
        cursor: pointer;
        background: rgb(255, 255, 255);
        text-align: center;
        color: rgb(102, 102, 102);
        border-top: 1px solid rgb(221, 221, 221);
        border-bottom: 1px solid rgb(221, 221, 221);
        border-left: 1px solid rgb(221, 221, 221);
        border-image: initial;
        border-right: none;
        &:last-child{
          border-right: 1px solid rgb(221, 221, 221);
          border-radius: 0px 4px 4px 0px;
        }
        &.active{
          color: rgb(255, 255, 255);
          background: #1B56AC;
          border-right-color: transparent;
          border-left-color: transparent;
          border-top: none;
          border-bottom: none;
        }
      }
    }
  }
</style>
