<template>
  <div
    :id="id"
    :class="className"
    :style="{height: height, width: width}"
  />
</template>

<script lang="ts">
import { defineComponent, onActivated, onBeforeUnmount, onDeactivated, onMounted, nextTick, watch } from 'vue'
import resize from '@/components/charts/mixins/resize'
import { init, EChartsOption } from 'echarts'
import * as echarts from 'echarts'
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '300px',
      required: true
    },
    chartData: {
      type: Object,
      default: {}
    },
    chartTitle: {
      type: String,
      default: ''
    },
    chartKey: {
      type: String,
      default: ''
    },
  },
  setup(props) {
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()

    watch(props, (newName, oldName) => {
      initChart()
    });

    const initChart = () => {
      const chartData = JSON.parse(JSON.stringify(props.chartData))
      if( !chartData.stocks ){
        return false;
      }
      const barChart = init(document.getElementById(props.id) as HTMLDivElement)
      // const xData = chartData.stocks.map((item:any)=>item.stockName)
      const series = chartData.stocks.map((item:any,idx:number)=>{
        return {
          value: item.turnover,
          name: item.stockName + '\n' + item.turnover.toFixed(2) + '%',
        }
      })

      barChart.setOption({
          title: {
            text: '{A|换手率\n(%)}',
            left: 'center',
            top: 'center',
            borderWidth: 110,//标题边框线宽，默认为0，可自行设置
            borderColor:'rgba(255,255,255,0.5)',
            padding: 0,
            borderRadius: 1000,//标题边框线宽，默认为0，可自行设置
            textStyle: {
               // width: 190,
               // height: '90%',
               rich: {
                 A: {
                   //设置背景图片，可以设置width和height，不设置时宽高自适应
                   width: 90,
                   height: 20,
                   color: '#333333',
                   // backgroundColor: 'red',
                 },
               },
             },
          },
          // tooltip: {
          //   trigger: 'item'
          // },
          // legend: {
          //   top: '5%',
          //   left: 'center'
          // },
          series: [
            {
              // name: 'Access From',
              type: 'pie',
              radius: ['43%', '70%'],
              scale: true,
              silent: true,
              avoidLabelOverlap: false,
              label: {
                // show: true,
                // position: 'outer',
                alignTo: 'labelLine',
                // bleedMargin: 5
              },
              left: '10%',
              right: '10%',
              // top: 0,
              // bottom: 0,
              // labelLine: {
                // show: false
              // },
              color: ['#FFC8A0','#FFA566','#FF7E1C','#FF6500','#FF3100','#007AED','#00A7F6','#58C3F8','#B0DDF5','#DEEAF5'],
              data: series
              //   [
              //   { value: 748, name: '1' ,itemStyle: { color: '#FFC8A0' }},
              //   { value: 735, name: '2',itemStyle: { color: '#FFA566' } },
              //   { value: 580, name: '3',itemStyle: { color:  } },
              //   { value: 484, name: '4',itemStyle: { color:  } },
              //   { value: 580, name: '5',itemStyle: { color:  } },
              //   { value: 484, name: '6',itemStyle: { color:  } },
              //   { value: 484, name: '7',itemStyle: { color:  } },
              //   { value: 580, name: '8',itemStyle: { color:  } },
              //   { value: 580, name: '8',itemStyle: { color:  } },
              //   { value: 580, name: '8',itemStyle: { color:  } },
              // ]
            }
          ]
      } as EChartsOption)
      chart.value = barChart


    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {

    }
  }
})
</script>
