<template>
  <div >
    <NavTitle :title='`${stockName}融资融券历史数据(${timeSelected.label})`' style="padding-left:0;margin-bottom:48px" />
    <el-table
          class="table-style-2 cursor"
          :data="stockList.list || []"
          border
          :cell-class-name='addClass'
          align="center"
          :default-sort ="{prop:'rzjmr', order:'descending'}"
          @sort-change="sortChange">
          <el-table-column prop="date" label="日期"  align="center" />
          <el-table-column prop="incr" :label="`${timeSelected.text}涨幅`"  align="center">
            <template #default='{row}'>
              <div v-dClass='row.incr'>{{Number(row.incr) > 0 ? '+' : ''}}{{ row.incr ? Number(row.incr).toFixed(2) + '%' : '-' }}</div>
            </template>
          </el-table-column>
          <el-table-column label="融资"  align="center" >
            <el-table-column prop="rzye" label="余额"  align="center" sortable="custom">
              <template #default='{row}'>
                <div>{{ bigNumberTransform(row.rzye) }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="rzyezb" label="余额占流通市值比(%)"  align="center" sortable="custom" />
            <el-table-column prop="rzmr" label="买入额(元)"  align="center" sortable="custom">
              <template #default='{row}'>
                <div>{{ bigNumberTransform(row.rzmr)  }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="rzjmr" label="净买入(元)"  align="center" sortable="custom">
              <template #default='{row}'>
                <div>{{ bigNumberTransform(row.rzjmr) }}</div>
              </template>
            </el-table-column>
            <el-table-column label="融资净买入统计"  align="center" sortable="custom">
              <template #default='{row}'>
                <div class="c-col">
                  <div v-if="row.continueAdd > 1" v-dClass="row.continueAdd" class="red">{{ row.continueAdd }}连增</div>
                  <div v-else-if='row.continueAdd < -1' class="green">{{ Math.abs(row.continueAdd) }}连减</div>
                  <div v-else>-</div>
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="融券"  align="center" >
            <el-table-column prop="rqye" label="余额(元)"  align="center" sortable="custom">
              <template #default='{row}'>
                <div>{{ bigNumberTransform(row.rqye) }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="rqmcl" label="卖出额"  align="center" sortable="custom">
              <template #default='{row}'>
                <div>{{ bigNumberTransform(row.rqmcl) }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="rqjmc" label="净卖额"  align="center" sortable="custom">
              <template #default='{row}'>
                <div>{{ bigNumberTransform(row.rqjmc) }}</div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="rzrqye" label="融资融券余额(元)"  align="center" sortable="custom">
            <template #default='{row}'>
              <div>{{ bigNumberTransform(row.rzrqye) }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="vol" label="成交量(股)"  align="center">
            <template #default='{row}'>
              <div>{{ amount(row.vol) }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="amt" label="成交额(元)"  align="center" >
            <template #default='{row}'>{{ amount(row.amt) }}</template>
          </el-table-column>
          <el-table-column prop="boardName" label="所属行业"  align="center">
            <template #default='{row}'>
              <div @click="goDetail(row)">{{ row.boardName }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="stockPeTTM" label="个股/行业PE-TTM"  align="center">
            <template #default='{row}'>
              <div>{{ row.stockPeTTM ? row.stockPeTTM.toFixed(2) : '-' }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="boardPeTTM" label="个股/行业PE-TTM"  align="center" />
          <el-table-column prop="valuation" label="估值水位"  align="center" />
        </el-table>
      <CPagination ref='pagin' :init='false' :showPageSizes='true' :pageSize='10' :pageData='stockList' @fetchData='fetchData'/>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue'
import CPagination from "@/components/pagination/Index.vue"
import NavTitle from '../../components/nav-title.vue'
import { getMarginStockLog } from '@/apis/trading'
import { useRoute  } from "vue-router";
import { bigNumberTransform } from '@/utils/data-format'
export default defineComponent({
  components:{
    CPagination,
    NavTitle
  },
  setup() {
    const route = useRoute();
    const dataMap = reactive({
      stockName: route.query.name,
      timeSelected: {
        text: ''
      },
      stockList : {
        list: []
      }
    })

    const params = {
      timeUnit: '',
      pageSize: 10,
      pageNum: 1,
      stockCode: route.query.code,
      orderBy:'rzjmr',
      order:'DESC'
    }

    const fetchData = async (value:any) => {
      if(value.pageNum){
        params['pageNum'] = value.pageNum
      }
      if(value.pageSize){
        params['pageSize'] = value.pageSize
      }
      const boardDetail = await getMarginStockLog(params)
      if( boardDetail ){
        dataMap.stockList = (boardDetail as any).data;
      }
    }

    const changeTime = async (value:any) => {
      console.log('修改时间了 ', value)
      if( value.value ){
        dataMap.timeSelected = value
        params['timeUnit'] = value.value
        if( value.count !== 'DAYS' ){
          delete params['num']
        }
      }
      if( value.count ){
        params['num'] = value.count
      }
      fetchData({})
    }

    const addClass = (column:any) => {
      if( column.column.property === params.orderBy ){
        return 'sort-bg'
      }
    }

    const sortChange = (column: any) => {
      let isAsc = column.order === "ascending" ? 'ASC' : 'DESC';
      params['orderBy'] = column["prop"]
      params['order'] = isAsc
      fetchData({})
    }

    const amount = (value: any, fixed = 2, unit = '元') => {
      if (unit == '万') {
        value = value * 10000
      } else if (unit == '亿') {
        value = value * 100000000
      }
      if (Math.abs(value / 100000000) >= 1) {
        return (value / 100000000).toFixed(fixed) + '亿'
      }
      if (Math.abs(value / 10_000) >= 1) {
        return (value / 10000).toFixed(fixed) + '万'
      }
      return value
    }

    const goDetail = async (item:any) => {
      window.open(`/investment/marginTrading/industry?code=${item.boardName}`)
    }

    return {
      ...toRefs(dataMap),
      fetchData,
      changeTime,
      addClass,
      sortChange,
      bigNumberTransform,
      amount,
      goDetail
    }
  }
})
</script>
<style scoped lang="scss">
.c-col{
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  &>div{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    &:nth-child(1){
      border-right: 1px solid rgb(235, 238, 245);
    }
  }
}
</style>

