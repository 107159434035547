<template>
  <div class="app-container">
    <div class="bg">
      <div class="tit">
        <div class="t"><span>市场总览</span></div>
        <div class="update-time">更新于{{stockList6.updateTime}}</div>
      </div>
<!--      顶部涨平跌-->
      <market-top v-loading='zcLoading' :zs-list="currentBoardData.zsList" />
      <chartA id='sczl1' :chartData="stockList6" />
      <div class="antd-pro-pages-market-styles-riseFallbar">
        <img class="antd-pro-pages-market-styles-riseFallIcon" src="@/assets/images/arrow-top-red.png" v-if="stockList6.rise">
        <div class="antd-pro-pages-market-styles-riseNumber">{{stockList6.rise}}</div>
        <div class="antd-pro-pages-market-styles-fallRiseBar">
          <div class="antd-pro-pages-market-styles-riseBar" :style="{width: stockList6.rise / (stockList6.fall + stockList6.flat + stockList6.rise) * 100 + '%'}"></div>
          <div class="antd-pro-pages-market-styles-marketGrayBar" :style="{width: stockList6.flat / (stockList6.fall + stockList6.flat + stockList6.rise) * 100 + '%'}"></div>

          <div class="antd-pro-pages-market-styles-fallBar" :style="{width: stockList6.fall / (stockList6.fall + stockList6.flat + stockList6.rise) * 100 + '%'}"></div>
        </div>
        <div class="antd-pro-pages-market-styles-fallNumber">{{stockList6.fall}}</div>
        <img class="antd-pro-pages-market-styles-riseFallIcon" src="@/assets/images/arrow-bottom-green.png" v-if="stockList6.fall">

      </div>

      <div class="bkyd-chart sczl-chart">
        <div class="bkyd-chart-des">上证指数</div>
        <div class="bkyd-chart-line">
          <div class="num">{{zlMaxMinVal.yMax}}</div>
          <div class="line"></div>
        </div>
        <chartB id='sczl2' :chartData="stockList7" @getZlMaxMinVal='getZlMaxMinVal' />
        <div class="bkyd-chart-line">
          <div class="num">{{zlMaxMinVal.yMin}}</div>
          <div class="line"></div>
        </div>
      </div>
<!--      2.0表格-->
      <market-table :board-list="currentBoardData.boardList" />
    </div>

    <div class="bg">
      <div class="tit">
        <div class="t"><span>两市成交</span></div>
        <div class="update-time">更新于{{stockDate}}</div>
      </div>
      <chartC id='lschj' :chartData="stockList5" />
    </div>

    <div class="bg">
      <div class="tit">
        <div class="t"><span>板块统计</span></div>
        <div class="update-time">更新于{{stockDate}}</div>
      </div>

      <el-tabs v-model="bktjActiveIdx1" class="tabs1" @tab-click="handleOutNavClick">
        <el-tab-pane label="按行业" >
          <el-tabs v-model="bktjActiveIdx2" class="tabs2" @tab-click="handleInnerNavClick" >
            <el-tab-pane :label="item.name" v-for="(item, idx) in stockList2" :key='idx'>
              <!-- <el-skeleton v-if="!stockList4[item.type]">
                <template #template>
                  <el-skeleton-item variant="text" style="height:98px;margin-bottom:12px" />
                  <el-skeleton-item variant="text" style="height:98px;" />
                </template>
              </el-skeleton> -->
              <div v-if="!stockList4[item.type]" v-loading='true' style='height:222px'></div>
              <el-scrollbar v-else >

                <ul class="bktj-list">
                  <li
                    v-for="(item, idx) in (stockList4[item.type].slice(0, Math.floor(stockList4[item.type].length/2)))"
                    :key='idx'
                    :class="item.boardIncr > 0 ? '' : 'color2'">
                      <BoardItem :item='item' />
                  </li>
                  <li style="opacity:0;width:35px" />
                </ul>
                <ul class="bktj-list">
                  <li
                    :class="item.boardIncr > 0 ? '' : 'color2'"
                    v-for="(item, idx) in (stockList4[item.type].slice(Math.ceil(stockList4[item.type].length/2), stockList4[item.type].length)).reverse()" :key='idx'>
                      <BoardItem :item='item' />
                  </li>
                </ul>
                <div class="list-shadow"></div>
              </el-scrollbar>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
        <el-tab-pane label="按市值" >
          <div v-if="!stockList3" v-loading='true' style='height:222px'></div>
          <template v-else>
            <ul class="bktj-list" >
              <li
                :class="item.boardIncr > 0 ? '' : 'color2'"
                v-for="(item, idx) in (stockList3.slice(0, 3))"
                :key='idx'>
                  <BoardItem :item='item' disabled />
              </li>
            </ul>
            <ul class="bktj-list" >
              <li
                :class="item.boardIncr > 0 ? '' : 'color2'"
                v-for="(item, idx) in (stockList3.slice(3, 6))"
                :key='idx'>
                  <BoardItem :item='item' disabled />
              </li>
            </ul>
          </template>
        </el-tab-pane>
        <el-tab-pane label="按地域" >
          <div v-if="!stockList8.length" v-loading='true' style='height:222px'></div>
          <el-scrollbar v-else class="tabs2">
            <ul class="bktj-list" >
              <li
                :class="item.boardIncr > 0 ? '' : 'color2'"
                v-for="(item, idx) in (stockList8.slice(0, Math.floor(stockList8.length/2)))"
                :key='idx'>
                  <BoardItem :item='item' />
              </li>
              <li style="opacity:0;width:35px" />
            </ul>
            <ul class="bktj-list" >
              <li
                :class="item.boardIncr > 0 ? '' : 'color2'"
                v-for="(item, idx) in (stockList8.slice(Math.ceil(stockList8.length/2), stockList8.length)).reverse()"
                :key='idx'>
                  <BoardItem :item='item' />
              </li>
            </ul>
            <div class="list-shadow"></div>
          </el-scrollbar>
        </el-tab-pane>
      </el-tabs>

    </div>

    <div class="bg">
      <div class="tit title_box">
        <div class="left_box">
          <div class="t"><span>北向资金</span></div>
          <div class="update-time">更新于{{stockBxDate}}</div>
        </div>
        <div class="more_box" @click="goMore">查看更多 ></div>
      </div>
      <div class="bxzj_barT">
        <div v-if="tagType === 1" class="fen_left_box">
          <div class="first_box">
            <span>北向资金总净买入</span>
            <div class="bottom_box">
              <span v-if="northboundFunds.timeSharing.state === 0" style="text-align: center">--</span>
              <span v-if="northboundFunds.timeSharing.state === 0" style="text-align: center"></span>
              <span v-if="northboundFunds.timeSharing.state === 1" :style="styleMathe(northboundFunds.timeSharing.bx)">{{ northboundFunds.timeSharing.bx }}</span>
              <span v-if="northboundFunds.timeSharing.state === 1" :style="styleMathe(northboundFunds.timeSharing.bx)">亿</span>
            </div>
          </div>
          <div class="line_box"></div>
          <div class="center_box">
            <span>沪股通</span>
            <div class="bottom_box">
              <span v-if="northboundFunds.timeSharing.state === 0" style="text-align: center">--</span>
              <span v-if="northboundFunds.timeSharing.state === 0"></span>
              <span v-if="northboundFunds.timeSharing.state === 1">{{ northboundFunds.timeSharing.sh }}</span>
              <span v-if="northboundFunds.timeSharing.state === 1">亿</span>
            </div>
          </div>
          <div class="line_two_box"></div>
          <div class="three_box">
            <span>深股通</span>
            <div class="bottom_box">
              <span v-if="northboundFunds.timeSharing.state === 0" style="text-align: center">--</span>
              <span v-if="northboundFunds.timeSharing.state === 0" style="text-align: center"></span>
              <span v-if="northboundFunds.timeSharing.state === 1">{{ northboundFunds.timeSharing.sz }}</span>
              <span v-if="northboundFunds.timeSharing.state === 1">亿</span>
            </div>
          </div>
        </div>
        <div v-if="tagType === 2" class="ls_left_box">
          <div class="item_box">
            <span>近1日</span>
            <div class="bottom_box">
              <span :style="styleMathe(northboundFunds.history.day1In)">{{ northboundFunds.history.day1In }}</span>
              <span :style="styleMathe(northboundFunds.history.day1In)">亿</span>
            </div>
          </div>
          <div class="line_box"></div>
          <div class="item_box">
            <span>近5日</span>
            <div class="bottom_box">
              <span :style="styleMathe(northboundFunds.history.day5In)">{{ northboundFunds.history.day5In }}</span>
              <span :style="styleMathe(northboundFunds.history.day5In)">亿</span>
            </div>
          </div>
          <div class="line_box"></div>
          <div class="item_box">
            <span>近20日</span>
            <div class="bottom_box">
              <span :style="styleMathe(northboundFunds.history.day20In)">{{ northboundFunds.history.day20In }}</span>
              <span :style="styleMathe(northboundFunds.history.day20In)">亿</span>
            </div>
          </div>
          <div class="line_box"></div>
          <div class="item_box">
            <span>近60日</span>
            <div class="bottom_box">
              <span :style="styleMathe(northboundFunds.history.day60In)">{{ northboundFunds.history.day60In }}</span>
              <span :style="styleMathe(northboundFunds.history.day60In)">亿</span>
            </div>
          </div>
        </div>
        <div class="right_box">
          <div :class="tagType === 1 ? 'left' : 'left_check'" @click="tagClick(1)"><span>分时</span></div>
          <div :class="tagType === 2 ? 'right_check' : 'right'" @click="tagClick(2)"><span>历史</span></div>
        </div>
      </div>
      <div v-if="tagType === 1" style="position: relative">
        <chart-d id='bxzjOne' :chart-data="northboundFunds.timeSharing" />
        <div class="center_box" style=" position: absolute; text-align: center;left:0;right:0;margin: auto; bottom: 40px; z-index: 3; font-size: 12px;
color: #999999;" >
          <span >11:30/13:00</span>
        </div>
      </div>

      <div style="position: relative" class="line_box" v-if="tagType === 2">
        <div class="title_box">
          <div>价格指数</div>
          <div>净买入(亿)</div>
        </div>
        <div class="num_box">
          <div>{{ bxMaxMinVal.MaxArrpLeft }}</div>
          <div class="center_line"></div>
          <div>{{ bxMaxMinVal.MaxArrpRight }}</div>
        </div>

        <chart-e id='bxzjTwo' :chart-data="northboundFunds.history" @getbxVal="getbxVal" />

        <div style="width: 100%; position: absolute; left: 0; bottom: 45px; z-index: 3" class="num_box">
          <div>{{ bxMaxMinVal.MinArrpLeft }}</div>
          <div class="center_line"></div>
          <div>{{ bxMaxMinVal.MinArrpRight }}</div>
        </div>

        <div  class="time-list">
          <div>{{ bxMaxMinVal.timeLeft }}</div>
          <div>{{ bxMaxMinVal.timeCenter }}</div>
          <div>{{ bxMaxMinVal.timeRight }}</div>
        </div>
      </div>
    </div>

    <div class="bg">
      <div class="tit title_box">
        <div class="left_box">
        <div class="t"><span>融资融券</span></div>
        <div class="update-time">更新于{{stockDate}}</div>
        </div>
        <div class="more_box" @click="goMoreMargin">查看更多 ></div>
      </div>
      <div class="bar_data_box">
        <div class="item_box">
          <div class="top">融资融券余额</div>
          <div class="center">{{marginTradingData.dayOnDayData.preRzrqye}}</div>
          <div class="bottom">
            <span>环比</span>
            <span :style="styleMath(marginTradingData.dayOnDayData.rzrqyehb)">{{marginTradingData.dayOnDayData.rzrqyehb}}</span>
          </div>
        </div>
        <div class="line"></div>
        <div class="item_box">
          <div class="top">融资余额</div>
          <div class="center">{{marginTradingData.dayOnDayData.preRzye}}</div>
          <div class="bottom">
            <span>环比</span>
            <span :style="styleMath(marginTradingData.dayOnDayData.rzyehb)">{{marginTradingData.dayOnDayData.rzyehb}}</span>
          </div>
        </div>
        <div class="line"></div>
        <div class="item_box">
          <div class="top">融券余额</div>
          <div class="center">{{marginTradingData.dayOnDayData.preRqye}}</div>
          <div class="bottom">
            <span>环比</span>
            <span :style="styleMath(marginTradingData.dayOnDayData.rqyehb)">{{marginTradingData.dayOnDayData.rqyehb}}</span>
          </div>
        </div>
      </div>
       <chart-f id='rzrq' :chart-data="marginTradingData" />
    </div>

    <div class="bg">
      <div class="tit">
        <div class="t"><span>板块异动</span></div>
        <div class="update-time">更新于{{stockDate}}</div>
      </div>
      <!-- <chartC id='lschj' /> -->
      <div class="szzs" v-if="stockList1 && stockList1.indexName">
        <div class="name">{{stockList1.indexName}}</div>
        <div class="num">
          <div v-dClass='stockList1.indexChg'>{{stockList1.indexPrice}}</div>
          <div v-dClass='stockList1.indexChg'>
            <template v-if="stockList1.indexChg > 0">+</template>{{stockList1.indexChg}}
          </div>
          <div v-dClass='stockList1.indexIncr'>{{stockFormat(stockList1.indexIncr)}}</div>
        </div>
      </div>

      <div class="bkyd-chart" v-if="stockList1.maxPrice">
        <div class="bkyd-chart-line">
          <div class="num">{{stockList1.maxPrice}}</div>
          <div class="line"></div>
        </div>
        <div class="chart-box">
          <chartG id='bkyd1' :chartData="stockList1"/>
        </div>
        <div class="bkyd-chart-line">
          <div class="num">{{stockList1.minPrice}}</div>
          <div class="line"></div>
        </div>
        <div class="time-list" style='margin-bottom:0'>
          <div>9:30</div>
          <div>11:30/13:00</div>
          <div>15:00</div>
        </div>
        <div class="bkyd-b">
          <chartH id='bkyd2' :chartData="stockList1" />
        </div>
      </div>
    </div>
    <div class="bg">
      <div class="tit">
        <div class="t"><span>指数估值</span></div>
        <div class="update-time">更新于{{stockDate}}</div>
      </div>
      <valuation-table />
    </div>
  </div>
</template>

<script lang="ts">
import BoardItem from "./components/BoardItem.vue";
import chartA from "./charts/chartA.vue";
import chartB from "./charts/chartB.vue";
import chartC from "./charts/chartC.vue";
import chartD from "./charts/chartD.vue";
import chartE from "./charts/chartE.vue";
import chartF from "./charts/chartF.vue";
import chartG from "./charts/chartG.vue";
import chartH from "./charts/chartH.vue";
import marketTop from './components/market-overview.vue'
import marketTable from './components/market-table.vue'
import valuationTable from './components/index-valuation-table.vue'

import { getDataCenterBoardUnusualAction, getDataCenterBoardTypes, getDataCenterBoardMvStatistic, getDataCenterBoardIndustryStatistic, getDataCenterMarketTransaction, marginTradingData, getDataCenterMarketOverviewToday, getDataCenterMarketOverviewHistory, northboundFundsTimeSharing, northboundFundsHistory, getStockTradeDate, getCurrentBoardData, getRegionStatistic } from '@/apis/market'

import { stockFormat } from '@/utils/data-format'

import moment from 'moment'

import { defineComponent, reactive, toRefs, ref, onBeforeUnmount } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    BoardItem,
    chartA,
    chartB,
    chartC,
    chartD,
    chartE,
    chartF,
    chartG,
    chartH,
    marketTop,
    marketTable,
    valuationTable
  },
  setup() {
    const router = useRouter();
    const time = ref()
    const dataMap = reactive({
      bktjActiveIdx1: 0,
      bktjActiveIdx2: 0,
      stockList1: {},
      stockList2: [],
      stockList3: null as any,
      stockList4: {},
      stockList5: [],
      stockList6: [],
      stockList7: [],
      stockList8: [],
      zlMaxMinVal: {},
      marginTradingData: {
        dayOnDayData: {}
      },
      tagType: 1,
      northboundFunds: {
        timeSharing: {},
        history: {}
      },
      bxMaxMinVal: {},
      stockDate: '',
      stockBxDate: '',
      currentBoardData: {},
      time: null,
      zcLoading: true
    })
    const northboundFundsFn = async (item: any) => {
      // 北向资金
      // 分时
      if (item === 1) {
        const timeSharingData = await northboundFundsTimeSharing({ })
        if ((timeSharingData as any).data.state === 0) {
          (timeSharingData as any).data.list = []
        }
        dataMap.northboundFunds.timeSharing = (timeSharingData as any).data;
      } else if (item === 2) {
        const historyData = await northboundFundsHistory({ })
        console.log(historyData, 'historyData')
        dataMap.northboundFunds.history = (historyData as any).data;
      }
    }
    const tagClick = (item: any) => {
      dataMap.tagType = item
      northboundFundsFn(item)
    }
    tagClick(1)
    time.value = setInterval(() => {
      let openingTime = new Date().setHours(9,0,0,0)
      let closingTime = new Date().setHours(15,30,0,0)
      let currentTime = Date.now()
      if (currentTime >= openingTime && currentTime <= closingTime) {
        fetchData()
        northboundFundsFn(1)
      } else {
        clearInterval(time.value)
      }
    }, 60000)
    const fetchData = async () => {
      //板块异动
      const data1 = await getDataCenterBoardUnusualAction({ })
      dataMap.stockList1 = (data1 as any).data;

      //板块统计 导航
      const data2 = await getDataCenterBoardTypes({ type: 2 })
      dataMap.stockList2 = (data2 as any).data;
      getIndustry(dataMap.stockList2[dataMap.bktjActiveIdx2])

      //两市成交额
      const data3 = await getDataCenterMarketTransaction({ })
      dataMap.stockList5 = (data3 as any).data;

      //当日涨跌数据
      const data4 = await getDataCenterMarketOverviewToday({ })
      dataMap.stockList6 = (data4 as any).data;

      //历史涨跌数据
      const data5 = await getDataCenterMarketOverviewHistory({ })
      dataMap.stockList7 = (data5 as any).data;

      //获取交易日信息 //statisticDate
      const data6 = await getStockTradeDate({ })
      dataMap.stockDate = moment((data6 as any).data.statisticDate).format('YYYY-MM-DD HH:mm:ss');
      dataMap.stockBxDate = moment((data6 as any).data.prevNbTradeDate).format('YYYY-MM-DD HH:mm:ss');

      const currentBoardList = await getCurrentBoardData({})
      if ((currentBoardList as any).code === 200) {
        dataMap.currentBoardData = (currentBoardList as any).data
        dataMap.zcLoading = false
      }


    }
    fetchData()
    const marginTrading = async () => {
      //融资融券数据
      const tradingData = await marginTradingData({ })
      dataMap.marginTradingData = (tradingData as any).data;
    }
    marginTrading()

    //板块统计 行业统计
    const getIndustry = async (item:any) => {
      // if( dataMap.stockList4[item.type] ){
      //   return false
      // }
      const data4 = await getDataCenterBoardIndustryStatistic({ boardType: item.type })
      dataMap.stockList4[item.type] = (data4 as any).data;
    }

    //板块统计 行业市值 tab切换
    const handleOutNavClick = async (item:any) => {
      if( item.index === '1' && !dataMap.stockList3 ){
        //请求板块统计  市值统计
        const data = await getDataCenterBoardMvStatistic({ })
        dataMap.stockList3 = (data as any).data;
      }

      console.log(item.index)

      if( item.index === '2' && !dataMap.stockList8.length ){
        //请求板块统计  市值统计
        const data = await getRegionStatistic({ })
        dataMap.stockList8 = (data as any).data;
      }

    }

    //板块统计 行业 二级导航 tab切换
    const handleInnerNavClick = async (item:any) => {
      getIndustry(dataMap.stockList2[item.index])
    }

    //获取总览最大最小值
    const getZlMaxMinVal = (obj:any) => {
      dataMap.zlMaxMinVal = obj
    }
    //获取总览最大最小值
    const getbxVal = (obj:any) => {
      dataMap.bxMaxMinVal = obj
    }
    const styleMath = (item: any) => {
      if( item ){
        const itemNumber = Number(item.slice(0, item.length - 1))
        return itemNumber < 0 ?  'color: #5CBC7C' : itemNumber > 0 ?  'color: #F74E4F' : 'color: #606266'
      }
    }
    const styleMathe = (item: any) => {
      if( item ){
        const itemNumber = item
        return itemNumber < 0 ?  'color: #5CBC7C' : itemNumber > 0 ?  'color: #F74E4F' : 'color: #606266'
      }
    }
    const goMore = () => {
      router.push(`/northboundFunds/index`)
    }
    const goMoreMargin = () => {
      router.push(`/marginTrading/index`)
    }
    onBeforeUnmount(() => {
      clearInterval(time.value)
    })


    return {
      ...toRefs(dataMap),
      stockFormat,
      getZlMaxMinVal,
      tagClick,
      handleOutNavClick,
      handleInnerNavClick,
      getbxVal,
      styleMath,
      styleMathe,
      goMore,
      goMoreMargin
    };
  },
});
</script>
<style lang='scss' scoped>
.bg {
  background: #fff;
  padding: 38px 32px 54px;
  margin-bottom: 30px;
  > .bar_data_box {
    background: #F7F8FA;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    margin-bottom: 30px;
    > .item_box {
      padding: 8px 0;
      &:first-child {
        padding-left: 30px;
      }
      &:last-child {
        padding-right: 20px;
      }
      > .top {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        text-align: center;
      }
      > .center {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: bold;
        color: #444444;
        line-height: 20px;
        text-align: center;
      }
      > .bottom {
        text-align: center;
        > span {
          &:first-child {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
          }
          &:last-child {
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #F74E4F;
            line-height: 20px;
            margin-left: 7px;
          }
        }
      }
    }
    > .line {
      width: 1px;
      height: 50px;
      background: #386FF2;
      border-radius: 2px;
      opacity: 0.06;
      margin: 0 17px;
    }
  }
  >
  .bxzj_barT {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 30px;
    > .fen_left_box {
      border-radius: 8px;
      padding: 12px 50px 9px 29px;
      background: #F7F8FA;
      display: flex;
      > .first_box {
        > span {
          display: block;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 20px;
          text-align: center;
        }
        > .bottom_box {
          > span {
            &:first-child {
              font-size: 20px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #F74E4F;
              line-height: 28px;
            }
            &:last-child {
              font-size: 12px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #F74E4F;
              line-height: 17px;
            }
          }
        }
      }
      > .line_box {
        width: 1px;
        height: 51px;
        background: #386FF2;
        border-radius: 2px;
        opacity: 0.06;
        margin: 0 48px 0 64px;
      }
      > .center_box {
        > span {
          display: block;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 20px;
          text-align: center;
        }
        > .bottom_box {
          text-align: center;
          > span {
            &:first-child {
              font-size: 20px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #0086FB;
              line-height: 28px;
            }
            &:last-child {
              font-size: 12px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #0086FB;
              line-height: 17px;
            }
          }
        }
      }
      > .line_two_box {
        width: 1px;
        height: 51px;
        background: #386FF2;
        border-radius: 2px;
        opacity: 0.06;
        margin: 0 30px 0 44px;
      }
      > .three_box {
        > span {
          display: block;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 20px;
          text-align: center;
        }
        > .bottom_box {
          text-align: center;
          > span {
            &:first-child {
              font-size: 20px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #FE9625;
              line-height: 28px;
            }
            &:last-child {
              font-size: 12px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #FE9625;
              line-height: 17px;
            }
          }
        }
      }

    }
    > .ls_left_box {
      border-radius: 8px;
      padding: 12px 16px 9px 16px;
      background: #F7F8FA;
      display: flex;
      > .item_box {
        > span {
          display: block;
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 20px;
          text-align: center;
        }
        > .bottom_box {
          text-align: center;
          > span {
            &:first-child {
              font-size: 18px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #F74E4F;
              line-height: 28px;
            }
            &:last-child {
              font-size: 8px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #F74E4F;
              line-height: 17px;
            }
          }
        }
      }
      > .line_box {
        width: 1px;
        height: 51px;
        background: #386FF2;
        border-radius: 2px;
        opacity: 0.06;
        margin: 0 28px;
      }

    }
    > .right_box {
      border-radius: 4px;
      display: flex;
      > .left {
        width: 79px;
        line-height: 35px;
        background: #386FF2;
        border-radius: 4px 0px 0px 4px;
        text-align: center;
        cursor: pointer;
        > span {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 20px;
        }
      }
      > .left_check {
        width: 79px;
        line-height: 35px;
        background: #FFFFFF;
        border-radius: 4px 0px 0px 4px;
        border: 1px solid #DDDDDD;
        border-right: none;
        text-align: center;
        cursor: pointer;
        > span {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #666666;
          line-height: 20px;
        }
      }
      > .right {
        width: 79px;
        line-height: 35px;
        background: #FFFFFF;
        border-radius: 0px 4px 4px 0px;
        border: 1px solid #DDDDDD;
        text-align: center;
        cursor: pointer;
        > span {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #666666;
          line-height: 20px;
        }
      }
      > .right_check {
        width: 79px;
        line-height: 35px;
        background: #386FF2;
        border-radius: 0px 4px 4px 0px;
        border: 1px solid #DDDDDD;
        text-align: center;
        cursor: pointer;
        > span {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 20px;
        }
      }
    }
  }
  .line_box {
    > .title_box {
      display: flex;
      justify-content: space-between;
      > div {
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #0E1018;
        line-height: 17px;
        opacity: 0.5;
      }
    }
    > .num_box {
      margin-top: 7px;
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > div {
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #0E1018;
        line-height: 17px;
        opacity: 0.5;
      }
      > .center_line {
        flex: 1;
        width: 100%;
        height: 1px;
        border-bottom: 1px #CCCCCC dashed;
        margin: 0 7px;
      }
    }
    .time-list{
      margin: 0 7px;
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      display: flex;
      justify-content: space-between;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 40px;
      z-index: 3;
      div{
        flex: 1;
        &:nth-child(2){
          text-align: center;
        }
        &:last-child{
          text-align: right;
        }
      }
    }
  }
}
.tit {
  display: flex;
  align-items: flex-end;
  margin-bottom: 30px;
  .t {
    font-size: 20px;
    font-weight: 500;
    color: #333333;
    position: relative;
    span{
      position: relative;
      z-index: 2;
    }
    &::after {
      content: "";
      width: 80px;
      height: 9px;
      background: linear-gradient(
        270deg,
        rgba(56, 111, 242, 0) 0%,
        #386ff2 100%
      );
      z-index: 1;
      position: absolute;
      left: 0;
      bottom: -3px;
    }
  }
  .update-time {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    margin-left: 5px;
  }
}
.title_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  .left_box {
    display: flex;
    align-items: flex-end;
    .t {
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      position: relative;
      span{
        position: relative;
        z-index: 2;
      }
      &::after {
        content: "";
        width: 80px;
        height: 9px;
        background: linear-gradient(
            270deg,
            rgba(56, 111, 242, 0) 0%,
            #386ff2 100%
        );
        z-index: 1;
        position: absolute;
        left: 0;
        bottom: -3px;
      }
    }
    .update-time {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      margin-left: 5px;
    }
  }
  .more_box {
    cursor: pointer;
  }
}

.bkyd-b{
  padding: 0 80px 0 60px;
}
.sczl-chart{
  position: relative;
  .bkyd-chart-des{
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #0E1018;
    margin-bottom: 6px;
    opacity: .5;
  }
  .bkyd-chart-line:last-child{
    position: absolute;
    width: 100%;
    bottom: 60px;
  }
}

.antd-pro-pages-market-styles-riseFallbar{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 18px;
  box-sizing: border-box;
  margin: 37px 0 75px;
  .antd-pro-pages-market-styles-riseFallIcon{
    height: 12px;
    width: 12px;
  }
  .antd-pro-pages-market-styles-fallNumber{
    margin-left: 20px;
    margin-right: 6px;
    color: #14ba71;
    font-size: 14px;
    font-weight: 500;
    line-height: 15px;
  }
  .antd-pro-pages-market-styles-fallRiseBar{
    flex: 1 1;
    display: flex;
    flex-direction: row;
    margin: 0 5px;
    overflow: hidden;
    .antd-pro-pages-market-styles-fallBar{
      height: 6px;
      // background: #14ba71;
      // transform: skewX(-30deg, 0deg);
      position: relative;
      &::after{
        content:"";
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        // z-index: -1;
        background: #14ba71;
        transform-origin: bottom left;
        -ms-transform: skew(-30deg);
        -webkit-transform: skew(-30deg);
        transform: skew(-30deg);
      }
    }
    .antd-pro-pages-market-styles-marketGrayBar{
      height: 6px;
      // background: #646d80;
      margin: 0 6px;
      // transform: skewX(-30deg);
      position: relative;
      // transform: skew(-30deg, 0deg);
      &::after{
        content:"";
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        // z-index: -1;
        background: #646d80;
        transform-origin: bottom left;
        // -ms-transform: skew(0deg, 30deg);
        // -webkit-transform: skew(0deg, 30deg);
        transform: skewX(-30deg);
      }
    }
    .antd-pro-pages-market-styles-riseBar{
      height: 6px;
      position: relative;
      background: #f54346;
      &::after{
        content:"";
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: #f54346;
        transform-origin: bottom left;
        transform: skewX(-30deg);
      }
    }
  }
  .antd-pro-pages-market-styles-riseNumber{
    margin-left: 6px;
    margin-right: 20px;
    color: #f54346;
    font-size: 14px;
    font-family: DIN-Medium,DIN;
    font-weight: 500;
    line-height: 15px;
  }
}

::v-deep .tabs1{
  > .el-tabs__header{
    // width: 277px;
    height: 55px;
    border-radius: 8px;
    .el-tabs__nav{
      background: #F1F2F7;
      border-radius: 8px;
      padding: 3px 5px;
    }
    .el-tabs__nav-wrap{
      &::after{
        height: 0;
      }
    }
    .el-tabs__nav-scroll{
      padding: 3px;
      .el-tabs__active-bar{
        display: none;
      }
      .el-tabs__nav-wrap{
        &::after{
          height: 0;
        }
      }
      .el-tabs__item{
        width: 135px;
        height: 49px;
        text-align: center;
        padding: 0;
        line-height: 49px;
        color: #999BA7;
        font-size: 16px;
        &.is-active{
          background: #FFFFFF;
          box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.1);
          border-radius: 8px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
        }
      }
    }
  }
}
::v-deep .tabs2{
  position: relative;
  .list-shadow{
    width: 58px;
    height: 220px;
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, #FFFFFF 100%);
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .el-tabs__nav-wrap{
    &::after{
      height: 1px;
      background: #F1F2F7;
    }
  }
  .el-tabs__nav-scroll{
    padding: 3px 3px 10px;
    .el-tabs__active-bar{
      // width: 12px!important;
      // height: 4px;
      // background: #386FF2;
      // border-radius: 2px;
      display: none;
    }
    .el-tabs__nav-wrap{
      &::after{
        // height: 0;
      }
    }
    .el-tabs__item{
      // width: 135px;
      // height: 49px;
      text-align: center;
      padding: 0;
      margin-right: 52px;
      // line-height: 49px;
      color: #999BA7;
      font-weight: 500;
      font-size: 16px;
      position: relative;
      &.is-active{
        // background: #fff!important;
        // box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.1);
        border-radius: 8px;
        // font-family: PingFangSC-Medium, PingFang SC;
        color: #333333;
        &::after{
          content: '';
          width: 12px;
          height: 4px;
          background: #386FF2;
          border-radius: 2px;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
        }
      }
    }
  }
}
.tabs3{
  margin-top: 50px;
}

.bktj-list{
  display: flex;
  margin-bottom: 12px;
  // &.bktj-list2{
  //   li{
  //     background: linear-gradient(180deg, #EAF9F2 0%, #F7FCFB 100%);
  //     // .num1{
  //     //   color: #5CBC7C;
  //     // }
  //   }
  // }
  ::v-deep li{
    flex-shrink: 0;
    width: 137px;
    height: 98px;
    text-align: center;
    padding-top: 10px;
    margin-right: 8px;
    background: linear-gradient(180deg, #FEF2F3 0%, #FFFCFC 100%);
    border-radius: 6px;
    &.color2{
      background: linear-gradient(180deg, #EAF9F2 0%, #F7FCFB 100%);
    }
    .industry{
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      padding: 0 10px;
    }
    .num1{
      font-size: 22px;
      font-weight: 500;
      // color: #F74E4F;
      line-height: 30px;
      margin-bottom: 12px;
    }
    .name{
      font-size: 12px;
      font-weight: 500;
      .text{
        color: #717890;
      }
      .num2{
      }
    }
  }
}


.szzs{
  // width: 290px;
  // height: 79px;
  background: #F7F8FA;
  border-radius: 8px;
  // margin-left: 20px;
  margin-bottom: 30px;
  padding: 18px 35px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  .name{
    font-size: 18px;
    // font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    margin-bottom: 5px;
  }
  .num{
    display: flex;
    align-items: flex-end;
    div{
      font-size: 14px;
      margin-right: 16px;
      &:nth-child(1){
        font-size: 20px;
        margin-right: 22px;
        // color: #F74E4F;
      }
      // &:nth-child(2),&:nth-child(3){
      //   font-size: 16px;
      // }
      &:nth-child(3){
        margin-right: 0;
      }
    }
  }
}
.bkyd-chart{
  .chart-box{
    padding-left: 40px;
    margin: auto;
  }
  .bkyd-chart-line{
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: .5;
    .num{
      // width: 200px;
      font-size: 12px;
      font-weight: 500;
      color: #0E1018;
      padding-right: 10px;
    }
    .line{
      flex: 1;
      height: 1px;
      border-top: 1px dashed #BFBFBF;
    }
  }
  .time-list{
    margin: 15px 0 30px 30px;
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    display: flex;
    div{
      flex: 1;
      &:nth-child(2){
        text-align: center;
      }
      &:last-child{
        text-align: right;
      }
    }
  }
}
</style>
