
import {
  defineComponent,
  onActivated,
  onBeforeUnmount,
  onDeactivated,
  onMounted,
  nextTick,
  watch,
  reactive,
  toRefs
} from 'vue'
import { init, EChartsOption } from 'echarts'
import resize from '@/components/charts/mixins/resize'
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '350px',
      required: true
    },
    chartData: {
      type: Object,
      default: {}
    },
    selectedTime: {
      type: String || Number,
      default: ''
    },
    titleTag: {
      type: String || Number,
      default: ''
    },
    styleStatus: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()
    const dataMap = reactive({
      count: 0
    })

    watch(props, (newName, oldName) => {
      initChart()
    });

    const initChart = () => {
      const xAxisList = Array.from((props.chartData as any).data, ({ name }) => name);
      const yAxisLeftList = Array.from((props.chartData as any).data, ({ value }) => value);
      const barChart = init(document.getElementById(props.id) as HTMLDivElement)
      barChart.setOption({
        grid: {
          top: '30px',
          left: '67px',
          right: '67px',
          bottom: '30px',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          formatter:function(params: any) {
            let text = ``;
            for (let i = 0; i < params.length; i++) {
              const item = params[i]
              text += `<div style="display: flex; justify-content: space-between">
                      <div style="display: flex;align-items: center">
                        <span style='width:8px;height:8px;display:inline-block;border-radius:50%;margin-right:3px;background: ${item.color}'></span>
                        <span style='display:inline-block;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #666666;line-height: 17px;'>${item.name}：</span>
                        </div>
                        <span style='display:inline-block;font-size: 14px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #333333;line-height: 20px;'>${ item.value }家</span>
                    </div>`
            }
            return text
          }
        },
        xAxis: {
          data: xAxisList,
          axisLine: { show: false, onZero: false },
          splitLine: { show: false },
          axisPointer: {
            label: { show: false }
          },
          axisLabel:{
            show: true
          },
          triggerEvent: true,
          axisTick: { show: false },
          splitArea: { show: false }
        },
        yAxis: [
          {
            type: 'value',
            name: '单位（家）',
            splitLine: { show: true, lineStyle: { type: 'dashed' } },
            axisPointer: {
              type: 'none',
              label: { show: false }
            },
            axisLabel: {
              show: true,
              formatter:function(param: any) {
                return param
              }
            }
          }
        ],
        series: [
          {
            // name: '北上净买入(亿元)',
            type: 'bar',
            barWidth: 47,
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: 'top',
                  fontSize: 12,
                  formatter: (params: any) => {
                    return params.value + '家'
                  }
                },
                color: '#3B7FEE'
              }
            },
            data: yAxisLeftList
          },
        ]
      } as EChartsOption)
      chart.value = barChart
    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {
      ...toRefs(dataMap),
    }
  }
})
