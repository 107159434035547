<template>
  <div class="new-title">
   <el-row>
    <el-col :span="showTime ? 8 : 24">
      <div class="tit" :class="isJump ? 'cursor' : ''" @click="handleJump('')">
        <span class="title-b-border">{{title}}</span>
        <span class="small-title" v-if="smallTitle">{{smallTitle}}</span>
        <div class="arrow" v-if="icon">
          <i class="el-icon-d-arrow-right" />
        </div>
      </div>
    </el-col>
    <el-col :span="16" class="time-group" v-if="showTime">
      <div class="tab">
        <ul>
          <li :class="dstxActive === idx ? 'active' : ''" @click="swichItem(idx)" v-for="(item,idx) in dicts.timeSelection" :key='idx'>{{item.label}}</li>
          <li :class="dstxActive === 5 ? 'active' : ''" @click="swichItem(5)">自定义</li>
        </ul>
      </div>
      <el-date-picker
        v-if="showTimeSelect"
        class="date-picker"
        unlink-panels
        v-model="selectTime"
        type="daterange"
        value="YYYY-MM"
        value-format="yyyy-MM-dd HH:mm"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
        @change='getTime'
      />
    </el-col>
  </el-row>
  </div>
</template>

<script lang="ts">

import { reactive, defineComponent, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import dicts from '@/utils/dicts'

export default defineComponent({
  props: {
    title: {
      type: String
    },
    showTime: {
      type: Boolean,
      default: true
    },
    smallTitle: {
      type: String,
    },
    icon: {
      type: Boolean,
      default: false
    },
    isJump: {
      type: String,
      default: ''
    },
    isEmitJump: {
      type: Boolean,
      default: false
    },
    toNewList: {
      type: Boolean,
      default: false
    },
    timeKey: {
      type: String,
      default: ''
    },
    initIdx: {
      type: Number,
      default: 0
    },
  },
  setup(props, context) {
    const route = useRoute();
    const isSpecial = route.query.isSpecial;
    const isBuyFlag = route.query.isBuyFlag;
    const timeLevel = route.query.timeLevel as any;
    const beginDate = route.query.beginDate;
    const endDate = route.query.endDate;
    let selectedTime = isSpecial ? Number(timeLevel) === 5 ? [beginDate, endDate] : [] : [];
    let newSpTimeLevel = isSpecial ? Number(timeLevel) : 0;
    let newSpShowTimeSelect = isSpecial ? Number(timeLevel) === 5 ? true : false : false;
    if(isBuyFlag) {
      newSpTimeLevel = Number(timeLevel) + 1;
      newSpShowTimeSelect = ((Number(timeLevel) + 1) === 5) ? true : false;
      selectedTime = ((Number(timeLevel) + 1) === 5) ? [beginDate, endDate] : [];
    }
    const dataMap = reactive({
      dicts,
      dstxActive  : newSpTimeLevel,
      showTimeSelect: newSpShowTimeSelect,
      selectTime: selectedTime,

      swichItem: (idx: any) => {
        dataMap.dstxActive = idx
        dataMap.showTimeSelect = idx === 5 ? true : false
        if(idx !== 5){
          dataMap.selectTime = [];
        }
        if( props.timeKey === 'custom' ){
          idx !== 5 && context.emit('change', dicts.timeSelection[idx])
        }else{
          idx !== 5 && context.emit('change', props.timeKey ? {timeLevel: dicts.timeSelection[idx][props.timeKey]} : {timeLevel: dicts.timeSelection[idx].value})
        }
      },
      getTime: (val:any) => {
        context.emit('change', {
          timeLevel: 0,
          dateFrom: window.moment(val[0]).format('YYYY-MM-DD'),
          dateTo: window.moment(val[1]).format('YYYY-MM-DD'),
        })
      }
    })

    if( props.initIdx === 0 || props.initIdx ){
      dataMap.dstxActive = props.initIdx
    }

    const handleJump = (name:any) => {
      if( props.isJump ){
        if(props.toNewList) {
          if( dataMap.showTimeSelect ){
            const beginDate = window.moment(`${dataMap.selectTime[0]}`).format('YYYY-MM-DD')
            const endDate = window.moment(`${dataMap.selectTime[1]}`).format('YYYY-MM-DD')
            window.open(`/investment/agency/buy-rating?timeLevel=${dataMap.dstxActive - 1}&isBuyFlag=1&beginDate=${beginDate}&endDate=${endDate}`)
          }else{
            window.open(`/investment/agency/buy-rating?timeLevel=${dataMap.dstxActive - 1}&isBuyFlag=1`)
          }
        }else {
          if( dataMap.showTimeSelect ){
            const beginDate = window.moment(`${dataMap.selectTime[0]}`).format('YYYY-MM-DD')
            const endDate = window.moment(`${dataMap.selectTime[1]}`).format('YYYY-MM-DD')
            window.open(`/investment/agency/index?idx=${props.isJump}&timeLevel=${dataMap.dstxActive - 1}&beginDate=${beginDate}&endDate=${endDate}&organizationSName=${name}`)
          }else{
            window.open(`/investment/agency/index?idx=${props.isJump}&timeLevel=${dataMap.dstxActive - 1}&organizationSName=${name}`)
          }
        }
      }
      if(props.isEmitJump) {
        if(dataMap.showTimeSelect) {
          const beginDate = window.moment(`${dataMap.selectTime[0]}`).format('YYYY-MM-DD')
          const endDate = window.moment(`${dataMap.selectTime[1]}`).format('YYYY-MM-DD')
          context.emit('goToList', { beginDate: beginDate, endDate: endDate, timeLevel: dataMap.dstxActive  })
        }else {
          context.emit('goToList', { timeLevel: dataMap.dstxActive  })
        }
      }
    }

    const handleJump2 = (name:any) => {
      if( props.isJump ){
        if( dataMap.showTimeSelect ){
          const beginDate = window.moment(`${dataMap.selectTime[0]}`).format('YYYY-MM-DD')
          const endDate = window.moment(`${dataMap.selectTime[1]}`).format('YYYY-MM-DD')
          window.open(`/investment/agency/index?idx=${props.isJump}&timeLevel=${dataMap.dstxActive - 1}&beginDate=${beginDate}&endDate=${endDate}&orgSName=${name}`)
        }else{
          window.open(`/investment/agency/index?idx=${props.isJump}&timeLevel=${dataMap.dstxActive - 1}&orgSName=${name}`)
        }
      }
    }

    const setTime = (param:any) => {
      // console.log(param,dicts.timeSelection)
      dicts.timeSelection.map((item:any, idx:any)=>{
        if( item.timeLevel === param ){
          dataMap.dstxActive = idx
          console.log(dicts.timeSelection[idx])
          context.emit('change', dicts.timeSelection[idx], true)
        }
      })
    }

    if(isSpecial) {
      const newTimeLevel = Number(timeLevel) === 5 ? 0 : dicts.timeSelection[Number(timeLevel)].value;
      context.emit('change', {
        timeLevel: newTimeLevel,
        dateFrom: dataMap.selectTime[0] && window.moment(`${dataMap.selectTime[0]}`).format('YYYY-MM-DD'),
        dateTo: dataMap.selectTime[0] && window.moment(`${dataMap.selectTime[1]}`).format('YYYY-MM-DD'),
      })
    }else if(isBuyFlag){
      const newTimeLevel = (Number(timeLevel) + 1) === 5 ? 0 : dicts.timeSelection[Number(timeLevel) + 1].value;
      context.emit('change', {
        timeLevel: newTimeLevel,
        dateFrom: dataMap.selectTime[0] && window.moment(`${dataMap.selectTime[0]}`).format('YYYY-MM-DD'),
        dateTo: dataMap.selectTime[0] && window.moment(`${dataMap.selectTime[1]}`).format('YYYY-MM-DD'),
      })
    }else {
      if( props.timeKey === 'custom' ){
        context.emit('change', dicts.timeSelection[dataMap.dstxActive])
      }else{
        context.emit('change', props.timeKey ? {timeLevel: dicts.timeSelection[dataMap.dstxActive][props.timeKey]} : {timeLevel: -1})
      }
    }
    
    return { ...toRefs(dataMap),handleJump,handleJump2,setTime }
  }
})
</script>

<style lang="scss" scoped>
.new-title{
  margin-bottom: 20px;
  .tit{
    height: 32px;
    line-height: 32px;
    font-size: 20px;
    font-weight: bold;
    color: #000000;
    cursor: pointer;
    &.cursor{
      cursor: pointer;
    }
    .title-b-border{
      &::after{
        background: linear-gradient(90deg, #3C7FEE 0%, rgba(60,127,238,0) 100%);
        opacity: 0.58;
      }
    }
    .small-title{
      font-size: 17px;
      font-weight: 400;
      color: #666666;
    }
    .arrow{
      color: #8FB6F5;
      display: inline-block;
      font-size: 16px;
      margin-left: 10px;
    }
  }
  .time-group{
    text-align: right;
  }
  ::v-deep .date-picker{
    height: 32px!important;
    margin-left: 30px;
    position: relative;
    top: 2px;
  }
  .tab{
    display: inline-block;
    ul{
      display: flex;
      // border: 1px solid #DDDDDD;
      border-radius: 4px;
      overflow: hidden;
      // font-size: 0;
      li{
        width: 52px;
        height: 32px;
        line-height: 32px;
        cursor: pointer;
        background: #FFFFFF;
        text-align: center;
        // border-right: 1px solid #DDDDDD;
        color: #666666;
        border: 1px solid #DDDDDD;
        border-right: none;
        &.active{
          color: #fff;
          background: #386FF2;
          border-color: transparent;
          border-top: none;
          border-bottom: none;
        }
        &:last-child{
          // border: 0;
          border-right: 1px solid #DDDDDD;
          border-radius: 0 4px 4px 0;
        }
        &:first-child{
          // border: 0;
          // border-right: 1px solid #DDDDDD;
          border-radius: 4px 0 0 4px;
        }
      }
    }
  }
}
</style>
