<template>
  <div
    :id="id"
    :class="className"
    :style="{height: height, width: width}"
  />
</template>

<script lang="ts">
import { defineComponent, onActivated, onBeforeUnmount, onDeactivated, onMounted, nextTick, watch } from 'vue'
import resize from './mixins/resize'
import { init, EChartsOption } from 'echarts'
import * as echarts from 'echarts'
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '193px',
      required: true
    },
    chartData: {
      type: Object,
      default: {}
    },
    isMobile: {
      type: Boolean,
      default: false
    },
  },
  setup(props) {
    watch(props, (newName, oldName) => {
      initChart()
    });

    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()
    const initChart = () => {
      if( !props.chartData.orgHoldChart ){
        return false;
      }
      const barChart = init(document.getElementById(props.id) as HTMLDivElement)
      let orgHoldChart = JSON.parse(JSON.stringify(props.chartData.orgHoldChart)).reverse()
      // return false;
      // let orgHoldChart =  props.chartData.orgHoldChart.reverse()

      let seriesdata1 = [],
          seriesdata2 = [],
          seriesdata3 = [],
          timedata=[],
          max=0
      if(orgHoldChart&&orgHoldChart.length>0){
        for (let i = 0; i < orgHoldChart.length; i++) {
          const item = orgHoldChart[i];
    
          timedata.push(item.date)
          seriesdata1.push(item.fund)
          seriesdata2.push(item.other)
          seriesdata3.push(item.price)
          if(i==0){
        
            max = item.other+item.fund
            if(max<item.other+item.fund){
              max = item.other+item.fund
            }
          }else{
            if(max<item.other+item.fund){
              max = item.other+item.fund
            }
          }
        }
          
      }
      max = Number((Math.floor((max*1.2)>100?100:(max*1.2))+(Math.floor((max*1.2)>100?100:(max*1.2))%10==0?0:(10-(Math.floor((max*1.2)>100?100:(max*1.2))%10)))).toFixed(2))

      barChart.setOption({
        tooltip: {
          show: true,
          trigger: 'axis', 
          position:'inside',
          // padding:4,
          textStyle:{
            color:'#999999',
            fontSize:'10',
          },
          formatter:function(param:any){
            // console.log(param)
            // 利用循环更改显示的内容
            let list = param.length
            // let Oparam=param
            // console.log(list)
            let txt = '<div style="padding-bottom:10px">' + param[0].name+"</div>"
            for (var i=0;i<list;i++){
              if(i==list-1){
              txt +=param[i].marker+ param[i].seriesName + ":" + param[i].data+'元'
              }else{
                txt += '<div style="padding-bottom:10px">' +param[i].marker+ param[i].seriesName + ":" + param[i].data+'%' + '</div>'
              }
            }
            return txt
          }
        },
        grid: {
          left: 0,
          right: 0,
          bottom: 10,
          top: 10,
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: timedata,
            show:true,
            axisLine: {
              lineStyle: {
                color: '#E8E8E8',
                width:1,
              }
            },
            axisLabel: {
                show:true,
                color:  '#999999',
                fontSize:12
            },
            axisTick:{
                show:false
            },
    
          }
        ],
        yAxis: [
          {
            type: 'value',
            show:props.isMobile ? true : false,
            axisTick: { show: false },
            axisLine: {
              lineStyle: {
                color:  '#999'
              }
            },
            min:0,
            max:max,
            interval:max/4,
            axisLabel:{
              show:props.isMobile ? true : false,
              color: '#666',
              formatter: function(item:any){
                return item.toFixed(2) +'%'
              },
            },
            splitLine: {
                lineStyle: {
                    // type: 'dashed'
                    color:  '#E8E8E8',
                    width:1,
                }
            }
          },
          {
            type: 'value',
            show:false,
            axisTick: { show: false },
            axisLine: {
              lineStyle: {
                color:  '#999'
              }
            },
            axisLabel: {
              color: '#666'
            },
            splitLine: {
                lineStyle: {
                    // type: 'dashed'
                    color:  '#E8E8E8',
                    width:1,
                }
            }
          }
        ],
        series: [
          {
            name: '基金',
            stack: 'one',
            type: 'bar',
            yAxisIndex:0,
            barWidth: 15,
            label: {
              normal: {
                show: false,
              }
            },
            data: seriesdata1,
            itemStyle: {
                normal: {
                    barBorderRadius: [0,0,0,0],
                    color:'#FB160C',
                },
                label:{
                    show:false
                }
            }
          },
          {
            name: '其他机构',
            type: 'bar',
            stack: 'one',
            yAxisIndex:0,
            label: {
              normal: {
                show: false,
              }
            },
            data: seriesdata2,
            itemStyle: {
                normal: {
                    barBorderRadius: [5,5,0,0],
                    color:'#3B7FEE',
                },
                label:{
                    show:false
                }
            }
          },
          {
            name: '股价',
            type: 'line',
            smooth: true,  //这个可以显示为平滑过渡的线条
            showSymbol:true,
            yAxisIndex:1,
            symbolSize: 6,
            color:'#FE9625',
            itemStyle:{
              normal:{
                
                lineStyle:{
                  color:'#FE9625',
                  width:1,
                  barBorderRadius:0,
                },
              },
              emphasis:{
                lineStyle:{
                  width:1,
                },
              }
            },
            data:seriesdata3
          }
        ]
      } as unknown as EChartsOption)
      chart.value = barChart
    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {

    }
  }
})
</script>

<style lang="scss" scoped>

</style>
