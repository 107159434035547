
import { defineComponent, onActivated, onBeforeUnmount, onDeactivated, onMounted, nextTick, watch } from 'vue'
import resize from '@/components/charts/mixins/resize'
import { init, EChartsOption } from 'echarts'
import * as echarts from 'echarts'
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '400px',
      required: true
    },
    chartData: {
      type: Object,
      default: {}
    },
    selectedTime: {
      type: String || Number,
      default: ''
    },
  },
  setup(props) {
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()

    watch(props, (newName, oldName) => {
      initChart()
    });

    const initChart = () => {
      const chartData = props.chartData
      if( !chartData || !chartData.length ){
        return false;
      }

      const barChart = init(document.getElementById(props.id) as HTMLDivElement)
      const date = chartData.map((item:any)=>item.date)

      const servies = chartData.map((item:any)=>item.closePrice)

      const markData:any = []
      let position = false
      chartData.map((item:any,idx:any)=>{
          if( item.events.length ){
            position = !position
            markData.push(
              {
                value: item.events[0].eventDate + `\n` + item.events[0].eventDesc,
                coord: [idx, item.closePrice],
                symbol: 'circle',
                symbolSize: 0,
                symbolOffset: [0, 0],
                label: {
                  // position: position ? 'insideTopLeft' : 'insideBottomLeft',
                  padding: [30, 0, 0, 0]
                },
                itemStyle: { color: '#F74E4F'  }
                // itemStyle: { color: '#ccc' }
              }
            )
          }
      })
      console.log(markData)
      barChart.setOption({
        tooltip:{
          show: true,
        trigger: 'axis',
        },
        grid: {
          top: '45px',
          left: '0',
          right: '10px',
          bottom: '55px',
          containLabel: true
        },
        legend: {
          show: false
        },
        toolbox: {
          show: true
        },
        calculable: true,
        xAxis: [
          {
            type: 'category',
            data: date,
            axisTick: { show: false },
            splitLine:{
              show: false
            },
            axisLabel: {
              //x轴文字的配置
              show: true,
              rotate: "90"
            },
            minorSplitLine: {
              show: true
            },
            axisLine: {
                show: true,
                onZero: false,
                lineStyle: {
                    color: "#333333",
                    width: 1,
                    // type: "solid"
                }
            },
          }
        ],
        yAxis: [
          {
            type: 'value',
            scale: true,
            splitLine: {
              show: true,
               interval: function(index:any, value:any) {
                  console.log('ddddd',index,value)
                  return index === 1
                },
              lineStyle: {
                color: "#CECECE",
                width: 1,
                type: 'dashed',
              },
            },
            axisLabel: {
              show: true,
              color: '#333'
            },
            splitNumber: 2,
            axisLine: {
                show: false,
                lineStyle: {
                    color: "#333333",
                    width: 1,
                }
            },
          }
        ],
        dataZoom: [{
          show: true,
          startValue: 0,            // 开始位置，范围在 0-100 之间，表示相对位置
          endValue: 300,            // 结束位置，范围在 0-100 之间，表示相对位置
          moveOnMouseMove: true,
          moveOnMouseWheel: false
        },{
          type: 'inside',      // 可选值有 slider、inside 和 sliderInside
        }],
        series: [
          {
            name: '',
            silent: false,
            barGap: '80%',
            barWidth: '40px', // 柱子宽度
            type: 'line',
            symbol: 'none',
            data: servies,
            markPoint:{
              silent: true,
              data: markData
            },
          }
        ]
      } as EChartsOption)
      chart.value = barChart


    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {

    }
  }
})
