<template>
  <div>
    <div v-if="stockList5ChartData.data && stockList5ChartData.data.length">
      <el-tabs v-model="activeAnalysisIdx"  @tab-click="swichAnalysisItem">
                <el-tab-pane label="市盈率" :name="1">
                </el-tab-pane>
                <el-tab-pane label="市净率" :name="2">
                </el-tab-pane>
                <el-tab-pane label="市销率" :name="3">
                </el-tab-pane>
              </el-tabs>
      <div class="tab-content">
        <div class="zb-des">根据{{tabkey[activeAnalysisIdx-1].name}}估值模型分析，{{stockName}}当前{{tabkey[activeAnalysisIdx-1].name}}为<span>{{stockList5[tabkey[activeAnalysisIdx-1].id + 'Valuation']}}</span>，{{tabkey[activeAnalysisIdx-1].name}}估值分位点<span>{{stockList5[tabkey[activeAnalysisIdx-1].id + 'Quantile']}}</span>，处于近上市以来的{{stockList5[tabkey[activeAnalysisIdx-1].id + 'Quantile'] < 25 ? '低估' : stockList5[tabkey[activeAnalysisIdx-1].id + 'Quantile'] < 75 ? '适中' : '高估'}}区间。<template v-if="stockList5[tabkey[activeAnalysisIdx-1].id + 'Quantile'] < 25">后市大概率会存在均值回归过程，当前买入耐心持有将预期获得较高回报。</template></div>

            <chart-b id="chart-analysis-right" :chartData="stockList5ChartData" />
        <div class="chart-des">解读：估值分位点反映当前指数或个股的估值水平，按分位点计算前25%为较低位置，25%-75%为中等位置，75%-100%为较高位置。</div>
      </div>
    </div>
    <el-empty description="暂无数据" v-else />
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import { getValuationDaily, getValuationQuantileDaily } from '@/apis/diagnosis'
import ChartB from './ChartB.vue'

export default {
  components: {
    ChartB,
  },
  setup() {
    const route = useRoute()
    const stockCode = route.query.stockCode || route.query.code
    const dataMap = reactive({
      stockList4: [],
      stockList5: {},
      activeAnalysisIdx: 1,
      stockList5ChartData: {},
      tabkey:[{
        id: 'pe',
        name: '市盈率'
      },{
        id: 'pb',
        name: '市净率'
      },{
        id: 'ps',
        name: '市销率'
      }],
      stockName: route.query.stockName || route.query.name
    })

    const changeHandle = async(res) => {
      let data4 = await getValuationDaily({
        stockCode,
        timeLevel: '3y'
      })
      dataMap.stockList4 = data4.data

      let data5 = await getValuationQuantileDaily({
        stockCode,
        timeLevel: '3y'
      })
      dataMap.stockList5 = data5.data
      swichAnalysisItem(dataMap.activeAnalysisIdx)
    }
    changeHandle()

    const swichAnalysisItem = () => {
          dataMap.stockList5ChartData = {
            max: dataMap.stockList5[dataMap.tabkey[dataMap.activeAnalysisIdx-1].id+'Max'],
            mid: dataMap.stockList5[dataMap.tabkey[dataMap.activeAnalysisIdx-1].id+'Mid'],
            min: dataMap.stockList5[dataMap.tabkey[dataMap.activeAnalysisIdx-1].id+'Min'],
            data: dataMap.stockList5[dataMap.tabkey[dataMap.activeAnalysisIdx-1].id+'Records']
          }
    }

    return {
      ...toRefs(dataMap),
      changeHandle,
      swichAnalysisItem
    }
  }
}
</script>

<style scoped lang="scss">
.zb-des{
  margin: 10px 0 30px;
  span{
    color:#e93030
  }
}
.chart-des{
  margin-top: 10px;
}
</style>
