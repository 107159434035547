<template>
    <div class="table_box">
      <div class="item_box" v-for="(item, index) in tableList.quarterData" :key="index">
        <div class="top_box">
          {{ item.year }}
          
        </div>
        <div class="sub_item">
          <div class="left_box">归母TTM</div>
          <div class="right_box">PE-TTM</div>
        </div>
        <div class="sub_item">
          <div class="left_box" :style="index === tableList.quarterData.length-1 && tableList.quarterData[tableList.quarterData.length-1].quarter1NetProfit && !tableList.quarterData[tableList.quarterData.length-1].quarter2NetProfit && !tableList.quarterData[tableList.quarterData.length-1].quarter3NetProfit && !tableList.quarterData[tableList.quarterData.length-1].quarter4NetProfit ? 'color: #FE9625;' : ''">{{ item.quarter1NetProfit ? amount(item.quarter1NetProfit) : '-' }}</div>
          <div class="right_box">
            {{ item.quarter1PeTtm ? item.quarter1PeTtm : '-' }}
            <img v-if="item.quarter1Ahead === 1" class="e" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAA0CAYAAADFeBvrAAACA0lEQVRoQ+1aPU8CQRB9Q4gVsSEWNnZaaLT0B1iY2Fja2dkYIRErEi0oxFCB8SM0NnbGP6AxxsbC3gQLqdSGQgoDQtTzxixKIMQj7OZOjnOuu2Tf7rydt3Mze0PoeDIJnrItrDAwz4wxAJHOMX1+rxLhkYCLUBhHyRwV2u2h5stpioeKZWQZWAUj1Geje1ueYBOQH49iYylF7wrUIKTI3JdxBsZcbzP5bBThaiKKBUWqQSgd5wNmrPnMTC1ziHC4uU8xUmfGsnA7MDJzokmww2HM0E6cczZjXWs7fDo4RNildJwLzJj0qY1aZhHhjrZjXPFhaNYi0ja4qgixKdqPOCNCI6NAZNhbOg9FwLb11zAitLgMTM/qL6aDyCaB+qsO4nusEFK7IB7SVw76Ljm2gZtLA8sdINfngPWhP59rZ+jTAjIJfQPcRgghp6AgHnJbaz/zieREch5Jy7HOM8m2f8sUJCh45DnXgoKyz+TL3smrXgP2tszZukrI3IwWUpUMKo8zfYSQ1+WDbzykbiaeS6ZCaeHe6sBxznwe1yQnYdvcCV2R4iHJ5TySluRy3TZWktM/lJ1EuX8T5VTqU3pyT1u1KnCS15/PNcnpL90dUXkxq4uEkNflQ9Nv4qG2i8Zg/TQO3G/9wDVeBK41Rp2lQDUvKUKBay9rkgpMA2B7EjLoLZpfWHyAVHsoqAMAAAAASUVORK5CYII=" alt="">
          </div>
        </div>
        <div class="sub_item">
          <div class="left_box" :style="index === tableList.quarterData.length-1 && tableList.quarterData[tableList.quarterData.length-1].quarter2NetProfit && !tableList.quarterData[tableList.quarterData.length-1].quarter3NetProfit && !tableList.quarterData[tableList.quarterData.length-1].quarter4NetProfit ? 'color: #FE9625;' : ''">{{ item.quarter2NetProfit ? amount(item.quarter2NetProfit) : '-' }}</div>
          <div class="right_box">
            {{ item.quarter2PeTtm ? item.quarter2PeTtm : '-' }}
            <img v-if="item.quarter2Ahead === 1" class="e" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAA0CAYAAADFeBvrAAACA0lEQVRoQ+1aPU8CQRB9Q4gVsSEWNnZaaLT0B1iY2Fja2dkYIRErEi0oxFCB8SM0NnbGP6AxxsbC3gQLqdSGQgoDQtTzxixKIMQj7OZOjnOuu2Tf7rydt3Mze0PoeDIJnrItrDAwz4wxAJHOMX1+rxLhkYCLUBhHyRwV2u2h5stpioeKZWQZWAUj1Geje1ueYBOQH49iYylF7wrUIKTI3JdxBsZcbzP5bBThaiKKBUWqQSgd5wNmrPnMTC1ziHC4uU8xUmfGsnA7MDJzokmww2HM0E6cczZjXWs7fDo4RNildJwLzJj0qY1aZhHhjrZjXPFhaNYi0ja4qgixKdqPOCNCI6NAZNhbOg9FwLb11zAitLgMTM/qL6aDyCaB+qsO4nusEFK7IB7SVw76Ljm2gZtLA8sdINfngPWhP59rZ+jTAjIJfQPcRgghp6AgHnJbaz/zieREch5Jy7HOM8m2f8sUJCh45DnXgoKyz+TL3smrXgP2tszZukrI3IwWUpUMKo8zfYSQ1+WDbzykbiaeS6ZCaeHe6sBxznwe1yQnYdvcCV2R4iHJ5TySluRy3TZWktM/lJ1EuX8T5VTqU3pyT1u1KnCS15/PNcnpL90dUXkxq4uEkNflQ9Nv4qG2i8Zg/TQO3G/9wDVeBK41Rp2lQDUvKUKBay9rkgpMA2B7EjLoLZpfWHyAVHsoqAMAAAAASUVORK5CYII=" alt="">
          </div>
        </div>
        <div class="sub_item">
          <div class="left_box" :style="index === tableList.quarterData.length-1 && tableList.quarterData[tableList.quarterData.length-1].quarter3NetProfit && !tableList.quarterData[tableList.quarterData.length-1].quarter4NetProfit ? 'color: #FE9625;' : ''">{{ item.quarter3NetProfit ? amount(item.quarter3NetProfit) : '-' }}</div>
          <div class="right_box">
            {{ item.quarter3PeTtm ? item.quarter3PeTtm : '-' }}
            <img v-if="item.quarter3Ahead === 1" class="e" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAA0CAYAAADFeBvrAAACA0lEQVRoQ+1aPU8CQRB9Q4gVsSEWNnZaaLT0B1iY2Fja2dkYIRErEi0oxFCB8SM0NnbGP6AxxsbC3gQLqdSGQgoDQtTzxixKIMQj7OZOjnOuu2Tf7rydt3Mze0PoeDIJnrItrDAwz4wxAJHOMX1+rxLhkYCLUBhHyRwV2u2h5stpioeKZWQZWAUj1Geje1ueYBOQH49iYylF7wrUIKTI3JdxBsZcbzP5bBThaiKKBUWqQSgd5wNmrPnMTC1ziHC4uU8xUmfGsnA7MDJzokmww2HM0E6cczZjXWs7fDo4RNildJwLzJj0qY1aZhHhjrZjXPFhaNYi0ja4qgixKdqPOCNCI6NAZNhbOg9FwLb11zAitLgMTM/qL6aDyCaB+qsO4nusEFK7IB7SVw76Ljm2gZtLA8sdINfngPWhP59rZ+jTAjIJfQPcRgghp6AgHnJbaz/zieREch5Jy7HOM8m2f8sUJCh45DnXgoKyz+TL3smrXgP2tszZukrI3IwWUpUMKo8zfYSQ1+WDbzykbiaeS6ZCaeHe6sBxznwe1yQnYdvcCV2R4iHJ5TySluRy3TZWktM/lJ1EuX8T5VTqU3pyT1u1KnCS15/PNcnpL90dUXkxq4uEkNflQ9Nv4qG2i8Zg/TQO3G/9wDVeBK41Rp2lQDUvKUKBay9rkgpMA2B7EjLoLZpfWHyAVHsoqAMAAAAASUVORK5CYII=" alt="">
          </div>
        </div>
        <div class="sub_item">
          <div class="left_box" :style="index === tableList.quarterData.length-1 && tableList.quarterData[tableList.quarterData.length-1].quarter4NetProfit ? 'color: #FE9625;' : ''">{{ item.quarter4NetProfit ? amount(item.quarter4NetProfit) : '-' }}</div>
          <div class="right_box">
            {{ item.quarter4PeTtm ? item.quarter4PeTtm : '-'}}
            <img v-if="item.quarter4Ahead === 1" class="e" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAA0CAYAAADFeBvrAAACA0lEQVRoQ+1aPU8CQRB9Q4gVsSEWNnZaaLT0B1iY2Fja2dkYIRErEi0oxFCB8SM0NnbGP6AxxsbC3gQLqdSGQgoDQtTzxixKIMQj7OZOjnOuu2Tf7rydt3Mze0PoeDIJnrItrDAwz4wxAJHOMX1+rxLhkYCLUBhHyRwV2u2h5stpioeKZWQZWAUj1Geje1ueYBOQH49iYylF7wrUIKTI3JdxBsZcbzP5bBThaiKKBUWqQSgd5wNmrPnMTC1ziHC4uU8xUmfGsnA7MDJzokmww2HM0E6cczZjXWs7fDo4RNildJwLzJj0qY1aZhHhjrZjXPFhaNYi0ja4qgixKdqPOCNCI6NAZNhbOg9FwLb11zAitLgMTM/qL6aDyCaB+qsO4nusEFK7IB7SVw76Ljm2gZtLA8sdINfngPWhP59rZ+jTAjIJfQPcRgghp6AgHnJbaz/zieREch5Jy7HOM8m2f8sUJCh45DnXgoKyz+TL3smrXgP2tszZukrI3IwWUpUMKo8zfYSQ1+WDbzykbiaeS6ZCaeHe6sBxznwe1yQnYdvcCV2R4iHJ5TySluRy3TZWktM/lJ1EuX8T5VTqU3pyT1u1KnCS15/PNcnpL90dUXkxq4uEkNflQ9Nv4qG2i8Zg/TQO3G/9wDVeBK41Rp2lQDUvKUKBay9rkgpMA2B7EjLoLZpfWHyAVHsoqAMAAAAASUVORK5CYII=" alt="">
          </div>
        </div>
      </div>
    </div>
</template>
<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue'
export default defineComponent({
  props: {
    tableList: {
      type: Object,
      default: {}
    }

  },
  setup() {
    const defineData = reactive({

    })
    const amount = (value: any, fixed = 2, unit = '元') => {
      if (unit == '万') {
        value = value * 10000
      } else if (unit == '亿') {
        value = value * 100000000
      }
      if (Math.abs(value / 100000000) >= 1) {
        return (value / 100000000).toFixed(fixed) + '亿'
      }
      if (Math.abs(value / 10_000) >= 1) {
        return (value / 10000).toFixed(fixed) + '万'
      }
      return value
    }
    return {
      ...toRefs(defineData),
      amount
    }
  }
})
</script>
<style scoped lang="scss">
.table_box {
  width: 90%;
  margin: 51px auto 0 auto;
  display: flex;
  > .item_box {
    flex: 1;
    border-right: 1px solid #E8E8E8;
    border-top: 1px solid #E8E8E8;
    border-bottom: 1px solid #E8E8E8;
    &:first-child {
      border-left: 1px solid #E8E8E8;
    }
    > .top_box {
      line-height: 40px;
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      border-bottom: 1px solid #E8E8E8;
    }
    > .sub_item {
      line-height: 38px;
      display: flex;
      border-bottom: 1px solid #E8E8E8;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #3B7FEE;
      &:last-child {
        border-bottom: none;
      }
      > .left_box {
        flex: 1;
        text-align: center;
        border-right: 1px solid #E8E8E8;
      }
      > .right_box {
        flex: 1;
        text-align: center;
      }
    }
  }
}
.e{
  vertical-align: middle;
  margin-top: -4px;
  width: 20px;
}
</style>
