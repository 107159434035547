<template>
  <div
    :id="id"
    :class="className"
    :style="{height: height, width: width}"
  />
</template>

<script lang="ts">
import { defineComponent, onActivated, onBeforeUnmount, onDeactivated, onMounted, nextTick, watch } from 'vue'
import resize from '@/components/charts/mixins/resize'
import { init, EChartsOption } from 'echarts'
import * as echarts from 'echarts'
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '250px',
      required: true
    },
    chartData: {
      type: Object,
      default: {}
    },
    selectedTime: {
      type: String || Number,
      default: ''
    },
  },
  setup(props) {
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()

    watch(props, (newName, oldName) => {
      initChart()
    });

    const initChart = () => {
      const chartData = props.chartData.list
      if( !chartData || !chartData.length ){
        return false;
      }
      const barChart = init(document.getElementById(props.id) as HTMLDivElement)
      const xData = chartData.map((item:any)=>item.name)
      // chartData[0].incr = 1
      const servies = chartData.map((item:any)=>{
          return {
            value: item.incr,
            label: {
              formatter: '{c}',
              show: true,
              position: item.incr > 0 ? 'insideTop' : 'insideBottom',
              textStyle: {
                fontWeight: 'bolder',
                fontSize: '12',
                color: '#fff'
              }
            },
            itemStyle: {
              normal: {
                //background: linear-gradient(180deg, #FD0A2D 0%, #FF4B23 100%);
                // linear-gradient(180deg, #00AD88 0%, #00B43F 100%);
                // 四个数字分别对应 数组中颜色的开始位置，分别为 右，下，左，上。例如（1,0,0,0 ）代表从右边开始渐
                // 变。offset取值为0~1，0代表开始时的颜色，1代表结束时的颜色，柱子表现为这两种颜色的渐变。
                color: item.incr <= 0 ? new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: '#00AD88'
                }, {
                  offset: 1,
                  color: '#00B43F'
                }]) : new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                  offset: 0,
                  color: '#FD0A2D'
                }, {
                  offset: 1,
                  color: '#FF4B23'
                }]),
              }
            }
          }
      })

      barChart.setOption({
        // title: {
        //   text: '123',
        //
        // },
        // tooltip: {
        //   trigger: 'axis',
        //   formatter:function(param:any){
        //     let text = ''
        //     // text += `<div style='margin-bottom:5px'>${param[0].name}</div>`
        //     text += `<div>
        //                 <span style='width:10px;height:10px;display:inline-block;border-radius:50%;background:#F74E4F'></span>
        //                 <span style='margin-left:10px;display:inline-block;text-align:right;color:#333'>${param[0].value}</span>
        //             </div>`
        //     return text
        //   }
        // },
        grid: {
          top: '45px',
          left: '0',
          right: '10px',
          bottom: '0%',
          containLabel: true
        },
        legend: {
          show: true,
          icon: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAANCAYAAADISGwcAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAB7SURBVDhPY/h3+/b/X9Gx/3/aOxKFfyUm///38OF/dPDo84v/Zccn/o/ZU0MULj8+6f+TLy//jzpg1AGjDhh1wKgDGP69fPn/z5y5/3939xCF/yxY+P/f27dQaxHg3Y+P/9fc3fN/zrUNROG1d/cC9Xz6zwDVP0Dg/38AQVzhoCdnGa8AAAAASUVORK5CYII=',
          itemWidth: 22,
          itemHeight: 8,
          textStyle: {
            height: 10,
            rich: {
              a: {
                verticalAlign: 'bottom',
              },
            }
          }
          // data: ['涨跌幅(%)'],
          // selectedMode: false
          // icon: 'circle',
          // x: '0',
          // left: '0',
          // top: '0',
          // itemGap: 30,
          // textStyle: {
          //   align: 'left', // 这个是可以左右调整的
          //   fontSize: 14,// 字体大小
          //   color:'#999999'
          //   // padding:[0,0,25,-45]//主要就是用这个，可以把文字上下调整
          //   // color: 'rgba(255,255,255,0.65)'//字体颜色
          // }
        },
        toolbox: {
          show: true
        },
        calculable: true,
        xAxis: [
          {
            type: 'category',
            data: xData,
            axisTick: { show: false },
            splitLine:{
              show: false
            },
            axisLabel: {
              //x轴文字的配置
              show: true,
              interval: 0,//使x轴文字显示全
            },
            minorSplitLine: {
              show: true
            },
            axisLine: {
                show: true,
                onZero: false,
                lineStyle: {
                    color: "#333333",
                    width: 1,
                    // type: "solid"
                }
            },
          }
        ],
        yAxis: [
          {
            type: 'value',
            // scale: true,
            splitLine: {
              show: true,
               interval: function(index:any, value:any) {
                  console.log('ddddd',index,value)
                  return index === 1
                },
              lineStyle: {
                color: "#CECECE",
                width: 1,
                type: 'dashed',
              },
            },
            axisLabel: {
              show: true,
              color: '#333'
            },
            splitNumber: 2,
            axisLine: {
                show: true,
                lineStyle: {
                    color: "#333333",
                    width: 1,
                }
            },
          }
        ],
        series: [
          {
            name: '涨跌幅(%)',
            slient: false,
            barGap: '80%',
            barWidth: '40px', // 柱子宽度
            type: 'bar',
            data: servies
          }
        ]
      } as EChartsOption)
      chart.value = barChart


    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {

    }
  }
})
</script>
