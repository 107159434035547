<template>
  <div >
    <SearchForm class="search-form" @search='search' />
    <el-table
      class="table-style-2 cursor"
      :data="stockList.list || []"
      border
      :header-cell-style="handerMethod"
      align="center"
      @sort-change="sortChange">
      <el-table-column prop="date" label="日期" width="100"  align="center" />
      <el-table-column prop="stockName" label="个股名称"  align="center" />
      <el-table-column prop="incr" label="当日涨幅"  align="center">
        <template #default='{row}'>
          <div v-dClass='row.incr'>{{Number(row.incr) > 0 ? '+' : ''}}{{ row.incr ? Number(row.incr).toFixed(2) + '%' : '-' }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="rzye" label="融资余额"  align="center">
        <template #default='{row}'>
          <div>{{ bigNumberTransform(row.rzye) }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="rzyezb" label="融资余额占流通市值比"  align="center">
        <template #default='{row}'>
          <div>{{ row.rzyezb ? row.rzyezb.toFixed(2) + '%' : '-' }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="rzjmr" label="融资净买入" width="120"  align="center">
        <template #default='{row}'>
          <div>{{ bigNumberTransform(row.rzjmr) }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="rzjmrzb" label="融资净买入占流通市值比(%)"  align="center">
        <template #default='{row}'>
          <div v-if='(row.rzjmr || row.rzjmr == 0) && row.rzjmr > 0'>{{ row.rzjmrzb.toFixed(2) }}</div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column prop="rzrqye" label="融资融券余额"  align="center">
        <template #default='{row}'>
          <div>{{ bigNumberTransform(row.rzrqye) }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="vol" label="成交量(股)" width="110"  align="center">
        <template #default='{row}'>
          <div>{{ amount(row.vol) }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="amt" label="成交额(元)"  align="center">
        <template #default='{row}'>{{ amount(row.amt) }}</template>
      </el-table-column>
      <el-table-column prop="turnover" label="换手率"  align="center">
        <template #default='{row}'>
          <div>{{ row.turnover ? row.turnover.toFixed(2) + '%' : '-' }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="boardName" label="所属行业" width="120"  align="center">
        <template #default='{row}'>
          <div @click="goDetail(row)">{{ row.boardName }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="stockPeTTM" label="个股/行业PE-TTM"  align="center">
        <template #default='{row}'>
          <div>{{ row.stockPeTTM ? row.stockPeTTM.toFixed(2) : '-' }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="boardPeTTM" label="个股/行业PE-TTM"  align="center" />
      <el-table-column prop="valuation" label="估值水位"  align="center" />
      <el-table-column prop="marketCap" label="总市值" width="120"  align="center">
        <template #default='{row}'>
          <div>{{ bigNumberTransform(row.marketCap) }}</div>
        </template>
      </el-table-column>
    </el-table>
    <CPagination ref='pagin' :showPageSizes='true' :pageSize='10' :pageData='stockList' @fetchData='fetchData'/>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue'
import CPagination from "@/components/pagination/Index.vue"
import SearchForm from './SearchForm.vue'
import { getMarginStockLeast } from '@/apis/trading'
import { useRoute  } from "vue-router";
import { bigNumberTransform } from '@/utils/data-format'
export default defineComponent({
  components:{
    CPagination,
    SearchForm
  },
  setup() {
    const route = useRoute();
    const dataMap = reactive({
      stockList : {
        list: []
      }
    })

    const params = {
      pageSize: 10,
      pageNum: 1,
      stockCode: route.query.code,
      // stockCode: '002714.SZ',
    }
    const fetchData = async (value:any) => {

      if(value.pageNum){
        params['pageNum'] = value.pageNum
      }
      if(value.pageSize){
        params['pageSize'] = value.pageSize
      }

      const list = await getMarginStockLeast(params)
      if( list ){
        dataMap.stockList = (list as any).data;
      }
    }
    fetchData({})

    //合并单位表头
    const handerMethod = (obj:any) => {
      const { row, columnIndex } = obj
      if (row[0].level == 1) {


        row[11].colSpan = 0
        row[12].colSpan = 2
        if (columnIndex === 13) {
          return { display: 'none' }
        }
      }
    }

    const search = (val:any) =>{
      console.log(val)
      params['conditions'] = val
      fetchData({})
    }

    const goDetail = async (item:any) => {
      window.open(`/investment/marginTrading/industry?code=${item.boardName}`)
    }

    const amount = (value: any, fixed = 2, unit = '元') => {
      if (unit == '万') {
        value = value * 10000
      } else if (unit == '亿') {
        value = value * 100000000
      }
      if (Math.abs(value / 100000000) >= 1) {
        return (value / 100000000).toFixed(fixed) + '亿'
      }
      if (Math.abs(value / 10_000) >= 1) {
        return (value / 10000).toFixed(fixed) + '万'
      }
      return value
    }

    return {
      ...toRefs(dataMap),
      handerMethod,
      fetchData,
      search,
      bigNumberTransform,
      goDetail,
      amount
    }
  }
})
</script>
<style scoped lang="scss">
.search-form{
    margin-top: 80px;
  }
</style>
