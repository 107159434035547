<template>
  <!--  指数估值 -->
  <div class="table_box">
    <nav-switch :tab-list="valuationNvaList" :initial-type="1" @changeTab='changeTab' style="margin-top:0" />
    <el-table
      border
      v-loading='loading'
      class="table-style-2 cursor"
      :data="tableData.list || []"
      @row-click='goDetail'
      :header-cell-style="headerMethod"
      :default-sort ="{prop:'name', order:'descending'}"
      @sort-change="sortChange"
      style="width: 100%">
      <!-- <el-table-column prop="name" label="个股" width="102" align="center">
        <template #default='{row}'>{{ row.name }}<br />{{ row.code }}</template>
      </el-table-column> -->
      <el-table-column prop="name" label="名称" align="center" sortable="custom">
        <template #default='{row}'>
          <div>{{ row.name }}</div>
          <div v-if="typeof row.valuationQuantile !== 'object' && row.valuationQuantile >=0 && row.valuationQuantile < 25" class="green">估值偏低</div>
          <div v-if="typeof row.valuationQuantile !== 'object' && row.valuationQuantile >= 25 && row.valuationQuantile < 75" >估值适中</div>
          <div v-if="typeof row.valuationQuantile !== 'object' && row.valuationQuantile >= 75 && row.valuationQuantile < 100" class="red">估值偏高</div>
        </template>
      </el-table-column>
      <el-table-column prop="lastClose" label="最新收盘价" align="center">
        <template #default='{row}'>
          <div v-dClass='row.lastIncrease'>{{ row.lastClose ? row.lastClose.toFixed(2) : '-' }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="lastIncrease" label="最新收盘价" align="center">
        <template #default='{row}'>
          <div v-dClass='row.lastIncrease'>{{row.lastIncrease > 0 ? '+' : ''}}{{ row.lastIncrease }}%</div>
        </template>
      </el-table-column>
      <el-table-column prop="peTtm" label="PE-TTM" align="center" sortable="custom">
        <template #default='{row}'>
          <div>{{ row.peTtm.toFixed(2) }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="valuationQuantile" label="估值分位" align="center" sortable="custom">
        <template #default='{row}'>
          <div v-if="row.valuationQuantile">{{ row.valuationQuantile.toFixed(2) }}%</div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column prop="pb" label="PB" align="center" sortable="custom">
        <template #default='{row}'>
          <div>{{ row.pb.toFixed(2) }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="roe" label="ROE" align="center" sortable="custom">
        <template #default='{row}'>
          <div>{{ row.roe.toFixed(2) }}%</div>
        </template>
      </el-table-column>
      <el-table-column prop="lastMonthIncrease" label="近1月(涨幅)" align="center" sortable="custom">
        <template #default='{row}'>
          <div v-dClass='row.lastMonthIncrease'>{{ dataFormat(row.lastMonthIncrease) }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="last3MonthIncrease" label="近3月" align="center" sortable="custom">
        <template #default='{row}'>
          <div v-dClass='row.last3MonthIncrease'>{{ dataFormat(row.last3MonthIncrease) }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="last6MonthIncrease" label="近6月" align="center" sortable="custom">
        <template #default='{row}'>
          <div v-dClass='row.last6MonthIncrease'>{{ dataFormat(row.last6MonthIncrease) }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="lastYearIncrease" label="近1年" align="center" sortable="custom">
        <template #default='{row}'>
          <div v-dClass='row.lastYearIncrease'>{{ dataFormat(row.lastYearIncrease) }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="sinceThisYearIncrease" label="今年以来" align="center" sortable="custom">
        <template #default='{row}'>
          <div v-dClass='row.sinceThisYearIncrease'>{{ dataFormat(row.sinceThisYearIncrease) }}</div>
        </template>
      </el-table-column>
    </el-table>
    <paging-component ref='paging' :showPageSizes='true' :pageSize='10' :pagerCount="3"  :pageData='tableData' @fetchData='fetchData' />
  </div>
</template>
<script lang="ts">
import pagingComponent from '@/components/pagination/Index.vue'
import { defineComponent, reactive, toRefs, ref } from 'vue'
import navSwitch from './nav-switch.vue'

import { getIndexBoardValuation } from '@/apis/market'
export default defineComponent({
  components: {
    pagingComponent,
    navSwitch
  },
  setup(props) {
    const paging = ref(null)
    const dataMap = reactive({
      loading: true,
      tableData: {},
      valuationNvaList: [
        {
          id: 1,
          name: '沪深',
          val: 'index'
        },
        {
          id: 2,
          name: '一级行业',
          val: 'swl1'
        },
        {
          id: 3,
          name: '二级行业',
          val: 'swl2'
        },
        {
          id: 4,
          name: '三级行业',
          val: 'swl3'
        },
      ],
    })
    let params = {
      type: 'index',
      pageNum: '1',
      pageSize: '10'
    }
    const fetchData = async (value:any) => {
      if( value.pageNum ){
        params['pageNum'] = value.pageNum
      }
      if( value.pageSize ){
        params['pageSize'] = value.pageSize
      }
      if( value.type ){
        params['type'] = value.type
      }
      dataMap.loading = true
      const marginList = await getIndexBoardValuation(params)
      dataMap.tableData = (marginList as any).data;
      dataMap.loading = false
    }
    const headerMethod = (item: any) => {
      const { row, columnIndex } = item
      // row[0].colSpan = 0
      if (row[0].level == 1) {
        row[8].colSpan = 0
        row[1].colSpan = 2
        if (columnIndex === 2) {
          return { display: 'none' }
        }
      }
    }
    const goDetail = (item: any) => {
      const path = '/page/data_center_all'
      window.open( `${path}?industryName=${item.name}&boardType=${item.type}`)
    }
    const changeTab = (item: any) => {
      if( paging.value ){
        (paging as any).value.setCurrentPage(1)
      }
      fetchData({
        type: item.val,
        pageNum: 1
      })
    }
    const sortChange = (column: any) => {
      let isAsc = column.order === "ascending" ? 'asc' : 'desc';
      params['sortFiled'] = column["prop"]
      params['sort'] = isAsc
      fetchData({})
    }

    const dataFormat = (val: any) => {
      if( typeof val !== 'number' ){
        return '-'
      }
      if( val > 0 ){
        return '+' + val.toFixed(2) + '%'
      }else{
        return val.toFixed(2) + '%'
      }
    }


    return {
      ...toRefs(dataMap),
      paging,
      fetchData,
      goDetail,
      headerMethod,
      changeTab,
      sortChange,
      dataFormat
    }
  }
})
</script>
<style scoped lang="scss">
.table_box {
  // width: 1317px;
}
</style>
