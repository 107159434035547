
import { defineComponent, reactive, toRefs } from 'vue'
import CTitle from '@/components/title/Index.vue'
import LineChartG from '@/components/charts/LineChartG.vue'
import LineChartH from '@/components/charts/LineChartH.vue'
import { getDiagnosticAggregation } from '@/apis/diagnosis'
import { useRoute } from 'vue-router'

export default defineComponent({
  components: {
    CTitle,
    LineChartG,
    LineChartH
  },
  setup() {
    const route = useRoute()
    const stockCode = route.query.code//'600519.SH'
    // const stockCode = '600751.SH'
    const dataMap = reactive({
      ajaxRes: false,
      stockList1: {},
      timearr: []
    })
    const fetchData = async () => {
      console.log('dddd')
      const data1 = await getDiagnosticAggregation({ stockCode: stockCode })
      dataMap.stockList1 = (data1 as any).data;
      dataMap.ajaxRes = true;
    }
    fetchData()

    const getTimeList = (res:any) => {
      dataMap.timearr = res
    }

    return {
      ...toRefs(dataMap),
      getTimeList
    }
  }
})
