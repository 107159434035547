<template>
  <div :class="!disabled ? 'box' : ''" @click="goDataCenter">
    <div class="industry line1">{{item.boardName}}</div>
    <div class="num1" v-dClass='item.boardIncr'>{{stockFormat(item.boardIncr.toFixed(2))}}</div>
    <div class="name">
      <span class="text">{{item.stockName}}</span>
      <span class="num2" v-dClass='item.stockIncr'>{{stockFormat(item.stockIncr.toFixed(2))}}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { stockFormat } from '@/utils/data-format'

export default defineComponent({
  props: {
    item: {
      type: Object,
      default: {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const goDataCenter = () => {
      if( props.disabled ){
        return false
      }
      const row = props.item
      window.open(`/page/data_center_all?industryName=${row['boardName']}&boardType=${row['boardType']}`)
    }

    return {
      stockFormat,
      goDataCenter
    }
  }
})
</script>
<style lang='scss' scoped>
.box {
  cursor: pointer;
}
</style>
