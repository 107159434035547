<template>
  <div
    :id="id"
    :class="className"
    :style="{height: height, width: width}"
  />
</template>

<script lang="ts">
import { defineComponent, onActivated, onBeforeUnmount, onDeactivated, onMounted, nextTick, watch } from 'vue'
import resize from './mixins/resize'
import { init, EChartsOption } from 'echarts'
let symbolImg = 'image://'+ require('@/assets/images/circle.png') 
import * as echarts from 'echarts'
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'lineChartsCompoents'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '193px',
      required: true
    },
    chartData: {
      type: Object,
      default: []
    },
    
  },
  setup(props) {
    watch(props, (newName, oldName) => {
      initChart()
    });
    
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()
    const initChart = () => {
      const barChart = init(document.getElementById(props.id) as HTMLDivElement)

      let xData = [];
      let serviesData1 = [];
      for (let index = 0; index < props.chartData.length; index++) {
        xData.push(props.chartData[index].dateLabel)
        serviesData1.push(props.chartData[index].score)
      }

      barChart.setOption({
        // tooltip: {
        //   formatter: function (params) {
        //     // var data = params.data || [0, 0];
        //     return params.value;
        //   }
        // },
        tooltip: {
          trigger: 'axis',
          backgroundColor: "rgba(21,84,155,0.67)", // 修改背景颜色
          // backgroundColor: "#15549B", // 修改背景颜色
          borderColor: "transparent", // 修改边框颜色
          borderRadius: 10,
          formatter:function(param:any){
            let str = `
              <div style='width:100%;height:100%;border-radius:border-radius: 30px;padding:5px 10px;color:#fff'>
                <div style='padding-bottom:5px;'>${param[0].name}</div>
                <div>${param[0].value}</div>
              </div>
            `
            return str
          }
        },
        grid: {
          top: '10%',
          left: '30px',
          right: '0%',
          bottom: '30px'
          // containLabel: true
        },
        xAxis: {
          type: 'category',
          data: xData,
          axisTick: { show: false },
          axisLine: {
            show: false,
            lineStyle: {
              color: 'rgba(66,129,236,.5)'
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false,
            lineStyle: {
              color: 'rgba(66,129,236,.5)'
              // color: '#4281EC',
            }
          }
        },
        series: [
          {
            data: serviesData1,
            type: 'line',
            symbol: symbolImg,
            symbolSize: 20,
            lineStyle: {
              color: '#2E5DF6',
              width: 3
              // type: 'dashed'
            },
            itemStyle: {
              borderWidth: 3,
              borderColor: '#EE6666',
              color: '#2E5DF6'
            }
          }
        ]
      } as unknown as EChartsOption)
      chart.value = barChart
    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {

    }
  }
})
</script>

<style lang="scss" scoped>

</style>
