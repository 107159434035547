
import { defineComponent, reactive, toRefs } from 'vue'
import CPagination from "@/components/pagination/Index.vue"
import SearchForm from './SearchForm.vue'
import { getMarginStockLeast } from '@/apis/trading'
import { useRoute  } from "vue-router";
import { bigNumberTransform } from '@/utils/data-format'
export default defineComponent({
  components:{
    CPagination,
    SearchForm
  },
  setup() {
    const route = useRoute();
    const dataMap = reactive({
      stockList : {
        list: []
      }
    })

    const params = {
      pageSize: 10,
      pageNum: 1,
      stockCode: route.query.code,
      // stockCode: '002714.SZ',
    }
    const fetchData = async (value:any) => {

      if(value.pageNum){
        params['pageNum'] = value.pageNum
      }
      if(value.pageSize){
        params['pageSize'] = value.pageSize
      }

      const list = await getMarginStockLeast(params)
      if( list ){
        dataMap.stockList = (list as any).data;
      }
    }
    fetchData({})

    //合并单位表头
    const handerMethod = (obj:any) => {
      const { row, columnIndex } = obj
      if (row[0].level == 1) {


        row[11].colSpan = 0
        row[12].colSpan = 2
        if (columnIndex === 13) {
          return { display: 'none' }
        }
      }
    }

    const search = (val:any) =>{
      console.log(val)
      params['conditions'] = val
      fetchData({})
    }

    const goDetail = async (item:any) => {
      window.open(`/investment/marginTrading/industry?code=${item.boardName}`)
    }

    const amount = (value: any, fixed = 2, unit = '元') => {
      if (unit == '万') {
        value = value * 10000
      } else if (unit == '亿') {
        value = value * 100000000
      }
      if (Math.abs(value / 100000000) >= 1) {
        return (value / 100000000).toFixed(fixed) + '亿'
      }
      if (Math.abs(value / 10_000) >= 1) {
        return (value / 10000).toFixed(fixed) + '万'
      }
      return value
    }

    return {
      ...toRefs(dataMap),
      handerMethod,
      fetchData,
      search,
      bigNumberTransform,
      goDetail,
      amount
    }
  }
})
